import React from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import CalendarCon from '@/components/Account/Calendar/index';
import DropDownSwitch from '@/components/dropDownSwitch/index';
import DropDownCom from '../dropDownCom/index';
import DisclaimerComponents from '../disclaimer/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class ListIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      marketData:[
        {
          name:'US',
          id:'US',
        },
        {
          name:'JP',
          id:'JP',
        },
        {
          name:'UK',
          id:'UK',
        },
        {
          name:'DE',
          id:'DE',
        },
        {
          name:'HK',
          id:'HK',
        },
        {
          name:'SG',
          id:'SG',
        },
        {
          name:'CN',
          id:'CN',
        }
      ],
      periodData:[],
      selectMarket:{name:'US',id:'US'},
      selectPeriod:{},
      totalNum:0,
      totalNumTemp:0,
      listData:[],
      closeCalendar:false,
      isCalendarShow:false,
      isCampaign:false,
      defaultMarket:'',
      marketsData:[],
      amountData:'',
      isLoading:true,
      periodValue:'',
      isNumberShow:true,
      isSelectMonth:false,
      isQualified:true,
      campaginLink:'https://www.fubon.com/securities/overseasec/index.html',
      isNumeric:false,
    }
  }
  // is_campaign==1--活动时间
  getApiData(){
    const {isNumeric}=this.state;
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'marketing-campaign',{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(res => {
      if(Number(res['status']) === 0){
        let data=res['data'];
        if(data&&JSON.stringify(data)!=='{}'){
          let period_option=data['period_option'];
          let amounts=data['minimun_lottery_amount'];
          let is_campaign=Number(data['is_campaign']);
          let markets=data['markets'];
          let periodOption=this.updatePeriodOption(period_option);
          let newMarkets='',getOption=periodOption[1]['id'];
          
          if(is_campaign==0){
            newMarkets=['US'];
            this.setState({
              periodValue:getOption,
            });
          }else{
            let getData = this.processMarkets(markets);
            newMarkets=getData['sortedMarkets'];
            this.setState({
              defaultMarket:getData['newData'],
              marketsData:getData['sortedMarkets'],
            });
          }
          if(getOption!=='OPTIONAL'){
            const params = {
              date_option: getOption,
              from_date: '',
              to_date: '',
              markets: newMarkets,
              amounts,
              isNumeric,
            };
            this.getMarketData(params, is_campaign);
          }
          this.setState({
            periodData: periodOption,
            amountData: amounts,
            totalNumTemp: data['max_lottery'],
            selectPeriod: periodOption[1],
            isCampaign: is_campaign==1,
            isNumberShow: is_campaign==1?true:false,
          });
        }
      }
    })
  }
  getMarketData(paramsData, campaign){
    const {totalNumTemp} =this.state;
    const { date_option, from_date, to_date, markets, amounts, isNumeric } = paramsData;

    fetch(common.getHttpZs+'getMarketTransaction',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        date_option,
        from_date,
        to_date,
        markets,
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        let data=res['data'];
        if(data&&data.length>0){
          if(campaign==1){
            let { updatedResData, totalNumber, isQualified } = this.addNumberProperty(data, amounts, isNumeric);
            this.setState({
              isLoading: false,
              listData: updatedResData,
              totalNum: totalNumber > totalNumTemp ? totalNumTemp : totalNumber,
              isQualified,
            });
          }else{
            this.setState({
              isLoading: false,
              listData: data,
            });
          }
        }else{
          this.setState({
            listData: [],
            isLoading: false,
            totalNum: 0,
            isQualified:false,
          });
        }
      }else{
        this.setState({
          listData: [],
          isLoading: false,
          totalNum: 0,
        });
      }
    })
    .catch((err) => {
      console.log('Request failed', err);
      this.setState({
        listData: [],
        isLoading: false,
        totalNum: 0,
      });
    })
  }
  // 添加抽奖次数number参数，并计算总的抽奖次数(如果超过 totalNumTemp，则设置为 totalNumTemp)
  addNumberProperty(resData, amounts, isNumeric) {
    let totalNumber = 0; // 用于累加总的抽奖次数
    let isQualified = false; //判断是否达标
    const getData = isNumeric ? amounts['monthly'] : amounts['weekly'];
    const updatedResData = resData.map(item => {
      let country = item['country'];
      let currency = item['currency'];
      let amount = parseFloat(item['amount']);
      let minAmount = getData[country][currency];
      let number = parseInt(amount / minAmount);
      totalNumber += number; // 累加到总次数
      // 判断是否达标
      if (amount >= minAmount) {
        isQualified = true; // 一旦有一个项目达标，就设置为 true
      }
      return {
        ...item,
        number: number,
      };
    });
    return { updatedResData, totalNumber, isQualified };
  }
  // 把数组改为对象数组并把US放在首位
  chgMarketData(markets) {
    markets.sort((a, b) => {
      if (a === "US") return -1;
      if (b === "US") return 1;
      return 0;
    });
    let objData=markets.map(market => ({ name: market, id: market }));
    return {markets,objData};
  }
  cbselectData(item,type){
    const {closeCalendar,selectPeriod,selectMarket}=this.state;
    const isMarket = type === 'market';
    const updatedItem = isMarket ? { selectMarket: item } : { selectPeriod: item };
    const updatedVisibility = isMarket ? { selectPeriod: { ...selectPeriod, isShow: false } } : { selectMarket: { ...selectMarket, isShow: false } };
    this.setState({
      ...updatedVisibility,
      ...updatedItem,
      closeCalendar: !closeCalendar,
    });
  }
  // 7-9月
  // 市場別只能選美國/日本，1個欄位
  // 查詢區間只能查本週、上週、7月、8月、9月，5個欄位
  // 选择下拉框对应的值
  cbDropDownItem(item,sign){
    const {from_date, to_date} = this.props;
    const {marketsData, amountData, periodValue, isCampaign, selectMarket, selectPeriod}=this.state;
    const { name,id } = item;
    let isNumeric = false;
    this.setState({
      [sign]: {
        ...[sign],
        name,
        id,
        isShow: false,
      },
      isLoading:true,
    });
    if(sign=='selectPeriod'){
      // 如選擇月份則不顯示抽獎次數
      isNumeric = Number.isFinite(Number(id));
      this.setState({
        isCalendarShow:id=='OPTIONAL',
        periodValue:id,
        isSelectMonth:isCampaign?isNumeric:false,
        isNumberShow:isCampaign?!isNumeric:false,
        isNumeric,
      });
    }
    
    if(id=='OPTIONAL'){
      const params = {
        date_option: 'OPTIONAL',
        from_date,
        to_date,
        markets: sign === 'selectPeriod' ? isCampaign ? marketsData : [selectMarket['id']] : [id],
        amounts: amountData,
        isNumeric,
      };
      this.getMarketData(params, isCampaign);
    }else{
      const params = {
        date_option: sign === 'selectPeriod' ? id : periodValue,
        from_date: id === 'OPTIONAL' || (sign === 'selectMarket' && selectPeriod['id'] === 'OPTIONAL') ? from_date : '',
        to_date: id === 'OPTIONAL' || (sign === 'selectMarket' && selectPeriod['id'] === 'OPTIONAL') ? to_date : '',
        markets: sign === 'selectPeriod' ? isCampaign ? marketsData : [selectMarket['id']] : [id],
        amounts: amountData,
        isNumeric,
      };
      this.getMarketData(params, isCampaign);
    }
  }
  // 处理下拉框数据
  updatePeriodOption(data) {
    const newData = [];
    newData.push(...data.map(item => {
      let name = '';
      let id = item;
      switch (item) {
        case 'LAST_WEEK':
          name = "Last Week";
          break;
        case "THIS_WEEK":
          name = "This Week";
          break;
        case "OPTIONAL":
          name = "Customise";
          break;
        default:
          if (item.match(/\d{6}/)) {
            const month = item.slice(4);
            name = `${parseInt(month, 10)} Month`;
          }
          break;
      }
      return { id, name};
    }));
    return newData;
  }
  // 处理数据，确保US在首位
  processMarkets(markets) {
    let sortedMarkets = [];
    if (markets.includes('US')) {
      sortedMarkets.push('US');
    }
    for (let market of markets) {
      if (!sortedMarkets.includes(market)) {
        sortedMarkets.push(market);
      }
    }
    let newData = {
      name: sortedMarkets.join(' / '),
    };
    return {sortedMarkets,newData};
  }
  // 选择日期
  callbackDateData(data){
    const {marketsData, selectMarket, amountData, isNumeric, isCampaign}=this.state;
    this.setState({
      isLoading:true,
    });
    const params = {
      date_option: 'OPTIONAL',
      from_date: data[0],
      to_date: data[1],
      markets: isCampaign ? marketsData : [selectMarket['id']],
      amounts: amountData,
      isNumeric,
    };
    this.getMarketData(params, isCampaign);
    this.props.cbChangeDateData(data);
  }
  // 查看活动
  campaginClick(){
    const {campaginLink}=this.state;
    //ios终端
    if(common.isiOS){
      window.location.href=campaginLink;
    }else{//android终端/pc端
      window.open(campaginLink, '_blank');
    }
  }
  componentDidMount() {
    this.getApiData();
  }
  render() {
    const {t,ChgThemeData,isMobile,isPC} = this.props;
    const {marketData,periodData,selectPeriod,selectMarket,totalNum,listData,closeCalendar,isCalendarShow,isCampaign,defaultMarket,isLoading,isNumberShow,isSelectMonth, isQualified}=this.state;
    
    return (
      <div className={[APPstyles.flex_1,APPstyles.flex_column,styles.campaginListPage,!isPC&&styles.campaginListPageTable,isMobile&&styles.campaginListPageMobile].join(' ')} style={{height:'100%'}}>
        <div className={[styles.topCon,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <div className={[APPstyles.flex_row,APPstyles.flex_1].join(' ')}>
            {isCampaign && 
              <div style={{marginRight:isMobile?8:0,minWidth:isMobile?58:'auto'}}>
                <div className={[styles.total,APPstyles.flex_column].join(' ')}>
                  <span className={APPstyles.fz12}>
                    {t(isSelectMonth?'Draw qualification':'Total Draw')}
                  </span>
                  <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                    {isSelectMonth ? isQualified ? t('Qualified') : t('Disqualified') : totalNum}
                  </span>
                </div>
                <div className={[styles.view,styles.viewMobile,APPstyles.color_56CCF2,APPstyles.flex_center].join(' ')} style={{display:isMobile?'':'none'}} onClick={()=>{this.campaginClick()}}>{t('View Campagin')}</div>
              </div>
            }
            <div className={[isMobile?APPstyles.flex_column:APPstyles.flex_row,APPstyles.flex_1].join(' ')}>
              <DropDownSwitch {...this.props} isCampaign={isCampaign} sign='campagin' />
              <div className={isMobile?APPstyles.flex_between:APPstyles.flex_row}>
                <DropDownCom 
                  {...this.props}
                  sign='market'
                  newData={marketData}
                  isCampaign={isCampaign}
                  selectData={isCampaign?defaultMarket:selectMarket}
                  cbselectData={(item)=>{this.cbselectData(item,'market')}}
                  cbDropDownItem={(item)=>{this.cbDropDownItem(item,'selectMarket')}}
                />
                <DropDownCom 
                  {...this.props}
                  sign='period'
                  newData={periodData}
                  selectData={selectPeriod}
                  isCampaign={isCampaign}
                  cbselectData={(item)=>{this.cbselectData(item,'period')}}
                  cbDropDownItem={(item)=>{this.cbDropDownItem(item,'selectPeriod')}}
                />
              </div>
              {isCalendarShow&&
                <div className={[APPstyles.flex_topcenter,isMobile&&APPstyles.flex_1].join(' ')} style={{position:'relative',marginTop:isMobile?8:0}}>
                  <CalendarCon
                    {...this.props}
                    sign='campagin'
                    closeCalendar={closeCalendar}
                    callbackDateData={(data)=>{this.callbackDateData(data)}}
                    cbCloseInput={()=>{
                      selectMarket['isShow'] = false;
                      selectPeriod['isShow'] = false;
                      this.setState({
                        selectMarket,
                        selectPeriod,
                      });
                    }}
                  />
                </div>
              }
            </div>
          </div>
          {isCampaign && 
            <div className={[styles.view,APPstyles.color_56CCF2,APPstyles.flex_center].join(' ')} style={{display:isMobile?'none':''}} onClick={()=>{this.campaginClick()}}>{t('View Campagin')}</div>
          }
        </div>
        <div style={{position:'relative',paddingBottom:isPC?60:0}} className={[APPstyles.flex1_auto,APPstyles.flex_column].join(' ')}>
          {/* 加载中 */}
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          <div 
          className={[
            styles.commonCon,
            styles.headerCon,
            APPstyles.flex_row,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:'',
            !isMobile&&styles.headerPC,
          ].join(' ')}
          >
            <span>{t('Country1')}</span>
            <span>{t('Currency3')}</span>
            <span>{t('Total Transaction Amount')}</span>
            {isCampaign && 
              <span style={{display:isNumberShow?'':'none'}}>{t('Number Of Draws')}</span>
            }
          </div>
          {listData.length>0?
            listData.map((item,index) => {
              return  <div
                        key={index} 
                        className={[styles.commonCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}
                        style={{display:item['currency']=='GBP'&&item['amount']==0?'none':''}}
                      >
                        <span>{t(item['country'])}</span>
                        <span>{t(item['currency'])}</span>
                        <span>{common.transThousands(common.keepNum(item['amount'],0))}</span>
                        {isCampaign &&
                          <span style={{display:isNumberShow?'':'none'}}>
                            {item['number']>0?common.transThousands(item['number']):item['number']}
                          </span>
                        }
                      </div>
            }):
            <div className={[APPstyles.noData,APPstyles.flex_center,APPstyles.flex_1].join(' ')} style={{display:isLoading?'none':''}}>{t('No datas')}</div>  
          }
        {isCampaign && !isPC && <DisclaimerComponents />}
        </div>
        {isCampaign && isPC && <DisclaimerComponents />}
       </div>
    )
  }
}
export default withTranslation()(ListIndex);