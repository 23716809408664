import React from 'react';
import { withTranslation } from 'react-i18next';
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
} from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from './index.less';
import DropDown from './dropDown/index';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chgImg:dropDownImg,
    }
  }
  handleClick = (e) => {
    const {selectData,cbselectData,sign,isCampaign} = this.props;
    e.stopPropagation();
    if(sign=='market'&&isCampaign) return;
    selectData['isShow']=!selectData['isShow'];
    cbselectData(selectData);
  }
  //点击外部关闭下拉框
  handleClose = (e) => {
    const {selectData,cbselectData} = this.props;
    if (!this.marketComponent.contains(e.target)) {
      selectData['isShow']=false;
      cbselectData(selectData);
    }
  }
  handleMouse(isHover) {
    const { ChgThemeData,isPC,isCampaign } = this.props;
    if (!isPC||isCampaign) return;
    let imgurl;
    if (isHover) {
      imgurl = ChgThemeData === 'Light' ? dropDownOnLightImg : dropDownOnImg;
    } else {
      imgurl = dropDownImg;
    }
    this.setState({ 
      chgImg: imgurl, 
    });
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClose);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClose);
  }
  render() {
    const {t,ChgThemeData,newData,selectData,sign,isCampaign,isMobile} = this.props;
    const {chgImg}=this.state;
    return (
      <div 
        className={[isMobile?APPstyles.flex_1:APPstyles.flex_topcenter].join(' ')} 
        style={{position:'relative',margin:isMobile?sign=='market'?'0 4px 0 0':'0 0 0 4px':sign=='period'?'0 8px':0}}
      >
        <div 
          className={[styles.market,isMobile&&styles.marketMobile,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black].join(' ')}
          style={{cursor:sign=='market'?isCampaign?'default':'pointer':'pointer'}}
          onClick={(e) => this.handleClick(e)}
          onMouseOver={() => this.handleMouse(true)} 
          onMouseOut={() => this.handleMouse(false)}
        >
          <span className={[APPstyles.fz12,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{t(selectData['name'])}</span>
          <img 
              src={chgImg} 
              className={styles.dropDownImg} 
              style={{transform:selectData['isShow']?'rotate(-180deg)':'rotate(0deg)',cursor:sign=='market'?isCampaign?'default':'pointer':'pointer'}}
            />
        </div>
        <div ref={testComponent=>this.marketComponent=testComponent}>
          <DropDown 
            {...this.props} 
            newDropDownData={newData}
            selectItems={selectData}
            cbDropDownItem={(data)=>{
              this.props.cbDropDownItem(data);
            }}
          />
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);