import React from 'react'
import { Button,message,Input } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '../../asserts/js/common'
import { 
  checkOnImg,
  checkOffImg
} from '../../asserts/imgs/login/index'
import Appstyles from '../../App.less'
import styles from './Apply.less'

let getJobIDH=36

class Apply extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      nameValue:'',
      jobValue:'',
      isAgree:false,
      isRelief:false,
      isOneself:false,
      jobData:['建築營造','天然資源、食品與農業','藝文與影音傳播','企業經營管理','教育與訓練','金融財務','政府公共事務','醫療保健','個人及社會服務','休閒與觀光旅遊','資訊科技','司法、法律與公共安全','行銷與銷售','製造','科學、技術、工程、數學','物流運輸','其他'],
      jobIndex:null,
      isjobOptionShow:false,
      isLoading:false,
      minheight1:800
    }
  }
  //NASDAQ全球用戶訂閱同意書
  agreeClick(){
    this.setState(state => ({
      isAgree: !state.isAgree
    }))
  }
  //海外股市新平台使用免責聲明
  reliefClick(){
    this.setState(state => ({
      isRelief: !state.isRelief
    }))
  }
  //打开免责声明
  reliefReadClick(){
    this.props.callbackReadOpen()
  }
  pdfReadClick(){
    this.props.callbackPDFOpen()
  }
  //本人确认服務條款
  oneselfClick(){
    this.setState(state => ({
      isOneself: !state.isOneself
    }))
  }
  //申請人姓名
  changeNameClick(e) {
    this.setState({
      nameValue: e.target.value
    })
  }
  //申請人職業--隐藏/显示
  dropDownClick() {
    this.setState(state => ({
      isjobOptionShow: !state.isjobOptionShow
    }))
    getJobIDH=document.getElementById('getJobID').clientHeight
  }
  //申請人職業--选择
  occupationClick(index,data){
    this.setState({
      jobIndex:index,
      jobValue:data,
      isjobOptionShow:true
    })
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  //送出申請
  sendClick(){
    const {isAgree,isRelief,isOneself,nameValue,jobValue}=this.state
    if(!isAgree){
      message.warning({
        content: '請勾上NASDAQ全球用戶訂閱同意書！'
      })
      return
    }
    if(!isRelief){
      message.warning({
        content: '請勾上海外股市新平臺使用免責聲明！'
      })
      return
    }
    if(!isOneself){
      message.warning({
        content: '請勾上本人聲明有關的服務條款！'
      })
      return
    }
    if(nameValue==''){
      message.warning({
        content: '請填寫申請人姓名！'
      })
      return
    }
    if(jobValue==''){
      message.warning({
        content: '請選擇申請人職業！'
      })
      return
    }
    this.setState({
      isLoading: true
    })
    if (common.enableWebca) {
      //SIGN WITH CERT WHEN CLICKED SIGN BUTTON
      const userid = this.props.uid;
      const certFilter = "//S_CN=" + userid + "//";
      const signContent = "SIGNEAGREEMENT";

      if (window.inAppDetector.isInApp()) {
        //IF IT IS IN WEBVIEW, SIGN WITH CERT IN APP
        window.mtklib.sign({
          "idno": userid,
          "tosign": signContent,
          "method": "P1",
          "cb": (cbobj) => {
            if (cbobj.code == "0") {
              fetch(common.getHttpAgree + 'signeagreement', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json,text/plain,*/*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  token: window.sessionStorage.getItem('token'),
                  name: nameValue,
                  job: jobValue,
                  sign_type: "pkcs1",
                  signature: cbobj.signed,
                  src: signContent,
                  sn: cbobj.casno
                })
              })
                .then(res => res.json())
                .then(res => {
                  if (res['status'] == 0) {
                    this.props.callbackSend()
                  } else {
                    //获取失败
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: res['message']
                    })
                  }
                })
            } else {
              //获取失败
              this.setState({
                isLoading: false
              })
              message.warning({
                content: cbobj.code + " " + cbobj.desc
              })
            }
          }
        })
      } else {
        //執行PKCS7簽章
        window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, token, data) => {
          if (code === 0 && data) {
            var certData = JSON.parse(data);

            fetch(common.getHttpAgree + 'signeagreement', {
              method: 'POST',
              headers: {
                'Accept': 'application/json,text/plain,*/*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                token: window.sessionStorage.getItem('token'),
                name: nameValue,
                job: jobValue,
                signature: certData.signature,
                data: signContent,
                subject: certData.subdn
              })
            })
              .then(res => res.json())
              .then(res => {
                if (res['status'] == 0) {
                  this.props.callbackSend()
                } else {
                  //获取失败
                  this.setState({
                    isLoading: false
                  })
                  message.warning({
                    content: res['message']
                  })
                }
              })
          } else if (code === 5067 || code === "5067") {
            //憑證挑選
            window.twcaLib.SelectSignerFubon(userid, certFilter, "", "", "", 0x04 | 0x08, 0, null, (code, msg, token, data) => {
              if (code === 0) {
                //執行PKCS7簽章
                window.twcaLib.SignPkcs7(signContent, 0x0000, (code, msg, token, data) => {
                  if (code === 0 && data) {
                    var certData = JSON.parse(data);

                    fetch(common.getHttpAgree + 'signeagreement', {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json,text/plain,*/*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        token: window.sessionStorage.getItem('token'),
                        name: nameValue,
                        job: jobValue,
                        signature: certData.signature,
                        data: signContent,
                        subject: certData.subdn
                      })
                    })
                      .then(res => res.json())
                      .then(res => {
                        if (res['status'] == 0) {
                          this.props.callbackSend()
                        } else {
                          //获取失败
                          this.setState({
                            isLoading: false
                          })
                          message.warning({
                            content: res['message']
                          })
                        }
                      })
                  } else {
                    this.setState({
                      isLoading: false
                    })
                    message.warning({
                      content: code + " " + msg
                    })
                  }
                });
              } else {
                this.setState({
                  isLoading: false
                })
                message.warning({
                  content: code + " " + msg
                })
              }
            });
          } else {
            this.setState({
              isLoading: false
            })
            message.warning({
              content: code + " " + msg
            })
          }
        });
      }
    } else {
      const token = window.sessionStorage.getItem('token')
      fetch(common.getHttpAgree + 'signeagreement', {
        method: 'POST',
        headers: {
          'Accept': 'application/json,text/plain,*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          name: nameValue,
          job: jobValue,
          signature: "dev",
          data: "dev",
          subject: "dev"
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res['status'] == 0) {
            this.props.callbackSend()
          } else {
            //获取失败
            this.setState({
              isLoading: false
            })
            message.warning({
              content: res['message']
            })
          }
        })
    }
  }
  componentWillMount(){
   
  }
  render(){
    const {bodyW,minbodyW,mobilebodyW,bodyH} = this.props
    const {isAgree,isRelief,isOneself,nameValue,jobValue,jobData,jobIndex,isjobOptionShow,isLoading,minheight1}=this.state
    return (
      <div 
        className={[
          styles.trade_apply_con,
          Appstyles.overflowYScroll
        ].join(' ')}
        style={{
          width:bodyW>mobilebodyW?525:'100%',
          padding:bodyW>mobilebodyW?20:'50px 20px',
          height:bodyW>mobilebodyW?bodyH<=minheight1?'80%':'auto':'100%'
        }}
      >
        <div className={styles.trade_apply_title}>富邦證券美股即時行情報價線上申請</div>
        <div className={styles.trade_apply_subtitle}>美股即時行情報價線上申請僅限<span style={{color:'#000000'}}>NASDAQ</span>全球用戶訂閱用戶條款中所定義之非專業用戶，若您為專業用戶，請洽詢您所屬營業員。</div>
        <div className={styles.trade_apply_msg}>請詳閱NASDAQ全球用戶訂閱同意書與內容與服務條款。</div>
        <div className={[Appstyles.flex_row,styles.trade_apply_check].join(' ')}>
          <img src={isAgree?checkOnImg:checkOffImg} className={Appstyles.img24} onClick={this.agreeClick.bind(this)} alt='checked' />
          <span style={{cursor:'pointer'}} onClick={this.pdfReadClick.bind(this)}>NASDAQ全球用戶訂閱同意書</span>
        </div>
        <div className={[Appstyles.flex_row,styles.trade_apply_check].join(' ')}>
          <img src={isRelief?checkOnImg:checkOffImg} className={Appstyles.img24} onClick={this.reliefClick.bind(this)} alt='checked' />
          <span style={{cursor:'pointer'}} onClick={this.reliefReadClick.bind(this)}>海外股市新平台使用免責聲明</span>
        </div>
        <div className={[Appstyles.flex_row,styles.trade_apply_check,styles.trade_apply_terms].join(' ')}>
          <img src={isOneself?checkOnImg:checkOffImg} className={Appstyles.img24} onClick={this.oneselfClick.bind(this)} alt='checked' />
          <span>本人聲明為NASDAQ全球用戶訂閱用戶條款中所定義之非專業用戶，並已詳閱NASDAQ全球用戶訂閱同意書與海外股市新平台使用免責聲明之內容與服務條款。</span>
        </div>
        <div className={styles.trade_apply_msg}>根據NASDAQ交易所規定，用戶需如實填寫以下資料以備查核，資料僅供申請美股即時報價使用。</div>
        <div className={[Appstyles.flex_row,styles.trade_apply_name].join(' ')}>
          <span>申請人姓名:</span>
          <Input autoComplete='off' value={nameValue} onChange={this.changeNameClick.bind(this)} />
        </div>
        <div className={[Appstyles.flex_row,styles.trade_apply_name].join(' ')}>
          <span>申請人職業:</span>
          <div className={styles.trade_apply_occupation} onClick={this.dropDownClick.bind(this)}>
            <span id='getJobID' className={styles.trade_apply_occupation_getName}>{jobValue}</span>
            <div 
              className={styles.trade_apply_dropdown} 
              style={{
                display:isjobOptionShow?'':'none',
                top:getJobIDH+2
              }}
            >
              {
              jobData.map((item,index) => {
                return <span key={index} style={{color:jobIndex==index?'#008FC7':'#404141'}} className={Appstyles.flex_column} onClick={this.occupationClick.bind(this,index,item)}>{item}</span>
                })
              }
            </div>
          </div>
        </div>
        <div className={[styles.trade_place_button,styles.trade_apply_btn].join(' ')}>
          <Button type='default' loading={isLoading} className={Appstyles.btn_blue} onClick={this.sendClick.bind(this)}>送出申請</Button>
        </div>
      </div>
  )}
}
export default withTranslation()(Apply)