/*
    Developer: Parker Suen
    This hook is for checking the page if it is mounted/rendered on the first time.
*/

import React, { useRef, useEffect } from "react";

const useIsFirstRender = () => {
  const refIsFirstRender = useRef(true);
  useEffect(() => {
    refIsFirstRender.current = false;
  }, []);
  return refIsFirstRender.current;
};

export default useIsFirstRender;
