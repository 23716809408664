import React from 'react'
import { withTranslation } from 'react-i18next'
import APPstyles from '@/App.less'
import styles from './index.less'

class DropDown extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  choiceOneClick(data){
    this.props.cbDropDownData(data)
  }
  render(){
    const {t,ChgThemeData,choiceData,chgkeyData,tag}=this.props
    return (
      <div 
        className={[
          styles.dropDown_content,
          tag=='market'&&styles.dropDown_market,
          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          ChgThemeData=='Light'?APPstyles.dropDown_con_light:APPstyles.bg_star_off
        ].join(' ')}
      >
            {choiceData.map((item,index) => {
              return  <span
                        key={index}
                        className={[
                          APPstyles.flex_row,
                          APPstyles.flex_topcenter,
                          styles.dropDown_content_child,
                          ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black,
                          chgkeyData==item['key']?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_1b1b1b:''
                        ].join(' ')}
                        onClick={this.choiceOneClick.bind(this,item)} 
                      >
                        {t(item['name'])}
                      </span>
              })
            } 
      </div>
  )}
}
export default withTranslation()(DropDown)