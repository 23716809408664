import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import axios from 'axios'
import common from '../../../asserts/js/common'
import { 
    leftImg,
    leftLightImg
} from '../../../asserts/imgs/common/index'
import { 
    foldImg,
    foldOnImg,
    foldOnLightImg
} from '../../../asserts/imgs/quote/index'
import Tabs from '../../Tabs/index'
import MsgCon from './MsgCon/index'
import Quote from './Quote/index'
import News from '../../../pages/News/index'
import StreetEvent from './StreetEvent/index'
import StockReports from './StockReports+/index'
import Fundamentals from './Fundamentals/index'
import Profile from './Profile/index'
import APPstyles from '../../../App.less'
import styles from './index.less'

let page=1

class QuoteDetail extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            data:'',
            isLoading:false,
            chgTabName : 'quote',
            tabQouteData:[
                {
                  name:'Quote',
                  isShow:false,
                  id:'quote'
                },
                {
                  name:'News',
                  isShow:false,
                  id:'news'
                },
                {
                  name:'Events',
                  isShow:false,
                  id:'events'
                },
                {
                  name:'StockReports+',
                  isShow:false,
                  id:'stockReports'
                },
                {
                  name:'Fundamentals',
                  isShow:false,
                  id:'fundamentals'
                },
                {
                  name:'Profile',
                  isShow:false,
                  id:'profile'
                }
            ],
            fbstockid:'',
            foldImgUrl:foldImg,
            isToggleOn: true,
            listsData:[],
            eventData:[
                {
                    checked:false,
                    name:'General/ Political Event',
                    key:'GeneralAndPoliticalEvents'
                },
                {
                    checked:true,
                    name:'Earning Conference Call',
                    key:'EarningsCallsAndPresentations'
                },
                {
                    checked:true,
                    name:'Earning Releases',
                    key:'EarningsReleases'
                },
                {
                    checked:true,
                    name:'Corporate Conference Call',
                    key:'CorporateCallsAndPresentations'
                },
                {
                    checked:true,
                    name:'Conference Presentation',
                    key:'ConferencePresentations'
                },
                {
                    checked:true,
                    name:'Other Corporate',
                    key:'OtherCorporate'
                },
                {
                    checked:true,
                    name:'Conference',
                    key:'Conferences'
                },
                {
                    checked:true,
                    name:'Shareholder Annual General Meeting',
                    key:'ShareholderAndAnnualMeetings'
                },
                {
                    checked:true,
                    name:'Guidance Call/ Presentation',
                    key:'GuidanceCallsAndPresentations'
                },
                {
                    checked:true,
                    name:'Corporate Analyst Meeting',
                    key:'CorporateAnalystMeetings'
                },
                {
                    checked:true,
                    name:'Sales Trading Statement',
                    key:'SalesAndTradingStatementCallsAndPresentations'
                },
                {
                    checked:true,
                    name:'Sales Release',
                    key:'SalesAndTradingStatementReleases'
                },
                {
                    checked:true,
                    name:'M & A Announcement',
                    key:'MergerAndAcquisitionCallsAndPresentations'
                },
                {
                    checked:false,
                    name:'Economic Event',
                    key:'EconomicEvents'
                },
                {
                    checked:true,
                    name:'ExDividends',
                    key:'ExDividends'
                },
                {
                    checked:true,
                    name:'Stock Splits',
                    key:'StockSplits'
                }
            ],
            timeData:[
                {
                    name:'Any Time',
                    key:'all'
                },
                {
                    name:'Today',
                    key:'today'
                },
                {
                    name:'Previous 7 days',
                    key:'prev_7d'
                },
                {
                    name:'Next 7 days',
                    key:'next_7d'
                },
                {
                    name:'Next 1 month',
                    key:'next_1m'
                },
                {
                    name:'Past 1 month',
                    key:'prev_1m'
                }
            ],
            isSELoading:false,
            loadMore:false,
            noMore:false,
            eventTypes:['All'],
            period:'all',
            recperpage:10,
            pn:1,
            isScrollShow:false,
            dateData:[
                {
                    name:'1D',
                    key:'AVG_SCORE'
                },
                {
                    name:'1W',
                    key:'AVG_SCORE_1W'
                },
                {
                    name:'3M',
                    key:'AVG_SCORE_3M'
                },
                {
                    name:'6M',
                    key:'AVG_SCORE_6M'
                }
            ],
            dateKey:'AVG_SCORE',
            RefinitivKeyData:['REC_SELL_CNT','REC_REDUCE_CNT','REC_HOLD_CNT','REC_BUY_CNT','REC_STRONG_BUY_CNT'],
            ScoreKeyData:['ANALYST_SCORE','FUND_SCORE','RV_SCORE','RISK_SCORE','TECH_SCORE'],
            allChartData:{
                REC_CNT:0,
                ScoreData:{
                    StockData:[],
                    SectorData:[]
                },
                AverageScore:'',
                PeerComAlltData:[],
                PeerComData:[],
                PeerComxAisxData:[],
                RefinitivData:[],
                AnalystPriceTarget:'',
                loadingData:[true,true,true,true],
                colorsData:[]
            },
            triggerCloseChartZoom: 0,
            chgRic:'',
            indexData:[0,1,4,5,6,7],
            ratingColor:'#E03E57',
            isNewsSCrollShow:false,
            eventChoiceKeyData:['GeneralAndPoliticalEvents','EconomicEvents'],
            isResetShow:false
        }
        this.myDetailRef = React.createRef()
        this.myDetailRef_1 = React.createRef()
    }
    //返回--mobile
    backMobileClick(){
        const {currentIndex}=this.props
        if(currentIndex==2||currentIndex==3){
            this.props.cbCloseDetailPage(false)
            return
        }
        this.props.callbackLeftPageShow('list')
        this.props.cbDetailPageShow(false)
    }
    //获取数据--getquote
    getQuoteDetail(ric){
        const {allChartData,chgTabName}=this.state
        let token=window.sessionStorage.getItem('token')
        fetch(common.getHttp+'getquote',{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                token:token,
                ric:ric
            })
        })
        .then(res => res.json())
        .then(res => {
            //获取数据成功
            if(res['status']==0){
                //SubscribeRic
                this.props.store.addSubscribeRic(ric)  
                this.props.store.setQuoteData([res['data']]) 

                let fbstockid=res['data']['fbstockid']

                this.props.callbackFbstockid(fbstockid)
                this.setState({
                    data:res['data'],
                    fbstockid:fbstockid,
                    isLoading:false,
                    allChartData:allChartData,
                    chgRic:ric
                })
                if(chgTabName=='events'){
                    this.onLoadSEData(ric,'Y')
                }
                if(chgTabName=='stockReports'){
                    let symbol=res['data']['symbol']
                    if(ric&&typeof(ric)!=='undefined'&&symbol&&typeof(symbol)!=='undefined'){
                        allChartData['loadingData']=[true,true,true,true]
                        this.setState({
                            allChartData:allChartData
                        })
                        this.getSRData(ric,symbol)
                        return
                    }
                    this.onLoadSRData()
                }
                return
            }
            //获取失败
            this.setState({
                isLoading: false,
                data:'',
                fbstockid:'',
                chgRic:''
            })
        })
    }
    //折叠--点击
    foldClick() {
        const {ChgThemeData}=this.props
        const {isToggleOn}=this.state
        this.setState(state => ({
            isToggleOn: !state.isToggleOn,
            foldImgUrl:!state.isToggleOn||ChgThemeData=='Light'?foldImg:foldOnImg
        }))
        this.props.cbIsToggleOn(isToggleOn)
    }
    //回到顶部
    backTopFun(){
        const {isPC,isTablet,isMobile,currentIndex}=this.props
        const {indexData}=this.state
        let getId=isPC||(isTablet&&!isMobile&&indexData.includes(currentIndex))?'getDetailID_1':'getDetailID'
        let id=document.getElementById(getId)
        if(id){
            setTimeout(() => id.scrollTop=0, 10)
        }
    }
    //初始化数据--StreetEvent 
    onLoadSEData(ric,tag){
        const {eventData,period}=this.state
        let chgEventData='',eventKeyData='',chgTimeVal=''
        if(ric&&typeof(ric)!=='undefined'){
            page=1
            this.setState({
                isSELoading: true,
                pn:1,
                listsData:[],
                loadMore:false,
                noMore:false,
            })
            if(tag=='Y'){
                chgEventData=eventData
                chgTimeVal=period
            }else{
                chgEventData=this.resetSEDataFun()
                chgTimeVal='all'
            }
            eventKeyData=common.dealCheckedKeyData(chgEventData)
            eventKeyData=eventKeyData.length?eventKeyData:['All']
            this.setState({
                eventTypes:eventKeyData
            })
            this.getSEData(eventKeyData,chgTimeVal,1,[],ric)
            return
        }
        this.setState({
            isSELoading: false,
            pn:1,
            listsData:[],
            loadMore:false,
            noMore:false
        })
        page=1
    }
    resetSEDataFun(){
        const {eventData,eventChoiceKeyData}=this.state
        for(let i in eventData){
            eventData[i]['checked']=true
            if(eventChoiceKeyData.includes(eventData[i]['key'])){ 
                eventData[i]['checked']=false
            }
        }
        this.setState({
            eventData:eventData
        })
        return eventData
    }
    //下拉框传回的值--StreetEvent
    cbStreetEventData(item){
        const {period,eventTypes,data}=this.state
        page=1
        this.setState({
            pn:1,
            isSELoading:true,
            listsData:[],
            loadMore:false,
            noMore:false,
            eventTypes:item['eventTypes'],
            period:item['period']
        })
        // event
        if(item['tag']=='event'){
            this.getSEData(item['eventTypes'],period,1,[],data['ric'])
            return
        }
        // time
        this.getSEData(eventTypes,item['period'],1,[],data['ric'])  
    }
    //页面滚动触发的事件--minpc/mobile--StreetEvent
    handleScroll(){
        const {isPC,isTablet,isMobile,isRightPageShow,currentIndex,currentData23}=this.props
        const {chgTabName,indexData,isNewsSCrollShow}=this.state
        if(chgTabName=='news'&&(isMobile&&currentData23&&!currentData23.includes(currentIndex))||(isTablet&&currentData23&&currentData23.includes(currentIndex))){
            let current=this.myDetailRef.current
            if(Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 10){
                this.setState({
                    isNewsSCrollShow:!isNewsSCrollShow
                })
            }
        }
        if(chgTabName=='events'){
            this.setState(state=>({
                isScrollShow:!state['isScrollShow']
            }))
            let getRef=isTablet&&!isMobile&&indexData.includes(currentIndex)?this.myDetailRef_1.current:this.myDetailRef.current
            if((isTablet||(isRightPageShow!==''&&isPC))&&(Number(getRef.scrollHeight - getRef.scrollTop- getRef.clientHeight) < 10)){
                this.cbScrollSEData()
            }
        }
    }
    //滚动加载更多--StreetEvent
    cbScrollSEData(){
        const {eventTypes,period,loadMore,noMore,isSELoading,listsData,data}=this.state
        if(loadMore||noMore||isSELoading) return
        page++
        this.setState({
            pn: page,
            loadMore:true,
            noMore:false
        })
        this.getSEData(eventTypes,period,page,listsData,data['ric'])
    }
    //获取数据--StreetEvent
    getSEData(eventTypes,period,pn,listData,ric){
        const {eventData,recperpage}=this.state
        axios.post(common.getHttp+'street-event', {
            token: sessionStorage.getItem('token'),
            stockid:ric,
            event_types:eventTypes,
            period:period,
            recperpage:recperpage,
            pn:pn
        })
        .then((res) => {
            let resData=res['data']
            //获取数据成功
            if(resData['status']==0){
                let data=resData['data']['datalist']
                let pn1=data.length?data.length>=10?false:true:false
                let pnother=listData.length?data.length?false:true:true
                let noMoreData=pn==1?pn1:pnother
                if(data.length){
                    for(let i in data){
                        let key = eventData.find((x) => x['key'] == data[i]['event_type'])
                        data[i]['title']=key['name']
                    }
                }
                this.setState({
                    listsData:listData.concat(data),
                    isSELoading: false,
                    loadMore:false,
                    noMore:noMoreData
                })
                return
            }
            //获取失败
            this.onErrSEData()
        })
    }
    //获取失败
    onErrSEData(){
        this.setState({
            listsData:[],
            isSELoading: false,
            loadMore:false,
            noMore:false
        })
    }
    //切换时间--Peer Comparison--StockReports+
    chgPeerComDataFun(datas,key,symbol){
        const {allChartData}=this.state
        let peerData=[],xAisxData=[],getColorsData=[]
        if(datas&&datas.length){
            for(let i in datas){
                peerData[i]=datas[i][key]
                xAisxData[i]=datas[i]['ticker']
                getColorsData[i]=datas[i]['ticker']==symbol?'#008FC7':'#AFADAD'
            }
            allChartData['PeerComData']=peerData
            allChartData['PeerComxAisxData']=xAisxData
            allChartData['colorsData']=getColorsData
            this.setState({
                allChartData:allChartData
            })
            return
        }
        allChartData['PeerComData']=[]
        allChartData['PeerComxAisxData']=[]
        this.setState({
            allChartData:allChartData
        })
    }
    //获取数据--StockReports+
    getSRData(ric,symbol){
        const {dateKey,RefinitivKeyData,allChartData,ScoreKeyData}=this.state
        let token=window.sessionStorage.getItem('token')
        fetch(common.getHttp+'srplus',{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                token:token,
                ric:ric
            })
        })
        .then(res => res.json())
        .then(res => {
            //获取数据成功
            if(res['status']==0){
                let datas=res['data']
                // Company Score
                this.CompanyScoreFun(datas,allChartData,ScoreKeyData)
                // Peer Comparison
                this.PeerComparisonFun(datas['PeerComparisom'],allChartData,dateKey,symbol)
                // Analyst Price Target
                this.AnalystFun(datas['AnalystPriceTarget'],allChartData)
                // REFINITIV Broker Rating
                this.REFINITIVFun(datas['RefinitivBrokerRating'],allChartData,RefinitivKeyData)
                return
            }
            this.onLoadSRData()
        })
    }
    // Company Score
    CompanyScoreFun(datas,allChartData,ScoreKeyData){
        let  CompanyScore=datas['CompanyScore'],getStockData=[],getSectorData=[]
        allChartData['AverageScore']=datas['AverageScore']
        if(CompanyScore&&JSON.stringify(CompanyScore) !== '{}'){
            for(let i in ScoreKeyData){
                if(typeof(CompanyScore['Stock'])=='undefined'){
                    getStockData=[]
                }else{
                    getStockData.push(CompanyScore['Stock'][ScoreKeyData[i]])
                }
                if(typeof(CompanyScore['Sector'])=='undefined'){
                    getSectorData=[]
                }else{
                    getSectorData.push(CompanyScore['Sector'][ScoreKeyData[i]])
                }
            }
            allChartData['ScoreData']['StockData']=getStockData
            allChartData['ScoreData']['SectorData']=getSectorData
        }else{
            allChartData['ScoreData']['StockData']=[]
            allChartData['ScoreData']['SectorData']=[] 
        }
        allChartData['loadingData'][0]=false
        this.setState({
            allChartData:allChartData
        })
    }
    // Peer Comparison
    PeerComparisonFun(datas,allChartData,dateKey,symbol){
        if(datas.length){
            this.chgPeerComDataFun(datas,dateKey,symbol)
            allChartData['PeerComAlltData']=datas
            
        }else{
            allChartData['PeerComAlltData']=[]
            allChartData['PeerComData']=[]
        }
        allChartData['loadingData'][1]=false
        this.setState({
            allChartData:allChartData
        })
    }
    // Analyst Price Target
    AnalystFun(datas,allChartData){
        allChartData['AnalystPriceTarget']=datas
        allChartData['loadingData'][2]=false
        this.setState({
            allChartData:allChartData
        })
    }
    // 判断数组里面的值都为0
    isArrZeroFun(arr){
        let temp = [...new Set(arr)]
        return temp.length === 1 && temp[0] === 0
    }
    // REFINITIV Broker Rating
    REFINITIVFun(datas,allChartData,RefinitivKeyData){
        let getRefinitivData=[],getREC_CNT=0,colorData=["#38925A", "#39C46E", "#AFADAD", "#E03E57", "#AB1829"],colorIndex=3
        if(datas&&JSON.stringify(datas) !== '{}'){
            for(let i in RefinitivKeyData){
                let value=datas[RefinitivKeyData[i]]==null?0:datas[RefinitivKeyData[i]]
                getRefinitivData.push(value)
            }
            // 查找数组里最大值的下标对应的颜色
            let max=Math.max(...getRefinitivData)
            colorIndex = getRefinitivData.map(item => item).indexOf(max)
            // 是否数组的值为0
            let isZeroData=this.isArrZeroFun(getRefinitivData)
            allChartData['RefinitivData']=isZeroData?[]:getRefinitivData
            getREC_CNT=datas['REC_CNT']?datas['REC_CNT']:0
            allChartData['REC_CNT']=Number(getREC_CNT)
        }else{
            allChartData['RefinitivData']=[]
            allChartData['REC_CNT']=0
            colorIndex=3
        }
        allChartData['loadingData'][3]=false
        this.setState({
            allChartData:allChartData,
            ratingColor:colorData[colorIndex]
        })
    }
    //初始化数据
    onLoadSRData(){
        this.setState({
            ratingColor:'#E03E57',
            allChartData:{
                ScoreData:{
                    StockData:[],
                    SectorData:[]
                },
                AverageScore:'',
                PeerComAlltData:[],
                PeerComData:[],
                PeerComxAisxData:[],
                RefinitivData:[],
                AnalystPriceTarget:'',
                loadingData:[false,false,false,false],
                colorsData:[]
            }
        })
    }
    // Chart Zoom
    onTriggerCloseChartZoom(){
        this.setState(state => ({triggerCloseChartZoom: state.triggerCloseChartZoom + 1 }))
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {ricData,isBackTopShow,ChgThemeData,isDetailPageShow,isMobile,chgTabData,isPC,closeRightPageShow}=this.props
        const {isToggleOn}=this.state
        //更改数据 
        if((ricData!==newProps['ricData'])){
            if(ricData&&JSON.stringify(ricData) !== '{}'){
                this.setState({
                    isLoading: true
                })
                ricData['ric']&&this.getQuoteDetail(ricData['ric'])
                this.backTopFun()
            }
        }
        if(isBackTopShow!==newProps['isBackTopShow']){
            this.backTopFun()
        }
        if(ChgThemeData!==newProps['ChgThemeData']){
            let imgurl=isToggleOn?foldImg:ChgThemeData=='Light'?foldOnLightImg:foldOnImg
            this.setState({
                foldImgUrl:imgurl
            })
        }
        if(isDetailPageShow!==newProps['isDetailPageShow']&&isMobile){
            this.setState({
                chgTabName:'quote'
            })
            this.backTopFun()
        }
        if(chgTabData!==newProps['chgTabData']){
            if(isPC) return
            this.setState({
                chgTabName:'quote'
            })
            this.backTopFun()
            this.onLoadSRData()
        }
        // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
        if(closeRightPageShow!==newProps['closeRightPageShow']){
            page=1
            let booleans=true
            let imgurl=booleans?foldImg:ChgThemeData=='Light'?foldOnLightImg:foldOnImg
            this.setState({
                foldImgUrl:imgurl,
                isToggleOn:true,
                chgTabName:'quote'
            })
            // 根据是否有数据来决定是否清空数据
            let dataLen=sessionStorage.getItem('hasQuoteDataLen')
            if(dataLen>0) return
            let chgEventData=this.resetSEDataFun()
            let eventKeyData=common.dealCheckedKeyData(chgEventData)
            this.setState({
                data:'',
                listsData:[],
                isSELoading:false,
                loadMore:false,
                noMore:false,
                eventTypes:eventKeyData,
                period:'all',
                recperpage:10,
                pn:1,
                chgRic:'',
                isLoading:false
            })
            this.backTopFun()
            this.onLoadSRData()
        }
    }
    render(){
        const {t,ChgThemeData,currentIndex,ricData,isRightPageShow,isMobile,isTablet,tagIshowHeader,isPC} = this.props
        const {tabQouteData,chgTabName,data,isLoading,fbstockid,foldImgUrl,isToggleOn,eventData,timeData,isSELoading,loadMore,noMore,listsData,period,isScrollShow,eventTypes,dateData,allChartData,chgRic,indexData,ratingColor,isNewsSCrollShow,isResetShow}=this.state
        return (
            <div 
                className={[
                    APPstyles.flex_column,
                    styles.detail_con
                ].join(' ')} 
            >
                <div 
                    className={[
                    styles.quote_right_fold,
                    isMobile?APPstyles.display_none:'',
                    ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                    ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
                    ].join(' ')}
                    style={{
                        display:currentIndex==2||currentIndex==3?'none':''
                    }}
                >
                    <img 
                        src={foldImgUrl} 
                        className={APPstyles.img16} 
                        onClick={this.foldClick.bind(this)} 
                        onMouseOver={()=>{
                            let imgurl=ChgThemeData=='Light'?foldOnLightImg:foldOnImg
                            this.setState({
                                foldImgUrl:imgurl
                            })
                        }} 
                        onMouseOut={()=>{
                            let imgurl=isToggleOn?foldImg:ChgThemeData=='Light'?foldOnLightImg:foldOnImg
                            this.setState({
                                foldImgUrl:imgurl
                            })
                        }} 
                        alt='fold' 
                    />
                </div> 
                <div
                    className={[
                        APPstyles.flex_row,
                        styles.back_mobile,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
                        ((currentIndex!==2||currentIndex!==3)&&isMobile)||((currentIndex==2||currentIndex==3)&&isTablet)?'':APPstyles.display_none
                    ].join(' ')}
                    id="back-mobile"
                >
                    <img 
                        src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                        onClick={() => {
                            this.backMobileClick();
                            // this.props.isChartZoom && this.props.onClickZoom();
                            this.onTriggerCloseChartZoom();
                            //ios终端
                            if((currentIndex==2||currentIndex==3)&&isMobile){
                                let isSow=tagIshowHeader=='add'?true:false
                                this.props.cbIsShowHeaderPage(isSow)
                            }
                            if((currentIndex==2||currentIndex==3)&&isTablet&&!isMobile){
                                this.props.cbIsShowHeaderPage(true)
                            }
                        }} 
                        className={APPstyles.img24} 
                        alt='back' 
                    />
                    <span>{t('Detail Quotes')}</span>
                </div>
                <div 
                    id='getDetailID'
                    ref={this.myDetailRef} 
                    onScroll={() => this.handleScroll()}
                    className={[
                        isPC||(isTablet&&!isMobile&&indexData.includes(currentIndex))?'':APPstyles.flex1_auto,
                        isPC||(isTablet&&!isMobile&&indexData.includes(currentIndex))?APPstyles.hPer_overflowH:'',
                        APPstyles.flex_column
                    ].join(' ')}
                > 
                    <MsgCon 
                        {...this.props} 
                        data={data} 
                        isLoading={isLoading}
                        chgTabName={chgTabName} 
                        fbstockid={fbstockid}
                        callbackTab={(data)=>{
                            this.props.callbackTab(data)
                        }}
                        cbSwitchStar={(data)=>{
                            this.props.cbSwitchStar(data)
                        }} 
                        callbackTradeShow={(tag,data)=>{
                            this.props.callbackTradeShow(tag,data)
                        }}
                        callbackAlertsShow={(tag,data)=>{
                            this.props.callbackAlertsShow(tag,data)
                        }}
                        cbCghTradeData={(data)=>{
                            this.props.cbCghTradeData(data)
                        }}
                        cbChgWatchlistIdFun={(id)=>{
                            this.props.cbChgWatchlistIdFun(id)
                        }}
                        cbSetWatchListFun={(data,tag,id)=>{
                          this.props.cbSetWatchListFun(data,tag,id)
                          this.props.cbIsShowHeaderPage(true)
                        }}
                    />
                    <Tabs 
                        {...this.props} 
                        tabData={tabQouteData} 
                        tag='detail'
                        callbackTabIndex={(index)=>{
                            this.backTopFun()
                            this.setState({
                                chgTabName:tabQouteData[index]['id'],
                                period:'all'
                            })
                            if(tabQouteData[index]['id']=='events'){
                                this.onLoadSEData(data['ric'],'N')
                            }
                            if(tabQouteData[index]['id']=='stockReports'){
                                if(ricData['ric']&&typeof(ricData['ric'])!=='undefined'&&ricData['symbol']&&typeof(ricData['symbol'])!=='undefined'){
                                    allChartData['loadingData']=[true,true,true,true]
                                    this.setState({
                                        allChartData:allChartData
                                    })
                                    this.getSRData(ricData['ric'],ricData['symbol'])
                                    return
                                }
                                this.onLoadSRData()
                            }
                        }} 
                    />
                    {/* 切换tab */}
                    <div 
                        id='getDetailID_1'
                        ref={this.myDetailRef_1} 
                        onScroll={() => this.handleScroll()}
                        className={[
                            (isPC&&(chgTabName!=='events'||chgTabName!=='news'))||(isTablet&&!isMobile&&indexData.includes(currentIndex))?APPstyles.flex1_auto:'',
                            (isPC&&(chgTabName=='events'||chgTabName=='news'))?APPstyles.hPer_overflowH:'',
                            chgTabName=='news'?APPstyles.flex_1:''
                        ].join(' ')}
                    >
                    {chgTabName=='quote'&&<Quote 
                        {...this.props} 
                        data={data} 
                        isLoading={isLoading} 
                        isRightPageShow={isRightPageShow}
                        triggerCloseChartZoom={this.state.triggerCloseChartZoom}
                    />}
                    {chgTabName=='news'&&<News 
                        {...this.props} 
                        newTag='quote'
                        data={data} 
                        chgRic={chgRic}
                        isLoading={isLoading}
                        isNewsSCrollShow={isNewsSCrollShow} 
                        cbRightDetailShowFun={(data,loading)=>{
                          this.props.cbRightDetailShowFun(data,loading)
                        }}
                    />}
                    {chgTabName=='events'&&<StreetEvent 
                        {...this.props} 
                        data={data} 
                        fbstockid={fbstockid} 
                        eventData={eventData}
                        timeData={timeData}
                        isLoading={isSELoading}
                        loadMore={loadMore}
                        noMore={noMore}
                        listsData={listsData}
                        period={period}
                        eventTypes={eventTypes}
                        isScrollShow={isScrollShow}
                        isResetShow={isResetShow}
                        cbStreetEventData={this.cbStreetEventData.bind(this)}
                        cbScrollSEData={this.cbScrollSEData.bind(this)}
                        cbResetData={()=>{
                            this.setState({
                                isResetShow:!isResetShow,
                                period:'all'
                            })
                            this.onLoadSEData(data['ric'],'N')
                        }}
                        cbBackTop={()=>{
                            this.backTopFun()
                        }}
                        cbShowDetailData={(data)=>{
                            this.props.cbShowDetailData(data)
                        }}
                    />}
                    {chgTabName=='stockReports'&&<StockReports 
                        {...this.props} 
                        data={data}
                        dateData={dateData}
                        allChartData={allChartData}
                        ratingColor={ratingColor}
                        cbTimeData={this.chgPeerComDataFun.bind(this)} 
                    />}
                    {chgTabName=='fundamentals'&&<Fundamentals 
                        {...this.props}
                        data={data} 
                        chgRic={chgRic}
                    />}
                    {chgTabName=='profile'&&<Profile 
                        {...this.props}
                        data={data}  
                        chgRic={chgRic}
                    />}  
                    </div>
                </div>
            </div>
    )}
}
export default withTranslation()(observer(QuoteDetail))