import React from 'react'
import { withTranslation } from 'react-i18next'
import { Spin } from 'antd'
import common from '@/asserts/js/common'
import { 
  closeOnLightImg,
  closeOnImg,
  closeImg,
  leftImg,
  leftLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg
} from '@/asserts/imgs/common/index'
import InputCon from '@/components/Account/InputCon/index'
import Disclaimer from './disclaimer/index'
import APPstyles from '@/App.less'
import styles from './index.less'

let chgPage=1

class Detail extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      sortData:[
        {
          upImg:dropUpImg,
          downImg:dropDownImg,
          sortKey:''
        }
      ],
      minH800:800,
      chgCloseImg:closeImg,
      dropDownL:16,
      dropDownR:'unset',
      dropDownT:46,
      dropDownW:'94%',
      isScrollShow:false,
      listData:[],
      isLoading:true,
      stock_id:null,
      country_code:'US',
      page:1,
      sizeTotal:0,
      recperpage:20,
      currency:'USD',
      loadMore:false,
      noMore:false,
      total_today_profit:0,
      isOnload:true,
      newSimplequoteData:''
    }
    this.myRef = React.createRef()
  }
  // 排序
  sortClick(key,sort){
    const {ChgThemeData}=this.props
    const {listData,sortData,isLoading}=this.state
    this.setState({
      isLoading:true
    })
    if(listData.length<=0||isLoading) return
    this.resetSortDataFun()
    let getData=[],noData=[]
    for(let i in listData){//当数据为-/undefined时放在数组的后面
      let tempsData=listData[i][key]
      tempsData=='-'||typeof(tempsData) == 'undefined'?noData.push(listData[i]):getData.push(listData[i])
    }
    let newData=common.sortNumber(sort,getData,key)
    // 排序--img
    if(sort=='asc'){
      sortData[0]['upImg']=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
    }else{
      sortData[0]['downImg']=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
    }
    sortData[0]['sortKey']=sort
    this.setState({
      listData:newData,
      sortData:sortData,
      isLoading:false
    })
  }
  // 恢复默认的
  resetSortDataFun(){
    const {sortData}=this.state
    for(let i in sortData){
      sortData[i]['upImg']=dropUpImg
      sortData[i]['downImg']=dropDownImg
    }
    this.setState({
      sortData:sortData
    })
  }
  // input返回的数据
  callbackInputData(data){ 
    let currency=common.imgShortNameChgCur[data[1]]
    chgPage=1  
    this.setState({
      isLoading:true,
      page:1,
      stock_id:data[0],
      currency:currency,
      country_code:data[1]
    })      
    this.resetSortDataFun()
    this.getData(1,data[0],currency,data[1],[],'','search')
  }
  //滚动加载更多 / 判断列表是否有滚动条
  handleScroll(isScroll){
    const {loadMore,noMore,isLoading,listData,stock_id,currency,country_code,newSimplequoteData}=this.state
    let current=this.myRef.current
    if(current){
      let scrollHeight=current.scrollHeight
      let clientHeight=current.clientHeight
      let scrollTop=current.scrollTop
      if(isScroll=='Y'){
        // 判断列表是否有滚动条
        this.setState({
          isScrollShow:scrollHeight>clientHeight?true:false
        })
      }else{
        // 滚动加载更多
        if(Number(scrollHeight - scrollTop- clientHeight) < 10){
          if(loadMore||noMore||isLoading||listData.length<=20) return
          chgPage++
          this.setState({
            page: chgPage,
            loadMore:true,
            noMore:false
          })
          this.resetSortDataFun()
          this.getData(chgPage,stock_id,currency,country_code,listData,newSimplequoteData)
        }
      }
    }
  }
  //获取数据
  getData(page,stock_id,currency,country_code,listsData,simplequoteData,isSearch){
    const {recperpage,isOnload}=this.state
    fetch(common.getHttpZs+'getTodayProfit', {
      method: 'POST',
      headers: {
        Accept: 'application/json,text/plain,*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: sessionStorage.getItem('token'),
        page: page,
        recperpage: recperpage,
        stock_id: stock_id,
        currency:currency,
        country_code:country_code
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then((res) => {
      res=JSON.parse(res)
      if(res['status']==0){
        if(JSON.stringify(res['data']) !== '{}'){
          let datalist=res['data']['datalist']
          let size_total=res['data']['size_total']
          let total_today_profit=res['data']['total_today_profit']
          this.setState({
            total_today_profit:total_today_profit
          })
          if(datalist.length>0){
            let pn1=datalist.length?datalist.length>=10?false:true:false
            let pnother=listsData.length?datalist.length?false:true:true
            let noMoreData=page==1?pn1:pnother 
            let newData=listsData.concat(datalist)
            // 目前這裡的股票代碼和名稱是從 getTodayProfit API的stock_code和stock_name拿取的, 請更改為呼叫 getsimplequote API, 用當中的 symbol 和 name
            if(isSearch=='search'){
              let getRicData=[]
              if(newData.length){
                for(let j in newData){
                  getRicData.push(newData[j]['stock_id'])
                }
              }
              if(getRicData.length){
                getRicData= [...new Set(getRicData)]//去重
                this.getsimplequoteFun(getRicData,newData)
              }
            }else{
              if(newData.length&&simplequoteData){
                for(let i in newData){
                  let getItem=simplequoteData[newData[i]['stock_id']]
                  newData[i]['stock_code']=getItem['symbol']
                  newData[i]['stock_name']=getItem['name']
                }
              }
              this.setState({
                listData: newData,
                isLoading:false
              }) 
            }
            this.setState({
              sizeTotal:size_total,
              loadMore:false,
              noMore:noMoreData
            })  
            if(isOnload){
              this.setState({
                isOnload:false
              }) 
              this.handleScroll('Y')
            }
            return
          }
          if(listsData.length){
            this.setState({
              isLoading:false,
              loadMore:false,
              noMore:true
            }) 
          }else{
            this.resetData()
          }
          return
        }
        this.resetData()
        return
      }
      this.resetData()
    })
    .catch((err) => {
      console.log(err)
      this.resetData()
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,allData){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        this.setState({
          newSimplequoteData:JSON.stringify(resData)=='{}'?'':resData
        })
        if(JSON.stringify(resData)!=='{}'){
          if(allData.length&&resData){
            for(let i in allData){
              let getItem=resData[allData[i]['stock_id']]
              allData[i]['stock_code']=getItem['symbol']
              allData[i]['stock_name']=getItem['name']
            }
          }
          this.setState({
            listData: allData,
            isLoading:false
          }) 
        }else{
          this.setState({
            isLoading:false
          }) 
        }
      }
    })
    .catch((err) => {
      console.log(err)
      this.setState({
        isLoading:false
      }) 
    })
  }
  resetData(){
    const {todaysCur,todayCountryCode}=this.props 
    chgPage=1
    this.setState({
      listData:[],
      isLoading:false,
      page:1,
      sizeTotal:0,
      recperpage:20,
      currency:todaysCur,
      country_code:todayCountryCode,
      loadMore:false,
      noMore:false,
      isScrollShow:false,
      total_today_profit:0
    })
  }
  // 初始化
  componentDidMount(){ 
    const {todaysCur,todayCountryCode,simplequoteData}=this.props   
    chgPage=1
    this.setState({
      currency:todaysCur,
      country_code:todayCountryCode,
      newSimplequoteData:simplequoteData
    })      
    this.getData(1,null,todaysCur,todayCountryCode,[],simplequoteData)
  }
  render(){
    const {t,bodyH,ChgThemeData,isMobile,isPC} = this.props
    const {minH800,chgCloseImg,dropDownL,dropDownR,dropDownT,dropDownW,isScrollShow,total_today_profit,listData,isLoading,loadMore,noMore,sortData}=this.state
    return (
      <div className={styles.order_fixed}>
        <div
          className={[
            styles.order_con,
            APPstyles.flex_column,
            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
          ].join(' ')}
          style={{
            width:isMobile?'100%':525,
            height:isMobile?'100%':bodyH<=minH800?'80%':725
          }}
          >
            {/* title */}
          <div 
            className={[
              isPC?APPstyles.flex_between:'',
              APPstyles.flex_row,
              styles.title_con,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
            style={{
              padding:isPC?'4px 16px':'4px 6px'
            }}
          >
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
              alt='back' 
              className={APPstyles.img24} 
              style={{
                display:isPC?'none':''
              }} 
              onClick={()=>{
                this.props.cbCloseDetail(false)
              }}  
            />
            <span>{t('Today’s P/L2')}</span>
            <img 
              src={chgCloseImg} 
              alt='close' 
              className={[
                APPstyles.img24
              ].join(' ')} 
              style={{
                display:isPC?'':'none'
              }}  
              onClick={()=>{
                this.props.cbCloseDetail(false)
              }}    
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgCloseImg:closeImg
                })
              }}
            />
          </div>
          <div 
            className={[
              styles.detail_msg_con,
              APPstyles.flex_column,
              APPstyles.flex_center,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
              <span 
              className={[
                styles.detail_msg_title,
                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
              ].join(' ')}
              >
                {t("Today's selling profit and loss details")}
              </span>
              <span 
                className={[
                  styles.detail_msg_num,
                  total_today_profit>0?APPstyles.color_red:total_today_profit<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                ].join(' ')}
              >
                {common.addplusNum(common.transThousands(common.keepNum(total_today_profit,2)))}
              </span>
          </div>
          <div 
            className={[
              APPstyles.flex_column,
              APPstyles.flex_center,
              styles.detail_input_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <InputCon
              {...this.props}
              dropDownL={dropDownL}
              dropDownR={dropDownR}
              dropDownT={dropDownT}
              dropDownW={dropDownW}
              callbackInputData={this.callbackInputData.bind(this)}
            />
          </div>
          <div 
            className={[
              styles.detail_table_header,
              styles.detail_table_common,
              APPstyles.flex_row,
              APPstyles.flex_topcenter,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ].join(' ')}
            style={{
              padding:isScrollShow?'0 25px 0 16px':'0 16px'
            }}
          >
            <span>{t('Name')}</span>
            <span>{t('QTY Sold')}</span>
            <span>{t('Avg Cost')}<br/>{t('Transcated Price1')}</span>
            <span className={APPstyles.flex_row} style={{justifyContent:'flex-end'}}>
              <label>{t('P/L1')}<br/>{t('P/L(%)')}</label>
              <label 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <label 
                      className={APPstyles.img_dropdown_up} 
                      style={{top:4}}
                      onClick={()=>{this.sortClick('profit_and_lost','asc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                        sortData[0]['upImg']=imgurl
                        this.setState({
                          sortData:sortData
                        })
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[0]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                        sortData[0]['upImg']=imgurl
                        this.setState({
                          sortData:sortData
                        })
                      }}
                    >
                      <img src={sortData[0]['upImg']} style={{bottom:3}} />
                    </label>
                    <label 
                      className={APPstyles.img_dropdown_down} 
                      style={{bottom:-3}}
                      onClick={()=>{this.sortClick('profit_and_lost','desc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                        sortData[0]['downImg']=imgurl
                        this.setState({
                          sortData:sortData
                        })
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[0]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                        sortData[0]['downImg']=imgurl
                        this.setState({
                          sortData:sortData
                        })
                      }}
                    >
                      <img src={sortData[0]['downImg']} style={{bottom:4}} />
                    </label>
                  </label>
            </span>
          </div>
          {/* 列表--body */}
          <div 
            ref={this.myRef} 
            onScroll={() => this.handleScroll()}
            className={APPstyles.flex1_auto} 
            style={{
              position:'relative'
            }}
          >
            {/* 加载中 */}
            <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
            {listData.length>0?
                listData.map((item, index) => {
                      return (
                        <div 
                          key={index}
                          className={[
                            styles.detail_table_body,
                            styles.detail_table_common,
                            APPstyles.flex_row,
                            APPstyles.flex_topcenter,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                          ].join(' ')}
                        >
                          <span className={APPstyles.flex_column}>
                            <label className={styles.detail_table_name}>{item['stock_code']}</label>
                            <label className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur}>{item['stock_name']}</label>
                          </span>
                          <span>{common.transThousands(common.keepNum(item['qty_sold'],0))}</span>
                          <span>{common.transThousands(common.keepNum(item['avg_cost'],2))}</span>
                          <span>
                            <label className={Number(item['profit_and_lost'])>0?APPstyles.color_red:Number(item['profit_and_lost'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                              {common.addplusNum(common.transThousands(common.keepNum(item['profit_and_lost'],2)))}
                            </label>
                            <br />
                            <label className={Number(item['profit_and_lost_perc'])>0?APPstyles.color_red:Number(item['profit_and_lost_perc'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                              {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(item['profit_and_lost_perc']*100,2))))}
                            </label>
                          </span>
                        </div>
                      );
                    })
              :
                <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
            }
            <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
            <div className={APPstyles.loading_more} style={{display:noMore&&listData.length>20?'':'none'}}>- {t('No More Data Available')} -</div>
            {listData.length>0&&<Disclaimer {...this.props} />}
          </div>
          {listData.length==0&&<Disclaimer {...this.props} />}
        </div>
      </div>
  )}
}
export default withTranslation()(Detail)