import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./ButtonBar.less";

const ButtonBar = (props) => {
  const { t } = useTranslation();
  const renderButtons = () => {
    return props.arr.map((item, id) => (
      <span
        key={id}
        id={item.id}
        className={[
          styles.button_bar_button,
          props.selected === item.id ? styles.selected : undefined,
        ].join(" ")}
        onClick={props.onClick}
      >
        {t(item.label)}
      </span>
    ));
  };
  return <div className={styles.button_bar}>{renderButtons()}</div>;
};

ButtonBar.propTypes = {
  arr: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonBar;
