import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const Volume = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [upColor, setUpColor] = useState(
    props.ChgThemeData === "Light" ? "F5475B" : "F5475B"
  );
  const [downColor, setDownColor] = useState(
    props.ChgThemeData === "Light" ? "39C46E" : "39C46E"
  );
  const [height, setHeight] = useState("1");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  const refIsColorChange = useRef(false); // check if user hasn't chnage the volume color

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.Volume_Option.option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      props.onTAValueChange({
        ta: "Volume",
        name: `${t("chart5.TA.Volume")} (Volume)`,
        option: {
          upColor: localChartSetting.upColor,
          downColor: localChartSetting.downColor,
          height: localChartSetting.height
        },
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    if (!refIsColorChange.current) {
      setUpColor(props.ChgThemeData === "Light" ? "F5475B" : "F5475B");
      setDownColor(props.ChgThemeData === "Light" ? "39C46E" : "39C46E");
    }
  }, [props.ChgThemeData]);

  useEffect(() => {
    props.onTAValueChange({
      ta: "Volume",
      name: `${t("chart5.TA.Volume")} (Volume)`,
      option: { upColor: upColor, downColor: downColor, height: height },
    });
  }, [upColor, downColor, height]);

  useEffect(() => {
    setUpColor(props.localChartSetting.Volume_Option.option.upColor);
    setDownColor(props.localChartSetting.Volume_Option.option.downColor);
    setHeight(props.localChartSetting.Volume_Option.option.height);
  }, [props.localChartSetting]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    setUpColor(props.localChartSetting.Volume_Option.option.upColor);
    setDownColor(props.localChartSetting.Volume_Option.option.downColor);
    setHeight(props.localChartSetting.Volume_Option.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setUpColor(props.ChgThemeData === "Light" ? "F5475B" : "F5475B");
    setDownColor(props.ChgThemeData === "Light" ? "39C46E" : "39C46E");
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "V-up":
        setUpColor(props.selectedColor);
        break;
      case "V-down":
        setDownColor(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    refIsColorChange.current = true;
    props.onColorClick(color, type, title);
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("Volume")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.Volume")} (Volume)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("Volume")}
        />
      </div>

      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-Volume">
            <div className="taparamlbl">{t("chart5.whenThePriceRises")}</div>
            <button
              id="msi-ta-Volume-vu-color"
              className="taparam colorpicker"
              value={upColor}
              data-colorpickerdialogtitle="価格上昇時"
              style={{ backgroundColor: "#" + upColor }}
              onClick={() =>
                onColorClick(upColor, "V-up", t("chart5.whenThePriceRises"))
              }
            ></button>
          </div>

          <div className="menusubitem" id="msi-ta-Volume">
            <div className="taparamlbl">{t("chart5.whenThePriceFalls")}</div>
            <button
              id="msi-ta-Volume-vd-color"
              className="taparam colorpicker"
              value={downColor}
              data-colorpickerdialogtitle="価格下降時"
              style={{ backgroundColor: "#" + downColor }}
              onClick={() =>
                onColorClick(downColor, "V-down", t("chart5.whenThePriceFalls"))
              }
            ></button>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-Volume">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-Volume-ps-h"
                defaultValue="1"
                value={height}
                onChange={(e) => onHeightChange(e)}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
