/*
    Developer: Parker Suen
    This hook is for  catching the query string from URL request
*/

import React from "react";
import { useLocation } from "react-router-dom";

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQueryString;
