import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '../common'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighStocks from 'highcharts/highstock'
HighchartsMore(Highcharts)

class Candlestick extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  onLoadData(){
    const {id,chartData,ChgThemeData,bodyW,mobilebodyW,title}=this.props
    HighStocks.stockChart(id,{
      chart: {
        backgroundColor: ChgThemeData=='Light'?'#FCFCFC':'#000000',
        spacingRight:14,
        spacingTop:14,
        spacingBottom:20
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      xAxis: {
        showFirstLabel:true,
        showLastLabel:true,
        gridLineWidth:1,
        gridLineColor:ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C',
        tickWidth: 0,
        lineWidth:0,
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%m/%d',
          week: '%m/%d',
          month: '%m/%Y',
          year: '%m/%Y'
        },
        labels: {
          style: {
            color: '#828282',
            fontSize:'10px',
            opacity:.9,
            fontFamily: 'Roboto'
          }
        },
        crosshair: {
          width:2,
          color: ChgThemeData=='Light'?'#E0E0E0':'#1C1C1C',
          dashStyle:'shotdash'
        }
      },
      yAxis: {
        showFirstLabel:true,
        showLastLabel:true,
        gridLineWidth:1,
        gridLineColor:ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C',
        lineWidth:1,
        lineColor:ChgThemeData=='Light'?'#F3F3F3':'#1C1C1C',
        title: {
          text: ''
        },
        opposite: true,
        labels: {
          x:4,
          y:2,
          align: 'left',
          style: {
            color: '#828282',
            fontSize:'10px',
            opacity:.9,
            fontFamily: 'Roboto'
          },
          formatter(){
            return common.transThousands(this.value)
          }
        }
      },
      tooltip:{
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        borderWidth: 0,
        style: {
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto'
        },
        valueDecimals:2
      },
      series: [
        {
          type: 'candlestick',
          name: title,
          color: ChgThemeData=='Light'?'#FFFFFF':'#39C46E',
          lineColor: ChgThemeData=='Light'?'#333333':'#39C46E',
          upColor: ChgThemeData=='Light'?'#333333':'#F5475B',
          upLineColor: ChgThemeData=='Light'?'#333333':'#F5475B',
          maxPointWidth: 40,
          data: chartData[0]
        },
        {
          type: 'line',
          name: '5 SMA',
          color: '#9064CD',
          lineColor: '#9064CD',
          lineWidth:1,
          data:chartData[1]
        },
        {
          type: 'line',
          name: '25 SMA',
          color: '#00D0D4',
          lineColor: '#00D0D4',
          lineWidth:1,
          data:chartData[2]
        }
      ]
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,ChgThemeData,isTradeShow,isSettingShow,isToggleOn}=this.props
    if(
        ChgThemeData!==newProps['ChgThemeData']||
        // bodyW!==newProps['bodyW']||
        isTradeShow!==newProps['isTradeShow']||
        isSettingShow!==newProps['isSettingShow']||
        isToggleOn!==newProps['isToggleOn']
      ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(Candlestick)