import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import useIsFirstRender from "../../Hooks/useIsFirstRender";
import common from "../../../asserts/js/common";
import useInterval from "../../Hooks/useInterval";
import audioAlert from "../../../asserts/audio/Bell.mp3";
import AlertsModal from "./AlertsModal";
import { ReactComponent as IconAlert } from "../../../asserts/imgs/svg/bx_bx-bell-outline-plus.svg";
import { ReactComponent as IconAlertWhite } from "../../../asserts/imgs/svg/bx_bx-bell-outline-plus-white.svg";

import styles from "./AlertsCountButton.less";
import APPstyles from "../../../App.less";
import { 
  bellOffImg,
  bellOnImg
} from '../../../asserts/imgs/common/index'
/*
  props:
  isAccountPage - boolean - special handling style on Account page
  disableHover - boolean - disable hover change color (actually change img)
*/
const AlertsCountButton = (props) => {
  const isFirstRender = useIsFirstRender();
  const { ChgThemeData, isAccountPage, alertsStore, disableHover } = props;

  // const [count, setCount] = useState(alertsStore.count);
  const [alertImg, setAlertImg] = useState(bellOffImg);
  const [audio, setAudio] = useState(new Audio(audioAlert));
  const [isOpen, setIsOpen] = useState(
    alertsStore.isNotification === true ? true : false
  );
  // const [isFirstFetch, setIsFirstFetch] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 10000);

  const fetchData = () => {
    let token = window.sessionStorage.getItem("token");

    fetch(common.getHttp + "alert/notification-count", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          if (alertsStore.count !== res.count && res.count !== 0) {
            alertsStore.setCount(res.count);
            alertsStore.isSound && audio.play();

            // popup modal if alert exists in first fetch
            if (alertsStore.isFirstFetch && alertsStore.isNotification) {
              setIsOpen(true);
            }
          }
        }

        alertsStore.isFirstFetch && alertsStore.setIsFirstFetch();
      })
      .catch((err) => {
        if (alertsStore.count !== 0) {
          alertsStore.setCount(0);
        }
        alertsStore.isFirstFetch && alertsStore.setIsFirstFetch();
      });
  };

  const onClickAlertsCountButton = () => {
    props.callbackAlertsShow("alerts", undefined, 2, true);
  };

  return (
    <>
      <div
        className={[
          APPstyles.alert_con,
          styles.alerts_count_button,
          isAccountPage ? styles.account_page : "",
        ].join(" ")}
        onClick={onClickAlertsCountButton}
        style={{ marginRight: props.isTabletMenu ? "0" : undefined }}
      >
        <IconAlert
          className={[
            styles.img,
            ChgThemeData === "Light" ? styles.light : "",
            !props.disableHover ? styles.img_hover : "",
          ].join(" ")}
        />
        {/* <img
          src={alertImg}
          className={[APPstyles.img24, styles.img].join(" ")}
          alt="add"
          onMouseOver={() => {
            !props.disableHover &&
              setAlertImg(ChgThemeData == "Light" ? bellOffImg : bellOnImg);
          }}
          onMouseOut={() => {
            !props.disableHover && setAlertImg(bellOffImg);
          }}
        /> */}
        {alertsStore.count !== 0 && (
          <span
            className={
              alertsStore.count < 10 ? APPstyles.nums : APPstyles.nums_rect
            }
          >
            {alertsStore.count > 99 ? "99+" : alertsStore.count}
          </span>
        )}
      </div>
      {isOpen && <AlertsModal {...props} setIsOpen={setIsOpen} />}
    </>
  );
};

export default observer(AlertsCountButton);
