import React from 'react';
import { Button,message,Input } from 'antd';
import { withTranslation } from 'react-i18next';
import common from '@/asserts/js/common';
import { bgImg,eyeOnImg,eyeOffImg } from '@/asserts/imgs/login/index';
import { logoLightImg,warningImg,closeImg } from '@/asserts/imgs/common/index';
import Appstyles from '@/App.less';
import styles from './index.less';

class UpdatePwdIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      updateData:{
        pwdVal:'',newPwdVal:'',comfirmPwdVal:'',
        isPwdShow:false,isNewPwdShow:false,isConfirmPwdShow:false,
        pwdValTip:'請再次輸入密碼',
        comfirmPwdValTip:'請輸入新密碼',
        comfirmPwdValTip:'再次確認新密碼',
      },
      tipsData:[
        {value:'請勿使用生日、電話、身份證字號之內容為密碼,以保障交易安全性。'},
        {value:'密碼長度最少為7~12位混合英數字之組合(字母有大小寫之區)。'},
        {value:'不得設定為連續數字或字元,例如:123456、abcdefg。'},
        {value:'不得設定為相同之重複數字或字元,例如:111111、888888。'},
        {value:'密碼不得設定與身分證字號相同。'},
        {value:'設定的密碼不得與上次設定之密碼相同。'},
      ],
      isTipVisible:true,
    }
  }
  InputonChange(sign,e){
    const {updateData}=this.state;
    updateData[sign]=e['target']['value'];
    this.setState({
      updateData,
    });
  }
  // 是否可见
  chgEysClick(e,sign){
    const{updateData}=this.state;
    e.stopPropagation();
    updateData[sign]=!updateData[sign];
    this.setState({
      updateData,
    });
  }
  onTipsClick(){
    this.setState({
      isTipVisible:false,
    });
  }
  updateClick(sign){
    const {updateData}=this.state;
    if(sign==='update'){
      for (let key in updateData) {
        if (key === 'pwdVal' || key === 'newPwdVal' || key === 'comfirmPwdVal') {
          if (updateData[key] === '') {
            message.warning({content: updateData[key + 'Tip']});
            return;
          }
        }
      }
      if (updateData['newPwdVal'] !== updateData['comfirmPwdVal']) {
        message.warning({content: '輸入的新密碼要一致'});
        return;
      }
      this.updatePwdAPI(sign);
    }else{
      this.props.cbApplyData(sign);
    }
  }
  // 密碼變更
  updatePwdAPI(sign){
    const {updateData}=this.state;
    fetch(common.getHttpZs+'changePassword',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        old_pass:updateData['pwdVal'],
        new_pass:updateData['newPwdVal'],
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        message.warning({
          content: res['extra_message'],
        });
        this.props.cbApplyData(sign);
      } else if(res['status']==901) {
        message.warning({
          content:res['error']['message'],
        });
      } else {
        message.warning({
          content:res['message'],
        });
      }
    })
  }
  render(){
    const {isPC,isMobile} = this.props;
    const{updateData,isTipVisible,tipsData}=this.state;
    return (
      <div 
        className={[Appstyles.WHPer100].join(' ')}
        style={{
          backgroundImage:common.isTestShow?'':'url('+bgImg+')',
          backgroundSize:isPC?'100% 100%':isMobile?'210% 100%':'152% 100%',
          justifyContent:isMobile?'center':'flex-start',
          backgroundColor:common.isTestShow?'#222429':'',
        }}
      >
        <div style={{height:'100%'}} className={Appstyles.flex_column}>
          <div className={[styles.updatePwdCon,Appstyles.flex_column,!isPC&&styles.updatePwdConM,!isPC&&Appstyles.flex1_auto].join(' ')}>
            <img 
              src={logoLightImg} 
              className={[
                styles.logo,
                isMobile?styles.logoMobile:'',
              ].join(' ')}
              style={{display:common.isTestShow?'none':''}}
            />
              <div className={[styles.updatePwdChild,isMobile&&styles.updatePwdChildMobile,isTipVisible?Appstyles.flex1_auto:''].join(' ')}>
                <span className={styles.title}>修改密碼</span>
                <div>
                  <div className={[Appstyles.flex_topcenter,styles.pwdCon].join(' ')}>
                    <Input 
                      type={updateData['isPwdShow']?'text':'password'}
                      autoComplete='off' 
                      placeholder='請再次輸入密碼'
                      value={updateData['pwdVal']} 
                      onChange={(e)=>this.InputonChange('pwdVal',e)}
                    />
                    <img src={updateData['isPwdShow']?eyeOnImg:eyeOffImg} className={[Appstyles.img24,Appstyles.transformY].join(' ')} onClick={(e) => this.chgEysClick(e,'isPwdShow')} />
                  </div>
                  <div className={[Appstyles.flex_topcenter,styles.pwdCon].join(' ')}>
                    <Input 
                      type={updateData['isNewPwdShow']?'text':'password'}
                      autoComplete='off' 
                      placeholder='請輸入新密碼'
                      value={updateData['newPwdVal']} 
                      onChange={(e)=>this.InputonChange('newPwdVal',e)}
                    />
                    <img src={updateData['isNewPwdShow']?eyeOnImg:eyeOffImg} className={[Appstyles.img24,Appstyles.transformY].join(' ')} onClick={(e) => this.chgEysClick(e,'isNewPwdShow')} />
                  </div>
                  <div className={[Appstyles.flex_topcenter,styles.pwdCon].join(' ')}>
                    <Input 
                      type={updateData['isConfirmPwdShow']?'text':'password'}
                      autoComplete='off' 
                      placeholder='再次確認新密碼'
                      value={updateData['comfirmPwdVal']} 
                      onChange={(e)=>this.InputonChange('comfirmPwdVal',e)}
                    />
                    <img src={updateData['isConfirmPwdShow']?eyeOnImg:eyeOffImg} className={[Appstyles.img24,Appstyles.transformY].join(' ')} onClick={(e) => this.chgEysClick(e,'isConfirmPwdShow')} />
                  </div>
                  {isTipVisible &&
                    <div className={styles.tipsCon}>
                      <div className={[styles.tipsTitle,Appstyles.flex_between].join(' ')}>
                        <span className={Appstyles.flex_row} style={{alignItems:'center'}}><img src={warningImg} className={Appstyles.img24} style={{cursor:'default'}} />注意事項</span>
                        <img src={closeImg} className={Appstyles.img24} onClick={() => this.onTipsClick()} />
                      </div>
                      {tipsData.map((item,index) => {
                        return <div key={index} className={[Appstyles.flex_row,styles.contents].join(' ')}>
                                <span>{index+1}.</span>
                                <span>{item['value']}</span>
                              </div> 
                        })
                      }
                    </div>
                  }
                </div>
                <div className={[Appstyles.flex_row,styles.btnCon].join(' ')}>
                  <Button className={[Appstyles.bg_grey,Appstyles.btn_gray].join(' ')} style={{marginRight:4}} onClick={() => this.updateClick('cancel')}>取消</Button>
                  <Button className={[Appstyles.bg_blue,Appstyles.btn_blue].join(' ')} style={{marginLeft:4}} onClick={() => this.updateClick('update')}>確認</Button>
                </div>
              </div>
            {isPC&&<span className={styles.sign}>© 富邦綜合證券股份有限公司</span>}
          </div>
          {!isPC&&<span className={[styles.sign,Appstyles.flex_center].join(' ')}>© 富邦綜合證券股份有限公司</span>}
        </div>
      </div>
  )}
}
export default withTranslation()(UpdatePwdIndex);