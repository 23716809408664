import common from './common'
// candlestick
const candlestickData={
  chart: {
    backgroundColor: '',
    height: 287,
    spacingRight:14,
    spacingTop:14,
    spacingBottom:20
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  exporting: {
    enabled: false
  },
  rangeSelector: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  scrollbar: {
    enabled: false
  },
  xAxis: {
    showFirstLabel:true,
    showLastLabel:true,
    gridLineWidth:1,
    gridLineColor:'',
    tickWidth: 0,
    lineWidth:0,
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%m/%d',
      week: '%m/%d',
      month: '%m/%Y',
      year: '%m/%Y'
    },
    labels: {
      style: {
        color: '',
        fontSize:'10px',
        opacity:.9
      }
    },
    crosshair: {
      width:2,
      color: '',
      dashStyle:'shotdash'
    }
  },
  yAxis: {
    showFirstLabel:true,
    showLastLabel:true,
    gridLineWidth:1,
    gridLineColor:'',
    lineWidth:1,
    lineColor:'',
    title: {
      text: ''
    },
    opposite: true,
    labels: {
      x:4,
      y:2,
      align: 'left',
      style: {
        color: '',
        fontSize:'10px',
        opacity:.9
      },
      formatter(){
        return common.transThousands(this.value)
      }
    }
  },
  tooltip:{
    backgroundColor:'',
    borderWidth: 0,
    style: {
      color:''
    },
    valueDecimals:2,
    // pointFormatter(){
    //   console.log(this.y);
    // }
  },
  series: [
    {
      type: 'candlestick',
      name: '',
      color: '',
      lineColor: '',
      upColor: '',
      upLineColor: '',
      maxPointWidth: 40,
      data: []
    },
    {
      type: 'line',
      name: '5 SMA',
      color: '#9064CD',
      lineColor: '#9064CD',
      lineWidth:1,
      data:[]
    },
    {
      type: 'line',
      name: '25 SMA',
      color: '#00D0D4',
      lineColor: '#00D0D4',
      lineWidth:1,
      data:[]
    }
  ]
}
// line
const lineData={
  chart: {},
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  legend:{
    enabled: false
  },
  xAxis: {
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    crosshair: {
      width: 0
    }
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    tickPositions: []
  },
  tooltip:{
    enabled: false
  },
  plotOptions: {
    series: {
      connectNulls:true,
      states: {
        select: {
          enabled: false
        },
        hover: {
          enabled: false
        }
      },
      marker: {
        radius: 0,
        states: {
          select: {
            enabled: false
          },
          hover: {
            enabled: false
          }
        }
      }
    }
  },
  series: []
}
// area
const areaData={
  chart: {
		type: 'area',
		backgroundColor: '',
		height: 35,
		width:48,
    spacing:[0, 0, 1, 0]
	},
	credits: {
		enabled: false
	},
	title: {
		text: ''
	},
	legend:{
		enabled: false
	},
	exporting: {
		enabled: false
	},
	xAxis: {
		visible: false
	},
	yAxis: {
		title: {
			text: ''
		},
		labels: {
			enabled: false
		},
		gridLineWidth: 0,
		// plotLines: [{
		// 	color: '',
		// 	width: 1,
		// 	value: 0,
    //   dashStyle: 'ShortDash'
		// }]
	},
	tooltip:{
		enabled: false
	},
	plotOptions: {
		series: {
			connectNulls:true,
			states: {
				select: {
					enabled: false
				},
				hover: {
					enabled: false
				}
			},
			marker: {
				radius: 0,
				states: {
					select: {
						enabled: false
					},
					hover: {
						enabled: false
					}
				}
			}
		}
	},
	series: []
}
export default{
  candlestickData:candlestickData,
  lineData:lineData,
  areaData:areaData
}
