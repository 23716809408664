import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const ICH = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(9);
  const [color, setColor] = useState("009999");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enable2, setEnable2] = useState(true);
  const [period2, setPeriod2] = useState(26);
  const [color2, setColor2] = useState("BB1100");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [enable3, setEnable3] = useState(true);
  const [color3, setColor3] = useState("CC9900");
  const [thickness3, setThickness3] = useState(2);
  const [style3, setStyle3] = useState("s");
  const [showTracker3, setShowTracker3] = useState(true);

  const [enable4, setEnable4] = useState(true);
  const [period4, setPeriod4] = useState(52);
  const [color4, setColor4] = useState("FF00FF");
  const [thickness4, setThickness4] = useState(2);
  const [style4, setStyle4] = useState("s");
  const [showTracker4, setShowTracker4] = useState(true);

  const [enable5, setEnable5] = useState(true);
  const [color5, setColor5] = useState("FFAAAA");
  const [thickness5, setThickness5] = useState(2);
  const [style5, setStyle5] = useState("s");
  const [showTracker5, setShowTracker5] = useState(true);

  const [upColor, setUpColor] = useState("DDDDDD");
  const [downColor, setDownColor] = useState("DDDDDD");
  const [value, setValue] = useState(period + "," + period2 + "," + period4);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.ICH_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      const o1 = localChartSetting.option.options[0]
      const o2 = localChartSetting.option.options[1]
      const o3 = localChartSetting.option.options[2]
      const o4 = localChartSetting.option.options[3]
      const o5 = localChartSetting.option.options[4]

      let option1 = "";
      let option2 = "";
      let option3 = "";
      let option4 = "";
      let option5 = "";
      let new_value = "";

      option1 = {
        period: o1.period,
        color: o1.color,
        thickness: o1.thickness,
        style: o1.style,
        showTracker: o1.showTracker,
        enabled: o1.enabled,
      };

      option2 = {
        period: o2.period,
        color: o2.color,
        thickness: o2.thickness,
        style: o2.style,
        showTracker: o2.showTracker,
        enabled: o2.enabled,
      };

      option3 = {
        color: o3.color,
        thickness: o3.thickness,
        style: o3.style,
        showTracker: o3.showTracker,
        enabled: o3.enabled,
      };

      option4 = {
        period: o4.period,
        color: o4.color,
        thickness: o4.thickness,
        style: o4.style,
        showTracker: o4.showTracker,
        enabled: o4.enabled,
      };

      option5 = {
        color: o5.color,
        thickness: o5.thickness,
        style: o5.style,
        showTracker: o5.showTracker,
        enabled: o5.enabled,
      };

      let options = [option1, option2, option3, option4, option5];
      new_value = o1.period + "," + o2.period + "," + o4.period;
      setValue(new_value);
      props.onOverlayValueChange({
        ta: "ICH",
        name: `${t("chart5.TA.ICH")} (ICH)`,
        option: { options: options, upColor: localChartSetting.option.upColor, downColor: localChartSetting.option.downColor },
        value: new_value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const ICH = props.localChartSetting.ICH_Option

    const o1 = ICH.option.options[0]
    const o2 = ICH.option.options[1]
    const o3 = ICH.option.options[2]
    const o4 = ICH.option.options[3]
    const o5 = ICH.option.options[4]

    setEnable(o1.enabled);
    setPeriod(parseInt(o1.period) || period);
    setColor(o1.color);
    setThickness(parseInt(o1.thickness) || thickness);
    setStyle(o1.style);
    setShowTracker(o1.showTracker);

    setEnable2(o2.enabled);
    setPeriod2(parseInt(o2.period) || period2);
    setColor2(o2.color);
    setThickness2(parseInt(o2.thickness) || thickness2);
    setStyle2(o2.style);
    setShowTracker2(o2.showTracker);

    setEnable3(o3.enabled);
    setColor3(o3.color);
    setThickness3(parseInt(o3.thickness) || thickness3);
    setStyle3(o3.style);
    setShowTracker3(o3.showTracker);

    setEnable4(o4.enabled);
    setPeriod4(parseInt(o4.period) || period4);
    setColor4(o4.color);
    setThickness4(parseInt(o4.thickness) || thickness4);
    setStyle4(o4.style);
    setShowTracker4(o4.showTracker);

    setEnable5(o5.enabled);
    setColor5(o5.color);
    setThickness5(parseInt(o5.thickness) || thickness5);
    setStyle5(o5.style);
    setShowTracker5(o5.showTracker);

    setUpColor(ICH.upColor);
    setDownColor(ICH.downColor);
  }, [props.localChartSetting]);

  useEffect(() => {
    let option1 = "";
    let option2 = "";
    let option3 = "";
    let option4 = "";
    let option5 = "";
    let new_value = "";

    option1 = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    option2 = {
      period: period2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
      enabled: enable2,
    };

    option3 = {
      color: color3,
      thickness: thickness3,
      style: style3,
      showTracker: showTracker3,
      enabled: enable3,
    };

    option4 = {
      period: period4,
      color: color4,
      thickness: thickness4,
      style: style4,
      showTracker: showTracker4,
      enabled: enable4,
    };

    option5 = {
      color: color5,
      thickness: thickness5,
      style: style5,
      showTracker: showTracker5,
      enabled: enable5,
    };

    let options = [option1, option2, option3, option4, option5];
    new_value = period + "," + period2 + "," + period4;
    setValue(new_value);
    props.onOverlayValueChange({
      ta: "ICH",
      name: `${t("chart5.TA.ICH")} (ICH)`,
      option: { options: options, upColor: upColor, downColor: downColor },
      value: new_value,
    });
  }, [
    period,
    color,
    thickness,
    style,
    showTracker,
    enable,
    period2,
    color2,
    thickness2,
    style2,
    showTracker2,
    enable2,
    color3,
    thickness3,
    style3,
    showTracker3,
    enable3,
    period4,
    color4,
    thickness4,
    style4,
    showTracker4,
    enable4,
    color5,
    thickness5,
    style5,
    showTracker5,
    enable5,
    upColor,
    downColor,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const ICH = props.localChartSetting.ICH_Option

    const o1 = ICH.option.options[0]
    const o2 = ICH.option.options[1]
    const o3 = ICH.option.options[2]
    const o4 = ICH.option.options[3]
    const o5 = ICH.option.options[4]

    setEnable(o1.enabled);
    setPeriod(parseInt(o1.period) || period);
    setColor(o1.color);
    setThickness(parseInt(o1.thickness) || thickness);
    setStyle(o1.style);
    setShowTracker(o1.showTracker);

    setEnable2(o2.enabled);
    setPeriod2(parseInt(o2.period) || period2);
    setColor2(o2.color);
    setThickness2(parseInt(o2.thickness) || thickness2);
    setStyle2(o2.style);
    setShowTracker2(o2.showTracker);

    setEnable3(o3.enabled);
    setColor3(o3.color);
    setThickness3(parseInt(o3.thickness) || thickness3);
    setStyle3(o3.style);
    setShowTracker3(o3.showTracker);

    setEnable4(o4.enabled);
    setPeriod4(parseInt(o4.period) || period4);
    setColor4(o4.color);
    setThickness4(parseInt(o4.thickness) || thickness4);
    setStyle4(o4.style);
    setShowTracker4(o4.showTracker);

    setEnable5(o5.enabled);
    setColor5(o5.color);
    setThickness5(parseInt(o5.thickness) || thickness5);
    setStyle5(o5.style);
    setShowTracker5(o5.showTracker);

    setUpColor(ICH.upColor);
    setDownColor(ICH.downColor);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(9);
    setColor("009999");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnable2(true);
    setPeriod2(26);
    setColor2("BB1100");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);

    setEnable3(true);
    setColor3("CC9900");
    setThickness3(2);
    setStyle3("s");
    setShowTracker3(true);

    setEnable4(true);
    setPeriod4(52);
    setColor4("FF00FF");
    setThickness4(2);
    setStyle4("s");
    setShowTracker4(true);

    setEnable5(true);
    setColor5("FFAAAA");
    setThickness5(2);
    setStyle5("s");
    setShowTracker5(true);

    setUpColor("DDDDDD");
    setDownColor("DDDDDD");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p1":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "p2":
        if (enable2) setEnable2(false);
        else setEnable2(true);
        break;
      case "p3":
        if (enable3) setEnable3(false);
        else setEnable3(true);
        break;
      case "p4":
        if (enable4) setEnable4(false);
        else setEnable4(true);
        break;
      case "p5":
        if (enable5) setEnable5(false);
        else setEnable5(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "p1":
        setStyle(e.target.value);
        break;
      case "p2":
        setStyle2(e.target.value);
        break;
      case "p3":
        setStyle3(e.target.value);
        break;
      case "p4":
        setStyle4(e.target.value);
        break;
      case "p5":
        setStyle5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "p1":
        setPeriod(e.target.value);
        break;
      case "p2":
        setPeriod2(e.target.value);
        break;
      case "p4":
        setPeriod4(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "p1":
        setThickness(e.target.value);
        break;
      case "p2":
        setThickness2(e.target.value);
        break;
      case "p3":
        setThickness3(e.target.value);
        break;
      case "p4":
        setThickness4(e.target.value);
        break;
      case "p5":
        setThickness5(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "p1":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "p2":
        if (showTracker2) setShowTracker2(false);
        else setShowTracker2(true);
        break;
      case "p3":
        if (showTracker3) setShowTracker3(false);
        else setShowTracker3(true);
        break;
      case "p4":
        if (showTracker4) setShowTracker4(false);
        else setShowTracker4(true);
        break;
      case "p5":
        if (showTracker5) setShowTracker5(false);
        else setShowTracker5(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "ICH-C1":
        setColor(props.selectedColor);
        break;
      case "ICH-C2":
        setColor2(props.selectedColor);
        break;
      case "ICH-C3":
        setColor3(props.selectedColor);
        break;
      case "ICH-C4":
        setColor4(props.selectedColor);
        break;
      case "ICH-C5":
        setColor5(props.selectedColor);
        break;
      case "ICH-up":
        setUpColor(props.selectedColor);
        break;
      case "ICH-down":
        setDownColor(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("ICH")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.ICH")} (ICH) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("ICH")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-ICH"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.turningLine")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ICH-tenkan-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "ICH-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-KC-mid-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable2 ? {} : { opacity: "0.5" }}
          id="msi-ta-ICH"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.referenceLine")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ICH-kijun-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "ICH-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-KC-mid-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable3 ? {} : { opacity: "0.5" }}
          id="msi-ta-ICH"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable3 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p3")}
            ></div>
            <div className="taparamlbl">
              {t("chart5.leading")} {t("chart5.span")} 1
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color3}
              style={{ backgroundColor: "#" + color3 }}
              onClick={() =>
                onColorClick(color3, "ICH-C3", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness3}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p3")}>
              <select id="msi-ta-KC-mid-style" value={style3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
        <div
          className="menusubitem checkbox selected"
          style={enable4 ? {} : { opacity: "0.5" }}
          id="msi-ta-ICH"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable4 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p4")}
            ></div>
            <div className="taparamlbl">
              {t("chart5.leading")} {t("chart5.span")} 2
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ICH-senkouspanb-period0"
                type="number"
                step="1"
                min="1"
                value={period4}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p4")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color4}
              style={{ backgroundColor: "#" + color4 }}
              onClick={() =>
                onColorClick(color4, "ICH-C4", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness4}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p4")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p4")}>
              <select id="msi-ta-KC-mid-style" value={style4}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker4 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p4")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable5 ? {} : { opacity: "0.5" }}
          id="msi-ta-ICH"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable5 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p5")}
            ></div>
            <div className="taparamlbl">
              {t("chart5.lagging")} {t("chart5.span")}
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color5}
              style={{ backgroundColor: "#" + color5 }}
              onClick={() =>
                onColorClick(color5, "ICH-C5", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness5}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p5")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p5")}>
              <select id="msi-ta-KC-mid-style" value={style5}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker5 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p5")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-ICH">
            <div className="taparamlbl">{t("chart5.risingClouds")}</div>
            <button
              id="msi-ta-ICH-upcloud-color"
              className="taparam colorpicker"
              value={upColor}
              style={{ backgroundColor: "#" + upColor }}
              onClick={() =>
                onColorClick(upColor, "ICH-up", t("chart5.risingClouds"))
              }
            ></button>
          </div>

          <div className="menusubitem" id="msi-ta-ICH">
            <div className="taparamlbl">{t("chart5.fallingClouds")}</div>
            <button
              id="msi-ta-ICH-downcloud-color"
              className="taparam colorpicker"
              value={downColor}
              style={{ backgroundColor: "#" + downColor }}
              onClick={() =>
                onColorClick(downColor, "ICH-down", t("chart5.fallingClouds"))
              }
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};
