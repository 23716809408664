import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  leftImg,
  leftLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Left extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTimeShow:false,
      categoryImg:dropDownImg,
      timeImg:dropDownImg,
      timeData:[
        {
          name:'Any Time',
          key:'all'
        },
        {
          name:'Today',
          key:'today'
        },
        {
          name:'Past Week',
          key:'last_week'
        },
        {
          name:'Past Month',
          key:'last_month'
        }
      ],
      timeIndex:0,
      newsIndex:0
    }
    this.myLeftRef = React.createRef()
    this.myListRef = React.createRef()
  }
  //点击外部关闭下拉框
  handleTimeClick = e => {
    if(!this.testTimeComponent.contains(e.target)) {
      this.setState({
        isTimeShow:false,
        timeImg:dropDownImg
      })
    }
  }
  // 回到顶部--left
  goBackTopLeftFun(){
    let current=this.myLeftRef.current
    current.scrollTop=0
  }
  // 回到顶部--list
  goBackTopListFun(){
    let current=this.myListRef.current
    current.scrollTop=0
  } 
  //页面滚动触发的事件
  handleScroll(){
    let getRef=this.myListRef.current
    this.setState({
      isTimeShow:false
    })
    if(Number(getRef.scrollHeight - getRef.scrollTop- getRef.clientHeight) < 10){
      this.props.cbScrollDataFun()
    }
  }
  //页面滚动触发的事件
  handleScroll2(){
    let getRef=this.myLeftRef.current
    this.setState({
      isTimeShow:false
    })
    if(Number(getRef.scrollHeight - getRef.scrollTop- getRef.clientHeight) < 10){
      this.props.cbScrollDataFun()
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isCriteriaShow,isResultShow,isChgListIndexShow,isSearchPageShow,isSaveShow}=this.props
    if(isCriteriaShow!==newProps['isCriteriaShow']){
      if(!isCriteriaShow){
        this.setState({
          categoryImg:dropDownImg
        })
      }
    } 
    if(isResultShow!==newProps['isResultShow']||isChgListIndexShow!==newProps['isChgListIndexShow']){
      this.setState({
        newsIndex:0
      })
      this.goBackTopListFun()
    }
    // 回到顶部
    if(isSearchPageShow!==newProps['isSearchPageShow']||isSaveShow!==newProps['isSaveShow']){
      this.goBackTopLeftFun()
      this.goBackTopListFun()
    }
    if(isSaveShow!==newProps['isSaveShow']){
      this.setState({
        newsIndex:0
      })
    } 
  }
  //初始化数据
  componentDidMount() {
    document.addEventListener('click', this.handleTimeClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleTimeClick)
  }
  render() {
    const {t,isPC,isTablet,isMobile,ChgThemeData,isCriteriaShow,isResultShow,isLoading,loadMore,noMore,newsData,newTag} = this.props
    const {categoryImg,timeImg,timeData,timeIndex,newsIndex,isTimeShow}=this.state
    return (
      <div 
        ref={this.myLeftRef} 
        onScroll={() => newTag!=='quote'&&isMobile&&this.handleScroll2()}
        className={[
          newTag!=='quote'&&isMobile?'':APPstyles.flex_column,
          newTag!=='quote'&&isMobile?'':APPstyles.HPer100,
          newTag!=='quote'&&isMobile&&!isResultShow?APPstyles.flex1_auto:'',
          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          (newTag=='quote'&&isTablet)||(newTag!=='quote'&&isMobile)?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
          isMobile&&isResultShow&&APPstyles.position_fixed,
          isMobile&&isResultShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
        ].join(' ')}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        <div className={[styles.news_top,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')} style={{display:isResultShow?'none':''}}>
          {newTag!=='quote'&&<div 
            className={[
              styles.news_dropdown_header,
              APPstyles.flex_between,
              ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black
            ].join(' ')}
            onClick={(e)=>{
              e.stopPropagation()
              this.setState(state => ({
                isTimeShow:false,
                timeImg:dropDownImg
              }))
              this.props.cbCriteriaShow()
            }}
            onMouseOver={()=>{
              if(!isPC) return
              let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
              this.setState({
                categoryImg:imgurl
              })
            }} 
            onMouseOut={()=>{
              if(!isPC) return
              let imgurl=isCriteriaShow?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
              this.setState({
                categoryImg:imgurl
              })
            }}
          >
            <span>{t('Select Category')}</span>
            <img
              src={categoryImg}
              alt='dropdown'
              className={[isCriteriaShow ? styles.imgRotate : '',styles.select_img].join(' ')}
            />
          </div>}
          <div
            ref = {testComponent => this.testTimeComponent = testComponent}  
            className={[
              styles.news_dropdown_header,
              APPstyles.flex_between,
              ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black
            ].join(' ')}
            style={{
              marginBottom:newTag=='quote'?0:8
            }}
            onClick={(e)=>{
              e.stopPropagation()
              this.setState(state => ({
                isTimeShow: !state.isTimeShow,
                categoryImg:dropDownImg
              }))
              this.props.cbCloseCategoryFun()
            }}
            onMouseOver={()=>{
              if(!isPC) return
              let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
              this.setState({
                timeImg:imgurl
              })
            }} 
            onMouseOut={()=>{
              if(!isPC) return
              let imgurl=isTimeShow?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
              this.setState({
                timeImg:imgurl
              })
            }}
          >
            <span>{t(timeData[timeIndex]['name'])}</span>
            <img
              src={timeImg}
              alt='dropdown'
              className={[isTimeShow ? styles.imgRotate : '',styles.select_img].join(' ')}
            />
            <div 
              className={[
                styles.news_dropdown_con,
                ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_star_off
              ].join(' ')}
              style={{
                display:isTimeShow?'':'none'
              }}
            >
              {timeData.map((item,index) => {
                return  <span
                          key={index}
                          className={[
                            ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black,
                            timeIndex==index?ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_event_black:''
                          ].join(' ')}
                          onClick={(e)=>{
                            e.stopPropagation()
                            this.setState({
                              isTimeShow:false,
                              timeIndex:index
                            })
                            this.props.cbChgTimeKeyFun(item['key'])
                          }}
                        >
                          {t(item['name'])}
                        </span>
              })}
            </div>
          </div>
          {newTag!=='quote'&&<div className={[styles.news_reset,ChgThemeData=='Light'?APPstyles.reset_light:''].join(' ')}>
            <span onClick={()=>{
              this.setState({
                timeIndex:0
              }) 
              this.props.cbResetDataFun() 
            }}
              >{t('Reset All')}</span>
          </div>}
        </div>
        <div 
          className={[
            styles.news_result_con,
            APPstyles.flex_topcenter,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
          style={{display:isResultShow?'':'none'}}
        >
          <img 
            src={ChgThemeData=='Light'?leftLightImg:leftImg} 
            className={APPstyles.img24} 
            onClick={()=>{
              this.props.cbCloseResultFun() 
            }} 
            alt='back' 
          />
          {t('Search Result')}
        </div>
        <div
          ref={this.myListRef} 
          onScroll={() => this.handleScroll()}
          className={[
            isMobile&&!isResultShow?'':APPstyles.flex1_auto,
            APPstyles.paddingBottom20
          ].join(' ')}
        >
          {newsData.length?
            newsData.map((item,index) => {
              return  <div 
                        key={index}
                        className={[
                          styles.news_list_one,
                          APPstyles.flex_column,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                          newsIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark:'',
                          isMobile?APPstyles.fz16:''
                        ].join(' ')}
                        onClick={()=>{
                          this.setState({
                            newsIndex:index
                          })
                          if(newTag=='quote'&&isTablet){
                            this.props.cbRightDetailShowFun(item,isLoading)
                            return
                          }
                          this.props.cbRightDataFun(item)
                          this.props.cbDetailShowFun()
                        }}
                      >
                        <span>{item['title']}</span>
                        <span>{item['sorting_date_tw']}</span>
                      </div>
              })
            :
              <div className={[APPstyles.isLoading,APPstyles.color_grey].join(' ')} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
          }
          <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
          <div className={APPstyles.loading_more} style={{display:noMore?'':'none'}}>- {t('No More Data Available')} -</div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Left)