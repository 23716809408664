import React from 'react';
import { withTranslation } from 'react-i18next';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  render() {
    const {t,ChgThemeData,newDropDownData,sign,selectItems,isCampaign} = this.props;
    return (
      <div 
        className={[
          styles.campagin_DropDown,
          APPstyles.flex_column,
          ChgThemeData=='Light'?APPstyles.dropDown_light:APPstyles.dropDown_black,
        ].join(' ')} 
        style={{display:selectItems['isShow']?'':'none',left:sign=='market'?0:8}}
      >
        {newDropDownData.map((item) => {
          return  <span 
                    key={item['id']}
                    className={[
                      APPstyles.btn_black,
                      APPstyles.flex_center,
                      ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                      ChgThemeData=='Light'?styles.spanlight_hover:APPstyles.bg_1b1b1b_hover,
                      selectItems['id']==item['id']?ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_event_black:'',
                    ].join(' ')}
                    style={{display:isCampaign&&item['id']=='OPTIONAL'?'none':''}}
                    onClick={(e)=>{
                      e.stopPropagation();
                      this.props.cbDropDownItem(item);
                    }}
                  >
                    {t(item['name'])}
                  </span>
        })}
      </div>
    )
  }
}
export default withTranslation()(Index);