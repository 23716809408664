import React from 'react'
import { Tooltip,Input } from 'antd'
import { LoadingOutlined,RightOutlined,LeftOutlined,PlusCircleOutlined,MinusCircleOutlined,CloseOutlined} from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import NASDAQPDF from '../../asserts/pdf/NASDAQ.pdf'

import Appstyles from '../../App.less'
import styles from './Apply.less'

import Apply from './Apply'
import Disclaimer from './Disclaimer'
import DisclaimerRead from './DisclaimerRead'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

let scaleNum=1

class ApplyComponents extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isDisclaimerShow:false,
      isDisclaimerReadShow:false,
      pageNumber: 1,
      pageNumberInput: 1,
      pageNumberFocus: false,
      numPages: 1,
      pageWidth: 600,
      isPDFshow:false,
      isShowData:[true,false,false,false]
    }
  }
  //pdf--显示
  callbackPDFOpen(){
    const {bodyW,mobilebodyW} = this.props
    let getW=bodyW>mobilebodyW?600:bodyW<=320?250:300
    this.setState({
      isShowData:[false,true,false,false],
      pageWidth:getW,
      pageNumber:1,
      pageNumberInput:1
    })
  }
  //免责声明--read--显示
  callbackReadOpen(){
    this.setState({
      isShowData:[false,false,true,false]
    })
  }
  //免责声明--read/pdf--隐藏
  callbackClose(){
    this.setState({
      isShowData:[true,false,false,false]
    })
  }
  //送出申請--apply.js
  callbackSend(){
    this.setState({
      isShowData:[false,false,false,true]
    })
  }
  //進入海外股市新平台--Disclaimer.js
  callbackGoinPage(){
    const {uid,aid,locationName}=this.props
    window.sessionStorage.setItem('uid',uid)
    window.sessionStorage.setItem('aidListId',aid)
    // loging页面
    this.props.callbackUid('uid')
    this.props.callbackpathname('/'+locationName[1]+'/Quote')
  }
  //上一页
  lastPage = () => {
    if (this.state.pageNumber == 1) {
      return
    }
    const page = this.state.pageNumber - 1
    this.setState({ pageNumber: page ,pageNumberInput:page})
  }
  //下一页
  nextPage = () => {
    if (this.state.pageNumber == this.state.numPages) {
      return
    }
    const page = this.state.pageNumber + 1
    this.setState({ pageNumber: page ,pageNumberInput:page})
  }
  //input--页数
  onPageNumberFocus = e => {
    this.setState({ pageNumberFocus: true })
  }
  //input--页数
  onPageNumberBlur = e => {
    this.setState({ pageNumberFocus: false ,pageNumberInput:this.state.pageNumber})
  }
  //input--改变页数
  onPageNumberChange = e => {
    let value=e.target.value
    value=value<=0?1:value
    value=value>=this.state.numPages?this.state.numPages:value
    this.setState({ pageNumberInput: value })
  }
  //input--页数
  toPage = e => {
    this.setState({ pageNumber: Number(e.target.value) })
  }
  //缩小
  pageZoomOut = () => {
    if (this.state.pageWidth <= 600) {
      return
    }
    const pageWidth = this.state.pageWidth * 0.8
    this.setState({ pageWidth: pageWidth })
  }
  //放大
  pageZoomIn = () => {
    const pageWidth = this.state.pageWidth * 1.2
    this.setState({ pageWidth: pageWidth })
  }
  //总页数
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages })
  }
  getScaleNumFun(){
    const {bodyW,minbodyW,mobilebodyW,bodyH} = this.props

    if(bodyW>minbodyW){
      scaleNum=bodyH>950?1:.9
    }else if(bodyW>mobilebodyW&&bodyW<=minbodyW){
      scaleNum=bodyH>900?1:.8
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW} = this.props

    if(bodyW!==newProps['bodyW']){
      this.getScaleNumFun()
    }
  }
  //初始化
  componentDidMount(){
    this.getScaleNumFun()
  }
  render(){
    const {bodyW,mobilebodyW} = this.props
    const {pageNumber,pageNumberFocus,pageNumberInput,numPages,pageWidth,isShowData} = this.state
    return (
      <div className={styles.apply_con}>
        {
          isShowData[0]?
            <Apply 
              {...this.props}
              callbackSend={this.callbackSend.bind(this)} 
              callbackReadOpen={this.callbackReadOpen.bind(this)} 
              callbackPDFOpen={this.callbackPDFOpen.bind(this)} 
            />
          :isShowData[1]?
            <div 
              className={[
                Appstyles.flex_column,
                styles.app_pdfcon
              ].join(' ')}
              style={{
                width:bodyW>mobilebodyW?600:'100%'
              }}
            >
              <CloseOutlined 
                className={styles.app_PDFclose} 
                style={{
                  top:bodyW>mobilebodyW?36:10,
                  right:bodyW>mobilebodyW?36:10,
                  fontSize:bodyW>mobilebodyW?30:18
                }}
                onClick={this.callbackClose.bind(this)}
               />
              <Document
                file={NASDAQPDF}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={<LoadingOutlined />}
              >
                <Page 
                  pageNumber={pageNumber} 
                  width={pageWidth} 
                  scale={scaleNum}
                  loading={<LoadingOutlined />} 
                />
              </Document>
              <div 
                className={[
                  Appstyles.flex_row,
                  styles.app_pdfcon_pageTool
                ].join(' ')}
                style={{
                  bottom:bodyW>mobilebodyW?30:20,
                  right:bodyW>mobilebodyW?46:20
                }}
              >
                <Tooltip title={pageNumber == 1 ? '已是第一页' : '上一页'}>
                  <LeftOutlined onClick={this.lastPage} />
                </Tooltip>
                <Input 
                  type='number' 
                  value={pageNumberFocus ? pageNumberInput : pageNumber}
                  onFocus={this.onPageNumberFocus}
                  onBlur={this.onPageNumberBlur}
                  onChange={this.onPageNumberChange}
                  onPressEnter={this.toPage} 
                  style={{width:80}}
                /> 
                <span className={styles.app_pdfcon_pageAll}>/ {numPages}</span>
                <Tooltip title={pageNumber == numPages ? '已是最后一页' : '下一页'}>
                  <RightOutlined onClick={this.nextPage} />
                </Tooltip>
                <Tooltip title='缩小'>
                  <MinusCircleOutlined onClick={this.pageZoomOut} />
                </Tooltip>
                <Tooltip title='放大'>
                  <PlusCircleOutlined onClick={this.pageZoomIn} />
                </Tooltip>
              </div>
            </div>
          :isShowData[2]?
            <DisclaimerRead 
              {...this.props}
              callbackClose={this.callbackClose.bind(this)}
            />
          :isShowData[3]?
            <Disclaimer 
              {...this.props}
              callbackGoinPage={this.callbackGoinPage.bind(this)} 
            />
          :''
        }
      </div> 
  )}
}
export default withTranslation()(ApplyComponents)