import React from 'react'
import { withTranslation } from 'react-i18next'
import { Spin,Tooltip } from 'antd'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  helpImg,
  leftImg,
  leftLightImg
} from '@/asserts/imgs/common/index'
import { 
  tradeOffImg
} from '@/asserts/imgs/nav/index'
import Disclaimer from '../disclaimer/index'
import APPstyles from '@/App.less'
import styles from '../index.less'

class Mobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      minW450:450,
      minW490:490,
      minW320:320,
      isScrollTabletShow:false,
      loginName:''
    }
    this.myTabletRef = React.createRef()
    this.myOneHeaderRef = React.createRef()
  }
  // 动态更改头部的paddingright
  handleScrollTablet(){
    const {isMobile,isTablet,isSearchPageShow}=this.props
    if(isMobile||(isTablet&&!isMobile&&isSearchPageShow)) return
    let current=this.myTabletRef.current
    if(current){
      this.setState({
        isScrollTabletShow:current.scrollHeight>current.clientHeight?true:false
      })
    } 
  }
  detailShowClick(item){
    this.props.detailShowClick(item)
  }
  //回到顶部
  cbGobackTopFun(){
    let current=this.myTabletRef.current
    if(current){
      this.myTabletRef.current.scrollTop=0
    } 
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {isLoading,tabIndex,isSearchPageShow,bodyW}=this.props
    if(
      isLoading!==newProps['isLoading']||
      tabIndex!==newProps['tabIndex']||
      isSearchPageShow!==newProps['isSearchPageShow']||
      bodyW!==newProps['bodyW']
    ){
      this.handleScrollTablet()
      this.cbGobackTopFun()
    }
  }
  // 初始化
  componentDidMount(){ 
    this.setState({
      loginName:sessionStorage.getItem('loginName')
    })
    this.props.cbHeaderStickyShowFun()
  }
  render() {
    const {t,bodyW,tableData,changeName,isLoading,ChgThemeData,ChgLangData,isTablet,isMobile,store,folioInfoData,isSearchPageShow,headerStickyShow,sortData,chgTab} = this.props
    const {minW450,minW490,minW320,isScrollTabletShow,loginName}=this.state
    return (
      <div className={isMobile?'':isSearchPageShow?'':APPstyles.flex1_hidden}>
          <div 
            className={[
              styles.account_table,
              isMobile||(isTablet&&!isMobile&&isSearchPageShow)?styles.account_tableMobile:'',
              (isTablet&&!isMobile&&isSearchPageShow)?APPstyles.paddingBottom40:'',
              isMobile?'':isSearchPageShow?'':APPstyles.hPer_overflowH,
              isMobile?'':isSearchPageShow?'':APPstyles.flex_column
            ].join(' ')}
          >
            {/* 加载中 */}
            <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
            {tableData.length>0?
              tableData.map((item,index) => {
              return  <div 
                        key={item} 
                        className={[
                          isMobile?'':isSearchPageShow?'':APPstyles.hPer_overflowH,
                          isMobile?'':isSearchPageShow?'':APPstyles.flex_column
                        ].join(' ')}
                      >
                        <div 
                          className={[
                            APPstyles.flex_between,
                            styles.account_table_oneheader,
                            isMobile&&headerStickyShow?styles.sticky_con:'',
                            isMobile&&headerStickyShow&&loginName=='notFound'?styles.sticky_top_0:''
                          ].join(' ')}
                        >
                          <div 
                            ref={this.myOneHeaderRef} 
                            className={[
                              APPstyles.flex_topcenter,
                              APPstyles.flex_1,
                              ChgThemeData=='Light'?styles.table_oneheader_light:styles.table_oneheader_dark,
                              (isMobile&&headerStickyShow)||(isTablet&&!isMobile&&!isSearchPageShow)?styles.paddingRight_header_con:''
                            ].join(' ')}
                            style={{
                              padding:isMobile?'0 8px':'0 16px'
                            }}
                          >
                            <img src={item['img']} className={APPstyles.img16} style={{marginLeft:0,marginRight:8}} alt='flag' />
                            <span>{t(item['country'])}{ChgLangData=='zh-CN'?'':' '}{t('Securities')} {'('+item['len']+')'}</span>
                          </div>
                        </div>
                        <div className={[styles.account_table_body,isMobile?'':isSearchPageShow?'':APPstyles.flex_column,isMobile?'':isSearchPageShow?'':APPstyles.hPer_overflowH].join(' ')}>
                        <div 
                          className={[
                            isMobile&&headerStickyShow?styles.sticky_con:'',
                            isMobile&&headerStickyShow?styles.sticky_top_1:'',
                            isMobile&&headerStickyShow&&loginName=='notFound'?styles.sticky_top_2:''
                          ].join(' ')}
                        >
                          <div 
                            className={[
                              APPstyles.flex_row,
                              styles.account_table_bodyheader,
                              styles.account_table_bodycommon,
                              isTablet&&!isMobile?styles.account_table_bodycommon_min:'',
                              isMobile?styles.account_table_bodycommon_mobile:'',
                              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                              bodyW<=minW320&&ChgLangData=='zh-CN'?styles.fz11_zhcn:'',
                              isMobile?styles.header_mobile:'',
                              isMobile&&headerStickyShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:'',
                              isMobile?ChgThemeData=='Dark'?styles.header_mobileD:styles.header_mobileW:''
                            ].join(' ')}
                            style={{
                              display:index==0?'':'none',
                              margin:(isMobile&&headerStickyShow)||(isTablet&&!isMobile&&!isSearchPageShow&&isScrollTabletShow)?common.isiOS?'0 8px':'0 17px 0 8px':0
                            }}
                          >
                            <div 
                              style={{
                                width:bodyW<=minW490?ChgLangData=='zh-CN'?item['len']==0?'26%':'20%':item['len']==0?'22%':'16%':isTablet&&!isMobile?item['len']==0?'41%':'37%':item['len']==0?'38%':'32%'
                              }}
                            >
                              {t(changeName)}
                            </div>
                            <div 
                              className={styles.account_header_totalcost} 
                              style={{
                                position:'relative',
                                right:bodyW<=minW320&&ChgLangData=='zh-CN'?14:'unset',
                                width:bodyW<=minW490?ChgLangData=='zh-CN'?'25%':'29%':'18%'
                              }}
                            >
                              <span style={{marginBottom:2}}>{t('Prev Day QTY')}</span>
                              <span>{t('Current QTY')}</span>
                              <Tooltip 
                                title={t("Previous day quantity is the number of stock held in the portfolio as of previous day, excluding the number of stocks bought or sold today. If you have any questions, please contact customer service center: 0800-073-588")} 
                                overlayClassName={ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm}
                              >
                                <img
                                  src={helpImg}
                                  className={[APPstyles.img16,APPstyles.transformY].join(' ')}
                                  style={{
                                    position:'absolute',
                                    right:-8,
                                    display:ChgLangData==='zh-CN'?'none':isMobile?'none':'',
                                  }}
                                  alt="tips"
                                />
                              </Tooltip>
                            </div>
                            <div className={APPstyles.flex_column} style={{position:'relative',right:bodyW<=minW320&&ChgLangData=='zh-CN'?11:'unset'}}>
                              <span>{t('Purchase Price1')}</span>
                              <span>{t('Last Price2')}</span>
                              <Tooltip 
                                title={t("Purchase price displays the average purchase as of previous day, not taking into consideration for stocks bought/sold today. If you have any questions, please contact customer service center: 0800-073-588")} 
                                overlayClassName={[
                                  ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
                                  bodyW>minW450?'':APPstyles.tooltipcon_custurm_right
                                ].join(' ')}
                              >
                                <img
                                  src={helpImg}
                                  className={[APPstyles.img16,APPstyles.transformY].join(' ')}
                                  style={{
                                    position:'absolute',
                                    right:-10,
                                    display:isMobile&&ChgLangData!=='zh-CN'?'none':'',
                                  }}
                                  alt="tips"
                                />
                              </Tooltip>
                            </div>
                            <div className={APPstyles.flex_row} style={{width:bodyW<=minW490?'28%':'23%',justifyContent:'flex-end',alignItems:'center'}}>
                              <div className={APPstyles.flex_column}>
                                <span>{t(chgTab=='US'?'Unrealised P/L1':'Unrealised P/L')}</span>
                                <span>{t('P/L Ratio')}</span>
                              </div>
                              <div 
                                className={[
                                  APPstyles.upDown_con,
                                  APPstyles.flex_column
                                ].join(' ')}
                              >
                                <div 
                                  className={APPstyles.img_dropdown_up}
                                  onClick={()=>{
                                    this.props.cbSortClickFun('unrealised_pl','asc','unrealised')
                                  }} 
                                >
                                  <img src={sortData[1]['upImg']} />
                                </div>
                                <div 
                                  className={APPstyles.img_dropdown_down} 
                                  onClick={()=>{
                                    this.props.cbSortClickFun('unrealised_pl','desc','unrealised')
                                  }} 
                                >
                                  <img src={sortData[1]['downImg']} />
                                </div>
                              </div>
                            </div>
                            <div style={{display:item['len']==0?'none':''}}>&nbsp;</div>
                          </div>
                        </div>
                        <div 
                          ref={this.myTabletRef} 
                          onScroll={() => this.handleScrollTablet()}
                          className={[isMobile?'':isSearchPageShow?'':APPstyles.flex1_auto].join(' ')} 
                          style={{padding:isMobile?0:isSearchPageShow?0:'0 8px'}}
                        >
                          {item['data'].map((itemData,itemIndex) => {
                            let total = 0,upl = 0,pl_ratio = 0,cost = 0
                            folioInfoData[itemIndex]['total_amount']=total
                            folioInfoData[itemIndex]['unrealised_pl']=upl
                            folioInfoData[itemIndex]['total_pl_ratio']=pl_ratio
                            store.folioInfoData=folioInfoData
                            return  <div key={itemIndex}> 
                            {
                              itemData['datalist'].map((list,i) => {
                              let data=store.getDataByRic(list['stock_id'])
                              if (item['country']==='US') {
                                data=store.getDataByRic(list['stock_code'] + '.NB')
                              }
                              let TRDPRC_1=data&&(typeof(data['TRDPRC_1'])=='undefined'||data['TRDPRC_1']==null?0:Number(data['TRDPRC_1']))
                              let idv_total=data&&(list['current_qty']*TRDPRC_1*(1-list['fee_rate']))
                              let idv_upl=data&&(list['current_qty']*(TRDPRC_1*(1-list['fee_rate'])-list['purchase_price']))
                              let idv_cost=data&&(list['current_qty'] * list['purchase_price'])
                              let idv_ratio=idv_cost==0?0:data&&(idv_upl / idv_cost * 100)
                              idv_ratio=idv_ratio=='Infinity'||isNaN(idv_ratio)?0:idv_ratio
                              total+=idv_total
                              upl+=idv_upl
                              cost+=idv_cost
                              pl_ratio=cost==0?0:(upl/cost*100)
                              folioInfoData[itemIndex]['total_amount']=total
                              folioInfoData[itemIndex]['unrealised_pl']=upl
                              folioInfoData[itemIndex]['total_pl_ratio']=pl_ratio
                              store.folioInfoData=folioInfoData
                              return  <div key={i}>
                                        <div 
                                          className={[
                                            APPstyles.flex_row,
                                            styles.account_table_bodyBody,
                                            styles.account_table_bodycommon,
                                            isTablet&&!isMobile?styles.account_table_bodycommon_min:'',
                                            isMobile?styles.account_table_bodycommon_mobile:'',
                                            ChgThemeData=='Light'?styles.account_table_bodyBody_light:'',
                                            isMobile?APPstyles.fz17:''
                                          ].join(' ')}
                                          style={{
                                            borderBottom:list['isShow']?
                                                          ChgThemeData=='Light'?'1px solid #FFFFFF':'1px solid #222429'
                                                        :
                                                          ChgThemeData=='Light'?'1px solid #E0E0E0':'1px solid #373737'
                                          }}
                                        >
                                          <div 
                                            className={APPstyles.flex_column} 
                                            style={{
                                              width:bodyW<=minW490?'27%':isTablet&&!isMobile?'37%':'32%'
                                            }}
                                            onClick={()=>{
                                              this.props.cbGotoQuotePageFun(list)
                                            }}
                                          >
                                            <span className={APPstyles.flex_topcenter}>
                                              <label className={APPstyles.fw700} style={{marginBottom:list['currency']?0:2}}>{list['stock_code']}</label>
                                              <label className={styles.account_name_cur} style={{marginBottom:list['currency']?2:0}}>{list['currency']}</label>
                                            </span>
                                            <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.ellipsis,isMobile?APPstyles.fz14:''].join(' ')}>{list['stock_name']}</span>
                                          </div>
                                          <div className={APPstyles.flex_column} style={{position:'relative'}}>
                                            <span style={{marginBottom:2}}>{common.transThousands(common.keepNum(list['current_qty'],0))}</span>
                                            <span>{common.transThousands(common.keepNum(list['current_avail_qty'],0))}</span>
                                            <img 
                                              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                                              className={[
                                                APPstyles.img18,
                                                list['isShow']?styles.dropDown_img_up:styles.dropDown_img_down
                                              ].join(' ')} 
                                              onClick={()=>{
                                                this.props.dropDownClick(itemIndex,i)
                                              }}
                                            />
                                          </div>
                                          <div className={APPstyles.flex_column}>
                                            <span style={{marginBottom:2}}>{common.transThousands(common.keepNum(list['purchase_price'],2))}</span>
                                            <span>{common.transThousands(common.keepNum(TRDPRC_1,2))}</span>
                                          </div>
                                          <div className={APPstyles.flex_column} style={{width:bodyW<=minW490?'28%':'23%',paddingRight:isMobile||(isSearchPageShow&&!isMobile)?6:0}}>
                                            <span 
                                              className={[
                                                Number(idv_upl)>0?APPstyles.color_red:Number(idv_upl)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                isMobile?list['unrealised_pl_len']>8?isMobile?APPstyles.fz12:APPstyles.fz11:list['unrealised_pl_len']==8?isMobile?APPstyles.fz13:APPstyles.fz12:isMobile?APPstyles.fz17:APPstyles.fz16:''
                                              ].join(' ')}
                                            >
                                              {common.addplusNum(common.transThousands(common.keepNum(idv_upl,2)))}
                                            </span>
                                            <span className={Number(idv_ratio)>0?APPstyles.color_red:Number(idv_ratio)<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                                              {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(idv_ratio,2))))}
                                            </span>
                                          </div>
                                          <div>
                                            <img src={tradeOffImg} className={APPstyles.img24} onClick={this.detailShowClick.bind(this,list)} alt='more' />
                                          </div>
                                        </div>
                                        {/* dropdown内容 */}
                                        <div
                                          className={[
                                            APPstyles.flex_row,
                                            styles.account_table_bodyBody,
                                            styles.account_table_bodycommon,
                                            isTablet&&!isMobile?styles.account_table_bodycommon_min:'',
                                            isMobile?styles.account_table_bodycommon_mobile:'',
                                            ChgThemeData=='Light'?styles.account_table_bodyBody_light:'',
                                            isMobile?APPstyles.fz17:''
                                          ].join(' ')}
                                          style={{
                                            display:list['isShow']?'':'none'
                                          }}
                                        >
                                          <span style={{width:bodyW<=minW490?'27%':isTablet&&!isMobile?'37%':'32%'}}>&nbsp;</span>
                                          <div className={APPstyles.flex_column} style={{textAlign:'right'}}>
                                            <span 
                                              className={[
                                                isMobile?ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white:APPstyles.color_grey,
                                                APPstyles.fz12
                                              ].join(' ')} 
                                              style={{marginBottom:2}}
                                            >
                                              {t('Currency2')}
                                              </span>
                                            <span className={APPstyles.fz14}>{t(list['currency'])}</span>
                                          </div>
                                          <div className={APPstyles.flex_column} style={{textAlign:'right'}}>
                                            <span 
                                              className={[
                                                isMobile?ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white:APPstyles.color_grey,
                                                APPstyles.fz12
                                              ].join(' ')} 
                                              style={{marginBottom:2}}
                                            >
                                              {t('Total Cost2')}
                                              </span>
                                            <span>{common.transThousands(common.keepNum(idv_cost,2))}</span>
                                          </div>
                                          <div className={APPstyles.flex_column} style={{textAlign:'right',width:bodyW<=minW490?'28%':'23%',paddingRight:isMobile||(isSearchPageShow&&!isMobile)?6:0}}>
                                            <span 
                                              className={[
                                                isMobile?ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white:APPstyles.color_grey,
                                                APPstyles.fz12
                                              ].join(' ')} 
                                              style={{marginBottom:2}}
                                            >
                                              {t('Total Amount')}
                                            </span>
                                            <span 
                                              style={{marginBottom:2}}
                                              className={
                                                isMobile?list['total_amount_len']>8?isMobile?APPstyles.fz12:APPstyles.fz11:list['total_amount_len']==8?isMobile?APPstyles.fz13:APPstyles.fz12:isMobile?APPstyles.fz17:APPstyles.fz16:''
                                              }
                                            >
                                              {common.transThousands(common.keepNum(idv_total,2))}
                                            </span>
                                          </div>
                                          <span>&nbsp;</span>
                                        </div>
                                      </div>
                              })
                            }
                            <div
                              className={itemIndex==(item['data'].length-1)?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark}
                              style={{
                                paddingBottom:itemIndex==item['data'].length-1?0:24
                              }}
                            >
                              <div 
                                className={[
                                  APPstyles.flex_row,
                                  styles.account_table_bodycommon,
                                  styles.account_table_bodyLast,
                                  isTablet&&!isMobile?styles.account_table_bodyLast_min:'',
                                  isMobile?styles.account_table_bodyLast_mobile:''
                                ].join(' ')}
                              >
                                <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{width:item['len']==0?isMobile?'66%':'66%':isMobile?'60%':'62%'}}>{t('Total')} ({t(itemData['currency'])})</div>
                                <div 
                                  className={APPstyles.flex_column}
                                  style={{
                                    paddingRight:isMobile||(isSearchPageShow&&!isMobile)?6:0
                                  }}
                                >
                                  <span className={folioInfoData[itemIndex]['unrealised_pl']>0?APPstyles.color_red:folioInfoData[itemIndex]['unrealised_pl']<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{marginBottom:2}}>
                                    {common.addplusNum(common.transThousands(common.keepNum(folioInfoData[itemIndex]['unrealised_pl'],2)))}
                                  </span>
                                  <span className={Number(folioInfoData[itemIndex]['total_pl_ratio'])>0?APPstyles.color_red:Number(folioInfoData[itemIndex]['total_pl_ratio'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} >{common.changeRate(common.addplusNum(common.transThousands(common.keepNum(folioInfoData[itemIndex]['total_pl_ratio'],2))))}</span>
                                </div>
                                <div style={{display:item['len']==0?'none':'',width:item['len']==0?'auto':isMobile?'6%':'4%'}}>&nbsp;</div>
                              </div>
                            </div>
                          </div>
                          })}
                          {(isTablet&&!isMobile&&!isSearchPageShow&&index==0)&&
                            <Disclaimer {...this.props} />
                          }
                        </div>
                      </div>
                    </div>
              })
              :
              <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>   
            }
          </div>
      </div>
    )
  }
}
export default withTranslation()(observer(Mobile))