import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  searchImg,
  searchOnImg,
  searchOnLightImg,
  dropDownImg
} from '@/asserts/imgs/common/index'
import Header from '@/components/Navigation/Header/index'
import Slider from '@/components/Navigation/Slider/index'
import SlidingPanel from '@/components/Navigation/SlidingPanel/index'
import Tabs from '@/components/Tabs/index'
import OrderMask from '@/components/Trade/OrderMask/index'
import SearchCon from '@/components/Quote/Add/index'
import Left from './left/index'
import Right from './right/index'
import CriteriaCon from '@/components/Quote/Screener/criteria/index'
import TipsModal from '@/components/Quote/Screener/tipsModal/index'
import AlertsCountButton from '@/components/Alerts/components/AlertsCountButton'
import APPstyles from '@/App.less'
import styles from '../Account/index.less'

let page=1

class News extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex : 0,
      tabsData:[
        {
          name:'News',
          isShow:false,
          id:'news'
        }
      ],
      sliderIndex:'account',
      ChgLangData:'zh-CN',
      ChgThemeData:'Dark',
      isSlidingPanelShow:false,
      tradeData:[false,false,false,false],
      tradeOrderData:[],
      accountTradeData:[],
      isTradeShow:true,
      IsSubmitOrder:false,
      isSearchPageShow:false,
      isShowHeaderPage:true,
      isOpenAlertModalInTabletMode: false,
      chgSearchImg:searchImg,
      getLeftW:400,
      getCriteriaH:600,
      isCriteriaShow:false,
      listData:[
        {
          title:'US Stocks',
          key:'US',
          isSelcetAll:true,
          isSelcetAllTemp:true,
          isShowAll:true,
          isResultChecked:true,
          dropDownImg:dropDownImg,
          data:[
            {
              name:'US Stocks',
              checked:true,
              checkedTemp:true,
              key:831
            },
            {
              name:'US Stocks Radar',
              checked:true,
              checkedTemp:true,
              key:897
            }
          ]
        },
        {
          title:'International Shares',
          key:'InternationalShares',
          isSelcetAll:true,
          isSelcetAllTemp:true,
          isShowAll:true,
          isResultChecked:true,
          dropDownImg:dropDownImg,
          data:[
            {
              name:'Eurasian Stocks',
              checked:true,
              checkedTemp:true,
              key:832
            },
            {
              name:'International Politics And Economics',
              checked:true,
              checkedTemp:true,
              key:833
            }
          ]
          },
          {
          title:'Commodity',
          key:'Commodity',
          isSelcetAll:true,
          isSelcetAllTemp:true,
          isShowAll:true,
          isResultChecked:true,
          dropDownImg:dropDownImg,
          data:[
            {
              name:'Gold',
              checked:true,
              checkedTemp:true,
              key:840
            },
            {
              name:'Energy',
              checked:true,
              checkedTemp:true,
              key:841
            },
            {
              name:'Agriculture',
              checked:true,
              checkedTemp:true,
              key:842
            },
            {
              name:'Raw Material',
              checked:true,
              checkedTemp:true,
              key:843
            },
            {
              name:'Bond',
              checked:true,
              checkedTemp:true,
              key:845
            },
            {
              name:'Index',
              checked:true,
              checkedTemp:true,
              key:887
            },
            {
              name:'Stocks1',
              checked:true,
              checkedTemp:true,
              key:888
            }
          ]
        }
      ],
      ischeckAll:false,
      isClickBtn:false,
      isResetAll:false,
      isDetailShow:false,
      isResultShow:false,
      isChgListIndexShow:false,
      isLoading:true,
      getTimeVal:'all',
      getStock:'',
      getCategories:[],
      recperpage:15,
      pn:1,
      isSaveShow:false,
      newsData:[],
      loadMore:false,
      noMore:false,
      rightData:'',
      isClearSearch:false,
      isTipsModalShow:false,
      isChgRightDataShow:false,
      watchListData:[],
      isWatchlistShow:true,
      tradeName:'',
      isSwitchModalShow:false,
    }
    this.myNewLeftRef = React.createRef()
  }
  // 获取路由跳转--app.js
  callbackPathnameApp(data){
    this.props.callbackPathnameApp(data)
  }
  //slider--更改navIndex
  //added param isClose, using for close trade modal, slider nav icon rearranged and it conflict between old index and nex index
  callbackChgNavIndex(id,isClose){
    let tradeData=!isClose&&(id=='trade'||id=='alerts')?[true,true,false,false]:[false,false,false,false]
    if(!isClose&&id==='trade'){this.setState({isOpenAlertModalInTabletMode:false})}
    if(!isClose&&id==='alerts'){this.setState({isOpenAlertModalInTabletMode:true})}
    this.setState({
      sliderIndex:id,
      tradeData:tradeData
    })
  }
  //是否显示左侧的menu
  callbackShowSlidingPanel(data){
    this.setState({
      isSlidingPanelShow:data
    })
  }
  //trade--show--portfolio
  tradeShowClick(item){
    const {isTradeShow}=this.state
    this.setState(state => ({
      isTradeShow: !state.isTradeShow,
      accountTradeData:[item,isTradeShow],
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: false,
    }))
  }
  //alert--show--portfolio
  alertsShowClick(item){
    const {isTradeShow}=this.state
    this.setState(state => ({
      isTradeShow: !state.isTradeShow,
      accountTradeData:[item,isTradeShow],
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: true,
    }))
  }
  // 处理keyData的数据
  dealKeyDataFun(item){
    let keyData=[]
    for(let i in item){
      let data=item[i]['data']
      for(let j in data){
        if(data[j]['checked']){
          keyData.push(data[j]['key'])
        }
      }
    }
    return keyData
  }
  //处理数据--弹框--确认
  dealCriteriaDataFun(item,stock,time){
    const {isSaveShow,recperpage}=this.state
    page=1
    this.setState({
      pn:1,
      getStock:stock,
      isSaveShow:!isSaveShow
    })
    let keyData=this.dealKeyDataFun(item)
    if(!keyData.length){
      this.setState({
        isTipsModalShow: true
      })
      return
    }
    this.setState({
      getCategories: keyData,
      isCriteriaShow:false,
      isLoading:true
    })
    this.dealAPIDataFun(stock,keyData,time,recperpage,page,[],false)
  }
  // 选择search数据
  chgSearchDataFun(data){
    const {isSaveShow,isChgListIndexShow,getTimeVal,getCategories,recperpage}=this.state
    page=1
    let symbol=data['symbol']
    this.setState({
      isSearchPageShow:false,
      chgSearchImg:searchImg,
      isResultShow:true,
      isChgListIndexShow:!isChgListIndexShow,
      getStock:symbol,
      pn:1,
      isSaveShow:!isSaveShow
    })
    this.dealAPIDataFun(symbol,getCategories,getTimeVal,recperpage,page,[],false)
  }
  // 关闭search页面--清空stock，然后重新执行api
  chgCloseSearchFun(){
    const {isSaveShow,getTimeVal,getCategories,recperpage}=this.state
    page=1
    this.setState({
      chgSearchImg:searchImg,
      isSearchPageShow:false,
      isResultShow:false,
      getStock:'',
      pn:1,
      isSaveShow:!isSaveShow,
      isLoading: true
    })
    this.dealAPIDataFun('',getCategories,getTimeVal,recperpage,page,[],false)
  }
  // 选择时间数据
  chgTimeKeyFun(key){
    const {isSaveShow,getCategories,getStock,recperpage}=this.state
    page=1
    this.setState({
      getTimeVal: key,
      pn:1,
      isSaveShow:!isSaveShow,
      isLoading: true
    })      
    this.dealAPIDataFun(getStock,getCategories,key,recperpage,page,[],false)
  }
  //滚动加载更多
  chgScrollDataFun(){
    const {loadMore,noMore,isLoading,newsData,getCategories,getStock,recperpage,getTimeVal}=this.state
    if(loadMore||noMore||isLoading) return
    page++
    this.setState({
      pn: page,
      loadMore:true,
      noMore:false,
      isLoading: true
    })
    this.dealAPIDataFun(getStock,getCategories,getTimeVal,recperpage,page,newsData,true)
  }
  // 处理api数据
  dealAPIDataFun(stock,categories,date_option,recperpage,pn,newsData,isScroll){
    const {rightData}=this.state
    fetch(common.getHttp+'news',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        stock:stock,
        categories:categories,
        date_option:date_option,
        recperpage:recperpage,
        pn:pn
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['datalist']
        let pn1=data.length?data.length>=10?false:true:false
        let pnother=newsData.length?data.length?false:true:true
        let noMoreData=pn==1?pn1:pnother
        let getData=newsData.concat(data)
        // 转意符换成普通字符
        for(let i in getData){
          getData[i]['content']=this.escape2Html(getData[i]['content'])
        }
        this.setState({
          newsData:getData,
          isLoading: false,
          loadMore:false,
          noMore:noMoreData,
          rightData:isScroll?rightData:getData[0]
        })
        return
      }
      this.setState ({
        isLoading:false,
        newsData:[],
        rightData:'',
        loadMore:false,
        noMore:false
      })
    })
  }
  // 转意符换成普通字符
  escape2Html(str) {
    var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'}
    return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
   }
  //reset all
  resetClick(){
    const {isResetAll,listData}=this.state
    let data=this.resetListDataFun()
    page=1
    this.setState({
      isResetAll:!isResetAll,
      isLoading:true,
      getTimeVal:'all',
      listData:data,
      loadMore:false,
      noMore:false
    })
    this.dealCriteriaDataFun(listData,'','all')
  }
  //恢复为默认的数据--listData
  resetListDataFun(){
    const {listData}=this.state
    for(let i in listData){
      listData[i]['isShowAll']=true
      listData[i]['isSelcetAll']=true
      listData[i]['isSelcetAllTemp']=true
      listData[i]['isResultChecked']=true
      let data=listData[i]['data']

      for(let j in data){
        data[j]['checked']=true
        data[j]['checkedTemp']=true
      }
    }
    return listData 
  }
  //点击外部关闭弹框
  handleClick = e => {
    const {isClickBtn}=this.state
    if(!this.testComponent.contains(e.target)){
      this.setState({
        isCriteriaShow:isClickBtn?true:false
      })
      setTimeout(()=>{
        this.setState({
          isClickBtn:false
        })
      },100)
    }
  }
  // 动态更改高度
  getCriteriaHFun(){
    const {bodyH,isPC} = this.props
    this.setState({
      getCriteriaH:bodyH>850?600:isPC?'80%':'90%'
    }) 
  }
  // 动态更改categoryD的位置
  chgLeftWFun(){
    let current=this.myNewLeftRef.current
    this.setState({
      getLeftW:current.clientWidth
    })
  }
  // 动态添加/取消
  chgWatchListFun(ric,tag,id){
    const {watchListData}=this.state
    let item=watchListData.find(item=>item['watchlist_id']==id)
    let ricData=item['stock_id_list']
    // 添加
    if(tag=='add'){
      ricData.unshift(ric)
    // 删除
    }else{
      for(let i in ricData){
        if(ricData[i]==ric){
          ricData.splice(i,1)  
        }
      }
    }
    this.setWatchListDataFun(watchListData)
  }
  // watchlist---set 
  setWatchListDataFun(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        this.getWatchListFun()
      }
    })
  }
  //watchlist---get
  getWatchListFun(){
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'user-watchlist?token='+token,{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']
        this.setState({
          watchListData:data
        })
        sessionStorage.setItem('watchListData',JSON.stringify(data))
      }
    })
  }
  getWatchListClick(){
    const {isWatchlistShow,isClearSearch}=this.state
    this.setState({
      isSearchPageShow:true,
      isClearSearch:!isClearSearch
    })
    if(isWatchlistShow){
      this.setState({
        isWatchlistShow:false
      })
      this.getWatchListFun()
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,bodyH,isMobile,isNewsSCrollShow,newTag,ricData} = this.props
    const {listData,getTimeVal}=this.state
    if(bodyW!==newProps['bodyW']){
      this.chgLeftWFun() 
    }
    if(bodyH!==newProps['bodyH']&&!isMobile){
      this.getCriteriaHFun()
    }
    if(isNewsSCrollShow!==newProps['isNewsSCrollShow']&&newTag=='quote'){
     this.chgScrollDataFun() 
    }
    if(ricData!==newProps['ricData']){
      if(newTag=='quote'&&ricData&&JSON.stringify(ricData) !== '{}'){
        this.setState({
          isLoading:true
        })
        ricData&&this.dealCriteriaDataFun(listData,ricData['symbol'],getTimeVal)
      }
    }
  }
  //初始化数据
  componentDidMount() {
    const {newTag,ricData} = this.props
    const {listData,getTimeVal}=this.state
    //更改主题--setting
    let theme=localStorage.getItem('theme')
    let lang=localStorage.getItem('lang')
    let tradeName=localStorage.getItem('tradeName')
    this.setState({
      ChgThemeData:theme?theme:'Dark',
      ChgLangData:lang?lang:'zh-CN',
      isShowHeaderPage:newTag=='quote'?false:true,
      tradeName:tradeName?tradeName:'general'
    })
    this.chgLeftWFun()
    let chgStock=newTag=='quote'&&ricData&&JSON.stringify(ricData)!== '{}'?ricData['symbol']:''
    this.dealCriteriaDataFun(listData,chgStock,getTimeVal)
    document.addEventListener('click', this.handleClick) 
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }
  render() {
    const {isPC,isMobile,isTablet,newTag,currentIndex,currentData23,ChgThemeDataTemp,isRightPageShow} = this.props
    const {tabsData,sliderIndex,ChgLangData,ChgThemeData,isSlidingPanelShow,tradeData,tradeOrderData,accountTradeData,IsSubmitOrder,isSearchPageShow,isShowHeaderPage,chgSearchImg,getLeftW,getCriteriaH,isCriteriaShow,listData,ischeckAll,isResetAll,isDetailShow,isResultShow,isChgListIndexShow,isLoading,isSaveShow,loadMore,noMore,newsData,rightData,isClearSearch,getStock,isTipsModalShow,isChgRightDataShow,getTimeVal,watchListData,tradeName,isSwitchModalShow}=this.state
    let ChgThemeDataRes=newTag=='quote'?ChgThemeDataTemp:ChgThemeData
    let chgRightPageShow=(isPC&&newTag=='quote'&&currentData23&&!currentData23.includes(currentIndex)&&(isRightPageShow=='trade'||isRightPageShow=='alerts'))
    return (
      <div 
        className={[
          APPstyles.flex_column,
          APPstyles.app_layout,
          ChgThemeDataRes=='Light'?APPstyles.app_layout_light:'',
          ChgThemeDataRes=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
        ].join(' ')}
        style={{
          height:newTag=='quote'?'100%':'100vh'
        }}
      >
        {newTag!=='quote'&&
        <div className={APPstyles.sticky_top_mobile}>
          <Header 
            {...this.props}
            ChgThemeData={ChgThemeDataRes}
            sliderIndex={sliderIndex}
            isShowHeaderPage={isShowHeaderPage}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          />
          {/* header--tab */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.header_con,
              ChgThemeDataRes=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,,
              ChgThemeDataRes=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
            ].join(' ')}
            style={{
              marginLeft:isPC?72:0
            }}
          >
            <div className={[APPstyles.flex_between,APPstyles.wPer100].join(' ')}>
              <div className={[APPstyles.flex_row,styles.header_con_left,APPstyles.wPer100].join(' ')} style={{alignItems: 'center'}}>
                <img 
                  src={chgSearchImg} 
                  alt='search' 
                  className={APPstyles.img24} 
                  style={{
                    margin:'0 16px',
                    display:isMobile?'none':''
                  }}
                  onClick={this.getWatchListClick.bind(this)}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeDataRes=='Light'?searchOnLightImg:searchOnImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    let imgurl=isSearchPageShow?ChgThemeDataRes=='Light'?searchOnLightImg:searchOnImg:searchImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }}
                />
                <div 
                  className={[APPstyles.flex_row,APPstyles.flex_1,styles.header_tabs].join(' ')}
                  style={{
                    paddingLeft:isMobile?16:0,
                    paddingRight:isMobile?16:0
                  }}
                >
                  <Tabs 
                    {...this.props} 
                    tag='news'
                    tabData={tabsData}
                    ChgThemeData={ChgThemeDataRes}
                    callbackTabIndex={(index)=>{
                      // this.setState({
                      //   tabIndex:index,
                      //   isSearchPageShow:false,
                      //   chgSearchImg:searchImg
                      // })
                    }}
                  />
                </div>
                <div className={[APPstyles.img_shadow,ChgThemeDataRes=='Light'?APPstyles.img_shadow_light:''].join(' ')} style={{display:isMobile?'':'none'}}>
                  <img 
                    src={isMobile&&ChgThemeData=='Dark'?searchOnImg:chgSearchImg}
                    alt='search' 
                    className={APPstyles.img24} 
                    style={{
                      margin:'0 16px'
                    }}
                    onClick={this.getWatchListClick.bind(this)}
                  />
                </div>
              </div>
              { isPC&&<AlertsCountButton {...this.props} ChgThemeData={ChgThemeDataRes} callbackAlertsShow={this.alertsShowClick.bind(this)}/> }
            </div>
          </div>
        </div>
        }
        <div
          className={[
            APPstyles.flex_row,
            newTag=='quote'?'':isPC?APPstyles.marginLeft72:APPstyles.marginLeft0,
            isTablet&&!isMobile?APPstyles.hPer_overflowH:APPstyles.flex1_hidden
          ].join(' ')}
          style={{position:'relative'}}
        >
          {/* left */}
          <div
            ref={this.myNewLeftRef} 
            className={[
              isPC?
                currentData23&&currentData23.includes(currentIndex)||chgRightPageShow?
                  APPstyles.wPer100
                :
                  APPstyles.wPer30
              :isMobile||(newTag=='quote'&&isTablet)?
                APPstyles.wPer100
              :
                APPstyles.wPer40,
              !isSearchPageShow?'':ChgThemeDataRes=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
              isMobile&&isSearchPageShow?APPstyles.position_fixed:'',
              isMobile&&isSearchPageShow?ChgThemeDataRes=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
            ].join(' ')}
            style={{
              position:'relative',
              display:(isPC&&newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)&&isDetailShow)?'none':chgRightPageShow&&isDetailShow?'none':''
            }}
          >
            <div style={{display:isSearchPageShow?'':'none',height:'100%'}}>
              <SearchCon
                {...this.props}
                currentIndex={4}
                sliderIndex={'news'}
                ChgThemeData={ChgThemeDataRes}
                isSearchPageShow={isSearchPageShow}
                isClearSearch={isClearSearch}
                watchListData={watchListData}
                cbNewsDataPageFun={this.chgSearchDataFun.bind(this)}
                cbCloseSearchPage={this.chgCloseSearchFun.bind(this)}
                cbSetWatchListFun={this.chgWatchListFun.bind(this)}
              />
            </div>
            <div 
              className={newTag!=='quote'&&isMobile?APPstyles.flex_column:''}
              style={{
                display:isSearchPageShow?'none':'',
                height:'100%'
              }}
            >
              <Left 
                {...this.props}
                ChgThemeData={ChgThemeDataRes}
                isCriteriaShow={isCriteriaShow}
                isResultShow={isResultShow}
                isChgListIndexShow={isChgListIndexShow}
                isSearchPageShow={isSearchPageShow}
                isLoading={isLoading}
                isSaveShow={isSaveShow}
                loadMore={loadMore}
                noMore={noMore}
                newsData={newsData}
                cbResetDataFun={this.resetClick.bind(this)}
                cbChgTimeKeyFun={this.chgTimeKeyFun.bind(this)}
                cbScrollDataFun={this.chgScrollDataFun.bind(this)}
                cbRightDetailShowFun={(data,loading)=>{
                  this.props.cbRightDetailShowFun(data,loading)
                }}
                cbCriteriaShow={()=>{
                  this.setState({
                    isCriteriaShow: true
                  })
                  this.getCriteriaHFun()
                }}
                cbDetailShowFun={()=>{
                  if(isMobile||(isPC&&newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)||chgRightPageShow)){
                    this.setState({
                      isDetailShow: true
                    })
                  }
                }}
                cbCloseResultFun={()=>{
                  this.setState({
                    isSearchPageShow: true
                  })
                }}
                cbRightDataFun={(data)=>{
                  this.setState({
                    rightData: data,
                    isChgRightDataShow:!isChgRightDataShow
                  })
                }}
                cbCloseCategoryFun={()=>{
                  this.setState({
                    isCriteriaShow: false
                  })
                }}
              />
            </div>
          </div>
          {/* right--content*/}
          <div 
            className={[  
              isPC?
                (newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)&&isDetailShow)||chgRightPageShow?
                  APPstyles.wPer100
                :
                  APPstyles.wPer70
              :isMobile?
                APPstyles.wPer100
              :
                APPstyles.wPer60,
              APPstyles.flex_column,
              newTag!=='quote'&&isMobile&&isDetailShow?APPstyles.position_fixed:'',
              newTag!=='quote'&&isMobile&&isDetailShow?ChgThemeDataRes=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
            ].join(' ')}
            style={{
              display:(isPC&&newTag=='quote'&&!currentData23&&currentData23.includes(currentIndex))||(newTag!=='quote'&&!isMobile)?
                        ''
                      :
                        isMobile&&isDetailShow||
                        (isPC&&newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)&&isDetailShow)||
                        (isPC&&newTag=='quote'&&currentData23&&!currentData23.includes(currentIndex))?
                          chgRightPageShow&&!isDetailShow?'none':''
                        :
                          'none'
            }}
          >
            <Right 
              {...this.props} 
              ChgThemeData={ChgThemeDataRes}
              isLoading={isLoading}
              rightData={rightData}
              isChgRightDataShow={isChgRightDataShow}
              isDetailShow={isDetailShow}
              chgRightPageShow={chgRightPageShow}
              cbDetailCloseFun={()=>{
                this.setState({
                  isDetailShow: false
                })
              }}
            />
          </div>
          <div ref = {testComponent => this.testComponent = testComponent} style={{display:isCriteriaShow?'':'none'}}>
            <CriteriaCon 
                {...this.props}
                CriteriaTag='news'
                isCriteriaShow={isCriteriaShow}
                isResetAll={isResetAll}
                listData={listData}
                ChgThemeData={ChgThemeDataRes}
                ChgLangData={ChgLangData}
                ischeckAll={ischeckAll}
                getLeftW={getLeftW}
                getCriteriaH={getCriteriaH}
                cbCriteriaData={(data)=>{
                  this.dealCriteriaDataFun(data,getStock,getTimeVal)
                }}
                cbIsShowHeaderPage={(data)=>{
                  this.setState({
                    isShowHeaderPage:data
                  })
                }}
                cbCriteriaHide={(tag)=>{
                  if(tag=='N'){
                    this.setState({
                      isCriteriaShow: false
                    })
                  }
                }}
            />
          </div>
        </div>
        <Slider 
          {...this.props}
          sliderIndex={sliderIndex}
          ChgThemeData={ChgThemeDataRes}
          isShowHeaderPage={isShowHeaderPage}
          isSwitchModalShow={isSwitchModalShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackShowSlidingPanel={this.callbackShowSlidingPanel.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackAlertsShow={this.alertsShowClick.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        <SlidingPanel 
          {...this.props}
          ChgThemeData={ChgThemeDataRes}
          ChgLangData={ChgLangData}
          isSlidingPanelShow={isSlidingPanelShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
          }}
        />
        {/* 遮罩层 */}
        {newTag!=='quote'&&
          <OrderMask
            {...this.props} 
            tradeData={tradeData}
            tradeOrderData={tradeOrderData}
            accountTradeData={accountTradeData}
            IsSubmitOrder={IsSubmitOrder}
            ChgThemeData={ChgThemeDataRes}
            ChgLangData={ChgLangData}
            sliderIndex={sliderIndex}
            tradeName={tradeName}
            isOpenAlertModalInTabletMode={this.state.isOpenAlertModalInTabletMode}
            alertsPage={2}
            callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
            calkbackOrderData={(data)=>{
              //提交订单的数据--trade
              this.setState({
                tradeOrderData:data
              })
            }}
            calkbackBtnStatus={(data)=>{
              //哪个页面显示
              this.setState({
                tradeData:data
              })
            }}
            cbSubmitOrderFun={()=>{
              this.setState({
                IsSubmitOrder:!IsSubmitOrder,
                sliderIndex:'news'
              })
            }}
            cbSwitchMoalShow={(data)=>{
              this.setState({
                isSwitchModalShow:data,
              });
            }}
          />
        }
        {isTipsModalShow&&
          <TipsModal
            {...this.props}
            tag='news'
            ChgThemeData={ChgThemeDataRes}
            cbCloseModal={()=>{
              this.setState({
                isTipsModalShow: false,
                isClickBtn: true
              })
            }}
          />
        }
      </div>
    )
  }
}
export default withTranslation()(News)