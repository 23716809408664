import React from 'react'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import common from '@/asserts/js/common'
import { 
  tipImg,
  tipOnImg,
  tipOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Unrealised extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,bodyW,hasData,isUnrealised,ChgThemeData,isPC,isTablet,isMobile,isSearchPageShow,tipsImg2,chgCur,minW570} = this.props
    return (
      <div
        className={[
          isMobile?ChgThemeData=='Dark'?styles.msg_con_mobileD:styles.msg_con_mobileW:'',
          isUnrealised&&(!isMobile&&isTablet&&isSearchPageShow)&&styles.min_height64,
          isUnrealised?'':(isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.width_per5:'',
          isUnrealised&&isMobile&&styles.min_height62,
          isUnrealised?'':(!isMobile&&isTablet&&isSearchPageShow)?'':styles.marginRight36
        ].join(' ')}
        style={{
          display:isUnrealised?
                    (isPC&&!isSearchPageShow)||(!isMobile&&isTablet&&isSearchPageShow)||isMobile?'':'none'
                  :
                    (isPC&&!isSearchPageShow)||(!isMobile&&isTablet&&isSearchPageShow)||isMobile?'none':''
        }}
      >
        <div
          className={[
            APPstyles.flex_row,
            styles.tooltip_con,
            styles.min_height24
          ].join(' ')}
          style={{marginBottom:isMobile?2:0}}
        >
          <span className={styles.title} style={{marginBottom:0}}>{t('Total Unrealised P/L1')} <label style={{display:chgCur?'':'none'}}>({t(chgCur)})</label></span>
          <Tooltip 
            title={t("Total Unrealised P/L is for reference only. This calculation includes transaction fees but does not include transaction taxes or other related expenses.")}  
            overlayClassName={[
              ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm,
              bodyW>minW570?'':APPstyles.tooltipcon_custurm_right
            ].join(' ')}
          >
            <img 
              src={tipsImg2}  
              className={APPstyles.img16} 
              alt='tips'
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                this.props.cbImgurlFun(imgurl)
              }} 
              onMouseOut={()=>{
                this.props.cbImgurlFun(tipImg)
              }}
            />
          </Tooltip>
        </div>
        <span 
          className={[
            Number(hasData&&hasData['unrealised_pl'])>0?APPstyles.color_red:Number(hasData&&hasData['unrealised_pl'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            APPstyles.fw700
          ].join(' ')}
          style={{
            fontSize:isMobile?isUnrealised?16:20:24,
            lineHeight:isMobile?isUnrealised?'18px':'24px':'28px'
          }}
        >
          {common.addplusNum(common.transThousands(common.keepNum(hasData&&hasData['unrealised_pl'],2)))}
        </span>
      </div>
  )}
}
export default withTranslation()(Unrealised)