import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../js/common'
HighchartsMore(Hightcharts)

class Gauge extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  onLoadData(){
    const {t,id,seriesData,ChgThemeData,tooltipData,isMobile,bodyW}=this.props
    Hightcharts.chart(id,{
        chart: {
          type: 'gauge',
          backgroundColor: ChgThemeData=='Light'?'#FFFFFF':'#222429',
          spacing:[0, 0, 0, 0]
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        tooltip:{
          borderWidth:0,
          backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
          style:{
            color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '14px',
          },
          pointFormatter(){
            let getY=''
            let valueSuffix=id=='Ratios0Id'||id=='Ratios1Id'?'x':'%'
            if(tooltipData.length){
              for(let i in tooltipData){
                if(tooltipData[i]['id']==id){
                  getY=this['color']=='#008FC7'?tooltipData[i]['stock']:tooltipData[i]['sector']
                }
              }
            }
            getY=common.transThousands(common.keepNum(getY,2))+valueSuffix
            let str='<div><span style="color:'+this['color']+'">●</span><i style="opacity:0;color:none">.</i><span>'+t(this['series']['name'])+' : '+getY+'</span></div>'
            return str
          }
        },
        pane: {
          startAngle: -120,
          endAngle: 120,
          borderWidth: 0,
          background: [{
            backgroundColor: ChgThemeData=='Light'?'#FFFFFF':'#222429',
            borderWidth: 0
          }],
          size:isMobile&&bodyW<=450?'50%':'60%'
        },
        yAxis: {
          min: -10,
          max: 70,
          tickColor: ChgThemeData=='Light'?'#FFFFFF':'#222429',
          tickAmount: 9,
          tickLength: 20,
          tickWidth: 1,
          minorTickWidth: 0,
          lineWidth:0,
          gridZIndex:0,
          offset: 5,
          labels: {
            distance: 15,
            style: {
              color: '#828282',
              fontWeight: 400,
              fontSize: '10px',
              fontFamily: 'Roboto'
            },
            formatter(){
              if(this.value<0||this.value>60){
                return ''
              }
              let tag=id=='Ratios0Id'||id=='Ratios1Id'?'x':'%'
              return this.value+tag
            }
          },
          plotBands: [{
            from: -10,
            to: 0,
            color: '#C4C4C5'
          },{
            from: 0,
            to: 10,
            color: '#B0B1B2'
          },{
            from: 10,
            to: 20,
            color: '#9E9EA0'
          },{
            from: 20,
            to: 30,
            color: '#8A8B8D'
          },{
            from: 30,
            to: 40,
            color: '#78797C'
          },{
            from: 40,
            to: 50,
            color: '#646669'
          },{
            from: 50,
            to: 60,
            color: '#646669'
          },{
            from: 60,
            to: 70,
            color: '#3E4044'
          }]
        },
        plotOptions: {
          gauge: {
            dial: {
              radius: '95%',
              backgroundColor:'#8D48A2',
              borderWidth: 0,
              borderHeight: 5,
              baseWidth: 7,
              topWidth: 0,
              baseLength:'0%',
              rearLength: '0%'
            },
            pivot: {
              radius: 7,
              backgroundColor:'#828282',
              index:1
            },
            dataLabels: {
              enabled:false
            }
          }
        },
        series:seriesData
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,isRightPageShow,isToggleOn,seriesData}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      isRightPageShow!==newProps['isRightPageShow']||
      isToggleOn!==newProps['isToggleOn']||
      seriesData!==newProps['seriesData']
    ){
      setTimeout(()=>{
        this.onLoadData()
      }, 10)
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id,isMobile}=this.props
    return (
      <div id={id} style={{width:'100%',height:120,position: 'absolute',top:isMobile?30:10,left:isMobile?0:10}}></div>
    )
  }
}
export default withTranslation()(Gauge)