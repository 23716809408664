import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../js/common'
HighchartsMore(Hightcharts)

class Column extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      lengendData:['https://s2.loli.net/2022/05/31/guD2nMPFohJZA95.png','https://s2.loli.net/2022/05/31/V7xAYruwCUbpJ1z.png','https://s2.loli.net/2022/05/31/Yug4JNpjUdE2cWH.png','https://s2.loli.net/2022/05/31/3QjgrCv2oz1Nabc.png']
    }
  }
  onLoadData(){
    const {t,id,seriesData,ChgThemeData,xAxisData,plotDHValue,DHColorsData,bodyW,ChgLangData,isRightPageShow,isPC,isMobile,currentIndex,isStatementTab}=this.props
    const {lengendData}=this.state
    let getHDValue=((!plotDHValue||Number(plotDHValue)!==0)&&id=='DividendHistoryId')
    let zhCN=(isRightPageShow&&isPC&&bodyW<1360)||currentIndex==3||(isMobile&&bodyW<451)
    let enUS=(isRightPageShow&&isPC&&bodyW<1389)||currentIndex==3||(isMobile&&bodyW<461)
    let enUS_1=isMobile&&bodyW<382
    let isCashFlowTrue=ChgLangData=='zh-CN'?id=='CashFlowId'?zhCN:false:id=='CashFlowId'?enUS:id=='IncomeStatementId'?enUS_1:false
  
    Hightcharts.chart(id,{
      chart: {
        type: 'column',
        backgroundColor: '',
        spacing:[0, 0, 0, 0],
        height:220,
        spacingTop:id=='DividendHistoryId'?48:isCashFlowTrue?70:50

      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      legend:{
        enabled:id=='DividendHistoryId'?false:true,
        useHTML: true,
        symbolWidth: 0.001,
        symbolHeight: 0.001,
        symbolRadius: 0.001,
        padding:0,
        margin:0,
        floating:true,
        x:-3,
        y:isCashFlowTrue?-70:id=='IncomeStatementId'?-43:-49,
        align:id=='IncomeStatementId'&&isStatementTab?'right':'left',
        verticalAlign: 'top',
        itemMarginTop:5,
        itemMarginBottom:0,
        itemStyle: {
          color: ChgThemeData=='Light'?'#828282':'#E0E0E0',
          fontWeight: '400',
          fontSize: '12px',
          fontFamily: 'Roboto'
        },
        itemHoverStyle: {
          color: ChgThemeData=='Light'?'#373737':'#828282'
        },
        labelFormatter(){
          let url=lengendData[Number(this['index'])]
          let str='<img src = "'+url+'" width="6px" height="6px" style="margin-right:6px" />'+ t(this['name'])
          return str
        }
      },
      xAxis: {
        categories: xAxisData,
        title: {
          text: ''
        },
        labels: {
          style: {
            color: '#828282',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '11px',
          }
        },
        gridLineWidth: 0,
        gridLineColor: ChgThemeData=='Light'?'#E0E0E0':'#373737',
        lineColor: ChgThemeData=='Light'?'#E0E0E0':'#828282',
        tickWidth:0,
        plotBands:getHDValue? [{
          color: ChgThemeData=='Light'?'rgba(224,224,224,.5)':'#1B1B1B',
          from: Number(plotDHValue),
          to: xAxisData.length,
          label: {
            text: t('Estimate'),
            align: 'left',
            textAlign: 'left',
            x:8,
            y:13,
            style:{
              color: '#828282',
              fontWeight: '400',
              fontSize: '10px',
              fontFamily: 'Roboto'
            }
          }
        }]:[],
        plotLines:getHDValue? [{
          color: ChgThemeData=='Light'?'#333333':'#828282',
          width: 1,
          value: Number(plotDHValue),
          dashStyle:'dash'
        }]:[]
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          style: {
            color: '#828282',
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '11px',
          }
        },
        gridLineWidth: 1,
        gridLineColor: ChgThemeData=='Light'?'#E0E0E0':'#373737',
        showFirstLabel: true,
        showLastLabel:true,
        // tickAmount:7,
        plotLines: [{
          color: '#828282',
          width: 1,
          value: 0,
          zIndex: 5
        }]
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px'
        },
        pointFormatter(){
          let getName='',getY=common.transThousands(this['y'])
          if(id=='DividendHistoryId'){
            getName=this['color']=='#008FC7'?'Estimate':this['series']['name']
          }else{
            getName=this['series']['name']
            getY=common.addNumM(common.transThousands(common.keepNum(this['y'],0)))
          }
          let str='<div><span style="color:'+this['color']+'">●</span><i style="opacity:0;color:none">.</i><span>'+t(getName)+' : '+getY+'</span></div>'
          return str
        }
      },
      plotOptions: {
        column: id=='DividendHistoryId'?{
          colorByPoint: true,
          colors:DHColorsData
        }:{},
        series: {
          connectNulls:true,
          borderWidth:0,
          crop:false,
          overflow:'none',
          padding:20 
        }
      },
      series: seriesData
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,isRightPageShow,isToggleOn,seriesData,isStatementTab}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      isRightPageShow!==newProps['isRightPageShow']||
      isToggleOn!==newProps['isToggleOn']||
      seriesData!==newProps['seriesData']||
      isStatementTab!==newProps['isStatementTab']
    ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(Column)