import React from 'react'
import { Button,Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '@/asserts/js/common'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  leftImg,
  leftLightImg,
  closeMinOnLightImg,
  closeMinOnImg,
  remeberOffImg,
  remeberOnImg,
  remeberOffImg1
} from '@/asserts/imgs/common/index'
import PriceModal from '../PriceModal/index'
import DropDownSimple from './DropDown/index'
import DropDown from '../../DropDown/index'
import NumberCon from './Number/index'
import NoteCon from '@/components/modal/note/index';
import APPstyles from '@/App.less'
import styles from './index.less'

let timeoutId=null

class SimpleIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      accountNameVal:'',
      isBuyingPowerShow:false,
      selectCur:'Select Currency',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
      optionsData:[],
      inputNameVal:'',
      optonsNameShow:false,
      optionsNameData:[],
      optonsNameIndex:null,
      isDownLoading:false,
      optionsName:'',
      optionsSymbol:'',
      isnameShow:false,
      buySellIndex:null,
      typeData:[
        {
          name:'Limit',
          key:0,
          key1:0,
          disabled:'disabled'
        }
      ],
      quantityNums:1,
      priceNums:'',
      untilData:[
        {
          name:'Today',
          key:'today',
          disabled:'disabled'
        }
      ],
      isLoading:false,
      Amount:'0',
      isBuyingPowerLoading:true,
      isADR:0,
      countryCode:'US',
      fbstockid:'',
      isSelectCurShow:true,
      isPriceModalShow:false,
      priceModalTag:'',
      directionData:[
        {
          name:'Buy',
          name1:'Buy Price',
          key:'B',
          disabled:'disabled'
        },
        {
          name:'Sell',
          name1:'Sell Price',
          key:'S',
          disabled:'disabled'
        }
      ],
      allData:[],
      curreny:'',
      share:'',
      chgCloseImg:closeImg,
      clearImg:closeImg,
      chgDropDownImg:dropDownImg,
      comfirmDisabled:'disabled',
      currency_1:'',
      currency_cn:'',
      minW400:400,
      minW450:450,
      isRemember:false,
      simpleImg:dropDownImg,
      isSimpleShow:false,
      chgKeySimple:{
        name:'Simple',
        key:'simple'
      },
      isMarketShow:false,
      marketImg:dropDownImg,
      marketData:[
        {
          name:'US Stocks',
          key:'US'
        },
        {
          name:'HK Stocks',
          key:'HK'
        },
        {
          name:'JP Stocks',
          key:'JP'
        },
        {
          name:'SG Stocks',
          key:'SG'
        },
        {
          name:'UK Stocks',
          key:'GB'
        },
        {
          name:'DE Stocks',
          key:'DE'
        },
        {
          name:'CN Stocks',
          key:'CN'
        }
      ],
      chgkeyMarket:{
        name:'Choose Market',
        key:''
      },
      isHasBuySelll:false,
      amountLen:0,
      QtyHeld:0,
      minShare:1,
      priceLen:0,
      chgBuyPowerText:'Buying Power2',
      isBuyPowerLoading:false,
      UNITValue:'',
      isTWShow:false,
      isDropDownIconShow:true,
      isDeliveryShow:false,
      deliverySymbol:'',
      SKIND:'',
      noteData:{sign:'note',title:'Please Note',content:'This is a GEM stock in Hong Kong, and its stock price is easily manipulated by interested parties. Please be reminded to pay attention to related investment risks and information sources',cancelText:'Cancel',sureText:'Confirm Order'},
      noteVisible:false,
    }
    this.timer = null
  }
  selectCurClick(e){
    const {isnameShow,isTWShow,isBuyPowerLoading,isSelectCurShow,chgBuyPowerText}=this.state;
    e.stopPropagation();
    if((chgBuyPowerText=='Buying Power2'||chgBuyPowerText=='System busy, please try later')&&isnameShow&&isSelectCurShow&&!isBuyPowerLoading){
      this.buyPowerShowClick();
      return;
    }
    if(!isnameShow){
      this.setState({
        isTipBuyPowerShow:true,
      });
      return;
    }
    if(!isTWShow) return;
    if(isBuyPowerLoading) return;
    
    this.setState(prevState => ({
      isBuyingPowerShow:!prevState.isBuyingPowerShow,
    }));
  }
  //input---Name
  changeInputClick(e) {
    const {chgkeyMarket}=this.state
    let value=e['target']['value']

    if(chgkeyMarket['key']==''){
      this.setState({
        isPriceModalShow:true,
        priceModalTag:'market'
      })
      return
    }
    this.setState({
      inputNameVal:value,
      optonsNameShow:value?true:false,
      isDownLoading:value?true:false
    })
    if(value=='') return
    clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      this.getPowersearch(value)
    }, 500)
  }
  //下拉菜单--Name
  dropDownNameClick(data){
    this.setState({
      optonsNameIndex:data[1],
      optonsNameShow:false,
      isnameShow:false,
      isLoading:true,
      optionsNameData:[],
      isSelectCurShow:true,
      isBuyingPowerShow:false
    })
    let ric=data[0]['ric']==null?data[0]['fbstockid']:data[0]['ric'];
    this.changeRicFun(ric);
  }
  //clear--Name
  clearClick(tag) {
    const {directionData}=this.state
    for(let i in directionData){
      directionData[i]['disabled']='disabled'
    }
    this.setState({
      inputNameVal: '',
      optonsNameIndex:null,
      optonsNameShow:false,
      buySellIndex:null,
      isnameShow:false,
      priceNums:'',
      Amount:0,
      isSelectCurShow:true,
      curreny:'',
      share:'',
      quantityNums:1,
      directionData:directionData,
      comfirmDisabled:'disabled',
      isHasBuySelll:false,
      amountLen:0,
      minShare:1,
      QtyHeld:0,
      currency_1:'',
      currency_cn:'',
      priceLen:0,
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'Buying Power2',
      isBuyPowerLoading:false,
      isDropDownIconShow:true,
      UNITValue:'',
      isTWShow:false,
      isDeliveryShow:false,
      deliverySymbol:'',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
    })
    if(tag!=='market'){
      this.setState({
        chgkeyMarket:{
          name:'Choose Market',
          key:''
        }
      })
    }
  }
  //change value
  changeNumClick(data){
    const {priceNums,quantityNums}=this.state;
    let value=data['name']=='quantity'?Number(data['value']):data['value'];
    value=value?value:'';
    if(data['name']=='quantity'){
      let getAmount=value*priceNums
      this.setState({
        quantityNums:value,
        Amount:getAmount,
        amountLen:Number(String(getAmount).split('.')[0].length+2)
      })
      return
    }
    if(data['name']=='price'){
      let getAmount=value*quantityNums
      let value0=String(value).split('.')[0]
      let value1=String(value).split('.')[1]
      value0=typeof(value0)=='undefined'?0:value0.length
      value1=typeof(value1)=='undefined'?0:value1.length

      this.setState({
        priceNums: value,
        Amount:getAmount,
        amountLen:Number(String(getAmount).split('.')[0].length+2),
        priceLen:Number(value0+value1)
      })
    }
  }
  //清空数据
  clearData(){
    const {typeData,directionData}=this.state
    for(let i in directionData){
      directionData[i]['disabled']='disabled'
    }
    typeData[0]['key1']=0
    this.setState({
      isBuyingPowerShow:false,
      selectCur:'Select Currency',
      BuyingPowerSymbol:'',
      BuyingPowerNum:'',
      inputNameVal:'',
      buySellIndex:null,
      optonsNameIndex:null,
      optonsNameShow:false,
      isnameShow:false,
      isSelectCurShow:true,
      quantityNums:1,
      priceNums:'',
      Amount:0,
      isPriceModalShow:false,
      priceModalTag:'',
      countryCode:'US',
      isADR:0,
      curreny:'',
      share:'',
      typeData:typeData,
      directionData:directionData,
      comfirmDisabled:'disabled',
      isGetAccountInfo:true,
      isSimpleShow:false,
      isMarketShow:false,
      chgkeyMarket:{
        name:'Choose Market',
        key:''
      },
      isHasBuySelll:false,
      amountLen:0,
      QtyHeld:0,
      minShare:1,
      currency_1:'',
      currency_cn:'',
      priceLen:0,
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'Buying Power2',
      isBuyPowerLoading:false,
      isDropDownIconShow:true,
      UNITValue:'',
      isTWShow:false,
      isDeliveryShow:false,
      deliverySymbol:'',
    })
  }
  //页面--关闭
  closeClick(){
    const {sliderIndex,currentIndex}=this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let target=sessionStorage.getItem('target')
    sessionStorage.setItem('navId',navIdAPP)
    if(target=='trade'){
      sessionStorage.setItem('target','quote')
    }
    this.setState({
      isRemember:false
    })
    this.backTopFun()
    this.clearData()
    this.props.cbChgPageShow()

    if(navIdAPP=='quote'&&navId=='quote'){
      sessionStorage.removeItem('isRememberSimpleQuote')
    }else{
      sessionStorage.removeItem('isRememberSimpleTrade')
    }
    //quote
    if(sliderIndex=='quote'){
      this.props.callbackTradeShow('close','')
      let data=[false,false,false,false]
      this.props.calkbackBtnStatus(data)
    }
    // trade
    if(sliderIndex=='trade'){
      this.props.callbackChgNavIndex(navIdAPP, true)
      sessionStorage.setItem('navId',navIdAPP)
      if(currentIndex==3){
        this.props.callbackTradeShow('close','')
      }
    }
    // trade/account/news
    if(sliderIndex=='trade'||sliderIndex=='account'||sliderIndex=='news'){
      let data=[false,false,false,false]
      this.props.calkbackBtnStatus(data)
    }
  }
  //Confirm Order
  confirmClick(){
    const {quantityNums,priceNums,isLoading,SKIND}=this.state
    if(quantityNums==''||Number(quantityNums)==0||quantityNums=='-'||priceNums==''||Number(priceNums)==0||priceNums=='-'){
      let tag=quantityNums==''||Number(quantityNums)==0?'quantity':priceNums==''||Number(priceNums)==0?'price':''
      this.setState({
        isPriceModalShow:true,
        priceModalTag:tag
      })
      return
    }
    if(isLoading) return;
    // 港股創業板(8開頭) FSMB檔的SKIND欄位值=”B”出现提示
    if(SKIND=='B'){
      this.setState({
        noteVisible:true,
      });
    }else{
      this.confirmFun();
    }
  }
  confirmFun(){
    const {directionData,buySellIndex,isADR,quantityNums,priceNums,optionsSymbol,optionsName,untilData,countryCode,fbstockid,typeData}=this.state
    let data=String(isADR)=='0'?[true,false,false,true]
            :countryCode=='US'?[true,false,true,false]
              :[true,false,false,true]
    
    let resData=[{
      symbol:optionsSymbol,
      name:optionsName,
      price:String(priceNums),
      Quantity:quantityNums,
      priceType:typeData[0],
      GoodUntil:untilData[0],
      buySellIndex:directionData[buySellIndex],
      countryCode:countryCode,
      fbstockid:fbstockid,
      isShowGTC:false,
      isADR:isADR,
      isClickADR:false
    }]
    this.props.calkbackBtnStatus(data)
    this.props.calkbackOrderData(resData)
    this.backTopFun()
    let target=sessionStorage.getItem('target')
    if(target=='trade'){
      sessionStorage.setItem('target','quote')
    }
  }
  cbNoteConFun(sign){
    this.setState({
      noteVisible:false,
    });
    sign=='Y'&&this.confirmFun();
  }
  //powersearch--获取数据
  getPowersearch(value){
    const {chgkeyMarket}=this.state
    fetch(common.getHttp+'powersearch',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        k:value,
        limit:10,
        tradable:true,
        country:chgkeyMarket['key']
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        //flag
        let resData=res['data']['datalist']
        if(resData.length>0){
          for(let i in resData){
            resData[i]['img']=common.imgShortNameFlag[resData[i]['exchange_country_code']]
          }
          resData=common.imgDataFlagFun(resData,'img')
        } 
        this.setState({
          optionsNameData:resData,
          isDownLoading:false
        })
        return
      }
      //获取失败
      this.setState({
        isLoading: false
      })
    })
  }
  //获取数据--all
  changeRicFun(ric){
    const {directionData,marketData,chgkeyMarket,quantityNums}=this.state;
    let token=sessionStorage.getItem('token');
    this.backTopFun();
    this.setState({
      isLoading:true,
      QtyHeld:0,
      isDeliveryShow:false,
      chgBuyPowerText:'Buying Power2',
    });
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token,
        ric:[ric]
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']
        if(data && typeof(data)!='undefined' && data!=0&&typeof(data[ric]['fbstockid']) !== 'undefined'){
          let country_code=data[ric]['exchange_country_code'];
          let UNITValue=data[ric]['UNIT'];
          this.dealDropDownDataFun([],country_code,UNITValue,'onload');
          for(let i in marketData){
            if(marketData[i]['key']==country_code){
              chgkeyMarket['name']=marketData[i]['name']
              chgkeyMarket['key']=country_code
              this.setState({
                chgkeyMarket:chgkeyMarket
              })
            }
          }
          if(typeof(data[ric]['fbstockid']) !== 'undefined'){
            this.getStockHoldingInfo(token,data[ric]['fbstockid'],country_code)
          }
          for(let i in directionData){
            directionData[i]['disabled']=''
          }
          // 处理数据--flag
          for(let i in data){
            data[i]['img']=common.imgShortNameFlag[data[i]['exchange_country_code']]
          }
          data=common.imgDataFlagFun(data,'img')

          this.props.store.addSubscribeRic(data[ric]['ric'])  
          this.props.store.setQuoteData([data[ric]]) 

          let TRDPRC_1=typeof(data[ric]['TRDPRC_1'])=='undefined'?0:common.keepNum(data[ric]['TRDPRC_1'],2)
          let getAmount=Number(TRDPRC_1)*Number(quantityNums)
          let getShare=data[ric]['share']
          
          let value0=String(TRDPRC_1).split('.')[0]
          let value1=String(TRDPRC_1).split('.')[1]
          value0=typeof(value0)=='undefined'?0:value0.length
          value1=typeof(value1)=='undefined'?0:value1.length

          this.setState({
            optionsName:data[ric]['name'],
            optionsSymbol:data[ric]['symbol'],
            isADR:data[ric]['isADR'],
            isnameShow:true,
            buySellIndex:null,
            priceNums:TRDPRC_1,
            countryCode:country_code,
            fbstockid:data[ric]['fbstockid'],
            Amount:getAmount,
            isLoading: false,
            share:getShare,
            quantityNums:typeof(getShare)=='undefined'?1:getShare,
            curreny:typeof(data[ric]['UNIT'])=='undefined'?common.imgShortNameChgCur[country_code]:data[ric]['UNIT'],
            directionData:directionData,
            comfirmDisabled:'disabled',
            amountLen:Number(String(getAmount).split('.')[0].length+2),
            minShare:getShare?getShare:1,
            priceLen:Number(value0+value1),
            UNITValue:UNITValue,
            SKIND:data[ric]['SKIND']?data[ric]['SKIND']:'',
          })
          return
        }
        this.setState({
          isLoading: false
        })
        return
      }
      //获取失败
      this.setState({
        isLoading: false
      })
    }).catch(err => {
      //获取失败
      this.setState({
        isLoading: false
      })
    })
  }
  //获取数据--QtyHeld
  getStockHoldingInfo(token,stock_id,country_code){
    fetch(common.getHttpZs+'getStockHoldingInfo',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token,
        stock_id:stock_id,
        country_code:country_code
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        this.setState({
          QtyHeld:res['data']['qty'],
          currency_1:res['data']['currency'],
          currency_cn:res['data']['currency_cn']
        })
      }
    })
  }
  // 不同的国家对应不同的数据
  dealDropDownDataFun(data,country_code,UNITValue,sign){
    let getValue='';
    let getDataOne='';
    let getTW='';
    let getData=[];
    let newData=[];
    let isCNShow=true;//但選了中國股要特別處理，如市場是使用台幣，就不顯示任何貨幣
    let customerCurrency=sessionStorage.getItem('customerCurrency');
    customerCurrency=JSON.parse(customerCurrency);

    if(customerCurrency.length){
      getValue=country_code=='GB'?'UK':country_code;
      let UK_code=country_code=='DE'?'GB':country_code;
      const itemData = customerCurrency.find((item) => item === UK_code);
      if(itemData){// 台币
        getTW='TW';
        isCNShow=country_code=='CN'?false:true;
      }else{//原币
        if(UNITValue){
          getValue=common.shortNameChgCode[UNITValue];
          getValue=getValue=='GB'?'UK':getValue;
        }
      }
    }else{//原币
      if(UNITValue){
        getValue=common.shortNameChgCode[UNITValue];
        getValue=getValue=='GB'?'UK':getValue;
      }
    }
    if(sign=='onload'&&isCNShow){
      this.setState({
        isDeliveryShow:true,
        deliverySymbol:common.imgShortNameChgCur[getTW?getTW:getValue],
      });
      return;
    }
    let countryOne=data[getValue];
    let resData=Object.keys(countryOne);
    let flagData=Object.keys(common.imgArrFlag);
    if(getTW=='TW'){//台幣排第一
      let withoutTWD = resData.filter(currency => currency !== 'TWD');
      resData = ['TWD', ...withoutTWD];
    }
    if(resData.length>0){
      for(let i in resData){
        for(let j in flagData){
          if(flagData[j]==resData[i]){
            let obj={
              symbol:resData[i],
              num:countryOne[resData[i]],
              img:common.imgArrFlag[resData[i]],
            };
            let value=getTW?getTW:getValue;
            if(resData[i]==common.imgShortNameChgCur[value]){
              getDataOne=obj;
            }else{
              if(getTW !== 'TW' && (UNITValue === 'CNY' || UNITValue === 'EUR' || UNITValue === 'HKD' || UNITValue === 'GBP' || UNITValue === 'JPY' || UNITValue === 'TWD' || UNITValue === 'USD' || UNITValue === 'SGD')){
                getDataOne = this.dealValueData(data, UNITValue);
              }
            }
            getData.push(obj);
          }
        }
      }
      newData=common.imgDataFlagFun(getData,'img');
    }
    this.setState({
      optionsData:newData,
      isTWShow:getTW=='TW'?true:false,
      isBuyPowerLoading:false,
      isDropDownIconShow:false,
      isBuyingPowerLoading:false,
    });
    if(getDataOne&&isCNShow){
      this.setState({
        isSelectCurShow:false,
        BuyingPowerNum:getDataOne['num'],
        BuyingPowerSymbol:getDataOne['symbol'],
      });
    }
  }
  buyPowerShowClick(){
    const {chgBuyPowerText}=this.state;
    this.setState({
      isBuyPowerLoading:true,
      chgBuyPowerText:'Loading, please wait',
    });
    this.getAccountInfoFun();
    const handler = setTimeout(() => {
      chgBuyPowerText=='Loading, please wait'&&this.noDataFun();
    }, 15000);
    return () => {
      clearTimeout(handler);
    };
  }
  dealValueData(data,UNITValue){
    let val=data['ALL'][UNITValue]?data['ALL'][UNITValue]:'0.00';
      return {
      symbol:UNITValue,
      num:val,
      img:common.imgArrFlag[UNITValue],
    }
  }
  DataFun(){
    //获取失败
    this.setState({
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'Buying Power2',
      isBuyPowerLoading:false,
    })
  }
  //获取数据--Buying Power
  getAccountInfoFun(){
    const {countryCode,UNITValue}=this.state;
    fetch(common.getHttpZs+'getAccountInfo',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      clearTimeout(timeoutId)
      if(res['status']==0){
        let allData=res['data']['buying_power'];
        this.setState({
          allData:allData,
          chgBuyPowerText:'',
        });
        this.dealDropDownDataFun(allData,countryCode,UNITValue);
        return
      }
      //获取失败
      this.noDataFun()
    }).catch(err => {
      //获取失败
      clearTimeout(timeoutId)
      console.log(err)
      this.noDataFun()
    })
  }
  noDataFun(){
    this.setState({
      optionsData: [],
      isBuyingPowerLoading:false,
      chgBuyPowerText:'System busy, please try later',
      isBuyPowerLoading:false,
    });
  }
  //获取 Account Number
  getAccountIdFun(){
    fetch(common.getHttpZs+'getAccountId',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let aidListData=JSON.parse(sessionStorage.getItem('aidListData'))
        let getData=aidListData[res['account_id']] 
        this.setState({
          accountNameVal:getData['BranchName']+' '+getData['AID']+' '+getData['Name']
        })
        return
      }
      //获取失败
      this.setState({
        accountNameVal:''
      })
    })
  }
  //回到顶部
  backTopFun(){
    let newId=document.getElementById('getTradeID')
    if(newId){
      setTimeout(()=>{
        newId.scrollTop=0
      }, 10)
    }
  }
  //点击外部关闭下拉框--cur
  handleCurClick = e => {
    if(!this.CurComponent.contains(e.target)){
      this.setState ({
        isBuyingPowerShow:false,
        chgDropDownImg:dropDownImg
      })
    }
  }
  //点击外部关闭下拉框--Code
  handleCodeClick = e => {
    if(!this.CodeComponent.contains(e.target)) {
      this.setState ({
        optonsNameShow:false,
        inputNameVal:'',
        optionsNameData:[],
        optonsNameIndex:null,
        isDownLoading:false
      })
    }
  }
  //关闭所有的下拉框 trade弹框
  closeAllClick(){
    let navId=sessionStorage.getItem('navId')
    if(navId=='trade'){
      this.setState({
        isBuyingPowerShow:false,
        optonsNameShow:false,
        inputNameVal:'',
        optionsNameData:[],
        optonsNameIndex:null,
        isDownLoading:false,
        isSimpleShow:false,
        isMarketShow:false
      })
    }
  }
  simpleClick(e){
    const {isSimpleShow}=this.state
    e.stopPropagation()
    this.setState({
      isSimpleShow:!isSimpleShow
    })
  }
  //点击外部关闭下拉框--simple
  handleSimpleClick = e => {
    if(!this.simpleComponent.contains(e.target)){
      this.setState ({
        isSimpleShow:false,
        simpleImg:dropDownImg
      })
    }
  }
  marketClick(e){
    const {isMarketShow}=this.state
    e.stopPropagation()
    this.setState({
      isMarketShow:!isMarketShow,
      optonsNameShow:false,
      isBuyingPowerShow:false,
      isSimpleShow:false
    })
  }
  //点击外部关闭下拉框--market
  handleMarketClick = e => {
    if(!this.marketComponent.contains(e.target)){
      this.setState ({
        isMarketShow:false,
        marketImg:dropDownImg
      })
    }
  }
  // 跳转到委托回报页面
  gotoPageClick(){
    const {locationName}=this.props
    let pathName='/'+locationName[1]+'/Account'
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    this.props.callbackChgNavIndex(navIdAPP, true)

    if(navIdAPP=='account'){
      this.props.cbGotoPage_1()
      sessionStorage.setItem('navId','account')
    }else{
      this.props.callbackPathnameApp(pathName)// 获取路由跳转--app.js
      sessionStorage.setItem('navId','account')
      sessionStorage.setItem('navIdAPP','account')
      sessionStorage.setItem('isGotoPage','Y')
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ricData,accountTradeData,IsSubmitOrder,isShowPageData,isBackTopShow,isTradeMinbody,currentIndex,isMobile,isCghTradeData,closeAllDropDown,isTablet,isPC,isSwitchShow}=this.props
    let navId=sessionStorage.getItem('navId')
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    //关闭所有的下拉框
    if(closeAllDropDown!==newProps['closeAllDropDown']&&typeof(closeAllDropDown)!=='undefined'){
      this.closeAllClick()
    }
    //清空数据
    if(IsSubmitOrder!==newProps['IsSubmitOrder']){
      if(navIdAPP=='quote'&&navId=='quote'){
        let isRemember=sessionStorage.getItem('isRememberSimpleQuote')
        if(!isRemember){
          this.clearData()
        }
      }else{
        let isRemember=sessionStorage.getItem('isRememberSimpleTrade')
        if(!isRemember){
          this.clearData()
        }
      }
    }
    //回到顶部
    if(isBackTopShow!==newProps['isBackTopShow']){
      this.backTopFun()
    }
    if(isSwitchShow!==newProps['isSwitchShow']){
      this.getAccountIdFun()
    }
    // currentIndex==2/3更改trade数据---isMobile
    if(navIdAPP=='quote'&&isMobile&&isCghTradeData!==newProps['isCghTradeData']){
      if(!ricData||JSON.stringify(ricData) == '{}') return
      this.setState({
        isLoading:true
      })
      this.changeRicFun(ricData['ric'])
      this.getAccountIdFun()
    }
    //account --trade弹框
    if(navIdAPP=='account'&&accountTradeData!==newProps['accountTradeData']){
      if(JSON.stringify(accountTradeData[0])!=='{}'){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(accountTradeData[0]['stock_id'])
        this.getAccountIdFun()
      }
    }
    // trade--弹框
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(navId=='trade'){
          if(isShowPageData[0]){
            this.clearData()
            this.getAccountIdFun()
          }
        }
    }
    //quote页面--isTablet
    if(isTradeMinbody!==newProps['isTradeMinbody']){
      if((isTablet&&!isMobile&&ricData&&JSON.stringify(ricData)!=='{}')||(isPC&&(currentIndex==2||currentIndex==3))){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(ricData['ric'])
        this.getAccountIdFun()
      }
    }
    //quote页面--pc端
    if(isPC&&navIdAPP=='quote'&&ricData&&JSON.stringify(ricData)!=='{}'){
      if(isPC&&(currentIndex==2||currentIndex==3)) return
      if(ricData!==newProps['ricData']){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(ricData['ric']) 
      }
    }
  }
  //初始化
  componentDidMount(){
    const {ricData,accountTradeData,isChgPageOn,isTablet,isMobile}=this.props
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    let target=sessionStorage.getItem('target')
    if(isChgPageOn||target=='trade'){
      this.getAccountIdFun()
    }
    // Quote页面
    if(navIdAPP=='quote'&&ricData&&JSON.stringify(ricData) !== '{}'){
      this.changeRicFun(ricData['ric'])
      if(navId=='trade'&&isTablet&&!isMobile) return
      this.getAccountIdFun()
    }
    // account
    if(navIdAPP=='account'&&typeof(accountTradeData)!=='undefined'){
      if(accountTradeData.length>0&&JSON.stringify(accountTradeData[0])!=='{}'){
        this.setState({
          isLoading:true
        })
        this.changeRicFun(accountTradeData[0]['stock_id'])
        this.getAccountIdFun()
      }
    }
    document.addEventListener('click', this.handleCurClick)
    document.addEventListener('click', this.handleCodeClick)
    document.addEventListener('click', this.handleSimpleClick)
    document.addEventListener('click', this.handleMarketClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleCurClick)
    document.removeEventListener('click', this.handleCodeClick)
    document.removeEventListener('click', this.handleSimpleClick)
    document.removeEventListener('click', this.handleMarketClick)
  }
  render(){
    const {t,ChgThemeData,ChgLangData,isMobile,isTablet,isPC,bodyW,simpleData} = this.props
    const {accountNameVal,isBuyingPowerShow,BuyingPowerSymbol,BuyingPowerNum,inputNameVal,optonsNameShow,optionsData,buySellIndex,quantityNums,priceNums,optonsNameIndex,optionsNameData,optionsName,optionsSymbol,isnameShow,isLoading,isDownLoading,Amount,isBuyingPowerLoading,isSelectCurShow,isPriceModalShow,priceModalTag,directionData,curreny,share,chgCloseImg,clearImg,chgDropDownImg,comfirmDisabled,currency_1,currency_cn,minW400,minW450,isRemember,simpleImg,isSimpleShow,chgKeySimple,isMarketShow,marketData,marketImg,chgkeyMarket,isHasBuySelll,amountLen,QtyHeld,minShare,priceLen,chgBuyPowerText,isBuyPowerLoading,isTWShow,isDropDownIconShow,isDeliveryShow,deliverySymbol,noteData,noteVisible}=this.state
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    let navId=sessionStorage.getItem('navId')
    return (
      <div
        className={[
          styles.trade_layout,
          isTablet&&!isMobile?styles.trade_layout_minPC:'',
          isMobile?styles.trade_layout_mobile:'',
          APPstyles.flex_column,
          APPstyles.flex1_hidden,
          ChgThemeData=='Light'?APPstyles.borderLeft_light:APPstyles.borderLeft_dark,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
          isMobile?ChgThemeData=='Dark'?styles.trade_layout_mobileD:styles.trade_layout_mobileW:'',
          isHasBuySelll?styles.trade_layout_hasBuySelll:''
        ].join(' ')}
        onClick={this.closeAllClick.bind(this)}
      >
        {/* 加载中 */}
        <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
        {/* title */}
        <div 
          className={[
            APPstyles.flex_between,
            isMobile?APPstyles.flex_row:'',
            styles.name,
            styles.tradeflow_con,
            isMobile?styles.name_mobile:'',
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <div>
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
              alt='back' 
              className={APPstyles.img24} 
              style={{
                display:isMobile?'':'none'
              }} 
              onClick={this.closeClick.bind(this)}  
            />
            <span>{t('Trade')}</span>
          </div>
          <div className={APPstyles.flex_topcenter}>
            <div 
              className={[styles.trade_choicecon,APPstyles.flex_topcenter].join(' ')}
              onClick={this.simpleClick.bind(this)}
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                this.setState({
                  simpleImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  simpleImg:dropDownImg
                })
              }} 
            >
              {t(chgKeySimple['name'])}
              <img 
                src={simpleImg} 
                alt='dropDown' 
                className={styles.img_dropDown} 
                style={{transform:!isSimpleShow?'rotate(0deg)':'rotate(-180deg)',marginRight:isMobile?8:15}}
              />
              {/* 下拉框 */}
              <div 
                className={styles.dropsDown_con} 
                style={{display:isSimpleShow?'':'none'}} 
                ref = {testComponent => this.simpleComponent = testComponent}
              >
                <DropDownSimple
                  {...this.props}
                  tag='simple'
                  choiceData={simpleData}
                  chgkeyData={chgKeySimple['key']}
                  cbDropDownData={(data)=>{
                    this.setState({
                      chgKeySimple:data,
                      isSimpleShow:false
                    })
                    if(data['key']=='general'){
                      this.props.cbChgPageShow(data['key'])
                    }
                  }}
                />
              </div>
            </div>
            <img 
              src={chgCloseImg}
              alt='close'
              className={APPstyles.img24}
              style={{
                display:isMobile?'none':''
              }}  
              onClick={this.closeClick.bind(this)}   
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                this.setState({
                  chgCloseImg:closeImg
                })
              }} 
            />
          </div>
        </div>
        <div 
          id='getTradeID'
          className={[APPstyles.flex1_auto,buySellIndex==0?styles.bg_buy:buySellIndex==1?styles.bg_sell:''].join(' ')}
        >
          {/* Account Number */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.accountNumber,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Account Number')} : </span>
            <span style={{cursor:'pointer'}} className={ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white} onClick={()=>{this.props.cbSwitchMoalShow()}}>{accountNameVal}</span>
          </div>
          {/* market */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Market')}</span>
            <div className={APPstyles.flex_1} style={{position:'relative'}}>
              <div
                className={[
                  APPstyles.flex_between,
                  styles.market_con,
                  chgkeyMarket['key']?styles.linear_gradient_bg:ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black,
                  chgkeyMarket['key']?APPstyles.color_black:APPstyles.color_white
                ].join(' ')}
                onClick={this.marketClick.bind(this)}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                  this.setState({
                    marketImg:imgurl
                  })
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  this.setState({
                    marketImg:dropDownImg
                  })
                }} 
              >
                {t(chgkeyMarket['name'])}
                <img 
                  src={chgkeyMarket['key']?dropDownOnLightImg:marketImg} 
                  alt='dropDown' 
                  className={styles.img_dropDown} 
                  style={{transform:!isMarketShow?'rotate(0deg)':'rotate(-180deg)'}}
                />
              </div>
              {/* 下拉框 */}
              <div 
                className={styles.dropsDown_con} 
                style={{display:isMarketShow?'':'none'}} 
                ref = {testComponent => this.marketComponent = testComponent}
              >
                <DropDownSimple
                  {...this.props}
                  tag='market'
                  choiceData={marketData}
                  chgkeyData={chgkeyMarket['key']}
                  cbDropDownData={(data)=>{
                    this.setState({
                      chgkeyMarket:data,
                      isMarketShow:false
                    })
                    // 如轉變市場, 自動清空商品
                    this.clearClick('market')
                  }}
                />
              </div>
            </div>
          </div>
          {/* 股票名稱 */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.buyingPower,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Name2')}</span>
            <div className={[APPstyles.flex_between,styles.buySelected,bodyW<minW450?styles.buySelected_name:''].join(' ')}>
              <div 
                className={[
                  APPstyles.flex_topcenter,
                  styles.entername,
                  ChgThemeData=='Light'?styles.entername_light:''
                ].join(' ')}
              >
                <input 
                  autoComplete='off' 
                  placeholder={t('Enter the Name')} 
                  style={{
                    display:isnameShow?'none':optonsNameIndex==null?'':'none'
                  }} 
                  className={ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black}
                  value={inputNameVal} 
                  onChange={this.changeInputClick.bind(this)}
                />
                <div className={[APPstyles.flex_row,styles.getNamecon].join(' ')} style={{display:isnameShow?'':optonsNameIndex==null?'none':''}}>
                  <span className={[APPstyles.flex_topcenter,APPstyles.color_theme_black,styles.linear_gradient_bg,styles.getNamecon_symbol].join(' ')} style={{fontSize:18}}>
                    {optionsSymbol}
                  </span>
                  <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:''}>
                    {optionsName}
                  </span>
                </div>
              </div>
              <img 
                src={isHasBuySelll?closeOnImg:clearImg} 
                alt='clear' 
                className={APPstyles.img16} 
                onClick={this.clearClick.bind(this)}  
                onMouseOver={()=>{
                  if(isHasBuySelll) return
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?closeMinOnLightImg:closeMinOnImg
                  this.setState({
                    clearImg:imgurl
                  })
                }} 
                onMouseOut={()=>{
                  if(isHasBuySelll) return
                  if(!isPC) return
                  this.setState({
                    clearImg:closeImg
                  })
                }}  
              /> 
              {/* 下拉框 */}
              <div ref = {testComponent => this.CodeComponent = testComponent}>
              {optonsNameShow&&
                <div className={styles.dropsDown_con}>
                  <DropDown
                    {...this.props}
                    tag='inputcon'
                    data={optionsNameData}
                    isLoading={isDownLoading}
                    cbDropDownData={this.dropDownNameClick.bind(this)}
                  />
                </div>
              }
              </div>
            </div>
          </div>
          {/* direction */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.direction,
              styles.tradeflow_con,
              isMobile?styles.direction_mobile:''
            ].join(' ')}
          >
            <span className={styles.spanW}>{t(ChgLangData=='zh-CN'?'direction':'Direction')}</span>
            <div 
              className={[
                APPstyles.flex_between,
                APPstyles.flex_1,
                styles.direction_right,
                ChgThemeData=='Light'&&!isHasBuySelll?styles.direction_right_light:'',
                isMobile?styles.direction_right_mobile:''
              ].join(' ')}
            >
              <div 
                className={[
                  APPstyles.flex_between,
                  APPstyles.flex_1,
                  styles.direction_right_bg,
                  ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.inputcon_light:APPstyles.bg_1b1b1b,
                ].join(' ')}
              >
                {directionData.map((item,index) => {
                  return  <Button 
                            type='default' 
                            disabled={item['disabled']} 
                            key={index}
                            className={[
                              buySellIndex==index?styles.color_buysell:'',
                              isHasBuySelll?APPstyles.line_dark:ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_backTop_black,
                              buySellIndex==index?buySellIndex==0?styles.linear_gradient_buy:styles.linear_gradient_sell:'',
                              ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.border_white:''
                            ].join(' ')}
                            onClick={()=>{
                              this.setState({
                                buySellIndex:index,
                                comfirmDisabled:'',
                                isHasBuySelll:true
                              })
                            }}
                          >
                            {t(item['name'])}
                          </Button>
                  })
                }
              </div>
              <div className={[APPstyles.flex_column,APPstyles.flex_center,ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{marginLeft:6}}>
                <span>{t('Qty Held1')}</span>
                <span>{QtyHeld?common.transThousands(QtyHeld):0}</span>
              </div>
            </div>
          </div>
          {/* Quantity */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Quantity')}</span>
            <NumberCon
              {...this.props}
              tag='quantity'
              placeholderName='Enter Quantity'
              value={quantityNums}
              share={share}
              minShare={minShare}
              isHasBuySelll={isHasBuySelll}
              QtyHeld={QtyHeld}
              cbInputValue={this.changeNumClick.bind(this)}
              cbNumberWarning={()=>{
                this.setState({
                  isPriceModalShow:true,
                  priceModalTag:'quantity'
                })
              }}
              chgValueFun={(data)=>{
                this.setState({
                  quantityNums:data
                })
              }}
            />
          </div>
          {/* Price */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Price1')}</span>
            <NumberCon
              {...this.props}
              tag='price'
              placeholderName='Enter Price'
              value={priceNums}
              isHasBuySelll={isHasBuySelll}
              priceLen={priceLen}
              QtyHeld={QtyHeld}
              cbInputValue={this.changeNumClick.bind(this)}
              cbNumberWarning={()=>{
                this.setState({
                  isPriceModalShow:true,
                  priceModalTag:'price'
                })
              }}
            />
          </div>
          {/* curreny */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.curreny_con,
              styles.tradeflow_con
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Ccy')}</span>
            <div className={[APPstyles.flex_topcenter,APPstyles.flex_1].join(' ')}>
              <span className={[styles.curreny_span1,APPstyles.flex_center,ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
                {
                  currency_1&&currency_cn?
                    ChgLangData=='zh-CN'?currency_cn:currency_1
                  :
                    curreny?t(curreny):'-'  
                }
              </span>
              <span className={curreny?ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white:''} style={{fontSize:16}}>
                {t('Amount1')}
              </span>
              <span 
                className={[
                  ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white,
                  styles.curreny_span3
                ].join(' ')}
                style={{
                  fontSize:isMobile?amountLen==6?18:amountLen==7?16:amountLen>=8?14:20:20
                }}
              >
                {common.transThousands(common.keepNum(Amount,2))}
              </span>
            </div>
          </div>
          {/*  Order Memory */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.tradeflow_con,
              styles.trade_tips
            ].join(' ')}
          >
            <span className={styles.spanW}>{t('Order Memory')}</span>
            <div 
              style={{cursor:'pointer'}}
              onClick={()=>{
                this.setState({
                  isRemember:!isRemember
                })
                if(isRemember){
                  if(navIdAPP=='quote'&&navId=='quote'){
                    sessionStorage.removeItem('isRememberSimpleQuote')
                  }else{
                    sessionStorage.removeItem('isRememberSimpleTrade')
                  }
                }else{
                  if(navIdAPP=='quote'&&navId=='quote'){
                    sessionStorage.setItem('isRememberSimpleQuote',true)
                  }else{
                    sessionStorage.setItem('isRememberSimpleTrade',true)
                  }
                }
              }}
            >
              <img src={isRemember?remeberOnImg:isHasBuySelll?remeberOffImg1:remeberOffImg} className={APPstyles.img24} style={{position:'relative',top:-1,marginRight:4}} /> 
            </div> 
          </div>
          {/* Buying Power/Today’s Order  */}
          <div
            className={[
              APPstyles.flex_center,
              styles.trade_btncon
            ].join(' ')}
            style={{
              display:isnameShow?'':'none'
            }}
          >
            <div
              className={[
                APPstyles.flex_center,
                styles.trade_btncon_child
              ].join(' ')}
            >
              {/* Buying Power */}
              <div 
                className={[
                  APPstyles.flex_center,
                  styles.buyingPower,
                  ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.inputcon_light:''
                ].join(' ')}
              >
                <div 
                  className={[APPstyles.flex_between,styles.buySelected,bodyW<minW400?styles.buySelected_name:''].join(' ')} 
                  style={{cursor:isDropDownIconShow?'pointer':isTWShow?'pointer':'default'}}
                  onClick={this.selectCurClick.bind(this)}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'&&!isHasBuySelll?dropDownOnLightImg:dropDownOnImg
                    this.setState({
                      chgDropDownImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    this.setState({
                      chgDropDownImg:dropDownImg
                    })
                  }} 
                >
                  <div className={[APPstyles.flex_row,styles.buySelected_right,APPstyles.color_white].join(' ')}>
                    <div 
                      style={{display:isSelectCurShow?'':'none',opacity:1,alignItems:'center'}} 
                      className={[
                        ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:'',
                        styles.buySelected_right_name,
                        APPstyles.flex_row,,
                        chgBuyPowerText=='System busy, please try later'?styles.buySelected_right_name1:'',
                      ].join(' ')}
                    >
                      <Spin spinning={isBuyPowerLoading} style={{position:'static',transform: 'unset',marginRight:8}} />
                      <label style={{cursor:isDropDownIconShow?'pointer':isTWShow?'pointer':'default'}}>{t(chgBuyPowerText)}</label>
                    </div>
                    <div className={[styles.buySelected_right_symbol,APPstyles.flex_row,isSelectCurShow?APPstyles.display_none:''].join(' ')}>
                      <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                        {common.transThousands(common.keepNum(BuyingPowerNum,2))}
                      </span>
                      <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{top:0}}>
                        {t(BuyingPowerSymbol)}
                      </span>
                    </div>
                    <span className={[styles.Delivery,isDeliveryShow?'':APPstyles.display_none].join(' ')}>{t(deliverySymbol)}{t('Delivery')}</span>
                  </div>
                  <img 
                    src={chgDropDownImg} 
                    alt='dropDown' 
                    className={styles.img_dropDown} 
                    style={{transform:!isBuyingPowerShow?'rotate(0deg)':'rotate(-180deg)',display:isDropDownIconShow?'':isTWShow?'':'none'}}
                  />
                </div>
              </div>
              <Button 
                type='default' 
                className={[
                  APPstyles.btn_gray,
                  ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.inputcon_light:'',
                  ChgThemeData=='Light'&&!isHasBuySelll?styles.trade_order_light:''
                ].join(' ')} 
                onClick={this.gotoPageClick.bind(this)}
              >
                {t('Today’s Order')}
              </Button>
              {/* 下拉框 */}
              <div 
                className={styles.dropsDown_con} 
                style={{display:isBuyingPowerShow?'':'none'}} 
                ref = {testComponent => this.CurComponent = testComponent}
              >
                <DropDown
                  {...this.props}
                  tag='selectCurrency'
                  tag2='selectCurSimple'
                  isHasBuySelll={isHasBuySelll}
                  data={optionsData}
                  isLoading={isBuyingPowerLoading}
                  cbDropDownData={{}}
                />
              </div>
            </div>
          </div>
          {/* Details */}
          <div 
            className={[
              APPstyles.flex_topcenter,
              styles.trade_tips,
              ChgThemeData=='Light'&&!isHasBuySelll?APPstyles.color_theme_black:APPstyles.color_white
            ].join(' ')}
            style={{
              display:isnameShow?'':'none'
            }}
          >
            {t("After placing an order, please be sure to confirm your order status in")} {t(ChgLangData=='zh-CN'?'':'"Today’s Order"')}{ChgLangData=='zh-CN'?'':'.'}
          </div>
        </div>
        {/* Button */}
        <div 
          className={[
            APPstyles.flex_row,
            APPstyles.place_button,
            ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
          ].join(' ')}
          style={{paddingTop:16}}
        >
          <Button 
            type='default' 
            className={APPstyles.btn_gray} 
            onClick={this.closeClick.bind(this)}
          >
            {t('Cancel')}
          </Button>
          <Button 
            type='default' 
            style={{background:buySellIndex==0?'#F5475B':buySellIndex===1?'#39C46E':'#393939'}}
            className={ buySellIndex==0?styles.linear_gradient_buy:buySellIndex==1?styles.linear_gradient_sell:''} 
            disabled={comfirmDisabled}
            onClick={this.confirmClick.bind(this)}
          >
            {t(buySellIndex===null?'Place An Order':'Confirm Order')}
          </Button>
        </div>
        {isPriceModalShow&&
          <PriceModal
            {...this.props}
            tag={priceModalTag}
            cbClosePriceModal={()=>{
              this.setState({
                isPriceModalShow: false
              })
            }}
          />
        }
        {noteVisible&&
          <NoteCon 
            {...this.props} 
            visible={noteVisible}
            modalData={noteData} 
            directionData={directionData[buySellIndex]} 
            cbBtnDataFun={(sign)=>this.cbNoteConFun(sign)}
          />
        }
      </div>
  )}
}
export default withTranslation()(observer(SimpleIndex))
