import React from 'react';
import { withTranslation } from 'react-i18next';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  render() {
    const {ChgThemeData,cbDropDownItem,selectData,optionsData} = this.props;
    return (
      <div className={[APPstyles.flex_column,styles.dropDown,ChgThemeData=='Light'&&styles.dropDownLight].join(' ')} style={{display:selectData['isShow']?'':'none'}}>
        {optionsData.map((item,index) => {
          return  <span 
                    key={index}
                    className={[
                      APPstyles.btn_black,
                      APPstyles.flex_center,
                      ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                      ChgThemeData=='Light'?styles.spanlight_hover:APPstyles.bg_1b1b1b_hover,
                      selectData['id']==item['key']?ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_event_black:'',
                    ].join(' ')}
                    onClick={(e)=>{
                      e.stopPropagation();
                      cbDropDownItem(selectData['name'],item);
                    }}
                  >
                    {item['value']}
                  </span>
        })}
      </div>
    )
  }
}
export default withTranslation()(Index);