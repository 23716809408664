import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import APPstyles from '../../../../../App.less'
import styles from './index.less'

class DropDownCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
    render(){
        const {t,ChgThemeData,isDropDownShow,dropDownData,dropDownIndex,tag} = this.props 
        return (
            <div 
                className={[
                    styles.dropDown_con,
                    ChgThemeData=='Light'?styles.dropDown_con_light:'',
                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                ].join(' ')} 
                style={{
                    display:isDropDownShow?'':'none',
                    maxHeight:tag=='ESGScoreGrade'?246:300
                }}
            >
                {
                    dropDownData.map((item,index) => {
                        return  <div
                                    key={index} 
                                    className={[
                                        styles.dropDown_item,
                                        ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                                        dropDownIndex==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_event_black:''
                                    ].join(' ')}
                                    onClick={(e)=>{
                                        this.props.cbDropDownValue(item,index,e)
                                    }} 
                                >
                                   {t(item['name'])}
                                </div>
                    })
                }
            </div>
        )}
}
export default withTranslation()(observer(DropDownCon))