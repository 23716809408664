import { AnyCnameRecord } from "dns";
import { method } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const RMI = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(14);
  const [ta, setTa] = useState("mema");
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [period2, setPeriod2] = useState(4);

  const [buy, setBuy] = useState(25);
  const [sale, setSale] = useState(75);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(
    ta + "," + period + `,${t("chart5.TA.MOM")} ${t("chart5.period")}` + period2
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.RMI_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const RMI = localChartSetting.option;

        setValue(
          RMI.ta +
          "," +
          RMI.period +
          `,${t("chart5.TA.MOM")} ${t("chart5.period")}` +
          RMI.period2
        );
        props.onTAValueChange({
          ta: "RMI",
          name: `${t("chart5.TA.RMI")} (RMI)`,
          option: {
            period: RMI.period,
            ta: RMI.ta,
            color: RMI.color,
            thickness: RMI.thickness,
            style: RMI.style,
            showTracker: RMI.showTracker,
            period2: RMI.period2,
            buy: RMI.buy,
            sale: RMI.sale,
            height: RMI.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const RMI = props.localChartSetting.RMI_Option.option;

    setPeriod(parseInt(RMI.period) || period);
    setTa(RMI.ta);
    setColor(RMI.color);
    setThickness(parseInt(RMI.thickness) || thickness);
    setStyle(RMI.style);
    setShowTracker(RMI.showTracker);

    setPeriod2(parseInt(RMI.period2) || period2);
    setBuy(parseInt(RMI.buy) || buy);
    setSale(parseInt(RMI.sale) || sale);
    setHeight(RMI.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(
      ta +
      "," +
      period +
      `,${t("chart5.TA.MOM")} ${t("chart5.period")}` +
      period2
    );
    props.onTAValueChange({
      ta: "RMI",
      name: `${t("chart5.TA.RMI")} (RMI)`,
      option: {
        period: period,
        ta: ta,
        color: color,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        period2: period2,
        buy: buy,
        sale: sale,
        height: height,
      },
      value: value,
    });
  }, [
    period,
    ta,
    color,
    thickness,
    style,
    showTracker,
    period2,
    buy,
    sale,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const RMI = props.localChartSetting.RMI_Option.option;

    setPeriod(parseInt(RMI.period) || period);
    setTa(RMI.ta);
    setColor(RMI.color);
    setThickness(parseInt(RMI.thickness) || thickness);
    setStyle(RMI.style);
    setShowTracker(RMI.showTracker);

    setPeriod2(parseInt(RMI.period2) || period2);
    setBuy(parseInt(RMI.buy) || buy);
    setSale(parseInt(RMI.sale) || sale);
    setHeight(RMI.height);
  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(14);
    setTa("mema");
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setPeriod2(4);
    setBuy(25);
    setSale(75);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "P1") setPeriod(e.target.value);
    else setPeriod2(e.target.value);
  };
  const onTAChange = (e) => {
    setTa(e.target.value);
  };

  const onThicknessChange = (e) => {
    setThickness(e.target.value);
  };

  const onStyleChange = (e) => {
    setStyle(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  // e: any, type: string
  const onValueChange = (e, type) => {
    if (type === "buy") setBuy(e.target.value);
    else setSale(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "RMI":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "RMI", t("chart5.setting"));
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("RMI")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.RMI")} (RMI) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("RMI")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-RMI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.period")}</div>

            <div className="selectbox taparam" onChange={(e) => onTAChange(e)}>
              <select defaultValue={ta} value={ta}>
                <option value="ema">EMA</option>
                <option value="sma">SMA</option>
                <option value="mema">MEMA</option>
                <option value="wma">WMA</option>
              </select>
            </div>

            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e)}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e)}>
              <select id="msi-ta-UI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={onShowTracker}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-RMI">
          <div className="flexbox">
            <div className="taparamlbl">
              {t("chart5.TA.MOM")} {t("chart5.period")}
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-RMI-pM-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="floatmsibox">
          <div className="menusubitem" id="msi-ta-RMI">
            <div className="taparamlbl">
              {t("chart5.buy")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="0"
                max="100"
                value={buy}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onValueChange(e, "buy")}
              />
            </div>
          </div>
          <div className="menusubitem" id="msi-ta-RMI">
            <div className="taparamlbl">
              {t("chart5.sell")} {t("chart5.signal")}
            </div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="0"
                max="100"
                value={sale}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onValueChange(e, "sale")}
              />
            </div>
          </div>
        </div>
        <div className="menusubitem" id="msi-ta-RMI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select defaultValue="1" onChange={(e) => onHeightChange(e)} value={height}>
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
