import React, { Component } from "react";
import "./Chart5.scss";
import { chartFactory } from "./c5/alpha_chart.js";
import $ from "jquery";
import config from "../Configs/config";
import axios from "axios";
import html2canvas from "html2canvas";
import CompareList from "../Configs/compare.json";
import { addMinutes, addMonths, lastDayOfMonth } from "date-fns";
import { addHours } from "date-fns/esm";
import { isEqual } from "lodash";
import {
  DEFAULT_SETTING,
  DEFAULT_SETTING_LIGHT,
} from "./TechnicalChart/TechnicalChart";
import common from "../../asserts/js/common";
// import moment from "moment-timezone"
import { t } from "i18next";
import APPstyles from '@/App.less'

export default class Chart5 extends Component {
  container;
  chartpane;
  canvasbox;
  scrollpane;
  scrollcanvasbox;
  $chartframeobj;
  mainchartobj;
  scrollcharobj;
  compseries;
  series;
  dataSeries;
  RCDataSeries;
  UPDWDataSeries;
  eventSeries;
  updateSeries;
  ta;
  currentTrendLineObj = null;

  tachartobjList = new Array();
  compseriesList = new Array();

  currentColor = "0066FF";
  IntervalID;
  constructor(props) {
    super(props);

    const {
      backgroundColor: BACKGROUND_COLOR,
      chartBackgroundColor: CHARTBACKGROUNDCOLOR,
      chartLineColor: CHARTLINECOLOR,
      toolTipsColor: TOOLTIPSCOLOR,
      // upColor: UPCOLOR,
      // upFillColor: UPFILLCOLOR,
      // downColor: DOWNCOLOR,
      // downFillColor: DOWNFILLCOLOR,
    } = props.ChgThemeData === "Light"
        ? DEFAULT_SETTING_LIGHT
        : DEFAULT_SETTING;

    this.container = React.createRef();
    this.chartpane = React.createRef();
    this.scrollpane = React.createRef();
    this.canvasbox = React.createRef();
    this.nextProps = React.createRef();

    this.refIsDoneOn1MinFutureDateHandling = React.createRef(false);

    this.IntervalID = 0;
    this.state = {
      haveChart: true,
      haveCompareChart: true,
      isOpen: props.isSrcollViewOpen,
      status: true,
      period: this.props.period,
      periodOption: this.props.periodOption,
      tapane: [],
      // default US market, fix bug
      info: {
        ses: { 1: { fr: "09:30", to: "16:00" } },
        tz: "America/New_York",
      },
      priceTrackColor: "008000",
      priceTrackFontColor: "FFFFFF",
      priceTrackThickness: 2,
      priceTrackStyle: "d",
      yAsis: false,
      lineColor: "0033CC",
      lineFillColor: "99CCFF",
      lineFillColor2: "FFFFFF",
      lineGradientEnable: true,
      lineStyle: "s",
      backgroundColor: BACKGROUND_COLOR,
      chartBackground: CHARTBACKGROUNDCOLOR,
      chartLineColor: CHARTLINECOLOR,
      toolTipColor: TOOLTIPSCOLOR,
      crossHairLineColor: "444444",
      crossHairPointColor: "333333",
      crossHairStyle: "s",
      crossHairFontColor: "FFFFFF",
      showCrosshair: true,
      showTracker: true,
      showAnnotationPopupMenu: false,
      popupMenuMode: "Normal",
      drawTool: "move",
      drawLineColor: "0080FF",
      drawLineThickness: 2,
      drawLineStyle: "s",
      drawRetracementColor: "0080FF",
      drawRetracementThickness: 2,
      drawRetracementStyle: "s",
      drawFanColor: "0080FF",
      drawFanThickness: 2,
      drawFanStyle: "s",
      drawArcsColor: "0080FF",
      drawArcsThickness: 2,
      drawArcsStyle: "s",
      holList: [],
      showCHTooltips: true,
      annotationColor: "0080FF",
      annotationThickness: 2,
      currentRIC: "", // for checking new event in api call
      isFetchEventData: false, // for checking new event in api call
      previousEventData: {}, // for checking new event in api call
      allEventData: {}, // for checking new event in api call
      previousEventType: "", // for checking new event in api call
      mainTooltip: "", // mouse hover price info
      isChartRotated: false
    };
  }

  componentDidMount() {
    this.$chartframeobj = chartFactory.create();
    let w = this.container.current.clientWidth;
    let h = this.container.current.clientHeight;
    this.currentColor = "0066FF";
    let options = {};
    if (this.props.Crosshair)
      options = {
        crosshairFunc: this.crosshairFunc,
        tooltipFunc: this.tooltipFunc,
        dateRangeFunc: this.dateRangeFunc,
        annotEvtFunc: this.annotEvtFunc,
        eventboxFunc: this.eventboxFunc,
      };
    else
      options = {
        crosshairFunc: this.crosshairFunc,
        annotEvtFunc: this.annotEvtFunc,
      };
    this.mainchartobj = this.$chartframeobj.add(
      chartFactory.PRICE_CHART,
      "mainchart",
      w,
      h,
      $(this.canvasbox.current),
      options,
      1
    );
    this.mainchartobj.shrink(0.1);
    this.showSessionBreak();
    this.scrollcharobj = this.$chartframeobj.add(
      chartFactory.CONDENSE_CHART,
      "scrollchart",
      w,
      h * 0.4,
      $(this.refs.scrollcanvasbox)
    );

    if (!this.props.Crosshair) this.mainchartobj.setCrosshairStyle();

    this.getChartFrame();
    //this.renderChart(this.props);

    // this.IntervalID = setInterval(
    //   function (this) {
    //     this.updateMinChart();
    //   }.bind(this),
    //   10000
    // );
  }

  componentWillUnmount() {
    clearInterval(this.IntervalID);
  }

  async componentWillReceiveProps(nextProps) {
    this.nextProps.current = Object.assign({}, nextProps);
    // console.log(nextProps);
    // console.log("nextProps.tapane");
    // console.log(nextProps.tapane);
    if (nextProps.code === "") return;
    this.currentColor = nextProps.drawLineColor;
    this.setState(
      {
        mainTooltip: "",
        period: nextProps.period,
        periodOption: nextProps.periodOption,
        //info: nextProps.info,
        priceTrackColor: nextProps.chartSetting.priceTrackColor,
        priceTrackFontColor: nextProps.chartSetting.priceTrackFontColor,
        priceTrackThickness: nextProps.chartSetting.priceTrackThinkness,
        priceTrackStyle: nextProps.chartSetting.priceTrackStyle,
        yAsis: nextProps.chartSetting.yAsis,
        backgroundColor: nextProps.chartSetting.backgroundColor,
        chartBackground: nextProps.chartSetting.chartBackgroundColor,
        chartLineStyle: nextProps.chartSetting.chartLineStyle,
        chartLineColor: nextProps.chartSetting.chartLineColor,
        toolTipColor: nextProps.chartSetting.toolTipsColor,
        crossHairLineColor: nextProps.chartSetting.crosshairLineColor,
        crossHairStyle: nextProps.chartSetting.crosshairLineStyle,
        crossHairPointColor: nextProps.chartSetting.crosshairPointColor,
        crossHairPointSize: nextProps.chartSetting.crosshairPointSize,
        crossHairFontColor: nextProps.chartSetting.crosshairFontColor,
        showCrosshair: nextProps.Crosshair,
        showTracker: nextProps.showTracker,
        showDrawTool: nextProps.showDrawTool,
        drawTool: nextProps.drawTool,
        showMainTracker: nextProps.showMainTracker,
        showToolTips: nextProps.showToolTips,
        showCompare: nextProps.showCompare,
        isChartRotated: nextProps.isChartRotated
      },
      () => {
        chartFactory.isChartRotated = nextProps.isChartRotated

        this.getChartFrame();
        // resizeChart to fix background color change issue
        this.resizeChart(nextProps, true);
        this.resizeChart(nextProps);
      }
    );
    if (!this.state.showAnnotationPopupMenu) {
      this.setState({
        drawLineColor: nextProps.drawToolOption.drawLineColor,
        drawRetracementColor: nextProps.drawToolOption.drawRetracementColor,
        drawFanColor: nextProps.drawToolOption.drawFanColor,
        drawArcsColor: nextProps.drawToolOption.drawArcsColor,
        drawLineThickness: nextProps.drawToolOption.drawLineThickness,
        drawLineStyle: nextProps.drawToolOption.drawLineStyle,
        drawRetracementThickness:
          nextProps.drawToolOption.drawRetracementThickness,
        drawRetracementStyle: nextProps.drawToolOption.drawRetracementStyle,
        drawFanThickness: nextProps.drawToolOption.drawFanThickness,
        drawFanStyle: nextProps.drawToolOption.drawFanStyle,
        drawArcsThickness: nextProps.drawToolOption.drawArcsThickness,
        drawArcsStyle: nextProps.drawToolOption.drawArcsStyle,
      });
    }

    if (!nextProps.showMainTracker) {
      this.mainchartobj.removeTracker("price");
      switch (this.props.type) {
        case "line":
          this.mainchartobj.removeLine();
          break;
        case "candlestick":
          this.mainchartobj.removeCandlestick();
          break;
        case "bar":
          this.mainchartobj.removeOHLC();
          break;
        case "histogram":
          this.mainchartobj.removeHistogram();
          break;
      }
    }

    if (this.props.isSrcollViewOpen !== nextProps.isSrcollViewOpen) {
      this.setState({ isOpen: nextProps.isSrcollViewOpen }, () => {
        setTimeout(() => {
          this.resizeChart(nextProps);
        }, 100);
        return;
      });
    }

    if (nextProps.drawTool === "drawLine") {
      this.setState({
        annotationColor: nextProps.drawToolOption.drawLineColor,
        annotationThickness: nextProps.drawToolOption.drawLineThickness,
      });
    } else if (nextProps.drawTool === "drawRetracement") {
      this.setState({
        annotationColor: nextProps.drawToolOption.drawRetracementColor,
        annotationThickness: nextProps.drawToolOption.drawRetracementThickness,
      });
    } else if (nextProps.drawTool === "drawFan") {
      this.setState({
        annotationColor: nextProps.drawToolOption.drawFanColor,
        annotationThickness: nextProps.drawToolOption.drawFanThickness,
      });
    } else if (nextProps.drawTool === "drawArcs") {
      this.setState({
        annotationColor: nextProps.drawToolOption.drawArcsColor,
        annotationThickness: nextProps.drawToolOption.drawArcsThickness,
      });
    }

    if (nextProps.drawTool !== this.props.drawTool) {
      this.setState({ isChangeByColorPicker: false });
    }

    // if (
    //   this.props.selectedColor !== nextProps.selectedColor
    // ) {
    if (nextProps.selectedColor !== "" && this.state.isChangeByColorPicker) {
      // #3085 reset style
      if (this.state.drawTool === "drawLine") {
        this.currentColor = nextProps.selectedColor;
        this.setState(
          {
            drawLineColor: nextProps.selectedColor,
            annotationColor: nextProps.selectedColor,
          },
          () => {
            this.renderPopupMenu();
          }
        );
      } else if (this.state.drawTool === "drawRetracement") {
        this.setState({
          drawRetracementColor: nextProps.selectedColor,
          annotationColor: nextProps.selectedColor,
        });
      } else if (this.state.drawTool === "drawFan") {
        this.setState({
          drawFanColor: nextProps.selectedColor,
          annotationColor: nextProps.selectedColor,
        });
      } else if (this.state.drawTool === "drawArcs") {
        this.setState({
          drawArcsColor: nextProps.selectedColor,
          annotationColor: nextProps.selectedColor,
        });
      }

      if (this.props.selectedColor !== nextProps.selectedColor) {
        if (
          this.currentTrendLineObj !== undefined &&
          this.currentTrendLineObj !== null
        ) {
          this.currentTrendLineObj.color = "#" + nextProps.selectedColor;
          this.currentTrendLineObj.focusColor =
            "#" + this.makefocuscolor(nextProps.selectedColor);
          this.setState({ annotationColor: nextProps.selectedColor });
          if (this.state.drawTool !== "drawLine")
            this.currentTrendLineObj.textColor = "#" + nextProps.selectedColor;
          this.mainchartobj.refreshAnnotation();
        }
      }
    }

    //return;
    //}

    if (!nextProps.showCompare) {
      if (this.props.drawTool !== nextProps.drawTool) {
        this.setState({
          drawTool: nextProps.drawTool,
          showAnnotationPopupMenu: false,
        });
        this.currentTrendLineObj = null;
        switch (nextProps.drawTool) {
          case "drawLine":
            if (!nextProps.showCompare) {
              this.mainchartobj.canvas.css("cursor", "auto");
              this.scrollcharobj.canvas.css("cursor", "auto");
              this.mainchartobj.mode = chartFactory.MODE_TREND_LINE;
              this.setState({
                showAnnotationPopupMenu: true,
                popupMenuMode: "Exit",
                showCHTooltips: false,
              });
            }
            break;
          case "drawRetracement":
            if (!nextProps.showCompare) {
              this.mainchartobj.canvas.css("cursor", "auto");
              this.scrollcharobj.canvas.css("cursor", "auto");
              this.mainchartobj.mode = chartFactory.MODE_FIB_RETRACE;
              this.setState({
                showAnnotationPopupMenu: true,
                popupMenuMode: "Exit",
                showCHTooltips: false,
              });
            }
            break;
          case "drawFan":
            if (!nextProps.showCompare) {
              this.mainchartobj.canvas.css("cursor", "auto");
              this.scrollcharobj.canvas.css("cursor", "auto");
              this.mainchartobj.mode = chartFactory.MODE_FIB_FANS;
              this.setState({
                showAnnotationPopupMenu: true,
                popupMenuMode: "Exit",
                showCHTooltips: false,
              });
            }
            break;
          case "drawArcs":
            if (!nextProps.showCompare) {
              this.mainchartobj.canvas.css("cursor", "auto");
              this.scrollcharobj.canvas.css("cursor", "auto");
              this.mainchartobj.mode = chartFactory.MODE_FIB_ARCS;
              this.setState({
                showAnnotationPopupMenu: true,
                popupMenuMode: "Exit",
                showCHTooltips: false,
              });
            }
            break;

          case "anno":
            if (!nextProps.showCompare) {
              this.mainchartobj.canvas.css("cursor", "auto");
              this.scrollcharobj.canvas.css("cursor", "auto");
              this.mainchartobj.mode = chartFactory.MODE_LABEL;
              this.setState({
                showAnnotationPopupMenu: true,
                popupMenuMode: "Exit",
                showCHTooltips: false,
              });
            }
            break;
          case "move":
          default:
            this.mainchartobj.canvas.css("cursor", "pointer");
            this.scrollcharobj.canvas.css("cursor", "pointer");
            this.mainchartobj.mode = chartFactory.MODE_NORMAL;
            this.mainchartobj.selectAnnotation(-1, -1);
            this.setState({
              showAnnotationPopupMenu: false,
              showCHTooltips: true,
            });
            break;
        }

        return;
      }

      // Enable showing trendlines
      this.mainchartobj.enableFibonacci();
      this.mainchartobj.enableTrendLine();
      if (nextProps.isMobile) {
        this.mainchartobj.setCrosshairStyle();
      }
    } else {
      // Stop showing trendlines
      this.mainchartobj.disableTrendLine();
      this.mainchartobj.disableFibonacci();
      this.setState({ showAnnotationPopupMenu: false });
    }
    if (
      !isEqual(this.props.compare, nextProps.compare) ||
      !nextProps.showCompare
    ) {
      this.compseriesList.forEach((item) => {
        this.mainchartobj.drawers[0].obj.removeSeries(item);
      });
      this.mainchartobj.removeBaseComparison();
      this.compseriesList = new Array();
    }

    if (
      this.props.code === nextProps.code &&
      this.props.ccy === nextProps.ccy &&
      this.props.period === nextProps.period &&
      this.props.periodOption === nextProps.periodOption &&
      this.props.info === nextProps.info
    ) {
      //console.log("update")
      this.updateChart(nextProps);
      //this.updateMinChart(nextProps);
    } else {
      if (this.props.overlay.length > 0) {
        this.props.overlay.forEach((e) => {
          this.compseriesList = new Array();
          this.removeOverLay(e);
        });
      }

      if (this.props.tapane.length > 0) {
        this.props.tapane.forEach((e) => {
          this.tachartobjList = new Array();
          this.removeTAPane(e);
        });
      }
      this.props.callbackIsAllowRefreshUpdateChart(false);
      // console.log("render chart: ", nextProps.code);
      this.getInfo(nextProps);
      this.renderChart(nextProps);
    }
  }

  // props: any, code: string
  getRCData = (props, code) => {
    let api = "hts";
    let option = "d";
    let dataType = chartFactory.HTS_DATA;
    let gridType = chartFactory.GRID_DATE_ALIGN;
    switch (props.period) {
      case "1min":
        option = "1";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "5mins":
        option = "5";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "15mins5d":
      case "15mins":
        option = "15";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "1hour":
        option = "60";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      default:
        break;
    }

    this.mainchartobj.setGridType(gridType);
    let domain =
      window.location.protocol === "http:"
        ? config.URL + "/smbcc5/data/"
        : config.URL_HTTPS + "/smbcc5/data/";

    let date = "";
    if (this.mainchartobj.series.points != null)
      date =
        this.mainchartobj.series.points[0].year.toString() +
        (this.mainchartobj.series.points[0].month > 10
          ? this.mainchartobj.series.points[0].month.toString()
          : "0" + this.mainchartobj.series.points[0].month.toString()) +
        (this.mainchartobj.series.points[0].day > 10
          ? this.mainchartobj.series.points[0].day.toString()
          : "0" + this.mainchartobj.series.points[0].day.toString());

    let url = "";
    if (api === "hts") {
      if (
        props.period === "day_free" ||
        props.period === "week_free" ||
        props.period === "month_free"
      ) {
        url =
          domain +
          api +
          "?ric=" +
          code +
          "&i=" +
          props.periodOption.option +
          "&adj=" +
          1 +
          "&fr=" +
          date +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      } else
        url =
          domain +
          api +
          "?ric=" +
          code +
          "&i=" +
          props.periodOption +
          "&adj=" +
          1 +
          "&fr=" +
          date +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
    } else {
      url =
        domain +
        api +
        "?ric=" +
        code +
        "&i=" +
        option +
        "&fr=" +
        date +
        "&token=" +
        props.token +
        "&qid=" +
        new Date().getTime();
    }

    url = common.getHttpChart;

    let data = {
      token: sessionStorage.getItem("token"),
      ric: props.code,
      i:
        api === "hts"
          ? props.period === "day_free" ||
            props.period === "week_free" ||
            props.period === "month_free"
            ? props.periodOption.option
            : props.periodOption
          : option,
      adj: api === "hts" ? 1 : undefined,
      fr: date,
    };

    this.RCDataSeries = this.$chartframeobj.data(dataType, code, url, data, {
      successFunc: this.RCDataReady,
      decimalPlace: 2,
    });
    this.RCDataSeries.open();
  };

  // props: any, code: string
  getUPDWData = (props, code) => {
    let api = "hts";
    let option = "d";
    let dataType = chartFactory.HTS_DATA;
    let gridType = chartFactory.GRID_DATE_ALIGN;
    switch (props.period) {
      case "1min":
        option = "1";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "5mins":
        option = "5";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "15mins5d":
      case "15mins":
        option = "15";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "1hour":
        option = "60";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      default:
        break;
    }

    this.mainchartobj.setGridType(gridType);
    let domain =
      window.location.protocol === "http:"
        ? config.URL + "/smbcc5/data/"
        : config.URL_HTTPS + "/smbcc5/data/";

    let date = "";
    if (this.mainchartobj.series.points != null)
      date =
        this.mainchartobj.series.points[0].year.toString() +
        (this.mainchartobj.series.points[0].month > 10
          ? this.mainchartobj.series.points[0].month.toString()
          : "0" + this.mainchartobj.series.points[0].month.toString()) +
        (this.mainchartobj.series.points[0].day > 10
          ? this.mainchartobj.series.points[0].day.toString()
          : "0" + this.mainchartobj.series.points[0].day.toString());

    let url = "";
    if (api === "hts") {
      if (
        props.period === "day_free" ||
        props.period === "week_free" ||
        props.period === "month_free"
      ) {
        url =
          domain +
          api +
          "?ric=" +
          code +
          "&i=" +
          props.periodOption.option +
          "&adj=" +
          1 +
          "&fr=" +
          date +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      } else
        url =
          domain +
          api +
          "?ric=" +
          code +
          "&i=" +
          props.periodOption +
          "&adj=" +
          1 +
          "&fr=" +
          date +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
    } else {
      url =
        domain +
        api +
        "?ric=" +
        code +
        "&i=" +
        option +
        "&fr=" +
        date +
        "&token=" +
        props.token +
        "&qid=" +
        new Date().getTime();
    }

    url = common.getHttpChart;

    let data = {
      token: sessionStorage.getItem("token"),
      ric: props.code,
      i:
        api === "hts"
          ? props.period === "day_free" ||
            props.period === "week_free" ||
            props.period === "month_free"
            ? props.periodOption.option
            : props.periodOption
          : option,
      adj: api === "hts" ? 1 : undefined,
      fr: date,
    };

    this.UPDWDataSeries = this.$chartframeobj.data(dataType, code, url, data, {
      successFunc: this.UPDWDataReady,
      errorFunc: this.UPDWDataError,
      decimalPlace: 2,
    });
    this.UPDWDataSeries.open();
  };

  getEvent = (eventType, fr, to) => {
    return;

    let isNewFetch = this.state.currentRIC === this.props.code ? false : true; // new fetch means this RIC request chart event for the first times
    let isNewEventType = !isEqual(this.state.previousEventType, eventType);
    let params =
      isNewFetch || isNewEventType
        ? {
          ric: this.props.code,
          fr: fr,
          to: to,
          eventType: eventType,
        }
        : {
          ric: this.props.code,
          fr: to,
          eventType: eventType,
        };

    axios
      .get("/smbcapi/eventapi/chartEvent", {
        params: params,
      })
      .then(({ data }) => {
        let eventData = data.data;

        if (
          !isEqual(this.state.previousEventData, eventData) ||
          isNewFetch ||
          isNewEventType
        ) {
          // console.log("isNewEventType");
          // console.log(isNewEventType);
          if (isNewFetch || isNewEventType) {
            //console.log("V new");
            this.setState({
              currentRIC: this.props.code,
              allEventData: eventData,
              previousEventType: eventType,
            });
          } else {
            //console.log("X new");
            eventData = Object.assign({}, this.state.allEventData, eventData);
          }
          //console.log(eventData);

          this.setState({ previousEventData: eventData });
        } else {
          return;
        }

        // clear old event data
        if (this.series.eventData) {
          Object.keys(this.series.eventData).map((key) => {
            try {
              var date = this.series.convTimeStr(key);
              var index = this.series.indexOf(date);
              if (index !== null && index !== -1 && index !== 0) {
                this.series.points[index].events = null;
                this.series.updateAll();
              }
            } catch (err) {
              //console.log(err);
            }
          });
        }

        // add new event data
        if (eventData)
          if (this.series) {
            this.series.eventData = eventData;

            Object.keys(eventData).map((key) => {
              try {
                var date = this.series.convTimeStr(key);
                var index = this.series.indexOf(date);
                if (index !== null && index !== -1 && index !== 0) {
                  this.series.points[index].events = eventData[key];
                  this.series.updateAll();
                }
              } catch (err) {
                //console.log(err);
              }
            });

            this.mainchartobj.addEvent({}, NaN);
          }
      });
  };

  clearALL() {
    this.mainchartobj.clearAnnotations(chartFactory.MODE_TREND_LINE);
    this.mainchartobj.clearAnnotations(chartFactory.MODE_FIB_RETRACE);
    this.mainchartobj.clearAnnotations(chartFactory.MODE_FIB_FANS);
    this.mainchartobj.clearAnnotations(chartFactory.MODE_FIB_ARCS);
    this.mainchartobj.clearAnnotations(chartFactory.MODE_LABEL);
  }

  printChart() {
    //remove crosshair before generate image
    this.mainchartobj.setCrosshairStyle();
    if (this.tachartobjList.length > 0) {
      for (let i = 0; i < this.tachartobjList.length; i++) {
        let taobj = this.tachartobjList[i];
        taobj.tachartobj.setCrosshairStyle();
      }
    }

    let imgbuf;
    html2canvas(this.canvasbox.current).then((canvas) => {
      let imgbuf = canvas.toDataURL();
      let img_w = this.canvasbox.current.getBoundingClientRect().width;
      let img_h = this.canvasbox.current.getBoundingClientRect().height;
      this.props.printChart(
        imgbuf,
        img_w,
        img_h,
        this.state.mainTooltip,
        this.state.toolTipColor,
        this.tachartobjList
      );
    });

    //add crosshair before generate image
    this.mainchartobj.setCrosshairStyle(1 | 2);
    if (this.tachartobjList.length > 0) {
      for (let i = 0; i < this.tachartobjList.length; i++) {
        let taobj = this.tachartobjList[i];
        taobj.tachartobj.setCrosshairStyle(1 | 2);
      }
    }
  }

  getChartFrame() {
    this.$chartframeobj.chartColor = "#" + this.state.chartBackground;
    this.$chartframeobj.gridColor = "#" + this.state.chartLineColor;
    this.$chartframeobj.gridDash =
      this.state.chartLineStyle === "d" ? chartFactory.TRACKER_DASH : 0;
    this.$chartframeobj.gridLabelColor = "#" + this.state.toolTipColor;
    this.$chartframeobj.crosshairColor = "#" + this.state.crossHairLineColor;
    this.$chartframeobj.crosshairTextColor =
      "#" + this.state.crossHairFontColor;
    this.$chartframeobj.crosshairStyle =
      this.state.crossHairStyle === "d" ? chartFactory.CROSSHAIR_DASH : 0;
    this.mainchartobj.crosshairStyle =
      this.state.crossHairStyle === "d" ? chartFactory.CROSSHAIR_DASH : 0;
    this.mainchartobj.flip(this.state.yAsis);
    this.scrollcharobj.chartColor = "#" + this.state.chartBackground;
    this.scrollcharobj.gridLabelColor = "#" + this.state.toolTipColor;

    let s =
      chartFactory.CROSSHAIR_HORIZONTAL |
      chartFactory.CROSSHAIR_VERTICAL |
      (this.state.crossHairStyle === "d" ? chartFactory.CROSSHAIR_DASH : 0);

    if (this.state.showCrosshair) {
      this.mainchartobj.setCrosshairStyle(s);
    } else this.mainchartobj.setCrosshairStyle();

    if (
      !this.state.showTracker ||
      !this.state.showMainTracker ||
      this.state.showCompare
    ) {
      this.mainchartobj.removeTracker("price");
    } else {
      this.mainchartobj.removeTracker("price");
      if (
        this.mainchartobj.series &&
        this.mainchartobj.series.points &&
        this.mainchartobj.series.points.length > 0
      ) {
        let points = this.mainchartobj.series.points;
        let value = points[this.mainchartobj.series.spotCount - 1].close;
        this.mainchartobj.addPriceTracker2("price", value, {
          color: "#" + this.state.priceTrackColor,
          invertColor: "#" + this.state.priceTrackColor,
          textColor: "#" + this.state.priceTrackFontColor,
          thickness: this.state.priceTrackThickness,
          dashstyle:
            this.state.priceTrackStyle === "d" ? chartFactory.TRACKER_DASH : 0,
          front: true,
        });
      }
    }

    if (this.state.showDrawTool && !this.state.showCompare) {
      this.mainchartobj.enableTrendLine();
      this.mainchartobj.enableFibonacci();
    } else {
      this.mainchartobj.disableTrendLine();
      this.mainchartobj.disableFibonacci();
    }
  }

  resizeChart = (props, isChangeColor) => {
    // console.log("this.container");
    // console.log(this.container);
    let resizefromOutside = false;
    if (props === "") {
      resizefromOutside = true;
      props = this.props;
    }

    if (this.container && this.container.current) {
      if (
        this.$chartframeobj.charts.length > 1 &&
        props.tapane.length > 0 &&
        props.compare.length === 0
      )
        this.mainchartobj.setGridStyle(chartFactory.GRID_LABEL_Y);
      else
        this.mainchartobj.setGridStyle(
          chartFactory.GRID_LABEL_X | chartFactory.GRID_LABEL_Y
        );

      this.mainchartobj.resize(1, 1);

      let w = isChangeColor
        ? this.container.current.clientWidth - 1
        : this.container.current.clientWidth;
      let h = this.container.current.clientHeight;

      if (w === 0 || h === 0) {
        // console.log("width height");
        // console.log(this.container.current.clientWidth);
        // console.log(this.container.current.clientHeight);

        setTimeout(() => this.resizeChart(this.props), 500);
        return;
      }

      if (props.tapane.length > 0) {
        if (this.state.isOpen) {
          let taheight = 0;
          for (let i = 0; i < this.tachartobjList.length; i++) {
            let taobj = this.tachartobjList[i];
            taobj.tachartobj.resize(
              w,
              this.getHeight(
                this.container.current.clientHeight <= 600
                  ? 0
                  : parseInt(taobj.option.option.height)
              )
            );
            taheight += taobj.tachartobj.height;
            this.setState({ render: true });

            if (i < this.tachartobjList.length - 1)
              taobj.tachartobj.setGridStyle(
                taobj.tachartobj.gridStyle & ~chartFactory.GRID_LABEL_X
              );
            else
              taobj.tachartobj.setGridStyle(
                taobj.tachartobj.gridStyle | chartFactory.GRID_LABEL_X
              );
          }

          this.renderTooltips();

          taheight += 50 + this.getScrollPaneHeight();
          this.mainchartobj.resize(w, h - taheight);
        } else {
          let taheight = 0;
          for (let i = 0; i < this.tachartobjList.length; i++) {
            let taobj = this.tachartobjList[i];
            taobj.tachartobj.resize(
              w,
              this.getHeight(
                this.container.current.clientHeight <= 600
                  ? 0
                  : parseInt(taobj.option.option.height)
              )
            );
            taheight += taobj.tachartobj.height;
            this.setState({ render: true });

            if (i < this.tachartobjList.length - 1)
              taobj.tachartobj.setGridStyle(
                taobj.tachartobj.gridStyle & ~chartFactory.GRID_LABEL_X
              );
            else
              taobj.tachartobj.setGridStyle(
                taobj.tachartobj.gridStyle | chartFactory.GRID_LABEL_X
              );
          }

          this.renderTooltips();
          taheight += 20;
          this.mainchartobj.resize(w, h - taheight);
        }
      } else {
        // if (props.showCompare) {
        //     if (this.state.isOpen)
        //         this.mainchartobj.resize(w, h - 160);
        //     else
        //         this.mainchartobj.resize(w, h - 25);
        // } else {
        if (this.state.isOpen)
          this.mainchartobj.resize(w, h - (50 + this.getScrollPaneHeight()));
        else this.mainchartobj.resize(w, h - 15);
        // }
      }

      //scrollpanel
      if (this.$chartframeobj.charts.length > 1) {
        this.$chartframeobj.charts[1].resize(w, this.getScrollPaneHeight());
      }
    }
  };

  removeOverLay(ta) {
    let ids = [];
    let drawers = this.mainchartobj.drawers;

    switch (ta.ta) {
      case "SMA":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.SMA))
            ids.push(drawers[i].obj.name);
        }

        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }

        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "MEMA":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.MEMA))
            ids.push(drawers[i].obj.name);
        }

        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "TVMA":
        this.mainchartobj.removeDrawer(chartFactory.TVMA);
        this.mainchartobj.removeTracker(chartFactory.TVMA);
        break;
      case "SAR":
        this.mainchartobj.removeDrawer(chartFactory.SAR);
        this.mainchartobj.removeTracker(chartFactory.SAR);
        break;
      case "LRT":
        this.mainchartobj.removeDrawer(chartFactory.LRT);
        this.mainchartobj.removeTracker(chartFactory.LRT);
        break;
      case "ICH":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.ICHIMOKU))
            ids.push(drawers[i].obj.name);
        }

        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "BB":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.BOLLINGERBANDS))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        let tids = [];
        let trackers = this.mainchartobj.trackers;
        for (let i = 0; i < trackers.length; i++) {
          if (trackers[i].name.startsWith(chartFactory.BOLLINGERBANDS))
            tids.push(trackers[i].name);
        }
        for (let i = 0; i < tids.length; i++) {
          this.mainchartobj.removeTracker(tids[i]);
        }
        break;
      case "EMA":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.EMA))
            ids.push(drawers[i].obj.name);
        }

        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "WMA":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.WMA))
            ids.push(drawers[i].obj.name);
        }

        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "VAP":
        this.mainchartobj.removeDrawer(chartFactory.VOL_BY_PRICE);
        this.mainchartobj.removeTracker(chartFactory.VOL_BY_PRICE);
        break;
      case "ENV":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.ENVELOPE)) {
            ids.push(drawers[i].obj.name);
          }
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }

        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "PRR":
        this.mainchartobj.removeDrawer(chartFactory.PRR);
        break;
      case "VWAP":
        this.mainchartobj.removeDrawer(chartFactory.VWAP);
        this.mainchartobj.removeTracker(chartFactory.VWAP);
        break;
      case "WC":
        this.mainchartobj.removeDrawer(chartFactory.WEIGHTED_CLOSE);
        this.mainchartobj.removeTracker(chartFactory.WEIGHTED_CLOSE);
        break;
      case "TP":
        this.mainchartobj.removeDrawer(chartFactory.TP);
        break;
      case "KC":
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.KC))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        for (let i = 0; i < this.mainchartobj.trackers.length; i++) {
          this.mainchartobj.removeAllTrackers();
        }
        break;
      case "nooverlay":
      default:
        break;
    }
  }

  removeTAPane(ta) {
    let ids = [];
    let drawers = this.mainchartobj.drawers;
    switch (ta.ta) {
      case "Volume":
        this.$chartframeobj.remove(chartFactory.VOLUME);
        break;
      case "VE":
        this.$chartframeobj.remove(chartFactory.VE);
        break;
      case "Turnover":
        this.$chartframeobj.remove(chartFactory.TURNOVER);
        break;
      case "ADXR":
        this.$chartframeobj.remove(chartFactory.ADXR);
        break;
      case "MACD":
        this.$chartframeobj.remove(chartFactory.MACD);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.MACD))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "Bias":
        this.$chartframeobj.remove(chartFactory.BIAS);
        break;
      case "ALF":
        this.$chartframeobj.remove(chartFactory.ALF);
        break;
      case "VC":
        this.$chartframeobj.remove(chartFactory.CHAIKIN_VOL);
        break;
      case "ATR":
        this.$chartframeobj.remove(chartFactory.ATR);
        break;
      case "SD":
        this.$chartframeobj.remove(chartFactory.STD_DEV);
        break;
      case "VltyCC":
        this.$chartframeobj.remove(chartFactory.VOLATILITY);
        break;
      case "Vector":
        this.$chartframeobj.remove(chartFactory.VECTOR);
        break;
      case "KRI":
        this.$chartframeobj.remove(chartFactory.KRI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.KRI))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "NVI":
        this.$chartframeobj.remove(chartFactory.NVI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.NVI))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "PL":
        this.$chartframeobj.remove(chartFactory.PSY);
        break;
      case "CO":
        this.$chartframeobj.remove(chartFactory.CHAIKIN_OSC);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.CHAIKIN_OSC))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;

      case "RCI":
        this.$chartframeobj.remove(chartFactory.RCI);
        break;
      case "CCI":
        this.$chartframeobj.remove(chartFactory.CCI);
        break;
      case "MI":
        this.$chartframeobj.remove(chartFactory.MASS_INDEX);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.MASS_INDEX))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;

      case "EOM":
        this.$chartframeobj.remove(chartFactory.EOM);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.EOM))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "FI":
        this.$chartframeobj.remove(chartFactory.FORCE_INDEX);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.FORCE_INDEX))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "MFI":
        this.$chartframeobj.remove(chartFactory.MFI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.MFI))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "RMI":
        this.$chartframeobj.remove(chartFactory.RMI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.RMI))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "RSI":
        this.$chartframeobj.remove(chartFactory.RSI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.RSI))
            ids.push(drawers[i].obj.name);
        }
        this.mainchartobj.removeAllTrackers();
        break;
      case "SKD":
        this.$chartframeobj.remove(chartFactory.STOCHASTIC_SLOW);
        break;
      case "FKD":
        this.$chartframeobj.remove(chartFactory.STOCHASTIC_FAST);
        break;
      case "DMI":
        this.$chartframeobj.remove(chartFactory.DMI);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.DMI))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;
      case "UO":
        this.$chartframeobj.remove(chartFactory.UO);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.UO))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;

      case "DPO":
        this.$chartframeobj.remove(chartFactory.DPO);
        break;
      case "WAD":
        this.$chartframeobj.remove(chartFactory.WAD);
        break;
      case "Trix":
        this.$chartframeobj.remove(chartFactory.TRIX);
        for (let i = 0; i < drawers.length; i++) {
          if (drawers[i].obj.name.startsWith(chartFactory.TRIX))
            ids.push(drawers[i].obj.name);
        }
        for (let i = 0; i < ids.length; i++) {
          this.mainchartobj.removeDrawer(ids[i]);
        }
        break;

      case "VROC":
        this.$chartframeobj.remove(chartFactory.VROC);
        break;
      case "VOSC":
        this.$chartframeobj.remove(chartFactory.VOSC);
        break;
      case "ACD":
        this.$chartframeobj.remove(chartFactory.ACD);
        break;
      case "OBV":
        this.$chartframeobj.remove(chartFactory.OBV);
        break;
      case "VR":
        this.$chartframeobj.remove(chartFactory.VR);
        break;
      case "UPDW":
        this.$chartframeobj.remove(chartFactory.UPDW);
        break;
      case "POSC":
        this.$chartframeobj.remove(chartFactory.POSC);
        break;
      case "PROC":
        this.$chartframeobj.remove(chartFactory.PROC);
        break;
      case "MOM":
        this.$chartframeobj.remove(chartFactory.MOMENTUM);
        break;
      case "WR":
        this.$chartframeobj.remove(chartFactory.WILLIAM_R);
        break;
      case "PCV":
        this.$chartframeobj.remove(chartFactory.PCV);
        break;
      case "Margin":
        this.$chartframeobj.remove(chartFactory.Margin);
        break;
      case "RC":
        this.$chartframeobj.remove(chartFactory.RC);
        break;
      case "CC":
        this.$chartframeobj.remove(chartFactory.CC);
        break;
      case "UI":
        this.$chartframeobj.remove(chartFactory.UI);
        break;
      case "notapane":
      default:
        break;
    }
  }

  getUpdateURL = () => {
    let url = "";

    let api = "hts";
    let option = "d";
    let dataType = chartFactory.HTS_DATA;
    let gridType = chartFactory.GRID_DATE_ALIGN;
    switch (this.props.period) {
      case "1min":
        option = "1";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "5mins":
        option = "5";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "15mins":
      case "15mins5d":
        option = "15";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "1hour":
        option = "60";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      default:
        break;
    }

    this.mainchartobj.setGridType(gridType);
    //production
    let domain =
      window.location.protocol === "http:"
        ? config.URL + "/smbcc5/data/"
        : config.URL_HTTPS + "/smbcc5/data/";
    let last = null;
    if (this.series && this.series.points && this.series.spotCount > 0)
      last = this.series.points[this.series.spotCount - 1];
    let fr = "";
    if (last != null) {
      fr += last.year;

      if (last.month < 10) fr += "0";
      fr += last.month;

      if (last.day < 10) fr += "0";
      fr += last.day;
    }

    if (api === "hts") {
      if (
        this.props.period === "day_free" ||
        this.props.period === "week_free" ||
        this.props.period === "month_free"
      ) {
        url =
          domain +
          api +
          "?ric=" +
          this.props.code +
          "&i=" +
          this.props.periodOption.option +
          "&adj=1" +
          "&fr=" +
          fr +
          "&qid=" +
          new Date().getTime();
      } else {
        url =
          domain +
          api +
          "?ric=" +
          this.props.code +
          "&i=" +
          this.props.periodOption +
          "&adj=1" +
          "&fr=" +
          fr +
          "&qid=" +
          new Date().getTime();
      }
    } else {
      if (last != null) {
        if (last.hour < 10) fr += "0";
        fr += last.hour;

        if (last.min < 10) fr += "0";
        fr += last.min;
      }

      url =
        domain +
        api +
        "?ric=" +
        this.props.code +
        "&i=" +
        option +
        "&fr=" +
        fr +
        "&qid=" +
        new Date().getTime();
    }

    url = common.getHttpChart;
    // let data = {
    //   token:
    //     "xIZtZ6MRHzksYmlD%2Frdecri6CcwCRAgpwisQmDLWzOHwpVcUbwvguTGETu7H9bB6",
    //   ric: "AAPL.NB",
    //   i: "d",
    // };

    let data = {
      token: sessionStorage.getItem("token"),
      ric: this.props.code,
      i:
        api === "hts"
          ? this.props.period === "day_free" ||
            this.props.period === "week_free" ||
            this.props.period === "month_free"
            ? this.props.periodOption.option
            : this.props.periodOption
          : option,
      adj: api === "hts" ? 1 : undefined,
      fr: fr,
    };

    return [url, data];
  };

  isChartSeries = () => {
    return this.series?.points ? true : false;
  };

  refreshUpdateChart = (data, dataPeriod, quote) => {
    let currentDate = new Date();
    currentDate.setUTCHours(0);
    currentDate.setUTCMinutes(0);
    currentDate.setUTCSeconds(0);
    currentDate.setUTCMilliseconds(0);
    let streamingDate = new Date(quote.trd_date);
    let streamingDatetime = new Date(
      currentDate.getTime() + Number(quote.trd_time)
    );

    let isMTS = false;
    let interval = 0; // count the time to append the latest point
    let period;
    switch (this.props.period) {
      case "1min":
        interval = 1;
        isMTS = true;
        break;
      case "5mins":
        interval = 5;
        isMTS = true;
        break;
      case "15mins":
      case "15mins5d":
        interval = 15;
        isMTS = true;
        break;
      case "1hour":
        interval = 60;
        isMTS = true;
        break;
      default:
        break;
    }
    switch (this.props.period) {
      case "1min":
        period = "1";
        break;
      case "5mins":
        period = "5";
        break;
      case "15mins":
      case "15mins5d":
        period = "15";
        break;
      case "1hour":
        period = "60";
        break;
      default:
        break;
    }
    switch (this.props.periodOption) {
      case "d":
        period = "d";
        break;
      case "w":
        period = "w";
        break;
      case "m":
        period = "m";
        break;
      default:
        break;
    }

    // console.log("refreshUpdateChart");
    // console.log(currentDate, quote);

    if (dataPeriod !== period) {
      // console.log("dataPeriod !== period", dataPeriod, period);
      // console.log(this.props.period, this.props.periodOption);
      return;
    }

    // check if the data last is valid
    if (!quote?.last) {
      // console.log("invalid last");
      return;
    }

    // check if the data is ready for comparison
    if (
      quote.trd_time === 0 ||
      quote.trd_time === "0" ||
      quote.trd_time === undefined ||
      quote.trd_time === null
    ) {
      return;
    }

    // check if the date is update
    // if (
    //   currentDate.toLocaleDateString("sv-SE", {
    //     timeZone: "America/New_York",
    //   }) !== streamingDate.toLocaleDateString("sv-SE")
    // ) {
    //   return;
    // }

    // console.log(currentDate, quote);

    // tune the quote date and time
    quote = {
      ...quote,
      trd_date: streamingDate.toLocaleDateString("sv-SE"),
      trd_time: streamingDatetime
        .toLocaleTimeString("en-US", {
          hour12: false,
          timeZone: "America/New_York",
        })
        .substring(0, 5),
    };

    // console.log(quote);

    if (this.series && this.series.points && this.series.spotCount > 0) {
      let arr = this.series._parserawdata(data);
      let info = this.state.info;
      let fr = info.ses["1"].fr;
      let to = info.ses["1"].to;
      let quotePoint;
      // quotePoint = {
      //   close: 0.3123,
      //   date: new Date(2022, 6, 29, 9, 30),
      //   day: 29,
      //   high: 0.3149,
      //   hour: 9,
      //   low: 0.311,
      //   min: 30,
      //   month: 7,
      //   open: 0.3122,
      //   turnover: 930007883,
      //   volume: 297051,
      //   year: 2022,
      // };
      let lastPointDatetime = new Date(
        arr[arr.length - 1].year,
        arr[arr.length - 1].month - 1,
        arr[arr.length - 1].day,
        arr[arr.length - 1].hour,
        arr[arr.length - 1].min
      );

      let last = this.series.points[this.series.spotCount - 1];
      let datafr = ""; // series last point time

      if (last != null) {
        datafr += last.year;

        if (last.month < 10) datafr += "0";
        datafr += last.month;

        if (last.day < 10) datafr += "0";
        datafr += last.day;
      }

      if (isMTS && last != null) {
        if (last.hour < 10) datafr += "0";
        datafr += last.hour;

        if (last.min < 10) datafr += "0";
        datafr += last.min;
      }

      // series last point
      let dataDate = new Date(
        last.year,
        last.month - 1,
        last.day,
        last.hour,
        last.min
      );

      for (let i = 0; i < arr.length; i++) {
        let thisfr = "";

        thisfr += arr[i].year;

        if (arr[i].month < 10) thisfr += "0";
        thisfr += arr[i].month;

        if (arr[i].day < 10) thisfr += "0";
        thisfr += arr[i].day;

        if (isMTS) {
          if (arr[i].hour < 10) thisfr += "0";
          thisfr += arr[i].hour;

          if (arr[i].min < 10) thisfr += "0";
          thisfr += arr[i].min;
        }

        if (i === arr.length - 1) {
          // last row compare data with Quote data
          // check to append or overWrite #3205 #3288

          let trd_date = quote.trd_date;
          let quoteDateString = trd_date.replace(/-/g, "");
          if (isMTS)
            quoteDateString =
              quoteDateString + quote.trd_time.replace(/:/g, "");
          let quoteDate = new Date(
            trd_date.substring(0, 4),
            trd_date.substring(5, 7) - 1,
            trd_date.substring(8),
            quote.trd_time.substring(0, 2),
            quote.trd_time.substring(3)
          );
          let quoteDateForHTS = new Date(
            trd_date.substring(0, 4),
            trd_date.substring(5, 7) - 1,
            trd_date.substring(8)
          );
          let thisDate = new Date(
            arr[i].year,
            arr[i].month - 1,
            arr[i].day,
            arr[i].hour,
            arr[i].min
          );
          let startDate = new Date(
            trd_date.substring(0, 4),
            trd_date.substring(5, 7) - 1,
            trd_date.substring(8),
            parseInt(fr.substr(0, 2)),
            parseInt(fr.substr(3, 2))
          );
          let endDate = new Date(
            trd_date.substring(0, 4),
            trd_date.substring(5, 7) - 1,
            trd_date.substring(8),
            parseInt(to.substr(0, 2)),
            parseInt(to.substr(3, 2))
          );

          // quoteDate - streaming data datetime
          // quoteDateString - streaming data date string (for hts)
          // quoteDateForHTS - streaming data datetime (for hts)
          // thisDate - hts/mts last point datetime
          // lastPointDatetime - hts/mts last point datetime
          // thisfr - hts/mts current point date string
          // startDate - trade day open datetime
          // endDate - trade day close datetime
          // dataDate - chart series last point datetime
          // datafr - chart series last point date string

          if (
            (isMTS && quoteDate.getTime() === thisDate.getTime()) ||
            (!isMTS && quoteDateString === thisfr)
          ) {
            //arr[i] = { ...arr[1] };
            //OverWrite
            arr[i].close = Number(quote.last);

            if (arr[i].high < quote.last) arr[i].high = Number(quote.last);
            if (arr[i].low > quote.last) arr[i].low = Number(quote.last);
          } else if (
            !isMTS ||
            (quoteDate >= startDate && quoteDate <= endDate)
          ) {
            let nextAppendTime = new Date(
              arr[i].year,
              arr[i].month - 1,
              arr[i].day,
              arr[i].hour,
              arr[i].min + interval
            );
            //append
            // if mts, check interval time is valid (1min/5mins/15mins/15mins5d/1hour)
            if (
              (isMTS &&
                quoteDate > dataDate &&
                (this.props.period === "1min" ||
                  (this.props.period === "5mins" &&
                    quoteDate.getMinutes() % 5 === 0) ||
                  (this.props.period === "15mins" &&
                    quoteDate.getMinutes() % 15 === 0) ||
                  (this.props.period === "15mins5d" &&
                    quoteDate.getMinutes() % 15 === 0) ||
                  (this.props.period === "1hour" &&
                    quoteDate.getMinutes() === 0))) ||
              (!isMTS && quoteDateForHTS > dataDate)
            ) {
              // console.log("append");
              // console.log(quoteDate);
              // console.log(dataDate);

              quotePoint = { ...arr[i] };
              quotePoint.close = Number(quote.last);
              quotePoint.year = parseInt(trd_date.substring(0, 4));
              quotePoint.month = parseInt(trd_date.substring(5, 7));
              quotePoint.day = parseInt(trd_date.substring(8));

              let currentDate = new Date(
                quotePoint.year,
                quotePoint.month - 1,
                quotePoint.day
              );

              if (quotePoint.high < quote.last)
                quotePoint.high = Number(quote.last);
              if (quotePoint.low > quote.last)
                quotePoint.low = Number(quote.last);

              if (isMTS || !isMTS) {
                quotePoint.hour = parseInt(quote.trd_time.substring(0, 2));
                quotePoint.min = parseInt(quote.trd_time.substring(3));
                currentDate.setHours(quotePoint.hour, quotePoint.min);

                quotePoint.open = Number(quote.last);
                quotePoint.high = Number(quote.last);
                quotePoint.low = Number(quote.last);
                quotePoint.close = Number(quote.last);
              }

              quotePoint.date = currentDate;
            } else {
              // console.log("not append, update last chart point");

              last.close = Number(quote.last);

              if (last.high < quote.last) last.high = Number(quote.last);
              if (last.low > quote.last) last.low = Number(quote.last);

              // console.log(last);
              this.updateChart(this.nextProps.current);
              return;
              // this.series.update(last);
            }
          } else {
            // console.log("Out of trade time, overWrite the latest point");
            // Out of trade time, overWrite the latest point
            arr[i].close = Number(quote.last);

            if (arr[i].high < quote.last) arr[i].high = Number(quote.last);
            if (arr[i].low > quote.last) arr[i].low = Number(quote.last);
          }
        }

        let isappend;
        if (thisfr === datafr) {
          // Update
          // console.log("this.series.update(arr[i])");
          this.series.update(arr[i]);
        } else {
          // Append
          let startDate = new Date(
            arr[i].year,
            arr[i].month - 1,
            arr[i].day,
            parseInt(fr.substr(0, 2)),
            parseInt(fr.substr(3, 2))
          );
          let endDate = new Date(
            arr[i].year,
            arr[i].month - 1,
            arr[i].day,
            parseInt(to.substr(0, 2)),
            parseInt(to.substr(3, 2))
          );
          let thisDate = new Date(
            arr[i].year,
            arr[i].month - 1,
            arr[i].day,
            arr[i].hour,
            arr[i].min
          );
          if (
            (!isMTS && thisDate > dataDate) ||
            (thisDate >= startDate &&
              thisDate <= endDate &&
              thisDate > dataDate)
          ) {
            // console.log("this.series.append(arr[i])");
            isappend = true;
            this.series.append(arr[i]);
            if (
              this.mainchartobj.axisX.endIndex ===
              this.mainchartobj.series.spotCount - 2
            ) {
              this.mainchartobj.slideX(-1);
            }
          }
        }
      }

      if (quotePoint) {
        // console.log("this.series.append(quotePoint)");
        // console.log("quotePoint");
        // console.log(quotePoint);
        // console.log(quotePoint.date);
        lastPointDatetime = quotePoint.date;
        this.series.append(quotePoint);
        if (
          this.mainchartobj.axisX.endIndex ===
          this.mainchartobj.series.spotCount - 2
        ) {
          this.mainchartobj.slideX(-1);
        }
      }

      // finish all append, check if market just open now, add future dates and slide to end
      let todayOpen = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: this.state.info.tz,
        })
      );
      let todayClose = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: this.state.info.tz,
        })
      );
      let _fr = this.state.info.ses["1"].fr;
      let _to = this.state.info.ses["1"].to;
      todayOpen.setHours(_fr.split(":")[0], _fr.split(":")[1], 0, 0);
      todayClose.setHours(_to.split(":")[0], _to.split(":")[1], 0, 0);
      if (Number(_to.split(":")[0]) - Number(_fr.split(":")[0]) < 0) {
        // add 1 day in todayClose if the market open across midnight
        todayClose.setDate(todayClose.getDate() + 1);
      }
      if (
        this.state.period === "1min" &&
        !this.refIsDoneOn1MinFutureDateHandling.current &&
        lastPointDatetime.getTime() === todayOpen.getTime()
      ) {
        this.mainchartobj.series.futureDates(
          this.getFutureDates(this._currentemptyfuturepoints, this.state.period)
        );
        // slide x axis to market open period if market is opening after calling getFutureDates()
        this.mainchartobj.show(todayOpen, todayClose);
        this.tachartobjList.forEach((tachartobj) => {
          tachartobj.tachartobj.show(todayOpen, todayClose);
        });
        this.scrollcharobj.show(this.mainchartobj);
        this.refIsDoneOn1MinFutureDateHandling.current = true;
      }

      this.mainchartobj.draw(chartFactory.REASON_DATA_UPDATE);
      this.updateChart(this.nextProps.current);
    }
  };

  // only update current last candlestick
  // not use this now, all go to refreshUpdateChart
  streamingUpdateChart = (data) => {
    let streamingDate = new Date(data.trd_date);
    let streamingTime = new Date(Number(data.trd_time));
    let streamingDatetime = new Date(
      streamingDate.getFullYear(),
      streamingDate.getMonth(),
      streamingDate.getDate(),
      streamingTime.getHours(),
      streamingTime.getMinutes()
    );

    let last = this.series.points[this.series.spotCount - 1];

    // console.log("streamingUpdateChart");
    // console.log(streamingDatetime);
    // console.log(last);
  };

  updateMinChart = () => {
    if (
      this.props.period === "1min" ||
      this.props.period === "5mins" ||
      this.props.period === "15mins" ||
      this.props.period === "15mins5d" ||
      this.props.period === "1hour"
    ) {
      let api = "mts";
      let option = "d";
      let dataType = chartFactory.MTS_DATA;
      switch (this.props.period) {
        case "1min":
          option = "1";
          break;
        case "5mins":
          option = "5";
          break;
        case "15mins":
        case "15mins5d":
          option = "15";
          break;
        case "1hour":
          option = "60";
          break;
        default:
          break;
      }

      let last = this.series.points[this.series.spotCount - 1];
      let out = "";
      if (last != null) {
        out += last.year;

        if (last.month < 10) out += " 0";
        out += last.month;

        if (last.day < 10) out += "0";
        out += last.day;

        if (last.hour < 10) out += "0";
        out += last.hour;

        if (last.min < 10) out += "0";
        out += last.min;
      }

      // let date = new Date(Date.now());
      // let out =
      //   date.toLocaleString("en-us", { year: "numeric" }) +
      //   date.toLocaleString("en-us", { month: "2-digit" }) +
      //   date.toLocaleString("en-us", { day: "2-digit" }) +
      //   date.toLocaleString("en-GB", { hour: "2-digit" }) +
      //   date.toLocaleString("en-GB", { minute: "2-digit" });
      // out = out.replace(":", "");

      this.setState({ dataType: dataType });
      let domain =
        window.location.protocol === "http:"
          ? config.URL + "/smbcc5/data/"
          : config.URL_HTTPS + "/smbcc5/data/";
      let url =
        domain +
        api +
        "?ric=" +
        this.props.code +
        "&i=" +
        option +
        "&fr=" +
        out +
        "&token=" +
        this.props.token +
        "&qid=" +
        new Date().getTime();
      //console.log("xxx");
      this.updateSeries = this.$chartframeobj.data(
        dataType,
        this.props.ccy,
        url,
        { successFunc: this.updateReady, decimalPlace: 2 }
      );
      this.updateSeries.open();
    }
  };
  // points: {
  // date: Date;
  // year: any;
  // month: any;
  // day: any;
  // hour: any;
  // min: any;
  // open: any;
  // high: any;
  // low: any;
  // close: any;
  // volume: any;
  // turnover: any;}[]
  updateReady = () => {
    let points = this.updateSeries.points;

    if (points != null && points.length > 0) {
      points.forEach((entry) => {
        var date = this.updateSeries.convTimeStr(entry.date);
        var index = this.series.indexOf(date);
        if (index !== null && index !== -1 && index !== 0) {
          // Update
          this.mainchartobj.series.update(entry);
        } else {
          // Append
          this.mainchartobj.series.append(entry);
        }
      });
    }

    this.scrollcharobj.show(this.mainchartobj);

    if (this.state.showTracker && !this.state.showCompare) {
      if (points !== null) {
        this.mainchartobj.tracker("price", points[points.length - 1].close);
      }
    }

    //this.mainchartobj.series.futureDates(this.getFutureDates(this._currentemptyfuturepoints, this.state.period));
  };

  updateChart(nextProps) {
    this.setState({ mainTooltip: undefined, TATooltip: "" });
    // this.props.setOHLCTable(<div></div>);

    switch (this.props.type) {
      case "candlestick":
        this.mainchartobj.removeCandlestick();
        break;
      case "bar":
        this.mainchartobj.removeOHLC();
        break;
      case "line":
        this.mainchartobj.removeLine();
        break;
      case "histogram":
        this.mainchartobj.removeHistogram();
        break;
      default:
        break;
    }
    this.mainchartobj.removeTracker("price");

    this.props.overlay.forEach((e) => {
      this.compseriesList = new Array();
      this.removeOverLay(e);
    });

    this.props.tapane.forEach((e) => {
      this.tachartobjList = new Array();
      this.removeTAPane(e);
    });

    if (nextProps.showEvent) {
      if (this.props.eventType !== nextProps.eventType) {
        this.mainchartobj.removeDrawer(chartFactory.EVENT);
      }
    } else {
      this.mainchartobj.removeDrawer(chartFactory.EVENT);
    }

    // if (!nextProps.showCompare) {
    //   if (this.mainchartobj.drawers[0] !== undefined)
    //     this.mainchartobj.drawers[0].obj.removeSeries(this.compseries);

    //   //if (!nextProps.showCompare) {
    //     this.mainchartobj.removeBaseComparison();
    //     //this.series.decimalPlace = 5;
    //   //}
    // }

    if (nextProps.showCompare) {
      if (nextProps.showCompare) {
        this.compseriesList = new Array();
        this.mainchartobj.addBaseComparison2(100, {
          color: "#" + this.state.lineColor,
          thickness: this.state.lineThickness,
          blazeColor: "#" + this.state.crossHairPointColor,
          blazeRadius: this.state.crossHairPointSize,
        });

        let compare = nextProps.compare;
        compare.forEach((item) => {
          this.addComparison(nextProps, item);
        });
      }
    } else {
      if (nextProps.showMainTracker) {
        if (nextProps.showTracker) {
          if (
            this.mainchartobj.series &&
            this.mainchartobj.series.points &&
            this.mainchartobj.series.points.length > 0
          ) {
            let points = this.mainchartobj.series.points;
            let value = points[this.mainchartobj.series.spotCount - 1].close;
            this.mainchartobj.addPriceTracker2("price", value, {
              color: "#" + this.state.priceTrackColor,
              invertColor: "#" + this.state.priceTrackColor,
              textColor: "#" + this.state.priceTrackFontColor,
              thickness: this.state.priceTrackThickness,
              dashstyle:
                this.state.priceTrackStyle === "d"
                  ? chartFactory.TRACKER_DASH
                  : 0,
              front: true,
            });
          }
        }
        if (nextProps.type === "candlestick") {
          let style = {
            upColor: "#" + nextProps.option.downColor,
            upFillColor: "#" + nextProps.option.downFillColor,
            downColor: "#" + nextProps.option.upColor,
            downFillColor: "#" + nextProps.option.upFillColor,
          };
          this.mainchartobj.addCandlestick2(style, 1);
        } else if (nextProps.type === "histogram") {
          let style = {
            upColor: "#" + nextProps.option.downColor,
            upFillColor: "#" + nextProps.option.downFillColor,
            downColor: "#" + nextProps.option.upColor,
            downFillColor: "#" + nextProps.option.upFillColor,
          };
          this.mainchartobj.addHistogram2(style, 1);
        } else if (nextProps.type === "bar") {
          let styleobj = {
            upColor: "#" + nextProps.option.barColor,
            downColor: "#" + nextProps.option.barColor,
          };
          this.mainchartobj.addOHLC2(styleobj, 1);
        } else {
          let styleobj = {};
          if (nextProps.option.lineGradientEnable) {
            styleobj = {
              color: "#" + nextProps.option.lineColor,
              fillColor: "#" + nextProps.option.lineFillColor,
              fillColor2: "#" + nextProps.option.lineFillColor2,
              thickness: nextProps.option.lineThickness,
              style: nextProps.option.lineStyle === "s" ? 0 : "d",
              blazeColor: "#" + this.state.crossHairPointColor,
              blazeRadius: this.state.crossHairPointSize,
            };
          } else {
            styleobj = {
              color: "#" + nextProps.option.lineColor,
              thickness: nextProps.option.lineThickness,
              style: nextProps.option.lineStyle === "s" ? 0 : "d",
              blazeColor: "#" + this.state.crossHairPointColor,
              blazeRadius: this.state.crossHairPointSize,
            };
          }

          if (nextProps.option.showTracker) {
            if (
              this.mainchartobj.series &&
              this.mainchartobj.series.points &&
              this.mainchartobj.series.points.length > 0
            ) {
              this.mainchartobj.addFieldTracker(
                "close",
                "close",
                undefined,
                "#FFFFFF",
                "#" + nextProps.option.lineColor,
                false
              );
            }
          } else {
            this.mainchartobj.removeTracker("close");
          }

          this.mainchartobj.addLine2(styleobj, 1);
        }
      }

      if (nextProps.showTa) {
        //this.series.decimalPlace = 5;
        if (nextProps.overlay.length > 0) {
          nextProps.overlay.forEach((e) => {
            this.addOverlayTA(nextProps, e);
          });
        }
        if (nextProps.tapane.length > 0) {
          nextProps.tapane.forEach((e) => {
            this.addTAPane(nextProps, e);
          });
        }
      }
    }

    if (nextProps.showEvent)
      this.getEvent(nextProps.eventType, this.state.frStr, this.state.toStr);
    if (this.series && this.series.points && !nextProps.showCompare) {
      let frSplit = this.state.frStr.split("/");
      let frDate = new Date(frSplit[0], frSplit[1] - 1, frSplit[2]);
      this.setState({
        haveChart:
          this.series.spotCount !== 0 &&
          this.series.points[this.series.spotCount - 1].date > frDate,
      });
    }

    //console.log(this.state)
    this.resizeChart(nextProps);
  }

  // props: any, type: string
  getPeriodDate(props, type) {
    let date = new Date();
    let ds;
    if (props.period === "y") {
      ds =
        date.getFullYear().toString() +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : (date.getMonth() + 1).toString()) +
        (date.getDate() < 10
          ? "0" + date.getDate()
          : date.getDate().toString());
    } else if (props.period === "m") {
      date.setMonth(date.getMonth() - 1);
    } else {
      if (props.period.endsWith("m")) {
        let d = Number(props.period.substring(0, props.period.length - 1));
        date.setMonth(date.getMonth() - d);
      } else if (props.period.endsWith("y")) {
        date.setFullYear(
          date.getFullYear() -
          Number(props.period.substring(0, props.period.length - 1))
        );
      }
    }

    ds =
      date.getFullYear().toString() +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : (date.getMonth() + 1).toString()) +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString());

    return ds;
  }

  renderChart = (props) => {
    // console.log("renderChart");
    this.setState({ mainTooltip: undefined, TATooltip: "" });
    this.props.setOHLCTable(<div></div>);

    switch (this.props.type) {
      case "candlestick":
        this.mainchartobj.removeCandlestick();
        break;
      case "bar":
        this.mainchartobj.removeOHLC();
        break;
      case "line":
        this.mainchartobj.removeLine();
        break;
      case "histogram":
        this.mainchartobj.removeHistogram();
        break;
      default:
        break;
    }
    this.mainchartobj.removeTracker("price");

    this.props.overlay.forEach((e) => {
      this.compseriesList = new Array();
      this.removeOverLay(e);
    });

    this.props.tapane.forEach((e) => {
      this.tachartobjList = new Array();
      this.removeTAPane(e);
    });

    if (props.showEvent) {
      if (this.props.eventType !== props.eventType) {
        this.mainchartobj.removeDrawer(chartFactory.EVENT);
      }
    } else {
      this.mainchartobj.removeDrawer(chartFactory.EVENT);
    }

    let api = "hts";
    let option = "d";
    let dataType = chartFactory.HTS_DATA;
    let gridType = chartFactory.GRID_DATE_ALIGN;
    switch (props.period) {
      case "1min":
        option = "1";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "5mins":
        option = "5";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "15mins":
      case "15mins5d":
        option = "15";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "1hour":
        option = "60";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      default:
        break;
    }

    this.mainchartobj.setGridType(gridType);
    //production
    let domain =
      window.location.protocol === "http:"
        ? config.URL + "/smbcc5/data/"
        : config.URL_HTTPS + "/smbcc5/data/";

    let url = "";
    if (api === "hts") {
      if (
        props.period === "day_free" ||
        props.period === "week_free" ||
        props.period === "month_free"
      ) {
        url =
          domain +
          api +
          "?ric=" +
          props.code +
          "&i=" +
          props.periodOption.option +
          "&adj=" +
          1 +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      } else {
        url =
          domain +
          api +
          "?ric=" +
          props.code +
          "&i=" +
          props.periodOption +
          "&adj=" +
          1 +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      }
    } else {
      url =
        domain +
        api +
        "?ric=" +
        props.code +
        "&i=" +
        option +
        "&token=" +
        props.token +
        "&qid=" +
        new Date().getTime();
    }

    let d = this.indicesDP(props.code);
    if (api === "mts") {
      d = this.indicesDP(props.code);
    }

    url = common.getHttpChart;
    // let data = {
    //   token:
    //     "xIZtZ6MRHzksYmlD%2Frdecri6CcwCRAgpwisQmDLWzOHwpVcUbwvguTGETu7H9bB6",
    //   ric: "AAPL.NB",
    //   i: "d",
    // };

    let data = {
      token: sessionStorage.getItem("token"),
      ric: props.code,
      i:
        api === "hts"
          ? props.period === "day_free" ||
            props.period === "week_free" ||
            props.period === "month_free"
            ? props.periodOption.option
            : props.periodOption
          : option,
      adj: api === "hts" ? 1 : undefined,
    };

    this.series = this.$chartframeobj.data(dataType, props.code, url, data, {
      successFunc: this.dataReady,
      decimalPlace: 2,
    });
    this.mainchartobj.data(this.series);
    this.series.open();

    //scrollpane
    this.scrollcharobj.data(this.series);

    if (props.showMainTracker) {
      if (props.type === "candlestick") {
        let style = {
          upColor: "#" + props.option.downColor,
          upFillColor: "#" + props.option.downFillColor,
          downColor: "#" + props.option.upColor,
          downFillColor: "#" + props.option.upFillColor,
        };
        this.mainchartobj.addCandlestick2(style, 1);
      } else if (props.type === "histogram") {
        let style = {
          upColor: "#" + props.option.downColor,
          upFillColor: "#" + props.option.downFillColor,
          downColor: "#" + props.option.upColor,
          downFillColor: "#" + props.option.upFillColor,
        };
        this.mainchartobj.addHistogram2(style, 1);
      } else if (props.type === "bar") {
        let styleobj = {
          upColor: "#" + props.option.barColor,
          downColor: "#" + props.option.barColor,
        };
        this.mainchartobj.addOHLC2(styleobj, 1);
      } else {
        let styleobj = {};

        if (this.props.option.lineGradientEnable) {
          styleobj = {
            color: "#" + props.option.lineColor,
            fillColor: "#" + props.option.lineFillColor,
            fillColor2: "#" + props.option.lineFillColor2,
            thickness: props.option.lineThickness,
            style: props.option.lineStyle === "s" ? 0 : "d",
            blazeColor: "#" + this.state.crossHairPointColor,
            blazeRadius: this.state.crossHairPointSize,
          };
        } else {
          styleobj = {
            color: "#" + props.option.lineColor,
            thickness: props.option.lineThickness,
            style: props.option.lineStyle === "s" ? 0 : "d",
            blazeColor: "#" + this.state.crossHairPointColor,
            blazeRadius: this.state.crossHairPointSize,
          };
        }

        if (props.option.showTracker)
          if (
            this.mainchartobj.series &&
            this.mainchartobj.series.points &&
            this.mainchartobj.series.points.length > 0
          ) {
            this.mainchartobj.addFieldTracker(
              "close",
              "close",
              undefined,
              "#FFFFFF",
              "#" + props.option.lineColor,
              false
            );
          } else {
            this.mainchartobj.removeTracker("close");
          }

        this.mainchartobj.addLine2(styleobj, 1);
      }
    }

    if (props.compare.length === 0) {
      if (props.showTa) {
        //this.series.decimalPlace = 5;
        if (props.overlay.length > 0) {
          props.overlay.forEach((e) => {
            this.addOverlayTA(props, e);
          });
        }
        if (props.tapane.length > 0) {
          // this.setState({ tapane: props.tapane }, () => {
          //     props.tapane.forEach((e) => {
          //         this.addTAPane(props, e);
          //     });
          // })

          this.setState({ tapane: props.tapane });
          props.tapane.forEach((e) => {
            this.addTAPane(props, e);
          });
        }
      }
    } else {
      if (props.compare.length !== 0 && props.showCompare) {
        this.mainchartobj.addBaseComparison2(100, {
          color: "#" + this.state.lineColor,
          thickness: this.state.lineThickness,
          blazeColor: "#" + this.state.crossHairPointColor,
          blazeRadius: this.state.crossHairPointSize,
        });

        let compare = props.compare;
        compare.forEach((item) => {
          this.addComparison(props, item);
        });
      }
    }

    this.resizeChart(props);
    props.onFinishLoading();
    // console.log("renderChart end");
  };

  addComparison(props, item) {
    let compseries;
    switch (props.type) {
      case "candlestick":
        this.mainchartobj.removeCandlestick();
        break;
      case "bar":
        this.mainchartobj.removeOHLC();
        break;
      case "line":
        this.mainchartobj.removeLine();
        break;
      case "histogram":
        this.mainchartobj.removeHistogram();
        break;
      default:
        break;
    }

    let api = "hts";
    let option = "d";
    let dataType = chartFactory.HTS_DATA;
    let gridType = chartFactory.GRID_DATE_ALIGN;
    switch (props.period) {
      case "1min":
        option = "1";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "5mins":
        option = "5";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "15mins":
      case "15mins5d":
        option = "15";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      case "1hour":
        option = "60";
        api = "mts";
        dataType = chartFactory.MTS_DATA;
        gridType = chartFactory.GRID_TIME_ALIGN;
        break;
      default:
        break;
    }

    this.mainchartobj.setGridType(gridType);

    let domain =
      window.location.protocol === "http:"
        ? config.URL + "/smbcc5/data/"
        : config.URL_HTTPS + "/smbcc5/data/";

    let url = "";
    if (api === "hts") {
      if (
        props.period === "day_free" ||
        props.period === "week_free" ||
        props.period === "month_free"
      ) {
        url =
          domain +
          api +
          "?ric=" +
          item.compare.value +
          "&i=" +
          props.periodOption.option +
          "&adj=" +
          1 +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      } else {
        url =
          domain +
          api +
          "?ric=" +
          item.compare.value +
          "&i=" +
          props.periodOption +
          "&adj=" +
          1 +
          "&token=" +
          props.token +
          "&qid=" +
          new Date().getTime();
      }
    } else {
      url =
        domain +
        api +
        "?ric=" +
        item.compare.value +
        "&i=" +
        option +
        "&token=" +
        props.token +
        "&qid=" +
        new Date().getTime();
    }
    //console.log(dataType)
    url = common.getHttpChart;

    let data = {
      token: sessionStorage.getItem("token"),
      ric: props.code,
      i:
        api === "hts"
          ? props.period === "day_free" ||
            props.period === "week_free" ||
            props.period === "month_free"
            ? props.periodOption.option
            : props.periodOption
          : option,
      adj: api === "hts" ? 1 : undefined,
    };

    compseries = this.$chartframeobj.data(
      dataType,
      item.compare.value,
      url,
      data,
      {
        successFunc: this.dataReady2,
        decimalPlace: 2,
      }
    );
    compseries.open();

    let compare = { item: item, compseries: compseries };
    if (
      this.compseriesList.find((object) => {
        return object.item.compare.name === item.compare.name;
      }) === undefined
    )
      this.compseriesList.push(compare);
  }

  dataReady2 = () => {
    let haveCompareChart = true;
    this.compseriesList.forEach((item) => {
      if (item.compseries.points) {
        if (item.compseries.spotCount > 0)
          this.mainchartobj.drawers[0].obj.addSeries2(item.compseries, {
            color: "#" + item.item.option.color,
            thickness: item.item.option.thickness,
            style: item.item.option.style,
            blazeColor: "#" + this.state.crossHairPointColor,
            blazeRadius: this.state.crossHairPointSize,
          });
        else haveCompareChart = false;
      }
    });
    this.setState({ haveCompareChart: haveCompareChart });
    this.mainchartobj.draw();
    // this.dataReady();
  };

  RCDataReady = () => {
    let tachartobj = "";
    let taoption = {};
    this.tachartobjList.forEach((item) => {
      if (item.option.ta === "RC") {
        taoption = item.option.option;
        tachartobj = item.tachartobj;
      }
    });
    if (!this.mainchartobj.series.points) return;
    let idxric = this.ta.option.index;
    let pts = this.mainchartobj.series.points;
    let idxs = this.RCDataSeries.points;
    let pt, idx;
    var j = 0;

    if (idxs != null && tachartobj != null) {
      do {
        pt = pts[0];
        idx = idxs[j];
        if (Math.abs(idx.date - pt.date) !== 0) j++;
        else break;
      } while (true);
      for (let i = 0; i < pts.length; i++) {
        pt = pts[i];
        idx = idxs[j];
        if (idx) {
          const diffTime = Math.abs(idx.date - pt.date);
          //console.log(diffTime);
          let diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          //var diff = Date.compare(idx.date, pt.date);
          if (diff === 0) {
            // Good, same date, fill in the MRGN datapoint
            pt.idx = idx.close;
            j++; // ...and advance to the next MRGN datapoint
          } else if (diff > 0) {
            // Oh, no index data today (but more in the future), just fill in with last MRGN data
            if (i > 0) {
              var lastpt = pts[i - 1];
              pt.idx = lastpt.idx;
            }
          } else {
            // Skip this non-existing datapoint in the main series
            while (diff < 0) {
              j++;
              idx = idxs[j];
              if (idx) {
                const diffTime = Math.abs(idx.date - pt.date);
                diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                //diff = Date.compare(idx.date, pt.date);
              } else {
                // No more index data...?!
                diff = 0;
              }
            }

            // rollback i and do this date on main series again ;)
            i--;
          }
        } else {
          // No more index data...?!
          if (i > 0) {
            var lastpt = pts[i - 1];
            pt.idx = lastpt.idx;
          }
        }
      }

      this.mainchartobj.series.idxric = idxric; // ...also save the index RIC code here for future reference
      tachartobj.data(this.mainchartobj.series);

      if (this.RCDataSeries.spotCount !== 0) {
        tachartobj.addRC({
          period:
            taoption.period === "" || parseInt(taoption.period) === 0
              ? 0
              : parseInt(taoption.period),
          color: "#" + taoption.color,
          thickness: taoption.thickness,
          style: taoption.style,
        });

        if (this.ta.option.showTracker) {
          tachartobj.addFieldTracker(
            chartFactory.RC,
            chartFactory.RC,
            undefined,
            "white",
            "#" + this.ta.option.color,
            false,
            2
          );
        }
      }

      tachartobj.show(this.mainchartobj);
    }

    this.resizeChart(this.props);
  };

  UPDWDataReady = () => {
    let tachartobj = "";
    let taoption = {};
    this.tachartobjList.forEach((item) => {
      if (item.option.ta === "UPDW") {
        taoption = item.option.option;
        tachartobj = item.tachartobj;
      }
    });
    //console.log(this.UPDWDataSeries);
    if (!this.mainchartobj.series.points) return;
    let pts = this.mainchartobj.series.points;
    let mktstats = this.UPDWDataSeries.points;
    let pt, mktstat;
    let j = 0;

    if (mktstats != null && tachartobj != null) {
      for (let i = 0; i < pts.length; i++) {
        pt = pts[i];

        if (j < mktstats.length) {
          mktstat = mktstats[j];
          const diffTime = Math.abs(mktstat.date - pt.date);
          let diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diff === 0) {
            // Good, same date, fill in the MKTSTATS datapoint
            pt.adv = mktstat.high;
            pt.dec = mktstat.low;
            j++; // ...and advance to the next MKTSTATS datapoint
          } else if (diff > 0) {
            // Oh, no MKTSTATS data today (but more in the future), skip this as this is in fact an error condition?!
            pt.adv = -1;
            pt.dec = -1;
          } else {
            // Skip this non-existing datapoint in the main series
            while (diff < 0) {
              j++;
              if (j < mktstats.length) {
                mktstat = mktstats[j];

                const diffTime = Math.abs(mktstat.date - pt.date);
                diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              } else {
                // No more MKTSTATS data...?!
                diff = 0;
              }
            }

            // rollback i and do this date on main series again ;)
            i--;
          }
        } else {
          // No more MKTSTATS data...?!
          pt.adv = -1;
          pt.dec = -1;
        }
      }

      tachartobj.data(this.mainchartobj.series);
      tachartobj.idx = taoption.idx; // save this for reference, check it when update()...
      for (let i = 0; i < 2; i++) {
        let para = taoption.options[i];
        if (para.enabled && this.UPDWDataSeries.spotCount !== 0) {
          let options = {
            period:
              para.period === "" || parseInt(para.period) === 0
                ? 1
                : parseInt(para.period),
            color: "#" + para.color,
            thickness: para.thickness,
            style: para.style,
          };

          tachartobj.addUPDW(options);

          if (para.showTracker) {
            let name =
              chartFactory.UPDW +
              "_" +
              (para.period === "" || parseInt(para.period) === 0
                ? 1
                : parseInt(para.period));
            tachartobj.addFieldTracker(
              name,
              name,
              undefined,
              "white",
              "#" + para.color,
              false,
              2
            );
          }
        }
      }

      tachartobj.show(this.mainchartobj);
    }

    this.resizeChart(this.props);
  };

  UPDWDataError = () => {
    let tachartobj = "";
    this.tachartobjList.forEach((item) => {
      if (item.option.ta === "UPDW") {
        tachartobj = item.tachartobj;
      }
    });
    var pts = this.mainchartobj.series.points;
    var pt;
    for (var i = 0; i < pts.length; i++) {
      pt = pts[i];
      pt.adv = -1;
      pt.dec = -1;
    }
    tachartobj.data(this.mainchartobj.series);
    tachartobj.show(this.mainchartobj);
  };

  // isCheckMarketOpen: for add point when market open time (1min chart show today point only, no need slide)
  getFutureDates = (count, int, isCheckMarketOpen) => {
    let i = this.state.periodOption === "" ? "d" : this.state.periodOption;
    if (
      this.state.period === "day_free" ||
      this.state.period === "week_free" ||
      this.state.period === "month_free"
    ) {
      i = this.state.periodOption.option;
    }
    int = i;
    var res = [];
    if (this.series.spotCount > 0) {
      let pt = this.mainchartobj.series.points[this.series.spotCount - 1];
      if (pt && !isNaN(pt.date)) {
        let todayOpen = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: this.state.info.tz,
          })
        );
        let todayClose = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: this.state.info.tz,
          })
        );
        let _fr = this.state.info.ses["1"].fr;
        let _to = this.state.info.ses["1"].to;
        let dt = new Date(pt.date);
        todayOpen.setHours(_fr.split(":")[0], _fr.split(":")[1], 0, 0);
        todayClose.setHours(_to.split(":")[0], _to.split(":")[1], 0, 0);
        if (Number(_to.split(":")[0]) - Number(_fr.split(":")[0]) < 0) {
          todayClose.setDate(todayClose.getDate() + 1);
        }

        // logic of handling market open time in period 1min, display the market open period
        if (
          this.state.period === "1min" &&
          todayOpen.getTime() <= dt.getTime()
        ) {
          count = Math.round((todayClose.getTime() - dt.getTime()) / 60000);
          for (let i = 0; i < count; i++) {
            dt = this.getNextDate(int, dt);
            res.push(dt);
          }
        } else {
          // no add future days anymore to fix chart bug on 1 min before market open
          // for (let i = 0; i < count; i++) {
          //   dt = this.getNextDate(int, dt);
          //   res.push(dt);
          // }
        }
      }
    }
    return res;
  };

  // int: any, dt: Date
  getNextDate = (int, dt) => {
    if (this.state.period === "1min") {
      return addMinutes(dt, 1);
    } else if (this.state.period === "5mins") {
      return addMinutes(dt, 5);
    } else if (this.state.period === "15mins") {
      return addMinutes(dt, 15);
    } else if (this.state.period === "1hour") {
      return addHours(dt, 1);
    } else {
      switch (int) {
        case "w":
          return new Date(dt.setDate(dt.getDate() + 7));
        case "m":
          let d = addMonths(new Date(dt), 1);
          let result = lastDayOfMonth(d);
          return result;
        case "d":
        default:
          return new Date(dt.setDate(dt.getDate() + 1));
      }
    }
  };

  _currentemptyfuturepoints = 50;
  addOverlayTA(props, ta) {
    switch (ta.ta) {
      case "SMA":
        for (let i = 0; i < ta.option.length; i++) {
          let para = ta.option[i];
          if (para.enabled) {
            this.mainchartobj.addSMA(
              {
                period:
                  para.period === "" || parseInt(para.period) === 0
                    ? 1
                    : para.period,
                color: "#" + para.color,
                thickness: para.thickness,
                style: para.style,
              },
              100
            );
          }
          if (para.showTracker && para.enabled) {
            let name = "SMA_" + (para.period === "" ? 0 : para.period);
            this.mainchartobj.addFieldTracker(
              name,
              name,
              undefined,
              "white",
              "#" + para.color,
              false,
              2
            );
          }
        }
        break;
      case "EMA":
        for (let i = 0; i < ta.option.length; i++) {
          let para = ta.option[i];
          if (para.enabled) {
            this.mainchartobj.addEMA(
              {
                period:
                  para.period === "" || parseInt(para.period) === 0
                    ? 1
                    : para.period,
                color: "#" + para.color,
                thickness: para.thickness,
                style: para.style,
              },
              100
            );
          }
          if (para.showTracker && para.enabled) {
            let name = "EMA_" + para.period;
            this.mainchartobj.addFieldTracker(
              name,
              name,
              undefined,
              "white",
              "#" + para.color,
              false,
              -2
            );
          }
        }
        break;
      case "WMA":
        for (let i = 0; i < ta.option.length; i++) {
          let para = ta.option[i];
          if (para.enabled) {
            this.mainchartobj.addWMA(
              {
                period:
                  para.period === "" || parseInt(para.period) === 0
                    ? 1
                    : para.period,
                color: "#" + para.color,
                thickness: para.thickness,
                style: para.style,
              },
              100
            );
          }

          if (para.showTracker && para.enabled) {
            let name = "WMA_" + para.period;
            this.mainchartobj.addFieldTracker(
              name,
              name,
              undefined,
              "white",
              "#" + para.color,
              false,
              -2
            );
          }
        }
        break;

      case "MEMA":
        for (let i = 0; i < ta.option.length; i++) {
          let para = ta.option[i];
          if (para.enabled) {
            this.mainchartobj.addMEMA(
              {
                period:
                  para.period === "" || parseInt(para.period) === 0
                    ? 1
                    : para.period,
                color: "#" + para.color,
                thickness: para.thickness,
                style: para.style,
              },
              100
            );
          }
          if (para.showTracker && para.enabled) {
            let name = "MEMA_" + para.period;
            this.mainchartobj.addFieldTracker(
              name,
              name,
              undefined,
              "white",
              "#" + para.color,
              false,
              -2
            );
          }
        }
        break;
      case "TVMA":
        this.mainchartobj.addTVMA(
          {
            period: ta.option.period,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          },
          100
        );

        if (ta.option.showTracker)
          this.mainchartobj.addFieldTracker(
            chartFactory.TVMA,
            chartFactory.TVMA,
            undefined,
            "white",
            "#" + ta.option.color,
            false,
            -2
          );

        break;
      case "LRT":
        this.mainchartobj.addLRT(
          {
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          },
          100
        );

        if (ta.option.showTracker)
          this.mainchartobj.addFieldTracker(
            chartFactory.LRT,
            chartFactory.LRT,
            "v",
            "white",
            "#" + ta.option.color,
            false,
            -2
          );

        break;
      case "ICH":
        let options = {};
        options.period =
          parseInt(ta.option.options[0].period) === 0
            ? 1
            : parseInt(ta.option.options[0].period);
        options.period2 =
          parseInt(ta.option.options[1].period) === 0
            ? 1
            : parseInt(ta.option.options[1].period);
        options.period3 =
          parseInt(ta.option.options[3].period) === 0
            ? 1
            : parseInt(ta.option.options[3].period);
        options.convLine = {};
        if (ta.option.options[0].enabled) {
          options.convLine.color = "#" + ta.option.options[0].color;
          if (ta.option.options[0].showTracker)
            this.mainchartobj.addFieldTracker(
              "convLine",
              "convLine",
              undefined,
              "white",
              "#" + ta.option.options[0].color,
              false,
              -2
            );
        } else options.convLine.color = "rgba(0,0,0,0)";
        options.convLine.thickness = ta.option.options[0].thickness;
        options.convLine.style = ta.option.options[0].style;
        options.baseLine = {};
        if (ta.option.options[1].enabled) {
          options.baseLine.color = "#" + ta.option.options[1].color;
          if (ta.option.options[1].showTracker)
            this.mainchartobj.addFieldTracker(
              "baseLine",
              "baseLine",
              undefined,
              "white",
              "#" + ta.option.options[1].color,
              false,
              -2
            );
        } else options.baseLine.color = "rgba(0,0,0,0)";
        options.baseLine.color = ta.option.options[1].enabled
          ? "#" + ta.option.options[1].color
          : "rgba(0,0,0,0)";
        options.baseLine.thickness = ta.option.options[1].thickness;
        options.baseLine.style = ta.option.options[1].style;
        options.leadSpanA = {};

        if (ta.option.options[3].enabled) {
          options.leadSpanA.color = "#" + ta.option.options[3].color;
          if (ta.option.options[3].showTracker)
            this.mainchartobj.addFieldTracker(
              "leadSpanA",
              "leadSpanA",
              undefined,
              "white",
              "#" + ta.option.options[3].color,
              false,
              -2
            );
        } else options.leadSpanA.color = "rgba(0,0,0,0)";
        options.leadSpanA.color = ta.option.options[3].enabled
          ? "#" + ta.option.options[3].color
          : "rgba(0,0,0,0)";
        options.leadSpanA.thickness = ta.option.options[3].thickness;
        options.leadSpanA.style = ta.option.options[3].style;
        options.leadSpanB = {};

        if (ta.option.options[2].enabled) {
          options.leadSpanB.color = "#" + ta.option.options[2].color;
          if (ta.option.options[2].showTracker)
            this.mainchartobj.addFieldTracker(
              "leadSpanB",
              "leadSpanB",
              undefined,
              "white",
              "#" + ta.option.options[2].color,
              false,
              -2
            );
        } else options.leadSpanB.color = "rgba(0,0,0,0)";
        options.leadSpanB.color = ta.option.options[2].enabled
          ? "#" + ta.option.options[2].color
          : "rgba(0,0,0,0)";
        options.leadSpanB.thickness = ta.option.options[2].thickness;
        options.leadSpanB.style = ta.option.options[2].style;
        options.upCloud = {};
        options.upCloud.color = "#" + ta.option.upColor;
        options.downCloud = {};
        options.downCloud.color = "#" + ta.option.downColor;
        options.lagSpan = {};
        //console.log("CHart5")
        //console.log(ta)

        if (ta.option.options[4].enabled) {
          options.lagSpan.color = "#" + ta.option.options[4].color;
          if (ta.option.options[4].showTracker)
            this.mainchartobj.addFieldTracker(
              "lagSpan",
              "lagSpan",
              undefined,
              "white",
              "#" + ta.option.options[4].color,
              false,
              -2
            );
        } else options.lagSpan.color = "rgba(0,0,0,0)";
        options.lagSpan.color = ta.option.options[4].enabled
          ? "#" + ta.option.options[4].color
          : "rgba(0,0,0,0)";
        options.lagSpan.thickness = ta.option.options[4].thickness;
        options.lagSpan.style = ta.option.options[4].style;

        // // For the time-shifting into future with kijun-period
        // // ... we must call this before addIchimoku(), since ICH needs these future points to calculate and plot the TA
        if (options.period2 >= 5) {
          this._currentemptyfuturepoints = options.period2 + 1; // need 1 extra slot to ensure ICH can be plotted correctly when appending a new data point
        } else {
          this._currentemptyfuturepoints = 5;
        }
        if (this.mainchartobj.series)
          this.mainchartobj.series.futureDates(
            this.getFutureDates(
              this._currentemptyfuturepoints,
              this.state.period
            )
          );
        this.mainchartobj.addIchimoku(options, 100);
        break;

      case "BB":
        for (let i = 0; i < ta.option.deviation.length; i++) {
          let para = ta.option.deviation[i];
          if (para.enabled) {
            this.mainchartobj.addBollinger(
              {
                period:
                  ta.option.BB.period === "" ||
                    parseInt(ta.option.BB.period) === 0
                    ? 1
                    : ta.option.BB.period,
                deviation: {
                  value: para.value,
                  color0: "#" + para.color,
                  color1: "#" + para.color2,
                  thickness: para.thickness,
                  style: para.style,
                },
              },
              100
            );

            if (para.showTracker) {
              let name =
                chartFactory.BOLLINGERBANDS +
                "_" +
                ta.option.BB.period +
                "_" +
                para.value;
              this.mainchartobj.addFieldTracker(
                name,
                name,
                "upper",
                "white",
                "#" + para.color,
                false,
                -2
              );
              this.mainchartobj.addFieldTracker(
                name,
                name,
                "lower",
                "white",
                "#" + para.color2,
                false,
                -2
              );
            }
          }
        }
        if (ta.option.BB.enabled) {
          this.mainchartobj.addBollinger(
            {
              period:
                ta.option.BB.period === "" ||
                  parseInt(ta.option.BB.period) === 0
                  ? 1
                  : ta.option.BB.period,
              color: "#" + ta.option.BB.color,
              thickness: ta.option.BB.thickness,
              style: ta.option.BB.style,
            },
            100
          );

          if (ta.option.BB.showTracker) {
            let name = chartFactory.BOLLINGERBANDS + "_" + ta.option.BB.period;
            this.mainchartobj.addFieldTracker(
              name,
              name,
              "mid",
              "white",
              "#" + ta.option.BB.color,
              false,
              -2
            );
          }
        }

        break;
      case "SAR":
        if (ta.option) {
          this.mainchartobj.addSAR(
            {
              color: "#" + ta.option.color,
              factor: {
                def: parseFloat(ta.option.p1),
                inc: parseFloat(ta.option.p2),
                max: parseFloat(ta.option.p3),
              },
            },
            100
          );
        }

        if (ta.option.showTracker)
          this.mainchartobj.addFieldTracker(
            chartFactory.SAR,
            chartFactory.SAR,
            "sar",
            "white",
            "#" + ta.option.color,
            false,
            -2
          );
        break;
      case "VAP":
        if (ta.option) {
          this.mainchartobj.addVolByPrice(
            {
              zoneCount: 24,
              pctWidth: 0.5,
              upColor: this.hex2rgba("#" + ta.option.color, 0.5),
              downColor: this.hex2rgba("#" + ta.option.color2, 0.5),
            },
            100
          );
        }
        break;
      case "ENV":
        if (ta.option.mid.enabled) {
          this.mainchartobj.addEnvelope(
            {
              period:
                ta.option.mid.period === "" ||
                  parseInt(ta.option.mid.period) === 0
                  ? 1
                  : parseInt(ta.option.mid.period),
              color: "#" + ta.option.mid.color,
              thickness: ta.option.mid.thickness,
              style: ta.option.mid.style,
            },
            100
          );

          if (ta.option.mid.showTracker) {
            let name =
              chartFactory.ENVELOPE +
              "_" +
              (ta.option.mid.period === "" ||
                parseInt(ta.option.mid.period) === 0
                ? 1
                : parseInt(ta.option.mid.period));
            this.mainchartobj.addFieldTracker(
              name,
              name,
              "mid",
              "white",
              "#" + ta.option.mid.color,
              false,
              -2
            );
          }
        }

        for (let i = 0; i < ta.option.factor.length; i++) {
          let para = ta.option.factor[i];
          if (para.enabled) {
            this.mainchartobj.addEnvelope(
              {
                period:
                  ta.option.mid.period === "" ||
                    parseInt(ta.option.mid.period) === 0
                    ? 1
                    : parseInt(ta.option.mid.period),
                factor: {
                  value:
                    para.p === "" || parseFloat(para.p) === 0
                      ? 1 / 100
                      : parseFloat(para.p) / 100,
                  //period: para.p,
                  color0: "#" + para.color,
                  color1: "#" + para.color2,
                  thickness: para.thickness,
                  style: para.style,
                },
              },
              100
            );

            if (para.showTracker) {
              let name =
                chartFactory.ENVELOPE +
                "_" +
                (ta.option.mid.period === "" ||
                  parseInt(ta.option.mid.period) === 0
                  ? 1
                  : parseInt(ta.option.mid.period)) +
                "_" +
                (para.p === "" || parseFloat(para.p) === 0
                  ? 1 / 100
                  : parseFloat(para.p) / 100);
              this.mainchartobj.addFieldTracker(
                name,
                name,
                "upper",
                "white",
                "#" + para.color,
                false,
                -2
              );
              this.mainchartobj.addFieldTracker(
                name,
                name,
                "lower",
                "white",
                "#" + para.color2,
                false,
                -2
              );
            }
          }
        }
        break;
      case "PRR":
        if (ta.option) {
          this.mainchartobj.addPRR(
            {
              pctWidth: 1, // show across full width
              upColor: "#" + ta.option.color,
              downColor: "#" + ta.option.color,
            },
            100
          );
        }
        break;
      case "VWAP":
        if (ta.option) {
          this.mainchartobj.addVWAP(
            {
              color: "#" + ta.option.color,
              thickness: ta.option.thickness,
              style: ta.option.style,
            },
            100
          );

          if (ta.option.showTracker) {
            this.mainchartobj.addFieldTracker(
              chartFactory.VWAP,
              chartFactory.VWAP,
              "VWAP",
              "white",
              "#" + ta.option.color,
              false
            );
          }
        }
        break;
      case "WC":
        if (ta.option) {
          this.mainchartobj.addWeightedClose(
            {
              factor: parseFloat(ta.option.weight),
              color: "#" + ta.option.color,
              thickness: ta.option.thickness,
              style: ta.option.style,
            },
            100
          );
        }
        break;
      case "TP":
        if (ta.option) {
          this.mainchartobj.addTP(
            {
              period: Number(ta.option.period),
              color: "#" + ta.option.color,
            },
            100
          );
        }
        break;
      case "KC":
        if (ta.option) {
          for (let i = 0; i < ta.option.atr.length; i++) {
            let para = ta.option.atr[i];
            if (para.enabled) {
              this.mainchartobj.addKC(
                {
                  period: ta.option.mid.period,
                  atr: {
                    value: i + 1,
                    period: para.period,
                    color0: "#" + para.color,
                    color1: "#" + para.color1,
                    thickness: para.thickness,
                    style: para.style,
                  },
                },
                100
              );

              if (para.showTracker) {
                let name =
                  chartFactory.KC + "_" + ta.option.mid.period + "_" + (i + 1);
                this.mainchartobj.addFieldTracker(
                  name,
                  name,
                  "upper",
                  "white",
                  "#" + para.color,
                  false,
                  -2
                );
                this.mainchartobj.addFieldTracker(
                  name,
                  name,
                  "lower",
                  "white",
                  "#" + para.color1,
                  false,
                  -2
                );
              }
            }
          }

          if (ta.option.mid.enabled) {
            this.mainchartobj.addKC(
              {
                period: ta.option.mid.period,
                color: "#" + ta.option.mid.color,
                thickness: ta.option.mid.thickness,
                style: ta.option.mid.style,
              },
              100
            );

            if (ta.option.mid.showTracker) {
              let name = chartFactory.KC + "_" + ta.option.mid.period;
              this.mainchartobj.addFieldTracker(
                name,
                name,
                "mid",
                "white",
                "#" + ta.option.mid.color,
                false,
                -2
              );
            }
          }
        }
        break;
      default:
        break;
    }
  }

  getMainChartOptions() {
    return {
      gridType: this.mainchartobj.gridType,
      gridStyle: this.mainchartobj.gridStyle,
      crosshairStyle: this.mainchartobj.crosshairStyle,
      crosshairFunc: this.mainchartobj.crosshairFunc,
      tooltipFunc: this.mainchartobj.tooltipFunc,
      dateRangeFunc: this.mainchartobj.dateRangeFunc,
    };
  }

  // c: string, op: number
  hex2rgba(c, op) {
    if (c.startsWith("#")) c = c.substr(1);
    var r = parseInt(c.substring(0, 2), 16);
    var g = parseInt(c.substring(2, 4), 16);
    var b = parseInt(c.substring(4, 6), 16);
    return "rgba(" + r + "," + g + "," + b + "," + op + ")";
  }

  // h: number
  getHeight(h) {
    let c = this.tachartobjList.length;
    if (c === 0) {
      c = 1;
    }

    var r;
    if (this.props.isMobile && this.props.isLandscape) {
      switch (c) {
        default:
        case 1:
          switch (h) {
            case 0:
              r = 60;
              break;
            case 2:
              r = 80;
              break;
            default:
            case 1:
              r = 70;
              break;
          }
          break;

        case 2:
          switch (h) {
            case 0:
              r = 50;
              break;
            case 2:
              r = 70;
              break;
            default:
            case 1:
              r = 60;
              break;
          }
          break;

        case 3:
          switch (h) {
            case 0:
              r = 40;
              break;
            case 2:
              r = 60;
              break;
            default:
            case 1:
              r = 50;
              break;
          }
          break;
        case 4:
          switch (h) {
            case 0:
              r = 30;
              break;
            case 2:
              r = 50;
              break;
            default:
            case 1:
              r = 40;
              break;
          }
          break;
        case 5:
          switch (h) {
            case 0:
              r = 20;
              break;
            case 2:
              r = 40;
              break;
            default:
            case 1:
              r = 30;
              break;
          }
          break;
      }
    } else {
      switch (c) {
        default:
        case 1:
          switch (h) {
            case 0:
              r = 90;
              break;
            case 2:
              r = 130;
              break;
            default:
            case 1:
              r = 110;
              break;
          }
          break;

        case 2:
          switch (h) {
            case 0:
              r = 80;
              break;
            case 2:
              r = 120;
              break;
            default:
            case 1:
              r = 100;
              break;
          }
          break;

        case 3:
          switch (h) {
            case 0:
              r = 70;
              break;
            case 2:
              r = 110;
              break;
            default:
            case 1:
              r = 90;
              break;
          }
          break;
        case 4:
          switch (h) {
            case 0:
              r = 60;
              break;
            case 2:
              r = 100;
              break;
            default:
            case 1:
              r = 80;
              break;
          }
          break;
        case 5:
          switch (h) {
            case 0:
              r = 50;
              break;
            case 2:
              r = 90;
              break;
            default:
            case 1:
              r = 70;
              break;
          }
          break;
      }
    }
    return r;
  }

  getScrollPaneHeight() {
    let h = this.container.current.clientHeight;
    if (h < 400) {
      return 50;
    } else {
      return 100;
    }
  }

  addTAPane(props, ta) {
    let tachartobj = "";
    if (!this.mainchartobj) return;
    let w = this.container.current.clientWidth;
    let h = 100;
    h = this.getHeight(
      this.container.current.clientHeight <= 600
        ? 0
        : parseInt(ta.option.height)
    );
    let co = this.getMainChartOptions();

    switch (ta.ta) {
      case "Volume":
        let options = this.getMainChartOptions();
        options.gridStyle = options.gridStyle |= chartFactory.GRID_DIMINUTIVE;
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.VOLUME,
          w,
          h,
          $(this.canvasbox.current),
          options,
          100
        );
        tachartobj.data(this.mainchartobj.series);

        tachartobj.addVolume({
          upColor: "#" + ta.option.upColor,
          downColor: "#" + ta.option.downColor,
        });

        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "VE":
        co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.VE,
          w,
          h,
          $(this.canvasbox.current),
          co,
          100
        );
        tachartobj.data(this.mainchartobj.series);

        tachartobj.addVE({
          period:
            ta.option.period === "" || parseInt(ta.option.period) === 0
              ? 1
              : parseInt(ta.option.period),
          factor:
            ta.option.p === "" || parseInt(ta.option.p) === 0
              ? 0
              : parseInt(ta.option.p) / 100,
          envelope: {
            uColor: "#" + ta.option.color,
            bColor: "#" + ta.option.color2,
            thickness: ta.option.thickness,
            style: ta.option.style,
          },
          volume: {
            color: "#" + ta.option.volume,
          },
        });

        if (ta.option.showTracker) {
          tachartobj.addFieldTracker(
            "up",
            chartFactory.VE,
            "up",
            "white",
            "#" + ta.option.color,
            false
          );
          tachartobj.addFieldTracker(
            "lo",
            chartFactory.VE,
            "lo",
            "white",
            "#" + ta.option.color2,
            false
          );
        }

        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "Turnover":
        co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.TURNOVER,
          w,
          h,
          $(this.canvasbox.current),
          co,
          100
        );
        tachartobj.data(this.mainchartobj.series);
        tachartobj.addTurnover({
          color: "#" + ta.option.color,
        });
        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;

      case "MACD":
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.MACD,
          w,
          h,
          $(this.canvasbox.current),
          this.getMainChartOptions(),
          100
        );
        tachartobj.data(this.mainchartobj.series);
        tachartobj.addMACD({
          macd: {
            period:
              ta.option.p1 === "" || parseInt(ta.option.p1) === 0
                ? 1
                : ta.option.p1,
            period2: ta.option.p2,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          },
          signal: {
            period: ta.option.p3,
            color: "#" + ta.option.color3,
            thickness: ta.option.thickness3,
            style: ta.option.style3,
          },
          hist: {
            posColor: "#" + ta.option.upColor,
            negColor: "#" + ta.option.downColor,
          },
        });

        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            "macd",
            chartFactory.MACD,
            "macd",
            "white",
            "#" + ta.option.color,
            false,
            -2
          );
        if (ta.option.showTracker3)
          tachartobj.addFieldTracker(
            "signal",
            chartFactory.MACD,
            "signal",
            "white",
            "#" + ta.option.color3,
            false,
            -2
          );

        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "DMI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.DMI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addDMI({
            dmi: {
              period: parseInt(ta.option.period),
            },
            di: {
              posColor: ta.option.enabled ? "#" + ta.option.color : null,
              negColor: ta.option.enabled ? "#" + ta.option.color2 : null,
              thickness: ta.option.thickness,
              style: ta.option.style,
            },
            adx: {
              color: ta.option.adx.enabled ? "#" + ta.option.adx.color : null,
              thickness: ta.option.adx.thickness,
              style: ta.option.adx.style,
            },
            adxr: {},
          });

          tachartobj.show(this.mainchartobj);

          if (ta.option.enabled && ta.option.showTracker) {
            tachartobj.addFieldTracker(
              "dip",
              chartFactory.DMI,
              "dip",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
            tachartobj.addFieldTracker(
              "dim",
              chartFactory.DMI,
              "dim",
              "white",
              "#" + ta.option.color2,
              false,
              2
            );
          }
          if (ta.option.adx.enabled && ta.option.adx.showTracker)
            tachartobj.addFieldTracker(
              "adx",
              chartFactory.DMI,
              "adx",
              "white",
              "#" + ta.option.adx.color,
              false,
              2
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "ADXR":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.ADXR,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addADXR({
            dmi: {
              period:
                parseInt(ta.option.DMI) === 0 ? 1 : parseInt(ta.option.DMI),
            },
            di: {},
            adx: {},
            adxr: {
              period:
                parseInt(ta.option.ADX) === 0 ? 1 : parseInt(ta.option.ADX),
              color: "#" + ta.option.color,
              thickness: ta.option.thickness,
              style: ta.option.style,
            },
          });

          tachartobj.show(this.mainchartobj);

          if (ta.option.showTracker) {
            tachartobj.addFieldTracker(
              chartFactory.ADXR,
              chartFactory.ADXR,
              "adxr",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          }

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "ALF":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.ALF,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addALF({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.ALF,
              chartFactory.ALF,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "Bias":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.BIAS,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addBias({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 0
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.BIAS,
              chartFactory.BIAS,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "NVI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.NVI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          for (var i = 0; i < ta.option.atr.length; i++) {
            var para = ta.option.atr[i];
            if (para.enabled) {
              tachartobj.addNVI({
                cnvi: {
                  color: "#" + ta.option.NVI.color,
                  thickness: ta.option.NVI.thickness,
                  style: ta.option.NVI.style,
                },
                s: {
                  period:
                    para.period === "" || parseInt(para.period) === 0
                      ? 1
                      : parseInt(para.period),
                  color: "#" + para.color,
                  thickness: para.thickness,
                  style: para.style,
                },
              });

              if (ta.option.NVI.showTracker) {
                let name = chartFactory.NVI + "_" + para.period;
                tachartobj.addFieldTracker(
                  name,
                  name,
                  "cnvi",
                  "white",
                  "#" + ta.option.NVI.color,
                  false
                );
              }

              if (para.showTracker) {
                let name = chartFactory.NVI + "_" + para.period;
                tachartobj.addFieldTracker(
                  name,
                  name,
                  "s",
                  "white",
                  "#" + para.color,
                  false
                );
              }
            }
          }

          if (tachartobj.drawers.length === 0) {
            tachartobj.addNVI({
              cnvi: {
                color: "#" + ta.option.NVI.color,
                thickness: ta.option.NVI.thickness,
                style: ta.option.NVI.style,
              },
              s: {
                period: 1,
                color: "rgba(0,0,0,0)",
                thickness: 1,
                style: para.style,
              },
            });

            if (ta.option.NVI.showTracker) {
              let name = chartFactory.NVI + "_" + 1;
              tachartobj.addFieldTracker(
                name,
                name,
                "cnvi",
                "white",
                "#" + ta.option.NVI.color,
                false
              );
            }
          }

          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "VC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.CHAIKIN_VOL,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addChaikinVol({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.CHAIKIN_VOL,
              chartFactory.CHAIKIN_VOL,
              "cv",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "ATR":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.ATR,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addATR({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.ATR,
              chartFactory.ATR,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "SD":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.STD_DEV,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addStdDev({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.STD_DEV,
              chartFactory.STD_DEV,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "VltyCC":
        if (ta.option) {
          let timeFactor;
          if (props.periodOption === "" || props.periodOption === "d") {
            timeFactor = 254;
          } else if (props.periodOption === "w") {
            timeFactor = 52;
          } else if (props.periodOption === "m") {
            timeFactor = 12;
          } else {
            timeFactor = 4;
          }
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.VOLATILITY,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addVolatility({
            timeFactor: timeFactor,
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.VOLATILITY,
              chartFactory.VOLATILITY,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "KRI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.KRI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          for (let i = 0; i < ta.option.options.length; i++) {
            let para = ta.option.options[i];
            if (para.enabled) {
              tachartobj.addKRI({
                coefficient: ta.option.module,
                period:
                  para.period === "" || parseInt(para.period) === 0
                    ? 1
                    : parseInt(para.period),
                method: method,
                color: "#" + para.color,
                thickness: para.thickness,
                style: para.style,
              });
            }

            if (para.showTracker && para.enabled) {
              let name =
                chartFactory.KRI +
                "_" +
                (para.period === "" || parseInt(para.period) === 0
                  ? 1
                  : parseInt(para.period));
              tachartobj.addFieldTracker(
                name,
                name,
                "kri",
                "white",
                "#" + para.color,
                false,
                2
              );
            }
          }

          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "PL":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.PSY,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addPsychological({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 0
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.PSY,
              chartFactory.PSY,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "CO":
        if (ta.option) {
          co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.CHAIKIN_OSC,
            w,
            h,
            $(this.canvasbox.current),
            co,
            100
          );
          tachartobj.data(this.mainchartobj.series);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            default:
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          tachartobj.addChaikinOsc({
            period0: parseInt(ta.option.period),
            period1: parseInt(ta.option.period2),
            method: method,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.CHAIKIN_OSC,
              chartFactory.CHAIKIN_OSC,
              "co",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "RCI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.RCI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addRCI({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.RCI,
              chartFactory.RCI,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "CCI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.CCI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addCCI({
            period: ta.option.period,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.CCI,
              chartFactory.CCI,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "MI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.MASS_INDEX,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            default:
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          tachartobj.addMassIndex({
            period0: parseInt(ta.option.period),
            period1: parseInt(ta.option.period2),
            method: method,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          tachartobj.show(this.mainchartobj);
          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.MASS_INDEX,
              chartFactory.MASS_INDEX,
              "mi",
              "white",
              "#" + ta.option.color,
              false,
              2
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "UO":
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.UO,
          w,
          h,
          $(this.canvasbox.current),
          this.getMainChartOptions(),
          100
        );
        tachartobj.data(this.mainchartobj.series);

        tachartobj.addUO({
          period: ta.option.period,
          period2: parseInt(ta.option.period2),
          period3: parseInt(ta.option.period3),
          color: "#" + ta.option.color,
          thickness: ta.option.thickness,
          style: ta.option.style,
        });

        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            chartFactory.UO,
            chartFactory.UO,
            undefined,
            "white",
            "#" + ta.option.color,
            true,
            2
          );

        tachartobj.addPriceTracker2("lowerUO", ta.option.buy, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("upperUO", ta.option.sale, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("y50", 50, {
          color: "#EEEEEE",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dash: 0,
        });
        tachartobj.fixedScale(0, 100);
        tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);

        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "Vector":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.VECTOR,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addVector({
            periodV:
              ta.option.p1 === "" || parseInt(ta.option.p1) === 0
                ? 1
                : parseInt(ta.option.p1),
            colorV: ta.option.p1Enable ? "#" + ta.option.p1Color : null,
            thicknessV: ta.option.p1Thickness,
            styleV: ta.option.p1Style,
            periodS: ta.option.p2,
            colorS: ta.option.p2Enable ? "#" + ta.option.p2Color : null,
            thicknessS: ta.option.p2Thickness,
            styleS: ta.option.p2Style,
          });

          tachartobj.show(this.mainchartobj);
          if (ta.option.p1Enable && ta.option.p1ShowTracker)
            tachartobj.addFieldTracker(
              "v",
              chartFactory.VECTOR,
              "v",
              "white",
              "#" + ta.option.p1Color,
              false,
              2
            );
          if (ta.option.p2Enable && ta.option.p2ShowTracker)
            tachartobj.addFieldTracker(
              "s",
              chartFactory.VECTOR,
              "s",
              "white",
              "#" + ta.option.p2Color,
              false,
              2
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "DPO":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.DPO,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addDPO({
            period: ta.option.period,
            fillColor: this.hex2rgba("#" + ta.option.color, 0.5),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.DPO,
              chartFactory.DPO,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;

      case "WAD":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.WAD,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addWAD({
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.WAD,
              chartFactory.WAD,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;

      case "Trix":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.TRIX,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addTrix({
            trix: {
              period:
                ta.option.p1 === "" || parseInt(ta.option.p1) === 0
                  ? 1
                  : parseInt(ta.option.p1),
              color: "#" + ta.option.p1Color,
              thickness: ta.option.p1Thickness,
              style: ta.option.p1Style,
            },
            signal: {
              period:
                ta.option.p2 === "" || parseInt(ta.option.p2) === 0
                  ? 1
                  : parseInt(ta.option.p2),
              color: "#" + ta.option.p2Color,
              thickness: ta.option.p2Thickness,
              style: ta.option.p2Style,
            },
          });

          tachartobj.show(this.mainchartobj);

          if (ta.option.p1ShowTracker)
            tachartobj.addFieldTracker(
              "trix",
              chartFactory.TRIX,
              "trix",
              "white",
              "#" + ta.option.p1Color,
              false,
              3
            );
          if (ta.option.p2ShowTracker)
            tachartobj.addFieldTracker(
              "trixma",
              chartFactory.TRIX,
              "trixma",
              "white",
              "#" + ta.option.p2Color,
              false,
              3
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "VROC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.VROC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addVROC({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 0
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.VROC,
              chartFactory.VROC,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;

      case "VOSC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.VOSC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addVOSC({
            period: parseInt(ta.option.shortPeriod),
            period2: parseInt(ta.option.longPeriod),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.VOSC,
              chartFactory.VOSC,
              undefined,
              "white",
              "#" + ta.option.color,
              false
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "EOM":
        if (ta.option) {
          co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.EOM,
            w,
            h,
            $(this.canvasbox.current),
            co,
            100
          );
          tachartobj.data(this.mainchartobj.series);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            default:
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          tachartobj.addEOM({
            period: ta.option.period,
            method: method,
            color: "#" + ta.option.color,
            fillColor: this.hex2rgba(ta.option.color, 0.5),
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          tachartobj.show(this.mainchartobj);

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.EOM,
              chartFactory.EOM,
              "eom",
              "white",
              "#" + ta.option.color,
              false,
              2
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "FI":
        if (ta.option) {
          co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.FORCE_INDEX,
            w,
            h,
            $(this.canvasbox.current),
            co,
            100
          );
          tachartobj.data(this.mainchartobj.series);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            default:
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          tachartobj.addForceIndex({
            period: ta.option.period,
            method: method,
            color: "#" + ta.option.color,
            fillColor: this.hex2rgba(ta.option.color, 0.5),
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.FORCE_INDEX,
              chartFactory.FORCE_INDEX,
              "fi",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "MFI":
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.MFI,
          w,
          h,
          $(this.canvasbox.current),
          this.getMainChartOptions(),
          100
        );
        tachartobj.data(this.mainchartobj.series);
        tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);
        tachartobj.addMFI({
          period: ta.option.period,
          color: "#" + ta.option.color,
          thickness: ta.option.thickness,
          style: ta.option.style,
        });

        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            chartFactory.MFI,
            chartFactory.MFI,
            "mfi",
            "white",
            "#" + ta.option.color,
            true,
            2
          );

        tachartobj.addPriceTracker2("lowerRMI", ta.option.buy, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("upperRMI", ta.option.sale, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("y50", 50, {
          color: "#EEEEEE",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dash: 0,
        });
        tachartobj.fixedScale(0, 100);
        tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);

        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "RMI":
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.RMI,
          w,
          h,
          $(this.canvasbox.current),
          this.getMainChartOptions(),
          100
        );
        tachartobj.data(this.mainchartobj.series);

        let method = chartFactory.MA_SMA;
        switch (ta.option.ta) {
          case "sma":
            method = chartFactory.MA_SMA;
            break;
          case "wma":
            method = chartFactory.MA_WMA;
            break;
          case "mema":
            method = chartFactory.MA_MEMA;
            break;
          default:
          case "ema":
            method = chartFactory.MA_EMA;
            break;
        }

        tachartobj.addRMI({
          periodM:
            ta.option.period2 === "" || parseInt(ta.option.period2) === 0
              ? 1
              : parseInt(ta.option.period2),
          period:
            ta.option.period === "" || parseInt(ta.option.period) === 0
              ? 1
              : parseInt(ta.option.period),
          method: method,
          color: "#" + ta.option.color,
          fillColor: this.hex2rgba(ta.option.color, 0.5),
          thickness: ta.option.thickness,
          style: ta.option.style,
        });
        tachartobj.show(this.mainchartobj);

        tachartobj.addPriceTracker2("lowerRMI", ta.option.buy, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("upperRMI", ta.option.sale, {
          color: "#000080",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dashstyle: chartFactory.TRACKER_DASH,
        });
        tachartobj.addPriceTracker2("y50", 50, {
          color: "#EEEEEE",
          textColor: "#333333",
          invertColor: "#FFFFFF",
          dash: 0,
        });
        tachartobj.fixedScale(0, 100);
        tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);

        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            chartFactory.RMI,
            chartFactory.RMI,
            undefined,
            "white",
            "#" + ta.option.color,
            false,
            2
          );
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "RSI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.RSI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addPriceTracker2("lowerRSI", ta.option.buy, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("upperRSI", ta.option.sale, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("y50", 50, {
            color: "#EEEEEE",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dash: 0,
          });
          tachartobj.fixedScale(0, 100);

          for (let i = 0; i < ta.option.atr.length; i++) {
            let para = ta.option.atr[i];

            let method = chartFactory.MA_SMA;
            switch (para.ta) {
              case "sma":
                method = chartFactory.MA_SMA;
                break;
              case "wma":
                method = chartFactory.MA_WMA;
                break;
              case "mema":
                method = chartFactory.MA_MEMA;
                break;
              default:
              case "ema":
                method = chartFactory.MA_EMA;
                break;
            }

            if (para.enabled) {
              tachartobj.addRSI(
                {
                  method: method,
                  period: parseInt(para.period),
                  color: "#" + para.color,
                  thickness: para.thickness,
                  style: para.style,
                },
                100
              );

              if (para.showTracker) {
                let rsi_method = 0;
                switch (para.ta) {
                  case "sma":
                    rsi_method = chartFactory.MA_SMA;
                    break;
                  case "ema":
                    rsi_method = chartFactory.MA_EMA;
                    break;
                  case "mema":
                    rsi_method = chartFactory.MA_MEMA;
                    break;
                  case "wma":
                    rsi_method = chartFactory.MA_WMA;
                    break;
                }
                let name =
                  chartFactory.RSI + "_" + rsi_method + "_" + para.period;
                tachartobj.addFieldTracker(
                  name,
                  name,
                  undefined,
                  "white",
                  "#" + para.color,
                  false,
                  2
                );
              }
            }
          }

          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "SKD":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.STOCHASTIC_SLOW,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addPriceTracker2("lowerSKD", ta.option.buy, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("upperSKD", ta.option.sale, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("y50", 50, {
            color: "#EEEEEE",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dash: 0,
          });
          tachartobj.fixedScale(0, 100);

          let method = chartFactory.MA_SMA;
          switch (ta.option.method) {
            case "sma":
              method = chartFactory.MA_SMA;
              break;
            case "wma":
              method = chartFactory.MA_WMA;
              break;
            case "mema":
              method = chartFactory.MA_MEMA;
              break;
            default:
            case "ema":
              method = chartFactory.MA_EMA;
              break;
          }

          tachartobj.addStochastic({
            method: method,
            periodK:
              ta.option.K === "" || parseInt(ta.option.K) === 0
                ? 1
                : parseInt(ta.option.K),
            colorK: "#" + ta.option.color,
            thicknessK: ta.option.thickness,
            styleK: ta.option.style,
            periodD:
              ta.option.D === "" || parseInt(ta.option.D) === 0
                ? 1
                : parseInt(ta.option.D),
            periodF: 3,
            colorD: "#" + ta.option.color2,
            thicknessD: ta.option.thickness2,
            styleD: ta.option.style2,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.STOCHASTIC_SLOW,
              "SKD",
              "fastD",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          if (ta.option.showTracker2)
            tachartobj.addFieldTracker(
              chartFactory.STOCHASTIC_SLOW,
              "SKD",
              "slowD",
              "white",
              "#" + ta.option.color2,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "FKD":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.STOCHASTIC_FAST,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.setGridStyle(chartFactory.GRID_LABEL_X);
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addPriceTracker2("lowerFKD", ta.option.buy, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("upperFKD", ta.option.sale, {
            color: "#000080",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dashstyle: chartFactory.TRACKER_DASH,
          });
          tachartobj.addPriceTracker2("y50", 50, {
            color: "#EEEEEE",
            textColor: "#333333",
            invertColor: "#FFFFFF",
            dash: 0,
          });
          tachartobj.fixedScale(0, 100);
          tachartobj.addFastStochastic({
            method: chartFactory.MA_SMA,
            periodK:
              ta.option.K === "" || parseInt(ta.option.K) === 0
                ? 1
                : parseInt(ta.option.K),
            colorK: "#" + ta.option.color,
            thicknessK: ta.option.thickness,
            styleK: ta.option.style,
            periodD:
              ta.option.D === "" || parseInt(ta.option.D) === 0
                ? 1
                : parseInt(ta.option.D),
            periodF: 3,
            colorD: "#" + ta.option.color2,
            thicknessD: ta.option.thickness2,
            styleD: ta.option.style2,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.STOCHASTIC_FAST,
              chartFactory.STOCHASTIC_FAST,
              "fastK",
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          if (ta.option.showTracker2)
            tachartobj.addFieldTracker(
              chartFactory.STOCHASTIC_FAST,
              chartFactory.STOCHASTIC_FAST,
              "fastD",
              "white",
              "#" + ta.option.color2,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "ACD":
        co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.ACD,
          w,
          h,
          $(this.canvasbox.current),
          co,
          100
        );
        tachartobj.data(this.mainchartobj.series);

        tachartobj.addACD({
          color: "#" + ta.option.color,
          thickness: ta.option.thickness,
          style: ta.option.style,
        });
        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            chartFactory.ACD,
            chartFactory.ACD,
            undefined,
            "white",
            "#" + ta.option.color,
            false,
            2
          );
        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "OBV":
        co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
        tachartobj = this.$chartframeobj.add(
          chartFactory.PRICE_CHART,
          chartFactory.OBV,
          w,
          h,
          $(this.canvasbox.current),
          co,
          100
        );
        tachartobj.data(this.mainchartobj.series);

        tachartobj.addOBV({
          color: "#" + ta.option.color,
          thickness: ta.option.thickness,
          style: ta.option.style,
        });
        if (ta.option.showTracker)
          tachartobj.addFieldTracker(
            chartFactory.OBV,
            chartFactory.OBV,
            undefined,
            "white",
            "#" + ta.option.color,
            false
          );
        tachartobj.show(this.mainchartobj);
        this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        break;
      case "VR":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.VR,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addVR({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.VR,
              chartFactory.VR,
              undefined,
              "white",
              "#" + ta.option.color,
              false
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "UPDW":
        if (ta.option) {
          this.ta = ta;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.UPDW,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
          this.getUPDWData(props, ta.option.idx);
        }
        break;
      case "POSC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.POSC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addPOSC({
            period: parseInt(ta.option.shortPeriod),
            period2: parseInt(ta.option.longPeriod),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.POSC,
              chartFactory.POSC,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;

      case "PROC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.PROC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addPROC({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 0
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.PROC,
              chartFactory.PROC,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "MOM":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.MOMENTUM,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addMomentum({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 0
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.MOMENTUM,
              chartFactory.MOMENTUM,
              undefined,
              "white",
              "#" + ta.option.color,
              false
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "WR":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.WILLIAM_R,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addWilliamR({
            period:
              ta.option.period === "" || parseInt(ta.option.period) === 0
                ? 1
                : parseInt(ta.option.period),
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          tachartobj.show(this.mainchartobj);

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.WILLIAM_R,
              chartFactory.WILLIAM_R,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              undefined
            );

          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "PCV":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.PCV,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addPCV({
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.PCV,
              chartFactory.PCV,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              -2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "Margin":
        if (ta.option) {
          co.gridStyle = co.gridStyle | chartFactory.GRID_DIMINUTIVE;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.Margin,
            w,
            h,
            $(this.canvasbox.current),
            co,
            100
          );
          this.mainchartobj.series.idxric = ta.option.index; // ...also save the index RIC code here for future reference
          tachartobj.data(this.mainchartobj.series);
          tachartobj.setGridStyle(chartFactory.GRID_DIMINUTIVE);
          tachartobj.addMargin({
            colorB: "#" + ta.option.buyColor,
            thicknessB: ta.option.buyThickness,
            styleB: ta.option.buyStyle,
            colorS: "#" + ta.option.saleColor,
            thicknessS: ta.option.saleThickness,
            styleS: ta.option.saleStyle,
          });

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.Margin,
              chartFactory.Margin,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "RC":
        if (ta.option) {
          this.ta = ta;
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.RC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
          this.getRCData(props, ta.option.index);
        }

        break;
      case "CC":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.CC,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);
          tachartobj.addCC({
            period: ta.option.period,
            period2: ta.option.longPeriod,
            period3: ta.option.shortPeriod,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });
          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.CC,
              chartFactory.CC,
              undefined,
              "white",
              "#" + ta.option.color,
              false,
              2
            );
          tachartobj.show(this.mainchartobj);
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;
      case "UI":
        if (ta.option) {
          tachartobj = this.$chartframeobj.add(
            chartFactory.PRICE_CHART,
            chartFactory.UI,
            w,
            h,
            $(this.canvasbox.current),
            this.getMainChartOptions(),
            100
          );
          tachartobj.data(this.mainchartobj.series);

          tachartobj.addUI({
            period: ta.option.period,
            color: "#" + ta.option.color,
            thickness: ta.option.thickness,
            style: ta.option.style,
          });

          tachartobj.show(this.mainchartobj);

          if (ta.option.showTracker)
            tachartobj.addFieldTracker(
              chartFactory.UI,
              chartFactory.UI,
              undefined,
              "white",
              "#" + ta.option.color,
              false
            );
          this.tachartobjList.push({ tachartobj: tachartobj, option: ta });
        }
        break;

      default:
        break;
    }
  }

  getInfo = async (props) => {
    await axios
      .post(common.getHttpChartInfo, {
        // params: { ric: props.code },
        token: sessionStorage.getItem("token"),
        ric: props.code,
      })
      .then((res) => {
        let data = res.data.data;
        let hol = data.hol !== null ? data.hol : [];
        var result = [];
        var keys = Object.keys(hol);
        keys.forEach(function (key) {
          result.push(hol[key]);
        });

        this.setState({ info: data, holList: result });
      });
    // this.setState({ info: null, holList: [] });
  };

  // year: any, count: number
  getHoliday = (year, count) => {
    let d = new Date();
    d.setDate(d.getDate() - count);
    this.state.holList.forEach((item) => {
      if (item.year === year) {
        let arr = item.holidays.split(",");
        let dString =
          d.getDate() +
          "-" +
          (d.getMonth() + 1 < 10 ? 0 + d.getMonth() + 1 : d.getMonth() + 1);
        arr.forEach((item) => {
          if (item === dString) return true;
        });

        return false;
      }
    });

    return false;
  };

  // year: any, count: number
  getLongHoliday = (year, count) => {
    let d = new Date();
    let numToLoop = count;
    let numToNextLoop = 0;
    let numOfHol = 0;
    do {
      for (var i = 0; i < numToLoop; i++) {
        d.setDate(d.getDate() - 1);
        this.state.holList.forEach((item) => {
          if (item.year === year) {
            let arr = item.holidays.split(",");
            let dString =
              (d.getMonth() + 1 < 10
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1) +
              "-" +
              (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
            arr.forEach((item) => {
              if (item === dString) {
                numOfHol++;
                numToNextLoop++;
              }
            });
          }
        });
      }
      numToLoop = numToNextLoop;
      numToNextLoop = 0;
    } while (numToLoop !== 0);
    return numOfHol;
  };

  dataReady = () => {
    let fr = new Date();
    fr.setMinutes(fr.getMinutes() + fr.getTimezoneOffset() - 60 * 4);
    let to = new Date();
    to.setMinutes(to.getMinutes() + to.getTimezoneOffset() - 60 * 4);
    // if (!this.props.isMobile) {
    //   fr.setDate(fr.getDate() + 1);
    //   to.setDate(to.getDate() + 1);
    // }
    //console.log(this.series)
    //console.log(this.series.points)
    if (this.state.periodOption === "") {
      // points: {
      // date: Date;
      // year: any;
      // month: any;
      // day: any;
      // hour: any;
      // min: any;
      // open: any;
      // high: any;
      // low: any;
      // close: any;
      // volume: any;
      // turnover: any;}[]
      let points = this.series.points;

      switch (this.state.period) {
        default:
        case "1min":
        case "5mins":
        case "15mins":
        case "30mins":
        case "1hour":
          if (points != null && points.length > 0) {
            // if (points[points.length - 1].date.getDate() === fr.getDate()) {
            //   let isHol = this.getHoliday(fr.getFullYear(), 1);
            //   if (this.props.isMobile) fr.setDate(fr.getDate() - 1);
            //   else fr.setDate(fr.getDate() - 2);
            //   fr.setHours(9, 0, 0, 0);
            // } else {
            //   if (fr.getDay() === 1) {
            //     let numOfHol = this.getLongHoliday(
            //       fr.getFullYear(),
            //       this.props.isMobile ? 3 : 4
            //     );
            //     if (numOfHol > 0) {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 3);
            //       else fr.setDate(fr.getDate() - (4 + numOfHol));
            //       fr.setHours(9, 0, 0, 0);
            //     } else {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 3);
            //       else fr.setDate(fr.getDate() - 4);
            //       fr.setHours(9, 0, 0, 0);
            //     }
            //   } else if (fr.getDay() === 2) {
            //     let numOfHol = this.getLongHoliday(
            //       fr.getFullYear(),
            //       this.props.isMobile ? 1 : 4
            //     );
            //     if (numOfHol > 0) {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 4);
            //       else fr.setDate(fr.getDate() - (4 + numOfHol));
            //       fr.setHours(9, 0, 0, 0);
            //     } else {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 1);
            //       else fr.setDate(fr.getDate() - 4);
            //       fr.setHours(9, 0, 0, 0);
            //     }
            //   } else if (
            //     fr.getDay() == 3 ||
            //     fr.getDay() == 4 ||
            //     fr.getDay() == 5 ||
            //     fr.getDay() == 6
            //   ) {
            //     let numOfHol = this.getLongHoliday(
            //       fr.getFullYear(),
            //       this.props.isMobile ? 1 : 2
            //     );
            //     if (numOfHol > 0) {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 2);
            //       else fr.setDate(fr.getDate() - (2 + numOfHol));
            //       fr.setHours(9, 0, 0, 0);
            //     } else {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 1);
            //       else fr.setDate(fr.getDate() - 2);
            //       fr.setHours(9, 0, 0, 0);
            //     }
            //   } else if (fr.getDay() == 0) {
            //     let numOfHol = this.getLongHoliday(
            //       fr.getFullYear(),
            //       this.props.isMobile ? 2 : 3
            //     );
            //     if (numOfHol > 0) {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 3);
            //       else fr.setDate(fr.getDate() - (3 + numOfHol));
            //       fr.setHours(9, 0, 0, 0);
            //     } else {
            //       if (this.props.isMobile) fr.setDate(fr.getDate() - 2);
            //       else fr.setDate(fr.getDate() - 3);
            //       fr.setHours(9, 0, 0, 0);
            //     }
            //   }
            // }

            // get latest day data range
            let fr_temp = new Date(
              this.series.points[this.series.spotCount - 1].date
            );
            to = new Date(this.series.points[this.series.spotCount - 1].date);
            for (let i = this.series.spotCount - 1; i >= 0; i--) {
              fr_temp = this.series.points[i].date;
              if (to.getDate() === fr_temp.getDate()) {
                fr = fr_temp;
              } else {
                break;
              }
            }
          }
          break;
        case "15mins5d":
          if (points != null && points.length > 0) {
            if (points[points.length - 1].date.getDate() === fr.getDate()) {
              fr.setDate(fr.getDate() - 5);
              fr.setHours(9, 0, 0, 0);
            } else {
              if (fr.getDay() === 6) {
                let numOfHol = this.getLongHoliday(fr.getFullYear(), 5);
                if (numOfHol > 0) {
                  fr.setDate(fr.getDate() - (7 + numOfHol));
                  fr.setHours(9, 0, 0, 0);
                } else {
                  fr.setDate(fr.getDate() - 5);
                  fr.setHours(9, 0, 0, 0);
                }
              } else if (
                fr.getDay() == 2 ||
                fr.getDay() == 3 ||
                fr.getDay() == 4 ||
                fr.getDay() == 5
              ) {
                let numOfHol = this.getLongHoliday(fr.getFullYear(), 7);
                if (numOfHol > 0) {
                  fr.setDate(fr.getDate() - (8 + numOfHol));
                  fr.setHours(9, 0, 0, 0);
                } else {
                  fr.setDate(fr.getDate() - 7);
                  fr.setHours(9, 0, 0, 0);
                }
              } else if (fr.getDay() == 1) {
                let numOfHol = this.getLongHoliday(fr.getFullYear(), 7);
                if (numOfHol > 0) {
                  fr.setDate(fr.getDate() - (9 + numOfHol));
                  fr.setHours(9, 0, 0, 0);
                } else {
                  fr.setDate(fr.getDate() - 7);
                  fr.setHours(9, 0, 0, 0);
                }
              } else {
                let numOfHol = this.getLongHoliday(fr.getFullYear(), 6);
                if (numOfHol > 0) {
                  fr.setDate(fr.getDate() - (8 + numOfHol));
                  fr.setHours(9, 0, 0, 0);
                } else {
                  fr.setDate(fr.getDate() - 6);
                  fr.setHours(9, 0, 0, 0);
                }
              }
            }
          }
          //console.log(fr)
          break;
      }

      if (this.state.info && this.series.points) {
        this.prepareBreak();
      }
      this.showSessionBreak();
    } else if (
      this.state.period === "day_free" ||
      this.state.period === "week_free" ||
      this.state.period === "month_free"
    ) {
      let dateStr = this.state.periodOption.fr.split("/");
      let monthStr =
        dateStr[1].length >= 2
          ? Number(dateStr[1]) - 1
          : Number(dateStr[1].substring(1, 2) - 1);
      let dayStr =
        dateStr[2].length >= 2
          ? Number(dateStr[2])
          : Number(dateStr[2].substring(1, 2));
      fr = new Date(Number(dateStr[0]), monthStr, dayStr);

      let dateToStr = this.state.periodOption.to.split("/");
      let monthToStr =
        dateToStr[1].length >= 2
          ? Number(dateToStr[1]) - 1
          : Number(dateToStr[1].substring(1, 2) - 1);
      let dayToStr =
        dateToStr[2].length >= 2
          ? Number(dateToStr[2])
          : Number(dateToStr[2].substring(1, 2));
      to = new Date(Number(dateToStr[0]), monthToStr, dayToStr);
    } else {
      if (this.state.period === "y") {
        let date = new Date(fr.getFullYear(), 0, 1);
        fr = date;
      } else if (this.state.period === "m") {
        let date = new Date(fr.getFullYear(), fr.getMonth(), 1);
        fr = date;
      } else if (this.state.period.endsWith("m")) {
        fr.setMonth(
          fr.getMonth() -
          Number(this.state.period.substring(0, this.state.period.length - 1))
        );
      } else if (this.state.period.endsWith("y")) {
        fr.setFullYear(
          fr.getFullYear() -
          Number(this.state.period.substring(0, this.state.period.length - 1))
        );
      } else {
        //for week
        fr.setDate(
          fr.getDate() -
          Number(
            this.state.period.substring(0, this.state.period.length - 1)
          ) *
          7
        );
      }
    }
    let frStr =
      fr.getFullYear().toString() +
      "/" +
      (fr.getMonth() + 1 > 9
        ? (fr.getMonth() + 1).toString()
        : "0" + (fr.getMonth() + 1).toString()) +
      "/" +
      (fr.getDate() + 1 > 10
        ? fr.getDate().toString()
        : "0" + fr.getDate().toString());

    let toStr =
      to.getFullYear().toString() +
      "/" +
      (to.getMonth() + 1 > 9
        ? (to.getMonth() + 1).toString()
        : "0" + (to.getMonth() + 1).toString()) +
      "/" +
      (to.getDate() + 1 > 10
        ? to.getDate().toString()
        : "0" + to.getDate().toString());

    this.setState({ frStr: frStr, toStr: toStr });

    if (this.props.showEvent) this.getEvent(this.props.eventType, frStr, toStr);

    if (this.props.compare.length > 0) {
      this.mainchartobj.removeTracker("price");
    }

    if (!this.state.showCompare) {
      if (this.state.showTracker) {
        if (
          this.mainchartobj.series &&
          this.mainchartobj.series.points &&
          this.mainchartobj.series.points.length > 0
        ) {
          let points = this.mainchartobj.series.points;
          let value = points[this.mainchartobj.series.spotCount - 1].close;
          this.mainchartobj.addPriceTracker2("price", value, {
            color: "#" + this.state.priceTrackColor,
            invertColor: "#" + this.state.priceTrackColor,
            textColor: "#" + this.state.priceTrackFontColor,
            thickness: this.state.priceTrackThickness,
            dashstyle:
              this.state.priceTrackStyle === "d"
                ? chartFactory.TRACKER_DASH
                : 0,
            front: true,
          });
        }
      }
    }

    // this.mainchartobj.show(fr, to);

    // //ta
    // this.tachartobjList.forEach((tachartobj) => {
    //   tachartobj.tachartobj.show(fr, to);
    // });

    //scrollpane
    if (
      this.props.period === "1min" ||
      this.props.period === "5mins" ||
      this.props.period === "15mins" ||
      this.props.period === "15mins5d" ||
      this.props.period === "30mins" ||
      this.props.period === "1hour"
    ) {
      this.scrollcharobj.setGridType(chartFactory.GRID_TIME_ALIGN);
    } else this.scrollcharobj.setGridType(chartFactory.GRID_DATE_ALIGN);

    // this.scrollcharobj.show(this.mainchartobj);

    // if (
    //     this.state.period !== "1min" && this.state.period !== "5mins" &&
    //     this.state.period !== "15mins" && this.state.period !== "30mins" &&
    //     this.state.period !== "1hour") {
    this.mainchartobj.series.futureDates(
      this.getFutureDates(this._currentemptyfuturepoints, this.state.period)
    );
    // console.log(
    //   "dataReady futureDates",
    //   this.getFutureDates(this._currentemptyfuturepoints, this.state.period)
    // );

    // slide x axis to market open period if market is opening after calling getFutureDates()
    let pt = this.mainchartobj.series.points[this.series.spotCount - 1];
    let dt = new Date(pt.date);
    let todayOpen = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: this.state.info.tz,
      })
    );
    let todayClose = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: this.state.info.tz,
      })
    );
    let _fr = this.state.info.ses["1"].fr;
    let _to = this.state.info.ses["1"].to;
    todayOpen.setHours(_fr.split(":")[0], _fr.split(":")[1], 0, 0);
    todayClose.setHours(_to.split(":")[0], _to.split(":")[1], 0, 0);
    if (Number(_to.split(":")[0]) - Number(_fr.split(":")[0]) < 0) {
      todayClose.setDate(todayClose.getDate() + 1);
    }
    // console.log(
    //   this.state.period === "1min" && todayOpen.getTime() >= dt.getTime()
    // );
    // console.log(this.state.period === "1min");
    // console.log(this.state.period);
    // console.log(todayOpen.getTime() <= dt.getTime());
    if (this.state.period === "1min" && todayOpen.getTime() <= dt.getTime()) {
      this.mainchartobj.show(todayOpen, todayClose);
      this.scrollcharobj.show(this.mainchartobj);
      // ta change display range
      this.tachartobjList.forEach((tachartobj) => {
        tachartobj.tachartobj.show(todayOpen, todayClose);
      });
    } else {
      this.mainchartobj.show(fr, to);

      // ta
      this.tachartobjList.forEach((tachartobj) => {
        tachartobj.tachartobj.show(fr, to);
      });
    }

    // scrollpane
    this.scrollcharobj.show(this.mainchartobj);

    // }
    if (this.series.points)
      this.setState({
        haveChart:
          this.series.spotCount !== 0 &&
          this.series.points[this.series.spotCount - 1].date > fr,
      });
    this.resizeChart(this.props);
  };

  DEFAULTCOLOR_TRENDLINE_BASELINE = "808080";
  trendLineEvent = (event, engine, obj, x, y) => {
    switch (event) {
      case chartFactory.ANNOTATION_ADD:
        this.currentTrendLineObj = obj; // set this as the current trendline object
        this.setState({
          showAnnotationPopupMenu: true,
          popupMenuMode: "Normal",
          currentTrendLineObj: obj,
        });
        let color, thickness, dash;
        switch (obj.mode) {
          case chartFactory.MODE_TREND_LINE:
            color = this.state.annotationColor;
            thickness = this.state.annotationThickness;
            dash = this.state.drawLineStyle;

            obj.color = "#" + color;
            obj.focusColor = "#" + color;
            obj.thickness = thickness;
            obj.dash =
              this.state.drawLineStyle === "d" ? this.state.drawLineStyle : "";
            break;
          case chartFactory.MODE_FIB_RETRACE:
          case chartFactory.MODE_FIB_FANS:
          case chartFactory.MODE_FIB_ARCS:
            switch (obj.mode) {
              case chartFactory.MODE_FIB_RETRACE:
                color = this.state.drawRetracementColor;
                thickness = this.state.drawRetracementThickness;
                dash =
                  this.state.drawRetracementStyle === "d"
                    ? this.state.drawRetracementStyle
                    : "";
                break;
              case chartFactory.MODE_FIB_FANS:
                color = this.state.drawFanColor;
                thickness = this.state.drawFanThickness;
                dash =
                  this.state.drawFanStyle === "d"
                    ? this.state.drawFanStyle
                    : "";
                break;
              case chartFactory.MODE_FIB_ARCS:
                color = this.state.drawArcsColor;
                thickness = this.state.drawArcsThickness;
                dash =
                  this.state.drawArcstyle === "d"
                    ? this.state.drawArcstyle
                    : "";
                break;
            }

            obj.color = "#" + color;
            obj.focusColor = "#" + this.makefocuscolor(color);
            obj.thickness = thickness;
            obj.dash = dash;
            obj.baseLineColor = "#" + this.DEFAULTCOLOR_TRENDLINE_BASELINE;
            obj.textColor = obj.color;
            break;
        }
        this.props.trendlineObj(obj);
        break;
      case chartFactory.ANNOTATION_DELETE:
        this.currentTrendLineObj = null; // clear the selection reference
        if (this.props.drawTool === "move")
          this.setState({ showAnnotationPopupMenu: false });
        else
          this.setState({
            showAnnotationPopupMenu: true,
            popupMenuMode: "Exit",
          }); // switch to exit form, no trendline is selected after delete
        break;

      case chartFactory.ANNOTATION_SELECT_BEGIN:
        //console.log(obj);
        if (obj != null) {
          this.currentTrendLineObj = obj;
          //console.log(obj)
          this.setState({
            annotationThickness: obj.thickness,
            annotationColor: obj.color.substring(1, obj.color.length),
            showAnnotationPopupMenu: true,
            popupMenuMode: "Normal",
          });
        }
        break;

      case chartFactory.ANNOTATION_SELECT_END:
        //this.currentTrendLineObj  = null;
        //this.setState({ showAnnotationPopupMenu: false })
        break;
      case chartFactory.ANNOTATION_DESELECT:
        this.currentTrendLineObj = null;
        this.setState({ showAnnotationPopupMenu: false });
        break;
      case chartFactory.ANNOTATION_COPY:
        this.currentTrendLineObj = obj;
        break;
      case chartFactory.ANNOTATION_DEFOCUS:
        //this.currentTrendLineObj  = null;
        this.props.trendlineObjDefocus(obj);
        break;
    }
    // if (this.props.isMobile)
    //     this.props.trendlineObj(this.currentTrendLineObj)
  };

  makefocuscolor = (c) => {
    if (c.startsWith("#")) c = c.substr(1);
    var m = {
      r: parseInt(c.substring(0, 2), 16),
      g: parseInt(c.substring(2, 4), 16),
      b: parseInt(c.substring(4, 6), 16),
    };
    m.r = m.r + Math.round((255 - m.r) * 0.5);
    m.g = m.g + Math.round((255 - m.g) * 0.5);
    m.b = m.b + Math.round((255 - m.b) * 0.5);
    return m.r.toString(16) + m.g.toString(16) + m.b.toString(16);
  };

  annotEvtFunc = (type, src, obj, x, y) => {
    if (
      src.name === chartFactory.TREND_LINE ||
      src.name === chartFactory.FIBONACCI
    ) {
      this.setState({ type: type, src: src, obj: obj, x: x, y: y });
      this.trendLineEvent(type, src, obj, x, y);
    } else if (src.name === chartFactory.LABEL) {
      if (obj) obj.mode = chartFactory.MODE_LABEL;
      this.setState({ type: type, src: src, obj: obj, x: x, y: y });
      this.trendLineEvent(type, src, obj, x, y);
    }
  };

  eventboxFunc = (op, events, x, y, r) => {
    let width = 260;
    let height = 0;
    if (op === "SHOW") {
      var eventContent = "";
      for (var i = 0; i < events.length; ++i) {
        height += 48 + 5;
        eventContent +=
          "<div style='margin-bottom: 5px;'><div class='chartNewsEventHeadline' ";
        if (events[i].type == "S") {
          eventContent +=
            "style=' overflow: hidden; cursor: pointer' onclick='LabCI.C5PageObj.eventboxDialog(\"" +
            events[i].content1 +
            '","' +
            events[i].content2 +
            "\")'";
        } else {
          eventContent += "style='overflow: hidden;'";
        }
        eventContent += ">" + events[i].headline + "</div></div>";
      }

      var plotY = y - height - r * 2 - this.container.current.offsetTop + 10;
      var plotX = x - width / 2 - this.container.current.offsetLeft;

      this.setState({
        showEventBox: true,
        eventContent: eventContent,
        eventHeight: height,
        plotY: plotY,
        plotX: plotX,
      });
    } else if (op == "HIDE") {
      this.setState({ showEventBox: false });
    }
  };

  // date: Date
  formatDate(date) {
    if (date) {
      let out =
        date.toLocaleString("en-us", { year: "numeric" }) +
        "-" +
        date.toLocaleString("en-us", { month: "2-digit" }) +
        "-" +
        date.toLocaleString("en-us", { day: "2-digit" });
      if (
        this.props.period === "1min" ||
        this.props.period === "5mins" ||
        this.props.period === "15mins" ||
        this.props.period === "15mins5d" ||
        this.props.period === "1hour"
      )
        // out +=
        //   " " +
        //   date
        //     .toLocaleString("ja", { hour: "2-digit", minute: "2-digit" })
        //     .replace(":", "時") +
        //   "分" +
        //   " ";
        out +=
          " " +
          date.toLocaleString("ja", { hour: "2-digit", minute: "2-digit" }) +
          " ";

      return out;
    }
  }

  // num: any, ta?: string
  formatNumber(num, ta, digit) {
    //return (num === undefined || num === null) ? num : num.toString().replace(
    if (num === undefined || num === null) return num;
    let dp = null;
    const isMA =
      ta === "SMA" ||
      ta === "WMA" ||
      ta === "EMA" ||
      ta === "MEMA" ||
      ta === "TVMA" ||
      ta === "price";
    switch (this.props.code) {
      case ".DJI":
      case ".SPX":
      case "JPY=X":
        dp = 2;
        break;
      case ".IXIC":
        dp = 3;
        break;
      case "US10YT=XX":
      case "EUR=X":
      case "XAU=X":
      case "XAG=X":
      case ".TRCCRB":
        dp = 4;
        break;
      default:
        dp = null;
        break;
    }
    let realNum =
      typeof num === "string" ? parseFloat(num.replace(/,/g, "")) : num;
    return realNum === undefined || realNum === null
      ? realNum
      : dp === null || !isMA
        ? this.addComma(
          realNum.toFixed(
            CompareList.SECTOR.findIndex(
              (item) => item.value === this.props.code
            ) === -1
              ? digit !== null && digit !== undefined ? digit : 2
              : 2
          )
        )
        : this.addComma(realNum.toFixed(dp));
  }

  // ric: string, dp?: number
  indicesDP(ric, dp) {
    switch (ric) {
      case ".DJI":
      case ".SPX":
      case "JPY=X":
        return 2;
      case ".IXIC":
        return 3;
      case "US10YT=XX":
      case "EUR=X":
      case "XAU=X":
      case "XAG=X":
      case ".TRCCRB":
        return 4;
      default:
        return dp
          ? dp
          : CompareList.SECTOR.findIndex(
            (item) => item.value === this.props.code
          ) === -1
            ? 2
            : 2;
    }
  }

  // num: string
  addComma(num) {
    let split = num.split(".");
    split[0] = Number(split[0]).toLocaleString();
    return split[1] !== undefined ? split[0] + "." + split[1] : split[0];
  }

  crosshairFunc = (x, y) => {
    this.setState({ x: x, y: y });
  };

  renderTooltipHeader = () => {
    try {
      const { t } = this.props;
      let period = "";
      if (
        this.props.periodOption === "d" ||
        this.props.periodOption.option === "d"
      ) {
        period = t("chart5.daily");
      } else if (
        this.props.periodOption === "w" ||
        this.props.periodOption.option === "w"
      ) {
        period = t("chart5.weekly");
      } else if (
        this.props.periodOption === "m" ||
        this.props.periodOption.option === "m"
      ) {
        period = t("chart5.monthly");
      } else {
        if (this.props.period === "15mins" || this.props.period === "15mins5d") {
          period = t("chart5.15min");
        } else if (this.props.period === "5mins") {
          period = t("chart5.5min");
        } else if (this.props.period === "1min") {
          period = t("chart5.1min");
        } else if (this.props.period === "1hour") {
          period = t("chart5.1h");
        }
      }

      if (this.props.isMobile) {
        const spFrStr = this.state.frStr
          ? this.state.frStr.replace("/", "年").replace("/", "月") + "日"
          : this.state.frStr;
        const spToStr = this.state.toStr
          ? this.state.toStr.replace("/", "年").replace("/", "月") + "日"
          : this.state.toStr;
        return
          // <div>
          //   <div>
          //     <b>{period} </b>
          //     <b>
          //       {() => {
          //         switch (this.props.type) {
          //           case "line":
          //             return t("chart5.line");
          //           case "candlestick":
          //           default:
          //             return t("chart5.candlestick");
          //           case "bar":
          //             return t("chart5.bar");
          //           case "histogram":
          //             return t("chart5.histogram");
          //         }
          //       }}{" "}
          //     </b>
          //     <b>
          //       {`${t("chart5.date")}: `}
          //       {this.state.scrollpaneTooltip}
          //     </b>
          //   </div>
          //   <b>
          //     {this.state.showCompare ? ` ${t("chart5.comparisonMode")} ` : ""}
          //   </b>
          // </div>
          ;
      } else {
        return (
          <div
          // style={!this.props.isMobile ? { minWidth: "330px" } : {}}
          >
            <b>
              {this.props.nm + " "}
              {/*
            {this.props.code === "XAU=X" ||
            this.props.code === "XAG=X" ||
            this.props.code === "EUR=X" ||
            this.props.code === "JPY=X"
              ? "【10分更新】 "
              : this.props.code === "US10YT=XX"
              ? "【2時間遅延】 "
              : this.props.code === ".TRCCRB" || this.props.code === ".SPX"
              ? ""
              : this.props.perm === 2
              ? "【リアルタイム】 "
              : this.props.code === ".DJI"
              ? "【10分遅延】 "
              : "【15分遅延】 "}
            */}{" "}
              【{t("chart5.realTime")}】{" "}
            </b>
            <b>{period} </b>
            <b>
              {this.props.type === "line"
                ? t("chart5.line")
                : this.props.type === "candlestick"
                  ? t("chart5.candlestick")
                  : t("chart5.bar")}{" "}
            </b>
            <b>
              {this.state.showCompare ? ` ${t("chart5.comparisonMode")} ` : ""}
            </b>
          </div>
        );
      }
    } catch (err) { }
  };

  tooltipFunc = (res) => {
    const { t } = this.props;
    let w = this.container.current.clientWidth;
    //console.log(res)
    if (this.props.compare.length === 0 || !this.props.showCompare) {
      if (res.open) {
        let tt;
        this.setState({
          date: this.formatDate(res.date),
          open: this.formatNumber(res.open, "price"),
          high: this.formatNumber(res.high, "price"),
          low: this.formatNumber(res.low, "price"),
          close: this.formatNumber(res.close, "price"),
          updown: this.formatNumber(res.updown, "price"),
          ratio: this.formatNumber(res.ratio, "price"),
        });
        if (w < 500) {
          this.setState({ date: this.formatDate(res.date) });
          let updown = this.formatNumber(res.updown, "price", 2);
          let ratio = this.formatNumber(res.ratio, "price", 2);
          tt = (
            <div className={[
              "Chart5-OHLCTable",
              this.props.ChgThemeData == 'Light' ? APPstyles.bg_chart5_ohlc_white : APPstyles.bg_chart5_ohlc_black
            ].join(' ')} >
              {/* {"始値"}: <b>{this.formatNumber(res.open, "price")}</b> {"高値"}:{" "}
              <b>{this.formatNumber(res.high, "price")}</b> {"安値"}:{" "}
              <b>{this.formatNumber(res.low, "price")}</b> {"終値"}:{" "}
              <b>{this.formatNumber(res.close, "price")}</b> */}
              {t("chart5.start")}: <b>{this.formatNumber(res.open, "price", 2)}</b>{" "}
              {t("chart5.high")}: <b>{this.formatNumber(res.high, "price", 2)}</b>{" "}
              {t("chart5.low")}: <b>{this.formatNumber(res.low, "price", 2)}</b>{" "}
              {t("chart5.close")}:{" "}
              <b>{this.formatNumber(res.close, "price", 2)}</b>{" "}
              <br />
              {t("chart5.upDown")}:{" "}
              <span className={updown > 0 ? APPstyles.color_red : updown < 0 ? APPstyles.color_green : APPstyles.color_white}>
                <b>{updown > 0 ? `+${updown}` : updown}</b></span>{" "}
              {t("chart5.ratio")}:{" "}
              <span className={ratio > 0 ? APPstyles.color_red : ratio < 0 ? APPstyles.color_green : APPstyles.color_white}>
                <b>{ratio > 0 ? `+${ratio}` : ratio}%</b></span>{" "}
            </div>
          );
          let overlaytt;
          this.props.overlay.forEach((item, i) => {
            if (item.ta === "ICH") {
              let ich = res.ICH;
              this.setState({ ICH: ich });
            }

            if (item.ta === "SMA") {
              let sma0 = res["SMA_" + item.option[0].period];
              let sma1 = res["SMA_" + item.option[1].period];
              let sma2 = res["SMA_" + item.option[2].period];
              let sma3 = res["SMA_" + item.option[3].period];
              let sma4 = res["SMA_" + item.option[4].period];

              overlaytt = {
                sma0: this.formatNumber(sma0, item.ta),
                sma1: this.formatNumber(sma1, item.ta),
                sma2: this.formatNumber(sma2, item.ta),
                sma3: this.formatNumber(sma3, item.ta),
                sma4: this.formatNumber(sma4, item.ta),
              };
              this.setState({ SMA: overlaytt });
            }
            if (item.ta === "WMA") {
              let wma0 = res["WMA_" + item.option[0].period];
              let wma1 = res["WMA_" + item.option[1].period];
              let wma2 = res["WMA_" + item.option[2].period];
              let wma3 = res["WMA_" + item.option[3].period];
              let wma4 = res["WMA_" + item.option[4].period];

              overlaytt = {
                wma0: this.formatNumber(wma0, item.ta),
                wma1: this.formatNumber(wma1, item.ta),
                wma2: this.formatNumber(wma2, item.ta),
                wma3: this.formatNumber(wma3, item.ta),
                wma4: this.formatNumber(wma4, item.ta),
              };
              this.setState({ WMA: overlaytt });
            }
            if (item.ta === "EMA") {
              let ema0 = res["EMA_" + item.option[0].period];
              let ema1 = res["EMA_" + item.option[1].period];
              let ema2 = res["EMA_" + item.option[2].period];
              let ema3 = res["EMA_" + item.option[3].period];
              let ema4 = res["EMA_" + item.option[4].period];

              overlaytt = {
                ema0: this.formatNumber(ema0, item.ta),
                ema1: this.formatNumber(ema1, item.ta),
                ema2: this.formatNumber(ema2, item.ta),
                ema3: this.formatNumber(ema3, item.ta),
                ema4: this.formatNumber(ema4, item.ta),
              };
              this.setState({ EMA: overlaytt });
            }
            if (item.ta === "MEMA") {
              let mema0 = res["MEMA_" + item.option[0].period];
              let mema1 = res["MEMA_" + item.option[1].period];
              let mema2 = res["MEMA_" + item.option[2].period];
              let mema3 = res["MEMA_" + item.option[3].period];
              let mema4 = res["MEMA_" + item.option[4].period];

              overlaytt = {
                mema0: this.formatNumber(mema0, item.ta),
                mema1: this.formatNumber(mema1, item.ta),
                mema2: this.formatNumber(mema2, item.ta),
                mema3: this.formatNumber(mema3, item.ta),
                mema4: this.formatNumber(mema4, item.ta),
              };
              this.setState({ MEMA: overlaytt });
            }
            if (item.ta === "BB") {
              let bb0, bb1, bb2, bb3, bb4, bb5, bb6;
              if (res["BB_" + item.option.BB.period])
                bb0 = res["BB_" + item.option.BB.period].m;
              let v1 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[0].value
                ];
              if (v1) {
                bb1 = v1.l;
                bb2 = v1.u;
              }

              let v2 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[1].value
                ];
              if (v2) {
                bb3 = v2.l;
                bb4 = v2.u;
              }

              let v3 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[2].value
                ];
              if (v3) {
                bb5 = v3.l;
                bb6 = v3.u;
              }

              overlaytt = {
                bb0: this.formatNumber(bb0, item.ta),
                bb1: this.formatNumber(bb1, item.ta),
                bb2: this.formatNumber(bb2, item.ta),
                bb3: this.formatNumber(bb3, item.ta),
                bb4: this.formatNumber(bb4, item.ta),
                bb5: this.formatNumber(bb5, item.ta),
                bb6: this.formatNumber(bb6, item.ta),
              };
              this.setState({ BB: overlaytt });
            }
            if (item.ta === "ENV") {
              let env0;
              if (res["ENV_" + item.option.mid.period])
                env0 = res["ENV_" + item.option.mid.period].m;
              let v1 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[0].p
                  ? item.option.factor[0].p / 100
                  : item.option.factor[0].p)
                ];
              let env1 = v1 === undefined ? "" : v1.l;
              let env2 = v1 === undefined ? "" : v1.u;
              let v2 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[1].p
                  ? item.option.factor[1].p / 100
                  : item.option.factor[1].p)
                ];
              let env3 = v2 === undefined ? "" : v2.l;
              let env4 = v2 === undefined ? "" : v2.u;
              let v3 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[2].p
                  ? item.option.factor[2].p / 100
                  : item.option.factor[2].p)
                ];
              let env5 = v3 === undefined ? "" : v3.l;
              let env6 = v3 === undefined ? "" : v3.u;
              let v4 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[3].p
                  ? item.option.factor[3].p / 100
                  : item.option.factor[3].p)
                ];
              let env7 = v4 === undefined ? "" : v4.l;
              let env8 = v4 === undefined ? "" : v4.u;
              let v5 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[4].p
                  ? item.option.factor[4].p / 100
                  : item.option.factor[4].p)
                ];
              let env9 = v5 === undefined ? "" : v5.l;
              let env10 = v5 === undefined ? "" : v5.u;

              overlaytt = {
                env0: this.formatNumber(env0, item.ta),
                env1: this.formatNumber(env1, item.ta),
                env2: this.formatNumber(env2, item.ta),
                env3: this.formatNumber(env3, item.ta),
                env4: this.formatNumber(env4, item.ta),
                env5: this.formatNumber(env5, item.ta),
                env6: this.formatNumber(env6, item.ta),
                env7: this.formatNumber(env7, item.ta),
                env8: this.formatNumber(env8, item.ta),
                env9: this.formatNumber(env9, item.ta),
                env10: this.formatNumber(env10, item.ta),
              };
              this.setState({ ENV: overlaytt });
            }
            if (item.ta === "KC") {
              let env0;
              if (res["KC_" + item.option.mid.period])
                env0 = res["KC_" + item.option.mid.period].m;
              let v1 = res["KC_" + item.option.mid.period + "_1"];
              let env1 = v1 === undefined ? "" : v1.l;
              let env2 = v1 === undefined ? "" : v1.u;
              let v2 = res["KC_" + item.option.mid.period + "_2"];
              let env3 = v2 === undefined ? "" : v2.l;
              let env4 = v2 === undefined ? "" : v2.u;
              let v3 = res["KC_" + item.option.mid.period + "_3"];
              let env5 = v3 === undefined ? "" : v3.l;
              let env6 = v3 === undefined ? "" : v3.u;

              overlaytt = {
                env0: this.formatNumber(env0, item.ta),
                env1: this.formatNumber(env1, item.ta),
                env2: this.formatNumber(env2, item.ta),
                env3: this.formatNumber(env3, item.ta),
                env4: this.formatNumber(env4, item.ta),
                env5: this.formatNumber(env5, item.ta),
                env6: this.formatNumber(env6, item.ta),
              };
              this.setState({ KC: overlaytt });
            }
            if (
              item.ta === "TVMA" ||
              item.ta === "LRT" ||
              item.ta === "SAR" ||
              item.ta === "VWAP" ||
              item.ta === "WC"
            ) {
              overlaytt = this.formatNumber(res[item.ta], item.ta);
              this.setState({ [item.ta]: overlaytt });
            }
          });
        } else {
          let updown = this.formatNumber(res.updown, "price", 2);
          let ratio = this.formatNumber(res.ratio, "price", 2);
          tt = (
            <div style={this.props.isChartRotated ? { marginTop: "20px" } : {}} className={[
              "Chart5-OHLCTable",
              this.props.ChgThemeData == 'Light' ? APPstyles.bg_chart5_ohlc_white : APPstyles.bg_chart5_ohlc_black
            ].join(' ')} >
              {/* <b>{this.props.nm + " "}{'【リアルタイム】'}</b>
                        <b>{period}{' '}</b>
                        <b>{this.props.type === 'line' ? "ライン " : this.props.type === 'candlestick' ? "ローソク " : "バー "}</b> */}
              {/* {t("chart5.date")}: <b>{this.formatDate(res.date)}</b>{" "} */}
              {t("chart5.start")}: <b>{this.formatNumber(res.open, "price", 2)}</b>{" "}
              {t("chart5.high")}: <b>{this.formatNumber(res.high, "price", 2)}</b>{" "}
              {t("chart5.low")}: <b>{this.formatNumber(res.low, "price", 2)}</b>{" "}
              {t("chart5.close")}:{" "}
              <b>{this.formatNumber(res.close, "price", 2)}</b>{" "}
              {this.props.isChartRotated ? "" : <br />}
              {t("chart5.upDown")}:{" "}
              <span className={updown > 0 ? APPstyles.color_red : updown < 0 ? APPstyles.color_green : APPstyles.color_white}>
                <b>{updown > 0 ? `+${updown}` : updown}</b></span>{" "}
              {t("chart5.ratio")}:{" "}
              <span className={ratio > 0 ? APPstyles.color_red : ratio < 0 ? APPstyles.color_green : APPstyles.color_white}>
                <b>{ratio > 0 ? `+${ratio}` : ratio}%</b></span>{" "}
            </div>
          );
          let overlaytt;
          this.props.overlay.forEach((item, i) => {
            if (item.ta === "ICH") {
              let ich = res.ICH;
              this.setState({ ICH: ich });
            }

            if (item.ta === "SMA") {
              let sma0 = res["SMA_" + item.option[0].period];
              let sma1 = res["SMA_" + item.option[1].period];
              let sma2 = res["SMA_" + item.option[2].period];
              let sma3 = res["SMA_" + item.option[3].period];
              let sma4 = res["SMA_" + item.option[4].period];

              overlaytt = {
                sma0: this.formatNumber(sma0, item.ta),
                sma1: this.formatNumber(sma1, item.ta),
                sma2: this.formatNumber(sma2, item.ta),
                sma3: this.formatNumber(sma3, item.ta),
                sma4: this.formatNumber(sma4, item.ta),
              };
              this.setState({ SMA: overlaytt });
            }
            if (item.ta === "WMA") {
              let wma0 = res["WMA_" + item.option[0].period];
              let wma1 = res["WMA_" + item.option[1].period];
              let wma2 = res["WMA_" + item.option[2].period];
              let wma3 = res["WMA_" + item.option[3].period];
              let wma4 = res["WMA_" + item.option[4].period];

              overlaytt = {
                wma0: this.formatNumber(wma0, item.ta),
                wma1: this.formatNumber(wma1, item.ta),
                wma2: this.formatNumber(wma2, item.ta),
                wma3: this.formatNumber(wma3, item.ta),
                wma4: this.formatNumber(wma4, item.ta),
              };
              this.setState({ WMA: overlaytt });
            }
            if (item.ta === "EMA") {
              let ema0 = res["EMA_" + item.option[0].period];
              let ema1 = res["EMA_" + item.option[1].period];
              let ema2 = res["EMA_" + item.option[2].period];
              let ema3 = res["EMA_" + item.option[3].period];
              let ema4 = res["EMA_" + item.option[4].period];

              overlaytt = {
                ema0: this.formatNumber(ema0, item.ta),
                ema1: this.formatNumber(ema1, item.ta),
                ema2: this.formatNumber(ema2, item.ta),
                ema3: this.formatNumber(ema3, item.ta),
                ema4: this.formatNumber(ema4, item.ta),
              };
              this.setState({ EMA: overlaytt });
            }
            if (item.ta === "MEMA") {
              let mema0 = res["MEMA_" + item.option[0].period];
              let mema1 = res["MEMA_" + item.option[1].period];
              let mema2 = res["MEMA_" + item.option[2].period];
              let mema3 = res["MEMA_" + item.option[3].period];
              let mema4 = res["MEMA_" + item.option[4].period];

              overlaytt = {
                mema0: this.formatNumber(mema0, item.ta),
                mema1: this.formatNumber(mema1, item.ta),
                mema2: this.formatNumber(mema2, item.ta),
                mema3: this.formatNumber(mema3, item.ta),
                mema4: this.formatNumber(mema4, item.ta),
              };
              this.setState({ MEMA: overlaytt });
            }
            if (item.ta === "BB") {
              let bb0, bb1, bb2, bb3, bb4, bb5, bb6;
              if (res["BB_" + item.option.BB.period])
                bb0 = res["BB_" + item.option.BB.period].m;
              let v1 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[0].value
                ];
              if (v1) {
                bb1 = v1.l;
                bb2 = v1.u;
              }

              let v2 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[1].value
                ];
              if (v2) {
                bb3 = v2.l;
                bb4 = v2.u;
              }

              let v3 =
                res[
                "BB_" +
                item.option.BB.period +
                "_" +
                item.option.deviation[2].value
                ];
              if (v3) {
                bb5 = v3.l;
                bb6 = v3.u;
              }

              overlaytt = {
                bb0: this.formatNumber(bb0, item.ta),
                bb1: this.formatNumber(bb1, item.ta),
                bb2: this.formatNumber(bb2, item.ta),
                bb3: this.formatNumber(bb3, item.ta),
                bb4: this.formatNumber(bb4, item.ta),
                bb5: this.formatNumber(bb5, item.ta),
                bb6: this.formatNumber(bb6, item.ta),
              };
              this.setState({ BB: overlaytt });
            }
            if (item.ta === "ENV") {
              let env0;
              if (res["ENV_" + item.option.mid.period])
                env0 = res["ENV_" + item.option.mid.period].m;
              let v1 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[0].p
                  ? item.option.factor[0].p / 100
                  : item.option.factor[0].p)
                ];
              let env1 = v1 === undefined ? "" : v1.l;
              let env2 = v1 === undefined ? "" : v1.u;
              let v2 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[1].p
                  ? item.option.factor[1].p / 100
                  : item.option.factor[1].p)
                ];
              let env3 = v2 === undefined ? "" : v2.l;
              let env4 = v2 === undefined ? "" : v2.u;
              let v3 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[2].p
                  ? item.option.factor[2].p / 100
                  : item.option.factor[2].p)
                ];
              let env5 = v3 === undefined ? "" : v3.l;
              let env6 = v3 === undefined ? "" : v3.u;
              let v4 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[3].p
                  ? item.option.factor[3].p / 100
                  : item.option.factor[3].p)
                ];
              let env7 = v4 === undefined ? "" : v4.l;
              let env8 = v4 === undefined ? "" : v4.u;
              let v5 =
                res[
                "ENV_" +
                item.option.mid.period +
                "_" +
                (item.option.factor[4].p
                  ? item.option.factor[4].p / 100
                  : item.option.factor[4].p)
                ];
              let env9 = v5 === undefined ? "" : v5.l;
              let env10 = v5 === undefined ? "" : v5.u;

              overlaytt = {
                env0: this.formatNumber(env0, item.ta),
                env1: this.formatNumber(env1, item.ta),
                env2: this.formatNumber(env2, item.ta),
                env3: this.formatNumber(env3, item.ta),
                env4: this.formatNumber(env4, item.ta),
                env5: this.formatNumber(env5, item.ta),
                env6: this.formatNumber(env6, item.ta),
                env7: this.formatNumber(env7, item.ta),
                env8: this.formatNumber(env8, item.ta),
                env9: this.formatNumber(env9, item.ta),
                env10: this.formatNumber(env10, item.ta),
              };
              this.setState({ ENV: overlaytt });
            }
            if (item.ta === "KC") {
              let env0;
              if (res["KC_" + item.option.mid.period])
                env0 = res["KC_" + item.option.mid.period].m;
              let v1 = res["KC_" + item.option.mid.period + "_1"];
              let env1 = v1 === undefined ? "" : v1.l;
              let env2 = v1 === undefined ? "" : v1.u;
              let v2 = res["KC_" + item.option.mid.period + "_2"];
              let env3 = v2 === undefined ? "" : v2.l;
              let env4 = v2 === undefined ? "" : v2.u;
              let v3 = res["KC_" + item.option.mid.period + "_3"];
              let env5 = v3 === undefined ? "" : v3.l;
              let env6 = v3 === undefined ? "" : v3.u;

              overlaytt = {
                env0: this.formatNumber(env0, item.ta),
                env1: this.formatNumber(env1, item.ta),
                env2: this.formatNumber(env2, item.ta),
                env3: this.formatNumber(env3, item.ta),
                env4: this.formatNumber(env4, item.ta),
                env5: this.formatNumber(env5, item.ta),
                env6: this.formatNumber(env6, item.ta),
              };
              this.setState({ KC: overlaytt });
            }
            if (
              item.ta === "TVMA" ||
              item.ta === "LRT" ||
              item.ta === "SAR" ||
              item.ta === "VWAP" ||
              item.ta === "WC"
            ) {
              overlaytt = this.formatNumber(res[item.ta], item.ta);
              this.setState({ [item.ta]: overlaytt });
            }
          });
        }
        this.setState({ mainTooltip: tt });
        this.props.setOHLCTable(tt)
      } else {
        let tt;
        if (res.UI !== undefined) {
          tt = res.UI;
          this.setState({ UI: this.formatNumber(tt) });
        }
        if (res.CC !== undefined) {
          tt = res.CC;
          this.setState({ CC: this.formatNumber(tt) });
        }
        if (res.TP !== undefined) {
          tt = res.TP;
          this.setState({ TP: this.formatNumber(tt) });
        }
        if (res.RC !== undefined) {
          tt = res.RC;
          this.setState({ RC: this.formatNumber(tt) });
        }
        if (res.MI !== undefined) {
          tt = res.MI;
          this.setState({ MI: this.formatNumber(tt) });
        }
        if (res.PCV !== undefined) {
          tt = res.PCV;
          this.setState({ PCV: this.formatNumber(tt) });
        }
        if (res.PROC !== undefined) {
          tt = res.PROC;
          this.setState({ PROC: this.formatNumber(tt) });
        }
        if (res.WR !== undefined) {
          tt = res.WR;
          this.setState({ WR: this.formatNumber(tt) });
        }
        if (res.POSC !== undefined) {
          tt = res.POSC;
          this.setState({ POSC: this.formatNumber(tt) });
        }
        if (res.MOM !== undefined) {
          tt = res.MOM;
          this.setState({ MOM: this.formatNumber(tt) });
        }
        if (res.VR !== undefined) {
          tt = res.VR;
          this.setState({ VR: this.formatNumber(tt) });
        }
        if (res.FI !== undefined) {
          tt = res.FI;
          this.setState({ FI: this.formatNumber(tt) });
        }
        if (res.WAD !== undefined) {
          tt = res.WAD;
          this.setState({ WAD: this.formatNumber(tt) });
        }
        if (res.DPO !== undefined) {
          tt = res.DPO;
          this.setState({ DPO: this.formatNumber(tt) });
        }
        if (res.UO !== undefined) {
          tt = res.UO;
          this.setState({ UO: this.formatNumber(tt) });
        }
        if (res.MI !== undefined) {
          tt = res.MI;
          this.setState({ MI: this.formatNumber(tt) });
        }
        if (res.CCI !== undefined) {
          tt = res.CCI;
          this.setState({ CCI: this.formatNumber(tt) });
        }
        if (res.RCI !== undefined) {
          tt = res.RCI;
          this.setState({ RCI: this.formatNumber(tt) });
        }
        if (res.CO !== undefined) {
          tt = res.CO;
          this.setState({ CO: this.formatNumber(tt) });
        }
        if (res.PL !== undefined) {
          tt = res.PL;
          this.setState({ PL: this.formatNumber(tt) });
        }
        if (res.VltyCC !== undefined) {
          tt = res.VltyCC;
          this.setState({ VltyCC: this.formatNumber(tt) });
        }
        if (res.SD !== undefined) {
          tt = res.SD;
          this.setState({ SD: this.formatNumber(tt) });
        }
        if (res.ATR !== undefined) {
          tt = res.ATR;
          this.setState({ ATR: this.formatNumber(tt) });
        }
        if (res.VC !== undefined) {
          tt = res.VC;
          this.setState({ VC: this.formatNumber(tt) });
        }
        if (res.adxr !== undefined) {
          tt = res.adxr;
          this.setState({ ADXR: this.formatNumber(tt) });
        }
        if (res.Bias !== undefined) {
          tt = res.Bias;
          this.setState({ Bias: this.formatNumber(tt) });
        }
        if (res.ACD !== undefined) {
          tt = res.ACD;
          this.setState({ ACD: this.formatNumber(tt) });
        }
        if (res.ALF !== undefined) {
          tt = res.ALF;
          this.setState({ ALF: this.formatNumber(tt) });
        }
        if (res.RMI !== undefined) {
          tt = res.RMI;
          this.setState({ RMI: this.formatNumber(tt) });
        }
        if (res.MFI !== undefined) {
          tt = res.MFI;
          this.setState({ MFI: this.formatNumber(tt) });
        }
        if (res.Margin !== undefined) {
          tt = { mb: res.mb, ms: res.mb };
          this.setState({ Margin: this.formatNumber(tt) });
        }
        if (res.VOSC !== undefined) {
          tt = res.VOSC;
          this.setState({ VOSC: this.formatNumber(tt) });
        }
        if (res.VROC !== undefined) {
          tt = res.VROC;
          this.setState({ VROC: this.formatNumber(tt) });
        }
        if (res.OBV !== undefined) {
          tt = res.OBV;
          this.setState({ OBV: this.formatNumber(tt) });
        }
        if (res.EOM !== undefined) {
          tt = res.EOM;
          this.setState({ EOM: this.formatNumber(tt) });
        }
        if (res.VE !== undefined) {
          tt = {
            volume: this.formatNumber(res.volume),
            up: this.formatNumber(res.VE.up),
            lo: this.formatNumber(res.VE.lo),
          };
          this.setState({ VE: tt });
        }
        if (res.Vector !== undefined) {
          tt = {
            v: this.formatNumber(res.Vector.v),
            s: this.formatNumber(res.Vector.s),
          };
          this.setState({ Vector: tt });
        }
        if (res.trix !== undefined) {
          this.setState({
            Trix: {
              trix: this.formatNumber(res.trix),
              s: this.formatNumber(res.s),
            },
          });
        }
        if (res.adx !== undefined) {
          this.setState({
            DMI: {
              adx: this.formatNumber(res.adx),
              m: this.formatNumber(res.m),
              p: this.formatNumber(res.p),
            },
          });
        }

        if (res.macd !== undefined) {
          this.setState({
            MACD: {
              histogram: this.formatNumber(res.histogram),
              macd: this.formatNumber(res.macd),
              signal: this.formatNumber(res.signal),
            },
          });
        }

        if (res.pctD !== undefined) {
          this.setState({
            FKD: {
              pctD: this.formatNumber(res.pctD),
              pctK: this.formatNumber(res.pctK),
            },
          });
        }

        if (res.pctK !== undefined) {
          this.setState({
            SKD: {
              pctD: this.formatNumber(res.pctD),
              pctK: this.formatNumber(res.pctK),
            },
          });
        }

        if (res.Volume !== undefined) {
          tt = res.Volume;
          this.setState({ Volume: this.formatNumber(tt.volume, null, 0) });
        }
        this.props.tapane.forEach((item, i) => {
          if (item.ta === "UPDW") {
            let updw0, updw1;
            if (res["UPDW_" + item.option.options[0].period])
              updw0 = this.formatNumber(
                res["UPDW_" + item.option.options[0].period]
              );
            if (res["UPDW_" + item.option.options[1].period])
              updw1 = this.formatNumber(
                res["UPDW_" + item.option.options[1].period]
              );
            let tt;
            if (updw0 || updw1) {
              tt = { updw0: updw0, updw1: updw1 };
              this.setState({ UPDW: tt });
            }
          }

          if (item.ta === "KRI") {
            let kri0 = this.formatNumber(
              res["KRI_" + item.option.options[0].period]
            );
            let kri1 = this.formatNumber(
              res["KRI_" + item.option.options[1].period]
            );

            if (kri0 || kri1) {
              let tt = { kri0: kri0, kri1: kri1 };
              this.setState({ KRI: tt });
            }
          }

          if (item.ta === "RSI") {
            let rsi0_method = 0;
            let rsi1_method = 0;
            switch (item.option.atr[0].ta) {
              case "sma":
                rsi0_method = chartFactory.MA_SMA;
                break;
              case "wma":
                rsi0_method = chartFactory.MA_WMA;
                break;
              case "mema":
                rsi0_method = chartFactory.MA_MEMA;
                break;
              case "ema":
                rsi0_method = chartFactory.MA_EMA;
                break;
            }
            switch (item.option.atr[1].ta) {
              case "sma":
                rsi1_method = chartFactory.MA_SMA;
                break;
              case "wma":
                rsi1_method = chartFactory.MA_WMA;
                break;
              case "mema":
                rsi1_method = chartFactory.MA_MEMA;
                break;
              case "ema":
                rsi1_method = chartFactory.MA_EMA;
                break;
            }
            let rsi0 = this.formatNumber(
              res["RSI_" + rsi0_method + "_" + item.option.atr[0].period]
            );
            let rsi1 = this.formatNumber(
              res["RSI_" + rsi1_method + "_" + item.option.atr[1].period]
            );

            if (rsi0 || rsi1) {
              let tt = { rsi0: rsi0, rsi1: rsi1 };
              this.setState({ RSI: tt });
            }
          }

          if (item.ta === "NVI") {
            let nvi0 = res["NVI_" + item.option.atr[0].period];
            let nvi1 = res["NVI_" + item.option.atr[1].period];

            if (nvi0 || nvi1) {
              let tt = { nvi0: nvi0, nvi1: nvi1 };
              this.setState({ NVI: tt });
            }
          }
        });
      }
    } else {
      if (!this.props.isMobile) {
        let tt;
        tt = (
          <span>
            {"日付"}: <b>{this.formatDate(res.date)}</b>
          </span>
        );
        this.setState({ mainTooltip: tt });
      }
    }
  };

  dateRangeFunc = (from, to) => {
    let tt;
    tt = (
      <span>
        <b>
          {this.formatDate(from)} ～ {this.formatDate(to)}
        </b>
      </span>
    );
    if (this.props.isMobile) {
      tt = (
        <span>
          <b>
            {this.formatDate(from)}～{this.formatDate(to)}
          </b>
        </span>
      );
    }
    this.setState({ scrollpaneTooltip: tt });
  };

  onPanelClick = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        this.resizeChart(this.props);
      });
    } else
      this.setState({ isOpen: true }, () => {
        this.resizeChart(this.props);
      });
  };

  prepareBreak() {
    let info = this.state.info;
    let ses1 = info.ses["1"];
    let ses2 = info.ses["2"];
    let totalPoint = 0;
    if (
      (info.assettype === "EQTY" ||
        info.assettype === "EQTYIDX" ||
        info.assettype === "SECTOR") &&
      ses2 !== undefined
    ) {
      info = $.extend({}, info);
      info.ses["1"].to = info.ses["2"].to;
      delete info.ses["2"];
    }

    let tmparr = new Array();
    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    today = yyyy.toString() + mm.toString() + dd.toString();
    // let start =
    //   ses1.fr.substr(0, 2) +
    //   ses1.fr.substr(3, 2);
    // let daybreakStart =
    //   ses1.to.substr(0, 2) +
    //   ses1.to.substr(3, 2);
    // let end = yyyy.toString() + mm.toString() + dd.toString() + ses2.fr.substr(0, 2) + ses2.fr.substr(3, 2)
    // let daybreakEnd = yyyy.toString() + mm.toString() + dd.toString() + ses2.to.substr(0, 2) + ses2.to.substr(3, 2)
    if (this.series.points) {
      this.series.points.forEach((e) => {
        let row = new Date(e.year, e.month - 1, e.day, e.hour, e.min);
        let startDate = new Date(
          e.year,
          e.month - 1,
          e.day,
          parseInt(ses1.fr.substr(0, 2))
        );
        let endDate = new Date(
          e.year,
          e.month - 1,
          e.day,
          parseInt(ses1.to.substr(0, 2))
        );

        if (
          row >= startDate &&
          row <= endDate
          // || row === daybreakEnd || row === end
        ) {
          if (row.getTime() === startDate.getTime()) {
            e.isTrack = true;
          }
          tmparr.push(e);
          totalPoint++;
        }
      });
    }
    this.series.points = tmparr;
    this.series.spotCount = totalPoint;
  }

  showSessionBreak() {
    //console.log(this.mainchartobj.series);
    // this.mainchartobj.addDateTracker("lunchbreak", "lunchbreak", "#808080", {
    //   thickness: 2,
    //   dashstyle: chartFactory.TRACKER_DASH,
    // });

    this.mainchartobj.addDateTracker("daybreak", "daybreak", "#808080", {
      thickness: 2,
      dashstyle: chartFactory.TRACKER_DASH,
    });
  }

  renderEventBox() {
    if (this.container && this.container.current) {
      if (this.state.showEventBox) {
        return (
          <div
            className="eventBox"
            style={{
              top: this.state.plotY + "px",
              left: this.state.plotX + "px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: this.state.eventContent }}
            ></div>
          </div>
        );
      }
    }
  }

  renderTooltips() {
    if (this.container && this.container.current) {
      let h = this.container.current.clientHeight;
      let taheight = 0;
      if (this.state.render) {
        let list = [];
        this.tachartobjList.forEach((e, i) => {
          let idx = this.tachartobjList.length - 1 - i;
          let taobj = this.tachartobjList[idx];
          taheight += taobj.tachartobj.height;
          let top = h - 150 - taheight;
          if (!this.state.isOpen) {
            top = top + 130;
          }
          let obj;
          switch (taobj.option.ta) {
            case "FKD":
              if (this.state.FKD !== undefined)
                obj = (
                  <span>
                    <span style={{ color: "#" + taobj.option.option.color }}>
                      {"%K" +
                        (this.state.FKD.pctK === undefined
                          ? "- "
                          : this.state.FKD.pctK) +
                        " "}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.color2 }}>
                      {"%D" +
                        (this.state.FKD.pctD === undefined
                          ? "- "
                          : this.state.FKD.pctD)}
                    </span>
                  </span>
                );
              break;
            case "SKD":
              if (this.state.SKD !== undefined)
                obj = (
                  <span>
                    <span style={{ color: "#" + taobj.option.option.color }}>
                      {"%K" +
                        (this.state.SKD.pctK === undefined
                          ? "- "
                          : this.state.SKD.pctK) +
                        " "}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.color2 }}>
                      {"%D" +
                        (this.state.SKD.pctD === undefined
                          ? "- "
                          : this.state.SKD.pctD)}
                    </span>
                  </span>
                );
              break;
            case "DMI":
              if (this.state.DMI !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.enabled && (
                      <>
                        <span
                          style={{ color: "#" + taobj.option.option.color }}
                        >
                          {(this.state.DMI.p === undefined
                            ? "- "
                            : this.state.DMI.p) + "(+DI) "}
                        </span>
                        <span
                          style={{ color: "#" + taobj.option.option.color2 }}
                        >
                          {(this.state.DMI.m === undefined
                            ? "- "
                            : this.state.DMI.m) + "(-DI) "}
                        </span>
                      </>
                    )}
                    {taobj.option.option.adx.enabled && (
                      <span
                        style={{ color: "#" + taobj.option.option.adx.color }}
                      >
                        {(this.state.DMI.adx === undefined
                          ? "- "
                          : this.state.DMI.adx) + "(ADX)"}
                      </span>
                    )}
                  </span>
                );
              break;
            case "MACD":
              if (this.state.MACD !== undefined)
                obj = (
                  <span>
                    <span style={{ color: "#" + taobj.option.option.color }}>
                      {(this.state.MACD.macd === undefined
                        ? "- "
                        : this.state.MACD.macd) + "(MACD) "}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.color3 }}>
                      {(this.state.MACD.signal === undefined
                        ? "- "
                        : this.state.MACD.signal) + `(${t("chart5.signal")}) `}
                    </span>
                    <span>
                      {(this.state.MACD.histogram === undefined
                        ? "- "
                        : this.state.MACD.histogram) +
                        `(${t("chart5.oscillator2")})`}
                    </span>
                  </span>
                );
              break;
            case "KRI":
              if (this.state.KRI !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.options[0].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.options[0].color,
                        }}
                      >
                        {(this.state.KRI.kri0 === undefined
                          ? "- "
                          : this.state.KRI.kri0) +
                          "(" +
                          taobj.option.option.options[0].period +
                          ") "}
                      </span>
                    )}
                    {taobj.option.option.options[1].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.options[1].color,
                        }}
                      >
                        {(this.state.KRI.kri1 === undefined
                          ? "- "
                          : this.state.KRI.kri1) +
                          "(" +
                          taobj.option.option.options[1].period +
                          ") "}
                      </span>
                    )}
                  </span>
                );
              break;
            case "UPDW":
              if (this.state.UPDW !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.options[0].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.options[0].color,
                        }}
                      >
                        {(this.state.UPDW.updw0 === undefined
                          ? "- "
                          : this.state.UPDW.updw0) +
                          "(" +
                          taobj.option.option.options[0].period +
                          ") "}
                      </span>
                    )}
                    {taobj.option.option.options[1].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.options[1].color,
                        }}
                      >
                        {(this.state.UPDW.updw1 === undefined
                          ? "- "
                          : this.state.UPDW.updw1) +
                          "(" +
                          taobj.option.option.options[1].period +
                          ") "}
                      </span>
                    )}
                  </span>
                );
              break;
            case "Vector":
              if (this.state.Vector !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.p1Enable && (
                      <span
                        style={{ color: "#" + taobj.option.option.p1Color }}
                      >
                        {(this.state.Vector.v === undefined
                          ? "- "
                          : this.state.Vector.v) +
                          `(${t("chart5.TA.Vector")}) `}
                      </span>
                    )}
                    {taobj.option.option.p2Enable && (
                      <span
                        style={{ color: "#" + taobj.option.option.p2Color }}
                      >
                        {(this.state.Vector.s === undefined
                          ? "- "
                          : this.state.Vector.s) + ` (${t("chart5.signal")})`}
                      </span>
                    )}
                  </span>
                );
              break;
            case "NVI":
              if (this.state.NVI !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.atr[0].enabled &&
                      taobj.option.option.atr[0].period}
                    {taobj.option.option.atr[0].enabled &&
                      taobj.option.option.atr[1].enabled &&
                      ","}
                    {taobj.option.option.atr[1].enabled &&
                      taobj.option.option.atr[1].period}
                    {": "}
                    {taobj.option.option.atr[0].enabled &&
                      this.state.NVI.nvi0 ? (
                      <>
                        <span
                          style={{ color: "#" + taobj.option.option.NVI.color }}
                        >
                          {(this.state.NVI.nvi0.s === undefined
                            ? "- "
                            : this.formatNumber(this.state.NVI.nvi0.cnvi)) +
                            " "}
                        </span>
                        <span
                          style={{
                            color: "#" + taobj.option.option.atr[0].color,
                          }}
                        >
                          {(this.state.NVI.nvi0.s === undefined
                            ? "- "
                            : this.formatNumber(this.state.NVI.nvi0.s)) +
                            "(" +
                            taobj.option.option.atr[0].period +
                            ") "}
                        </span>
                      </>
                    ) : null}
                    {taobj.option.option.atr[1].enabled &&
                      this.state.NVI.nvi1 ? (
                      <>
                        <span
                          style={{ color: "#" + taobj.option.option.NVI.color }}
                        >
                          {(this.state.NVI.nvi1.s === undefined
                            ? "- "
                            : this.formatNumber(this.state.NVI.nvi1.cnvi)) +
                            " "}
                        </span>
                        <span
                          style={{
                            color: "#" + taobj.option.option.atr[1].color,
                          }}
                        >
                          {(this.state.NVI.nvi1.cnvi === undefined
                            ? "- "
                            : this.formatNumber(this.state.NVI.nvi1.s)) +
                            "(" +
                            taobj.option.option.atr[1].period +
                            ") "}
                        </span>
                      </>
                    ) : null}
                  </span>
                );
              break;
            case "RSI":
              if (this.state.RSI !== undefined)
                obj = (
                  <span>
                    {taobj.option.option.atr[0].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.atr[0].color,
                        }}
                      >
                        {(this.state.RSI.rsi0 === undefined
                          ? "- "
                          : this.state.RSI.rsi0) +
                          "(" +
                          taobj.option.option.atr[0].period +
                          ") "}
                      </span>
                    )}
                    {taobj.option.option.atr[1].enabled && (
                      <span
                        style={{
                          color: "#" + taobj.option.option.atr[1].color,
                        }}
                      >
                        {(this.state.RSI.rsi1 === undefined
                          ? "- "
                          : this.state.RSI.rsi1) +
                          "(" +
                          taobj.option.option.atr[1].period +
                          ") "}
                      </span>
                    )}
                  </span>
                );
              break;
            case "Margin":
              obj = (
                <span>
                  <span style={{ color: "#" + taobj.option.option.buyColor }}>
                    {this.state[taobj.option.ta] + "()"}
                  </span>
                  <span style={{ color: "#" + taobj.option.option.saleColor }}>
                    {this.state[taobj.option.ta] + "()"}
                  </span>
                </span>
              );
              break;
            case "Trix":
              if (this.state.Trix !== undefined)
                obj = (
                  <span>
                    <span style={{ color: "#" + taobj.option.option.p1Color }}>
                      {(this.state.Trix.trix === undefined
                        ? "- "
                        : this.state.Trix.trix) + "(Trix) "}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.p2Color }}>
                      {(this.state.Trix.s === undefined
                        ? "- "
                        : this.state.Trix.s) + `(${t("chart5.signal")})`}
                    </span>
                  </span>
                );
              break;
            case "VE":
              //console.log(this.state.VE)
              if (this.state.VE !== undefined)
                obj = (
                  <span>
                    <span style={{ color: "#" + taobj.option.option.volume }}>
                      {(this.state.VE.volume === undefined
                        ? "- "
                        : this.state.VE.volume) + ` (${t("chart5.vol")}) `}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.color }}>
                      {(this.state.VE.up === undefined
                        ? "- "
                        : this.state.VE.up) + ` (${t("chart5.upperBand")}) `}
                    </span>
                    <span style={{ color: "#" + taobj.option.option.color2 }}>
                      {(this.state.VE.lo === undefined
                        ? "- "
                        : this.state.VE.lo) + `(${t("chart5.lowerBand")}) `}
                    </span>
                  </span>
                );
              break;
            case "MFI":
            case "ACD":
            case "RMI":
            case "ALF":
            case "Bias":
            case "ADXR":
            case "VC":
            case "ATR":
            case "SD":
            case "VltyCC":
            case "RCI":
            case "CO":
            case "PL":
            case "MI":
            case "CCI":
            case "UO":
            case "DPO":
            case "WAD":
            case "FI":
            case "VR":
            case "MOM":
            case "WR":
            case "PCV":
            case "PROC":
            case "POSC":
            case "RC":
            case "CC":
            case "UI":
            case "VOSC":
            case "VROC":
            case "OBV":
            case "EOM":
            case "Volume":
              obj = (
                <span style={{ color: "#" + taobj.option.option.color }}>
                  {this.state[taobj.option.ta]}
                </span>
              );
              break;
          }

          list.push(
            <div
              key={i}
              style={{
                color: "#" + this.state.toolTipColor,
                fontWeight: "bold",
                position: "absolute",
                top:
                  top +
                  (this.state.isOpen && this.getScrollPaneHeight() === 50
                    ? 50
                    : 0) +
                  "px",
                //right: this.state.periodOption === "" ? 60 : 40,
              }}
              className="Chart5-MainTooltip"
            >
              {taobj.option.name} {obj}
            </div>
          );
        });
        return list;
      }
    }
  }

  renderOverLayTooltips() {
    let list = [];
    let top = 0;
    this.props.overlay.forEach((item, i) => {
      top +=
        (this.props.isMobile || this.props.isTablet) && !this.props.isLandscape
          ?
          this.props.isMobile ? 0
            : 50
          : 20;
      let obj;

      switch (item.ta) {
        case "ICH":
          if (this.state.ICH !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option.options[0].color }}>
                  {item.option.options[0].enabled
                    ? this.state.ICH.convLine === undefined
                      ? ""
                      : this.state.ICH.convLine +
                      `(${t("chart5.turningLine")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.options[1].color }}>
                  {item.option.options[1].enabled
                    ? this.state.ICH.baseLine === undefined
                      ? ""
                      : this.state.ICH.baseLine +
                      `(${t("chart5.referenceLine")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.options[2].color }}>
                  {item.option.options[2].enabled
                    ? this.state.ICH.leadSpanB === undefined
                      ? ""
                      : this.state.ICH.leadSpanB +
                      `(${t("chart5.leading")}${t("chart5.span")}1) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.options[3].color }}>
                  {item.option.options[3].enabled
                    ? this.state.ICH.leadSpanA === undefined
                      ? ""
                      : this.state.ICH.leadSpanA +
                      `(${t("chart5.leading")}${t("chart5.span")}2) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.options[4].color }}>
                  {item.option.options[4].enabled
                    ? this.state.ICH.lagSpan === undefined
                      ? ""
                      : this.state.ICH.lagSpan +
                      `(${t("chart5.lagging")}${t("chart5.span")})`
                    : ""}
                </span>
              </span>
            );
          break;
        case "SMA":
          if (this.state.SMA !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option[0].color }}>
                  {item.option[0].enabled
                    ? this.state.SMA.sma0 === undefined
                      ? ""
                      : this.state.SMA.sma0 + "(" + item.option[0].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[1].color }}>
                  {item.option[1].enabled
                    ? this.state.SMA.sma1 === undefined
                      ? ""
                      : this.state.SMA.sma1 + "(" + item.option[1].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[2].color }}>
                  {item.option[2].enabled
                    ? this.state.SMA.sma2 === undefined
                      ? ""
                      : this.state.SMA.sma2 + "(" + item.option[2].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[3].color }}>
                  {item.option[3].enabled
                    ? this.state.SMA.sma3 === undefined
                      ? ""
                      : this.state.SMA.sma3 + "(" + item.option[3].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[4].color }}>
                  {item.option[4].enabled
                    ? this.state.SMA.sma4 === undefined
                      ? ""
                      : this.state.SMA.sma4 + "(" + item.option[4].period + ")"
                    : ""}
                </span>
              </span>
            );
          break;
        case "WMA":
          if (this.state.WMA !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option[0].color }}>
                  {item.option[0].enabled
                    ? this.state.WMA.wma0 === undefined
                      ? ""
                      : this.state.WMA.wma0 + "(" + item.option[0].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[1].color }}>
                  {item.option[1].enabled
                    ? this.state.WMA.wma1 === undefined
                      ? ""
                      : this.state.WMA.wma1 + "(" + item.option[1].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[2].color }}>
                  {item.option[2].enabled
                    ? this.state.WMA.wma2 === undefined
                      ? ""
                      : this.state.WMA.wma2 + "(" + item.option[2].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[3].color }}>
                  {item.option[3].enabled
                    ? this.state.WMA.wma3 === undefined
                      ? ""
                      : this.state.WMA.wma3 + "(" + item.option[3].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[4].color }}>
                  {item.option[4].enabled
                    ? this.state.WMA.wma4 === undefined
                      ? ""
                      : this.state.WMA.wma4 + "(" + item.option[4].period + ")"
                    : ""}
                </span>
              </span>
            );
          break;
        case "EMA":
          if (this.state.EMA !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option[0].color }}>
                  {item.option[0].enabled
                    ? this.state.EMA.ema0 === undefined
                      ? ""
                      : this.state.EMA.ema0 + "(" + item.option[0].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[1].color }}>
                  {item.option[1].enabled
                    ? this.state.EMA.ema1 === undefined
                      ? ""
                      : this.state.EMA.ema1 + "(" + item.option[1].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[2].color }}>
                  {item.option[2].enabled
                    ? this.state.EMA.ema2 === undefined
                      ? ""
                      : this.state.EMA.ema2 + "(" + item.option[2].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[3].color }}>
                  {item.option[3].enabled
                    ? this.state.EMA.ema3 === undefined
                      ? ""
                      : this.state.EMA.ema3 + "(" + item.option[3].period + ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[4].color }}>
                  {item.option[4].enabled
                    ? this.state.EMA.ema4 === undefined
                      ? ""
                      : this.state.EMA.ema4 + "(" + item.option[4].period + ")"
                    : ""}
                </span>
              </span>
            );
          break;
        case "MEMA":
          if (this.state.MEMA !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option[0].color }}>
                  {item.option[0].enabled
                    ? this.state.MEMA.mema0 === undefined
                      ? ""
                      : this.state.MEMA.mema0 +
                      "(" +
                      item.option[0].period +
                      ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[1].color }}>
                  {item.option[1].enabled
                    ? this.state.MEMA.mema1 === undefined
                      ? ""
                      : this.state.MEMA.mema1 +
                      "(" +
                      item.option[1].period +
                      ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[2].color }}>
                  {item.option[2].enabled
                    ? this.state.MEMA.mema2 === undefined
                      ? ""
                      : this.state.MEMA.mema2 +
                      "(" +
                      item.option[2].period +
                      ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[3].color }}>
                  {item.option[3].enabled
                    ? this.state.MEMA.mema3 === undefined
                      ? ""
                      : this.state.MEMA.mema3 +
                      "(" +
                      item.option[3].period +
                      ") "
                    : ""}
                </span>
                <span style={{ color: "#" + item.option[4].color }}>
                  {item.option[4].enabled
                    ? this.state.MEMA.mema4 === undefined
                      ? ""
                      : this.state.MEMA.mema4 +
                      "(" +
                      item.option[4].period +
                      ")"
                    : ""}
                </span>
              </span>
            );
          break;
        case "BB":
          if (this.state.BB !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option.BB.color }}>
                  {item.option.BB.enabled
                    ? this.state.BB.bb0 === undefined
                      ? ""
                      : this.state.BB.bb0 + "(" + item.option.BB.period + ") "
                    : ""}
                </span>
                <span>
                  {item.option.deviation[0].enabled &&
                    item.option.deviation[0].value + "σ "}
                </span>
                <span style={{ color: "#" + item.option.deviation[0].color }}>
                  {item.option.deviation[0].enabled
                    ? this.state.BB.bb2 === undefined
                      ? ""
                      : this.state.BB.bb2 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.deviation[0].color2 }}>
                  {item.option.deviation[0].enabled
                    ? this.state.BB.bb1 === undefined
                      ? ""
                      : this.state.BB.bb1 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.deviation[1].enabled &&
                    item.option.deviation[1].value + "σ "}
                </span>
                <span style={{ color: "#" + item.option.deviation[1].color }}>
                  {item.option.deviation[1].enabled
                    ? this.state.BB.bb4 === undefined
                      ? ""
                      : this.state.BB.bb4 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.deviation[1].color2 }}>
                  {item.option.deviation[1].enabled
                    ? this.state.BB.bb3 === undefined
                      ? ""
                      : this.state.BB.bb3 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.deviation[2].enabled &&
                    item.option.deviation[2].value + "σ "}
                </span>
                <span style={{ color: "#" + item.option.deviation[2].color }}>
                  {item.option.deviation[2].enabled
                    ? this.state.BB.bb6 === undefined
                      ? ""
                      : this.state.BB.bb6 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.deviation[2].color2 }}>
                  {item.option.deviation[2].enabled
                    ? this.state.BB.bb5 === undefined
                      ? ""
                      : this.state.BB.bb5 + `(${t("chart5.down")}) `
                    : ""}
                </span>
              </span>
            );
          break;
        case "ENV":
          if (this.state.ENV !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option.mid.color }}>
                  {item.option.mid.enabled
                    ? this.state.ENV.env0 === undefined
                      ? ""
                      : this.state.ENV.env0 +
                      "(" +
                      item.option.mid.period +
                      ") "
                    : ""}
                </span>
                <span>
                  {item.option.factor[0].enabled &&
                    item.option.factor[0].p + "% "}
                </span>
                <span style={{ color: "#" + item.option.factor[0].color }}>
                  {item.option.factor[0].enabled
                    ? this.state.ENV.env2 === undefined
                      ? ""
                      : this.state.ENV.env2 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.factor[0].color2 }}>
                  {item.option.factor[0].enabled
                    ? this.state.ENV.env1 === undefined
                      ? ""
                      : this.state.ENV.env1 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.factor[1].enabled &&
                    item.option.factor[1].p + "% "}
                </span>
                <span style={{ color: "#" + item.option.factor[1].color }}>
                  {item.option.factor[1].enabled
                    ? this.state.ENV.env4 === undefined
                      ? ""
                      : this.state.ENV.env4 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.factor[1].color2 }}>
                  {item.option.factor[1].enabled
                    ? this.state.ENV.env3 === undefined
                      ? ""
                      : this.state.ENV.env3 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.factor[2].enabled &&
                    item.option.factor[2].p + "% "}
                </span>
                <span style={{ color: "#" + item.option.factor[2].color }}>
                  {item.option.factor[2].enabled
                    ? this.state.ENV.env6 === undefined
                      ? ""
                      : this.state.ENV.env6 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.factor[2].color2 }}>
                  {item.option.factor[2].enabled
                    ? this.state.ENV.env5 === undefined
                      ? ""
                      : this.state.ENV.env5 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.factor[3].enabled &&
                    item.option.factor[3].p + "% "}
                </span>
                <span style={{ color: "#" + item.option.factor[3].color }}>
                  {item.option.factor[3].enabled
                    ? this.state.ENV.env8 === undefined
                      ? ""
                      : this.state.ENV.env8 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.factor[3].color2 }}>
                  {item.option.factor[3].enabled
                    ? this.state.ENV.env7 === undefined
                      ? ""
                      : this.state.ENV.env7 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.factor[4].enabled &&
                    item.option.factor[4].p + "% "}
                </span>
                <span style={{ color: "#" + item.option.factor[4].color }}>
                  {item.option.factor[4].enabled
                    ? this.state.ENV.env10 === undefined
                      ? ""
                      : this.state.ENV.env10 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.factor[4].color2 }}>
                  {item.option.factor[4].enabled
                    ? this.state.ENV.env9 === undefined
                      ? ""
                      : this.state.ENV.env9 + `(${t("chart5.down")}) `
                    : ""}
                </span>
              </span>
            );
          break;
        case "KC":
          if (this.state.KC !== undefined)
            obj = (
              <span>
                <span style={{ color: "#" + item.option.mid.color }}>
                  {item.option.mid.enabled
                    ? this.state.KC.env0 === undefined
                      ? ""
                      : this.state.KC.env0 + "(" + item.option.mid.period + ") "
                    : ""}
                </span>
                <span>
                  {item.option.atr[0].enabled &&
                    "1xATR(" + item.option.atr[0].period + ") "}
                </span>
                <span style={{ color: "#" + item.option.atr[0].color }}>
                  {item.option.atr[0].enabled
                    ? this.state.KC.env2 === undefined
                      ? ""
                      : this.state.KC.env2 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.atr[0].color1 }}>
                  {item.option.atr[0].enabled
                    ? this.state.KC.env1 === undefined
                      ? ""
                      : this.state.KC.env1 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.atr[1].enabled &&
                    "2xATR(" + item.option.atr[1].period + ") "}
                </span>
                <span style={{ color: "#" + item.option.atr[1].color }}>
                  {item.option.atr[1].enabled
                    ? this.state.KC.env4 === undefined
                      ? ""
                      : this.state.KC.env4 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.atr[1].color1 }}>
                  {item.option.atr[1].enabled
                    ? this.state.KC.env3 === undefined
                      ? ""
                      : this.state.KC.env3 + `(${t("chart5.down")}) `
                    : ""}
                </span>
                <span>
                  {item.option.atr[2].enabled &&
                    "3xATR(" + item.option.atr[2].period + ") "}
                </span>
                <span style={{ color: "#" + item.option.atr[2].color }}>
                  {item.option.atr[2].enabled
                    ? this.state.KC.env6 === undefined
                      ? ""
                      : this.state.KC.env6 + `(${t("chart5.up")}) `
                    : ""}
                </span>
                <span style={{ color: "#" + item.option.atr[2].color1 }}>
                  {item.option.atr[2].enabled
                    ? this.state.KC.env5 === undefined
                      ? ""
                      : this.state.KC.env5 + `(${t("chart5.down")}) `
                    : ""}
                </span>
              </span>
            );
          break;
        case "WC":
        case "VWAP":
        case "TVMA":
        case "LRT":
        case "SAR":
          if (this.state[item.ta] !== undefined)
            obj = (
              <span style={{ color: "#" + item.option.color }}>
                {this.state[item.ta]}
              </span>
            );
          break;
      }

      list.push(
        <div
          key={i}
          style={{
            color: "#" + this.state.toolTipColor,
            fontWeight: "bold",
            top: top + "px",
            //right: this.state.periodOption === "" ? 60 : 40,
          }}
          className="Chart5-MainTooltip"
          onMouseMove={(evt) => {
            this.mainchartobj.mousemove(evt.pageX, evt.pageY);
          }}
        >
          {this.props.isMobile ? item.ta : item.name} {obj}
        </div>
      );
    });

    return list;
  }

  renderCompareTooltips = () => {
    let list = [];
    let compareList = this.compseriesList.filter(
      (v, i, a) =>
        a.findIndex((t) => t.item.compare.name === v.item.compare.name) === i
    );
    compareList.forEach((item) => {
      let obj = (
        <span style={{ color: "#" + item.item.option.color }}>
          {item.item.compare.name}{" "}
        </span>
      );
      list.push(obj);
    });

    return (
      <div
        className="compare-tooltips"
        style={{
          top: "60px",
          position: "absolute",
          marginLeft: "10px",
          left: "0px",
          width: "calc(100% - 86px)",
          //right: "61px",
          fontWeight: "bold",
        }}
        onMouseMove={(evt) => {
          this.mainchartobj.mousemove(evt.pageX, evt.pageY);
        }}
      >
        {list}
      </div>
    );
  };

  renderPopupMenu = () => {
    let { t } = this.props;

    if (this.state.showAnnotationPopupMenu && !this.props.isMobile) {
      return (
        <div>
          <div
            id="trendlinepopupmenu"
            className="edit_t"
            style={{ display: "block" }}
          >
            {this.state.popupMenuMode === "Normal" && (
              <div id="editform_t">
                <div className="menusubitem">
                  <div className="menusubitem stylebox ">
                    <div className="styleboxlbl">
                      {t("chart5.changeTrendLine")}
                    </div>
                    <button
                      id="msi-drawtool-params-color0"
                      className="colorpicker"
                      value={
                        // this.state.drawTool === "drawLine"
                        //   ? this.state.drawLineColor
                        //   : this.state.drawTool === "drawRetracement"
                        //     ? this.state.drawRetracementColor
                        //     : this.state.drawTool === "drawFan"
                        //       ? this.state.drawFanColor
                        //       : this.state.drawArcsColor
                        this.state.annotationColor
                      }
                      style={
                        // this.state.drawTool === "drawLine"
                        //   ? { background: "#" + this.state.drawLineColor }
                        //   : this.state.drawTool === "drawRetracement"
                        //     ? {
                        //       background: "#" + this.state.drawRetracementColor,
                        //     }
                        //     : this.state.drawTool === "drawFan"
                        //       ? { background: "#" + this.state.drawFanColor }
                        // :
                        { background: "#" + this.state.annotationColor }
                      }
                      onClick={() =>
                        this.onColorPickerOpen(
                          this.state.drawTool === "drawLine"
                            ? this.state.drawLineColor
                            : this.state.drawTool === "drawRetracement"
                              ? this.state.drawRetracementColor
                              : this.state.drawTool === "drawFan"
                                ? this.state.drawFanColor
                                : this.state.drawArcsColor
                        )
                      }
                    ></button>

                    <div className="inputbox">
                      <input
                        id="msi-drawtool-params-thickness"
                        type="number"
                        step="1"
                        min="1"
                        max="9"
                        value={
                          // this.state.drawTool === "drawLine"
                          //   ? this.state.drawLineThickness
                          //   : this.state.drawTool === "drawRetracement"
                          //     ? this.state.drawRetracementThickness
                          //     : this.state.drawTool === "drawFan"
                          //       ? this.state.drawFanThickness
                          //       : this.state.drawArcsThickness
                          this.state.annotationThickness
                        }
                        maxLength={1}
                        style={{ width: "30px" }}
                        onChange={(e) => {
                          this.onDrawToolThicknessChange(e);
                        }}
                      />
                    </div>

                    <div className="selectbox">
                      <select
                        id="msi-drawtool-params-style"
                        value={
                          this.state.drawTool === "drawLine"
                            ? this.state.drawLineStyle
                            : this.state.drawTool === "drawRetracement"
                              ? this.state.drawRetracementStyle
                              : this.state.drawTool === "drawFan"
                                ? this.state.drawFanStyle
                                : this.state.drawArcstyle
                        }
                        onChange={(e) => {
                          this.onDrawToolStyleChange(e);
                        }}
                      >
                        <option value="s">{t("chart5.realLine")}</option>
                        <option value="d">{t("chart5.dotLine")}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="actionbtngrp">
                  <button
                    className="deletebutton"
                    onClick={() => this.onDeleteClick()}
                  >
                    {t("chart5.remove")}
                  </button>
                  {this.props.drawTool === "drawLine" && (
                    <button
                      className="copybutton"
                      style={{ display: "block" }}
                      onClick={() => this.onCopyClick()}
                    >
                      {t("chart5.copy")}
                    </button>
                  )}
                  <div className="btnsep"></div>
                  <button
                    className="donebutton"
                    style={{ width: "105px" }}
                    onClick={() => {
                      this.onDoneClick();
                      this.props.drawToolChange();
                    }}
                  >
                    {t("chart5.confirm")}
                  </button>
                </div>
              </div>
            )}
            {this.state.popupMenuMode === "Exit" && (
              <div
                id="exitform"
                className="actionbtngrp"
                onClick={() => {
                  this.onDoneClick();
                  this.props.drawToolChange();
                }}
              >
                <button className="donebutton">{t("chart5.exitInput")}</button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  onColorPickerOpen = (color) => {
    //console.log(color);
    // if (this.state.drawTool === "drawLine") {
    //     this.setState({ source: 'drawLine' })
    //     this.props.colorPickerOpen(color, "drawLine")
    // } else if (this.state.drawTool === "drawRetracement") {
    //     this.setState({ source: 'drawRetracement' })
    //     this.props.colorPickerOpen(color, "drawRetracement")
    // } else if (this.state.drawTool === "drawFan") {
    //     this.setState({ source: 'drawFan' })
    //     this.props.colorPickerOpen(color, "drawFan")
    // } else if (this.state.drawTool === "drawArcs") {
    //     this.setState({ source: 'drawArcs' })
    //     this.props.colorPickerOpen(color, "drawArcs")
    // }
    this.setState({ isChangeByColorPicker: true });
    this.props.setColorSource(this.state.drawTool);
    this.props.colorPickerOpen(color, this.state.drawTool, "トレンドライン");
  };

  onDrawToolStyleChange = (e) => {
    if (this.state.drawTool === "drawLine") {
      this.setState({ drawLineStyle: e.target.value });
    } else if (this.state.drawTool === "drawRetracement") {
      this.setState({ drawRetracementStyle: e.target.value });
    } else if (this.state.drawTool === "drawFan") {
      this.setState({ drawFanStyle: e.target.value });
    } else if (this.state.drawTool === "drawArcs") {
      this.setState({ drawArcsStyle: e.target.value });
    }

    this.currentTrendLineObj.dash =
      e.target.value === "d" ? e.target.value : "";
    this.mainchartobj.refreshAnnotation();
  };

  onDrawToolThicknessChange = (e) => {
    //console.log("changeThinkness");
    if (this.state.drawTool === "drawLine") {
      this.setState({ drawLineThickness: e.target.value });
    } else if (this.state.drawTool === "drawRetracement") {
      this.setState({ drawRetracementThickness: e.target.value });
    } else if (this.state.drawTool === "drawFan") {
      this.setState({ drawFanThickness: e.target.value });
    } else if (this.state.drawTool === "drawArcs") {
      this.setState({ drawArcsThickness: e.target.value });
    }

    this.setState({ annotationThickness: e.target.value });

    this.currentTrendLineObj.thickness = e.target.value;
    this.mainchartobj.refreshAnnotation();
  };

  onDeleteClick = () => {
    if (this.currentTrendLineObj) {
      this.mainchartobj.deleteAnnotation();
    }
  };

  onCopyClick = () => {
    if (this.currentTrendLineObj) {
      this.mainchartobj.copyAnnotation();
    }
  };

  onDoneClick = () => {
    this.setState({ showCHTooltips: true });
    this.mainchartobj.selectAnnotation(-1, -1);
    this.setState({ showAnnotationPopupMenu: false });
  };

  renderCHToolTips = () => {
    const { t } = this.props;
    let overlayList = [];
    let taList = [];
    //console.log(this.props.overlay)
    this.props.overlay.forEach((item, i) => {
      let obj;

      switch (item.ta) {
        case "ICH":
          if (this.state.ICH !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option.options[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.options[0].enabled
                    ? this.state.ICH.convLine === undefined
                      ? ""
                      : this.formatNumber(this.state.ICH.convLine) +
                      `(${t("chart5.turningLine")}) `
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.options[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.options[1].enabled
                    ? this.state.ICH.baseLine === undefined
                      ? ""
                      : this.formatNumber(this.state.ICH.baseLine) +
                      `(${t("chart5.referenceLine")}) `
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.options[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.options[2].enabled
                    ? this.state.ICH.leadSpanA === undefined
                      ? ""
                      : this.formatNumber(this.state.ICH.leadSpanA) +
                      "(先行スパン1) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.options[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.options[3].enabled
                    ? this.state.ICH.leadSpanB === undefined
                      ? ""
                      : this.formatNumber(this.state.ICH.leadSpanB) +
                      "(先行スパン2) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.options[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.options[4].enabled
                    ? this.state.ICH.lagSpan === undefined
                      ? ""
                      : this.formatNumber(this.state.ICH.lagSpan) +
                      "(遅行スパン)"
                    : ""}
                </div>
              </div>
            );
          break;
        case "SMA":
          if (this.state.SMA !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[0].enabled
                    ? this.state.SMA.sma0 === undefined
                      ? ""
                      : this.state.SMA.sma0 + "(" + item.option[0].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[1].enabled
                    ? this.state.SMA.sma1 === undefined
                      ? ""
                      : this.state.SMA.sma1 + "(" + item.option[1].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[2].enabled
                    ? this.state.SMA.sma2 === undefined
                      ? ""
                      : this.state.SMA.sma2 + "(" + item.option[2].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[3].enabled
                    ? this.state.SMA.sma3 === undefined
                      ? ""
                      : this.state.SMA.sma3 + "(" + item.option[3].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[4].enabled
                    ? this.state.SMA.sma4 === undefined
                      ? ""
                      : this.state.SMA.sma4 + "(" + item.option[4].period + ")"
                    : ""}
                </div>
              </div>
            );
          break;
        case "WMA":
          if (this.state.WMA !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[0].enabled
                    ? this.state.WMA.wma0 === undefined
                      ? ""
                      : this.state.WMA.wma0 + "(" + item.option[0].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[1].enabled
                    ? this.state.WMA.wma1 === undefined
                      ? ""
                      : this.state.WMA.wma1 + "(" + item.option[1].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[2].enabled
                    ? this.state.WMA.wma2 === undefined
                      ? ""
                      : this.state.WMA.wma2 + "(" + item.option[2].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[3].enabled
                    ? this.state.WMA.wma3 === undefined
                      ? ""
                      : this.state.WMA.wma3 + "(" + item.option[3].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[4].enabled
                    ? this.state.WMA.wma4 === undefined
                      ? ""
                      : this.state.WMA.wma4 + "(" + item.option[4].period + ")"
                    : ""}
                </div>
              </div>
            );
          break;
        case "EMA":
          if (this.state.EMA !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[0].enabled
                    ? this.state.EMA.ema0 === undefined
                      ? ""
                      : this.state.EMA.ema0 + "(" + item.option[0].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[1].enabled
                    ? this.state.EMA.ema1 === undefined
                      ? ""
                      : this.state.EMA.ema1 + "(" + item.option[1].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[2].enabled
                    ? this.state.EMA.ema2 === undefined
                      ? ""
                      : this.state.EMA.ema2 + "(" + item.option[2].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[3].enabled
                    ? this.state.EMA.ema3 === undefined
                      ? ""
                      : this.state.EMA.ema3 + "(" + item.option[3].period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[4].enabled
                    ? this.state.EMA.ema4 === undefined
                      ? ""
                      : this.state.EMA.ema4 + "(" + item.option[4].period + ")"
                    : ""}
                </div>
              </div>
            );
          break;
        case "MEMA":
          if (this.state.MEMA !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[0].enabled
                    ? this.state.MEMA.mema0 === undefined
                      ? ""
                      : this.state.MEMA.mema0 +
                      "(" +
                      item.option[0].period +
                      ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[1].enabled
                    ? this.state.MEMA.mema1 === undefined
                      ? ""
                      : this.state.MEMA.mema1 +
                      "(" +
                      item.option[1].period +
                      ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[2].enabled
                    ? this.state.MEMA.mema2 === undefined
                      ? ""
                      : this.state.MEMA.mema2 +
                      "(" +
                      item.option[2].period +
                      ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[3].enabled
                    ? this.state.MEMA.mema3 === undefined
                      ? ""
                      : this.state.MEMA.mema3 +
                      "(" +
                      item.option[3].period +
                      ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option[4].enabled
                    ? this.state.MEMA.mema4 === undefined
                      ? ""
                      : this.state.MEMA.mema4 +
                      "(" +
                      item.option[4].period +
                      ")"
                    : ""}
                </div>
              </div>
            );
          break;
        case "BB":
          if (this.state.BB !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option.BB.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.BB.enabled
                    ? this.state.BB.bb0 === undefined
                      ? ""
                      : this.state.BB.bb0 + "(" + item.option.BB.period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[0].enabled
                    ? this.state.BB.bb1 === undefined
                      ? ""
                      : this.state.BB.bb1 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[0].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[0].enabled
                    ? this.state.BB.bb2 === undefined
                      ? ""
                      : this.state.BB.bb2 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[1].enabled
                    ? this.state.BB.bb3 === undefined
                      ? ""
                      : this.state.BB.bb3 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[1].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[1].enabled
                    ? this.state.BB.bb4 === undefined
                      ? ""
                      : this.state.BB.bb4 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[2].enabled
                    ? this.state.BB.bb5 === undefined
                      ? ""
                      : this.state.BB.bb5 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.deviation[2].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.deviation[2].enabled
                    ? this.state.BB.bb6 === undefined
                      ? ""
                      : this.state.BB.bb6 + "(下) "
                    : ""}
                </div>
              </div>
            );
          break;
        case "ENV":
          if (this.state.ENV !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option.mid.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.mid.enabled
                    ? this.state.ENV.env0 === undefined
                      ? ""
                      : this.state.ENV.env0 +
                      "(" +
                      item.option.mid.period +
                      ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[0].enabled
                    ? this.state.ENV.env1 === undefined
                      ? ""
                      : this.state.ENV.env1 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[0].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[0].enabled
                    ? this.state.ENV.env2 === undefined
                      ? ""
                      : this.state.ENV.env2 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[1].enabled
                    ? this.state.ENV.env3 === undefined
                      ? ""
                      : this.state.ENV.env3 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[1].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[1].enabled
                    ? this.state.ENV.env4 === undefined
                      ? ""
                      : this.state.ENV.env4 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[2].enabled
                    ? this.state.ENV.env5 === undefined
                      ? ""
                      : this.state.ENV.env5 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[2].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[2].enabled
                    ? this.state.ENV.env6 === undefined
                      ? ""
                      : this.state.ENV.env6 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[3].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[3].enabled
                    ? this.state.ENV.env7 === undefined
                      ? ""
                      : this.state.ENV.env7 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[3].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[3].enabled
                    ? this.state.ENV.env8 === undefined
                      ? ""
                      : this.state.ENV.env8 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[4].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[4].enabled
                    ? this.state.ENV.env9 === undefined
                      ? ""
                      : this.state.ENV.env9 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.factor[4].color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.factor[4].enabled
                    ? this.state.ENV.env10 === undefined
                      ? ""
                      : this.state.ENV.env10 + "(下) "
                    : ""}
                </div>
              </div>
            );
          break;
        case "KC":
          if (this.state.KC !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + item.option.mid.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.mid.enabled
                    ? this.state.KC.env0 === undefined
                      ? ""
                      : this.state.KC.env0 + "(" + item.option.mid.period + ") "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[0].enabled
                    ? this.state.KC.env1 === undefined
                      ? ""
                      : this.state.KC.env1 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[0].color1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[0].enabled
                    ? this.state.KC.env2 === undefined
                      ? ""
                      : this.state.KC.env2 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[1].enabled
                    ? this.state.KC.env3 === undefined
                      ? ""
                      : this.state.KC.env3 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[1].color1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[1].enabled
                    ? this.state.KC.env4 === undefined
                      ? ""
                      : this.state.KC.env4 + "(下) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[2].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[2].enabled
                    ? this.state.KC.env5 === undefined
                      ? ""
                      : this.state.KC.env5 + "(上) "
                    : ""}
                </div>
                <div
                  style={{
                    color: "#" + item.option.atr[2].color1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.option.atr[2].enabled
                    ? this.state.KC.env6 === undefined
                      ? ""
                      : this.state.KC.env6 + "(下) "
                    : ""}
                </div>
              </div>
            );
          break;
        case "WC":
        case "VWAP":
        case "TVMA":
        case "LRT":
        case "SAR":
          if (this.state[item.ta] !== undefined)
            obj = (
              <div
                style={{
                  color: "#" + item.option.color,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {this.state[item.ta]}
              </div>
            );
          break;
      }

      overlayList.push(
        <div
          key={i}
          style={{
            color: "#" + this.state.toolTipColor,
            fontWeight: "bold",
            borderTop: "1px solid #DDDDDD",
          }}
        >
          {item.name} {item.value}
          {obj}
        </div>
      );
    });

    this.tachartobjList.forEach((e, i) => {
      let idx = this.tachartobjList.length - 1 - i;
      let taobj = this.tachartobjList[idx];
      let obj;
      switch (taobj.option.ta) {
        case "FKD":
          if (this.state.FKD !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {"%K" +
                    (this.state.FKD.pctK === undefined
                      ? "- "
                      : this.state.FKD.pctK) +
                    " "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {"%D" +
                    (this.state.FKD.pctD === undefined
                      ? "- "
                      : this.state.FKD.pctD)}
                </div>
              </div>
            );
          break;
        case "SKD":
          if (this.state.SKD !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {"%K" +
                    (this.state.SKD.pctK === undefined
                      ? "- "
                      : this.state.SKD.pctK) +
                    " "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {"%D" +
                    (this.state.SKD.pctD === undefined
                      ? "- "
                      : this.state.SKD.pctD)}
                </div>
              </div>
            );
          break;
        case "DMI":
          if (this.state.DMI !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.DMI.p === undefined ? "- " : this.state.DMI.p) +
                    "(+DI) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.DMI.m === undefined ? "- " : this.state.DMI.m) +
                    "(-DI) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.ADXColor,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.DMI.adx === undefined
                    ? "- "
                    : this.state.DMI.adx) + "(ADX)"}
                </div>
              </div>
            );
          break;
        case "MACD":
          if (this.state.MACD !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.MACD.macd === undefined
                    ? "- "
                    : this.state.MACD.macd) + "(MACD) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.MACD.histogram === undefined
                    ? "- "
                    : this.state.MACD.histogram) + "(シグナル) "}
                </div>
                <div>
                  {(this.state.MACD.signal === undefined
                    ? "- "
                    : this.state.MACD.signal) + "(シグナル)"}
                </div>
              </div>
            );
          break;
        case "KRI":
          if (this.state.KRI !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.options[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.KRI.kri0 === undefined
                    ? "- "
                    : this.state.KRI.kri0) +
                    "(" +
                    taobj.option.option.options[0].period +
                    ") "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.options[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.KRI.kri1 === undefined
                    ? "- "
                    : this.state.KRI.kri1) +
                    "(" +
                    taobj.option.option.options[1].period +
                    ") "}
                </div>
              </div>
            );
          break;
        case "UPDW":
          if (this.state.UPDW !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.options[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.UPDW.updw0 === undefined
                    ? "- "
                    : this.state.UPDW.updw0) +
                    "(" +
                    taobj.option.option.options[0].period +
                    ") "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.options[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.UPDW.updw1 === undefined
                    ? "- "
                    : this.state.UPDW.updw1) +
                    "(" +
                    taobj.option.option.options[1].period +
                    ") "}
                </div>
              </div>
            );
          break;
        case "Vector":
          if (this.state.Vector !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.p1Color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.Vector.v === undefined
                    ? "- "
                    : this.state.Vector.v) + "(ベクトル) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.p2Color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.Vector.s === undefined
                    ? "- "
                    : this.state.Vector.s) + " (シグナル)"}
                </div>
              </div>
            );
          break;
        case "NVI":
          if (this.state.NVI !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.atr[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.NVI.nvi0.s === undefined
                    ? "- "
                    : this.state.NVI.nvi0.s) +
                    "(" +
                    taobj.option.option.atr[0].period +
                    ") "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.atr[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.NVI.nvi1.cnvi === undefined
                    ? "- "
                    : this.state.NVI.nvi1.cnvi) +
                    "(" +
                    taobj.option.option.atr[1].period +
                    ") "}
                </div>
              </div>
            );
          break;
        case "RSI":
          if (this.state.RSI !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.atr[0].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.RSI.rsi0 === undefined
                    ? "- "
                    : this.state.RSI.rsi0) +
                    "(" +
                    taobj.option.option.atr[0].period +
                    ") "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.atr[1].color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.RSI.rsi1 === undefined
                    ? "- "
                    : this.state.RSI.rsi1) +
                    "(" +
                    taobj.option.option.atr[1].period +
                    ") "}
                </div>
              </div>
            );
          break;
        case "Margin":
          obj = (
            <div>
              <div
                style={{
                  color: "#" + taobj.option.option.buyColor,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {this.state[taobj.option.ta] + "()"}
              </div>
              <div
                style={{
                  color: "#" + taobj.option.option.saleColor,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {this.state[taobj.option.ta] + "()"}
              </div>
            </div>
          );
          break;
        case "Trix":
          if (this.state.Trix !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.p1Color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.Trix.trix === undefined
                    ? "- "
                    : this.state.Trix.trix) + "(Trix) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.p2Color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.Trix.s === undefined
                    ? "- "
                    : this.state.Trix.s) + "(シグナル)"}
                </div>
              </div>
            );
          break;
        case "VE":
          if (this.state.VE !== undefined)
            obj = (
              <div>
                <div
                  style={{
                    color: "#" + taobj.option.option.volume,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.VE.volume === undefined
                    ? "- "
                    : this.state.VE.volume) + "(出来高) "}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.VE.up === undefined ? "- " : this.state.VE.up) +
                    "(上部バンド)"}
                </div>
                <div
                  style={{
                    color: "#" + taobj.option.option.color2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(this.state.VE.lo === undefined ? "- " : this.state.VE.lo) +
                    "(下部バンド)"}
                </div>
              </div>
            );
          break;
        case "MFI":
        case "ACD":
        case "RMI":
        case "ALF":
        case "Bias":
        case "ADXR":
        case "VC":
        case "ATR":
        case "SD":
        case "VltyCC":
        case "RCI":
        case "CO":
        case "PL":
        case "MI":
        case "CCI":
        case "UO":
        case "DPO":
        case "WAD":
        case "FI":
        case "VR":
        case "MOM":
        case "WR":
        case "PCV":
        case "PROC":
        case "POSC":
        case "RC":
        case "CC":
        case "UI":
        case "VOSC":
        case "VROC":
        case "OBV":
        case "EOM":
        case "Volume":
          obj = (
            <div
              style={{ color: "#" + taobj.option.option.color, float: "right" }}
            >
              {this.state[taobj.option.ta]}
            </div>
          );
          break;
      }

      taList.push(
        <div
          key={i}
          style={{
            color: "#" + this.state.toolTipColor,
            fontWeight: "bold",
            borderTop: "1px solid #DDDDDD",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {taobj.option.name +
            " " +
            (taobj.option.value !== undefined ? taobj.option.value : "")}
          {obj}
        </div>
      );
    });

    let top = 10;
    if (this.props.overlay.length > 1) {
      top = 20;
    } else {
      top = this.state.y;
    }

    let left = this.state.x;
    if (this.container && this.container.current) {
      if (this.state.x + 250 > this.container.current.clientWidth) {
        left = this.container.current.clientWidth - 250;
      }
    }

    return (
      <div
        id="chtip"
        style={{ top: top, left: left, backgroundColor: "#000000" }}
      >
        <div id="mainchart">
          <div id="date">
            <div className="chtiplbl">{t("chart5.date")}</div>
            <div className="chtipval chval">{this.state.date}</div>
          </div>
          <div id="open">
            <div className="chtiplbl">{t("chart5.start")}</div>
            <div className="chtipval chval">
              {this.formatNumber(this.state.open, "price")}
            </div>
          </div>
          <div id="high">
            <div className="chtiplbl">{t("chart5.high")}</div>
            <div className="chtipval chval">
              {this.formatNumber(this.state.high, "price")}
            </div>
          </div>
          <div id="low">
            <div className="chtiplbl">{t("chart5.low")}</div>
            <div className="chtipval chval">
              {this.formatNumber(this.state.low, "price")}
            </div>
          </div>
          <div id="close">
            <div className="chtiplbl">{t("chart5.close")}</div>
            <div className="chtipval chval">
              {this.formatNumber(this.state.close, "price")}
            </div>
          </div>
        </div>
        <div
        // onMouseMove={(evt) => {
        //   this.mainchartobj.mousemove(evt.pageX, evt.pageY);
        // }}
        >
          {overlayList}
        </div>

        {taList.reverse()}
      </div>
    );
  };

  zoomIn = () => {
    const endIndex = this.mainchartobj.axisX.endIndex - 20
    const newIndex = this.mainchartobj.axisX.startIndex + 20

    if (newIndex < endIndex) {
      this.mainchartobj.viewZoomX(this.mainchartobj.axisX.startIndex += 20, this.mainchartobj.axisX.endIndex)
      this.scrollcharobj.viewZoomX(this.scrollcharobj.axisX.startIndex += 20, this.scrollcharobj.axisX.endIndex)
    }

    this.updateChart(this.nextProps.current)
  }

  zoomOut = () => {
    if (this.mainchartobj.axisX.startIndex - 20 > 0) {
      this.mainchartobj.viewZoomX(this.mainchartobj.axisX.startIndex -= 20, this.mainchartobj.axisX.endIndex)
      this.scrollcharobj.viewZoomX(this.scrollcharobj.axisX.startIndex -= 20, this.scrollcharobj.axisX.endIndex)
    } else {
      this.mainchartobj.viewZoomX(this.mainchartobj.axisX.startIndex -= this.mainchartobj.axisX.startIndex - 1, this.mainchartobj.axisX.endIndex)
      this.scrollcharobj.viewZoomX(this.scrollcharobj.axisX.startIndex -= this.scrollcharobj.axisX.startIndex - 1, this.scrollcharobj.axisX.endIndex)
    }

    this.updateChart(this.nextProps.current)
  }

  render() {
    const { t } = this.props;

    return (
      <div className="Chart5" ref={this.container}>
        <div
          className="chartpane"
          ref={this.chartpane}
          style={{
            backgroundColor: "#" + this.state.backgroundColor,
            color: "#" + this.state.toolTipColor,
            height: "100%",
          }}
        >
          {(!this.state.haveChart ||
            (this.state.showCompare && !this.state.haveCompareChart)) && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("chart5.notAvailable")}
              </div>
            )}
          <div
            className="canvasbox"
            id="canvasbox"
            ref={this.canvasbox}
            style={{
              height:
                this.state.haveChart &&
                  (!this.state.showCompare ||
                    (this.state.showCompare && this.state.haveCompareChart))
                  ? "auto"
                  : 0,
              overflow: "hidden",
            }}
            onMouseMove={(e) => {
              if (this.state.drawTool !== "move") return;
              this.setState({ showCHTooltips: false });
              const timer = setTimeout(() => {
                this.setState({ showCHTooltips: true });
              }, 600);
              return () => clearTimeout(timer);
            }}
          >
            {this.props.showToolTips &&
              this.state.showCHTooltips &&
              !this.state.showCompare &&
              this.renderCHToolTips()}
            <div
              className="Chart5-MainTooltip"
              style={
                (this.props.isMobile || this.props.isTablet) &&
                  !this.props.isLandscape
                  ? {
                    color: "#" + this.state.toolTipColor,
                    display: "block",
                    //right: 64,
                  }
                  : { color: "#" + this.state.toolTipColor, display: "flex" }
              }
              onMouseMove={(evt) => {
                this.mainchartobj.mousemove(evt.pageX, evt.pageY);
              }}
            >
              {this.renderTooltipHeader()}
              <div
                style={
                  (this.props.isMobile || this.props.isTablet) &&
                    !this.props.isLandscape
                    ? { position: "relative" }
                    : { marginLeft: "10px" }
                }
              >
                {/* {console.log(this.state.mainTooltip)} */}
                {this.state.mainTooltip}
              </div>
              {this.props.showCompare && (
                <div
                  style={
                    this.props.isMobile && !this.props.isLandscape
                      ? {
                        position: "absolute",
                        right: "-15px",
                        top: "-2px",
                        color: "#999999",
                      }
                      : {
                        position: "absolute",
                        right: "-10px",
                        color: "#999999",
                      }
                  }
                >
                  %
                </div>
              )}
            </div>
            {!this.props.showCompare &&
              this.props.showTa &&
              this.renderOverLayTooltips()}
            {this.renderCompareTooltips()}
            {this.renderTooltips()}
            {this.renderPopupMenu()}
            {this.renderEventBox()}
          </div>
        </div>
        {this.props.enableScrollPane && (
          <div>
            <div
              className={"scrollpane"}
              style={{
                display: this.state.isOpen ? undefined : "none",
                overflow: this.state.isOpen ? "hidden" : undefined,
                height:
                  this.state.haveChart &&
                    (!this.state.showCompare ||
                      (this.state.showCompare && this.state.haveCompareChart))
                    ? "auto"
                    : 0,

                backgroundColor: "#" + this.state.backgroundColor,
                // backgroundColor: "red",
              }}
              ref={this.scrollpane}
            >
              <div
                className="scrollpane-ScrollTooltip"
                style={{ color: "#" + this.state.toolTipColor }}
              >
                {this.state.scrollpaneTooltip}
              </div>
              <div className="scrollcanvasbox" ref={"scrollcanvasbox"}></div>
            </div>
            <div className="openpanehandleContainer">
              <div
                className="openpanehandle zommOut"
                onClick={this.zoomOut}
                onTouchStart={this.zoomOut}
                style={
                  this.state.isOpen
                    ? { top: "-40px" }
                    : { top: "-40px", transform: "rotate(-180deg)" }
                }
              ></div>
              <div
                className="openpanehandle down"
                onClick={this.onPanelClick}
                onTouchStart={this.onPanelClick}
                style={
                  this.state.isOpen
                    ? { top: "-40px" }
                    : { top: "-40px", transform: "rotate(-180deg)" }
                }
              ></div>
              <div
                className="openpanehandle zommIn"
                onClick={this.zoomIn}
                onTouchStart={this.zoomIn}
                style={
                  this.state.isOpen
                    ? { top: "-40px" }
                    : { top: "-40px", transform: "rotate(-180deg)" }
                }
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
