import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const Trix = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [p1, setP1] = useState(15);
  const [p1Color, setP1Color] = useState("EE3311");
  const [p1Thickness, setP1Thickness] = useState(2);
  const [p1Style, setP1Style] = useState("s");
  const [p1ShowTracker, setP1ShowTracker] = useState(true);

  const [p2, setP2] = useState(9);
  const [p2Color, setP2Color] = useState("0080FF");
  const [p2Thickness, setP2Thickness] = useState(2);
  const [p2Style, setP2Style] = useState("s");
  const [p2ShowTracker, setP2ShowTracker] = useState(true);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(p1 + "," + p2);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.Trix_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const Trix = localChartSetting.option;

        setValue(Trix.p1 + "," + Trix.p2);
        props.onTAValueChange({
          ta: "Trix",
          name: `${t("chart5.TA.Trix")} (Trix)`,
          option: {
            p1: Trix.p1,
            p1Color: Trix.p1Color,
            p1Thickness: Trix.p1Thickness,
            p1Style: Trix.p1Style,
            p1ShowTracker: Trix.p1ShowTracker,
            p2: Trix.p2,
            p2Color: Trix.p2Color,
            p2Thickness: Trix.p2Thickness,
            p2Style: Trix.p2Style,
            p2ShowTracker: Trix.p2ShowTracker,
            height: Trix.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const Trix = props.localChartSetting.Trix_Option.option;

    setP1(parseInt(Trix.p1) || p1);
    setP1Color(Trix.p1Color);
    setP1Thickness(parseInt(Trix.p1Thickness) || p1Thickness);
    setP1Style(Trix.p1Style);
    setP1ShowTracker(Trix.p1ShowTracker);
    setP2(parseInt(Trix.p2) || p2);
    setP2Color(Trix.p2Color);
    setP2Thickness(parseInt(Trix.p2Thickness) || p2Thickness);
    setP2Style(Trix.p2Style);
    setP2ShowTracker(Trix.p2ShowTracker);
    setHeight(Trix.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(p1 + "," + p2);
    props.onTAValueChange({
      ta: "Trix",
      name: `${t("chart5.TA.Trix")} (Trix)`,
      option: {
        p1,
        p1Color,
        p1Thickness,
        p1Style,
        p1ShowTracker,
        p2,
        p2Color,
        p2Thickness,
        p2Style,
        p2ShowTracker,
        height: height,
      },
      value: value,
    });
  }, [
    p1,
    p1Color,
    p1Thickness,
    p1Style,
    p1ShowTracker,
    p2,
    p2Color,
    p2Thickness,
    p2Style,
    p2ShowTracker,
    height,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const Trix = props.localChartSetting.Trix_Option.option;

    setP1(parseInt(Trix.p1) || p1);
    setP1Color(Trix.p1Color);
    setP1Thickness(parseInt(Trix.p1Thickness) || p1Thickness);
    setP1Style(Trix.p1Style);
    setP1ShowTracker(Trix.p1ShowTracker);
    setP2(parseInt(Trix.p2) || p2);
    setP2Color(Trix.p2Color);
    setP2Thickness(parseInt(Trix.p2Thickness) || p2Thickness);
    setP2Style(Trix.p2Style);
    setP2ShowTracker(Trix.p2ShowTracker);
    setHeight(Trix.height);
  }, [props.isReset]);

  const resetALL = () => {
    setP1(15);
    setP1Color("EE3311");
    setP1Thickness(2);
    setP1Style("s");
    setP1ShowTracker(true);
    setP2(9);
    setP2Color("0080FF");
    setP2Thickness(2);
    setP2Style("s");
    setP2ShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "p1") setP1(e.target.value);
    else setP2(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    if (type === "p1") setP1Thickness(e.target.value);
    else setP2Thickness(e.target.value);
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    if (type === "p1") setP1Style(e.target.value);
    else setP2Style(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // type: string
  const onShowTracker = (type) => {
    if (type === "P1")
      if (p1ShowTracker) setP1ShowTracker(false);
      else setP1ShowTracker(true);
    else if (p2ShowTracker) setP2ShowTracker(false);
    else setP2ShowTracker(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "Trix-C1":
        setP1Color(props.selectedColor);
        break;
      case "Trix-C2":
        setP2Color(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("Trix")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.Trix")} (Trix) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("Trix")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-Trix">
          <div className="flexbox">
            <div className="taparamlbl">Trix {t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-Trix-ma-period0"
                type="number"
                step="1"
                min="1"
                value={p1}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={p1Color}
              style={{ backgroundColor: "#" + p1Color }}
              onClick={() =>
                onColorClick(p1Color, "Trix-C1", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={p1Thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-UI-p0-style" value={p1Style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  p1ShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-Trix">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.signal")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-Trix-s-period0"
                type="number"
                step="1"
                min="1"
                value={p2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={p2Color}
              style={{ backgroundColor: "#" + p2Color }}
              onClick={() =>
                onColorClick(p2Color, "Trix-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={p2Thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-UI-p0-style" value={p2Style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  p2ShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue="1"
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
