import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const KC = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(14);
  const [color, setColor] = useState("666666");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [ATR1enable, setATR1Enable] = useState(false);
  const [ATR1period, setATR1Period] = useState(10);
  const [ATR1color1, setATR1Color1] = useState("FFB0B0");
  const [ATR1color2, setATR1Color2] = useState("449999");
  const [ATR1thickness, setATR1Thickness] = useState(2);
  const [ATR1style, setATR1Style] = useState("s");
  const [ATR1showTracker, setATR1ShowTracker] = useState(true);

  const [ATR2enable, setATR2Enable] = useState(true);
  const [ATR2period, setATR2Period] = useState(10);
  const [ATR2color1, setATR2Color1] = useState("AA2211");
  const [ATR2color2, setATR2Color2] = useState("66CCCC");
  const [ATR2thickness, setATR2Thickness] = useState(2);
  const [ATR2style, setATR2Style] = useState("s");
  const [ATR2showTracker, setATR2ShowTracker] = useState(true);

  const [ATR3enable, setATR3Enable] = useState(false);
  const [ATR3period, setATR3Period] = useState(10);
  const [ATR3color1, setATR3Color1] = useState("FFB0B0");
  const [ATR3color2, setATR3Color2] = useState("449999");
  const [ATR3thickness, setATR3Thickness] = useState(2);
  const [ATR3style, setATR3Style] = useState("s");
  const [ATR3showTracker, setATR3ShowTracker] = useState(true);
  const [value, setValue] = useState(
    period +
    "," +
    "1xATR(" +
    ATR1period +
    ")," +
    "2xATR(" +
    ATR2period +
    ")," +
    "3xATR(" +
    ATR3period +
    ")"
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.KC_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      const KC = localChartSetting.option;

      const kc_atr1 = KC.atr[0]
      const kc_atr2 = KC.atr[1]
      const kc_atr3 = KC.atr[2]
      const mid = KC.mid

      let kc = "";
      let atr1 = "";
      let atr2 = "";
      let atr3 = "";

      kc = {
        period: mid.period,
        color: mid.color,
        thickness: mid.thickness,
        style: mid.style,
        showTracker: mid.showTracker,
        enabled: mid.enabled,
      };

      atr1 = {
        period: kc_atr1.period,
        color: kc_atr1.color,
        color1: kc_atr1.color1,
        thickness: kc_atr1.thickness,
        style: kc_atr1.style,
        showTracker: kc_atr1.showTracker,
        enabled: kc_atr1.enabled,
      };

      atr2 = {
        period: kc_atr2.period,
        color: kc_atr2.color,
        color1: kc_atr2.color1,
        thickness: kc_atr2.thickness,
        style: kc_atr2.style,
        showTracker: kc_atr2.showTracker,
        enabled: kc_atr2.enabled,
      };

      atr3 = {
        period: kc_atr3.period,
        color: kc_atr3.color,
        color1: kc_atr3.color1,
        thickness: kc_atr3.thickness,
        style: kc_atr3.style,
        showTracker: kc_atr3.showTracker,
        enabled: kc_atr3.enabled,
      };

      let atr = [atr1, atr2, atr3];

      let v = "";
      if (mid.enabled) {
        v = v + mid.period + ",";
      }
      if (kc_atr1.enabled) {
        v = v + "1xATR(" + kc_atr1.period + "),";
      }
      if (kc_atr2.enabled) {
        v = v + "2xATR(" + kc_atr2.period + "),";
      }
      if (kc_atr3.enabled) {
        v = v + "3xATR(" + kc_atr3.period + ")";
      }

      setValue(v);

      props.onOverlayValueChange({
        ta: "KC",
        name: `${t("chart5.TA.KC")} (KC)`,
        option: {
          mid: kc,
          atr: atr,
        },
        value: KC.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const KC = props.localChartSetting.KC_Option.option;

    const kc_atr1 = KC.atr[0]
    const kc_atr2 = KC.atr[1]
    const kc_atr3 = KC.atr[2]
    const mid = KC.mid

    setEnable(mid.enabled);
    setPeriod(parseInt(mid.period) || period);
    setColor(mid.color);
    setThickness(parseInt(mid.thickness) || thickness);
    setStyle(mid.style);
    setShowTracker(mid.showTracker);

    setATR1Enable(kc_atr1.enabled);
    setATR1Period(parseInt(kc_atr1.period) || ATR1period);
    setATR1Color1(kc_atr1.color);
    setATR1Color2(kc_atr1.color1);
    setATR1Thickness(parseInt(kc_atr1.thickness) || ATR1thickness);
    setATR1Style(kc_atr1.style);
    setATR1ShowTracker(kc_atr1.showTracker);

    setATR2Enable(kc_atr2.enabled);
    setATR2Period(parseInt(kc_atr2.period) || ATR2period);
    setATR2Color1(kc_atr2.color);
    setATR2Color2(kc_atr2.color1);
    setATR2Thickness(parseInt(kc_atr2.thickness) || ATR2thickness);
    setATR2Style(kc_atr2.style);
    setATR2ShowTracker(kc_atr2.showTracker);

    setATR3Enable(kc_atr3.enabled);
    setATR3Period(parseInt(kc_atr3.period) || ATR3period);
    setATR3Color1(kc_atr3.color);
    setATR3Color2(kc_atr3.color1);
    setATR3Thickness(parseInt(kc_atr3.thickness) || ATR3thickness);
    setATR3Style(kc_atr3.style);
    setATR3ShowTracker(kc_atr3.showTracker);

  }, [props.localChartSetting]);

  useEffect(() => {
    let kc = "";
    let atr1 = "";
    let atr2 = "";
    let atr3 = "";

    kc = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    atr1 = {
      period: ATR1period,
      color: ATR1color1,
      color1: ATR1color2,
      thickness: ATR1thickness,
      style: ATR1style,
      showTracker: ATR1showTracker,
      enabled: ATR1enable,
    };

    atr2 = {
      period: ATR2period,
      color: ATR2color1,
      color1: ATR2color2,
      thickness: ATR2thickness,
      style: ATR2style,
      showTracker: ATR2showTracker,
      enabled: ATR2enable,
    };

    atr3 = {
      period: ATR3period,
      color: ATR3color1,
      color1: ATR3color2,
      thickness: ATR3thickness,
      style: ATR3style,
      showTracker: ATR3showTracker,
      enabled: ATR3enable,
    };

    let atr = [atr1, atr2, atr3];

    let v = "";
    if (enable) {
      v = v + period + ",";
    }
    if (ATR1enable) {
      v = v + "1xATR(" + ATR1period + "),";
    }
    if (ATR2enable) {
      v = v + "2xATR(" + ATR2period + "),";
    }
    if (ATR3enable) {
      v = v + "3xATR(" + ATR3period + ")";
    }

    setValue(v);

    props.onOverlayValueChange({
      ta: "KC",
      name: `${t("chart5.TA.KC")} (KC)`,
      option: {
        mid: kc,
        atr: atr,
      },
      value: v,
    });
  }, [
    period,
    color,
    thickness,
    style,
    showTracker,
    enable,
    ATR1period,
    ATR1color1,
    ATR1color2,
    ATR1thickness,
    ATR1style,
    ATR1showTracker,
    ATR1enable,
    ATR2period,
    ATR2color1,
    ATR2color2,
    ATR2thickness,
    ATR2style,
    ATR2showTracker,
    ATR2enable,
    ATR3period,
    ATR3color1,
    ATR3color2,
    ATR3thickness,
    ATR3style,
    ATR3showTracker,
    ATR3enable,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const KC = props.localChartSetting.KC_Option.option;

    const kc_atr1 = KC.atr[0]
    const kc_atr2 = KC.atr[1]
    const kc_atr3 = KC.atr[2]
    const mid = KC.mid

    setEnable(mid.enabled);
    setPeriod(parseInt(mid.period) || period);
    setColor(mid.color);
    setThickness(parseInt(mid.thickness) || thickness);
    setStyle(mid.style);
    setShowTracker(mid.showTracker);

    setATR1Enable(kc_atr1.enabled);
    setATR1Period(parseInt(kc_atr1.period) || ATR1period);
    setATR1Color1(kc_atr1.color);
    setATR1Color2(kc_atr1.color1);
    setATR1Thickness(parseInt(kc_atr1.thickness) || ATR1thickness);
    setATR1Style(kc_atr1.style);
    setATR1ShowTracker(kc_atr1.showTracker);

    setATR2Enable(kc_atr2.enabled);
    setATR2Period(parseInt(kc_atr2.period) || ATR2period);
    setATR2Color1(kc_atr2.color);
    setATR2Color2(kc_atr2.color1);
    setATR2Thickness(parseInt(kc_atr2.thickness) || ATR2thickness);
    setATR2Style(kc_atr2.style);
    setATR2ShowTracker(kc_atr2.showTracker);

    setATR3Enable(kc_atr3.enabled);
    setATR3Period(parseInt(kc_atr3.period) || ATR3period);
    setATR3Color1(kc_atr3.color);
    setATR3Color2(kc_atr3.color1);
    setATR3Thickness(parseInt(kc_atr3.thickness) || ATR3thickness);
    setATR3Style(kc_atr3.style);
    setATR3ShowTracker(kc_atr3.showTracker);

  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(14);
    setColor("666666");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setATR1Enable(false);
    setATR1Period(10);
    setATR1Color1("FFB0B0");
    setATR1Color2("449999");
    setATR1Thickness(2);
    setATR1Style("s");
    setATR1ShowTracker(true);

    setATR2Enable(true);
    setATR2Period(10);
    setATR2Color1("AA2211");
    setATR2Color2("66CCCC");
    setATR2Thickness(2);
    setATR2Style("s");
    setATR2ShowTracker(true);

    setATR3Enable(false);
    setATR3Period(10);
    setATR3Color1("FFB0B0");
    setATR3Color2("449999");
    setATR3Thickness(2);
    setATR3Style("s");
    setATR3ShowTracker(true);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "default":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "ATR1":
        if (ATR1enable) setATR1Enable(false);
        else setATR1Enable(true);
        break;
      case "ATR2":
        if (ATR2enable) setATR2Enable(false);
        else setATR2Enable(true);
        break;
      case "ATR3":
        if (ATR3enable) setATR3Enable(false);
        else setATR3Enable(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "default":
        setStyle(e.target.value);
        break;
      case "ATR1":
        setATR1Style(e.target.value);
        break;
      case "ATR2":
        setATR2Style(e.target.value);
        break;
      case "ATR3":
        setATR3Style(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "default":
        setPeriod(e.target.value);
        break;
      case "ATR1":
        setATR1Period(e.target.value);
        break;
      case "ATR2":
        setATR2Period(e.target.value);
        break;
      case "ATR3":
        setATR3Period(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "default":
        setThickness(e.target.value);
        break;
      case "ATR1":
        setATR1Thickness(e.target.value);
        break;
      case "ATR2":
        setATR2Thickness(e.target.value);
        break;
      case "ATR3":
        setATR3Thickness(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "default":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "ATR1":
        if (ATR1showTracker) setATR1ShowTracker(false);
        else setATR1ShowTracker(true);
        break;
      case "ATR2":
        if (ATR2showTracker) setATR2ShowTracker(false);
        else setATR2ShowTracker(true);
        break;
      case "ATR3":
        if (ATR3showTracker) setATR3ShowTracker(false);
        else setATR3ShowTracker(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "KC-C1":
        setColor(props.selectedColor);
        break;
      case "KC-C2":
        setATR1Color1(props.selectedColor);
        break;
      case "KC-C3":
        setATR1Color2(props.selectedColor);
        break;
      case "KC-C4":
        setATR2Color1(props.selectedColor);
        break;
      case "KC-C5":
        setATR2Color2(props.selectedColor);
        break;
      case "KC-C6":
        setATR3Color1(props.selectedColor);
        break;
      case "KC-C7":
        setATR3Color2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("KC")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.KC")}&nbsp;(KC) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("KC")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-KC"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("default")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "default")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "KC-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "default")}
              />
            </div>
            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "default")}
            >
              <select id="msi-ta-KC-mid-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("default")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={ATR1enable ? {} : { opacity: "0.5" }}
          id="msi-ta-KC"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-p1-enabled"
              className={
                ATR1enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("ATR1")}
            ></div>
            <div className="taparamlbl">1 ATR {t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-p1-period0"
                type="number"
                step="1"
                min="1"
                value={ATR1period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "ATR1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-KC-p1-color0"
              className="colorpicker"
              value={ATR1color1}
              style={{ backgroundColor: "#" + ATR1color1 }}
              onClick={() =>
                onColorClick(ATR1color1, "KC-C2", t("chart5.setting"))
              }
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-KC-p1-color1"
              className="colorpicker"
              value={ATR1color2}
              style={{ backgroundColor: "#" + ATR1color2 }}
              onClick={() =>
                onColorClick(ATR1color2, "KC-C3", t("chart5.setting"))
              }
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-KC-p1-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={ATR1thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "ATR1")}
              />
            </div>

            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "ATR1")}
            >
              <select id="msi-ta-KC-p1-style" value={ATR1style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-KC-p1-showtracker"
                className={
                  ATR1showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("ATR1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={ATR2enable ? {} : { opacity: "0.5" }}
          id="msi-ta-KC"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-p2-enabled"
              className={
                ATR2enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("ATR2")}
            ></div>
            <div className="taparamlbl">2 ATR {t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-p2-period0"
                type="number"
                step="1"
                min="1"
                value={ATR2period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "ATR2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-KC-p2-color0"
              className="colorpicker"
              value={ATR2color1}
              style={{ backgroundColor: "#" + ATR2color1 }}
              onClick={() =>
                onColorClick(ATR2color1, "KC-C4", t("chart5.setting"))
              }
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-KC-p2-color1"
              className="colorpicker"
              value={ATR2color1}
              style={{ backgroundColor: "#" + ATR2color2 }}
              onClick={() =>
                onColorClick(ATR2color1, "KC-C5", t("chart5.setting"))
              }
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-KC-p2-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={ATR2thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "ATR2")}
              />
            </div>

            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "ATR2")}
            >
              <select id="msi-ta-KC-p2-style" value={ATR2style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-KC-p2-showtracker"
                className={
                  ATR2showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("ATR2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={ATR3enable ? {} : { opacity: "0.5" }}
          id="msi-ta-KC"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-p3-enabled"
              className={
                ATR3enable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("ATR3")}
            ></div>
            <div className="taparamlbl">3 ATR {t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-p3-period0"
                type="number"
                step="1"
                min="1"
                value={ATR3period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "ATR3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-KC-p3-color0"
              className="colorpicker"
              value={ATR3color1}
              style={{ backgroundColor: "#" + ATR3color1 }}
              onClick={() =>
                onColorClick(ATR3color1, "KC-C6", t("chart5.setting"))
              }
            ></button>
            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-KC-p3-color1"
              className="colorpicker"
              value={ATR3color2}
              style={{ backgroundColor: "#" + ATR3color2 }}
              onClick={() =>
                onColorClick(ATR3color2, "KC-C7", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-p3-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={ATR3thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "ATR3")}
              />
            </div>
            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "ATR3")}
            >
              <select id="msi-ta-KC-p3-style" value={ATR3style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-p3-showtracker"
                className={
                  ATR3showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("ATR3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
