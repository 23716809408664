import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  askImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class PriceModal extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  // 是否退出登录
  isLogoutFun(msg){
    const {locationName,isRightPageShow}=this.props
    this.props.cbCloseLogOout(false)
    if(msg=='Cancel'){
      let navId=sessionStorage.getItem('navId')
      if(navId=='logout'){
        if(isRightPageShow=='trade'){
          this.props.callbackChgNavIndex('settings')
          sessionStorage.setItem('navId','settings')
          return
        }
        this.chgNavIndexfun()
      }
      return
    }
    // 退出登录
    let loginName=sessionStorage.getItem('loginName')
    let name='/'+locationName[1]+'/'+loginName
    this.props.callbackPathnameApp(name)// 获取路由跳转--app.js
  }
  chgNavIndexfun(){
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    sessionStorage.setItem('navId',navIdAPP)
    this.props.callbackChgNavIndex(navIdAPP, true)
  }
  render(){
    const {t,bodyW,ChgThemeData,tag,ChgLangData,sureText,contentText,delData,cancelText,resultTitle,isMobile}=this.props
    let chgAdd=tag=='add'&&((ChgLangData=='zh-CN'&&bodyW<374)||(ChgLangData=='en-US'&&bodyW<537))
    let chgLogout=tag=='logout'&&ChgLangData=='en-US'&&bodyW<383
    let chgTodayorder=tag=='todayorder'&&((ChgLangData=='zh-CN'&&bodyW<397)||(ChgLangData=='en-US'&&bodyW<485))
    return (
      <div className={styles.modal_fixed}>
        <div 
          className={[
            styles.modal_con,
            ChgThemeData=='Light'?styles.modal_con_light:''
          ].join(' ')} 
          style={{
            width:isMobile?'80%':525,
            height:'auto',
            minHeight:193
          }}
        >
          <div 
            className={[
              styles.title,
              isMobile?styles.title_mobile:'',
              APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
            ].join(' ')}
          >
            <div  
              className={APPstyles.flex_row} 
              style={{
                alignItems:chgAdd||chgLogout||chgTodayorder||tag=='todayorder'?'flex-start':'center'
              }}
            >
              <img src={askImg} className={APPstyles.img24} style={{cursor:'default'}} alt='warning' />
              <span style={{display:tag!=='todayorder'?'':'none'}}>{t(contentText)}{tag=='screener'&&ChgLangData=='en-US'?' ':''}{tag=='screener'?t(resultTitle):''}{tag=='screener'&&ChgLangData=='zh-CN'?t('嗎'):''} {tag=='screener'?ChgLangData=='en-US'?'?':'？':''}</span>
              <div style={{display:tag=='todayorder'?'':'none'}}>
                {
                  ChgLangData=='zh-CN'?
                    <div>確認刪除此筆委託 : <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.fw500].join(' ')}>{delData&&delData['stock_name']}</span> {delData&&delData['direction']=='B'?'買進':'賣出'} {common.transThousands(common.filterErro(delData&&delData['order_price']))} {delData&&delData['qty']}股 委託書號:{delData&&delData['order_no']?delData&&delData['order_no']:delData&&delData['oid']} ? </div>
                  :  
                    <div>Do you want to cancel trade : <span className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.fw500].join(' ')}>{delData&&delData['stock_name']}</span>  {delData&&delData['direction']=='B'?'Buy':'Sell'} {common.transThousands(common.filterErro(delData&&delData['order_price']))} QTY:{delData&&delData['qty']} Order ID:{delData&&delData['order_no']?delData&&delData['order_no']:delData&&delData['oid']} ?</div>
                }
              </div>
            </div>
          </div>
          <div 
            className={[
              styles.button_con,
              APPstyles.flex_row,
              isMobile?styles.button_con_mobile:'',
              tag=='screener'?styles.button_con_screener:'',
              tag=='screener'&&isMobile?styles.button_con_screener_mobile:''
            ].join(' ')}
          >
            <Button 
              type='default' 
              className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} 
              onClick={()=>{
                if(tag=='logout'){
                  this.isLogoutFun('Cancel')
                  return
                }
                this.props.cbCloseBtnFun('N')
              }}
            >
              {t(cancelText)}
            </Button>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={()=>{
                if(tag=='logout'){
                  this.isLogoutFun('Confirm')
                  return
                }
                this.props.cbCloseBtnFun('Y')
              }}
            >
              {t(sureText)}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(PriceModal)