import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./TechnicalChart.scss";
// import { ReactComponent as Logo } from "./../../../img/svg/SMBCLogo.svg";
// import { ReactComponent as LogoBlack } from "./../../../img/svg/SMBCLogo_Black.svg";
// import { ReactComponent as IconHelp } from "./../../../img/svg/Help.svg";
// import { ReactComponent as IconHelpButton } from "./../../../img/svg/btn_hl1_help.svg";
// import { ReactComponent as IconRefinitivLogo } from "./../../../img/svg/RefinitivLogo.svg";
// import RefinitivFooter from "../../Footer/RefinitivFooter";
// import { Dropdown } from "../../Inputs/Dropdown";
import Chart5 from "../Chart5";
import { Menu } from "./Component/Menu";
// import GeneralButton from "../../Button/GeneralButton";
// import { ButtonBar } from "../../Button/ButtonBar";
// import green from "./../../../img/bullet_green.png";
// import red from "./../../../img/bullet_red.png";
import { ColorPicker } from "./Component/ColorPicker";
import DatePicker from "./Component/DatePicker";
import axios from "axios";
import CompareList from "../../Configs/compare.json";
// import { EQTY } from "../Compare/EQTY";
// import { CompareDropdown } from "../Compare/CompareDropdown";
import useQueryString from "../../Hooks/useQueryString";
// import { ObjSearchHistoryType } from "../../../pc/pages/Quote";
// import config from "../../../data/config";
import useInterval from "../../Hooks/useInterval";
import useIsFirstRender from "../../Hooks/useIsFirstRender";
// import InputBoxRICSearch from "../../Inputs/InputBoxRICSearch";
// import config from "../../../data/config";
import NumberFormat from "../../Inputs/NumberFormat";
// import ResizePopup from "../../../functions/resizePopup";
import config from "../../Configs/config";
import { useTranslation } from "react-i18next";
// import { isEqual } from "lodash";
import { Calendar } from "antd";
import locale from "antd/lib/calendar/locale/zh_CN.js";
import moment from "moment";
import { defaultChartSetting } from "../DefaultChartSetting";
import APPstyles from '@/App.less'
import common from '@/asserts/js/common'
import { 
  upImg,
  downImg
} from '@/asserts/imgs/common/index'

// type QuoteData = {
//   ric: string | null;
//   market: string | null;
//   name: string | null;
//   last: number | null;
//   change: number | null;
//   percentage: number | string | null;
//   dt: string | null;
//   perm: number;
//   isClosingRun: boolean | null;
// };

export const DEFAULT_SETTING = {
  backgroundColor: "000000",
  chartBackgroundColor: "000000",
  chartLineColor: "111111",
  toolTipsColor: "FFFFFF",
  upColor: "FFFFFF",
  upFillColor: "FFFFFF",
  downColor: "F5475B",
  downFillColor: "F5475B",
};
export const DEFAULT_SETTING_LIGHT = {
  backgroundColor: "FDFDFD",
  chartBackgroundColor: "FDFDFD",
  chartLineColor: "EEEEEE",
  toolTipsColor: "333333",
  upColor: "333333",
  upFillColor: "333333",
  downColor: "333333",
  downFillColor: "F5475B",
};

const INITIAL_OBJ_STORED_INDICES = {
  value: CompareList.RIC[0].value,
  flag: CompareList.RIC[0].flag,
};
const INITIAL_OBJ_STORED_SECTOR = {
  value: CompareList.SECTOR[0].value,
  flag: CompareList.SECTOR[0].flag,
};

const TechnicalChart = (props) => {
  const {
    backgroundColor: BACKGROUND_COLOR,
    // chartBackgroundColor: CHARTBACKGROUNDCOLOR,
    // chartLineColor: CHARTLINECOLOR,
    // toolTipsColor: TOOLTIPSCOLOR,
    // upColor: UPCOLOR,
    // upFillColor: UPFILLCOLOR,
    // downColor: DOWNCOLOR,
    // downFillColor: DOWNFILLCOLOR,
  } = props.ChgThemeData === "Light" ? DEFAULT_SETTING_LIGHT : DEFAULT_SETTING;

  // props = Object.assign({}, props, { ChgThemeData: "dark" });
  const isFirstRender = useIsFirstRender();
  const [isDarkMode, setIsDarkMode] = useState(
    props.ChgThemeData === "Light" ? false : true
  );
  // const [mode, setMode] = useState(1);
  // const modes = [
  //   { id: 1, value: "白", isActive: true, enable: true },
  //   { id: 2, value: "黒", isActive: false, enable: true },
  // ];

  let chartRef = React.createRef();
  let colorRef = React.createRef();
  let query = useQueryString();

  const [status, setStatus] = useState(true);
  const [period, setPeriod] = useState("1min");
  const [periodOption, setPeriodOption] = useState("");
  const [color, setColor] = useState("FF3300");
  const [title, setTitle] = useState("");
  const [colorType, setColorType] = useState("");
  const [isColorPicker, setIsColorPicker] = useState(false);
  const [isDatePicker, setIsDatePicker] = useState(false);
  const [showMainTracker, setShowMainTracker] = useState(true);
  const [showTa, setShowTa] = useState(true);
  const [showCompare, setShowCompare] = useState(true);
  const [showDrawTool, setShowDrawTool] = useState(true);
  const [showTracker, setShowTracker] = useState(true);
  const [showToolTips, setShowToolTips] = useState(true);
  const [showEvent, setShowEvent] = useState(false);
  const [eventType, setEventType] = useState("SCD");
  const [scrollView, setScrollView] = useState(false);
  const [crosshair, setCrosshair] = useState(true);
  const [chartType, setChartType] = useState("candlestick");
  const [option, setOption] = useState({
    lineColor: "0033CC",
    lineFillColor: "99CCFF",
    lineFillColor2: "FFFFFF",
    lineGradientEnable: true,
    lineStyle: "s",
  });
  const [drawTool, setDrawTool] = useState("move");
  const [drawToolOption, setDrawToolOption] = useState("");
  const [tapane, setTapane] = useState([]);
  const [overlay, setOverlay] = useState([]);

  const [isEQTY, setIsEQTY] = useState(
    query.get("ind") === "1" || query.get("ind") === null ? true : false
  );
  const [isEQTYIDX, setIsEQTYIDX] = useState(
    query.get("ind") === "2" ? true : false
  );
  const [isSECTOR, setIsSECTOR] = useState(
    query.get("ind") === "3" ? true : false
  );
  const [code, setCode] = useState(
    // query.get("ric") !== null && query.get("ric") !== ""
    //   ? query.get("ric")
    //   : query.get("code") !== null
    //   ? query.get("code")
    //   : ".DJI"
    ""
  ); // code is ric
  const [code_symbol, setCode_symbol] = useState(""); // code_symbol is symbol
  const [temp_code, setTemp_code] = useState(""); // temp_code is used to store ric temporarily
  const [cc, setCC] = useState("");
  const [nm, setName] = useState("");
  const [last, setLast] = useState(0)
  const [netChange, setnetChange] = useState(0)
  const [pctChange, setpctChange] = useState(0)
  const [compare, setCompare] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [isChartDrawColorPickerOpen, setIsChartDrawColorPickerOpen] =
    useState(false);
  const [menuColorPickerClose, setMenuColorPickerClose] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [dateType, setDateType] = useState("d");
  const [chartSetting, setChartSetting] = useState();

  const [powerSearchResult, setPowerSearchResult] = useState([]);
  const [info, setInfo] = useState();
  const [quote, setQuote] = useState({
    ric: null,
    market: null,
    name: null,
    last: null,
    change: null,
    percentage: null,
    dt: null,
    perm: 1,
    isClosingRun: null,
  });
  const [showMsg, setShowMsg] = useState(false);
  const [defaultValue, setDefaultValue] = useState(
    query.get("ric") ? query.get("ric") : ".DJI"
  );
  const [selectedRic, setSelectedRic] = useState(
    query.get("ric") ? query.get("ric") : ".DJI"
  );
  const [timeInterval, setTimeInterval] = useState(null); //  isAuto -> timeInterval -> trigger useInterval
  const [stockRic, setStockRic] = useState("");
  const [objStoredIndices, setObjStoredIndices] = useState(
    INITIAL_OBJ_STORED_INDICES
  );
  const [objStoredSector, setObjStoredSector] = useState(
    INITIAL_OBJ_STORED_SECTOR
  );
  const [isSymbolSearch, setIsSymbolSearch] = useState(false);
  const [isJustSelectEQTY, setIsJustSelectEQTY] = useState(false);
  const [token, setToken] = useState("");
  const [isChartZoom, setIsChartZoom] = useState(false);
  const [chartDataPeriod, setChartDataPeriod] = useState();
  const [chartData, setChartData] = useState();
  const [isAllowRefreshUpdateChart, setIsAllowRefreshUpdateChart] =
    useState(true);
  const refIsFirstFillingSymbol = useRef(true);
  const refRecentViewedRic = useRef(null);
  const refRecentViewedStockRic = useRef("AAPL.O"); // this ref is to remember the latest stock, for fixing dropdrown change issue #2768
  const refData = useRef(props.data); // using in setTimeout of function refreshQuote
  const refRefreshChartRic = useRef();
  const { t } = useTranslation();

  const [isChartRotated, setIsChartRotated] = useState(false);
  const [ohlcTable, setOHLCTable] = useState(<div></div>);
  const timeoutIDRef = React.useRef(null);

  const [localChartSetting, setLocalChartSetting] = useState(defaultChartSetting);
  const [loading, setLoading] = useState(false);

  const [isReset, setIsReset] = useState(false);

  // useEffect(() => {
  //   if (!timeoutIDRef.current) {
  //     timeoutIDRef.current = setTimeout(() => {
  //       setOHLCTable(<div></div>)
  //     }, 5000)
  //   }else{
  //     clearTimeout(timeoutIDRef.current)
  //     timeoutIDRef.current = setTimeout(() => {
  //       setOHLCTable(<div></div>)
  //     }, 3000)
  //   }
  // }, [ohlcTable])

  let isMobileViewInSector = props.isSector && !isChartZoom;
  // let isMobileViewInSector = false;
  let streamingData = props.data && props.store.getDataByRic(props.data["ric"]);

  axios.defaults.baseURL =
    window.location.protocol === "http:" ? config.URL : config.URL_HTTPS;
  axios.defaults.headers.common["Pragma"] = "no-cache";
  axios.defaults.params = { token: token };

  // console.log("props.data");
  // console.log(props.data);
  // console.log("props.data");
  // console.log(props.data);

  // useResize();
  // ResizePopup(865, 640);
  // window.onresize = debounce(() => {
  //   window.resizeTo(800, 620);
  // }, 500);

  // get smbc token for internal demo, will delete it after demo / api complete
  useEffect(() => {
    setToken(sessionStorage.getItem("token"));

    let localSetting;
    var stringJson = localStorage.getItem("fubon-chartSetting");
    if (stringJson !== null) {
      localSetting = JSON.parse(stringJson);
      if (localSetting === null) {
        localSetting = null;
      }
    } else {
      localSetting = null;
    }

    if (localSetting) {
      let setting = { ...localSetting }
      if (localSetting.ADXR_Option === undefined) {
        setting.ADXR_Option = localSetting.ADX_R_Option;
        setting.ADX_R_Option = undefined;
      }
      if (localSetting.VityCC_Option !== undefined) {
        setting.VltyCC_Option = localSetting.VityCC_Option;
        setting.VityCC_Option = undefined
      }
      if (localSetting.tapane === undefined) {
        setting.tapane = defaultChartSetting.tapane;
      }
      if (localSetting.overlay === undefined) {
        setting.overlay = defaultChartSetting.overlay;
      }
      if (localSetting["chartType-1min"] === undefined) {
        setting["chartType-1min"] = defaultChartSetting["chartType-1min"];
      }
      if (localSetting["tapane-1min"] === undefined) {
        setting["tapane-1min"] = defaultChartSetting["tapane-1min"];
      }
      if (localSetting["overlay-1min"] === undefined) {
        setting["overlay-1min"] = defaultChartSetting["overlay-1min"];
      }

      loadlocalChartSetting(setting)
    }

    // let pane = document.getElementById("chart");
    // try {
    //   pane.ontouchend = (e) => { e.preventDefault(); setOHLCTable(<div></div>) }
    //   pane.onmouseleave = (e) => { e.preventDefault(); setOHLCTable(<div></div>) }
    // } catch { }

    // axios
    //   .get("smbcapi/authapi/gentoken", {
    //     params: {
    //       uid: "u0001",
    //       pwd: "u0001",
    //       token: undefined,
    //     },
    //   })
    //   .then((res) => {
    //     setToken(res.data.token);
    //   });
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    // console.log("useEffect token, props.data");

    // setCode("AAPL.O");
    // setStockRic("AAPL.O");
    // setName("AAPLE");
    setCode(props.data?.ric ? props.data.ric : "");
    setStockRic(props.data?.ric ? props.data.ric : "");
    setName(props.data?.name);
    setLast(props.data?.TRDPRC_1 ? formatNumber(props.data.TRDPRC_1, 'price', 2) : null);
    setnetChange(props.data?.NETCHNG_1 ? formatNumber(props.data.NETCHNG_1, 'price', 2) : null);
    setpctChange(props.data?.PCTCHNG ? formatNumber(props.data.PCTCHNG, 'price', 2) : null);
  }, [token, props.data]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    onClickCloseZoom();
  }, [props.triggerCloseChartZoom]);

  // using in setTimeout of function refreshQuote
  // useEffect(() => {
  //   if (isEqual(refData.current, props.data)) {
  //     return;
  //   }

  //   refData.current = { ...props.data };
  //   console.log("props.data");
  //   console.log(props.data);
  // }, [props.data]);

  // useEffect(() => {
  //   setTimeout(() => setIsAllowRefreshUpdateChart(true), 3000);
  // }, [isAllowRefreshUpdateChart]);

  useEffect(() => {
    // if (!chartData || !isAllowRefreshUpdateChart) {
    //   return;
    // }
    if (!chartData) {
      return;
    }

    ensureChartReady().then((result) => {
      props.onFinishLoading();
      // check if ric is correct
      if (streamingData?.ric !== refRefreshChartRic.current) {
        Promise.all([
          refreshChart(result),
        ]).then((results) => {
          if (results[0]) {
            setChartData(results[0]);
          }
        })
        return;
      }

      let quote = {
        ric: streamingData?.ric,
        last: streamingData?.TRDPRC_1,
        trd_date: streamingData?.TRADE_DATE,
        trd_time: streamingData?.SALTIM_MS,
      };

      try {
        result.refreshUpdateChart(chartData, chartDataPeriod, quote);
      } catch (err) {
        console.error(err);
      }
    });
  }, [streamingData, chartData]);

  //apply quote to chart5 when first render
  useEffect(() => {
    // console.log("ensureChartReady");
    ensureChartReady().then((result) => {
      Promise.all([
        CompareList.SECTOR.findIndex((item) => item.value === code) === -1 &&
        refreshQuote(),
        refreshChart(result),
      ]).then((results) => {
        // Random Update Testing
        // const min = -10;
        // const max = 10;
        // const rand = min + Math.random() * (max - min);
        // results[0].last = results[0].last ? (parseFloat(results[0].last.toString()) + rand) : null;
        if (results[1]) {
          setChartData(results[1]);
          // let data = results[1];
          // try {
          //   results[0] !== false && result.refreshUpdateChart(data, results[0]);
          // } catch (err) {
          //   console.error(err);
          // }
        } else {
          setChartData();
        }
        //results[0] !== false && results[0].ric === code && setQuote(results[0] as QuoteData);
        results[0] !== false && setQuote(results[0]);
        // console.log("ensureChartReady");
        // console.log(props.onFinishLoading);
        props.onFinishLoading();
      });
    });
  }, [code, period, periodOption]);

  // This is the promise code, so this is the useful bit
  const ensureChartReady = () => {
    return new Promise(waitForChart);

    // waitForFoo makes the decision whether the condition is met
    // or not met or the timeout has been exceeded which means
    // this promise will be rejected
    function waitForChart(resolve, reject) {
      if (chartRef.current && chartRef.current.isChartSeries()) {
        resolve(chartRef.current);
      } else setTimeout(waitForChart.bind(this, resolve, reject), 100);
    }
  };

  // useEffect(() => {
  //   console.log("props.data");
  //   console.log(props.data);

  //   setCode(props.data?.ric ? props.data.ric : "AAPL.O");
  //   setStockRic(props.data?.ric ? props.data.ric : "AAPL.O");
  //   setName(props.data?.name);
  // }, [props.data]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    setIsDarkMode(props.ChgThemeData === "Light");
  }, [props.ChgThemeData]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  });
  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    updateDimensions();
  }, [isChartZoom]);

  // for fixing dropdrown change issue #2768
  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (isEQTY) {
      setCode(refRecentViewedStockRic.current);
    }
  }, [isEQTY]);

  useEffect(() => {
    // setCode_symbol(code)

    // if (isFirstRender) {
    // }

    // let find = CompareList.RIC.some((item) => item.value === code);
    // if (find) {
    //   setDefaultValue(code);
    //   if (
    //     CompareList.SECTOR.findIndex((item: any) => item.value === code) === -1
    //   )
    //     getQuote();
    //   else getSectorData();
    // } else {
    //   setDefaultValue("");
    //   if (code !== "") {
    //     if (
    //       CompareList.SECTOR.findIndex((item: any) => item.value === code) ===
    //       -1
    //     )
    //       getQuote();
    //     else getSectorData();
    //   }
    // }

    // set timeout is for fixing powersearch dropdown ric change bug
    const timer = setTimeout(() => {
      let find = CompareList.RIC.some((item) => item.value === code);
      if (find) {
        setDefaultValue(code);
        if (
          CompareList.SECTOR.findIndex((item) => item.value === code) === -1
        ) {
          // getQuote();
        }
        // else getSectorData();
      } else {
        setDefaultValue("");
        if (code !== "") {
          if (
            CompareList.SECTOR.findIndex((item) => item.value === code) === -1
          ) {
            // getQuote();
          }
          // else getSectorData();
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [code]);

  useEffect(() => {
    if (!isFirstRender) {
      if (CompareList.SECTOR.findIndex((item) => item.value === code) === -1) {
        // getQuote();
      }
      // else getSectorData();
    }

    // for restart interval
    setTimeInterval(null);

    // switch (period) {
    //   case "1min":
    //   case "5mins":
    //   case "15mins":
    //   case "1hour":
    //     setTimeInterval(null);
    //     setTimeInterval(10000);
    //     break;
    //   default:
    //     setTimeInterval(null);
    // }
  }, [period, periodOption]);

  // for restart interval
  useEffect(() => {
    if (timeInterval === null) {
      setTimeInterval(10000);
    }
  }, [timeInterval]);

  useInterval(() => {
    Promise.all([
      CompareList.SECTOR.findIndex((item) => item.value === code) === -1 &&
      refreshQuote(),
      refreshChart(),
    ]).then((results) => {
      // Random Update Testing
      // const min = -10;
      // const max = 10;
      // const rand = min + Math.random() * (max - min);
      // results[0].last = results[0].last ? (parseFloat(results[0].last.toString()) + rand) : null;

      if (results[1]) {
        setChartData(results[1]);
        // let data = results[1];
        // try {
        //   console.log("try");
        //   results[0] !== false &&
        //     chartRef.current.refreshUpdateChart(data, results[0]);
        // } catch (err) {
        //   console.error(err);
        // }
      }

      //results[0] !== false && results[0].ric === code && setQuote(results[0] as QuoteData);
      results[0] !== false && setQuote(results[0]);
    });

    // Previous AutoUpdate Logic
    // if (
    //   CompareList.SECTOR.findIndex((item: any) => item.value === code) === -1
    // ) {
    //   getQuote();
    //   chartRef.current.updateMinChart();
    // } else {
    //   getSectorData();
    //   chartRef.current.updateMinChart();
    // }
  }, timeInterval);

  useEffect(() => {
    // console.log(localChartSetting);
    try {
      if (localChartSetting) {
        const setting = localChartSetting;
        if (isReset) {
          period === "1min" ? setting["tapane-1min"] = new Array() : setting.tapane = new Array();
          period === "1min" ? setting["overlay-1min"] = new Array() : setting.overlay = new Array();
        }
        localStorage.setItem("fubon-chartSetting", JSON.stringify(setting));
      }
    } catch (err) { }
  }, [localChartSetting]);

  const getSectorData = () => {
    if (code !== "") {
      axios
        .get("smbcapi/sectorapi/detail", {
          params: {
            ric: code,
          },
        })

        .then((res) => {
          let quote = {
            ric: res.data.data.ric,
            name: res.data.data.name,
            market: null,
            last: res.data.data.last,
            change: res.data.data.netchng,
            percentage: res.data.data.pctchng,
            dt: res.data.data.trd_date
              ? res.data.data.trd_date.substring(5).replace(/-/g, "/")
              : "-",
            perm: 1,
            isClosingRun: res.data.data.last === 0,
          };

          if (refIsFirstFillingSymbol.current) {
            if (CompareList.SECTOR.find((item) => item.value === quote.ric)) {
              setObjStoredSector({ value: quote.ric, flag: "" });
            }
            refIsFirstFillingSymbol.current = false;
          }

          setQuote(quote);
          setName(res.data.data.name);

          // url rewrite
          // window.history.pushState(
          //   null,
          //   document.title,
          //   `pc?pid=chart&ric=${code}&token=${encodeURIComponent(
          //     query.get("token")
          //   )}`
          // );
        });
    }
  };

  const refreshQuote = () => {
    // return new Promise((resolve, reject) => {
    if (code !== "") {
      return new Promise(waitForQuote);
      // return new Promise((resolve, reject) => {
      //   resolve({
      //     last: props.data.TRDPRC_1,
      //     trd_date: props.data.TRADE_DATE,
      //   });
      // });

      // waitForFoo makes the decision whether the condition is met
      // or not met or the timeout has been exceeded which means
      // this promise will be rejected
      function waitForQuote(resolve, reject) {
        if (streamingData?.ric === code) {
          let returnObj = {
            last: streamingData.TRDPRC_1,
            trd_date: streamingData.TRADE_DATE,
            trd_time: streamingData.SALTIM_MS,
            // trd_date: streamingDate.toISOString().substring(0, 10),
            // trd_time: streamingTime.toISOString().substring(11, 16),
          };

          resolve(returnObj);
        } else setTimeout(() => waitForQuote(resolve, reject), 30);
      }
      // axios
      //   .get("smbcapi/quoteapi", {
      //     params: { rics: code, type: "quote" },
      //   })
      //   .then((res) => {
      //     let data = res.data.quotes;
      //     let quote = {
      //       ric: data[0].ric,
      //       name: data[0].name,
      //       market: data[0].exch,
      //       last:
      //         data[0].ric === "US10YT=XX"
      //           ? data[0].yield === undefined
      //             ? "-"
      //             : data[0].yield.toFixed(4)
      //           : data[0].ric === "JPY=X" ||
      //             data[0].ric === "EUR=X" ||
      //             data[0].ric === "XAU=X" ||
      //             data[0].ric === "XAG=X"
      //           ? data[0].bid === undefined
      //             ? "-"
      //             : data[0].bid.toFixed(4)
      //           : data[0].last === undefined
      //           ? "-"
      //           : data[0].last === 0
      //           ? data[0].pclose.toFixed(4)
      //           : data[0].last.toFixed(4),
      //       change:
      //         data[0].ric === "US10YT=XX"
      //           ? data[0].yld_netchng === undefined
      //             ? "-"
      //             : data[0].yld_netchng
      //           : data[0].ric === "XAU=X" || data[0].ric === "XAG=X"
      //           ? data[0].bid_netchng === undefined
      //             ? "-"
      //             : data[0].bid_netchng
      //           : data[0].netchng,
      //       percentage:
      //         data[0].ric === "US10YT=XX"
      //           ? data[0].yld_pctchng === undefined
      //             ? "-"
      //             : data[0].yld_pctchng
      //           : data[0].pctchng,
      //       dt:
      //         convertDateTime2(data[0].trd_date) +
      //         ` ${data[0].trd_time ? data[0].trd_time : "-"}`,
      //       perm: data[0].perm_id,
      //       isClosingRun: data[0].last === 0,
      //     };
      //     resolve(quote);
      //   })
      //   .catch((err) => {
      //     reject();
      //   });
    }
    // });
  };

  const refreshChart = (result) => {
    return new Promise((resolve, reject) => {
      let url = result
        ? result.getUpdateURL()[0]
        : chartRef.current.getUpdateURL()[0];

      let data = result
        ? result.getUpdateURL()[1]
        : chartRef.current.getUpdateURL()[1];

      refRefreshChartRic.current = data.ric;
      setChartDataPeriod(data.i);

      axios
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject();
        });
    });
  };

  // isSymbol?: boolean
  const getQuote = (isSymbol) => {
    if (code !== "" || isSymbol) {
      axios
        .get("smbcapi/quoteapi", {
          params: {
            rics: isSymbol
              ? null
              : // : isJustSelectEQTY
              // ? refRecentViewedStockRic.current
              code,
            sym: isSymbol ? code_symbol : null,
            type: "quote",
          },
        })
        .then((res) => {
          let data = res.data.quotes;

          // if (data[0].ric === code) {
          let quote = {
            ric: data[0].ric,
            name: data[0].name,
            market: data[0].exch,
            last:
              data[0].ric === "US10YT=XX"
                ? data[0].yield === undefined
                  ? "-"
                  : data[0].yield.toFixed(4)
                : data[0].ric === "JPY=X" ||
                  data[0].ric === "EUR=X" ||
                  data[0].ric === "XAU=X" ||
                  data[0].ric === "XAG=X"
                  ? data[0].bid === undefined
                    ? "-"
                    : data[0].bid.toFixed(4)
                  : data[0].last === undefined
                    ? "-"
                    : data[0].last === 0
                      ? data[0].pclose.toFixed(4)
                      : data[0].last.toFixed(4),
            change:
              data[0].ric === "US10YT=XX"
                ? data[0].yld_netchng === undefined
                  ? "-"
                  : data[0].yld_netchng
                : data[0].ric === "XAU=X" || data[0].ric === "XAG=X"
                  ? data[0].bid_netchng === undefined
                    ? "-"
                    : data[0].bid_netchng
                  : data[0].netchng,
            percentage:
              data[0].ric === "US10YT=XX"
                ? data[0].yld_pctchng === undefined
                  ? "-"
                  : data[0].yld_pctchng
                : data[0].pctchng,
            dt:
              convertDateTime2(data[0].trd_date) +
              ` ${data[0].trd_time ? data[0].trd_time : "-"}`,
            perm: data[0].perm_id,
            isClosingRun: data[0].last === 0,
          };
          setQuote(quote);
          setCode(data[0].ric);
          setStockRic(data[0].ric);
          setName(data[0].name);
          // for the first time to fill ticker symbol into input box #2008
          // if (refIsFirstFillingSymbol.current) {
          //   if (CompareList.RIC.find((item) => item.value === data[0].ric)) {
          //     setObjStoredIndices({ value: data[0].ric, flag: "" });
          //   } else if (
          //     CompareList.SECTOR.find((item) => item.value === data[0].ric)
          //   ) {
          //     setObjStoredSector({ value: data[0].ric, flag: "" });
          //   } else {
          //     setCode_symbol(data[0].symbol);
          //   }
          //   refIsFirstFillingSymbol.current = false;
          // }

          // url rewrite
          // window.history.pushState(
          //   null,
          //   document.title,
          //   `pc?pid=chart&ric=${code}&token=${encodeURIComponent(
          //     query.get("token")
          //   )}`
          // );

          // add search history logic
          if (data[0].asset_type === "EQTY" || data[0].is_ETF) {
            //   // add try catch to guard block third party cookies/localstorage issue
            //   try {
            //     let arrSearchHistory: Array<ObjSearchHistoryType> =
            //       localStorage.getItem("arrSearchHistory")
            //         ? JSON.parse(
            //             localStorage.getItem("arrSearchHistory") as string
            //           )
            //         : [];
            //     // fix previous 10 stocks to 6 stock in localstorage, can be removed in prod
            //     while (arrSearchHistory.length > 6) {
            //       arrSearchHistory.pop();
            //     }
            //     let targetIdx = arrSearchHistory.findIndex(
            //       (item: any) => item.ric === code
            //     );
            //     const {
            //       ric: ric_symbol,
            //       name,
            //       symbol,
            //       tradable,
            //       buysell_flag,
            //     } = data[0];
            //     if (
            //       ric_symbol === undefined ||
            //       name === undefined ||
            //       symbol === undefined ||
            //       tradable === undefined ||
            //       buysell_flag === undefined
            //     ) {
            //       throw new Error("have-undefined");
            //     }
            //     let newRic = {
            //       ric: ric_symbol as string,
            //       name: name as string,
            //       symbol: symbol as string,
            //       date: Date.now(),
            //       isNotHandle: tradable === 0 || tradable === null ? true : false,
            //       isBuy: buysell_flag === 0 || buysell_flag === 3 ? true : false,
            //       isSell: buysell_flag === 0 || buysell_flag === 2 ? true : false,
            //     };
            //     if (arrSearchHistory.length === 0 || targetIdx === -1) {
            //       // change to store only 6 stocks
            //       while (arrSearchHistory.length > 5) {
            //         arrSearchHistory.pop();
            //       }
            //       arrSearchHistory.push(newRic);
            //     } else {
            //       arrSearchHistory[targetIdx] = newRic;
            //     }
            //     arrSearchHistory.sort((a, b) => b.date - a.date);
            //     localStorage.setItem(
            //       "arrSearchHistory",
            //       JSON.stringify(arrSearchHistory)
            //     );
            //   } catch (err: any) {
            //     // if (err.stack.indexOf("have-undefined") !== -1) {
            //     //have undefined in return data, not to store it and exit
            //     // }
            //   }
            // if (refRecentViewedRic.current !== data[0].ric) {
            //   axios.get("smbcapi/recentapi/add", {
            //     params: { ric: data[0].ric },
            //   });
            //   refRecentViewedRic.current = data[0].ric;
            //   if (isEQTY) {
            //     refRecentViewedStockRic.current = data[0].ric;
            //   }
            // }
          }
          // }
        });
    }
  };

  const convertDateTime = (dt) => {
    let date =
      dt.substring(5, 7) +
      "/" +
      dt.substring(8, 11) +
      " " +
      dt.substring(11, 16);
    return date;
  };

  const convertDateTime2 = (dt) => {
    let date = "-";
    if (dt !== undefined && dt !== "-")
      date = dt.substring(5, 7) + "/" + dt.substring(8, 11);
    return date;
  };

  const onDropdownTypeChange = (e) => {
    //console.log(stockRic);
    //console.log(code);
    if (e.target.value === "1") {
      setIsEQTYIDX(false);
      setIsEQTY(true);
      setIsSECTOR(false);
      // call api when dropdown is back to stock
      if (stockRic !== "") {
        setCode(stockRic);
      }
    } else if (e.target.value === "2") {
      setIsEQTYIDX(true);
      setIsEQTY(false);
      setIsSECTOR(false);
      setCode(objStoredIndices.value);
    } else {
      setIsEQTYIDX(false);
      setIsEQTY(false);
      setIsSECTOR(true);
      setCode(objStoredSector.value);
    }
  };

  const onCompareSelectChange = (code) => {
    setCode(code.value);
    setCC(code.flag);
    setSelectedRic(code.value);
  };
  const onCompareSelectChangeIndices = (code) => {
    setObjStoredIndices({ value: code.value, flag: code.flag });
  };
  const onCompareSelectChangeSector = (code) => {
    setObjStoredSector({ value: code.value, flag: code.flag });
  };

  const onButtonClick = () => {
    window.close();
  };

  const onClickZoom = () => {
    setIsChartZoom(!isChartZoom);
    setIsChartRotated((props.isTablet || props.isMobile) ? !isChartZoom : false);
  };
  const onClickCloseZoom = () => {
    setIsChartZoom(false);
    setIsChartRotated(false);
  };

  const onPeriodChange = (period) => {
    setPeriod(period.period);
    setPeriodOption(period.option);
    setLocalChartSetting({ ...localChartSetting, period: period.period, periodOption: period.option })
  };

  // date: Date, type: any
  const onDatePickerClick = (date, type) => {
    setIsDatePicker(true);
    setSelectedDate(date);
    setDateType(type);
  };

  // date: Date
  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const onDatePickerClose = () => {
    setIsDatePicker(false);
  };

  const onColorPickerClose = () => {
    setIsColorPicker(false);
    setMenuColorPickerClose(menuColorPickerClose + 1);
    if (
      colorType === "drawLine" ||
      colorType === "drawRetracement" ||
      colorType === "drawFan" ||
      colorType === "drawArcs"
    ) {
      setIsChartDrawColorPickerOpen(false);
    }
  };

  const colorPickerOpen = (color, type, title) => {
    if (!isColorPicker) {
      if (
        type === "drawLine" ||
        type === "drawRetracement" ||
        type === "drawFan" ||
        type === "drawArcs"
      ) {
        setIsChartDrawColorPickerOpen(true);
      }
      setColorType(type);
      setColor(color);
      setTitle(title);
      setIsColorPicker(true);
    } else {
      if (colorType === type) {
        if (
          type === "drawLine" ||
          type === "drawRetracement" ||
          type === "drawFan" ||
          type === "drawArcs"
        ) {
          setIsChartDrawColorPickerOpen(false);
        }
        setIsColorPicker(false);
      } else {
        if (
          type === "drawLine" ||
          type === "drawRetracement" ||
          type === "drawFan" ||
          type === "drawArcs"
        ) {
          setIsChartDrawColorPickerOpen(true);
        }
        setColorType(type);
        setTitle(title);
        setColor(color);
        setIsColorPicker(true);
      }
    }
    setSelectedColor("");
  };

  const onChartTypeChange = (value) => {
    setChartType(value.type);
    setOption(value.option);
    switch (value.type) {
      case 'line':
        period === "1min" ? saveChartType(value, "chartType-1min", "lineOption") :
          saveChartType(value, "chartType", "lineOption")
        break;
      case 'candlestick':
        period === "1min" ? saveChartType(value, "chartType-1min", "candlestickOption") :
          saveChartType(value, "chartType", "candlestickOption")
        break;
      case 'bar':
        period === "1min" ? saveChartType(value, "chartType-1min", "barOption") :
          saveChartType(value, "chartType", "barOption")
        break;
      case 'histogram':
        period === "1min" ? saveChartType(value, "chartType-1min", "histogramOption") :
          saveChartType(value, "chartType", "histogramOption")
        break;
      default:
        break;
    }
  };

  const saveChartType = (value, period, type) => {
    const chartSetting = { ...localChartSetting };
    chartSetting[period] = value.type;
    chartSetting[type] = value.option;
    setLocalChartSetting(chartSetting)
  }

  const onDrawToolChange = (value) => {
    setDrawTool(value.type);
    setDrawToolOption(value.option);
    setLocalChartSetting({ ...localChartSetting, drawToolOption: value.option })
  };

  const onTApaneChange = (value) => {
    try {
      setTapane(value);
      if (!loading) {
        let chartSetting = { ...localChartSetting };
        value.forEach((item) => {
          let property = `${item.ta.replace('-', '')}_Option`;
          chartSetting[property] = {
            option: item.option ? item.option : undefined,
            value: item.value ? item.value : undefined
          } || { ...localChartSetting };
        });
        period === "1min" ? chartSetting['tapane-1min'] = value :
          chartSetting['tapane'] = value;
        setLocalChartSetting(chartSetting)
      }
    } catch { }
  };

  const onOverlayChange = (value) => {
    try {
      setOverlay(value);
      if (!loading) {
        let chartSetting = { ...localChartSetting };
        value.forEach((item) => {
          let property = `${item.ta.replace('-', '')}_Option`;
          chartSetting[property] = {
            option: item.option ? item.option : undefined,
            value: item.value ? item.value : undefined
          } || { ...localChartSetting };
        });
        period === "1min" ? chartSetting['overlay-1min'] = value :
          chartSetting['overlay'] = value;
        setLocalChartSetting(chartSetting)
      }
    } catch { }
  };

  const onViewChange = (value) => {
    setShowMainTracker(value.showMainTracker);
    setShowTa(value.showTa);
    setShowCompare(value.showCompare);
    setScrollView(value.showScrollView);
    setCrosshair(value.showCrosshair);
    setShowTracker(value.showTracker);
    setShowToolTips(value.showToolTips);
    setShowDrawTool(value.showDrawTool);
    setShowEvent(value.showEvent);
  };

  const onSettingChange = (value) => {
    setChartSetting(value);
  };

  const onAddComparisonClick = (value) => {
    setCompare(value);
  };

  // color: string
  const onColorChange = (color) => {
    setSelectedColor(color);
  };

  // date: Date
  const onSelectDateChange = (date) => {
    setReturnDate(date);
  };

  const [source, setSource] = useState("");
  // code: string, value: string, rSource: string
  const onPowerSearch = (code, value, rSource) => {
    setSource(rSource);
    if (value !== "") {
      let parm = encodeURIComponent(value);
      axios
        .get("smbcapi/searchapi/search", {
          params: {
            searchORD: 1,
            searchETF: 1,
            searchADR: 1,
            keyword: parm,
            pn: 0,
            rpp: 10,
            //exch: code,
            searchbizsumm: 0,
          },
        })
        .then((res) => {
          if (res.data.result.length === 0) setShowMsg(true);
          else setShowMsg(false);

          setPowerSearchResult(res.data.result);
        });
    } else {
      setPowerSearchResult([]);
      setShowMsg(false);
    }
  };

  // const onResultClick = (item: any) => {
  //   setCode(item.ric);
  //   setStockRic(item.ric); // store in this component no matter the dropdown is changed
  //   setPowerSearchResult([]);
  // };

  const onPrintChartClick = () => {
    chartRef.current.printChart();
  };

  const printChart = (img, imgW, imgH, mainTooltip, color, taList) => {
    let w = imgW + 10;
    let h = imgH + 20;

    let popup = window.open(
      "",
      "chart5PrintPopUp",
      `width=${w},height=${h},scrollbars=yes,resizable=no`
    );
    popup.document.body.innerHTML =
      "<html><head><title>" +
      "印刷 - " +
      nm +
      '</title></head><body><div><button id="printButton" class="actionbutton" style="float: right;">印刷</button></div><div style="margin-left: 8px; font-size: 12px; font-weight: bold; position: relative; top: 10px; z-index: -1">' +
      nm +
      '</div><img src="' +
      img +
      '" style="width:100%;top:10px;position:relative;"/></body></html>' +
      '<div id="disclaimer" style="font-size: 12px; padding: 10px; margin-top: 20px"><div>© 2022 Refinitiv All Rights Reserved.</div><br/>' +
      "<div>この情報は、リフィニティブから情報の提供を受けております。</div><br/>" +
      '<div>Refinitiv（リフィニティブ）が事前に書面により承認した場合を除き、リフィニティブのコンテンツを再発行や再配布すること（フレーミングまたは類似の方法による場合を含む）は、明示的に禁止されています。Refinitiv（リフィニティブ）およびRefinitiv（リフィニティブ）のロゴは、リフィニティブ及びその関連会社の登録商標であり、商標となっています。リフィニティブのその他のサービスに関する詳細をお知りになりたい場合は、リフィニティブのパブリック・ウェブサイト<a href="https://www.refinitiv.com" target="blank">http://www.refinitiv.com</a>をご覧ください。</div><br/>' +
      "<div>リフィニティブが提供するコンテンツ（本コンテンツ）はあくまでも個人への情報の提供を目的としたものであり、商用目的のために提供されているものではありません。また、投資活動を勧誘又は誘引するものではなく、取引又は売買を行う際の意思決定の目的で使用することは適切ではありません。本コンテンツは投資助言となる投資、税金、法律等のいかなる助言も提供せず、また、特定の金融の個別銘柄、金融投資あるいは金融商品に関するいかなる勧告もしません。本コンテンツの使用は、資格のある投資専門家の投資助言に取って代わるものではありません。本コンテンツは時間の経過により不正確となる場合があり、従ってヒストリカル情報としてのみ解釈されるべきであります。投資を検討中の利用者は、投資に関する契約を締結する前に、自ら、資格のある投資専門家に相談したうえで、投資をされるべきであります。投資行為は、その性質上、損失を帰結する場合があります。リフィニティブも第三者コンテンツ・プロバイダーも、明示又は黙示を問わず、提供された本コンテンツの正確性又は目的適合性に関する保証をすべて明示的に排除し、本コンテンツの誤謬・不正確や遅延、又はそれらに依拠してなされた行為について、何らの責任も負うものではありません。</div></div>";

    popup.document
      .getElementById("printButton")
      .addEventListener("click", () => {
        popup.document.getElementById("printButton").style.display = "none";
        //popup.document.getElementById("disclaimer").style.display = "none";
        popup.print();
        popup.document.getElementById("printButton").style.display = "block";
        //popup.document.getElementById("disclaimer").style.display = "block";
        //handlePrintClick(popup);
      });
  };

  const handlePrintClick = (popup) => {
    const w = window.open();
    if (popup.document.body.innerHTML) {
      w?.document.write(popup.document.body.innerHTML);
      w?.print();
    }
    w?.close();
  };

  // const onModeChange = (id: number) => {
  //   setMode(id);
  //   if (id === 1) {
  //     setIsDarkMode(false);
  //   } else {
  //     setIsDarkMode(true);
  //   }
  // };

  const onChartDrawToolChange = () => {
    setDrawTool("move");
  };

  const updateDimensions = () => {
    if (chartRef.current) chartRef.current.resizeChart("");
  };

  const resetAnnotation = () => {
    if (chartRef.current) chartRef.current.clearALL();
  };

  const trendlineObjDefocus = (obj) => { };

  const trendlineObj = (obj) => { };

  const onEventChange = (type) => {
    setEventType(type);
  };

  // ric: string
  const indicesDP = (ric) => {
    switch (ric) {
      case ".DJI":
      case ".SPX":
      case "JPY=X":
        return 2;
      case ".IXIC":
        return 3;
      case "US10YT=XX":
      case "EUR=X":
      case "XAU=X":
      case "XAG=X":
      case ".TRCCRB":
        return 4;
      default:
        return 4;
    }
  };

  // ric: string
  const indicesSpecialDefaultValue = (ric) => {
    switch (ric) {
      case ".DJI":
      case ".SPX":
      case "JPY=X":
        return "±0.00";
      case ".IXIC":
        return "±0.000";
      case "US10YT=XX":
      case "EUR=X":
      case "XAU=X":
      case "XAG=X":
      case ".TRCCRB":
        return "±0.0000";
      default:
        return "±0.0000";
    }
  };

  const [colorSource, setColorSource] = useState("");
  const changeColorSource = (source) => {
    setColorSource(source);
  };

  const formatNumber = (num, ta, digit) => {
    if (num === undefined || num === null) return num;
    let dp = null;
    const isMA =
      ta === "SMA" ||
      ta === "WMA" ||
      ta === "EMA" ||
      ta === "MEMA" ||
      ta === "TVMA" ||
      ta === "price";
    switch (code) {
      case ".DJI":
      case ".SPX":
      case "JPY=X":
        dp = 2;
        break;
      case ".IXIC":
        dp = 3;
        break;
      case "US10YT=XX":
      case "EUR=X":
      case "XAU=X":
      case "XAG=X":
      case ".TRCCRB":
        dp = 4;
        break;
      default:
        dp = null;
        break;
    }
    let realNum =
      typeof num === "string" ? parseFloat(num.replace(/,/g, "")) : num;
    return realNum === undefined || realNum === null
      ? realNum
      : dp === null || !isMA
        ? addComma(
          realNum.toFixed(
            CompareList.SECTOR.findIndex(
              (item) => item.value === code
            ) === -1
              ? digit !== null && digit !== undefined ? digit : 2
              : 2
          )
        )
        : addComma(realNum.toFixed(dp));
  }

  const addComma = (num) => {
    let split = num.split(".");
    split[0] = Number(split[0]).toLocaleString();
    return split[1] !== undefined ? split[0] + "." + split[1] : split[0];
  }

  const onChartZoomInClick = () => {
    chartRef.current.zoomIn()
  }

  const onChartZoomOutClick = () => {
    chartRef.current.zoomOut()
  }

  const loadlocalChartSetting = (setting) => {
    try {
      setLocalChartSetting(setting)
      // setPeriod(setting.period)
      // setPeriodOption(setting.periodOption)
    } catch (err) { }
  }

  return (
    <div
      className={[
        "TechnicalChart",
        isChartZoom ? "zoom" : "",
        props.isTablet ? "tablet" : "",
        props.isMobile ? "mobile" : "",
      ].join(" ")}
      style={{
        backgroundColor: props.ChgThemeData == "Light" ? "#ffffff" : "#222429",
      }}
    >
      {/* <div className="headerContainer">
        <div className="leftContainer">
          {isDarkMode ? (
            <LogoBlack
              style={{
                width: "106px",
                height: "30px",
                margin: "auto",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
          ) : (
            <Logo
              style={{
                width: "106px",
                height: "30px",
                margin: "auto",
                marginLeft: "10px",
                marginRight: "10px",
                background: "#FFFFFF",
              }}
            />
          )}
          <div className={isDarkMode ? "darkDropdown" : "dropdown"}>
            <Dropdown
              onChange={onDropdownTypeChange}
              isDarkMode={isDarkMode}
              options={[
                { label: "株式", value: "1" },
                { label: "指数・指標", value: "2" },
                { label: "業種", value: "3" },
              ]}
              defaultValue={query.get("ind")}
            />
          </div>
          <div
            className={isDarkMode ? "darkDropdown" : "dropdown"}
            style={{ marginLeft: "5px" }}
          >
            <div style={isEQTYIDX ? { width: "200px" } : { display: "none" }}>
              <CompareDropdown
                defaultValue={objStoredIndices.value}
                // selectedValue={objStoredIndices.value}
                isDarkMode={isDarkMode}
                isHeader={true}
                onSelectChange={(code: any) => {
                  onCompareSelectChange(code);
                  onCompareSelectChangeIndices(code);
                }}
                compareList={CompareList.RIC}
              />
            </div>
            <div style={isSECTOR ? { width: "285px" } : { display: "none" }}>
              <CompareDropdown
                defaultValue={objStoredSector.value}
                isDarkMode={isDarkMode}
                isHeader={true}
                onSelectChange={(code: any) => {
                  onCompareSelectChange(code);
                  onCompareSelectChangeSector(code);
                }}
                compareList={CompareList.SECTOR}
              />
            </div>
            <div style={isEQTY ? { width: "300px" } : { display: "none" }}>
             
              <InputBoxRICSearch
                id="chart-ric-search"
                style={
                  isDarkMode
                    ? {
                        border: "1px solid #dddde2",
                        backgroundColor: "black",
                        color: "white",
                      }
                    : { border: "1px solid #dddde2" }
                }
                value={code_symbol}
                onChange={(symbol) => {
                  setCode_symbol(symbol);
                }}
                onKeyPressEnter={() => {
                  //console.log("temp_code");
                  //console.log(temp_code);
                  if (temp_code !== "") {
                    setCode(temp_code);
                    setStockRic(temp_code);
                    setIsSymbolSearch(false);
                  }
                  // getQuote(false);

                  // if (temp_code !== "") {
                  //   setCode(temp_code);
                  //   setStockRic(temp_code);
                  //   setIsSymbolSearch(false);
                  // } else {
                  //   setIsSymbolSearch(true);
                  // }
                }}
                onSelected={(ric, type) => {
                  if (type === "click" || type === "highlight") {
                    if (ric !== "") {
                      setCode(ric);
                      setStockRic(ric);
                      setIsSymbolSearch(false);
                    } else {
                      setIsSymbolSearch(true);
                    }
                  } else {
                    setTemp_code(ric);
                  }
                }}
                placeholder="銘柄名・ティッカー"
                isSearchSummary={false}
                width={250}
                height={30}
              />
            </div>
          </div>
        </div>
        <div className="rightContainer">
  
          <IconHelpButton
            style={{
              margin: "auto",
              cursor: "pointer",
              marginRight: 5,
              width: 80,
            }}
            onClick={() => {
              window.open(`${config.SMBC_URL}${config.HELP_URL.CHART}`);
            }}
          />
        
          <RefinitivFooter />
        
          <div className="closeButton">
            <GeneralButton
              label={"閉じる"}
              id={"close"}
              isActive={true}
              disable={false}
              style={
                isDarkMode
                  ? {
                      flex: 1,
                      margin: "5px",
                      width: "56px",
                      height: "24px",
                      background: "#455A64",
                      border: "none",
                      color: "#FFFFFF",
                    }
                  : {
                      flex: 1,
                      margin: "5px",
                      width: "56px",
                      height: "24px",
                      background: "#FFFFFF",
                      border: "1px solid #B0BEC5",
                    }
              }
              onButtonClick={onButtonClick}
            />
          </div>
        </div>
      </div> */}

      <div
        className="content"
      // style={isDarkMode ? { backgroundColor: "#455a64" } : {}}
      >
        <div className="container">
          {isChartRotated ?
            <div>
              <b><span className={props.ChgThemeData == 'Light' ? APPstyles.color_009E9C : APPstyles.color_blue}>{nm}</span></b>
              &nbsp;&nbsp;&nbsp;
              <span className={Number(streamingData&&streamingData['NETCHNG_1'])>0?APPstyles.color_red:Number(streamingData&&streamingData['NETCHNG_1'])<0?APPstyles.color_green:Number(streamingData&&streamingData['NETCHNG_1'])==0?APPstyles.color_grey:props.ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                <b>{common.transThousands(common.keepNum(streamingData&&streamingData['TRDPRC_1'],2))}</b>
              </span> &nbsp;
              <img 
                        src={Number(streamingData&&streamingData['NETCHNG_1'])>0?upImg:downImg} 
                        style={{
                            width:13.86,
                            height:12,
                            cursor:'default',
                            'margin-right':'10px',
                            display:Number(streamingData&&streamingData['NETCHNG_1'])>0||String(streamingData&&streamingData['NETCHNG_1'])<0?'':'none'
                        }} 
                    />
              <span className={Number(streamingData&&streamingData['NETCHNG_1'])>0?APPstyles.color_red:Number(streamingData&&streamingData['NETCHNG_1'])<0?APPstyles.color_green:props.ChgThemeData=='Light'?APPstyles.color_grey:Number(streamingData&&streamingData['NETCHNG_1'])==0?APPstyles.color_grey:APPstyles.color_white}>
                <b>{common.addplusNum(common.transThousands(common.keepNum(streamingData&&streamingData['NETCHNG_1'],2)))}</b></span>&nbsp;{" "}

              <span className={Number(streamingData&&streamingData['PCTCHNG'])>0?APPstyles.color_red:Number(streamingData&&streamingData['PCTCHNG'])<0?APPstyles.color_green:props.ChgThemeData=='Light'?APPstyles.color_grey:Number(streamingData&&streamingData['PCTCHNG'])==0?APPstyles.color_grey:APPstyles.color_white}>
                <b>{common.changeRate(common.addplusNum(common.transThousands(common.keepNum(streamingData&&streamingData['PCTCHNG'],2))))}</b></span>{" "}
            </div> : ""}
          <div
            className={[
              "chartHeader",
              props.isMobile || isMobileViewInSector ? "mobile" : "",
              props.ChgThemeData === "Light" ? "light" : "",
            ].join(" ")}
          >
            {/* <div className="chartContent">
              <div className="content-top">
                <div className="ricName">
                  {quote !== undefined ? quote.name : ""}
                </div>
                <div className="marketName">
                  {quote !== undefined
                    ? quote.market
                      ? quote.market
                      : ""
                    : ""}
                </div>
              </div>
              <div>
                <span className="title">
                  {quote.ric === "US10YT=XX" ? "利回り" : "現在値"}
                </span>
                <span className="value">
                  <NumberFormat
                    value={quote.last}
                    defaultValue={"-"}
                    displayType={"text"}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={
                      CompareList.SECTOR.findIndex(
                        (item) => item.value === quote.ric
                      ) === -1
                        ? indicesDP(quote.ric)
                        : 2
                    }
                    isZeroDefaultValue={quote.isClosingRun}
                  />
                </span>
                <span className="title">{"前日比"}</span>
                <span
                  className={
                    "prevValue" +
                    (quote.change > 0
                      ? " upval"
                      : quote.change < 0
                      ? " downval"
                      : "")
                  }
                >
                  <NumberFormat
                    value={quote.change}
                    defaultValue={"-"}
                    specialDefaultValue={
                      CompareList.SECTOR.findIndex(
                        (item) => item.value === quote.ric
                      ) === -1
                        ? indicesSpecialDefaultValue(quote.ric)
                        : "±0.00"
                    }
                    displayType={"text"}
                    prefix={quote.change > 0 ? "+" : ""}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={
                      CompareList.SECTOR.findIndex(
                        (item) => item.value === quote.ric
                      ) === -1
                        ? indicesDP(quote.ric)
                        : 2
                    }
                    isZeroDefaultValue={quote.isClosingRun}
                    isZeroSpecialDefaultValue={!quote.isClosingRun}
                  />
                </span>
                <span
                  className={
                    "prevPercent" +
                    (quote.change > 0
                      ? " upval"
                      : quote.change < 0
                      ? " downval"
                      : "")
                  }
                >
                  <NumberFormat
                    value={quote.percentage}
                    defaultValue={"-"}
                    specialDefaultValue={
                      quote.change === 0
                        ? "±0.00%"
                        : quote.change > 0
                        ? "+0.00%"
                        : "-0.00%"
                    }
                    displayType={"text"}
                    prefix={quote.percentage > 0 ? "+" : ""}
                    suffix={"%"}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    isZeroDefaultValue={quote.isClosingRun}
                    isZeroSpecialDefaultValue={!quote.isClosingRun}
                  />
                </span>
                <span className="title">{quote.dt}</span>
                {(code === "JPY=X" ||
                  code === "EUR=X" ||
                  code === "US10YT=XX" ||
                  code === "XAG=X" ||
                  code === "XAU=X" ||
                  compare.find(
                    (item) =>
                      item.compare.value === "JPY=X" ||
                      item.compare.value === "EUR=X" ||
                      item.compare.value === "US10YT=XX" ||
                      item.compare.value === "XAG=X" ||
                      item.compare.value === "XAU=X"
                  )) &&
                  periodOption === "" && (
                    <span className="title" style={{ marginLeft: 10 }}>
                      ※日本時間表記となる指数が選択されております。ご注意ください。
                    </span>
                  )}
              </div>
            </div> */}
            {/* <div className="time-interval"></div> */}
            <div className="menu" style={isChartRotated && props.isTablet ? { width: '90%' } : {}}>
              <Menu
                token={encodeURIComponent(query.get("token"))}
                code={code}
                cc={cc}
                localChartSetting={localChartSetting}
                drawTool={drawTool}
                period={period}
                periodOption={periodOption}
                selectedColor={selectedColor}
                selectedDate={returnDate}
                powerSearchResult={powerSearchResult}
                menuColorPickerClose={menuColorPickerClose}
                onPowerSearch={onPowerSearch}
                onComparePowerSearch={onPowerSearch}
                colorPickerOpen={colorPickerOpen}
                onColorPickerClose={onColorPickerClose}
                onPeriodChange={onPeriodChange}
                onDatePickerClick={onDatePickerClick}
                onDatePickerClose={onDatePickerClose}
                onChartTypeChange={onChartTypeChange}
                onDrawToolChange={onDrawToolChange}
                onTApaneChange={onTApaneChange}
                onOverlayChange={onOverlayChange}
                onAddComparisonClick={onAddComparisonClick}
                onViewChange={onViewChange}
                onSettingChange={onSettingChange}
                onPrintChartClick={onPrintChartClick}
                showMsg={showMsg}
                setShowMsg={setShowMsg}
                resetAnnotation={resetAnnotation}
                colorRef={colorRef}
                onDateChange={onDateChange}
                source={source}
                onEventChange={onEventChange}
                colorSource={colorSource}
                setSelectedColor={setSelectedColor}
                chartRef={chartRef}
                ChgThemeData={props.ChgThemeData}
                ChgLangData={props.ChgLangData}
                t={t}
                onClickZoom={onClickZoom}
                onChartZoomInClick={onChartZoomInClick}
                onChartZoomOutClick={onChartZoomOutClick}
                isChartZoom={isChartZoom}
                isTablet={props.isTablet}
                isMobile={props.isMobile}
                isMobileViewInSector={isMobileViewInSector}
                setLocalChartSetting={setLocalChartSetting}
                setLoading={setLoading}
                setIsReset={setIsReset}
              />
            </div>
          </div>
          {ohlcTable}
          <div
            id="chart"
            className="chart"
            style={{ backgroundColor: "#" + BACKGROUND_COLOR }}
            onMouseLeave={(e) => { e.preventDefault(); setOHLCTable(<div></div>) }}
            onTouchEnd={(e) => { e.preventDefault(); setOHLCTable(<div></div>) }}
          >
            {token !== "" && (
              <Chart5
                ref={chartRef}
                token={encodeURIComponent(token)}
                code={code}
                // perm={quote.perm}
                nm={nm}
                last={last}
                info={info}
                enableMainTooltip={true}
                enableScrollPane={true}
                isSrcollViewOpen={scrollView}
                Crosshair={crosshair}
                showMainTracker={showMainTracker}
                showTa={showTa}
                showDrawTool={showDrawTool}
                showCompare={showCompare}
                showTracker={showTracker}
                showToolTips={showToolTips}
                showEvent={showEvent}
                eventType={eventType}
                compare={compare}
                type={chartType}
                option={option}
                drawTool={drawTool}
                drawToolOption={drawToolOption}
                chartSetting={chartSetting}
                overlay={overlay}
                tapane={tapane}
                period={period}
                periodOption={periodOption}
                domain={""}
                selectedColor={selectedColor}
                isChartDrawColorPickerOpen={isChartDrawColorPickerOpen}
                printChart={printChart}
                drawToolChange={onChartDrawToolChange}
                colorPickerOpen={colorPickerOpen}
                //onColorPickerClose={onColorPickerClose}
                trendlineObj={trendlineObj}
                trendlineObjDefocus={trendlineObjDefocus}
                setColorSource={changeColorSource}
                ChgThemeData={props.ChgThemeData}
                ChgLangData={props.ChgLangData}
                t={t}
                isTablet={props.isTablet}
                isMobile={props.isMobile}
                isMobileViewInSector={isMobileViewInSector}
                callbackIsAllowRefreshUpdateChart={(bool) => {
                  setIsAllowRefreshUpdateChart(false);
                }}
                onFinishLoading={props.onFinishLoading}
                setOHLCTable={setOHLCTable}
                isChartRotated={isChartRotated}
              />
            )}
          </div>
          {/* <div className="statusbar">
            <div
              className="statusball"
              style={
                status
                  ? { backgroundImage: `url(${green})` }
                  : { backgroundImage: `url(${red})` }
              }
            ></div>
            <div className="statusmsg"></div>
          </div> */}
        </div>
      </div>
      {isColorPicker && (
        <ColorPicker
          ref={colorRef}
          color={color}
          title={title}
          closeClick={onColorPickerClose}
          onColorChange={onColorChange}
          ChgThemeData={props.ChgThemeData}
        />
      )}
      {/* {isDatePicker && (
        // <DatePicker
        //   type={dateType}
        //   selectedDate={selectedDate}
        //   onSelectDateChange={onSelectDateChange}
        //   onCloseClick={onDatePickerClose}
        // />
        <Calendar
          fullscreen={false}
          value={moment(selectedDate.replace(/\//g, "-"))}
          // locale={props.ChgLangData == "zh-CN" ? locale : ""}
          onSelect={(date) => {
            console.log("Calendar");
            console.log(date);
          }}
        />
      )} */}
      {isDatePicker && (
        <div className="calendar-container">
          <div
            className="mask"
            onClick={() => {
              onDatePickerClose();
            }}
          ></div>
          <div
            className={[
              "calendar_pick",
              props.isMobile ? "mobile" : "",
              props.ChgThemeData === "Light" ? "calendar_pick_light" : "",
            ].join(" ")}
          >
            <Calendar
              fullscreen={false}
              value={moment(selectedDate.replace(/\//g, "-"))}
              locale={props.ChgLangData == "zh-CN" ? locale : ""}
              onSelect={(date) => {
                onSelectDateChange(date.toDate());
                onDatePickerClose();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TechnicalChart.propTypes = {
  store: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  ChgThemeData: PropTypes.string.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  triggerCloseChartZoom: PropTypes.number.isRequired,
  onFinishLoading: PropTypes.func.isRequired,
};

export default TechnicalChart;
