import React from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import APPstyles from "@/App.less";
import styles from "./index.less";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: this.props.currentIndex ? this.props.currentIndex : 0,
      tabsData: [],
      disableIndex2:
        this.props.disableIndex2 !== undefined
          ? this.props.disableIndex2
          : true,
    };
  }
  //tab栏切换
  tabClick(index) {
    this.setState({
      tabIndex: index,
    });
    this.props.callbackTabIndex(index);
  }
  //处理数据
  onMouseFun(index, boolean) {
    const { bodyW, minbodyW } = this.props;
    const { tabsData } = this.state;
    if (bodyW < minbodyW) return;
    for (let i in tabsData) {
      if (i == index) {
        tabsData[i]["isShow"] = boolean;
      }
    }
    this.setState({
      tabsData: tabsData,
    });
  }
  // 监听组件传递的值
  componentDidUpdate(newProps) {
    const {
      tabData,
      chgTabData,
      currentIndex,
      tag,
      isDetailPageShow,
      bodyW,
      mobilebodyW,
    } = this.props;
    //更改tab的数据
    // if(chgTabData!==newProps['chgTabData']){
    if (!isEqual(tabData, newProps["tabData"])) {
      if (!tabData) return;
      this.setState({
        tabsData: tabData,
      });
    }
    if (tag == "quote" && currentIndex !== newProps["currentIndex"]) {
      this.setState({
        tabIndex: currentIndex,
      });
    }
    if (
      tag == "detail" &&
      isDetailPageShow !== newProps["isDetailPageShow"] &&
      bodyW <= mobilebodyW
    ) {
      this.setState({
        tabIndex: 0,
      });
    }
  }
  //初始化
  componentDidMount() {
    const { tabData, tag } = this.props;
    if (tag == "quote") {
      let tabDataAll = window.localStorage.getItem("tabDataAll");
      if (tabDataAll) {
        this.setState({
          tabsData: JSON.parse(tabDataAll),
        });
        return;
      }
      this.setState({
        tabsData: tabData,
      });
      return;
    }
    this.setState({
      tabsData: tabData,
    });
  }
  render() {
    const { t, tag, ChgThemeData, chgTabNumData, bodyW, mobilebodyW,isMobile } =
      this.props;
    const { tabsData, tabIndex } = this.state;
    return (
      <div
        className={[
          APPstyles.flex_row,
          styles.tabs_con,
          tag == "quote" || tag == "account"
            ? ""
            : ChgThemeData == "Light"
            ? APPstyles.borderBottom_light
            : APPstyles.borderBottom_dark,
        ].join(" ")}
        style={{
          paddingRight: tag == "quote" ? 0 : 16,
          paddingLeft: tag == "account" ? 0 : 16,
        }}
      >
        <div
          className={[
            APPstyles.flex_row,
            styles.tab_one,
            tag == "detail" ? styles.tab_span : "",
            isMobile?ChgThemeData=='Dark'?APPstyles.fontSize16_colorWhite:APPstyles.fontSize16_colorDark:''
          ].join(" ")}
        >
          {tabsData.map((item, index) => {
            return (
              <div key={index}>
                {/* return  <div key={index} className={tag=='quote'&&index==2?APPstyles.display_none:''}> */}
                <span
                  className={[
                    styles.tab_seatnot,
                    tag == "quote" ? styles.quote : "",
                    index === tabIndex || item["isShow"] ? styles.active : "",
                    (tag == "quote" || tag == "account") && bodyW <= mobilebodyW
                      ? APPstyles.fz16
                      : "",
                  ].join(" ")}
                  onClick={this.tabClick.bind(this, index)}
                  onMouseOver={() => {
                    this.onMouseFun(index, true);
                  }}
                  onMouseOut={() => {
                    this.onMouseFun(index, false);
                  }}
                >
                  {tag == "account" ? (
                    <label>
                      {t(item["name"])}
                      {index == 0
                        ? "(" + chgTabNumData[0] + ")"
                        : index == 1
                        ? "(" + chgTabNumData[1] + ")"
                        : index == 2
                        ? "(" + chgTabNumData[2] + ")"
                        : ""}
                    </label>
                  ) : (
                    t(item["name"])
                  )}
                </span>
                <span
                  className={[
                    styles.tab_seat,
                    (tag == "quote" || tag == "account") && bodyW <= mobilebodyW
                      ? APPstyles.fz16
                      : "",
                  ].join(" ")}
                >
                  {tag == "account" ? (
                    <label>
                      {t(item["name"])}
                      {index == 0
                        ? "(" + chgTabNumData[0] + ")"
                        : index == 1
                        ? "(" + chgTabNumData[1] + ")"
                        : index == 2
                        ? "(" + chgTabNumData[2] + ")"
                        : ""}
                    </label>
                  ) : (
                    t(item["name"])
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default withTranslation()(observer(Tabs));
