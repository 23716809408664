import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '../../../../asserts/js/common'
import Pie from '../../../../asserts/js/charts/pie'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class PieCon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    const {t,isPC,ChgThemeData,id,stockLData,stockRData,isTablet,isMobile,isSearchPageShow,isPieShow} = this.props
    return (
      <div
        className={[
          (isTablet&&!isMobile&&isSearchPageShow)||isMobile?APPstyles.flex_column:APPstyles.flex_row,
          APPstyles.flex_1,
          APPstyles.wPer100,
          styles.pie_con
        ].join(' ')}
        style={{
          position:isMobile?'unset':'relative',
          bottom:isPC&&!isSearchPageShow?0:'unset',
          marginBottom:isTablet&&!isMobile?
                        isSearchPageShow&&!stockLData.length?0:15
                      :isMobile?
                        25
                      :
                      isSearchPageShow?10:18,     
          marginTop:isMobile?15:isTablet&&!isMobile?6:0,
          display:isMobile?isPieShow?'':'none':'',
          alignItems:(isTablet&&!isMobile&&!isSearchPageShow)||(isPC)?'center':'flex-start'
        }}
      >
        <div
          className={(isTablet&&!isMobile&&isSearchPageShow)||isMobile?APPstyles.marginauto:''} 
          style={{
            marginLeft:(isPC&&stockLData.length&&!isSearchPageShow)?22:(isTablet&&!isMobile&&!isSearchPageShow&&stockLData.length)?15:0,
            position:'relative',
            left:isPC?
                  isSearchPageShow?
                    stockLData.length?0:0
                  :
                    stockLData.length?-10:12
                :isTablet&&!isMobile&&!stockLData.length&&!isSearchPageShow?
                  15
                :
                  'unset'
          }}
        >
          <Pie id={id} {...this.props} />
        </div>
        <span 
          className={styles.no_Stock} 
          style={{
            display:stockLData.length?'none':'',
            marginTop:(isTablet&&!isMobile&&isSearchPageShow)||isMobile?32:0,
            marginBottom:isPC?
                          isSearchPageShow?0:stockLData.length?20:0
                        :isTablet&&!isMobile?
                          isSearchPageShow?40:0
                        :
                          40
          }}
        >
          {t('No datas')}
        </span>
        <div
          className={[
            APPstyles.flex1_auto,
            (isTablet&&isSearchPageShow)||isMobile?APPstyles.flex_column:APPstyles.flex_row,
            APPstyles.flex_1,
            (isTablet&&!isMobile&&isSearchPageShow)||isMobile?APPstyles.wPer100:''
          ].join(' ')}
          style={{
            display:stockLData.length?'':'none',
            position:'relative',
            top:isTablet&&!isMobile?
                  isSearchPageShow?2:0
                :isPC?
                  isSearchPageShow?-4:-6
                :
                  0,
            marginBottom:isMobile&&isPieShow?20:0,
            marginTop:isMobile?10:0,
            height:isPC||(isTablet&&!isMobile&&!isSearchPageShow)?100:'auto'
          }}
        >
          <div 
            style={{
              width:(isTablet&&!isMobile&&!isSearchPageShow)||isPC?'50%':'100%'
            }}
          >
          {
            stockLData.length?
            stockLData.map((item,index) => {
              return  <div 
                        key={index}
                        className={[
                          APPstyles.flex_between,
                          styles.list,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')}
                        style={{
                          margin:isPC?'0 40px 0 10px':(isTablet&&!isMobile&&isSearchPageShow)||isMobile?'0 16px':'0 30px 0 0',
                        }}
                      >
                        <div
                          className={[
                            styles.list_left,
                            APPstyles.flex_row
                          ].join(' ')}
                        >
                          <span className={styles.list_circle} style={{background:item['bgColor']}}></span>
                          <span className={isMobile?APPstyles.fz13:''}>{t(item['stock_name'])}</span>
                        </div>
                        <span className={[styles.num,isMobile?APPstyles.fz13:''].join(' ')}>
                          {Number(item['ratio_total_amount'])==100?common.changeRate(common.keepNum(item['ratio_total_amount'],0)):common.changeRate(common.keepNum(item['ratio_total_amount'],2))}
                        </span>
                      </div>
              })
            :
              ''
          }
          </div>
          <div 
            className={[
              (isTablet&&!isMobile&&isSearchPageShow)||isMobile?APPstyles.wPer100:APPstyles.wPer50
            ].join(' ')}
          >
          {
            stockRData.length?
            stockRData.map((item,index) => {
              return  <div
                        key={index} 
                        className={[
                          APPstyles.flex_between,
                          styles.list,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                        ].join(' ')}
                        style={{
                          margin:isTablet&&!isMobile?isSearchPageShow?'0 16px':'0 12px 0 0':isMobile?'0 16px':'0 22px 0 0'
                        }}
                      >
                        <div
                          className={[
                            styles.list_left,
                            APPstyles.flex_row
                          ].join(' ')}
                        >
                          <span className={styles.list_circle} style={{background:item['bgColor']}}></span>
                          <span>{t(item['stock_name'])}</span>
                        </div>
                        <span  className={styles.num}>
                        {Number(item['ratio_total_amount'])==100?common.changeRate(common.keepNum(item['ratio_total_amount'],0)):common.changeRate(common.keepNum(item['ratio_total_amount'],2))}
                        </span>
                      </div>
              })
            :
              ''
          }
          </div>  
        </div>      
      </div>
    )
  }
}
export default withTranslation()(PieCon)