import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const EOM = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(9);
  const [method, setMethod] = useState("sma");
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);
  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(method + "," + period);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.EOM_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      props.onTAValueChange({
        ta: "EOM",
        name: `${t("chart5.TA.EOM")} (EOM)`,
        option: {
          period: localChartSetting.option.period,
          method: localChartSetting.option.method,
          color: localChartSetting.option.color,
          thickness: localChartSetting.option.thickness,
          style: localChartSetting.option.style,
          showTracker: localChartSetting.option.showTracker,
          height: localChartSetting.option.height,
        },
        value: localChartSetting.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const EOM = props.localChartSetting.EOM_Option
    setPeriod(parseInt(EOM.option.period) || period);
    setMethod(EOM.option.method);
    setColor(EOM.option.color);
    setThickness(parseInt(EOM.option.thickness) || thickness);
    setStyle(EOM.option.style);
    setShowTracker(EOM.option.showTracker);
    setHeight(EOM.option.height);
  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(method + "," + period);
    props.onTAValueChange({
      ta: "EOM",
      name: `${t("chart5.TA.EOM")} (EOM)`,
      option: {
        period: period,
        method: method,
        color: color,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        height: height,
      },
      value: method + "," + period,
    });
  }, [period, method, color, thickness, style, showTracker, height]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const EOM = props.localChartSetting.EOM_Option
    setPeriod(parseInt(EOM.option.period) || period);
    setMethod(EOM.option.method);
    setColor(EOM.option.color);
    setThickness(parseInt(EOM.option.thickness) || thickness);
    setStyle(EOM.option.style);
    setShowTracker(EOM.option.showTracker);
    setHeight(EOM.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(9);
    setMethod("sma");
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  const onPeriodChange = (e) => {
    setPeriod(e.target.value);
  };
  const onMethodChange = (e) => {
    setMethod(e.target.value);
  };

  const onThicknessChange = (e) => {
    setThickness(e.target.value);
  };

  const onStyleChange = (e) => {
    setStyle(e.target.value);
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "EOM":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "EOM", t("chart5.setting"));
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("EOM")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.EOM")} (EOM) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("EOM")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.period")}</div>

            <div
              className="selectbox taparam"
              onChange={(e) => onMethodChange(e)}
            >
              <select defaultValue={method} value={method}>
                <option value="ema">EMA</option>
                <option value="sma">SMA</option>
                <option value="mema">MEMA</option>
                <option value="wma">WMA</option>
              </select>
            </div>

            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e)}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e)}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e)}>
              <select id="msi-ta-UI-p0-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={onShowTracker}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-FI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select defaultValue="1" onChange={(e) => onHeightChange(e)} value={height}>
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
