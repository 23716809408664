import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './index.less'
import App from './App'
import {Route,BrowserRouter} from 'react-router-dom'

const Root = () => {
  return (
    <BrowserRouter basename='/'>
      <Route path={`/`} component={App}></Route>
    </BrowserRouter>
  )
}
ReactDOM.render(< Root />, document.getElementById('root'))
