import React from 'react'
import { withTranslation } from 'react-i18next'
import { message } from 'antd'
import common from '@/asserts/js/common'
import { 
  quoteOnImg,
  quoteOffImg,
  accountOnImg,
  accountOffImg,
  tradeOnImg,
  tradeOffImg,
  newsOnImg,
  newsOffImg,
  researchOnImg,
  researchOffImg,
  switchOnImg,
  switchOffImg,
  settingOnImg,
  settingOffImg,
  logoutOnImg,
  logoutOffImg,
  menuOnImg,
  menuOffImg,
  quoteWhiteImg,
  accountWhiteImg,
  tradeWhiteImg,
  newsWhiteImg,
  menuWhiteImg,
  campaginOffImg,
  campaginOnImg,
} from '@/asserts/imgs/nav/index'
import Setting from '@/pages/Setting/index'
import TipsModal from '@/components/tipsModal/index'
import SwitchModal from '@/components/switch/index'
import APPstyles from '@/App.less'
import styles from './index.less'
import AlertsCountButton from '@/components/Alerts/components/AlertsCountButton'

let timer=null

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isResizeShow:false,
      navData:[],
      isSlidingPanelShow:true,
      loginName:'',
      isSettingShow:false,
      isLogOutShow:false,
      navDataPC:[
        {
          path:'/Quote',
          name:'Quote',
          imgOn:quoteOnImg,
          imgOff:quoteOffImg,
          isShow:false,
          id:'quote'
        },
        {
          path:'/Account',
          name:'Account',
          imgOn:accountOnImg,
          imgOff:accountOffImg,
          isShow:false,
          id:'account'
        },
        {
          path:'/Trade',
          name:'Trade',
          imgOn:tradeOnImg,
          imgOff:tradeOffImg,
          isShow:false,
          id:'trade'
        },
        {
          path:'/News',
          name:'News',
          imgOn:newsOnImg,
          imgOff:newsOffImg,
          isShow:false,
          id:'news'
        },
        {
          path:'/Research',
          name:'Research',
          imgOn:researchOnImg,
          imgOff:researchOffImg,
          spanColor:'#828282',
          isShow:false,
          id:'research'
        },
        {
          path:'/Campagin',
          name:'Campagin',
          imgOn:campaginOnImg,
          imgOff:campaginOffImg,
          spanColor:'#828282',
          isShow:false,
          id:'Campagin'
        },
        {
          path:'/Switch',
          name:'Switch',
          imgOn:switchOnImg,
          imgOff:switchOffImg,
          spanColor:'#828282',
          isShow:false,
          id:'switch'
        },
        {
          path:'/Settings',
          name:'Settings',
          imgOn:settingOnImg,
          imgOff:settingOffImg,
          isShow:false,
          id:'settings'
        },
        {
          path:'/Logout',
          name:'Logout',
          imgOn:logoutOnImg,
          imgOff:logoutOffImg,
          isShow:false,
          id:'logout'
        }
      ],
      navDataTablet:[
        {
          path:'/Quote',
          name:'Quote',
          imgOn:quoteOnImg,
          imgOff:quoteOffImg,
          isShow:false,
          id:'quote'
        },
        {
          path:'/Account',
          name:'Account',
          imgOn:accountOnImg,
          imgOff:accountOffImg,
          isShow:false,
          id:'account'
        },
        {
          path:'/Trade',
          name:'Trade',
          imgOn:tradeOnImg,
          imgOff:tradeOffImg,
          isShow:false,
          id:'trade'
        },
        {
          path:'/News',
          name:'News',
          imgOn:newsOnImg,
          imgOff:newsOffImg,
          isShow:false,
          id:'news'
        },
        {
          path:'/Menu',
          name:'Menu',
          imgOn:menuOnImg,
          imgOff:menuOffImg,
          isShow:false,
          id:'menu'
        }
      ],
      navDataMobile:[
        {
          path:'/Quote',
          name:'Quote',
          imgOn:quoteOnImg,
          imgOff:quoteOffImg,
          imgWhite:quoteWhiteImg,
          isShow:false,
          id:'quote'
        },
        {
          path:'/Account',
          name:'Account',
          imgOn:accountOnImg,
          imgOff:accountOffImg,
          imgWhite:accountWhiteImg,
          isShow:false,
          id:'account'
        },
        {
          path:'/Trade',
          name:'Trade',
          imgOn:tradeOnImg,
          imgOff:tradeOffImg,
          imgWhite:tradeWhiteImg,
          isShow:false,
          id:'trade'
        },
        {
          path:'/News',
          name:'News',
          imgOn:newsOnImg,
          imgOff:newsOffImg,
          imgWhite:newsWhiteImg,
          isShow:false,
          id:'news'
        },
        {
          path:'/Menu',
          name:'Menu',
          imgOn:menuOnImg,
          imgOff:menuOffImg,
          imgWhite:menuWhiteImg,
          isShow:false,
          id:'menu'
        }
      ],
      chgNavId:'quote',
      idData:['trade','research','settings','logout','menu','switch'],
      isSwitchShow:false,
      campaignVisible:false,
    }
  }
  //slider--切换不同页面
  sliderClick(data){
    const {idData,isSlidingPanelShow,chgNavId}=this.state
    let id=data['id']
    let target=sessionStorage.getItem('target')
    
    if(chgNavId==id&&id!=='menu') return
    if(id=='research'){
      this.getResearchPageFun()
      return
    }
    if(target=='trade'){
      sessionStorage.setItem('target','quote')
    }
    sessionStorage.setItem('navId',id)
    this.props.callbackChgNavIndex(id)
    this.setState({
      chgNavId:id
    })
    if(id=='logout'){
      this.setState({
        isLogOutShow:true
      })
    }
    if(id=='settings'){
      this.setState({
        isSettingShow:true
      })
    }
    if(id=='switch'){
      this.setState({
        isSwitchShow:true
      })
    }
    if(id=='menu'){
      this.setState(state => ({
        isSlidingPanelShow: !state.isSlidingPanelShow,
        isSettingShow:false
      }))
      this.props.callbackShowSlidingPanel(isSlidingPanelShow)
    }
    if(idData.includes(id)) return
    this.props.callbackPathnameApp(data['path'])// 获取路由跳转--app.js
    sessionStorage.setItem('navIdAPP',id)
  }
  onLoadDataFun(){
    const {locationName}=this.props
    const {navDataPC,navDataMobile,navDataTablet}=this.state
    for(let i in navDataPC){
      navDataPC[i]['path']='/'+locationName[1]+navDataPC[i]['path']
    }
    for(let i in navDataTablet){
      navDataTablet[i]['path']='/'+locationName[1]+navDataTablet[i]['path']
    }
    for(let i in navDataMobile){
      navDataMobile[i]['path']='/'+locationName[1]+navDataMobile[i]['path']
    }
    this.setState({
      navDataPC:navDataPC,
      navDataTablet:navDataTablet,
      navDataMobile:navDataMobile,
      chgNavId:'quote'
    })
  }
  // 动态更改navData的数据
  chgNavDataFun(isOnLoad){
    const {isPC,isMobile}=this.props
    const {navDataPC,navDataMobile,navDataTablet}=this.state
    let loginName=sessionStorage.getItem('loginName')
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    if(isOnLoad){
      this.onLoadDataFun()
    }
    let newNavData=isPC?navDataPC:isMobile?navDataMobile:navDataTablet
    this.setState({
      navData:newNavData,
      loginName:loginName
    })
    if(navIdAPP){
      let items = newNavData.find(item => item['id']==navIdAPP)
      this.setState({
        chgNavId:items?items['id']:'Campagin',
      });
      return
    }
    sessionStorage.setItem('navIdAPP','quote') 
  }
  //获取数据--url
  getResearchPageFun(){
    let token=sessionStorage.getItem('token')
    fetch(common.getHttpZs+'getResearchPage',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:token
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        //ios终端
        if(common.isiOS){
          window.location.href=res['url']
          return
        }
        //android终端/pc端
        window.open(res['url'],'_blank')
        return
      }
      //获取失败
      message.warning({
        content: res['message']
      })
    })
  }
  //处理数据
  onMouseFun(data,boolean){
    const {navData}=this.state
    let item = navData.find(item => item['id']==data['id'])
    item['isShow']=boolean
    this.setState({
      navData:navData
    })
  }
  // 判断是否显示
  switchShowFun(){
    let chgTimer=0
    timer = setInterval(()=>{
      let isSwitch=sessionStorage.getItem('isSwitchShow')
      chgTimer++
      if(isSwitch=='Y'){
        clearInterval(timer) 
        this.setState({
          isSwitchShow:true,
          chgNavId:'switch'
        })
        let target=sessionStorage.getItem('target')
        let chgId=target=='trade'?'trade':'switch'
        this.props.callbackChgNavIndex(chgId)
        sessionStorage.setItem('navId',chgId)
        sessionStorage.setItem('isSwitchShow','N')
      }else{
        if(chgTimer==10){
          clearInterval(timer) 
        }
      }
    }, 100)
  }
  // data 为空不显示活动图标
  getApiData(){
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'marketing-campaign',{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(res => {
      if(res['status']==0){
        let data = res['data'];
        const value = (data&&JSON.stringify(data)!=='{}');
        this.setState({
          campaignVisible: value,
        });
        sessionStorage.setItem('campaignVisible',value);
      }
    })
  }
  changeCampaignVisible(){
    const value=sessionStorage.getItem('campaignVisible');
    const newValue = value ? JSON.parse(value): false;
    this.setState({
      campaignVisible: newValue,
    });
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isPC,bodyW,sliderIndex,isSwitchModalShow}=this.props
    //更改chgNavId
    if(sliderIndex!==newProps['sliderIndex']){
      this.setState({
        chgNavId:sliderIndex
      })
    } 
    if(bodyW!==newProps['bodyW']){
      this.chgNavDataFun(false);
      isPC && this.changeCampaignVisible();
    }
    if(isSwitchModalShow!==newProps['isSwitchModalShow']){
      this.setState({
        isSwitchShow:isSwitchModalShow,
      });
    }
  }  
  //初始化
  componentDidMount(){
    const {isPC} = this.props;
    const isLoginOnload=sessionStorage.getItem('isLoginOnload');
    if(isPC && isLoginOnload==='Y'){
      this.getApiData();
    }else{
      isPC && this.changeCampaignVisible();
    }
    this.chgNavDataFun(true);
    this.switchShowFun();
    sessionStorage.removeItem('ChgAddData');
  }
  //卸载
  componentWillUnmount() {
    clearInterval(timer)
  }
  render(){
    const {t,ChgThemeData,isPC,isTablet,isMobile,isShowHeaderPage,ChgLangData} = this.props;
    const {navData,loginName,isSettingShow,isLogOutShow,chgNavId,isSwitchShow,campaignVisible}=this.state;
    return(   
      <div 
        className={[
          styles.slider_con,
          isPC?styles.slider_con_PC:isMobile?styles.slider_con_moblie:styles.slider_con_min,
          ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_dark,
          isPC?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
          isTablet?APPstyles.sticky_bottom_mobile:'',
          isShowHeaderPage?'':APPstyles.display_none
        ].join(' ')}
      >
        <ul 
          className={[
            styles.list,
            isTablet?ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark:''
          ].join(' ')}
        >
          {navData.map((item,index) => {
            let img = item['name'] === "Alerts" ? <AlertsCountButton {...this.props} callbackAlertsShow={this.props.callbackAlertsShow} isTabletMenu disableHover/>:<img src={chgNavId==item['id']||item['isShow']?item['imgOn']:isMobile&&ChgThemeData=='Dark'?item['imgWhite']:item['imgOff']} />
            let onClick = item['name'] === "Alerts" || (item['id']==='Campagin' && !campaignVisible) ? undefined : this.sliderClick.bind(this,item)
            return  <li 
                      key={index} 
                      style={{
                        display:loginName=='notFound'&&index==navData.length-1&&isPC?'none':(item['id']==='Campagin'&&!campaignVisible)?'none':'',
                      }} 
                      onMouseOver={()=>{
                        if(!isPC) return
                        this.onMouseFun(item,true)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        this.onMouseFun(item,false)
                      }}
                      onClick={onClick}
                    >
                      {img}
                      <span style={{color:chgNavId==item['id']||item['isShow']?'#008FC7':isMobile?ChgThemeData=='Dark'?'#E0E0E0':'#333333':'#828282'}}>{t(item['name'])}</span>
                    </li>
          })}
        </ul>
        {isSettingShow&&
          <Setting 
            {...this.props}
            callbackChgNavIndex={(data, isClose)=>{
              this.props.callbackChgNavIndex(data, isClose)
            }}
            callbackSetting={(data)=>{
              this.props.callbackSetting(data)
            }}
            cbCloseSetting={()=>{
              this.setState({
                isSettingShow:false
              })
            }}
          />
        }
        {isLogOutShow&&
          <TipsModal
            {...this.props}
            tag={'logout'}
            cancelText={ChgLangData=='zh-CN'?t('Cancel1'):t('Cancel')}
            sureText={ChgLangData=='zh-CN'?t('OK1'):t('OK')} 
            contentText={'Are you sure you want to logout?'}
            callbackPathnameApp={(data)=>{
              this.props.callbackPathnameApp(data)
            }}
            callbackChgNavIndex={(data, isClose)=>{
              this.props.callbackChgNavIndex(data, isClose)
            }}
            cbCloseLogOout={()=>{
              this.setState({
                isLogOutShow:false
              })
            }}
          />
        }
        {isSwitchShow&&
          <SwitchModal
            {...this.props}
            switchTag='slider'
            cancelText='Cancel'
            sureText='OK'
            switchTitle='Switch Account'
            switchName='Account1'
            cbCloseModal={()=>{
              this.setState({
                isSwitchShow:false
              });
              this.props.cbSwitchMoalShow(false);
            }}
            cbSwitchFun={()=>{
              // 切换账号更新list列表数据---navIdAPP=='quote'
              this.props.cbSwitchFun()
            }}
            cbCloseRightPageFun={()=>{
              // 切换账号成功则关闭trade页面---navIdAPP=='quote'
              this.props.cbCloseRightPageFun()
            }}
            chgAccountFun={()=>{
              // 切换账号成功则重新刷新trade页面里的order数据
              this.props.chgAccountFun()
            }}
          />
        }
      </div>
  )}
}
export default withTranslation()(Slider)