import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '@/asserts/js/common'
import { 
    dropUpOnLightImg,
    dropUpOnImg,
    dropUpImg,
    dropDownImg,
    dropDownOnImg,
    dropDownOnLightImg
} from '@/asserts/imgs/common/index'
import { 
    checkDarkImg,
    checkLightImg
} from '@/asserts/imgs/quote/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class ListCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isScrollLeft:false,
            chgRightW:'100%',
            isScrollHeader:false,
            getLeftConH:'auto',
            isMoreShow:false,
            chgScrollTop:null,
            chgHeaderH:32,
            rightIndex:0,
            leftIndex:0,
            leftHover:null,
            rightHover:null,
            isScrollShow:false,
            min1360:1360
        }
        this.myRef = React.createRef()
        this.myHeaderRef = React.createRef()
        this.myRightbodyRef = React.createRef()
        this.myHeaderConRef = React.createRef()
    }
    // 页面滚动触发的事件
    handleScroll(){
        const {chgScrollTop}=this.state
        let current=this.myRef.current
        let current_1=this.myHeaderConRef.current
        current_1.scrollLeft=current.scrollLeft
        this.setState({
            isScrollLeft:current.scrollLeft==0?false:true,
            isMoreShow:Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 20,
            isScrollShow:current.scrollHeight>current.clientHeight?true:false
        })
        if(current.scrollTop>0){
            if(current.scrollTop==chgScrollTop) return
            if(Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 10){
                this.props.cbChgListDataFun()
            }
            this.setState({
                chgScrollTop:current.scrollTop
            })
        }
    }
    // 页面滚动触发的事件
    handleHeaderXScroll(){
        let current=this.myRef.current
        let current_1=this.myHeaderConRef.current
        current.scrollLeft=current_1.scrollLeft
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isChgResultShow,headerData,bodyW,isPC,isGetResultShow,ChgLangData,isChgDataShow,isMobile,isSortShow,isHasKey,isChgToggleOn} = this.props 
        if(
            bodyW!==newProps['bodyW']||
            isChgResultShow!==newProps['isChgResultShow']||
            isChgToggleOn!==newProps['isChgToggleOn']||
            isChgDataShow!==newProps['isChgDataShow']
        ){
            let getW='100%',len=headerData.length-2
            let current=this.myRef.current
            if(len<6){
                let tabletNum=len==5?25*3+35*(len-3):100
                let isMobileNum=len>3?30*3+45*(len-3):100
                getW=isPC?'100%':isMobile?isMobileNum+'%':tabletNum+'%'
            }else{
                let num=16*3+19*(len-2)+21
                let len6=isChgToggleOn?'100%':isHasKey>1?20*3+24*2+28+'%':'100%'
                let PCNum=len==6?len6:num+'%'
                let tabletNum=25*3+35*(len-3)
                let isMobileNum=30*3+45*(len-3)
                getW=isPC?PCNum:isMobile?isMobileNum+'%':tabletNum+'%'
            }
            this.setState({
                chgRightW:getW,
                isScrollShow:current.scrollHeight>current.clientHeight?true:false
            })
        }
        // 动态更改左边的阴影效果和高度
        if(isChgDataShow!==newProps['isChgDataShow']||ChgLangData!==newProps['ChgLangData']){
            setTimeout(()=>{
                let current0=this.myHeaderRef.current
                let current1=this.myRightbodyRef.current
                this.setState({
                    getLeftConH:current1.scrollHeight-80,
                    chgHeaderH:current0.clientHeight+1
                })
            }, 10)
        }
        // 回到顶部
        if(isGetResultShow!==newProps['isGetResultShow']||isSortShow!==newProps['isSortShow']){
            this.myRef.current.scrollTop=0
        }
        // 更新数据index=0
        if(isChgDataShow!==newProps['isChgDataShow']){
            this.setState({
                leftIndex:0,
                rightIndex:0
            })
        }
    }
    render(){
        const {t,ChgThemeData,headerData,listData,indexData234,isMobile,isTablet,isPC,loadMore,noMore,ChgLangData,VOLData,ADIVCHGData,isHasKey,bodyW} = this.props  
        const {isScrollLeft,chgRightW,getLeftConH,isMoreShow,chgHeaderH,rightIndex,leftIndex,isScrollShow,leftHover,rightHover,min1360} = this.state
        return (
            <div className={[APPstyles.flex_column,APPstyles.hPer_overflowH].join(' ')}>
                <div 
                    ref={this.myHeaderConRef} 
                    onScroll={() => this.handleHeaderXScroll()}
                    className={[APPstyles.flex_row,styles.header_con].join(' ')}
                    style={{
                        paddingRight:isScrollShow?9:0
                    }}
                >
                    <div
                        className={[
                            APPstyles.flex_row,
                            styles.list_left_header,
                            styles.header_sticky,
                            bodyW<=min1360&&ChgLangData=='zh-CN'?styles.wPer37:APPstyles.wPer35,
                            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
                            isScrollLeft?ChgThemeData=='Light'?styles.list_left_shadow_light:styles.list_left_shadow:'',
                            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
                        ].join(' ')}
                    >
                        {headerData&&headerData.map((item,index) => {
                        return  <div 
                                    key={index}
                                    className={[
                                        APPstyles.flex_row,
                                        styles.left_header_one,
                                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                        isMobile?APPstyles.fz13:isPC?APPstyles.fz12:APPstyles.fz10
                                    ].join(' ')}
                                    style={{
                                        display:(isTablet&&index==1)||(isPC&&index<2)?'':'none',
                                        height:chgHeaderH,
                                        width:isPC?index==0?bodyW<=min1360&&ChgLangData=='zh-CN'?'45%':'40%':bodyW<=min1360&&ChgLangData=='zh-CN'?'55%':'60%':'100%',
                                        paddingLeft:isTablet||(isPC&&index==0)?16:0
                                    }}
                                >
                                    {t(item['name'])}
                                    <div 
                                        className={[
                                            APPstyles.upDown_con,
                                            APPstyles.flex_column
                                        ].join(' ')}
                                    >
                                        <div 
                                            className={APPstyles.img_dropdown_up}
                                            onClick={()=>{this.props.cbSortClick(item,index,'ASC')}}
                                            onMouseOver={()=>{
                                                if(!isPC) return
                                                let getUpImg=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                                                item['upImg']=getUpImg 
                                                this.props.cgChgheaderDataFun(headerData)
                                            }} 
                                            onMouseOut={()=>{
                                                if(!isPC) return
                                                let getUpImg=item['sortName']=='ASC'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                                                item['upImg']=getUpImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }}
                                        >
                                            <img src={item['upImg']} style={{bottom:2}} />
                                        </div>
                                        <div 
                                            className={APPstyles.img_dropdown_down} 
                                            onClick={()=>{this.props.cbSortClick(item,index,'DESC')}}
                                            onMouseOver={()=>{
                                                if(!isPC) return
                                                let getDownImg=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                                item['downImg']=getDownImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }} 
                                            onMouseOut={()=>{
                                                if(!isPC) return
                                                let getDownImg=item['sortName']=='DESC'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                                                item['downImg']=getDownImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }}
                                        >
                                            <img src={item['downImg']} style={{bottom:4}} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }  
                    </div>
                    <div className={bodyW<=min1360&&ChgLangData=='zh-CN'?styles.wPer63:APPstyles.wPer65}>
                    <div 
                        ref={this.myHeaderRef}
                        className={[
                            APPstyles.flex_row,
                            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
                        ].join(' ')} 
                        style={{
                            width:chgRightW
                        }}
                    >
                        {headerData&&headerData.map((item,index) => {
                        return  <div 
                                    key={index}
                                    className={[
                                        APPstyles.flex_row,
                                        styles.listM_right_header,
                                        headerData.length==5?styles.list_headerW_3:'',
                                        isTablet&&!isMobile&&headerData.length==6?styles.list_headerW_tablet_1:'',
                                        isTablet&&!isMobile&&headerData.length>6?styles.list_header_tablet:'',
                                        isTablet&&headerData.length==5?styles.list_header_tablet_0:'',
                                        isMobile?headerData.length==5?styles.list_headerW_3:styles.list_header_mobile:'',
                                        isPC&&headerData.length==6?styles.list_headerW_4:'',
                                        isPC&&headerData.length==7?styles.list_headerW_6:'',
                                        isPC&&headerData.length==8?isHasKey>1?styles.list_headerW_8:styles.list_headerW_7:'',
                                        isPC&&headerData.length>8?styles.list_headerW_5:'',
                                        isMobile?APPstyles.fz13:isPC?APPstyles.fz12:APPstyles.fz10
                                    ].join(' ')}
                                    style={{
                                        display:index==0||index==1?'none':''   
                                    }}
                                >
                                    <div className={APPstyles.flex_column} style={{alignItems:'end',paddingRight:headerData.length-1==index?headerData.length==5?16:24:0}}>
                                        <span>
                                            {t(
                                                ChgLangData=='zh-CN'&&(VOLData.includes(item['key'])||ADIVCHGData.includes(item['key']))?
                                                    item['nameAll']
                                                :
                                                    item['name']
                                            )}
                                        </span>
                                        <span 
                                            style={{
                                                display:!item['name1']||(ChgLangData=='zh-CN'&&(VOLData.includes(item['key'])||ADIVCHGData.includes(item['key'])))?'none':''
                                            }}
                                        >
                                            {t(item['name1'])}
                                        </span>
                                        <span style={{display:item['subName']?'':'none'}}>({t(item['subName'])})</span>
                                    </div>
                                    <div 
                                        className={[
                                            APPstyles.upDown_con,
                                            APPstyles.flex_column
                                        ].join(' ')}
                                        style={{
                                            display:indexData234.includes(index)||item['check']?'none':'',
                                            right:headerData.length-1==index?24:0
                                        }}
                                    >
                                        <div 
                                            className={APPstyles.img_dropdown_up} 
                                            onClick={()=>{this.props.cbSortClick(item,index,'ASC')}}
                                            onMouseOver={()=>{
                                                if(!isPC) return
                                                let getUpImg=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                                                item['upImg']=getUpImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }} 
                                            onMouseOut={()=>{
                                                if(!isPC) return
                                                let getUpImg=item['sortName']=='ASC'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                                                item['upImg']=getUpImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }}
                                        >
                                            <img src={item['upImg']} style={{bottom:2}} />
                                        </div>
                                        <div 
                                            className={APPstyles.img_dropdown_down} 
                                            onClick={()=>{this.props.cbSortClick(item,index,'DESC')}}
                                            onMouseOver={()=>{
                                                if(!isPC) return
                                                let downImg=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                                                item['downImg']=downImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }} 
                                            onMouseOut={()=>{
                                                if(!isPC) return
                                                let downImg=item['sortName']=='DESC'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                                                item['downImg']=downImg
                                                this.props.cgChgheaderDataFun(headerData)
                                            }}
                                        >
                                            <img src={item['downImg']} style={{bottom:4}} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    </div>
                </div>
            <div
                className={[
                    styles.list_con,
                    APPstyles.flex_1,
                    APPstyles.flex_column,
                ].join(' ')}
            >
                <div 
                    ref={this.myRef} 
                    onScroll={() => this.handleScroll()}
                    className={[
                        APPstyles.flex_row,
                        APPstyles.flex_1,
                        styles.list_con_xScroll,
                        isPC?'':styles.list_tablet_xScroll,
                        ChgThemeData=='Light'?styles.scrollbar_corner_light:styles.scrollbar_corner_dark
                    ].join(' ')}
                >
                    <div  
                        className={[
                            styles.list_left,
                            bodyW<=min1360&&ChgLangData=='zh-CN'?styles.wPer37:APPstyles.wPer35,
                            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
                            isScrollLeft?ChgThemeData=='Light'?styles.list_left_shadow_light:styles.list_left_shadow:''
                        ].join(' ')}
                        style={{
                            height:getLeftConH
                        }}
                    >
                        {listData&&listData.map((item,index) => {
                            return  <div 
                                        key={index}
                                        className={[
                                            styles.listM_left_body,
                                            isTablet?'':APPstyles.flex_topcenter,
                                            isTablet?APPstyles.flex_column:APPstyles.flex_row,
                                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
                                            ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                                            leftIndex==index||leftHover==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_1b1b1b:''
                                        ].join(' ')}
                                        style={{
                                            height:isPC?32:'auto',
                                            minHeight:isPC?'auto':52
                                        }}
                                        onClick={()=>{
                                            this.setState({
                                                leftIndex:index,
                                                rightIndex:index
                                            })
                                            this.props.cbChgListData(item) 
                                        }}
                                        onMouseOver={()=>{
                                            if(!isPC) return
                                            this.setState({
                                                rightHover:index
                                            })
                                        }} 
                                        onMouseOut={()=>{
                                            if(!isPC) return
                                            this.setState({
                                                rightHover:null
                                            })
                                        }}
                                    >
                                        <span 
                                            className={[
                                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                APPstyles.ellipsis
                                            ].join(' ')} 
                                            style={{
                                                width:isPC?bodyW<=min1360&&ChgLangData=='zh-CN'?'45%':'40%':'100%',
                                                paddingLeft:16,
                                                paddingBottom:isPC?0:2
                                            }}
                                        >
                                            {item['ticker']}
                                        </span>
                                        <span 
                                            className={[
                                                ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,
                                                APPstyles.ellipsis
                                            ].join(' ')} 
                                            style={{
                                                width:isPC?bodyW<=min1360&&ChgLangData=='zh-CN'?'55%':'60%':'100%',
                                                paddingLeft:isPC?0:16
                                            }}
                                        >
                                            {item['name_en']}
                                        </span>
                                    </div>
                            })
                        }
                    </div>
                    <div className={[styles.list_right,bodyW<=min1360&&ChgLangData=='zh-CN'?styles.wPer63:APPstyles.wPer65].join(' ')}> 
                        <div ref={this.myRightbodyRef} style={{width:chgRightW,paddingBottom:80}}>
                            {listData&&listData.map((item,index) => {
                                return  <div
                                            key={index}
                                            className={[
                                                APPstyles.flex_row,
                                                styles.listM_right_body,
                                                headerData.length==5?styles.list_bodyW_3:'',
                                                isTablet&&!isMobile&&headerData.length==6?styles.list_bodyW_tablet_1:'',
                                                isTablet&&!isMobile&&headerData.length>6?styles.list_body_tablet:'',
                                                isTablet&&headerData.length==5?styles.list_body_tablet_0:'',
                                                isMobile?headerData.length==5?styles.list_bodyW_3:styles.list_body_mobile:'',
                                                isPC&&headerData.length==6?styles.list_bodyW_4:'',
                                                isPC&&headerData.length==7?styles.list_bodyW_6:'',
                                                isPC&&headerData.length==8?isHasKey>1?styles.list_bodyW_8:styles.list_bodyW_7:'',
                                                isPC&&headerData.length>8?styles.list_bodyW_5:'',
                                                isMobile?APPstyles.fz16:APPstyles.fz14,
                                                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                                                ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                                                rightIndex==index||rightHover==index?ChgThemeData=='Light'?APPstyles.bg_list_light:APPstyles.bg_1b1b1b:''
                                            ].join(' ')}
                                            style={{
                                                minHeight:isPC?32:52
                                            }}
                                            onClick={()=>{
                                                this.setState({
                                                    leftIndex:index,
                                                    rightIndex:index
                                                })
                                                this.props.cbChgListData(item) 
                                            }}
                                            onMouseOver={()=>{
                                                if(!isPC) return
                                                this.setState({
                                                    leftHover:index
                                                })
                                                
                                            }} 
                                            onMouseOut={()=>{
                                                if(!isPC) return
                                                this.setState({
                                                    leftHover:null
                                                })
                                            }}
                                        >
                                            <span 
                                                className={[
                                                    styles.list_item,
                                                    Number(item['NETCHNG_1'])>0?APPstyles.color_red:Number(item['NETCHNG_1'])<0?APPstyles.color_green:Number(item['NETCHNG_1'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                                                ].join(' ')}
                                            >
                                                {common.transThousands(common.keepNum(item['TRDPRC_1'],2))}
                                            </span>
                                            <span 
                                                className={[
                                                    styles.list_item,
                                                    Number(item['NETCHNG_1'])>0?APPstyles.color_red:Number(item['NETCHNG_1'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(item['NETCHNG_1'])==0?APPstyles.color_grey:APPstyles.color_white
                                                ].join(' ')}
                                            >
                                                {common.addplusNum(common.transThousands(common.keepNum(item['NETCHNG_1'],2)))}
                                            </span>
                                            <span 
                                                className={[
                                                    styles.list_item,
                                                    Number(item['PCTCHNG'])>0?APPstyles.color_red:Number(item['PCTCHNG'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:Number(item['PCTCHNG'])==0?APPstyles.color_grey:APPstyles.color_white
                                                ].join(' ')}
                                            >
                                                <label style={{paddingRight:item['advData']?0:16}}>{common.addplusNum(common.transThousands(common.keepNum(item['PCTCHNG'],2)))}</label>
                                            </span>
                                            {
                                                item['advData']&&item['advData'].map((list,listIndex) => {
                                                return  <span 
                                                            key={listIndex}
                                                            className={styles.list_item} 
                                                        >
                                                            <label style={{paddingRight:item['advData'].length-1==listIndex?16:0,display:list['check']?'none':''}}>{item['adv'+listIndex]}</label>
                                                            <img src={ChgThemeData=='Light'?checkDarkImg:checkLightImg} className={APPstyles.img24} style={{marginRight:item['advData'].length-1==listIndex?16:0,display:list['check']?'':'none',cursor:'default'}} />
                                                        </span>
                                                })
                                            }
                                        </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={[APPstyles.loading_more,styles.list_more,APPstyles.transformX].join(' ')} 
                    style={{display:loadMore?isMoreShow?'':'none':'none'}}
                >
                    {t('Load more')}...
                </div>
                <div 
                    className={[APPstyles.loading_more,styles.list_more,APPstyles.transformX].join(' ')} 
                    style={{display:noMore?isMoreShow?'':'none':'none'}}
                >
                    - {t('No More Data Available')} -
                </div>
            </div>
            </div>
        )}
}
export default withTranslation()(observer(ListCon))