import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../js/common'

require('highcharts/modules/exporting')(Hightcharts)
require('highcharts/modules/heatmap')(Hightcharts)
require('highcharts/modules/treemap')(Hightcharts)

HighchartsMore(Hightcharts)

class TreemapCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  onLoadData(){
    let that=this
    const {t,id,treemapData,ChgLangData,ChgThemeData,isMobile}=this.props
    Hightcharts.chart(id,{
      chart: {
        backgroundColor: '',
        spacing:[0,0,0,0]
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px'
        },
        pointFormatter(){
          let name=this[ChgLangData=='zh-CN'?'name_cht':'name_en']
          let value=common.changeRate(common.addplusNum(common.transThousands(common.keepNum(this['tooltopValue'],2))))
          value=value=='-'?'-%':value
          return name +': ' +value
        }
      },
      plotOptions: {
        series: {
          borderColor:ChgThemeData=='Light'?'#E0E0E0':'#222429',
          shadow: false,
          animation: false,
          cursor: 'pointer',
          dataLabels: {
            shadow: false,
            padding:0,
            crop:false,
            align:'center',
            y:0,
            style: {
              color: '#FFFFFF',
              fontWeight: '400',
              fontSize: '16px',
              fontFamily: 'Roboto',
              textAlign:'center',
              textOutline:0
            },
            formatter(){
              let str0=''
              let getW=this['point']['shapeArgs']['width']
              let name=this['point'][ChgLangData=='zh-CN'?'name_cht':'name_en']
              let value=common.changeRate(common.addplusNum(common.transThousands(common.keepNum(this['point']['tooltopValue'],2))))
              value=value=='-'?'-%':value
              
              if(name=='Academic & Educational Services'){
                str0='<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">Acad...</div><br/>'
              }else if(name=='學術教育'){
                str0='<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">學術...</div><br/>'
              }else{
                str0='<div>'+name+'</div><br/>'
              }
              let str1='<div>'+value+'</div>'
              
              return isMobile?getW>60?str0+str1:'':str0+str1
            }
          },
          events: {
            load: function() {
              let points = that.series[0].points,
                  pointX1, pointY1, pointX2, pointY2,
                  labelX1, labelY1, labelX2, labelY2
              
              points.forEach(function(point, index) {
                pointX1 = +point.graphic.element.getAttribute('x')
                pointY1 = +point.graphic.element.getAttribute('y')
                pointX2 = pointX1 + +point.graphic.element.getAttribute('width')
                pointY2 = pointY1 + +point.graphic.element.getAttribute('height')
      
                labelX1 = point.dataLabel?point.dataLabel.x:0
                labelY1 = point.dataLabel?point.dataLabel.y:0
                labelX2 = point.dataLabel?labelX1 + point.dataLabel.width:0
                labelY2 = point.dataLabel?labelY1 + point.dataLabel.height:0
  
                if (!(labelX1 > pointX1 && labelY1 > pointY1 && labelX2 < pointX2 && labelY2 < pointY2)) {
                  point.update({
                    dataLabels: {
                      enabled: false
                    }
                  })
                }
              })
            },
            click: function (event) {
              let msgData={
                name_cht:event['point']['name_cht'],
                name_en:event['point']['name_en'],
                TRDPRC_1:event['point']['TRDPRC_1'],
                NETCHNG_1:event['point']['NETCHNG_1'],
                PCTCHNG:event['point']['PCTCHNG'],
                TRADE_DATE:event['point']['TRADE_DATE'],
                ric:event['point']['ric'],
                agg_code:event['point']['agg_code'],
                pricePctChg1M:event['point']['pricePctChg1M'],
                pricePctChg3M:event['point']['pricePctChg3M'],
                pricePctChg6M:event['point']['pricePctChg6M'],
                pricePctChg1Y:event['point']['pricePctChg1Y']
              }
              that.props.cbChgRightData(msgData)
            }
          },
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: "treemap",
		    layoutAlgorithm: 'squarified',
		    data: treemapData
      }],
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,treemapData,bodyW,isMobileDetailShow,isPC,isMobile}=this.props
    if(
      bodyW!==newProps['bodyW']||
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      treemapData!==newProps['treemapData']
    ){
      if(treemapData.length<=0) return
      this.onLoadData()
    }
    if(isMobileDetailShow!==newProps['isMobileDetailShow']){
      if(isMobile||isPC) return
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    const {treemapData}=this.props
    setTimeout(()=>{
      if(treemapData.length<=0) return
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(TreemapCon)