import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../../asserts/js/common'
import TreemapCon from '../../../../asserts/js/charts/treemap'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class GridCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    render(){
        const {t,isLoading,gridData,isTablet,isMobile} = this.props
        return (
            <div 
                className={[
                    APPstyles.flex1_hidden,
                    isMobile&&gridData.length?'':APPstyles.flex_column,
                    isMobile&&gridData.length?'':APPstyles.heightper,
                    styles.body_out
                ].join(' ')}
                style={{
                    paddingBottom:isMobile?0:25
                }}
            >
                <div 
                    className={[
                        APPstyles.flex_row,
                        isMobile&&gridData.length?'':APPstyles.flex1_hidden,
                        styles.body
                    ].join(' ')}
                    style={{
                        height:isMobile&&gridData.length?500:'auto',
                        minHeight:isMobile&&!gridData.length?200:'auto'
                    }}
                >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                {
                    gridData.length?
                        <TreemapCon
                            {...this.props}
                            id='treemapId'
                            cbChgRightData={(data)=>{
                                this.props.chgSectorData(data)
                                if(isTablet&&!isMobile){
                                    this.props.chgMenuTag('list')
                                }
                            }}
                        />
                    :
                        <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>  
                }
                </div> 
                <div className={[APPstyles.flex_row,styles.speed].join(' ')}>
                {
                    common.speedData.map((item,index) => {
                        return <span key={index} style={{backgroundColor:item['bgColor']}}>{item['num']}</span>
                    })
                }
                </div>
            </div>
        )}
}
export default withTranslation()(observer(GridCon))