import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Reminder extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isInitialTradeShow:false,
      minW600:600,
      contentData:[
        {
          num:1,
          content:'The GTC order is currently available only in USD for settlement and must not be uncollected funds, otherwise the order will not be executed.'
        },
        {
          num:2,
          content:'The GTC order will be sent only once a day before the opening of the market. If the order fails, we will not resend the order on the same day.'
        },
        {
          num:3,
          content:'If there is a change in the number of shares in stock or the market price of the subject of a GTC order due to code change, ex-dividend and ex-right, merger, or stock split, the contents of the order will not be changed accordingly. Please be aware of the risks involved. In the event of a reverse stock split, the order will be automatically canceled.'
        },
        {
          num:4,
          content:'The quote information may vary from one US exchange to another, and the trigger price is based on the information source of sub broker.'
        },
        {
          num:5,
          content:'If the market price is too volatile during the validity period of the GTC order, the order may not be executed on that day because the order price exceeds the volatility ratio set by the SEC. The clients may do the following: (1) Cancel the original order and place a new order. (2) Not to cancel the original order, the GTC will remain valid during the validity period.'
        },
        {
          num:6,
          content:'We reserve the right to stop or suspend the acceptance of GTC at any time based on the consideration of the sub broker or risk management policy.'
        }
      ],
      chgCloseImg:closeImg
    }
  }
  //close/confirm
  tipsClick(msg){
    const {sliderIndex,tradeOrderData,isTablet,isMobile} = this.props
    let getData=tradeOrderData[0]
    if(msg=='confirm'){
      let data=[true,false,false,true]
      this.props.calkbackBtnStatus(data)
      return
    }
    // close
    if(sliderIndex=='quote'){
      let data=isTablet&&!isMobile?[true,true,false,false]:[false,false,false,false]
      getData['isClickADR']=false
      this.props.calkbackBtnStatus(data)
      this.props.calkbackOrderData([getData])
      return
    }
    let data=[true,true,false,false]
    getData['isClickADR']=false
    this.props.calkbackBtnStatus(data)
    this.props.calkbackOrderData([getData])
  }
  render(){
    const {t,buySellIndex,bodyW,ChgThemeData,isMobile} = this.props
    const {minW600,contentData,chgCloseImg}=this.state
    return (
      <div 
        className={[
          styles.reminder,
          APPstyles.flex_column,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black,
          isMobile?APPstyles.flex1_auto:''
        ].join(' ')}
        style={{
          width:bodyW<=minW600?'80%':522,
          height:'100%'
        }}
      >
        <div
          className={[
            APPstyles.flex_between,
            styles.reminder_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          {t('GTC and Stop Limit Order Instructions')}
          <img 
              src={chgCloseImg}
              alt='close'
              onClick={this.tipsClick.bind(this,'close')}
              className={APPstyles.img24} 
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgCloseImg:closeImg
                })
              }}  
            />
        </div>
        <div 
          className={[
            styles.reminder_content,
            APPstyles.flex1_auto,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
          ].join(' ')}
        >
          {contentData.map((item,index) => {
            return  <div className={APPstyles.flex_row} key={index} style={{marginBottom:contentData.length-1==index?0:8}}>
                      <span>{item['num']}</span>
                      <span style={{marginRight:6}}>.</span>
                      <span className={isMobile?APPstyles.lineHeight20:''}>{t(item['content'])}</span>
                    </div>
          })}
        </div>
        <div 
          className={[APPstyles.flex_row,APPstyles.place_button].join(' ')}
          style={{paddingBottom:isMobile?28:24}}
        >
          <Button type='default' className={APPstyles.btn_gray} onClick={this.tipsClick.bind(this,'close')}>{t('Cancel')}</Button>
          <Button 
            type='default' 
            className={[
              buySellIndex&&buySellIndex['key']=='B'?APPstyles.btn_red:APPstyles.btn_green,
              buySellIndex&&buySellIndex['key']=='B'?APPstyles.bg_red:APPstyles.bg_green
            ].join(' ')}
            onClick={this.tipsClick.bind(this,'confirm')} 
          >
            {t('Confirm Order')}
          </Button>
        </div>
      </div>
  )}
}
export default withTranslation()(Reminder)