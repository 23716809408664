import React from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import APPstyles from '@/App.less';
import styles from './index.less';
import PaginationCon from '../../Pagination/index';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollShow:false,
    }
    this.myRef = React.createRef()
  }
  handleScroll(){
    let current=this.myRef.current;
    if(current){
      if(Number(current.scrollHeight - current.scrollTop- current.clientHeight) < 10){
        this.props.cbChgDataFun();
      }
    }
  }
  isScrollShowFun(){
    let current=this.myRef.current;
    if(current){
      this.setState({
        isScrollShow:current.scrollHeight>current.clientHeight?true:false,
      });
    }
  }
  componentDidUpdate(newProps){
    const {isLoading} = this.props
    if(isLoading!==newProps['isLoading']){
      setTimeout(()=>{
        this.isScrollShowFun();
      }, 10);
    }
  }
  render() {
    const {t,history,isLoading,ChgThemeData,loadMore,noMore,isMobile} = this.props
    const {isScrollShow}=this.state
    return (
      <div className={[APPstyles.flex_column,APPstyles.flex1_hidden].join(' ')}>
        <div
          className={[
            styles.header,
            styles.common,
            APPstyles.flex_row,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile&&(ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white),
          ].join(' ')}
          style={{padding:isScrollShow?'3px 25px 3px 16px':'3px 16px'}}
        >
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('direction')}</span>
            <span>{t('code')}</span>
            <span>{t('Name1')}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('QTY Filled3')}</span>
            <span>{t('Transacted Price1')}</span>
            <span>{t('Transacted Amt1')}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('Handling Fee')}</span>
            <span>{t('Processing Fee1')}</span>
            <span>{t('Exchange Fee1')}</span>
            <span>{t('Settlement Fee1')}</span>
          </div>
          <div className={[styles.child,APPstyles.flex_column].join(' ')}>
            <span>{t('Transaction Tax')}</span>
            <span>{t('Stamp Duty')}</span>
            <span>{t('AR/AP Amt1')}</span>
            <span>{t('Payment Date1')}</span>
          </div>
        </div>
        <div
          ref={this.myRef}
          onScroll={() => this.handleScroll()}
          className={[APPstyles.flex1_auto,APPstyles.paddingBottom20].join(' ')} 
          style={{position:isLoading?'static':'relative'}}
        >
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return (
                <div key={index}>
                  <div
                    className={[
                      styles.body,
                      styles.common,
                      APPstyles.flex_row,
                      ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                      ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                    ].join(' ')}
                  >
                    <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                      <span className={item['buy_sell']=='B'?APPstyles.color_red:APPstyles.color_green} style={{fontWeight:400}}>
                        {t(common.charAtToUpperCase(item['buy_sell']=='B'?'Buy':'Sell'))}
                      </span>
                      <span style={{fontWeight:700}}>{t(item['stock_code'])}</span>
                      <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur} style={{fontSize:12}}>
                        {t(item['stock_name'])}
                      </span>
                    </div>
                    <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                      <span>{t(item['qty_filled'])}</span>
                      <span>{common.transThousands(common.keepNum(item['transacted_price'],2))}</span>
                      <span>{common.transThousands(common.keepNum(item['transacted_amount'],2))}</span>
                    </div>
                    <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                      <span>{common.transThousands(common.keepNum(item['handling_fee'],2))}</span>
                      <span>{common.transThousands(common.keepNum(item['processing_fee'],2))}</span>
                      <span>{common.transThousands(common.keepNum(item['exchange_fee'],2))}</span>
                      <span>{common.transThousands(common.keepNum(item['settlement_fee'],2))}</span>
                    </div>
                    <div className={[styles.child,APPstyles.flex_column].join(' ')}>
                      <span>{common.transThousands(common.keepNum(item['transaction_tax'],2))}</span>
                      <span>{common.transThousands(common.keepNum(item['stamp_duty'],2))}</span>
                      <span className={Number(item['arap_amount'])>0?APPstyles.color_red:Number(item['arap_amount'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                        {common.transThousands(common.keepNum(item['arap_amount'],2))}
                      </span>
                      <span>{common.onlyOrderDate(item['payment_date'])}</span>
                    </div>
                  </div>
                  <div style={{marginTop:14,marginLeft:isMobile?0:8,display:history.length-1==index?'':'none'}}>
                    <PaginationCon {...this.props} />
                  </div>
                </div>
              );
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
          }
          <div className={APPstyles.loading_more} style={{display:loadMore?'':'none'}}>{t('Load more')}...</div>
          <div className={APPstyles.loading_more} style={{display:noMore?'':'none'}}>- {t('No More Data Available')} -</div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);
