import React from 'react'
import { withTranslation } from 'react-i18next'
import General from './General/index'
import Simple from './Simple/index'

class TradeCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isPageShow:'',
      simpleData:[
        {
          name:'General',
          key:'general'
        },
        {
          name:'Simple',
          key:'simple'
        }
      ],
      isChgPageOn:false
    }
  }
  //提交订单--所有的数据
  calkbackOrderData(data){
    this.props.calkbackOrderData(data)
  }
  // modal显示/隐藏--切换显示哪个/下单成功
  calkbackBtnStatus(data){
    this.props.calkbackBtnStatus(data)
  }
  callbackChgNavIndex(data){
    this.props.callbackChgNavIndex(data)
  }
  callbackTradeShow(tag,data){
    this.props.callbackTradeShow(tag,data)
  }
  cbGotoPage_1(){
    this.props.cbGotoPage_1()
  }
  callbackPathnameApp(data){
    this.props.callbackPathnameApp(data)
  }
  chgTradeNameFun(){
    const {tradeName}=this.props
    this.setState({
      isPageShow:tradeName
    })
  }
  // 切换页面
  cbChgPageShow(data){
    this.setState({
      isPageShow:data
    })
    if(!data){
      this.setState({
        isChgPageOn:false
      })
    }
  }
  cbSwitchMoalShow(){
    this.props.cbSwitchMoalShow(true);
    sessionStorage.setItem('isSwitchFirstShow','Y');
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {tradeName,isShowPageData,isTradeMinbody,accountTradeData,currentIndex}=this.props
    let navId=sessionStorage.getItem('navId')
    let navIdAPP=sessionStorage.getItem('navIdAPP')

    if(tradeName!==newProps['tradeName']){
      this.chgTradeNameFun()
    }
    // quote--trade--弹框
    if(isTradeMinbody!==newProps['isTradeMinbody']){
      this.chgTradeNameFun()
    }
    // trade--弹框
    if(navId=='trade'){
      if(isShowPageData&&(isShowPageData[0]!==newProps['isShowPageData'][0])){
        if(isShowPageData[0]){
          if(currentIndex!==2||currentIndex!==3){
            this.setState({
              isChgPageOn:true
            })
          }
          this.chgTradeNameFun()
        }
      }
    }
    //account--trade--弹框
    if(navIdAPP=='account'&&accountTradeData!==newProps['accountTradeData']){
      this.chgTradeNameFun()
    }
  }
  //初始化
  componentDidMount() {
    this.chgTradeNameFun()
  }
  render(){
    const {isPageShow,simpleData,isChgPageOn}=this.state
    return (
      <div style={{height:'100%'}}>
        {isPageShow=='general'&&
          <General
            {...this.props}
            simpleData={simpleData}
            isChgPageOn={isChgPageOn}
            calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            calkbackOrderData={this.calkbackOrderData.bind(this)} 
            callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
            callbackTradeShow={this.callbackTradeShow.bind(this)}
            cbGotoPage_1={this.callbackChgNavIndex.bind(this)}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
            cbChgPageShow={this.cbChgPageShow.bind(this)}
            cbSwitchMoalShow={this.cbSwitchMoalShow.bind(this)}
          />
        }
        {isPageShow=='simple'&&
          <Simple
            {...this.props}
            simpleData={simpleData}
            isChgPageOn={isChgPageOn}
            calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            calkbackOrderData={this.calkbackOrderData.bind(this)} 
            callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
            callbackTradeShow={this.callbackTradeShow.bind(this)}
            cbGotoPage_1={this.callbackChgNavIndex.bind(this)}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
            cbChgPageShow={this.cbChgPageShow.bind(this)}
            cbSwitchMoalShow={this.cbSwitchMoalShow.bind(this)}
          />
        }
      </div>
  )}
}
export default withTranslation()(TradeCon)