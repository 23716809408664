import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import common from '../../../../asserts/js/common'
import { 
    dropDownImg
} from '../../../../asserts/imgs/common/index'
import { 
    sliderLightImg,
    sliderImg
} from '../../../../asserts/imgs/quote/index'
import CriteriaCon from '../criteria/index'
import ResultsCon from '../results/index'
import TipsModal from '../tipsModal/index'
import Tabs from '../../../Tabs2/index'
import SwitchModal from '../../../switch/index'
import PresetList from './presetList/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class LeftCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isCriteriaShow:false,
            presetIndex:null,
            isSaveCriteria:false,
            criteriaNum:0,
            isResetAll:false,
            isChgCheckboxShow:false,
            rangeListData:[],
            isLoading:false,
            keysDropDownData:['DIVGR','EPSGR','t_dev_sma','VOL','pricePctChg'],
            listData:[
                {
                    title:'Market Cap',
                    key:'MarketCap',
                    isSelcetAll:true,
                    isSelcetAllTemp:true,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Small Cap',
                            checked:true,
                            checkedTemp:true,
                            key:'Small'
                        },
                        {
                            name:'Mid Cap',
                            checked:true,
                            checkedTemp:true,
                            key:'Mid'
                        },
                        {
                            name:'Large Cap',
                            checked:true,
                            checkedTemp:true,
                            key:'Large'
                        }
                    ]
                },
                {
                    title:'Instrument Type',
                    key:'InstrumentType',
                    isSelcetAll:true,
                    isSelcetAllTemp:true,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Ordinary',
                            checked:true,
                            checkedTemp:true,
                            key:'ORD'
                        },
                        {
                            name:'Preferred',
                            checked:true,
                            checkedTemp:true,
                            key:'PFS'
                        },
                        {
                            name:'ADR',
                            checked:true,
                            checkedTemp:true,
                            key:'ADR'
                        },
                        {
                            name:'ETF',
                            checked:true,
                            checkedTemp:true,
                            key:'ETF'
                        }
                    ]
                },
                {
                    title:'Sectors',
                    key:'Sectors',
                    isSelcetAll:true,
                    isSelcetAllTemp:true,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        
                        {
                            name:'Academic & Educational Services',
                            checked:true,
                            checkedTemp:true,
                            key:63
                        },
                        {
                            name:'Basic Materials1',
                            checked:true,
                            checkedTemp:true,
                            key:51
                        },
                        {
                            name:'Consumer Cyclicals',
                            checked:true,
                            checkedTemp:true,
                            key:53
                        },
                        {
                            name:'Consumer Non-Cyclicals',
                            checked:true,
                            checkedTemp:true,
                            key:54
                        },
                        {
                            name:'Energy',
                            checked:true,
                            checkedTemp:true,
                            key:50
                        },
                        {
                            name:'Financials',
                            checked:true,
                            checkedTemp:true,
                            key:55
                        },
                        {
                            name:'Government Activity',
                            checked:true,
                            checkedTemp:true,
                            key:62
                        },
                        {
                            name:'Industrials1',
                            checked:true,
                            checkedTemp:true,
                            key:52
                        },
                        {
                            name:'Institutions, Associations & Organizations',
                            checked:true,
                            checkedTemp:true,
                            key:61
                        },
                        {
                            name:'Healthcare1',
                            checked:true,
                            checkedTemp:true,
                            key:56
                        },
                        {
                            name:'Real Estate',
                            checked:true,
                            checkedTemp:true,
                            key:60
                        },
                        {
                            name:'Technology',
                            checked:true,
                            checkedTemp:true,
                            key:57
                        },
                        {
                            name:'Utilities',
                            checked:true,
                            checkedTemp:true,
                            key:59
                        },
                    ]
                },
                {
                    title:'Rating',
                    key:'Rating',
                    isSelcetAll:false,
                    isSelcetAllTemp:false,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Company Score (StockReports+)',
                            checked:false,
                            checkedTemp:false,
                            key:'AVG_SCORE'
                        },
                        {
                            name:'ESG Score',
                            checked:false,
                            checkedTemp:false,
                            key:'ESGScoreGrade',
                            dropDownShowLeft:false,
                            dropDownShowRight:false,
                            imgLeft:dropDownImg,
                            imgRight:dropDownImg,
                            leftValue:'A+',
                            rightValue:'D-',
                            leftIndex:0,
                            rightIndex:11,
                            dropDownData:[
                                {
                                    name:'A+',
                                    key:'A+'
                                },
                                {
                                    name:'A',
                                    key:'A'
                                },
                                {
                                    name:'A-',
                                    key:'A-'
                                },
                                {
                                    name:'B+',
                                    key:'B+'
                                },
                                {
                                    name:'B',
                                    key:'B'
                                },
                                {
                                    name:'B-',
                                    key:'B-'
                                },
                                {
                                    name:'C+',
                                    key:'C+'
                                },
                                {
                                    name:'C',
                                    key:'C'
                                },
                                {
                                    name:'C-',
                                    key:'C-'
                                },
                                {
                                    name:'D+',
                                    key:'D+'
                                },
                                {
                                    name:'D',
                                    key:'D'
                                },
                                {
                                    name:'D-',
                                    key:'D-'
                                }
                            ]
                        }
                    ]
                },
                {
                    title:'Valuation',
                    key:'Valuation',
                    isSelcetAll:false,
                    isSelcetAllTemp:false,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'P/E Normalized',
                            checked:false,
                            checkedTemp:false,
                            key:'APENORM'
                        },
                        {
                            name:'Price to Book',
                            checked:false,
                            checkedTemp:false,
                            key:'APRICE2BK'
                        },
                        {
                            name:'Price to Equity',
                            checked:false,
                            checkedTemp:false,
                            key:'APRICE2TOTEQ'
                        },
                        {
                            name:'Gross Margin (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'AGROSMGN'
                        },
                        {
                            name:'Dividend Yield (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'YIELD'
                        },
                        {
                            name:'Return On Equity (ROE) (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'AROEPCT'
                        }
                    ]
                },
                {
                    title:'Financial Strength',
                    key:'FinancialStrength',
                    isSelcetAll:false,
                    isSelcetAllTemp:false,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Quick Ratio',
                            checked:false,
                            checkedTemp:false,
                            key:'AQUICKRATI'
                        },
                        {
                            name:'Current Ratio',
                            checked:false,
                            checkedTemp:false,
                            key:'ACURRATIO'
                        },
                        {
                            name:'Total Debt/Total Assets (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'QTOTD2AST'
                        },
                        {
                            name:'Revenue Growth Rate (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'AREVCHG'
                        },
                        {
                            name:'Dividend Yield Growth Rate (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'DIVGR',
                            dropDownTitle:'1 Yr ago',
                            isDropDownShow:false,
                            isDropDownIndex:0,
                            chgDropDownImg:dropDownImg,
                            dropDownData:[
                                {
                                    name:'1 Yr ago',
                                    key:'ADIVCHG'
                                },
                                {
                                    name:'3 Yrs ago',
                                    key:'DIVGRPCT'
                                },
                                {
                                    name:'5 Yrs ago',
                                    key:'DIVTRENDGR'
                                },
                                {
                                    name:'10 Yrs ago',
                                    key:'DIVTREND10'
                                }
                            ]
                        },
                        {
                            name:'EPS Growth Rate (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'EPSGR',
                            dropDownTitle:'1Y1',
                            isDropDownShow:false,
                            isDropDownIndex:0,
                            chgDropDownImg:dropDownImg,
                            dropDownData:[
                                {
                                    name:'1Y1',
                                    key:'AEPSCHG'
                                },
                                {
                                    name:'3Y',
                                    key:'EPSGRPCT'
                                },
                                {
                                    name:'5Y1',
                                    key:'EPSTRENDGR'
                                },
                                {
                                    name:'10Y',
                                    key:'EPSTREND10'
                                },
                                {
                                    name:'1Q',
                                    key:'EPSCHNGIN'
                                }
                            ]
                        }
                    ]
                },
                {
                    title:'Technical Indicators',
                    key:'TechnicalIndicators',
                    isSelcetAll:false,
                    isSelcetAllTemp:false,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Golden Cross',
                            checked:false,
                            checkedTemp:false,
                            key:'t_short_goldx'
                        },
                        {
                            name:'Dead Cross',
                            checked:false,
                            checkedTemp:false,
                            key:'t_short_deadx'
                        },
                        {
                            name:'Bollinger Breakout (Bullish)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_boll_bull'
                        },
                        {
                            name:'Bollinger Breakout (Bearish)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_boll_bear'
                        },
                        {
                            name:'MACD (Bullish)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_macd_bullx'
                        },
                        {
                            name:'MACD (Bearish)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_macd_bearx'
                        },
                        {
                            name:'Fast Stochastic',
                            checked:false,
                            checkedTemp:false,
                            key:'t_fast_sto'
                        },
                        {
                            name:'Slow Stochastic',
                            checked:false,
                            checkedTemp:false,
                            key:'t_slow_sto'
                        },
                        {
                            name:'Simple Moving Average Deviation Rate (%)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_dev_sma',
                            dropDownTitle:'5 business days',
                            isDropDownShow:false,
                            isDropDownIndex:0,
                            chgDropDownImg:dropDownImg,
                            dropDownData:[
                                {
                                    name:'5 business days',
                                    key:'t_dev_sma5'
                                },
                                {
                                    name:'25 business days',
                                    key:'t_dev_sma25'
                                },
                                {
                                    name:'75 business days',
                                    key:'t_dev_sma75'
                                }
                            ]
                        },
                        {
                            name:'Relative Strength Index (RSI)',
                            checked:false,
                            checkedTemp:false,
                            key:'t_rsi'
                        }
                    ]
                },
                {
                    title:'Price & Volume',
                    key:'PriceVolume',
                    isSelcetAll:false,
                    isSelcetAllTemp:false,
                    isShowAll:true,
                    isResultChecked:true,
                    dropDownImg:dropDownImg,
                    data:[
                        {
                            name:'Percentage Change1',
                            checked:false,
                            checkedTemp:false,
                            key:'pricePctChg',
                            dropDownTitle:'1D',
                            isDropDownShow:false,
                            isDropDownIndex:0,
                            chgDropDownImg:dropDownImg,
                            dropDownData:[
                                {
                                    name:'1D',
                                    key:'rt_pctchng'
                                },
                                {
                                    name:'1W',
                                    key:'pricePctChg1W'
                                },
                                {
                                    name:'1M',
                                    key:'pricePctChg1M'
                                },
                                {
                                    name:'3M',
                                    key:'pricePctChg3M'
                                },
                                {
                                    name:'1Y1',
                                    key:'pricePctChg1Y'
                                },
                                {
                                    name:'5Y1',
                                    key:'pricePctChg5Y'
                                }
                            ]
                        },
                        {
                            name:'Volume - avg. trading vol. (x1M)1',
                            checked:false,
                            checkedTemp:false,
                            key:'VOL',
                            dropDownTitle:'Last 10 Days',
                            isDropDownShow:false,
                            isDropDownIndex:0,
                            chgDropDownImg:dropDownImg,
                            dropDownData:[
                                {
                                    name:'Last 10 Days',
                                    key:'VOL10DAVG'
                                },
                                {
                                    name:'Last 3 Months',
                                    key:'VOL3MAVG'
                                }
                            ]
                        },
                        {
                            name:'52 Week High1',
                            checked:false,
                            checkedTemp:false,
                            key:'rt_high52w_ind'
                        },
                        {
                            name:'52 Week Low1',
                            checked:false,
                            checkedTemp:false,
                            key:'rt_low52w_ind'
                        }
                    ]
                }
            ],
            tipsData:[],
            isDeleteIitem:false,
            isPresetShow:true,
            isgetPresetIndex:false,
            isTipsModalShow:false,
            tipsModalData:'',
            isGetResultShow:false,
            ischeckAll:false,
            isClickBtn:false,
            titleData:[
                {
                    name:'Quick Screeners',
                    isShow:false,
                    key:'quick'
                },
                {
                    name:'My Saved Screeners',
                    isShow:false,
                    key:'saved'
                }
            ],
            titleIndex:0,
            presetList:[
                {
                    name:'Technical Screener',
                    id:'technical',
                    num:0,
                    isShow:false
                },
                {
                    name:'Fundamental Screener',
                    id:'fundamental',
                    num:0,
                    isShow:false
                },
                {
                    name:'Top Gainer ETFs',
                    id:'top',
                    num:0,
                    isShow:false
                },
                {
                    name:'High Dividend Yield',
                    id:'high',
                    num:0,
                    isShow:false
                }
            ],
            quickData:[
                {
                    name:'Technical Screener',
                    id:'technical',
                    num:0,
                    isShow:false
                },
                {
                    name:'Fundamental Screener',
                    id:'fundamental',
                    num:0,
                    isShow:false
                },
                {
                    name:'Top Gainer ETFs',
                    id:'top',
                    num:0,
                    isShow:false
                },
                {
                    name:'High Dividend Yield',
                    id:'high',
                    num:0,
                    isShow:false
                }
            ],
            strategyData:[
                {
                    name:'Strategy 1',
                    id:'strategy1',
                    num:0,
                    isShow:false
                  },
                  {
                    name:'Strategy 2',
                    id:'strategy2',
                    num:0,
                    isShow:false
                  },
                  {
                    name:'Strategy 3',
                    id:'strategy3',
                    num:0,
                    isShow:false
                  },
                  {
                    name:'Strategy 4',
                    id:'strategy4',
                    num:0,
                    isShow:false
                  }
            ],
            isOnload:true,
            isStrategyShow:false,
            quickIndex:null,
            strategyIndex:null,
            strategyId:0,
            isSaveBtnShow:false,
            resultTitle:'New Screener',
            isDelImgShow:false,
            isDelImgShowTemp:false,
            isSetOnLoad_0:true,
            isSetOnLoad_1:true,
            isStrategyBtnShow:false
        }
        this.myLeftRef = React.createRef()
    }
    //处理数据--弹框--确认
    dealCriteriaDataFun(item,tag,num,index){
        const {isOnload} = this.state
        // Quick Screeners
        if(tag!=='index'){
            this.setState({
                isLoading: true,
                presetIndex:null,
                quickIndex:null,
                strategyIndex:null,
                isSaveBtnShow:isOnload?false:num==0?false:true,
                resultTitle:'New Screener'
            })
            this.backTopFun()
        }
        this.chgTabAllDatasFun()
        let keyData=[]
        for(let i in item){
            keyData[i]=common.dealCheckedKeyData(item[i]['data'])
        }
        let obj={
            MarketCap:keyData[0], 
            InstrumentType:keyData[1], 
            Sectors:keyData[2], 
            Rating:keyData[3], 
            Valuation:keyData[4], 
            FinancialStrength:keyData[5], 
            TechnicalIndicators:keyData[6], 
            PriceVolume:keyData[7], 
            token:sessionStorage.getItem('token')
        }
        this.getData('screener/criteria',obj,index,tag)
    }
    //处理数据--api
    dealAPIDataFun(resData,chgPresetIndex){
        const {keys3Data}=this.props
        const {keysDropDownData}=this.state
        let flagData=Object.keys(resData)
        let getNewData=this.resetListDataFun()
        for(let i in flagData){
            // keys3Data
            if(keys3Data.includes(flagData[i])){
                this.dealKeys3DataFun(flagData,getNewData,resData,i,i)
            }else{
                let keyData=resData[flagData[i]]
                let keyDataLen = Object.keys(keyData)
                let data=getNewData[i]['data']
                this.dealGetNewDataFun(data,keyDataLen,keyData,keysDropDownData,i,getNewData,chgPresetIndex)
            }
        }
        this.dealListDataFun(getNewData)
    }
    //处理数据--api--preset
    dealPresetAPIDataFun(resData,chgPresetIndex){
        const {keys3Data}=this.props
        const {keysDropDownData}=this.state
        let flagData=Object.keys(resData)
        let getNewData=this.resetListDataFun()
        for(let m in getNewData){
            for(let i in flagData){
                if(getNewData[m]['key']==flagData[i]){
                    // keys3Data
                    if(keys3Data.includes(flagData[i])){
                        this.dealKeys3DataFun(flagData,getNewData,resData,i,m)
                    }else{
                        let keyData=resData[flagData[i]]
                        let keyDataLen = Object.keys(keyData)
                        let data=getNewData[m]['data']
                        this.dealGetNewDataFun(data,keyDataLen,keyData,keysDropDownData,i,getNewData,chgPresetIndex)
                    }
                }
            }
        }
        this.dealListDataFun(getNewData)
    }
    dealKeys3DataFun(flagData,getNewData,resData,i,m){
        let keyData=resData[flagData[i]]
        let data=getNewData[m]['data']
        let boolean=keyData.length==data.length?true:false
        getNewData[m]['isSelcetAll']=boolean
        getNewData[m]['isSelcetAllTemp']=boolean
        for(let j in data){
            data[j]['checked']=false
            data[j]['checkedTemp']=false
            for(let k in keyData){
                if(data[j]['key']==keyData[k]){
                    data[j]['checked']=true
                    data[j]['checkedTemp']=true
                }
            } 
        }
    }
    dealGetNewDataFun(data,keyDataLen,keyData,keysDropDownData,i,getNewData,chgPresetIndex){
        let boolean=keyDataLen.length==data.length?true:false
        getNewData[i]['isSelcetAll']=boolean
        getNewData[i]['isSelcetAllTemp']=boolean   
        for(let j in data){
            data[j]['checked']=false
            data[j]['checkedTemp']=false
            for(let k in keyDataLen){
                if(data[j]['key']==keyDataLen[k]){
                    data[j]['checked']=true
                    data[j]['checkedTemp']=true
                    // keysDropDownData--有下拉框
                    if(keysDropDownData.includes(keyDataLen[k])){
                        let keysDropDown = Object.keys(keyData[keyDataLen[k]])
                        let dropDownData=data[j]['dropDownData']
                        let chgDropDownData=keyData[keyDataLen[k]]
                        let selectIndex=0,chgData=[],selectData=[]
                        // 预设
                        if(chgPresetIndex!==null){
                            for(let m in chgDropDownData){
                                chgData.push(chgDropDownData[m]) 
                            }
                            for(let n in chgData){
                                if(chgData[n]['select']){
                                    selectIndex=Number(n)
                                    selectData=chgData[n]['select']
                                    break
                                }
                            }
                            data[j]['isDropDownIndex']=selectIndex
                            data[j]['dropDownTitle']=dropDownData[selectIndex]['name']
                        }
                        let minMax=keyData[keyDataLen[k]][keysDropDown[selectIndex]]
                        let getMin4=common._truevalue2logvalue(minMax['min'])
                        let getMax4=common._truevalue2logvalue(minMax['max'])
                        let min4=common.keepNum(minMax['min'],2)
                        let max4=common.keepNum(minMax['max'],2)
                        let selects=minMax['select']

                        data[j]['min']=Number(minMax['min'])
                        data[j]['max']=Number(minMax['max'])
                        data[j]['logValue']=[getMin4,getMax4]
                        data[j]['minMax']=[getMin4,getMax4]
                        data[j]['range']=[min4,max4]
                        data[j]['trueValue']=[min4,max4]
                        data[j]['trueValueTemp']=[min4,max4]
                        
                        if(selects){
                            let getMin=common._truevalue2logvalue(selects[0])
                            let getMax=common._truevalue2logvalue(selects[1])
                            let min0=common.keepNum(selects[0],2)
                            let max0=common.keepNum(selects[1],2)

                            data[j]['logValue']=[getMin,getMax]
                            data[j]['minMax']=[getMin4,getMax4]
                            data[j]['range']=[min0,max0]
                            data[j]['trueValue']=[min0,max0]
                            data[j]['trueValueTemp']=[min0,max0]
                        }
                        for(let n in dropDownData){
                            for(let m in keysDropDown){
                                if(dropDownData[n]['key']==keysDropDown[m]){
                                    let keys=keyData[keyDataLen[k]][keysDropDown[m]]
                                    let getMin0=common._truevalue2logvalue(keys['min'])
                                    let getMax0=common._truevalue2logvalue(keys['max'])
                                    let min1=common.keepNum(keys['min'],2)
                                    let max1=common.keepNum(keys['max'],2)
                                    let selects1=keys['select']

                                    dropDownData[n]['min']=getMin0
                                    dropDownData[n]['max']=getMax0
                                    dropDownData[n]['logValue']=[getMin0,getMax0]
                                    dropDownData[n]['minMax']=[getMin0,getMax0]
                                    dropDownData[n]['range']=[min1,max1]  
                                    dropDownData[n]['trueValue']=[min1,max1]   
                                    dropDownData[n]['trueValueTemp']=[min1,max1]
                                    
                                    if(selects1){
                                        let getMin1=common._truevalue2logvalue(selects1[0])
                                        let getMax1=common._truevalue2logvalue(selects1[1])
                                        let min2=common.keepNum(selects1[0],2)
                                        let max2=common.keepNum(selects1[1],2)

                                        dropDownData[n]['logValue']=[getMin1,getMax1]
                                        dropDownData[n]['minMax']=[getMin0,getMax0]
                                        dropDownData[n]['range']=[min2,max2]
                                        dropDownData[n]['trueValue']=[min2,max2]
                                        dropDownData[n]['trueValueTemp']=[min2,max2]
                                    }
                                }
                            }
                        }
                    // 没有下拉框    
                    }else{
                        // ESGScoreGrade
                        if(data[j]['key']=='ESGScoreGrade'){
                            data[j]['leftValue']=keyData[keyDataLen[k]]['min']
                            data[j]['rightValue']=keyData[keyDataLen[k]]['max']
                        }else{
                            if(typeof(keyData[keyDataLen[k]]['min'])!=='undefined'){
                                let min0=keyData[keyDataLen[k]]['min']
                                let max0=keyData[keyDataLen[k]]['max']
                                let min1=common.keepNum(min0,2)
                                let max1=common.keepNum(max0,2)
                                let getMin2=common._truevalue2logvalue(min0)
                                let getMax2=common._truevalue2logvalue(max0)
                                
                                data[j]['min']=getMin2
                                data[j]['max']=getMax2
                                data[j]['logValue']=[getMin2,getMax2]
                                data[j]['minMax']=[getMin2,getMax2]
                                data[j]['range']=[min1,max1]
                                data[j]['trueValue']=[min1,max1]
                                data[j]['trueValueTemp']=[min1,max1]

                                let selectData=keyData[keyDataLen[k]]['select']

                                if(selectData){
                                    let getMin3=common._truevalue2logvalue(selectData[0])
                                    let getMax3=common._truevalue2logvalue(selectData[1])
                                    let min2=common.keepNum(selectData[0],2)
                                    let max2=common.keepNum(selectData[1],2)

                                    data[j]['logValue']=[getMin3,getMax3]
                                    data[j]['minMax']=[getMin2,getMax2]
                                    data[j]['range']=[min2,max2]
                                    data[j]['trueValue']=[min2,max2]
                                    data[j]['trueValueTemp']=[min2,max2]
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
    //处理数据--listData
    dealListDataFun(item,chgPresetShow){
        const {keys3Data,isMobile}=this.props
        const {isSaveCriteria,isPresetShow,isgetPresetIndex,isChgCheckboxShow,isOnload,presetIndex}=this.state
        let allData=[],rangeListData=[],allRangeData=[]
        for(let i in item){
            if(!keys3Data.includes(item[i]['key'])){
                allRangeData[i]=common.dealCheckedData(item[i]['data'])
            }
            allData[i]=common.dealCheckedData(item[i]['data'])
            let len=Number(allData[i].length)
            let boolean=len==item[i]['data'].length?true:false
            item[i]['len']=len
            item[i]['lenTemp']=len
            item[i]['isSelcetAll']=boolean
            item[i]['isSelcetAllTemp']=boolean
        }
        for(let i in item){
            item[i]['resultData']=allData
            item[i]['resultDataTemp']=allData
        }
        for(let j in allRangeData){
            if(allRangeData[j].length>0){
                for(let k in allRangeData[j]){
                    rangeListData.push(allRangeData[j][k])
                }
            }
        }
        this.setState({
            isSaveCriteria:!isSaveCriteria,
            rangeListData:rangeListData,
            listData:item,
            tipsData:allData,
            isLoading:false
        })
        // 获取到最新的数据-listData并执行右边列表的数据
        if(isPresetShow||chgPresetShow=='Y'){ 
            this.setState({
                isgetPresetIndex:!isgetPresetIndex,
                isChgCheckboxShow:!isChgCheckboxShow,
                isPresetShow:false,
                isOnload:false
            })
            if(!isOnload&&isMobile||!isMobile||presetIndex&&isMobile){
                this.props.cbIsGetResultShow(item,presetIndex==null?'':'btn')
            }
            //ios终端
            !isOnload&&isMobile&&this.props.cbIsShowHeaderPage(false)
        }
    }
    //处理数据 --result--选中/未选中--处理rangeListData数据
    dealResultDataFun(data){
        const {keys3Data}=this.props
        const {isChgCheckboxShow}=this.state
        let allData=[],rangeListData=[],allRangeData=[]
        for(let i in data){
            if(!keys3Data.includes(data[i]['key'])){
                allRangeData[i]=common.dealCheckedData(data[i]['data'])
            }
            allData[i]=common.dealCheckedData(data[i]['data'])
        }
        for(let j in allRangeData){
            if(allRangeData[j].length>0){
                for(let k in allRangeData[j]){
                    rangeListData.push(allRangeData[j][k])
                }
            }
        }
        this.setState({
            listData: data,
            tipsData:allData,
            rangeListData:rangeListData,
            isChgCheckboxShow:!isChgCheckboxShow,
            isSetOnLoad_0:false
        })
    }
    // 删除某个criteria
    deleteRangeData(data,index){
        const {keys3Data}=this.props
        const {listData,rangeListData,isChgCheckboxShow,isDeleteIitem}=this.state
        let allData=[]

        rangeListData.splice(index,1)
        
        for(let i in listData){
            let getData=listData[i]['data']
            for(let j in getData){
                if(getData[j]['key']==data['key']){
                    getData[j]['checked']=false
                    getData[j]['checkedTemp']=false
                   
                    if(!keys3Data.includes(listData[i]['key'])){
                        
                        if(typeof(getData[j]['min'])!=='undefined'){
                            getData[j]['trueValue']=[]
                            getData[j]['trueValueTemp']=[]
                            let dropDownData=getData[j]['dropDownData']

                            if(typeof(dropDownData)!=='undefined'){
                                getData[j]['trueValue']=[]
                                getData[j]['trueValueTemp']=[]
                                for(let k in dropDownData){
                                    dropDownData[k]['trueValue']=[]
                                    dropDownData[k]['trueValueTemp']=[]
                                }
                            }
                        }
                    }
                }
            }
        }
        for(let i in listData){
            allData[i]=common.dealCheckedData(listData[i]['data'])
            listData[i]['len']=Number(allData[i].length)
            listData[i]['lenTemp']=Number(allData[i].length)
        }
        for(let i in listData){
            listData[i]['resultData']=allData
        }
        this.setState({
            listData:listData,
            tipsData:allData,
            rangeListData:rangeListData,
            isChgCheckboxShow:!isChgCheckboxShow,
            isDeleteIitem:!isDeleteIitem,
            isSetOnLoad_0:false
        })
    }
    //获取API数据
    getData(pathName,obj,chgPresetIndex,tag){
        fetch(common.getHttp+pathName,{
            method:'POST',
            headers:{
                'Accept':'application/json,text/plain,*/*',
                'Content-Type':'application/json'
            },
            body:JSON.stringify(obj)
        })
        .then(res => res.json())
        .then(res => {
            if(res['status']==0){
                let resData=res['data']
                chgPresetIndex==null?this.dealAPIDataFun(resData,chgPresetIndex):this.dealPresetAPIDataFun(resData,chgPresetIndex)
                if(tag=='btn'){
                    this.setState({
                        isSetOnLoad_0: false,
                        isSetOnLoad_1:false,
                        isSaveBtnShow:true
                    }) 
                }
                return
            }
            this.setState({
                isLoading: false
            })
        })
    }
    // 获取结果
    getResultsFun(){
        const {isMobile} = this.props
        const {criteriaNum,listData,rangeListData,isGetResultShow} = this.state
        if(criteriaNum==0||isGetResultShow) return
        if(rangeListData.length){
            for(let i in rangeListData){
                let trueValue=rangeListData[i]['trueValue']
                if(typeof(trueValue)!=='undefined'&&(trueValue[0]==''||trueValue[1]=='')){
                    this.setState({
                        isTipsModalShow: true,
                        tipsModalData:rangeListData[i]
                    })
                    return
                }
            }
        } 
        this.props.cbIsGetResultShow(listData,'btn')
        //ios终端
        isMobile&&this.props.cbIsShowHeaderPage(false)
    }
    //reset all
    resetClick(tag){
        const {isResetAll,presetList,quickData,strategyData}=this.state
        let data=this.resetListDataFun()
        let getPreset=this.chgTabAllDataFun(presetList)
        let quick=this.chgTabAllDataFun(quickData)
        let strategy=this.chgTabAllDataFun(strategyData)
        // reset
        if(tag=='reset'){
            this.setState({
                isResetAll:!isResetAll,
                criteriaNum:0,
                presetIndex:null,
                quickIndex:null,
                strategyIndex:null,
                rangeListData:[],
                tipsData:[],
                listData:data,
                presetList:getPreset,
                quickData:quick,
                strategyData:strategy,
                isPresetShow:false,
                isSaveBtnShow:false,
                isLoading: false,
                resultTitle:'No Filters',
                isDelImgShow:false,
                isDelImgShowTemp:false
            })
        // noData
        }else{
            this.setState({
                isResetAll:!isResetAll,
                criteriaNum:0,
                rangeListData:[],
                tipsData:[],
                listData:data,
                presetList:getPreset,
                quickData:quick,
                strategyData:strategy,
                isPresetShow:false,
                isSaveBtnShow:false,
                isLoading: false,
                isDelImgShow:false,
                isDelImgShowTemp:false
            })
            this.props.cbIsGetResultShow(data,'btn','strategyNoData')
        }
        this.props.cbResetFun()
    }
    // Save this Screener 
    SaveScreenerClick(){
        this.setState({
            isStrategyShow:true
        })
    }
    //恢复为默认的数据--listData
    resetListDataFun(){
        const {keys3Data}=this.props
        const {listData}=this.state
        for(let i in listData){
            listData[i]['isShowAll']=true
            listData[i]['isSelcetAll']=false
            listData[i]['isSelcetAllTemp']=false
            listData[i]['isResultChecked']=true
            let data=listData[i]['data']
            for(let j in data){
                data[j]['checked']=false
                data[j]['checkedTemp']=false
                let dropDownData=data[j]['dropDownData']

                if(typeof(dropDownData)!=='undefined'){
                    data[j]['isDropDownShow']=false
                    data[j]['chgDropDownImg']=dropDownImg
                    data[j]['dropDownTitle']=dropDownData[0]['name']
                    data[j]['isDropDownIndex']=0
                    data[j]['trueValue']=[]
                    data[j]['trueValueTemp']=[]
                    
                    for(let k in dropDownData){
                        dropDownData[k]['trueValue']=[]
                        dropDownData[k]['trueValueTemp']=[]
                    }
                }
                if(data[j]['key']=='ESGScoreGrade'){
                    data[j]['leftValue']='A+'
                    data[j]['rightValue']='D-'
                    data[j]['rightValue']='D-'
                    data[j]['leftIndex']=0
                    data[j]['rightIndex']=11
                }
            }
            if(!keys3Data.includes(listData[i]['key'])){
                for(let k in data){
                    if(typeof(data[k]['min'])!=='undefined'){
                        data[k]['trueValue']=[]
                        data[k]['trueValueTemp']=[]
                    }
                }
            }
        }
        return listData 
    }
    resetPresetListFun(){
        const {presetList}=this.state
        let preset=this.chgTabAllDataFun(presetList)
        this.setState({
            presetList:preset
        })
    }
    // 改变数组里的某条数据
    chgTabDataFun(data,index,total,boolean){
        data[index]['num']=total
        data[index]['isShow']=boolean
        return data
    }
    //恢复为默认的数据
    chgTabAllDataFun(data){
        for(let i in data){
            data[i]['num']=0
            data[i]['isShow']=false
        }
        return data
    }
    //恢复为默认的数据--alls
    chgTabAllDatasFun(){
        const {presetList,quickData,strategyData}=this.state
        let preset=this.chgTabAllDataFun(presetList)
        let quick=this.chgTabAllDataFun(quickData)
        let strategy=this.chgTabAllDataFun(strategyData)
        this.setState({
            presetList:preset,
            quickData:quick,
            strategyData:strategy
        })
    }
    // 点击tab切换不同的title数据
    dealTitleDataFun(index){
        const {quickData,strategyData,quickIndex,strategyIndex,isDelImgShowTemp} = this.state
        let data=index==0?quickData:strategyData
        this.setState({
            presetList:data,
            titleIndex:index,
            presetIndex:index==0?quickIndex:strategyIndex,
            isDelImgShow:index==0?false:isDelImgShowTemp
        })
    }
    // 处理个数
    dealChgCriteriaNum(num,index){
        const {resultTitle,isCriteriaShow,isSetOnLoad_1,isStrategyBtnShow}=this.state
        this.setState({
            criteriaNum: num,
            resultTitle:isCriteriaShow&&index==null?num==0?'No Filters':'New Screener':resultTitle,
            isSaveBtnShow:isSetOnLoad_1?false:num==0?false:isStrategyBtnShow?false:true
        })
        setTimeout(()=>{
            this.setState({
                isStrategyBtnShow:false
            })
          }, 500)
    }
    // 点击presetIndex
    chgPresetIndexFun(item,index){
        const {presetIndex,titleIndex,quickData,strategyData} = this.state
        let quick=[],strategyd=[]
        let tabData=titleIndex==0?quickData:strategyData
        if(index==presetIndex) return 
        index=Number(index)
        this.resetPresetListFun()
        this.setState({
            presetIndex: index,
            isLoading: true,
            isPresetShow:true,
            quickIndex:titleIndex==0?index:null,
            strategyIndex:titleIndex==1?index:null,
            resultTitle:tabData[index]['name'],
            isSaveBtnShow:false,
            isSetOnLoad_0:true
        })
        // Quick Screeners
        if(titleIndex==0){
            strategyd=this.chgTabAllDataFun(strategyData)
            this.setState({
                strategyData: strategyd,
                isSetOnLoad_1:true,
                isDelImgShow:false,
                isDelImgShowTemp:false
            })
            let obj={
                preset:index+1, 
                token:sessionStorage.getItem('token')
            }
            this.getData('screener/preset',obj,index)
            return
        }
        // My Saved Screeners
        let chgData=[]
        let allStrategy=localStorage.getItem('strategyData')
        quick=this.chgTabAllDataFun(quickData)
        this.setState({
            quickData: quick,
            strategyId:item['id'],
            isStrategyBtnShow:true
        })
        // 空
        if(allStrategy==null){
            this.setState({
                isDelImgShow:false,
                isDelImgShowTemp:false
            })
            this.resetClick('noData')
            return
        }
        allStrategy=JSON.parse(allStrategy)
        let getData=allStrategy.find(allStrategy=>allStrategy['id']==item['id'])
        chgData=getData['data']
        // 对应某个有值
        if(chgData.length){
            this.setState({
                listData: chgData,
                isDelImgShow:true,
                isDelImgShowTemp:true
            })
            this.dealListDataFun(chgData,'Y')
            return
        }
        this.setState({
            isDelImgShow:false,
            isDelImgShowTemp:false
        })
        this.resetClick('noData')
        
    }
    // 删除对应的存储数据
    delListDataFun(){
        const {strategyId,isResetAll}=this.state
        let getData=JSON.parse(localStorage.getItem('strategyData'))
        let resData=getData.find(item=>item['id']==strategyId)
        resData['data']=[]
        localStorage.setItem('strategyData',JSON.stringify(getData))
        let data=this.resetListDataFun()
        this.setState({
            isResetAll:!isResetAll,
            criteriaNum:0,
            rangeListData:[],
            tipsData:[],
            listData:data,
            isPresetShow:false,
            isSaveBtnShow:false,
            isLoading: false
        })
        this.props.cbResetFun()
    }
    //点击外部关闭弹框
    handleClick = e => {
        const {isClickBtn}=this.state
        if(!this.testComponent.contains(e.target)){
            this.setState({
                isCriteriaShow:isClickBtn?true:false
            })
            setTimeout(()=>{
                this.setState({
                    isClickBtn:false
                })
            },100)
        }
    }
    //回到顶部
    backTopFun(){
        let current=this.myLeftRef.current
        current.scrollTop=0
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {getTotal,isGetResultBtnShow}=this.props
        const {presetIndex,presetList,titleIndex,quickData,strategyData}=this.state
        if(getTotal!==newProps['getTotal']){
            if(presetIndex!==null){
                let list=[],quick=[],strategyd=[]
                list=this.chgTabDataFun(presetList,presetIndex,getTotal,true)
                if(titleIndex==0){
                    quick=this.chgTabDataFun(quickData,presetIndex,getTotal,true)
                    
                }else{
                    strategyd=this.chgTabDataFun(strategyData,presetIndex,getTotal,true)
                }
                this.setState({
                    presetList: list,
                    quickData:quick.length?quick:quickData,
                    strategyData:strategyd.length?strategyd:strategyData,
                })
            }
        }
        if(isGetResultBtnShow!==newProps['isGetResultBtnShow']){
            this.setState({
                isGetResultShow: isGetResultBtnShow
            })
        }
    }
    //初始化
    componentDidMount(){
        const {listData}=this.state
        this.dealCriteriaDataFun(listData,'onload',null,null)
        document.addEventListener('click', this.handleClick) 
    }
    //卸载
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }
    render(){
        const {t,ChgThemeData,isMobile} = this.props
        const {presetList,isCriteriaShow,presetIndex,criteriaNum,isSaveCriteria,isResetAll,isChgCheckboxShow,rangeListData,isLoading,listData,tipsData,isDeleteIitem,isgetPresetIndex,isTipsModalShow,tipsModalData,ischeckAll,titleData,isStrategyShow,quickIndex,strategyIndex,strategyData,isSaveBtnShow,resultTitle,isDelImgShow,isSetOnLoad_0} = this.state 
        return (
            <div
                className={[
                    APPstyles.flex_column,
                    isMobile?APPstyles.flex1_auto:''
                ].join(' ')}
                style={{
                    height:'100%'
                }}
            >
            <div 
                className={[
                    styles.screener_left_tab,
                    APPstyles.flex_topcenter,
                    ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
                ].join(' ')}
            >
                <Tabs
                    {...this.props}
                    tabData={titleData}
                    tag='screener'
                    callbackTabIndex={this.dealTitleDataFun.bind(this)}
                />
            </div>
            <div
                ref={this.myLeftRef} 
                className={[
                    styles.left_con,
                    isMobile?'':APPstyles.flex1_auto
                ].join(' ')}
                style={{
                    height:isMobile?'auto':'100%'
                }}
            >
                {/* preset--list */}
                <PresetList
                    {...this.props}
                    presetList={presetList}
                    presetIndex={presetIndex}
                    cbChgPresetIndexFun={this.chgPresetIndexFun.bind(this)}
                />
                <ResultsCon
                    {...this.props}
                    isSaveCriteria={isSaveCriteria}
                    isResetAll={isResetAll}
                    criteriaNum={criteriaNum}
                    rangeListData={rangeListData}
                    listData={listData}
                    isLoading={isLoading}
                    quickIndex={quickIndex}
                    strategyIndex={strategyIndex}
                    resultTitle={resultTitle}
                    isDelImgShow={isDelImgShow}
                    cbResultData={this.dealResultDataFun.bind(this)}
                    cbDeleteData={this.deleteRangeData.bind(this)}
                    cbDelListDataFun={this.delListDataFun.bind(this)}
                    cbChgRangeListData={(data)=>{
                        this.setState({
                            rangeListData:data,
                            isSetOnLoad_0:false,
                            isSaveBtnShow:true
                        })
                    }}
                    cbChgRangeListData_1={(data)=>{
                        this.setState({
                            rangeListData:data
                        })
                    }}
                    cbIscheckAllFun={()=>{
                        this.setState({
                            ischeckAll:!ischeckAll
                        })
                    }}
                    cbEditTitleFun={(tag)=>{
                        titleData[1]['name']=tag=='Y'?'Edit Screener':'My Saved Screeners'
                        this.setState({
                            titleData:titleData,
                            isDelImgShow:tag=='Y'?false:isDelImgShow
                        })
                    }}
                    cbChgNumFun={(num)=>{
                        this.setState({
                            criteriaNum:num,
                            isSaveBtnShow:num==0?false:true
                        })
                    }}
                />
                { isTipsModalShow&&<TipsModal
                    {...this.props}
                    tag='nodata'
                    tipsModalData={tipsModalData}
                    cbCloseModal={()=>{
                        this.setState({
                            isTipsModalShow: false
                        })
                    }}
                /> }
            </div>
            <div 
                className={[
                    styles.criteria_con,
                    ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
                    ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
                ].join(' ')}
            >
                <div 
                    className={[
                        styles.criteria_btn,
                        APPstyles.fw700,
                        APPstyles.flex_center,
                        ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                        ChgThemeData=='Light'?styles.criteria_btn_light:''
                    ].join(' ')}
                    onClick={(e)=>{
                        e.stopPropagation()
                        for(let i in rangeListData){
                            rangeListData[i]['isDropDownShow']=false
                            rangeListData[i]['dropDownShowLeft']=false
                            rangeListData[i]['dropDownShowRight']=false
                        }
                        this.setState({
                            isCriteriaShow:true,
                            rangeListData:rangeListData
                        })
                        this.props.cbCriteriaShow()
                        //ios终端
                        isMobile&&this.props.cbIsShowHeaderPage(false)
                    }} 
                >
                    <img 
                    src={ChgThemeData=='Light'?sliderLightImg:sliderImg} 
                    className={APPstyles.img24} alt='slider' 
                    />
                    {t('Set Screener Criteria')} ({criteriaNum})
                </div>
                <div 
                    className={[
                        styles.criteria_btn,
                        APPstyles.fw700,APPstyles.flex_center,
                        criteriaNum!==0&&!isSetOnLoad_0?APPstyles.btn_blue:ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.btn_black,
                        criteriaNum!==0&&!isSetOnLoad_0?APPstyles.bg_blue:ChgThemeData=='Light'?styles.criteria_btn_light:'',
                        criteriaNum!==0&&!isSetOnLoad_0?'':styles.criteria_btn_notallowed,
                    ].join(' ')}
                    style={{margin:'8px 0'}}
                    onClick={this.getResultsFun.bind(this)}
                >
                    {t('Get Results')}
                </div>
                <div className={[styles.criteria_reset,ChgThemeData=='Light'?APPstyles.reset_light:''].join(' ')}>
                    {!isSaveBtnShow&&<span onClick={this.resetClick.bind(this,'reset')}>{t('Reset All')}</span>}
                    {isSaveBtnShow&&<span onClick={this.SaveScreenerClick.bind(this)}>{t('Save this Screener')}</span>}
                </div>
            </div>
            <div ref = {testComponent => this.testComponent = testComponent} style={{display:isCriteriaShow?'':'none'}}>
                <CriteriaCon 
                    {...this.props}
                    CriteriaTag='screener'
                    isCriteriaShow={isCriteriaShow}
                    isResetAll={isResetAll}
                    isChgCheckboxShow={isChgCheckboxShow}
                    listData={listData}
                    tipsData={tipsData}
                    isDeleteIitem={isDeleteIitem}
                    isgetPresetIndex={isgetPresetIndex}
                    ischeckAll={ischeckAll}
                    cbCriteriaData={this.dealCriteriaDataFun.bind(this)}
                    cbChgCriteriaNum={this.dealChgCriteriaNum.bind(this)}
                    cbCriteriaNum2={this.dealChgCriteriaNum.bind(this)}
                    cbIsShowHeaderPage={(data)=>{
                        this.props.cbIsShowHeaderPage(data)
                    }} 
                    cbCriteriaHide={()=>{
                        this.setState({
                            isCriteriaShow: false
                        })
                        this.props.cbCriteriaShow()
                    }}
                    cbCriteriaShow={()=>{
                        this.setState({
                            isClickBtn: true
                        })
                        
                    }}
                />
            </div>
            {isStrategyShow&&<SwitchModal
                {...this.props}
                switchTag='screener'
                cancelText='Cancel'
                sureText='Save'
                switchTitle='Save Strategy'
                switchName='Name1'
                screenerData={strategyData}
                listData={listData}
                cbCloseModal={(tag)=>{
                    this.setState({
                        isStrategyShow:false,
                        isSaveBtnShow:tag=='Y'?false:isSaveBtnShow
                    })
                }}
            />}
        </div>
    )}
}
export default withTranslation()(observer(LeftCon))