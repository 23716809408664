export const defaultChartSetting = {
    chartType: 'candlestick',
    tapane: [
        {
            "ta": "Volume",
            "name": "成交量 (Volume)",
            "option": {
                "upColor": "F5475B",
                "downColor": "39C46E",
                "height": "1"
            }
        }
    ],
    "overlay-1min": [],
    "chartType-1min": 'line',
    "tapane-1min": [{
        "ta": "Volume",
        "name": "成交量 (Volume)",
        "option": {
            "upColor": "F5475B",
            "downColor": "39C46E",
            "height": "1"
        }
    }],
    overlay: [{
        "ta": "SMA",
        "name": "簡單移動平均 (SMA)",
        "option": [
            {
                "period": 5,
                "color": "0099FF",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 10,
                "color": "FF6600",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 20,
                "color": "00FF00",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 60,
                "color": "FFFF00",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 200,
                "color": "449999",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": false
            }
        ],
        value: "5,10,20,60,200"
    }],
    period: "1min",
    periodOption: '',
    lineOption: {
        "lineColor": "F8F800",
        "lineFillColor": "99CCFF",
        "lineFillColor2": "FFFFFF",
        "lineGradientEnable": false,
        "lineStyle": "s",
        "lineThickness": 2,
        "showTracker": false
    },
    candlestickOption: {
        "upColor": "FFFFFF",
        "upFillColor": "FFFFFF",
        "downColor": "F5475B",
        "downFillColor": "F5475B"
    },
    barOption: {
        "barColor": "0033CC"
    },
    histogramOption: {
        "upColor": "FFFFFF",
        "upFillColor": "FFFFFF",
        "downColor": "F5475B",
        "downFillColor": "F5475B"
    },
    drawToolOption: {
        drawLineColor: "0080FF",
        drawLineThickness: 2,
        drawLineStyle: "s",
        drawRetracementColor: "0080FF",
        drawRetracementThickness: 2,
        drawRetracementStyle: "s",
        drawFanColor: "0080FF",
        drawFanThickness: 2,
        drawFanStyle: "s",
        drawArcsColor: "0080FF",
        drawArcsThickness: 2,
        drawArcsStyle: "s",
    },
    SMA_Option: {
        option: [
            {
                "period": 5,
                "color": "0099FF",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 10,
                "color": "FF6600",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 20,
                "color": "00FF00",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 60,
                "color": "FFFF00",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": true
            },
            {
                "period": 200,
                "color": "449999",
                "thickness": 1,
                "style": "s",
                "showTracker": false,
                "enabled": false
            }
        ],
        value: "5,10,20,60,200"
    },
    WMA_Option: {
        option: [
            {
                "period": 3,
                "color": "0080FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 5,
                "color": "991100",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 25,
                "color": "9933FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 75,
                "color": "FFB0B0",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 200,
                "color": "449999",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            }
        ],
        value: "3,5,25,75,200"
    },
    EMA_Option: {
        option: [
            {
                "period": 3,
                "color": "0080FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 5,
                "color": "991100",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 25,
                "color": "9933FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 75,
                "color": "FFB0B0",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 200,
                "color": "449999",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            }
        ],
        value: "3,5,25,75,200"
    },
    MEMA_Option: {
        option: [
            {
                "period": 3,
                "color": "0080FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 5,
                "color": "991100",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 25,
                "color": "9933FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 75,
                "color": "FFB0B0",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            {
                "period": 200,
                "color": "449999",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            }
        ],
        value: "3,5,25,75,200"
    },
    TVMA_Option: {
        option: {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true
        },
        value: 10
    },
    ICH_Option: {
        option: {
            "options": [
                {
                    "period": 9,
                    "color": "009999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 26,
                    "color": "BB1100",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "color": "CC9900",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 52,
                    "color": "FF00FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "color": "FFAAAA",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                }
            ],
            "upColor": "DDDDDD",
            "downColor": "DDDDDD"
        },
        value: "9,26,52"
    },
    MACD_Option: {
        "option": {
            "p1": 12,
            "p2": 26,
            "p3": 9,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "color3": "666666",
            "thickness3": 2,
            "style3": "s",
            "showTracker3": true,
            "upColor": "EE3311",
            "downColor": "449922",
            "height": "1"
        },
        "value": "12,26,9"
    },
    LRT_Option: {
        "option": {
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true
        }
    },
    SAR_Option: {
        "option": {
            "p1": 0.02,
            "p2": 0.02,
            "p3": 0.2,
            "style": "s",
            "color": "AA2211",
            "showTracker": false
        },
        "value": "Acceleration:0.02,0.02 Limit:0.2"
    },
    ADXR_Option: {
        "option": {
            "DMI": 14,
            "ADX": 6,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "14,6"
    },
    DMI_Option: {
        "option": {
            "enabled": true,
            "period": 14,
            "color": "EE3311",
            "color2": "449922",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1",
            "adx": {
                "enabled": true,
                "color": "0080FF",
                "thickness": 2,
                "style": "s",
                "showTracker": true
            }
        },
        "value": 14
    },
    Bias_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    ALF_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    BB_Option: {
        "option": {
            "BB": {
                "period": 25,
                "color": "666666",
                "thickness": 2,
                "style": "d",
                "showTracker": true,
                "enabled": true
            },
            "deviation": [
                {
                    "value": 1,
                    "color": "FFB0B0",
                    "color2": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "value": 2,
                    "color": "AA2211",
                    "color2": "66CCCC",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "value": 3,
                    "color": "FFB0B0",
                    "color2": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                }
            ]
        },
        "value": "25,1σ,2σ,3σ"
    },
    VC_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    ATR_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    SD_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 10
    },
    VltyCC_Option: {
        "option": {
            "period": 21,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 21
    },
    KRI_Option: {
        "option": {
            "options": [
                {
                    "period": 10,
                    "color": "AA2211",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 3,
                    "color": "0080FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                }
            ],
            "method": "sma",
            "module": "close",
            "height": "1"
        },
        "value": "sma,終値,10,3"
    },
    PL_Option: {
        "option": {
            "period": 9,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        }
    },
    CO_Option: {
        "option": {
            "period": 10,
            "period2": 3,
            "method": "ema",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "ema,10,3"
    },
    RCI_Option: {
        "option": {
            "period": 9,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 9
    },
    FKD_Option: {
        "option": {
            "K": 14,
            "D": 9,
            "color": "009999",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "color2": "BB1100",
            "thickness2": 2,
            "style2": "s",
            "showTracker2": true,
            "buy": 25,
            "sale": 75,
            "height": "1"
        },
        "value": "14,9"
    },
    SKD_Option: {
        "option": {
            "K": 14,
            "D": 9,
            "color": "009999",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "color2": "BB1100",
            "thickness2": 2,
            "style2": "s",
            "showTracker2": true,
            "method": "sma",
            "buy": 25,
            "sale": 75,
            "height": "1"
        },
        "value": "sma,14,9"
    },
    CCI_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    MI_Option: {
        "option": {
            "period": 25,
            "period2": 9,
            "method": "ema",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "25,9"
    },
    UPDW_Option: {
        "option": {
            "idx": ".AD.DJI",
            "height": "1",
            "options": [
                {
                    "period": 5,
                    "color": "991100",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 25,
                    "color": "0080FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                }
            ]
        },
        "value": "5,25"
    },
    UO_Option: {
        "option": {
            "period": 7,
            "period2": 14,
            "period3": 28,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "buy": 30,
            "sale": 70,
            "height": "1"
        },
        "value": "7,14,28"
    },
    Vector_Option: {
        "option": {
            "p1": 14,
            "p1Enable": true,
            "p1Color": "AA2211",
            "p1Thickness": 2,
            "p1Style": "s",
            "p1ShowTracker": true,
            "p2": 9,
            "p2Enable": true,
            "p2Color": "0080FF",
            "p2Thickness": 2,
            "p2Style": "s",
            "p2ShowTracker": true,
            "height": "1"
        },
        "value": "14,9"
    },
    DPO_Option: {
        "option": {
            "period": 20,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 20
    },
    WAD_Option: {
        "option": {
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        }
    },
    Trix_Option: {
        "option": {
            "p1": 15,
            "p1Color": "EE3311",
            "p1Thickness": 2,
            "p1Style": "s",
            "p1ShowTracker": true,
            "p2": 9,
            "p2Color": "0080FF",
            "p2Thickness": 2,
            "p2Style": "s",
            "p2ShowTracker": true,
            "height": "1"
        },
        "value": "15,9"
    },
    Volume_Option: {
        "option": {
            "upColor": "F5475B",
            "downColor": "39C46E",
            "height": "1"
        }
    },
    VE_Option: {
        "option": {
            "period": 14,
            "p": 6,
            "volume": "808080",
            "color": "AA2211",
            "color2": "449999",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "14,6%"
    },
    VAP_Option: {
        "option": {
            "color": "AA2211",
            "color2": "0080FF"
        }
    },
    VOSC_Option: {
        "option": {
            "shortPeriod": 9,
            "longPeriod": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "9,14"
    },
    VROC_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 10
    },
    ACD_Option: {
        "option": {
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        }
    },
    OBV_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        }
    },
    EOM_Option: {
        "option": {
            "period": 9,
            "method": "sma",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "sma,9"
    },
    FI_Option: {
        "option": {
            "period": 9,
            "method": "ema",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "ema,9"
    },
    MFI_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "buy": 25,
            "sale": 75,
            "height": "1"
        },
        "value": 14
    },
    NVI_Option: {
        "option": {
            "NVI": {
                "color": "AA2211",
                "thickness": 2,
                "style": "s",
                "showTracker": true
            },
            "atr": [
                {
                    "period": 72,
                    "color": "0080FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 255,
                    "color": "9933FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                }
            ],
            "height": "1"
        },
        "value": "72,255"
    },
    VR_Option: {
        "option": {
            "period": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    ENV_Option: {
        "option": {
            "mid": {
                "period": 20,
                "color": "666666",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            "factor": [
                {
                    "p": 2.5,
                    "color": "FFB0B0",
                    "color2": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                },
                {
                    "p": 5,
                    "color": "AA2211",
                    "color2": "66CCCC",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                },
                {
                    "p": 10,
                    "color": "FFB0B0",
                    "color2": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "p": 25,
                    "color": "AA2211",
                    "color2": "66CCCC",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                },
                {
                    "p": 50,
                    "color": "FFB0B0",
                    "color2": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                }
            ]
        },
        "value": "20,10%"
    },
    MOM_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 10
    },
    RMI_Option: {
        "option": {
            "period": 14,
            "ta": "mema",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "period2": 4,
            "buy": 25,
            "sale": 75,
            "height": "1"
        },
        "value": "mema,14,動量指標 期間4"
    },
    RSI_Option: {
        "option": {
            "buy": 25,
            "sale": 75,
            "atr": [
                {
                    "enabled": true,
                    "period": 14,
                    "ta": "ema",
                    "color": "008020",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true
                },
                {
                    "enabled": false,
                    "period": 9,
                    "ta": "ema",
                    "color": "9933FF",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true
                }
            ],
            "height": "1"
        },
        "value": "ema-14,ema-9"
    },
    WR_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 10
    },
    POSC_Option: {
        "option": {
            "shortPeriod": 9,
            "longPeriod": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "9,14"
    },
    PROC_Option: {
        "option": {
            "period": 10,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 10
    },
    PRR_Option: {
        "option": {
            "color": "808080"
        }
    },
    VWAP_Option: {
        "option": {
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true
        }
    },
    WC_Option: {
        "option": {
            "weight": 2,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true
        },
        "value": 2
    },
    PCV_Option: {
        "option": {
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        }
    },
    TP_Option: {
        "option": {
            "period": 5,
            "color": "FFFFFF"
        },
        "value": 5
    },
    RC_Option: {
        "option": {
            "period": 14,
            "index": ".DJI",
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 14
    },
    CC_Option: {
        "option": {
            "period": 10,
            "longPeriod": 11,
            "shortPeriod": 14,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": "10,11,14"
    },
    KC_Option: {
        "option": {
            "mid": {
                "period": 14,
                "color": "666666",
                "thickness": 2,
                "style": "s",
                "showTracker": true,
                "enabled": true
            },
            "atr": [
                {
                    "period": 10,
                    "color": "FFB0B0",
                    "color1": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                },
                {
                    "period": 10,
                    "color": "AA2211",
                    "color1": "66CCCC",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": true
                },
                {
                    "period": 10,
                    "color": "FFB0B0",
                    "color1": "449999",
                    "thickness": 2,
                    "style": "s",
                    "showTracker": true,
                    "enabled": false
                }
            ]
        },
        "value": "14,1xATR(10),2xATR(10),3xATR(10)"
    },
    UI_Option: {
        "option": {
            "period": 9,
            "color": "AA2211",
            "thickness": 2,
            "style": "s",
            "showTracker": true,
            "height": "1"
        },
        "value": 9
    }
}