import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Button} from 'antd'
import { 
    leftImg,
    leftLightImg,
    remeberOnImg,
    remeberOffImg,
} from '../../../../../asserts/imgs/common/index'
import APPstyles from '../../../../../App.less'
import styles from './index.less'

let count = 0 //定义一个计数器

class dropDown extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            listData:[],
            isSelcetAll:false,
            listIndex:0,
            eventData:[]
        }
    }
    //保存--mobile
    saveClick(e){
        const {tag} = this.props
        const {isSelcetAll,listData,eventData}=this.state
        e.stopPropagation()
        if(tag=='event'){
            let num=isSelcetAll?listData.length:0
            // All
            if(eventData.length==num){
                this.props.cbSelectData(['All',num,listData])
                return
            }
            // 单选
            this.props.cbSelectData([eventData,count,listData])
        }
    }
    //全选/全不选
    isSelectAllClick(e){
        const {isMobile} = this.props
        const {isSelcetAll,listData,eventData}=this.state
        e.stopPropagation()
        if(listData.length<=0) return
        this.setState(state => ({
            isSelcetAll: !state.isSelcetAll
        }))
        for(let i in listData){
            listData[i]['checked']=isSelcetAll?false:true
            eventData[i]=listData[i]['key']
        }
        this.setState({
            listData: listData,
            eventData:isSelcetAll?[]:eventData
        })
        if(!isMobile){
            let num=isSelcetAll?0:listData.length
            this.props.cbSelectData(['All',num])
        }
    }
    //单选/时间选择
    checkedOneClick(data,index,e){
        const {tag,isMobile}=this.props
        const {listData,eventData}=this.state
        count = 0//定义一个计数器
        e.stopPropagation()
        // 时间选择--time
        if(tag=='time'){
            this.setState({
                listIndex: index
            })
            this.props.cbSelectData(data)
            return
        }
        // event
        // 单选
        for(let i in listData){
            if(index==i){
                listData[i]['checked']=listData[i]['checked']?false:true 
                //传值
                if(listData[i]['checked']){//add
                    eventData.push(data['key'])
                }else{//del
                    for(let j in eventData){
                        if(data['key']==eventData[j]){
                            eventData.splice(j,1)
                        }
                    }
                }
            }
            //全选/全不选
            if(!listData[i]['checked']){
                this.setState({
                    isSelcetAll: false
                })
            }else{
                count++
            }
            //判断是否都是选中状态/如果是则自动选中全选按钮
            if(count == listData.length){
                this.setState({
                    isSelcetAll: true
                })
            }
        }
        this.setState({
            listData: listData,
            eventData:eventData
        })
        if(!isMobile){
            this.props.cbSelectData([eventData,count])
        }
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isReset,eventTypes}=this.props
        if(isReset!==newProps['isReset']){
            this.setState({
                isSelcetAll:false,
                listIndex:0,
                eventData:eventTypes
            })
        }
    }
    //初始化
    componentDidMount(){
        const {dropDownData,eventTypes} = this.props
        this.setState({
            listData: dropDownData,
            eventData:eventTypes
        }) 
    }
    render(){
        const {t,ChgThemeData,isDropDownShow,tag,isMobile,isTablet} = this.props
        const {listData,isSelcetAll,listIndex}=this.state
        return (
            <div>
                {
                    isDropDownShow?
                        <div
                            className={[
                                styles.dropDown_con,
                                APPstyles.flex_column,
                                isMobile&&tag=='event'&&isDropDownShow?APPstyles.position_fixed:'',
                                isMobile&&tag=='event'&&isDropDownShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                            ].join(' ')} 
                        >
                            <div
                                className={[
                                    styles.dropDown_header,
                                    APPstyles.flex_row,
                                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                                ].join(' ')} 
                                style={{
                                    display:isMobile&&tag=='event'&&isDropDownShow?'':'none'
                                }}
                            >
                                <img 
                                    src={ChgThemeData=='Light'?leftLightImg:leftImg} 
                                    className={APPstyles.img24} 
                                    onClick={(e)=>{
                                        e.stopPropagation() 
                                        this.props.cbDropDownHidden(false)
                                        
                                    }} 
                                    alt='back' 
                                />
                                {t('Select Events')}
                            </div>
                            <div 
                                className={[
                                    styles.dropDown,
                                    APPstyles.flex1_auto,
                                    !isMobile||tag=='time'?styles.dropDown_PC:'',
                                    !isMobile||tag=='time'?ChgThemeData=='Light'?APPstyles.dropDown_light:APPstyles.dropDown_black:''
                                ].join(' ')} 
                                style={{
                                    display:isDropDownShow?'':'none',
                                    height:!isMobile?tag=='event'?258:240:tag=='event'?'100%':240,
                                    padding:tag=='event'?isMobile?0:'8px 0':0
                                }}
                                onClick={(e)=>{
                                    e.stopPropagation()
                                }}
                            >
                                <div
                                    className={[
                                        APPstyles.flex_row,
                                        styles.item,
                                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                        ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black
                                    ].join(' ')}
                                    style={{
                                        display:tag=='event'?'':'none',
                                        paddingTop:isTablet?0:4
                                    }}
                                    onClick={this.isSelectAllClick.bind(this)} 
                                >
                                    <img
                                        src={isSelcetAll?remeberOnImg:remeberOffImg}
                                        className={[
                                            APPstyles.img24
                                        ].join(' ')}
                                        alt='checkbox'
                                    />
                                    {t('Select All')}
                                </div>
                                {
                                    listData.map((item,index) => {
                                
                                    return  <div
                                                key={index}
                                                className={[
                                                    APPstyles.flex_row,
                                                    styles.item,
                                                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                                                    ChgThemeData=='Light'?APPstyles.btn_hover_light:APPstyles.btn_black,
                                                    tag=='time'&&listIndex==index?ChgThemeData=='Light'?styles.active_light:styles.active:''
                                                ].join(' ')}
                                                style={{
                                                    padding:tag=='event'?'4px 16px':'12px 16px'
                                                }}
                                                onClick={this.checkedOneClick.bind(this,item,index)} 
                                            >
                                                <img
                                                    src={item['checked']?remeberOnImg:remeberOffImg}
                                                    className={[
                                                        APPstyles.img24
                                                    ].join(' ')}
                                                    style={{display:tag=='event'?'':'none'}}
                                                    alt='checkbox'
                                                />
                                                {t(item['name'])}
                                            </div>
                                    })
                                }
                            </div>
                            <div 
                                className={[
                                    APPstyles.flex_row,
                                    APPstyles.place_button,
                                    styles.dropDown_con_btn,
                                    ChgThemeData=='Light'?APPstyles.box_shadow_light:APPstyles.box_shadow_black
                                ].join(' ')}
                                style={{
                                    display:isMobile&&tag=='event'&&isDropDownShow?'':'none'
                                }}
                            >
                                <Button 
                                    type='default' 
                                    className={APPstyles.btn_gray} 
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        this.props.cbDropDownHidden(false)
                                    }}
                                >
                                    {t('Close')}
                                </Button>
                                <Button 
                                    type='default' 
                                    className={[APPstyles.btn_blue,APPstyles.bg_blue].join(' ')}
                                    onClick={this.saveClick.bind(this)} 
                                >
                                    {t('Save Settings')}
                                </Button>
                            </div>
                        </div>
                    :
                        ''
                }
            </div>
        )}
}
export default withTranslation()(observer(dropDown))