import React from 'react';
import { Button,Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg,
} from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from '../note/index.less';

class RiskIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgCloseImg:closeImg,
    }
  }
  btnClick(sign){
    this.props.cbBtnDataFun(sign);
  }
  onMouseOver = () => {
    const {ChgThemeData} = this.props;
    let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg;
    this.updateImage(imgurl);
  };
  onMouseOut = () => {
    this.updateImage(closeImg);
  };
  updateImage(imgurl) {
    const {isPC} = this.props;
    if(!isPC) return;
    this.setState({
      chgCloseImg: imgurl,
    });
  };
  render(){
    const {t,isMobile,ChgThemeData,modalData,visible}=this.props;
    const {chgCloseImg}=this.state;
    return (
      <Modal 
        centered={true}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={visible}
        className={[ChgThemeData==='Light'&&styles.modalLight,isMobile&&styles.modalMobile].join(' ')}
      >
        <div className={[styles.noteTitle,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData==='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span>{t(modalData['title'])}</span>
          <img 
            src={chgCloseImg} 
            className={APPstyles.img24} 
            onClick={()=>this.btnClick('N')}
            onMouseOver={() => this.onMouseOver()}
            onMouseOut={() => this.onMouseOut()}
          />
        </div>
        <div className={[styles.noteContent,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
          {t(modalData['content'])}
        </div>
        <div className={[styles.noteButton,isMobile&&styles.noteButtonM,APPstyles.flex_row].join(' ')}>
          <Button className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} onClick={()=>this.btnClick('N')}>
            {t('Cancel')}
          </Button>
          <Button 
            className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
            onClick={()=>this.btnClick('Y')}
          >
            {t(modalData['sureText'])}
          </Button>
        </div>
      </Modal>
  )}
}
export default withTranslation()(RiskIndex);