import React from 'react'
import { withTranslation } from 'react-i18next'
import TradeComponents from '../index'
import AlertsComponents from '@/components/Alerts/index'
import Reminder from '../Reminder/index'
import Disclaimer from '../Disclaimer/index'
import Confirmation from '../Confirmation/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class OrderMask extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isShowPageData:[false,false,false,false],
      buySellIndex:null,
      minH800:800,
      minH900:900,
      closeAllDropDown:false
    }
  }
  //提交订单--所有的数据
  calkbackOrderData(data){
    this.props.calkbackOrderData(data)
  }
  // modal显示/隐藏--切换显示哪个/下单成功
  calkbackBtnStatus(data){
    this.setState({
      isShowPageData:data
    })
    this.props.calkbackBtnStatus(data)
  }
  callbackChgNavIndex(data){
    this.props.callbackChgNavIndex(data)
  }
  //关闭所有的下拉框
  closeAllClick(){
    this.setState(state => ({
      closeAllDropDown: !state.closeAllDropDown
    }))
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {tradeData,tradeOrderData}=this.props
    if(tradeData!==newProps['tradeData']){
      this.setState({
        isShowPageData:tradeData
      })
    }
    if(tradeOrderData!==newProps['tradeOrderData']){
      this.setState({
        buySellIndex:tradeOrderData.length>0?tradeOrderData[0]['buySellIndex']:0
      })
    }
  }
  render(){
    const {ChgThemeData,bodyH, ricData, isOpenAlertModalInTabletMode,isPC,isMobile,isTablet,alertsPage,tradeOrderData} = this.props
    const {isShowPageData,buySellIndex,minH800,closeAllDropDown,minH900}=this.state
    let getData=tradeOrderData[0]
    let isSwitchFirstShow=sessionStorage.getItem('isSwitchFirstShow');
    return (
      <div 
        className={[
          styles.mask_con,
          APPstyles.mask_con_fixed,
          isShowPageData[0]?'':APPstyles.display_none
        ].join(' ')} 
        style={{
          zIndex:isSwitchFirstShow=='Y'?99:999999999,
          background:isSwitchFirstShow=='Y'?'none':'rgba(0, 0, 0,.7)'
        }}
        onClick={this.closeAllClick.bind(this)}
      >
        <div 
          style={{
            width:!isMobile?528:'100%',
            border:0,
            display:isShowPageData[1]?'':'none',
            height:!isMobile?(!isOpenAlertModalInTabletMode&&bodyH<=minH900)||(isOpenAlertModalInTabletMode&&bodyH<=700)?('80%'):(isOpenAlertModalInTabletMode?560:778):'100%'
          }}
        >
          {isOpenAlertModalInTabletMode?
            isShowPageData[0] ? <AlertsComponents {...this.props} ricData={ricData} /> : <></>
            :
            <TradeComponents 
              {...this.props}
              isShowPageData={isShowPageData}
              closeAllDropDown={closeAllDropDown}
              calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
              calkbackOrderData={this.calkbackOrderData.bind(this)} 
              callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
              callbackTradeShow={(tag,data)=>{
                this.props.callbackTradeShow(tag,data)
              }}
              cbGotoPage_1={()=>{
                this.setState({
                  isShowPageData:[false,false,false,false]
                })
                this.props.cbGotoPage_1()
              }}
              cbSwitchMoalShow={(data)=>{
                this.props.cbSwitchMoalShow(data);
              }}
            />
          }
        </div>
        <div
          className={[isPC?'':APPstyles.mask_content,isPC?'':APPstyles.wPer100].join(' ')} 
          style={{
            display:isShowPageData[2]?'':'none',
            height:getData&&(getData['isShowGTC']&&(getData['isADR']==0||(getData['isADR']==1&&(getData['isClickADR']||getData['countryCode']!=='US'))))?isPC?bodyH<=700?'80%':'auto':'80%':'auto'
          }}
        >
          <div style={{height:'100%',display:getData&&(getData['isShowGTC']&&(getData['isADR']==0||(getData['isADR']==1&&(getData['isClickADR']||getData['countryCode']!=='US'))))?'none':''}}>
            <Reminder 
              {...this.props}
              buySellIndex={buySellIndex}
              calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            />
          </div>
          <div style={{height:'100%',display:getData&&(getData['isShowGTC']&&(getData['isADR']==0||(getData['isADR']==1&&(getData['isClickADR']||getData['countryCode']!=='US'))))?'':'none'}}>
            <Disclaimer 
              {...this.props}
              buySellIndex={buySellIndex}
              calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            />
          </div>
        </div>
        <div 
          className={ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black}
          style={{
            display:isShowPageData[3]?'':'none',
            width:!isMobile?527:'100%',
            height:!isMobile?bodyH<=minH800?'80%':isPC?728:712:'100%'
          }} 
        >
          <Confirmation 
            {...this.props}
            calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
            cbSubmitOrderFun={()=>{
              this.props.cbSubmitOrderFun()
            }}
          />
        </div>
      </div> 
  )}
}
export default withTranslation()(OrderMask)