import React from 'react';
import { withTranslation } from 'react-i18next';
import { 
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg
} from '@/asserts/imgs/common/index';
import DropDown from './dropDown/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props); 
    const currentYear = new Date().getFullYear(); 
    this.state = {
      leftImg:dropDownImg,
      rightImg:dropDownImg,
      leftData:{isShow:false,title:currentYear - 11,id:'from'+currentYear - 11,name:'left'},
      rightData:{isShow:false,title:currentYear,id:'to'+currentYear - 10,name:'right'},
      fromYearOptions: this.generateYearOptions('from',currentYear - 11, currentYear - 1),
      toYearOptions: this.generateYearOptions('to',currentYear - 10, currentYear),
    }
  }
  // 下拉框显示/隐藏
  btnClick = (e, sign) => {
    e.stopPropagation();
    this.setState(prevState => {
      let newLeftData = { ...prevState.leftData };
      let newRightData = { ...prevState.rightData };
      if (sign === 'left') {
        newLeftData['isShow'] = !prevState['leftData']['isShow'];
        newRightData['isShow'] = false;
      }else{
        newRightData['isShow'] = !prevState['rightData']['isShow'];
        newLeftData['isShow'] = false;
      }
      return {
        leftData: newLeftData,
        rightData: newRightData,
      };
    });
  };
  // 下拉框选中值
  cbDropDownItem(sign,item){
    let {leftData,rightData}=this.state;
    if(sign=='left'){
      leftData['isShow']=false;
      leftData['id']=item['key'];
      leftData['title']=item['value'];
    }else{
      rightData['isShow']=false;
      rightData['id']=item['key'];
      rightData['title']=item['value'];
    }
    this.setState({
      leftData,
      rightData,
    });
    this.props.cbDateFun(sign,String(item['value']));
  }
  // 下拉框数组
  generateYearOptions = (sign,start, end) => {
    const years = [];
    for (let year = start; year <= end; year++) {
      let obj={
        value:year,
        key:sign+year,
      }
      years.push(obj);
    }
    return years;
  };
  //点击外部关闭下拉框
  handleOutsideClick = (e, targetComponent) => {
    let {leftData,rightData}=this.state;
    if(targetComponent){
      if (!targetComponent.contains(e.target)) {
        leftData['isShow']=false;
        rightData['isShow']=false;
        this.setState({
          leftData,
          rightData,
        });
      }
    }
  };
  // 鼠标移入移出切换图片
  handleMouse(sign,isHover) {
    const { ChgThemeData,isPC } = this.props;
    if (!isPC) return;
    let imgurl;
    if (isHover) {
      imgurl = ChgThemeData === 'Light' ? dropDownOnLightImg : dropDownOnImg;
    } else {
      imgurl = dropDownImg;
    }
    this.setState({ 
      [sign]: imgurl, 
    });
  }
  componentDidMount() {
    document.addEventListener('click', (e) => this.handleOutsideClick(e, this.leftComponent));
    document.addEventListener('click', (e) => this.handleOutsideClick(e, this.rightComponent));
  }
  componentWillUnmount() {
    document.removeEventListener('click', (e) => this.handleOutsideClick(e, this.leftComponent));
    document.removeEventListener('click', (e) => this.handleOutsideClick(e, this.rightComponent));
  }
  render() {
    const {t,ChgThemeData,isPC} = this.props;
    const { leftImg,rightImg,leftData,rightData,fromYearOptions,toYearOptions} = this.state;
    return (
      <div className={[APPstyles.flex_row,styles.dropDownCon,!isPC&&styles.dropDownConTabet,ChgThemeData=='Light'?APPstyles.calendar_light:APPstyles.bg_backTop_black].join(' ')}>
        <div 
          className={[styles.yearCon,APPstyles.flex_1].join(' ')}
          onClick={(e)=>this.btnClick(e,'left')}
          onMouseOver={() => this.handleMouse('leftImg',true)} 
          onMouseOut={() => this.handleMouse('leftImg',false)}
        >
          <div className={APPstyles.flex_between}>
            <span className={[APPstyles.fz12,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{marginLeft:16}}>{leftData['title']}</span>
            <img 
              src={leftImg} 
              className={styles.dropDownImg} 
              style={{transform:leftData['isShow']?'rotate(-180deg)':'rotate(0deg)'}}
            />
          </div>
          <div ref={testComponent=>this.leftComponent=testComponent}>
            <DropDown 
              {...this.props}
              selectData={leftData}
              optionsData={fromYearOptions}
              cbDropDownItem={(sign,data)=>this.cbDropDownItem(sign,data)} 
            />
          </div>
        </div>
        <span className={[styles.to,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{t('to')}</span>
        <div 
          className={[styles.yearCon,APPstyles.flex_1].join(' ')}
          onClick={(e)=>this.btnClick(e,'right')}
          onMouseOver={() => this.handleMouse('rightImg',true)} 
          onMouseOut={() => this.handleMouse('rightImg',false)}
        >
          <div className={APPstyles.flex_between}>
            <span className={[APPstyles.fz12,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{rightData['title']}</span>
            <img 
              src={rightImg} 
              className={styles.dropDownImg} 
              style={{transform:rightData['isShow']?'rotate(-180deg)':'rotate(0deg)',marginRight:16}}
            />
          </div>
          <div ref={testComponent=>this.rightComponent=testComponent}>
            <DropDown 
              {...this.props} 
              selectData={rightData}
              optionsData={toYearOptions}
              cbDropDownItem={(sign,data)=>this.cbDropDownItem(sign,data)} 
            />
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Index);