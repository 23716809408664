import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";

export const Turnover = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("808080");
  const [height, setHeight] = useState("1");

  useEffect(() => {
    props.onTAValueChange({
      ta: "Turnover",
      name: `${t("chart5.TA.Turnover")} (Turnover)`,
      option: { color: color, height: height },
    });
  }, [color, height]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    resetALL();
  }, [props.isReset]);

  const resetALL = () => {
    setColor("AA2211");
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "T":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "T", t("chart5.setting"));
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("Turnover")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.Turnover")} (Turnover)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("Turnover")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem" id="msi-ta-Turnover">
          <div className="taparamlbl">{t("chart5.setting")}</div>
          <button
            id="msi-ta-Turnover-t-color"
            className="taparam colorpicker"
            value={color}
            style={{ backgroundColor: "#" + color }}
            onClick={onColorClick}
          ></button>
        </div>
        <div className="menusubitem" id="msi-ta-Turnover">
          <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
          <div className="selectbox taparam">
            <select
              id="msi-ta-Turnover-ps-h"
              defaultValue="1"
              onChange={(e) => onHeightChange(e)}
            >
              <option value="2">{t("chart5.large")}</option>
              <option value="1">{t("chart5.normal")}</option>
              <option value="0">{t("chart5.small")}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
