import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const SAR = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState(0.02);
  const [period2, setPeriod2] = useState(0.02);
  const [period3, setPeriod3] = useState(0.2);
  const [color, setColor] = useState("AA2211");
  const [showTracker, setShowTracker] = useState(false);
  const [value, setValue] = useState(
    `${t("chart5.acceleration")}:` +
    period2 +
    "," +
    period +
    ` ${t("chart5.limit")}:` +
    period3
  );
  const [style, setStyle] = useState("s");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.SAR_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const SAR = localChartSetting.option;

        setValue(
          `${t("chart5.acceleration")}:` +
          SAR.p2 +
          "," +
          SAR.p1 +
          ` ${t("chart5.limit")}:` +
          SAR.p3
        );
        props.onOverlayValueChange({
          ta: "SAR",
          name: `${t("chart5.TA.SAR")} (SAR)`,
          option: {
            p1: SAR.p1,
            p2: SAR.p2,
            p3: SAR.p3,
            style: SAR.style,
            color: SAR.color,
            showTracker: SAR.showTracker,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const SAR = props.localChartSetting.SAR_Option.option;

    setPeriod(parseFloat(SAR.p1) || period);
    setPeriod2(parseFloat(SAR.p2) || period);
    setPeriod3(parseFloat(SAR.p3) || period);
    setColor(SAR.color);
    setShowTracker(SAR.showTracker);

  }, [props.localChartSetting]);


  useEffect(() => {
    setValue(
      `${t("chart5.acceleration")}:` +
      period2 +
      "," +
      period +
      ` ${t("chart5.limit")}:` +
      period3
    );
    props.onOverlayValueChange({
      ta: "SAR",
      name: `${t("chart5.TA.SAR")} (SAR)`,
      option: {
        p1: period,
        p2: period2,
        p3: period3,
        style: style,
        color: color,
        showTracker: showTracker,
      },
      value: value,
    });
  }, [period, period2, period3, color, showTracker]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const SAR = props.localChartSetting.SAR_Option.option;

    setPeriod(parseFloat(SAR.p1) || period);
    setPeriod2(parseFloat(SAR.p2) || period);
    setPeriod3(parseFloat(SAR.p3) || period);
    setColor(SAR.color);
    setShowTracker(SAR.showTracker);
  }, [props.isReset]);

  const resetALL = () => {
    setPeriod(0.02);
    setPeriod2(0.02);
    setPeriod3(0.2);
    setColor("AA2211");
    setShowTracker(false);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    if (type === "p1") setPeriod(e.target.value);
    else if (type === "p2") setPeriod2(e.target.value);
    else setPeriod3(e.target.value);
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  const onStyleChange = (e) => {
    setStyle(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "SAR":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "SAR", "表示設定");
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("SAR")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.SAR")} (SAR) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("SAR")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-SAR">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.initialValue")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-SAR-p0-period0"
                type="number"
                step="0.01"
                min="0.01"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-SAR-p0-color0"
              className="colorpicker"
              value={color}
              data-colorpickerdialogtitle="表示設定"
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
            {/* <div className="selectbox" onChange={(e) => onStyleChange(e)}>
                            <select id="msi-ta-UI-p0-style">
                                <option value="s" >実線</option>
                                <option value="d">点線</option>
                            </select>
                        </div> */}
            <div className="showtracker">
              <div
                id="msi-ta-SAR-p0-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={onShowTracker}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-SAR">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.unitOfAcceleration")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-SAR-p1-period0"
                type="number"
                step="0.01"
                min="0.01"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="menusubitem " id="msi-ta-SAR">
          <div className="flexbox">
            <div className="taparamlbl">
              {t("chart5.upperLimitOfAcceleration")}
            </div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-SAR-p2-period0"
                type="number"
                step="0.01"
                min="0.01"
                value={period3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
