import React from 'react'
import { withTranslation } from 'react-i18next'
import { Slider } from 'antd'
import common from '@/asserts/js/common'
import { 
  leftImg,
  leftLightImg,
  fontSizeMinImg,
  fontSizeMaxImg,
  fontSizeImg,
  fontSizeOnImg,
  fontSizeOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Right extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
     chgValue:14,
     minValue:12,
     maxValue:24,
     chgLineHeight:20,
     isChgSizeShow:false,
     chgSizeImg:fontSizeImg
    }
    this.myRightRef_0 = React.createRef()
    this.myRightRef_1 = React.createRef()
  }
  // 回到顶部
  goBackTopRight0Fun(){
    let current=this.myRightRef_0.current
    current.scrollTop=0
  }
  // 回到顶部
  goBackTopRight1Fun(){
    let current=this.myRightRef_1.current
    current.scrollTop=0
  }
  gotoFun(){
    const {newTag,isMobile,isTablet,isPC,currentData23,currentIndex} = this.props
    if((newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)||(newTag=='quote'&&isPC&&currentData23&&currentData23.includes(currentIndex))){
      this.goBackTopRight1Fun()
    }else{
      this.goBackTopRight0Fun()
    }
  }
  // 更改slider的值
  setValueChange(value){
    this.setState({
      chgValue:value,
      chgLineHeight:value+2
    })
    localStorage.setItem('fontSizeValue',value)
  }
  // tooltip 显示对应的值
  tipFormatterClick(value){
    const {minValue,maxValue}=this.state
    let num=maxValue-minValue
    let newValue=(value-minValue) / num
    let resValue=common.keepNum(newValue*100+100,0)+'%'
    return resValue
  }
  chgFontSizeFun(){
    let value=localStorage.getItem('fontSizeValue')
    value=Number(value)
    if(value){
      this.setState({
        chgValue:value,
        chgLineHeight:value+2
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isChgRightDataShow,ricData,ChgThemeData} = this.props
    const {isChgSizeShow}=this.state
    if(
      isChgRightDataShow!==newProps['isChgRightDataShow']||
      ricData!==newProps['ricData']
    ){
      this.gotoFun()
    }
    if(ChgThemeData!==newProps['ChgThemeData']){
      let chgImg=ChgThemeData=='Light'?fontSizeOnLightImg:fontSizeOnImg
      this.setState({
        chgSizeImg:isChgSizeShow?chgImg:fontSizeImg
      })
    }
  }
  //初始化
  componentDidMount(){
    this.gotoFun() 
    this.chgFontSizeFun() 
  }
  render() {
    const {t,isPC,isMobile,isTablet,ChgThemeData,rightData,isLoading,newTag,currentData23,currentIndex,isDetailShow,chgRightPageShow} = this.props
    const {chgValue,minValue,maxValue,chgLineHeight,isChgSizeShow,chgSizeImg}=this.state
    return (
      <div 
        ref={this.myRightRef_0}
        className={[
          (newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)||(newTag=='quote'&&isPC&&currentData23&&currentData23.includes(currentIndex))?APPstyles.flex1_hidden:APPstyles.flex1_auto,
          newTag=='quote'&&isTablet?APPstyles.position_fixed:'',
          newTag=='quote'&&isTablet?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
        ].join(' ')} 
        style={{position: 'relative'}}
      >
        <div 
          className={[
            APPstyles.flex_column,
            styles.news_right,
            isTablet?styles.news_tablet_right:'',
            (newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)?styles.news_isMobile_right:'',
            (newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)||(newTag=='quote'&&isPC&&currentData23&&currentData23.includes(currentIndex))?APPstyles.HPer100:'',
            newTag!=='quote'&&isPC?APPstyles.wPer60:'',
            newTag=='quote'&&isPC?currentData23&&currentData23.includes(currentIndex)&&isDetailShow?'':chgRightPageShow?'':APPstyles.wPer80:''
          ].join(' ')}
          style={{
            margin:(isMobile&&newTag!=='quote')||
                    newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)||
                    (newTag=='quote'&&isTablet)||
                    chgRightPageShow
                  ?
                    0
                  :
                    '16px auto'
          }}
        >
          <div 
            className={[
              APPstyles.flex_topcenter,
              styles.right_header,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
            ].join(' ')}
            style={{
              display:(isMobile&&newTag!=='quote')||(newTag=='quote'&&isTablet)||(isPC&&newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)&&isDetailShow)||chgRightPageShow?'':'none'
            }}
          >
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
              style={{
                width:24,
                height:24,
                cursor:'pointer'
              }} 
              onClick={()=>{
                this.props.cbDetailCloseFun() 
              }} 
              alt='back' 
            />
            {t('News Detail')}
          </div>
          <div 
            ref={this.myRightRef_1}
            className={[
              styles.news_con_right,
              (newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)||(newTag=='quote'&&isPC&&currentData23&&currentData23.includes(currentIndex))?APPstyles.flex1_auto:''
            ].join(' ')}
          >
            <div 
              className={[
                isPC&&newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)&&isDetailShow||chgRightPageShow?styles.news_con_right2:'',
                (newTag!=='quote'&&isMobile)||(newTag=='quote'&&isTablet)?APPstyles.paddingBottom30:''
              ].join(' ')}
            >
              {rightData?
                <div>
                  <span className={[styles.news_right_title,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>{rightData&&rightData['title']}</span>
                  <div
                    className={[
                      styles.news_right_date,
                      APPstyles.flex_between
                    ].join(' ')}
                  >
                    <span className={styles.news_date_left}>{rightData&&rightData['creator']} &nbsp; {rightData&&rightData['sorting_date_tw']}</span>
                    <img 
                      src={chgSizeImg}
                      style={{cursor:'pointer'}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        this.setState({
                          chgSizeImg:ChgThemeData=='Light'?fontSizeOnLightImg:fontSizeOnImg
                        })
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let chgImg=ChgThemeData=='Light'?fontSizeOnLightImg:fontSizeOnImg
                        this.setState({
                          chgSizeImg:isChgSizeShow?chgImg:fontSizeImg
                        })
                      }} 
                      onClick={()=>{
                        let chgImg=ChgThemeData=='Light'?fontSizeOnLightImg:fontSizeOnImg
                        this.setState({
                          isChgSizeShow:!isChgSizeShow,
                          chgSizeImg:isChgSizeShow?fontSizeImg:chgImg
                        })
                      }}
                    />
                  </div>
                  <div
                    className={[
                      styles.news_slider,
                      APPstyles.flex_topcenter,
                      ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_backTop_black
                    ].join(' ')}
                    style={{
                      width:isPC?newTag=='quote'&&currentData23&&currentData23.includes(currentIndex)?'100%':'72%':'100%',
                      display:isChgSizeShow?'':'none'
                    }}
                  >
                    <img src={fontSizeMinImg} />
                    <div 
                    className={[
                      styles.news_slidercon,
                      APPstyles.flex_1
                    ].join(' ')}
                    >
                      <Slider 
                        min={minValue} 
                        max={maxValue}
                        value={chgValue}
                        onChange={this.setValueChange.bind(this)} 
                        tipFormatter={this.tipFormatterClick.bind(this)} 
                      />
                    </div>
                    <img src={fontSizeMaxImg} />
                  </div>
                  <div 
                    className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_grey1,ChgThemeData=='Light'?styles.news_con_h6:''].join(' ')} 
                    style={{
                      fontSize:chgValue,
                      lineHeight:chgLineHeight+'px'
                    }}
                    dangerouslySetInnerHTML={{ __html:rightData&&rightData['content'] }}
                  ></div>
                </div>
              :
                <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Right)