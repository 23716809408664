import React from "react";
import { withTranslation } from "react-i18next"
import { Spin,Tooltip } from 'antd'
import common from '@/asserts/js/common'
import { 
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
  tipImg,
  tipOnImg,
  tipOnLightImg
} from '@/asserts/imgs/common/index'
import Mobile from '../Mobile'
import Header from "./Header/index"
import PaginationCon from '../Pagination/index'
import APPstyles from '@/App.less'
import styles from '@/pages/Account/index.less'

let chgPage=1

class DividendHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      history:[],
      summary:[],
      country_code:'US',
      page:1,
      stock_id:null,
      fromDate:'',
      toDate:'',
      sizeTotal:0,
      recperpage:20,
      isSort:false,
      total_shares_dividend:'',
      closeInput:false,
      loadMore:false,
      noMore:false,
      chgSort:'payable_date_desc',
      onLoad:true,
      minW1450:1450,
      isSortShow:false,
      currency:'',
      getDividendVal:'',
      getDividendCur:'',
      dropDownIndex:0,
      chgTipImg:tipImg,
      toolTipText:'海外股票現金股利或股票股利需待收到上手配發金額或股數，並核對無誤後才配發給客戶，如遇核對不一致須待查明確認後始可入帳，因此預計入帳日將可能變動'
    }
  }
  //排序
  sortClick(name,tag,sortName){
    const {sortData,isTablet,ChgThemeData}=this.props
    const {stock_id,page,history,fromDate,toDate,onLoad,chgSort,isLoading,isSortShow,currency,dropDownIndex}=this.state
    if(history.length<=0||(!onLoad&&chgSort==tag)||isLoading) return
    this.setState({
      isLoading: true,
      chgSort:tag,
      onLoad:false,
      loadMore:false,
      noMore:false,
      isSortShow:!isSortShow
    })
    let newData=common.getSortDataFun(sortData,ChgThemeData,name,sortName)
    chgPage=1
    let chgPn=isTablet?1:page
    this.getTableData(stock_id,chgPn,fromDate,toDate,tag,[],currency,dropDownIndex,'noload')
    this.props.cbChgSortDataFun(newData)
  }
  //获取数据API
  getTableData(stock_id,page,fromDate,toDate,sort,listsData,currency,index,onload,scroll) {
    const {recperpage}=this.state
    fetch(common.getHttpZs+"/getDividendHistory", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionStorage.getItem("token"),
        from_date: Number(fromDate),
        to_date: Number(toDate),
        recperpage:recperpage,
        stock_id:stock_id,
        page: page,
        sort:sort,
        currency:currency
      })
    })
    .then((res) => res.json())
    .then((result) => {
      if(result['status']==0){
        if(JSON.stringify(result['data']) !== '{}'){
          let total_dividend=result['data']['summary']['total_dividend']
          let total_shares_dividend=result['data']['summary']['total_shares_dividend']
          let datalist=result['data']['history']['datalist']
          let size_total=result['data']['history']['size_total']
          let dividendData=Object.keys(total_dividend)
          let flagData=Object.keys(common.imgArrFlag)
          let getRicData=[],getData=[],summary=[]
          this.setState({
            total_shares_dividend:total_shares_dividend
          })
          // 下拉框
          if(onload=='onload'){
            if(dividendData.length){
              for(let i in dividendData){
                for(let j in flagData){
                  if(dividendData[i]==flagData[j]){
                    getData.push({
                      cur:dividendData[i],
                      num:total_dividend[dividendData[i]],
                      img:common.imgArrFlag[dividendData[i]]
                    })
                  }
                }
              }
              summary=common.imgDataFlagFun(getData,'img')
              this.setState({
                summary:summary,
                getDividendVal:summary[index]['num'],
                getDividendCur:summary[index]['cur']
              })
            }else{
              this.setState({
                summary:[],
                getDividendVal:'',
                getDividendCur:''
              })
            }
          }
          // 数据
          if(datalist.length){
            for(let i in datalist){
              getRicData.push(datalist[i]['stock_id'])
            }
            if(getRicData.length==datalist.length){
              getRicData= [...new Set(getRicData)]//去重
              this.getsimplequoteFun(getRicData,datalist,listsData,page,size_total)
            }
            return
          }
          if(listsData.length){
            this.setState({
              isLoading:false,
              loadMore:false,
              noMore:true
            })
          }else{
            this.noDataFun2()
          }
          return
        }
        if(scroll=='scroll'){
          this.setState({
            loadMore:false,
            noMore:true
          })
        }else{
          this.noDataFun()
        }
        return
      }
      //获取失败
      this.noDataFun()
    })
  }
  //getsimplequote--获取数据
  getsimplequoteFun(ric,datalist,listsData,page,size_total){
    fetch(common.getHttp+'getsimplequote',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        ric:ric 
      })
    })
    .then((response) => {
      if(response.ok){
        return response.text()        
      }
      throw new Error('Something went wrong.')
    })
    .then(res => {
      //获取数据成功
      res=JSON.parse(res)
      if(res['status']==0){
        let resData=res['data']
        if(JSON.stringify(resData)!=='{}'){
          for(let i in datalist){
            for(let j in ric){
              if(datalist[i]['stock_id']==ric[j]){
                let data=resData[ric[j]]
                datalist[i]['stock_code']=data['symbol']
                datalist[i]['stock_name']=data['name']
              }
            }
          }
          this.commonDataFun(datalist,listsData,page,size_total)
          return
        }
        this.noDataFun()
        return
      }
      //获取失败
      this.noDataFun()
    })
    .catch((err) => {
      console.log('Request failed', err)
      this.commonDataFun(datalist,listsData,page,size_total)
    })
  }
  commonDataFun(datalist,listsData,page,size_total){
    const {isTablet}=this.props
    let pn1=datalist.length?datalist.length>=10?false:true:false
    let pnother=listsData.length?datalist.length?false:true:true
    let noMoreData=page==1?pn1:pnother
    
    if(datalist.length){
      for(let i in datalist){
        let net_dividend=datalist[i]['net_dividend']
        if (net_dividend&& net_dividend!== '-') {
          datalist[i]['net_dividend_len']=Number(String(net_dividend).split('.')[0].length+2)
        }
      }
    }
    this.setState({
      history: isTablet?listsData.concat(datalist):datalist,
      sizeTotal:size_total,
      isLoading:false,
      loadMore:false,
      noMore:noMoreData
    })
  }
  // 获取失败
  noDataFun(){
    this.setState({
      history: [],
      summary:[],
      sizeTotal:0,
      total_shares_dividend:0,
      isLoading:false,
      loadMore:false,
      noMore:false,
      getDividendVal:'',
      getDividendCur:''
    })
  }
  // 获取失败
  noDataFun2(){
    this.setState({
      history: [],
      sizeTotal:0,
      isLoading:false,
      loadMore:false,
      noMore:false
    })
  }
  //回传数据--input
  callbackInputData(data){
    const {fromDate,toDate,chgSort,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      stock_id:data[0],
      page:1
    })
    chgPage=1
    this.getTableData(data[0],1,fromDate,toDate,chgSort,[],currency,dropDownIndex,'onload')
  }
  //回传数据--日期
  callbackDateData(data){
    const {stock_id,chgSort,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      fromDate:data[0],
      toDate:data[1],
      page:1
    })
    chgPage=1
    this.getTableData(stock_id,1,data[0],data[1],chgSort,[],currency,dropDownIndex,'onload')
  }
  //回传数据--分页
  callbackPagekData(page){
    const {stock_id,fromDate,toDate,chgSort,currency,dropDownIndex}=this.state
    this.setState({
      isLoading:true,
      page:page
    })
    this.getTableData(stock_id,page,fromDate,toDate,chgSort,[],currency,dropDownIndex,'noload')
  }
  // currency
  chgCurrencyFun(data){
    const {stock_id,fromDate,toDate,chgSort}=this.state
    let cur=data[0]['cur']
    let index=data[1]
    this.setState({
      isLoading:true,
      currency:cur,
      page:1,
      getDividendVal:data[0]['num'],
      getDividendCur:cur,
      dropDownIndex:index
    })
    chgPage=1
    this.getTableData(stock_id,1,fromDate,toDate,chgSort,[],cur,index,'noload')
  }
  //滚动加载更多--isTablet/mobile
  cbChgDataFun(){
    const {loadMore,noMore,isLoading,fromDate,toDate,stock_id,history,chgSort,currency,dropDownIndex}=this.state
    if(loadMore||noMore||isLoading) return
    chgPage++
    this.setState({
      page: chgPage,
      loadMore:true,
      noMore:false
    })
    this.getTableData(stock_id,chgPage,fromDate,toDate,chgSort,history,currency,dropDownIndex,'noload','scroll')
  }
  //初始化数据
  componentDidMount() {
    const {from_date,to_date}=this.props
    const {stock_id,page,chgSort,currency,dropDownIndex}=this.state
    chgPage=1
    this.setState({
      fromDate: from_date,
      toDate: to_date,
      page: 1,
      loadMore:false,
      noMore:false
    })
    this.getTableData(stock_id,page,from_date,to_date,chgSort,[],currency,dropDownIndex,'onload')
  }
  render() {
    const { t,ChgThemeData,ChgLangData,isPC,isTablet,sortData,bodyW,isSearchPageShow } = this.props
    const { history,summary,isLoading,sizeTotal,total_shares_dividend,recperpage,page,loadMore,noMore,minW1450,isSortShow,getDividendCur,getDividendVal,chgTipImg,toolTipText} = this.state
    return (
      <div 
        className={[
          styles.account_layout,
          isPC&&isSearchPageShow?'':APPstyles.flex1_auto,
          isPC&&isSearchPageShow||isTablet?APPstyles.flex_column:'',
          isPC&&isSearchPageShow||isTablet?APPstyles.hPer_overflowH:'',
          isPC&&isSearchPageShow?'':isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':''
        ].join(' ')}
        style={{
          position:'relative'
        }}
      >
        {/* Header */}
        <Header
          {...this.props}
          summary={summary}
          sizeTotal={sizeTotal}
          recperpage={recperpage}
          page={page}
          history={history}
          isShow={false}
          total_shares_dividend={total_shares_dividend}
          getDividendCur={getDividendCur}
          getDividendVal={getDividendVal}
          callbackDateData={this.callbackDateData.bind(this)}
          callbackInputData={this.callbackInputData.bind(this)}
          callbackPagekData={this.callbackPagekData.bind(this)}
          cbChgCurrencyFun={this.chgCurrencyFun.bind(this)}
        />
        {
          isPC?
            <div
              className={[
                isPC&&isSearchPageShow?APPstyles.flex1_auto:'',
                isPC&&isSearchPageShow?isPC?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'':''
              ].join(' ')}
            >
              {/* header */}
              <div
                className={[
                  APPstyles.flex_row,
                  styles.account_header,
                  styles.header_pc,
                  styles.account_common,
                  styles.account_dividendhistory,
                  ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                  ChgLangData=='zh-CN'?styles.account_common_zhcn:'',
                  ChgLangData=='zh-CN'?APPstyles.fz12_zhcn:''
                ].join(" ")}
                style={{ padding: "4px 8px" }}
              >
                <div style={{ width: "4%" }}>{t("Type")}</div>
                <div style={{ width: bodyW<=minW1450?"5%":"4%" }}>{t("Country")}</div>
                <div>{t("Code")}</div>
                <div style={{ width: bodyW<=minW1450?"10%":"17%" }}>{t(ChgLangData == "zh-CN" ? "name" : "Name")}</div>
                <div style={{width: "8%",alignItems:'center'}}>
                  <span>{t("Payable Date")}</span>
                  <div 
                    className={[
                      APPstyles.upDown_con,
                      APPstyles.flex_column
                    ].join(' ')}
                  >
                    <div 
                      className={APPstyles.img_dropdown_up} 
                      onClick={()=>{this.sortClick('payable_date','payable_date_asc','asc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                        sortData[3]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[3]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                        sortData[3]['upImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[3]['upImg']} />
                    </div>
                    <div 
                      className={APPstyles.img_dropdown_down} 
                      onClick={()=>{this.sortClick('payable_date','payable_date_desc','desc')}}
                      onMouseOver={()=>{
                        if(!isPC) return
                        let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                        sortData[3]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }} 
                      onMouseOut={()=>{
                        if(!isPC) return
                        let imgurl=sortData[3]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                        sortData[3]['downImg']=imgurl
                        this.props.cgChgSortDataFun(sortData)
                      }}
                    >
                      <img src={sortData[3]['downImg']} />
                    </div>
                  </div>
                </div>
                <div style={{ width: "7%" }}>{t("Shares")}</div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ width: "6%" }}
                >
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Div Per")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Share")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Div Per Share")}
                  </span>
                </div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ width: bodyW<=minW1450?"10%":'8%' }}
                >
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Dividend")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Amount")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Dividend Amount")}
                  </span>
                </div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ width: bodyW<=minW1450?"10%":"8%" }}
                >
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Withholding")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Tax")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Withholding Tax")}
                  </span>
                </div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ width: "8%" }}
                >
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Issuer")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Bank’s Fee")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Issuer Bank’s Fee")}
                  </span>
                </div>
                <div style={{ width: bodyW<=minW1450?"10%":"8%" }}>{t("Net dividend")}</div>
                <div className={styles.account_header_totalcost}>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Paid")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Allotment")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Paid Allotment")}
                  </span>
                </div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ width: "7%" }}
                >
                  <span>{t("Paid Allotment")}</span>
                  <span>{t("Fee")}</span>
                </div>
                <div
                  className={styles.account_header_totalcost}
                  style={{ justifyContent: "flex-end" }}
                >
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Dividend in")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "none" : "" }}>
                    {t("Shares")}
                  </span>
                  <span style={{ display: ChgLangData == "zh-CN" ? "" : "none" }}>
                    {t("Dividend in Shares")}
                  </span>
                </div>
              </div>
              {/* body */}
              <div 
                className={history.length>0?'':APPstyles.minH300}
                style={{
                  position:!history.length&&!isLoading?'relative':'unset'
                }}
              >
                {/* 加载中 */}
                <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
                {
                  history.length>0?

                  history.map((item, index) => {
                    let chgCur=Number(item['currency'])==0?common.imgShortNameChgCur[item['country']]:item['currency']
                      return (
                        <div
                          key={index}
                          className={[
                            APPstyles.flex_row,
                            styles.account_body,
                            styles.account_common,
                            styles.account_dividendhistory,
                            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                            ChgThemeData=='Light'?APPstyles.bg_event_light:APPstyles.bg_1b1b1b_hover,
                            ChgThemeData=='Light'?styles.account_body_light:'',
                            ChgLangData=='zh-CN'?styles.account_common_zhcn:''
                          ].join(" ")}
                        >
                          <div
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{whiteSpace: "nowrap", width: "4%" }}
                          >
                            {common.charAtToUpperCase(t(item['type']))}
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{ width: bodyW<=minW1450?"5%":"4%" }}>{t(common.valToUpperCase(item['country']))}</div>
                          <div 
                            className={[ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,APPstyles.fw700].join(' ')}
                            style={{ justifyContent: "flex-start"}}
                          >
                            {common.valToUpperCase(item['stock_code'])}
                          </div>
                          <div
                            className={[ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur,APPstyles.fw500].join(' ')}
                            style={{
                              width: bodyW<=minW1450?"10%":"17%",
                              justifyContent: "flex-start",
                            }}
                          >
                            {common.valToUpperCase(item['stock_name'])}
                          </div>
                          <div 
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{width: "8%" }}
                          >
                            {common.onlyOrderDate(item['payable_date'])}
                          </div>
                          <div 
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} 
                            style={{ width: "7%" }}
                          >
                            {common.transThousands(common.keepNum(item['shares'],0))}
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} style={{ width: "6%" }}>{common.transThousands(common.keepNum(item['div_per_shares'],2))}</div>
                          <div 
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} 
                            style={{width: bodyW<=minW1450?"10%":"8%" }}
                          >
                            {chgCur}
                            {common.transThousands(common.keepNum(item['div_amount'],2))}
                          </div>
                          <div
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{width: bodyW<=minW1450?"10%":"8%"}}
                          >  
                            {chgCur}
                            {common.transThousands(common.keepNum(item['withholding_tax'],2))}
                          </div>
                          <div
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{width: "8%"}}
                          >
                            {chgCur}
                            {common.transThousands(common.keepNum(item['issuer_bank_fee'],2))}
                          </div>
                          <div
                            className={Number(item['net_dividend'])>0?APPstyles.color_red:Number(item['net_dividend'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{width: bodyW<=minW1450?"10%":"8%"}}
                          >
                            {common.dealAddCurData(item['currency'],item['country'],common.transThousands(common.keepNum(item['net_dividend'],2)))}
                          </div>
                          <div className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
                            {common.transThousands(common.keepNum(item['paid_allotment'],0))}
                          </div>
                          <div
                            className={Number(item['paid_allotment_fee'])>0?APPstyles.color_red:Number(item['paid_allotment_fee'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}
                            style={{width: "7%"}}
                          >
                            {common.dealAddCurData(item['currency'],item['country'],common.transThousands(common.keepNum(item['paid_allotment_fee'],2)))}
                          </div>
                          <div 
                            className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white} 
                            style={{ustifyContent: "flex-end" }}
                          >
                            {common.transThousands(common.keepNum(item['dividend_in_shares'],0))}
                          </div>
                        </div>
                      );
                    })
                  :
                    <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div> 
                }
              </div>
              {/* 分页 */}
              <div style={{display:isPC?isSearchPageShow?'':'none':'none'}}>
              <PaginationCon
                {...this.props}
                sizeTotal={sizeTotal}
                recperpage={recperpage}
                page={page}
                isShow={true}
                history={history}
                callbackPagekData={this.callbackPagekData.bind(this)}
              />
              </div>
            </div>
          :
          // 移动端
          <Mobile 
            {...this.props}
            history={history}
            changeName={ChgLangData == "zh-CN" ? "name" : "Name"}
            isLoading={isLoading}
            loadMore={loadMore}
            noMore={noMore}
            sizeTotal={sizeTotal}
            recperpage={recperpage}
            page={page}
            isShow={true}
            isSortShow={isSortShow}
            toolTipText={toolTipText}
            sortClick={this.sortClick.bind(this)}
            cbChgDataFun={this.cbChgDataFun.bind(this)}
          />
        }
        {/* 分页 */}
        <div style={{display:isPC&&!isSearchPageShow?'':'none'}}>
        <PaginationCon
          {...this.props}
          sizeTotal={sizeTotal}
          recperpage={recperpage}
          page={page}
          isShow={true}
          history={history}
          callbackPagekData={this.callbackPagekData.bind(this)}
        />
        </div>
      </div>
    );
  }
}
export default withTranslation()(DividendHistoryTable)
