import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import common from "../../asserts/js/common";
import Tabs from "../TabAlerts";
import ActiveAlerts from "./components/ActiveAlerts";
import CreateAlert from "./components/CreateAlert";
import AlertsHistory from "./components/AlertsHistory";
import Notifications from "./components/Notifications";
import AlertsModal from "./components/AlertsModal";
import { ReactComponent as IconArrowLeft } from "../../asserts/imgs/svg/arrow_left.svg";
import { ReactComponent as IconArrowLeftLight } from "../../asserts/imgs/svg/arrow_left_light.svg";
import { ReactComponent as IconClose } from "../../asserts/imgs/svg/close_24px.svg";

import styles from "./index.less";
import APPstyles from "../../App.less";
import { 
  closeImg,
  leftImg,
  leftLightImg,
} from '../../asserts/imgs/common/index'

const AlertsComponents = (props) => {
  const { t } = useTranslation();
  const {
    store,
    bodyW,
    minbodyW,
    mobilebodyW,
    ChgThemeData,
    ChgLangData,
    tradeData,
    ricData,
    sliderIndex,
    currentIndex,
    isTablet,
    isMobile,
    alertsPage,
  } = props;

  const [page, setPage] = useState(alertsPage);
  const [alertDetail, setAlertDetail] = useState();
  const [alertsHistoryCount, setAlertHistoryCount] = useState("-");

  const arrTab = useMemo(
    () => [
      {
        name: alertDetail ? t("alerts.editAlert") : t("alerts.createAlert"),
        isShow: false,
      },
      {
        name: t("alerts.activeAlerts"),
        isShow: false,
      },
      {
        name: t("alerts.alertsHistory") + `(${alertsHistoryCount})`,
        isShow: false,
      },
      {
        name: t("alerts.notifications"),
        isShow: false,
      },
    ],
    [ChgLangData, alertsHistoryCount, page, alertDetail]
  );
  const DEFAULT_PAGE_SIZE = 10;

  useEffect(() => {
    let token = window.sessionStorage.getItem("token");

    fetch(common.getHttp + "alert/history-alert-count", {
      method: "POST",
      headers: {
        Accept: "application/json,text/plain,*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          setAlertHistoryCount(res.total);
        } else {
          setAlertHistoryCount(0);
        }
      })
      .catch((err) => {
        console.error(err);
        setAlertHistoryCount(0);
      });
  }, []);

  useEffect(() => {
    if (page !== 0) {
      setAlertDetail();
    }
  }, [page]);

  const onClickClose = () => {
    const { bodyW, mobilebodyW, sliderIndex, currentIndex } =
      props;
    let navIdAPP=sessionStorage.getItem('navIdAPP')
    if (bodyW <= mobilebodyW && (sliderIndex === 'quote' || sliderIndex === 'alerts')) {
      props.callbackLeftPageShow && props.callbackLeftPageShow("list");
      props.cbDetailPageShow && props.cbDetailPageShow(false);
    }

    //quote
    // if (sliderIndex == 0 || sliderIndex == 2) {
    if (sliderIndex === 'quote') {
      props.callbackAlertsShow("close", "");
      return;
    }
    // trade, alerts
    if (sliderIndex === 'trade' || sliderIndex === 'alerts') {
      props.callbackChgNavIndex(navIdAPP, true);
      if (currentIndex == 3) {
        props.callbackAlertsShow("close", "");
      }
    }
    // trade/account
    if (sliderIndex === 'trade' || sliderIndex === 'account') {
      let data = [false, false, false, false];
      props.calkbackBtnStatus(data);
      // props.callbackChgNavIndex && props.callbackChgNavIndex(navIdAPP, true);
    }
  };

  const renderPage = () => {
    switch (page) {
      case 0:
        return (
          <CreateAlert
            {...props}
            setPage={setPage}
            alertDetail={alertDetail}
            setAlertDetail={setAlertDetail}
          />
        );
      case 1:
        return (
          <ActiveAlerts
            {...props}
            setPage={setPage}
            setAlertDetail={setAlertDetail}
          />
        );
      case 2:
        return (
          <AlertsHistory
            {...props}
            alertsHistoryCount={alertsHistoryCount}
            setAlertHistoryCount={setAlertHistoryCount}
          />
        );
      case 3:
        return <Notifications {...props} />;
      default:
        return;
    }
  };

  return (
    <div
      // id="getTradeID"
      className={[
        styles.alerts_components,
        // tradeData[0] || bodyW <= mobilebodyW ? APPstyles.flex_column : "",
        // tradeData[0] || bodyW <= mobilebodyW
        //   ? APPstyles.flex1_hidden
        //   : APPstyles.flex1_auto,
        APPstyles.flex_column,
        APPstyles.flex1_hidden,
        ChgThemeData == "Light"
          ? APPstyles.borderLeft_light
          : APPstyles.borderLeft_dark,
        ChgThemeData == "Light"
          ? APPstyles.bg_light
          : APPstyles.bg_content_dark,
        isTablet ? styles.tablet : "",
        isMobile ? styles.mobile : "",
      ].join(" ")}
    >
      <div className={APPstyles.isLoading}>
        {/* <Spin spinning={isLoading} /> */}
      </div>
      <div
        className={[
          APPstyles.flex_between,
          styles.name,
          styles.tradeflow_con,
          bodyW <= mobilebodyW ? styles.name_mobile : "",
          ChgThemeData == "Light"
            ? APPstyles.color_theme_black
            : APPstyles.color_white,
          ChgThemeData == "Light"
            ? APPstyles.borderBottom_light
            : APPstyles.borderBottom_dark,
        ].join(" ")}
      >
        {/* <img
          src={ChgThemeData == "Light" ? leftLightImg : leftImg}
          onClick={onClickClose}
          alt="back"
          className={[
            APPstyles.img24,
            bodyW <= mobilebodyW ? "" : APPstyles.display_none,
          ].join(" ")}
        /> */}
        {ChgThemeData == "Light" ? (
          <IconArrowLeftLight
            className={bodyW <= mobilebodyW ? "" : APPstyles.display_none}
            onClick={onClickClose}
          />
        ) : (
          <IconArrowLeft
            className={bodyW <= mobilebodyW ? "" : APPstyles.display_none}
            onClick={onClickClose}
          />
        )}
        <span>{t("alerts.alerts")}</span>
        <IconClose
          className={[
            ChgThemeData === "Light"
              ? APPstyles.hover_icon_light
              : APPstyles.hover_icon,
            bodyW > mobilebodyW ? "" : APPstyles.display_none,
          ].join(" ")}
          onClick={onClickClose}
        />
        {/* <img
          src={closeImg}
          onClick={onClickClose}
          alt="close"
          className={[
            APPstyles.img24,
            bodyW > mobilebodyW ? "" : APPstyles.display_none,
          ].join(" ")}
        /> */}
      </div>
      <div
        className={
          ChgThemeData === "Light"
            ? APPstyles.borderBottom_light
            : APPstyles.borderBottom_dark
        }
      >
        <Tabs
          {...props}
          tabData={arrTab}
          currentIndex={page}
          disableIndex2={false}
          tag="quote"
          callbackTabIndex={(index) => {
            setPage(index);
          }}
        />
      </div>

      {renderPage()}

      {/* modal develop in here first */}
      {false && <AlertsModal {...props} />}
    </div>
  );
};

export default AlertsComponents;
