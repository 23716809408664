import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  closeImg,
  closeOnImg,
  closeOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Reminder extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isInitialTradeShow:false,
      minW600:600,
      newUrl:'https://www.fbs.com.tw/WorldWideStocks/Charge',
      chgCloseImg:closeImg
    }
  }
  //打开链接
  openClick(){
    const {newUrl}=this.state
    //ios终端
    if(common.isiOS){
      window.location.href=newUrl
      return
    }
    //android终端/pc端
    window.open(newUrl,'_blank')
  }
  //close/confirm
  tipsClick(msg){
    const {sliderIndex,tradeOrderData,isTablet,isMobile} = this.props
    let getData=tradeOrderData[0]
    if(msg=='confirm'){
      let data=getData['isADR']==1&&getData['isShowGTC']?[true,false,true,false]:[true,false,false,true]
      getData['isClickADR']=true
      this.props.calkbackBtnStatus(data)
      this.props.calkbackOrderData([getData])
      return
    }
    // close
    if(sliderIndex=='quote'){
      let data=isTablet&&!isMobile?[true,true,false,false]:[false,false,false,false]
      getData['isClickADR']=false
      this.props.calkbackBtnStatus(data)
      this.props.calkbackOrderData([getData])
      return
    }
    getData['isClickADR']=false
    let data=[true,true,false,false]
    this.props.calkbackBtnStatus(data)
    this.props.calkbackOrderData([getData])
  }
  render(){
    const {t,buySellIndex,bodyW,ChgThemeData,isMobile} = this.props
    const {minW600,chgCloseImg}=this.state
    return (
      <div 
        className={[
          styles.reminder,
          APPstyles.flex_column,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
        ].join(' ')}
        style={{
          width:bodyW<=minW600?'80%':522
        }}
      >
        <div
          className={[
            APPstyles.flex_between,
            styles.reminder_title,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          {t('ADR Trading Reminder')}
          <img 
              src={chgCloseImg}
              alt='close'
              onClick={this.tipsClick.bind(this,'close')}
              className={APPstyles.img24} 
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgCloseImg:closeImg
                })
              }}   
            />
        </div>
        <div 
          className={[
            styles.reminder_content,
            APPstyles.flex1_auto,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
            isMobile?APPstyles.lineHeight20:'',
            isMobile?ChgThemeData=='Dark'?APPstyles.color_white:APPstyles.color_theme_black:''
          ].join(' ')}
        >
          {t('Trading U.S. ADR (Depository Receipt) stocks may be subject to custodial fees charged by the ADR custodian bank as required. For details, please refer to')}:&nbsp;&nbsp;
          <span 
            onClick={this.openClick.bind(this)}
            className={[
              ChgThemeData=='Light'?APPstyles.color_blue:APPstyles.color_56CCF2,
              ChgThemeData=='Light'?APPstyles.borderBottom_008FC7:APPstyles.borderBottom_56CCF2_hover
            ].join(' ')} 
            style={{cursor:'pointer'}}
          >
            https://www.fbs.com.tw/WorldWideStocks/Charge
          </span>
        </div>
        <div 
          className={[APPstyles.flex_row,APPstyles.place_button,styles.place_button_reminder].join(' ')}
          style={{paddingBottom:isMobile?28:24}}
        >
          <Button type='default' className={APPstyles.btn_gray} onClick={this.tipsClick.bind(this,'close')}>{t('Cancel')}</Button>
          <Button 
            type='default' 
            className={[
              buySellIndex&&buySellIndex['key']=='B'?APPstyles.btn_red:APPstyles.btn_green,
              buySellIndex&&buySellIndex['key']=='B'?APPstyles.bg_red:APPstyles.bg_green
            ].join(' ')}
            onClick={this.tipsClick.bind(this,'confirm')} 
          >
            {t('Confirm Order')}
          </Button>
        </div>
      </div>
  )}
}
export default withTranslation()(Reminder)