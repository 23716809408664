import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const ENV = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(20);
  const [color, setColor] = useState("666666");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enableP1, setEnableP1] = useState(false);
  const [p1, setP1] = useState(2.5);
  const [colorP1, setColorP1] = useState("FFB0B0");
  const [color2P1, setColor2P1] = useState("449999");
  const [thicknessP1, setThicknessP1] = useState(2);
  const [styleP1, setStyleP1] = useState("s");
  const [showTrackerP1, setShowTrackerP1] = useState(true);

  const [enableP2, setEnableP2] = useState(false);
  const [p2, setP2] = useState(5);
  const [colorP2, setColorP2] = useState("AA2211");
  const [color2P2, setColor2P2] = useState("66CCCC");
  const [thicknessP2, setThicknessP2] = useState(2);
  const [styleP2, setStyleP2] = useState("s");
  const [showTrackerP2, setShowTrackerP2] = useState(true);

  const [enableP3, setEnableP3] = useState(true);
  const [p3, setP3] = useState(10);
  const [colorP3, setColorP3] = useState("FFB0B0");
  const [color2P3, setColor2P3] = useState("449999");
  const [thicknessP3, setThicknessP3] = useState(2);
  const [styleP3, setStyleP3] = useState("s");
  const [showTrackerP3, setShowTrackerP3] = useState(true);

  const [enableP4, setEnableP4] = useState(false);
  const [p4, setP4] = useState(25);
  const [colorP4, setColorP4] = useState("AA2211");
  const [color2P4, setColor2P4] = useState("66CCCC");
  const [thicknessP4, setThicknessP4] = useState(2);
  const [styleP4, setStyleP4] = useState("s");
  const [showTrackerP4, setShowTrackerP4] = useState(true);

  const [enableP5, setEnableP5] = useState(false);
  const [p5, setP5] = useState(50);
  const [colorP5, setColorP5] = useState("FFB0B0");
  const [color2P5, setColor2P5] = useState("449999");
  const [thicknessP5, setThicknessP5] = useState(2);
  const [styleP5, setStyleP5] = useState("s");
  const [showTrackerP5, setShowTrackerP5] = useState(true);
  const [value, setValue] = useState(period + "," + p3 + "%");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.ENV_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      const midOption = localChartSetting.option.mid;
      const f1 = localChartSetting.option.factor[0];
      const f2 = localChartSetting.option.factor[1];
      const f3 = localChartSetting.option.factor[2];
      const f4 = localChartSetting.option.factor[3];
      const f5 = localChartSetting.option.factor[4];

      let mid = {
        period: midOption.period,
        color: midOption.color,
        thickness: midOption.thickness,
        style: midOption.style,
        showTracker: midOption.showTracker,
        enabled: midOption.enabled,
      };

      let factor1 = {
        p: f1.p,
        color: f1.color,
        color2: f1.color2,
        thickness: f1.thickness,
        style: f1.style,
        showTracker: f1.showTracker,
        enabled: f1.enabled,
      };

      let factor2 = {
        p: f2.p,
        color: f2.color,
        color2: f2.color2,
        thickness: f2.thickness,
        style: f2.style,
        showTracker: f2.showTracker,
        enabled: f2.enabled,
      };

      let factor3 = {
        p: f3.p,
        color: f3.color,
        color2: f3.color2,
        thickness: f3.thickness,
        style: f3.style,
        showTracker: f3.showTracker,
        enabled: f3.enabled,
      };

      let factor4 = {
        p: f4.p,
        color: f4.color,
        color2: f4.color2,
        thickness: f4.thickness,
        style: f4.style,
        showTracker: f4.showTracker,
        enabled: f4.enabled,
      };

      let factor5 = {
        p: f5.p,
        color: f5.color,
        color2: f5.color2,
        thickness: f5.thickness,
        style: f5.style,
        showTracker: f5.showTracker,
        enabled: f5.enabled,
      };

      let factor = [factor1, factor2, factor3, factor4, factor5];

      let v = "";
      if (midOption.enabled) {
        v = v + midOption.period + ",";
      }
      if (f1.enabled) {
        v = v + f1.p + "%,";
      }
      if (f2.enabled) {
        v = v + f2.p + "%,";
      }
      if (f3.enabled) {
        v = v + f3.p + "%,";
      }
      if (f4.enabled) {
        v = v + f4.p + "%,";
      }
      if (f5.enabled) {
        v = v + f5.p + "%";
      }

      setValue(v);

      props.onOverlayValueChange({
        ta: "ENV",
        name: `${t("chart5.TA.ENV")} (ENV)`,
        option: {
          mid: mid,
          factor: factor,
        },
        value: localChartSetting.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const ENV = props.localChartSetting.ENV_Option.option;
    const midOption = ENV.mid;
    const f1 = ENV.factor[0];
    const f2 = ENV.factor[1];
    const f3 = ENV.factor[2];
    const f4 = ENV.factor[3];
    const f5 = ENV.factor[4];

    setEnable(midOption.enabled);
    setPeriod(parseInt(midOption.period) || period);
    setColor(midOption.color);
    setThickness(parseInt(midOption.thickness) || thickness);
    setStyle(midOption.style);
    setShowTracker(midOption.showTracker);

    setEnableP1(f1.enabled);
    setP1(parseFloat(f1.p) || p1);
    setColorP1(f1.color);
    setColor2P1(f1.color2);
    setThicknessP1(parseInt(f1.thickness) || thicknessP1);
    setStyleP1(f1.style);
    setShowTrackerP1(f1.showTracker);

    setEnableP2(f2.enabled);
    setP2(parseFloat(f2.p) || p2);
    setColorP2(f2.color);
    setColor2P2(f2.color2);
    setThicknessP2(parseInt(f2.thickness) || thicknessP2);
    setStyleP2(f2.style);
    setShowTrackerP2(f2.showTracker);

    setEnableP3(f3.enabled);
    setP3(parseFloat(f3.p) || p3);
    setColorP3(f3.color);
    setColor2P3(f3.color2);
    setThicknessP3(parseInt(f3.thickness) || thicknessP3);
    setStyleP3(f3.style);
    setShowTrackerP3(f3.showTracker);

    setEnableP4(f4.enabled);
    setP4(parseFloat(f4.p) || p4);
    setColorP4(f4.color);
    setColor2P4(f4.color2);
    setThicknessP4(parseInt(f4.thickness) || thicknessP4);
    setStyleP4(f4.style);
    setShowTrackerP4(f4.showTracker);

    setEnableP5(f5.enabled);
    setP5(parseFloat(f5.p) || p5);
    setColorP5(f5.color);
    setColor2P5(f5.color2);
    setThicknessP5(parseInt(f5.thickness) || thicknessP5);
    setStyleP5(f5.style);
    setShowTrackerP5(f5.showTracker);
  }, [props.localChartSetting]);

  useEffect(() => {
    let mid = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    let factor1 = {
      p: p1,
      color: colorP1,
      color2: color2P1,
      thickness: thicknessP1,
      style: styleP1,
      showTracker: showTrackerP1,
      enabled: enableP1,
    };

    let factor2 = {
      p: p2,
      color: colorP2,
      color2: color2P2,
      thickness: thicknessP2,
      style: styleP2,
      showTracker: showTrackerP2,
      enabled: enableP2,
    };

    let factor3 = {
      p: p3,
      color: colorP3,
      color2: color2P3,
      thickness: thicknessP3,
      style: styleP3,
      showTracker: showTrackerP3,
      enabled: enableP3,
    };

    let factor4 = {
      p: p4,
      color: colorP4,
      color2: color2P4,
      thickness: thicknessP4,
      style: styleP4,
      showTracker: showTrackerP4,
      enabled: enableP4,
    };

    let factor5 = {
      p: p5,
      color: colorP5,
      color2: color2P5,
      thickness: thicknessP5,
      style: styleP5,
      showTracker: showTrackerP5,
      enabled: enableP5,
    };

    let factor = [factor1, factor2, factor3, factor4, factor5];

    let v = "";
    if (enable) {
      v = v + period + ",";
    }
    if (enableP1) {
      v = v + p1 + "%,";
    }
    if (enableP2) {
      v = v + p2 + "%,";
    }
    if (enableP3) {
      v = v + p3 + "%,";
    }
    if (enableP4) {
      v = v + p4 + "%,";
    }
    if (enableP5) {
      v = v + p5 + "%";
    }

    setValue(v);

    props.onOverlayValueChange({
      ta: "ENV",
      name: `${t("chart5.TA.ENV")} (ENV)`,
      option: {
        mid: mid,
        factor: factor,
      },
      value: value,
    });
  }, [
    enable,
    period,
    color,
    thickness,
    style,
    showTracker,
    enableP1,
    p1,
    colorP1,
    color2P1,
    thicknessP1,
    styleP1,
    showTrackerP1,
    enableP2,
    p2,
    colorP2,
    color2P2,
    thicknessP2,
    styleP2,
    showTrackerP2,
    enableP3,
    p3,
    colorP3,
    color2P3,
    thicknessP3,
    styleP3,
    showTrackerP3,
    enableP4,
    p4,
    colorP4,
    color2P4,
    thicknessP4,
    styleP4,
    showTrackerP4,
    enableP5,
    p5,
    colorP5,
    color2P5,
    thicknessP5,
    styleP5,
    showTrackerP5,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const ENV = props.localChartSetting.ENV_Option.option;
    const midOption = ENV.mid;
    const f1 = ENV.factor[0];
    const f2 = ENV.factor[1];
    const f3 = ENV.factor[2];
    const f4 = ENV.factor[3];
    const f5 = ENV.factor[4];

    setEnable(midOption.enabled);
    setPeriod(parseInt(midOption.period) || period);
    setColor(midOption.color);
    setThickness(parseInt(midOption.thickness) || thickness);
    setStyle(midOption.style);
    setShowTracker(midOption.showTracker);

    setEnableP1(f1.enabled);
    setP1(parseFloat(f1.p) || p1);
    setColorP1(f1.color);
    setColor2P1(f1.color2);
    setThicknessP1(parseInt(f1.thickness) || thicknessP1);
    setStyleP1(f1.style);
    setShowTrackerP1(f1.showTracker);

    setEnableP2(f2.enabled);
    setP2(parseFloat(f2.p) || p2);
    setColorP2(f2.color);
    setColor2P2(f2.color2);
    setThicknessP2(parseInt(f2.thickness) || thicknessP2);
    setStyleP2(f2.style);
    setShowTrackerP2(f2.showTracker);

    setEnableP3(f3.enabled);
    setP3(parseFloat(f3.p) || p3);
    setColorP3(f3.color);
    setColor2P3(f3.color2);
    setThicknessP3(parseInt(f3.thickness) || thicknessP3);
    setStyleP3(f3.style);
    setShowTrackerP3(f3.showTracker);

    setEnableP4(f4.enabled);
    setP4(parseFloat(f4.p) || p4);
    setColorP4(f4.color);
    setColor2P4(f4.color2);
    setThicknessP4(parseInt(f4.thickness) || thicknessP4);
    setStyleP4(f4.style);
    setShowTrackerP4(f4.showTracker);

    setEnableP5(f5.enabled);
    setP5(parseFloat(f5.p) || p5);
    setColorP5(f5.color);
    setColor2P5(f5.color2);
    setThicknessP5(parseInt(f5.thickness) || thicknessP5);
    setStyleP5(f5.style);
    setShowTrackerP5(f5.showTracker);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(20);
    setColor("666666");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnableP1(false);
    setP1(2.5);
    setColorP1("FFB0B0");
    setColor2P1("449999");
    setThicknessP1(2);
    setStyleP1("s");
    setShowTrackerP1(true);

    setEnableP2(false);
    setP2(5);
    setColorP2("AA2211");
    setColor2P2("66CCCC");
    setThicknessP2(2);
    setStyleP2("s");
    setShowTrackerP2(true);

    setEnableP3(true);
    setP3(10);
    setColorP3("FFB0B0");
    setColor2P3("449999");
    setThicknessP3(2);
    setStyleP3("s");
    setShowTrackerP3(true);

    setEnableP4(false);
    setP4(25);
    setColorP4("AA2211");
    setColor2P4("66CCCC");
    setThicknessP4(2);
    setStyleP4("s");
    setShowTrackerP4(true);

    setEnableP5(false);
    setP5(50);
    setColorP5("FFB0B0");
    setColor2P5("449999");
    setThicknessP5(2);
    setStyleP5("s");
    setShowTrackerP5(true);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  // e: any, type: string
  const onEnableChange = (e, type) => {
    switch (type) {
      case "P":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "P1":
        if (enableP1) setEnableP1(false);
        else setEnableP1(true);
        break;
      case "P2":
        if (enableP2) setEnableP2(false);
        else setEnableP2(true);
        break;
      case "P3":
        if (enableP3) setEnableP3(false);
        else setEnableP3(true);
        break;
      case "P4":
        if (enableP4) setEnableP4(false);
        else setEnableP4(true);
        break;
      case "P5":
        if (enableP5) setEnableP5(false);
        else setEnableP5(true);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "P":
        setPeriod(e.target.value);
        break;
      case "P1":
        setP1(e.target.value);
        break;
      case "P2":
        setP2(e.target.value);
        break;
      case "P3":
        setP3(e.target.value);
        break;
      case "P4":
        setP4(e.target.value);
        break;
      case "P5":
        setP5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "P":
        setThickness(e.target.value);
        break;
      case "P1":
        setThicknessP1(e.target.value);
        break;
      case "P2":
        setThicknessP2(e.target.value);
        break;
      case "P3":
        setThicknessP3(e.target.value);
        break;
      case "P4":
        setThicknessP4(e.target.value);
        break;
      case "P5":
        setThicknessP5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "P":
        setStyle(e.target.value);
        break;
      case "P1":
        setStyleP1(e.target.value);
        break;
      case "P2":
        setStyleP2(e.target.value);
        break;
      case "P3":
        setStyleP3(e.target.value);
        break;
      case "P4":
        setStyleP4(e.target.value);
        break;
      case "P5":
        setStyleP5(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    //if (showTracker) setShowTracker(false); else setShowTracker(true)

    switch (type) {
      case "P":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "P1":
        if (showTrackerP1) setShowTrackerP1(false);
        else setShowTrackerP1(true);
        break;
      case "P2":
        if (showTrackerP2) setShowTrackerP2(false);
        else setShowTrackerP2(true);
        break;
      case "P3":
        if (showTrackerP3) setShowTrackerP3(false);
        else setShowTrackerP3(true);
        break;
      case "P4":
        if (showTrackerP4) setShowTrackerP4(false);
        else setShowTrackerP4(true);
        break;
      case "P5":
        if (showTrackerP5) setShowTrackerP5(false);
        else setShowTrackerP5(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "ENV-C1":
        setColor(props.selectedColor);
        break;
      case "ENV-C2":
        setColorP1(props.selectedColor);
        break;
      case "ENV-C3":
        setColor2P1(props.selectedColor);
        break;
      case "ENV-C4":
        setColorP2(props.selectedColor);
        break;
      case "ENV-C5":
        setColor2P2(props.selectedColor);
        break;
      case "ENV-C6":
        setColorP3(props.selectedColor);
        break;
      case "ENV-C7":
        setColor2P3(props.selectedColor);
        break;
      case "ENV-C8":
        setColorP4(props.selectedColor);
        break;
      case "ENV-C9":
        setColor2P4(props.selectedColor);
        break;
      case "ENV-C10":
        setColorP5(props.selectedColor);
        break;
      case "ENV-C11":
        setColor2P5(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("ENV")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.ENV")} (ENV) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("ENV")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange(e, "P")}
            />
            <div className="taparamlbl">{t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-WC-p0-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "ENV-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P")}>
              <select value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enableP1 ? {} : { opacity: "0.5" }}
        >
          <div className="flexbox">
            <div
              className={
                enableP1 ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange(e, "P1")}
            />
            <div className="taparamlbl">%</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ENV-p1-period0"
                type="number"
                step="1"
                min="1"
                value={p1}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-ENV-p1-color0"
              className="colorpicker"
              value={colorP1}
              style={{ backgroundColor: "#" + colorP1 }}
              onClick={() => onColorClick(colorP1, "ENV-C2", "+P")}
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-ENV-p1-color1"
              className="colorpicker"
              value={color2P1}
              style={{ backgroundColor: "#" + color2P1 }}
              onClick={() => onColorClick(color2P1, "ENV-C3", "-P")}
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thicknessP1}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P1")}>
              <select value={styleP1}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTrackerP1 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enableP2 ? {} : { opacity: "0.5" }}
          id="msi-ta-ENV"
        >
          <div className="flexbox">
            <div
              className={
                enableP2 ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange(e, "P2")}
            />
            <div className="taparamlbl">%</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ENV-p2-period0"
                type="number"
                step="1"
                min="1"
                value={p2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-ENV-p2-color0"
              className="colorpicker"
              value={colorP2}
              style={{ backgroundColor: "#" + colorP2 }}
              onClick={() => onColorClick(colorP2, "ENV-C4", "+P")}
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-ENV-p2-color1"
              className="colorpicker"
              value={color2P2}
              style={{ backgroundColor: "#" + color2P2 }}
              onClick={() => onColorClick(color2P2, "ENV-C5", "-P")}
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thicknessP2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P2")}>
              <select value={styleP2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTrackerP2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
        <div
          className="menusubitem checkbox selected"
          style={enableP3 ? {} : { opacity: "0.5" }}
          id="msi-ta-ENV"
        >
          <div className="flexbox">
            <div
              className={
                enableP3 ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange(e, "P3")}
            />
            <div className="taparamlbl">%</div>
            <div className="inputbox taparam">
              <input
                type="number"
                step="1"
                min="1"
                value={p3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-ENV-p3-color0"
              className="colorpicker"
              value={colorP3}
              style={{ backgroundColor: "#" + colorP3 }}
              onClick={() => onColorClick(colorP3, "ENV-C6", "+P")}
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-ENV-p3-color1"
              className="colorpicker"
              value={color2P3}
              style={{ backgroundColor: "#" + color2P3 }}
              onClick={() => onColorClick(color2P3, "ENV-C7", "-P")}
            ></button>
            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thicknessP3}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P3")}>
              <select value={styleP3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTrackerP3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enableP4 ? {} : { opacity: "0.5" }}
          id="msi-ta-ENV"
        >
          <div className="flexbox">
            <div
              className={
                enableP4 ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange(e, "P4")}
            />
            <div className="taparamlbl">%</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-ENV-p4-period0"
                type="number"
                step="1"
                min="1"
                value={p4}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P4")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-ENV-p4-color0"
              className="colorpicker"
              value={colorP4}
              style={{ backgroundColor: "#" + colorP4 }}
              onClick={() => onColorClick(colorP4, "ENV-C8", "+P")}
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-ENV-p4-color1"
              className="colorpicker"
              value={color2P4}
              style={{ backgroundColor: "#" + color2P4 }}
              onClick={() => onColorClick(color2P4, "ENV-C9", "-P")}
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thicknessP4}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P4")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P4")}>
              <select value={styleP4}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTrackerP4 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P4")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enableP5 ? {} : { opacity: "0.5" }}
          id="msi-ta-ENV"
        >
          <div className="flexbox">
            <div
              className={
                enableP5 ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange(e, "P5")}
            />
            <div className="taparamlbl">%</div>

            <div className="inputbox taparam">
              <input
                id="msi-ta-ENV-p5-period0"
                type="number"
                step="1"
                min="1"
                value={p5}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "P5")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+P</div>
            <button
              id="msi-ta-ENV-p5-color0"
              className="colorpicker"
              value={colorP5}
              style={{ backgroundColor: "#" + colorP5 }}
              onClick={() => onColorClick(colorP5, "ENV-C10", "+P")}
            ></button>

            <div className="styleboxlbl">-P</div>
            <button
              id="msi-ta-ENV-p5-color1"
              className="colorpicker"
              value={color2P5}
              style={{ backgroundColor: "#" + color2P5 }}
              onClick={() => onColorClick(color2P5, "ENV-C11", "-P")}
            ></button>

            <div className="inputbox">
              <input
                type="number"
                step="1"
                min="1"
                max="9"
                value={thicknessP5}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "P5")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "P5")}>
              <select value={styleP5}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                className={
                  showTrackerP5 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("P5")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
