import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
    leftImg,
    leftLightImg
} from '../../../../asserts/imgs/common/index'
import CheckboxCon from '../checkboxs/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class CriteriaCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            getCheckboxData:[],
            getCriteriaNum:0
        }
    }
    //回到顶部
    backTopFun(){
        setTimeout(() => document.getElementById('criteriaId').scrollTop=0, 10)
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {isCriteriaShow,isResetAll,isChgCheckboxShow,listData,CriteriaTag}=this.props
        if(isCriteriaShow!==newProps['isCriteriaShow']){
           this.backTopFun()
        } 
        if(isResetAll!==newProps['isResetAll']){ 
            this.setState({
                getCheckboxData:CriteriaTag=='news'?listData:[]
            })
        }
        if(isChgCheckboxShow!==newProps['isChgCheckboxShow']){
            this.setState({
                getCheckboxData:listData
            })
        }
    }
    //初始化数据
    componentDidMount() {
        const {CriteriaTag,listData}=this.props
        // news页面
        this.setState({
            getCheckboxData:CriteriaTag=='news'?listData:[]
        })
    }
    render(){
        const {t,ChgThemeData,getLeftW,getCriteriaH,isCriteriaShow,isMobile,CriteriaTag} = this.props
        const {getCheckboxData,getCriteriaNum} = this.state 
        return (
            <div
                className={[
                    styles.criteria_con,
                    APPstyles.flex_column,
                    ChgThemeData=='Light'?APPstyles.dropDown_con_light:'',
                    ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                    isMobile&&isCriteriaShow?APPstyles.position_fixed:'',
                    isMobile&&isCriteriaShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
                ].join(' ')}
                style={{
                    top: 1,
                    left:isMobile?0:getLeftW,
                    width: isMobile?'100%':416,
                    height:isMobile?'100%':getCriteriaH
                }}
            >
                <div 
                    className={[
                        APPstyles.flex_row,
                        styles.criteria_title_con,
                        ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
                    ].join(' ')}
                    style={{
                        display:isMobile?'':'none'
                    }}
                > 
                    <img 
                        src={ChgThemeData=='Light'?leftLightImg:leftImg}
                        alt='back'  
                        className={APPstyles.img24} 
                        onClick={()=>{
                            this.props.cbCriteriaHide('N') 
                            //ios终端
                            isMobile&&this.props.cbIsShowHeaderPage(true) 
                        }} 
                    />
                    {t('Screener Settings')}
                </div>
                <div 
                    id='criteriaId'
                    className={[
                        styles.criteria_body,
                        APPstyles.flex1_auto,
                        ChgThemeData=='Light'?styles.criteria_body_light:''
                    ].join(' ')}
                >
                    <CheckboxCon
                        {...this.props}
                        cbCheckboxData={(data)=>{
                            this.setState({
                                getCheckboxData: data
                            }) 
                        }}
                        cbCriteriaNum={(data)=>{
                            this.setState({
                                getCriteriaNum: data
                            }) 
                        }}
                        cbCriteriaNum2={(data)=>{
                            this.setState({
                                getCriteriaNum: data
                            }) 
                            this.props.cbCriteriaNum2(data) 
                        }}
                    />
                </div>
                <div 
                    className={[
                        styles.criteria_btn,
                        ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_828282
                    ].join(' ')}
                >
                    <Button 
                        type='default' 
                        className={APPstyles.btn_gray} 
                        onClick={()=>{
                            this.props.cbCriteriaHide('N')
                            //ios终端
                            isMobile&&this.props.cbIsShowHeaderPage(true) 
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button 
                        type='default' 
                        className={[APPstyles.btn_blue,APPstyles.bg_blue].join(' ')}
                        onClick={()=>{
                            this.props.cbCriteriaHide('Y')
                            this.props.cbCriteriaData(getCheckboxData,'btn',getCriteriaNum,null)
                            CriteriaTag=='screener'&&this.props.cbChgCriteriaNum(getCriteriaNum,null)
                            //ios终端
                            isMobile&&this.props.cbIsShowHeaderPage(true) 
                        }}
                    >
                        {t('Save Settings')}
                    </Button>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(CriteriaCon))