import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import APPstyles from '@/App.less'
import styles from './index.less'
import NumberCon from './Number/index'

class CalculatorCon extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      cashAmount:'',
      getPrice:'',
      getQuantity:''
    }
  }
  changeNumClick(data){
    const {cashAmount,getPrice}=this.state
    let value=Number(data['value'])
    value=value?value:''
    if(data['name']=='cashAmount'){
      this.setState({
        cashAmount:value
      })
      if(value&&getPrice){
        this.setState({
          getQuantity:parseInt(value/getPrice)
        })
      }
    }else{
      this.setState({
        getPrice:value
      })
      if(value&&cashAmount){
        this.setState({
          getQuantity:parseInt(cashAmount/value)
        })
      }
    }
  }
  componentDidMount() {
    const {priceNums,quantityNums}=this.props
    this.setState({
      getPrice:priceNums,
      getQuantity:quantityNums
    })
  }
  render(){
    const {t,isMobile,ChgThemeData,ChgLangData,currency_1,currency_cn,curreny}=this.props
    const {cashAmount,getPrice,getQuantity}=this.state
    return (
      <div className={APPstyles.mask_con_fixed}>
        <div 
          className={[
            styles.calculator_con,
            APPstyles.flex_column,
            isMobile?styles.calculator_con_mobile:'',
            ChgThemeData=='Light'?styles.calculator_con_light:'',
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
          ].join(' ')} 
        >
          <div className={APPstyles.flex_1}>
            <span className={styles.calculator_title}>{t('Order Quantity Calculation')}</span>
            <div 
              className={[
                APPstyles.flex_row,
                styles.calculator_number_con,
              ].join(' ')}
            >
              <span>{t('Cash Amount')}</span>
              <NumberCon
                {...this.props}
                tag='cashAmount'
                placeholderName='Enter Cash Amount'
                value={cashAmount}
                cbInputValue={this.changeNumClick.bind(this)}
                cbNumberWarning={()=>{
                  this.props.cbNumberWarning();
                }}
              />
              <span>
                {
                  currency_1&&currency_cn?
                    ChgLangData=='zh-CN'?currency_cn:currency_1
                  :
                  t(curreny)  
                }
              </span>
            </div>
            <div 
              className={[
                APPstyles.flex_row,
                styles.calculator_number_con,
              ].join(' ')}
            >
              <span>{t('Price1')}</span>
              <NumberCon
                {...this.props}
                tag='price'
                placeholderName='Enter Price'
                value={getPrice}
                cbInputValue={this.changeNumClick.bind(this)}
                cbNumberWarning={()=>{
                  this.props.cbNumberWarning();
                }}
              />
              <span style={{opacity:0}}>
                {
                  currency_1&&currency_cn?
                    ChgLangData=='zh-CN'?currency_cn:currency_1
                  :
                  t(curreny)  
                }
              </span>
            </div>
            <div 
              className={[
                APPstyles.flex_row,
                styles.calculator_number_con,
              ].join(' ')}
            >
              <span>{t('Quantity')}</span>
              <span className={styles.calculator_number_quantity}>{getQuantity}</span>
            </div>
          </div>
          <div className={[APPstyles.flex_row,styles.calculator_btn].join(' ')}>
            <Button 
              type='default' 
              className={[APPstyles.bg_grey,APPstyles.btn_gray].join(' ')} 
              onClick={()=>{
                let obj={
                  tag:'N',
                  price:getPrice,
                  quatity:getQuantity,
                }
                this.props.cbCloseModal(obj)
              }}
            >
              {t('Cancel')}
            </Button>
            <Button 
              type='default' 
              className={[APPstyles.bg_blue,APPstyles.btn_blue].join(' ')} 
              onClick={()=>{
                let obj={
                  tag:'Y',
                  price:getPrice,
                  quatity:getQuantity,
                }
                this.props.cbCloseModal(obj)
              }}
            >
              {t('Confirm')}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(CalculatorCon)