import React from 'react'
import { withTranslation } from 'react-i18next'
import AlertsComponents from '../Alerts/index'
import TradeComponents from '../Trade/index'
import QuoteDetail from './QuoteDetail/index'
import WatchlistEdit from './WatchlistEdit/index'
import Add from './Add/index'
import List from './List/index'
import Edit from './Edit/index'
import APPstyles from '../../App.less'
import styles from './index.less'

class QuoteComponents extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isToggleOn: false,
      ricData:{},
      isBackTopShow:true,
      isDetailPageShow:false,
      isMobileShowAlert:false,
      appContentH:'auto',
      minW1280:1280,
      getListData:[]
    }
  }
  // 遮罩层--显示/隐藏
  calkbackBtnStatus(data){
    this.props.calkbackBtnStatus(data)
  }
  // 遮罩层--传递值
  calkbackOrderData(data){
    this.props.calkbackOrderData(data)
  }
  //选择某个ric
  callbackChgRic(data){
    this.setState({
      ricData:data
    })
  }
  //获取高度---ios
  getContentHFun(){
    const {bodyH,isTablet}=this.props
    let getH=isTablet?bodyH-5-66-49-56:bodyH-10-80-49
    this.setState({
      appContentH:getH
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isDetailPageShow,bodyW,bodyH,closeRightPageShow}=this.props
    if(isDetailPageShow!==newProps['isDetailPageShow']){
      this.setState({
        isDetailPageShow: isDetailPageShow
      })
    }
    if(bodyW!==newProps['bodyW']||bodyH!==newProps['bodyH']){
      this.getContentHFun()
    }
    // 切换账号成功则关闭恢复为最初的设置---navIdAPP=='quote'
    if(closeRightPageShow!==newProps['closeRightPageShow']){
      this.setState({
        isToggleOn: false
      })
      // 根据是否有数据来决定是否清空数据
      let dataLen=sessionStorage.getItem('hasQuoteDataLen')
      if(dataLen>0) return
      this.setState({
        ricData:{}
      })
    }
  }
  //初始化
  componentDidMount(){
    const {ricData,isDetailPageShow}=this.props
    this.getContentHFun()

    if(ricData||JSON.stringify(ricData) !== '{}'){
      this.setState({
        ricData:ricData
      })
    }
    let accountAddData=window.sessionStorage.getItem('accountAddData')
    if(accountAddData){
      this.setState({
        ricData: JSON.parse(accountAddData)
      })
      setTimeout(()=>{
        window.sessionStorage.setItem('ChgAddData',true)
        this.setState({
          isDetailPageShow: true
        })
        this.props.cbDetailPageShow(true)
      }, 10)
      window.sessionStorage.removeItem('accountAddData')
    }else{
      this.setState({
        isDetailPageShow: isDetailPageShow
      })
    }
  }
  render(){
    const {bodyW,ChgThemeData,isLeftPageShow,isRightPageShow,isTablet,isMobile,isPC,ChgLangData} = this.props
    const {isToggleOn,ricData,isBackTopShow,isDetailPageShow,appContentH,minW1280,getListData}=this.state
    return (
      <div
        className={[
          styles.quote_layout,
          APPstyles.flex_row,
          APPstyles.flex1_hidden,
          isTablet?APPstyles.marginLeft0:'',
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
        ].join(' ')}
        style={{
          height:appContentH
        }}
      >
        {/* content--left */}
        <div 
          className={[
            styles.quote_left,
            !isMobile?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'' 
          ].join(' ')} 
          style={{
            display:isPC?
                      !isToggleOn?'':'none'
                    :isTablet&&!isMobile?
                      !isToggleOn?
                        isRightPageShow=='trade'?'none':''
                      :'none'
                    :'',
            width:!isMobile?isTablet&&!isMobile?'40%':(isRightPageShow=='trade'||isRightPageShow=='alerts')&&ChgLangData=='en-US'&&bodyW<=minW1280?'28%':'30%':'100%'     
          }}
        >
          {
            isLeftPageShow=='list'?
              <List
                {...this.props}
                callbackChgRic={this.callbackChgRic.bind(this)} 
                callbackLeftPageShow={(data)=>{
                  this.props.callbackLeftPageShow(data)
                }} 
                cbDetailPageShow={(data)=>{
                  this.props.cbDetailPageShow(data)
                }} 
                cbSetWatchListFun={(data,tag,id)=>{
                  this.props.cbSetWatchListFun(data,tag,id)
                }}
                cbGetListDataFun={(data)=>{
                  this.setState({
                    getListData:data
                  })
                }}
              />
            :isLeftPageShow=='edit'?
              <WatchlistEdit 
                {...this.props}
                callbackChgTabName={(data)=>{
                  this.props.callbackChgTabName(data)
                }} 
                callbackLeftPageShow={(data)=>{
                  this.props.callbackLeftPageShow(data)
                }}
                cbOnLoadGetWatchlistFun={()=>{
                  this.props.cbOnLoadGetWatchlistFun()
                }}
              />
            :isLeftPageShow=='add'? 
              <Add  
                {...this.props}
                callbackChgRic={this.callbackChgRic.bind(this)} 
                callbackLeftPageShow={(data)=>{
                  this.props.callbackLeftPageShow(data)
                }}
                cbDetailPageShow={(data)=>{
                  this.props.cbDetailPageShow(data)
                }}
              /> 
            :isLeftPageShow=='stocksEdit'?
              <Edit 
                {...this.props}
                getListData={getListData}
                callbackChgRic={this.callbackChgRic.bind(this)} 
                callbackLeftPageShow={(data)=>{
                  this.props.callbackLeftPageShow(data)
                }}
                cbOnLoadGetWatchlistFun={()=>{
                  this.props.cbOnLoadGetWatchlistFun()
                }}
              /> 
            :
              ''    
          }
        </div>
        {/* content--right */}
        <div 
          className={[
            APPstyles.flex_row,
            styles.quote_right,
            isMobile&&isDetailPageShow?APPstyles.position_fixed:'' ,
            isMobile&&isDetailPageShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
          ].join(' ')}
          style={{
            width:isPC?
                    !isToggleOn?(isRightPageShow=='trade'||isRightPageShow=='alerts')&&ChgLangData=='en-US'&&bodyW<=minW1280?'72%':'70%':'100%'
                  :isTablet&&!isMobile?
                    !isToggleOn?
                      isRightPageShow=='trade'?'100%':isTablet&&!isMobile?'60%':'70%'
                    :'100%'
                  :'100%',     
            display: isPC? 
                      ''
                    :isTablet&&!isMobile?
                      ''
                    :isDetailPageShow||isRightPageShow=='trade'||isRightPageShow=='alerts'?'':'none'   
          }}
        >
          <div
            className={APPstyles.flex_column}
            style={{
              width: isPC? 
                      isRightPageShow=='alerts' || isRightPageShow=='trade'?ChgLangData=='en-US'&&bodyW<=minW1280?'55%':'50%':'100%'
                    :isTablet&&!isMobile?
                      '100%'
                    :'100%',
              display:isPC?
                        ''
                      :isTablet&&!isMobile?
                        isRightPageShow=='trade'?'none':''
                      :isDetailPageShow?'':'none'    
            }}
          >
            <QuoteDetail 
              {...this.props}
              ricData={ricData}
              isToggleOn={isToggleOn}
              isBackTopShow={isBackTopShow}
              isRightPageShow={isRightPageShow}
              // triggerCloseChartZoom={this.state.triggerCloseChartZoom}
              // onTriggerCloseChartZoom={this.onTriggerCloseChartZoom.bind(this)}
              callbackFbstockid={(data)=>{
                // 判断fbstockid是否有值，没有则隐藏isTradeShow
                this.props.callbackFbstockid(data)
              }}
              callbackTab={(data)=>{
                //top--tab栏切换
                this.props.callbackTab(data)
              }}
              cbIsToggleOn={(data)=>{
                this.setState({
                  isToggleOn: data
                })
              }}
              callbackTradeShow={(tag,data)=>{
                this.props.callbackTradeShow(tag,data)
              }}
              callbackAlertsShow={(tag,data)=>{
                this.props.callbackAlertsShow(tag,data)
              }}
              callbackLeftPageShow={(data)=>{
                this.props.callbackLeftPageShow(data)
              }}
              cbDetailPageShow={(data)=>{
                this.props.cbDetailPageShow(data)
              }} 
              cbSwitchStar={(data)=>{
                this.props.cbSwitchStar(data)
              }}
              cbShowDetailData={(data)=>{
                this.props.cbShowDetailData(data)
              }}
              cbRightDetailShowFun={(data,loading)=>{
                this.props.cbRightDetailShowFun(data,loading)
              }} 
              cbSetWatchListFun={(data,tag,id)=>{
                this.props.cbSetWatchListFun(data,tag,id)
              }}
              cbChgWatchlistIdFun={(id)=>{
                  this.props.cbChgWatchlistIdFun(id)
              }}
            />
          </div>
          {
            isRightPageShow=='alerts'?
              <div 
                className={isMobile&&isRightPageShow=='alerts'?APPstyles.position_fixed:''}
                style={{
                  width:isPC?ChgLangData=='en-US'&&bodyW<=minW1280?'45%':'50%':'100%',
                  height:'100%'
                }}
              >
                <AlertsComponents {...this.props} ricData={ricData}/>
              </div> 
            :
              ''
          }
          {
            isRightPageShow=='trade'?
              <div 
                className={isMobile&&isRightPageShow=='trade'?APPstyles.position_fixed:''}
                style={{
                  width:isPC?ChgLangData=='en-US'&&bodyW<=minW1280?'45%':'50%':'100%',
                  height:'100%'
                }}
              >
                <TradeComponents 
                  {...this.props}
                  ricData={ricData}
                  isBackTopShow={isBackTopShow}
                  calkbackBtnStatus={this.calkbackBtnStatus.bind(this)} 
                  calkbackOrderData={this.calkbackOrderData.bind(this)} 
                  callbackChgNavIndex={(data)=>{
                    //更改navindex
                    this.props.callbackChgNavIndex(data)
                  }}
                  callbackTradeShow={(tag,data)=>{
                    this.props.callbackTradeShow(tag,data)
                  }}
                  cbDetailPageShow={(data)=>{
                    this.props.cbDetailPageShow(data)
                  }}
                  cbSwitchMoalShow={(data)=>{
                    this.props.cbSwitchMoalShow(data);
                  }}
                />
              </div> 
            :
              ''
          }
        </div>
      </div>
     
  )}
}
export default withTranslation()(QuoteComponents)