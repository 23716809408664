import React from 'react';
import {withTranslation} from 'react-i18next';
import * as XLSX from 'xlsx';
import { Spin } from 'antd';
import common from '@/asserts/js/common';
import { 
  dropUpOnLightImg,
  dropUpOnImg,
  dropUpImg,
  dropDownImg,
  dropDownOnImg,
  dropDownOnLightImg,
} from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class StatementListIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  sortClick(name,tag,sortName){
    this.props.cbSortData(name,tag,sortName);
  }
  // 以excel的格式把api数据下载下来
  exportToExcel = (apiData) => {
    const {ChgLangData}=this.props;
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = XLSX.utils.book_new();
    // 向工作簿添加工作表，并命名
    XLSX.utils.book_append_sheet(wb, ws, 'Income Data');
    // 定义导出的文件名
    let exportFileName = ChgLangData === 'zh-CN' ? '對帳單' : 'Statement';
    // 写出工作簿（这里以二进制字符串形式）
    XLSX.writeFile(wb, exportFileName+'.xlsx');
  }
  componentDidUpdate(newProps){
    const {isExport, history, ChgLangData}=this.props;
    if(isExport !== newProps['isExport']){
      if(history && history.length>0){
        let translatedHistoryData;
        if(ChgLangData === 'zh-CN'){
          // 定义键的翻译
          const keyTranslation = {
            country: '市場',
            buy_sell: '買賣',
            stock_id: 'ID',
            stock_code: '代碼',
            stock_name: '名稱',
            qty_filled: '股數',
            transacted_price: '價格',
            transacted_amount: '價金',
            handling_fee: '手續費',
            processing_fee: '處理費',
            exchange_fee: '交易費',
            settlement_fee: '結算費',
            transaction_tax: '交易稅',
            stamp_duty: '印花稅',
            arap_amount: '應收付',
            currency: '幣別',
            payment_date: '交割日',
            source: '來源別',
            remittance_fee: '收款',
          };
          // 重新映射数据
          translatedHistoryData = history.map(record => {
            let newRecord = {};
            for(let key in record){
              newRecord[keyTranslation[key]] = record[key];
            }
            return newRecord;
          });
        } else {
          translatedHistoryData = history;
        }
        this.exportToExcel(translatedHistoryData);
      }
    } 
  } 
  render() {
    const {t,isPC,sortData,ChgThemeData,ChgLangData,isSearchPageShow,sizeTotal,recperpage,isLoading,history} = this.props;
    return (
      <div
        ref={el => this.container = el}
        className={[
          isSearchPageShow?APPstyles.flex1_auto:'',
          isSearchPageShow?Math.ceil(sizeTotal / recperpage)>1?APPstyles.paddingBottom30:history.length?APPstyles.paddingBottom40:'':'',
        ].join(' ')}
      >
        <div className={[styles.header,styles.common,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <div className={styles.child}>{t('Country2')}</div>
          <div className={[APPstyles.flex_row,styles.child].join(' ')}>
            {t('direction')}
            <div className={[APPstyles.upDown_con,APPstyles.flex_column].join(' ')}>
              <div 
                className={APPstyles.img_dropdown_up} 
                onClick={()=>{this.sortClick('direction','direction_asc','asc')}}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                  sortData[0]['upImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortData[0]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                  sortData[0]['upImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }}
              >
                <img src={sortData[0]['upImg']} />
              </div>
              <div 
                className={APPstyles.img_dropdown_down} 
                onClick={()=>{this.sortClick('direction','direction_desc','desc')}}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                  sortData[0]['downImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortData[0]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                  sortData[0]['downImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }}
              >
                <img src={sortData[0]['downImg']} />
              </div>
            </div>
          </div>
          <div className={styles.child}>{t('code')}</div>
          <div className={styles.child}>{t('Name1')}</div>
          <div className={styles.child}>{t('QTY Filled3')}</div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Transacted'}</span>}
            {ChgLangData=='en-US'&&<span>{'Price'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'價格'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Transacted'}</span>}
            {ChgLangData=='en-US'&&<span>{'Amt'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'價金'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Handling'}</span>}
            {ChgLangData=='en-US'&&<span>{'Fee'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'手續費'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Processing'}</span>}
            {ChgLangData=='en-US'&&<span>{'Fee'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'處理費'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Exchange'}</span>}
            {ChgLangData=='en-US'&&<span>{'Fee'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'交易費'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Settlement'}</span>}
            {ChgLangData=='en-US'&&<span>{'Fee'}</span>}
            {ChgLangData=='zh-CN'&&<span>{'結算費'}</span>}
          </div>
          <div className={[ChgLangData=='en-US'&&APPstyles.flex_column,styles.child].join(' ')}>
            {ChgLangData=='en-US'&&<span>{'Transaction'}</span>}
            {ChgLangData=='en-US'&&<span>{'Tax'}</span>}
            {ChgLangData=='zh-CN'&&'交易稅'}
          </div>
          <div className={styles.child}>{t('Stamp Duty')}</div>
          <div className={styles.child}>{t('AR/AP Amt1')}</div>
          <div className={styles.child}>{t('Currency3')}</div>
          <div className={[APPstyles.flex_row,styles.child].join(' ')}>
            {t('Payment Date1')}
            <div className={[APPstyles.upDown_con,APPstyles.flex_column].join(' ')}>
            <div 
                className={APPstyles.img_dropdown_up} 
                onClick={()=>{this.sortClick('payable_date','payment_date_asc','asc')}}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg
                  sortData[3]['upImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortData[3]['sortKey']=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
                  sortData[3]['upImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }}
              >
                <img src={sortData[3]['upImg']} />
              </div>
              <div 
                className={APPstyles.img_dropdown_down} 
                onClick={()=>{this.sortClick('payable_date','payment_date_desc','desc')}}
                onMouseOver={()=>{
                  if(!isPC) return
                  let imgurl=ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg
                  sortData[3]['downImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }} 
                onMouseOut={()=>{
                  if(!isPC) return
                  let imgurl=sortData[3]['sortKey']=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
                  sortData[3]['downImg']=imgurl
                  this.props.cgChgSortDataFun(sortData)
                }}
              >
                <img src={sortData[3]['downImg']} />
              </div>
            </div>
          </div>
          <div className={styles.child}>{t('Source1')}</div>
        </div>
        <div className={history.length?'':APPstyles.minH300} style={{position:!history.length&&!isLoading?'relative':'unset'}}>
          <div className={APPstyles.isLoading}><Spin spinning={isLoading} /></div>
          {history.length>0?
            history.map((item,index) => {
              return  <div
                        key={index} 
                        className={[
                          styles.body,
                          styles.common,
                          APPstyles.flex_row,
                          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                          ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
                        ].join(' ')}
                      >
                        <span className={styles.child}>{t(item['country'])}</span>
                        <span className={[styles.child,item['buy_sell']=='B'?APPstyles.color_red:APPstyles.color_green].join(' ')}>
                          {t(common.charAtToUpperCase(item['buy_sell']=='B'?'Buy':'Sell'))}
                        </span>
                        <span className={styles.child} style={{fontWeight:700}}>{t(item['stock_code'])}</span>
                        <span className={[styles.child,ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur].join(' ')} style={{fontWeight:500}}>
                          <label className={APPstyles.ellipsis}>{t(item['stock_name'])}</label>
                        </span>
                        <span className={styles.child}>{t(item['qty_filled'])}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['transacted_price'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['transacted_amount'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['handling_fee'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['processing_fee'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['exchange_fee'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['settlement_fee'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['transaction_tax'],2))}</span>
                        <span className={styles.child}>{common.transThousands(common.keepNum(item['stamp_duty'],2))}</span>
                        <span className={[styles.child,Number(item['arap_amount'])>0?APPstyles.color_red:Number(item['arap_amount'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')}>
                          {common.transThousands(common.keepNum(item['arap_amount'],2))}
                        </span>
                        <span className={styles.child}>{t(item['currency'])}</span>
                        <span className={styles.child}>{common.onlyOrderDate(item['payment_date'])}</span>
                        <span className={styles.child}>{t(item['source']?item['source']:'-')}</span>
                      </div>
            }):
            <div className={APPstyles.isLoading} style={{display:isLoading?'none':''}}>{t('No datas')}</div>    
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(StatementListIndex);