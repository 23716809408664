//console.log(process.env.REACT_APP_ENV);
const config = {
  URL:
    process.env.REACT_APP_ENV === "production"
      ? window.location.origin
      : "http://t1.trkd-asia.com",
  URL_HTTPS:
    process.env.REACT_APP_ENV === "production"
      ? window.location.origin
      : "https://t1.trkd-asia.com",
};

export default config;
