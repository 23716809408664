import React from 'react'
import { withTranslation } from 'react-i18next'
import HighchartsMore from 'highcharts/highcharts-more'
import Hightcharts from 'highcharts'
import common from '../../../../../asserts/js/common'
HighchartsMore(Hightcharts)

class PeerColumn extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  onLoadData(){
    const {t,id,ChgThemeData,allChartData,bodyW,mobilebodyW}=this.props
    Hightcharts.chart(id,{
      chart: {
        type: "column",
        width: bodyW < mobilebodyW ? Number(bodyW) - 32 : undefined,
        height: 218,
        marginTop: 30,
        marginBottom: 49,
        plotBorderColor: "rgba(0,0,0,0)",
        plotBorderWidth: 1.5,
        backgroundColor: "rgba(0,0,0,0)",
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: false
      },
      xAxis:{
        type: 'category',
        lineColor: "#828282",
        lineWidth: 1,
        categories: allChartData['PeerComxAisxData'],
        autoRotationLimit: 10,
        autoRotation: false,
        labels: {
          style: {
            color:"#828282",
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '11px',
          }
        }
      },
      yAxis: {
        softMax: 1,
        lineColor: "rgba(0,0,0,0)",
        lineWidth: 0,
        gridLineColor: ChgThemeData === "Light" ? "#E0E0E0" : "#373737",
        alternateGridColor: "rgba(0,0,0,0)",
        min: 0,
        max: 10,
        tickInterval: 2,
        title: {
          text: "",
        },
        labels: {
          style: {
            color:"#828282",
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '11px',
          }
        }
      },
      tooltip:{
        borderWidth:0,
        backgroundColor:ChgThemeData=='Light'?'#FFFFFF':'#222429',
        style:{
          color:ChgThemeData=='Light'?'#333333':'#FFFFFF',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '14px'
        },
        valueDecimals: 0,
        pointFormatter(){
          let str='<div><span style="color:'+this['color']+'">●</span><i style="opacity:0;color:none">.</i><span>'+t('Score')+' : '+common.transThousands(this['y'])+'</span></div>'
          return str
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          type: "column",
          name: "",
          data: allChartData['PeerComData'],
        },
      ],
      plotOptions: {
        column: {
          borderWidth: 0,
          colorByPoint: true,
          colors: allChartData['colorsData']
        },
        series: {
          dataLabels: {
            enabled: true,
            crop:false,
            overflow:'none',
            style: {
              color:ChgThemeData === "Light" ? "#333333" : "#FFFFFF",
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: '11px',
            }
          },
        },
      },
      credits: {
        enabled: false,
      },
    })
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {ChgThemeData,ChgLangData,isRightPageShow,isToggleOn,chartsData}=this.props
    if(
      ChgThemeData!==newProps['ChgThemeData']||
      ChgLangData!==newProps['ChgLangData']||
      isRightPageShow!==newProps['isRightPageShow']||
      isToggleOn!==newProps['isToggleOn']||
      chartsData!==newProps['chartsData']
    ){
      this.onLoadData()
    }
  }
  //初始化
  componentDidMount(){
    setTimeout(()=>{
      this.onLoadData()
    }, 10) 
  }
  render(){
    const {id}=this.props
    return (
      <div id={id} style={{width:'100%',height:'100%'}}></div>
    )
  }
}
export default withTranslation()(PeerColumn)