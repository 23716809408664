import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const PRR = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("808080");

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.PRR_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const PRR = localChartSetting.option;

        props.onOverlayValueChange({
          ta: "PRR",
          name: `${t("chart5.TA.PRR")} (PRR)`,
          option: { color: PRR.color },
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const PRR = props.localChartSetting.PRR_Option.option;

    setColor(PRR.color);
  }, [props.localChartSetting]);


  useEffect(() => {
    props.onOverlayValueChange({
      ta: "PRR",
      name: `${t("chart5.TA.PRR")} (PRR)`,
      option: { color: color },
    });
  }, [color]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const PRR = props.localChartSetting.PRR_Option.option;

    setColor(PRR.color);
  }, [props.isReset]);

  const resetALL = () => {
    setColor("808080");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "PRR":
        setColor(props.selectedColor);
        break;
    }
  };

  const onColorClick = () => {
    props.onColorClick(color, "PRR", t("chart5.setting"));
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("PRR")}></div> */}
      <div className="box-container">
        <span className="span">{t("chart5.TA.PRR")} (PRR)</span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("PRR")}
        />
      </div>

      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem" id="msi-ta-PRR">
          <div className="flexbox">
            <div className="taparamlbl">
              {t("chart5.technicalAnalysisLine")}
            </div>
            <button
              id="msi-ta-PRR-c-color"
              className="taparam colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={onColorClick}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};
