import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const DMI = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(14);
  const [color, setColor] = useState("EE3311");
  const [color2, setColor2] = useState("449922");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [ADXEnable, setADXEnable] = useState(true);
  const [ADXColor, setADXColor] = useState("0080FF");
  const [ADXThickness, setADXThickness] = useState(2);
  const [ADXStyle, setADXStyle] = useState("s");
  const [ADXShowTracker, setADXShowTracker] = useState(true);

  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(period);

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.DMI_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      props.onTAValueChange({
        ta: "DMI",
        name: `${t("chart5.TA.DMI")} (DMI)`,
        option: {
          enabled: localChartSetting.option.enabled,
          period: localChartSetting.option.period,
          color: localChartSetting.option.color,
          color2: localChartSetting.option.color2,
          thickness: localChartSetting.option.thickness,
          style: localChartSetting.option.style,
          showTracker: localChartSetting.option.showTracker,
          height: localChartSetting.option.height,
          adx: {
            enabled: localChartSetting.option.adx.enabled,
            color: localChartSetting.option.adx.color,
            thickness: localChartSetting.option.adx.thickness,
            style: localChartSetting.option.adx.style,
            showTracker: localChartSetting.option.adx.showTracker,
          },
        },
        value: localChartSetting.value,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    const DMI = props.localChartSetting.DMI_Option
    setEnable(DMI.option.enabled);
    setPeriod(parseInt(DMI.option.period) || period);
    setColor(DMI.option.color);
    setColor2(DMI.option.color2);
    setThickness(parseInt(DMI.option.thickness) || thickness);
    setStyle(DMI.option.style);
    setShowTracker(DMI.option.showTracker);
    setADXEnable(DMI.option.adx.enabled);
    setADXColor(DMI.option.adx.color);
    setADXThickness(parseInt(DMI.option.adx.thickness) || ADXThickness);
    setADXStyle(DMI.option.adx.style);
    setADXShowTracker(DMI.option.adx.showTracker);
    setHeight(DMI.option.height);
  }, [props.localChartSetting]);

  useEffect(() => {
    setValue(period);
    props.onTAValueChange({
      ta: "DMI",
      name: `${t("chart5.TA.DMI")} (DMI)`,
      option: {
        enabled: enable,
        period: period,
        color: color,
        color2: color2,
        thickness: thickness,
        style: style,
        showTracker: showTracker,
        height: height,
        adx: {
          enabled: ADXEnable,
          color: ADXColor,
          thickness: ADXThickness,
          style: ADXStyle,
          showTracker: ADXShowTracker,
        },
      },
      value: value,
    });
  }, [
    enable,
    period,
    color,
    color2,
    thickness,
    style,
    showTracker,
    height,
    ADXEnable,
    ADXColor,
    ADXThickness,
    ADXStyle,
    ADXShowTracker,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const DMI = props.localChartSetting.DMI_Option
    setEnable(DMI.option.enabled);
    setPeriod(parseInt(DMI.option.period) || period);
    setColor(DMI.option.color);
    setColor2(DMI.option.color2);
    setThickness(parseInt(DMI.option.thickness) || thickness);
    setStyle(DMI.option.style);
    setShowTracker(DMI.option.showTracker);
    setADXEnable(DMI.option.adx.enabled);
    setADXColor(DMI.option.adx.color);
    setADXThickness(parseInt(DMI.option.adx.thickness) || ADXThickness);
    setADXStyle(DMI.option.adx.style);
    setADXShowTracker(DMI.option.adx.showTracker);
    setHeight(DMI.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(14);
    setColor("EE3311");
    setColor2("449922");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);
    setADXEnable(true);
    setADXColor("0080FF");
    setADXThickness(2);
    setADXStyle("s");
    setADXShowTracker(true);
    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "ADX":
        if (ADXEnable) setADXEnable(false);
        else setADXEnable(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "p":
        setStyle(e.target.value);
        break;
      case "ADX":
        setADXStyle(e.target.value);
        break;
    }
  };

  const onPeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "p":
        setThickness(e.target.value);
        break;
      case "ADX":
        setADXThickness(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "p":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "ADX":
        if (ADXShowTracker) setADXShowTracker(false);
        else setADXShowTracker(true);
        break;
    }
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "DMI-C1":
        setColor(props.selectedColor);
        break;
      case "DMI-C2":
        setColor2(props.selectedColor);
        break;
      case "ADX":
        setADXColor(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("DMI")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.DMI")} (DMI) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("DMI")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-DMI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-DMI-di-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")}</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-DMI-di-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e)}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">+DI</div>
            <button
              id="msi-ta-DMI-di-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "DMI-C1", "+DI")}
            ></button>

            <div className="styleboxlbl">-DI</div>
            <button
              id="msi-ta-DMI-di-color1"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() => onColorClick(color2, "DMI-C2", "-DI")}
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-DMI-di-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p")}
              />
            </div>

            <div className="selectbox" onChange={(e) => onStyleChange(e, "p")}>
              <select id="msi-ta-DMI-di-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-DMI-di-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => {
                  onShowTracker("p");
                }}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={ADXEnable ? {} : { opacity: "0.5" }}
          id="msi-ta-DMI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-DMI-adx-enabled"
              className={
                ADXEnable ? "checkboxbutton-selected" : "checkboxbutton"
              }
              onClick={(e) => onEnableChange("ADX")}
            ></div>
            <div className="taparamlbl">ADX</div>
          </div>

          <div className="menusubitem stylebox">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-UI-p0-color0"
              className="colorpicker"
              value={ADXColor}
              data-colorpickerdialogtitle="表示設定"
              style={{ backgroundColor: "#" + ADXColor }}
              onClick={() => onColorClick(ADXColor, "ADX", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-UI-p0-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={ADXThickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "ADX")}
              />
            </div>
            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "ADX")}
            >
              <select id="msi-ta-UI-p0-style" value={ADXStyle}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-UI-p0-showtracker"
                className={
                  ADXShowTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={() => {
                  onShowTracker("ADX");
                }}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                value={height}
                onChange={(e) => onHeightChange(e)}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
