import React from 'react'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { 
  closeOnLightImg,
  closeOnImg,
  closeImg,
  leftImg,
  leftLightImg
} from '../../../../asserts/imgs/common/index'
import DetailChild from './DetailChild'
import Status from './Status'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class Detail extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      minH900:900,
      chgCloseImg:closeImg,
      isStatusShow:false
    }
  }
  render(){
    const {t,bodyH,ChgThemeData,isMobile,isTablet,isPC} = this.props
    const {minH900,chgCloseImg,isStatusShow}=this.state
    return (
      <div className={styles.order_fixed}>
        <div
          className={[
            APPstyles.flex_column,
            styles.order_con,
            ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
          ].join(' ')}
          style={{
            width:!isMobile?525:'100%',
            height:!isMobile?bodyH<=minH900?'80%':!isTablet?728:712:'100%'
          }}
          >
            {/* title */}
          <div 
            className={[
              isMobile||(!isMobile&&isStatusShow)?'':APPstyles.flex_between,
              isMobile||(!isMobile&&isStatusShow)?APPstyles.flex_row:'',
              styles.title,
              styles.tradeflow_con,
              ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
            ].join(' ')}
          >
            <img 
              src={ChgThemeData=='Light'?leftLightImg:leftImg} 
              alt='back' 
              className={APPstyles.img24} 
              style={{
                display:isMobile||(!isMobile&&isStatusShow)?'':'none'
              }} 
              onClick={()=>{
                if(isStatusShow){
                  this.setState({
                    isStatusShow:false
                  })
                  return
                }
                this.props.cbCloseDetail(false)
              }}  
            />
            <span>{t(isStatusShow?'Status':'Today’s Order')}</span>
            <img 
              src={chgCloseImg} 
              alt='close' 
              className={[
                APPstyles.img24,
                isPC&&isStatusShow?styles.detail_close:'',
                isPC&&isStatusShow?APPstyles.transformY:''
              ].join(' ')} 
              style={{
                display:(!isMobile&&!isStatusShow)||(isPC&&isStatusShow)?'':'none'
              }}  
              onClick={()=>{
                this.props.cbCloseDetail(false)
              }}    
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg
                this.setState({
                  chgCloseImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                this.setState({
                  chgCloseImg:closeImg
                })
              }}
            />
          </div>
          {/* content */}
            {!isStatusShow&&<DetailChild 
              {...this.props}
              cbChgStatusShowFun={()=>{
                this.setState({
                  isStatusShow:true
                })
              }}
            />}
            {isStatusShow&&<Status 
              {...this.props}
            />}
          {/* btn */}
          <div 
            className={[
              styles.button_con,
              ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark
            ].join(' ')}
            style={{
              display:isStatusShow?'none':''
            }}
          >
            <Button 
              type='default' 
              className={[APPstyles.btn_blue,APPstyles.bg_blue].join(' ')}
              onClick={()=>{
                this.props.cbCloseDetail(false)
              }}
            >
              {t('OK')}
            </Button>
          </div>
        </div>
      </div>
  )}
}
export default withTranslation()(Detail)