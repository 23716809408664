import React from 'react'
import { withTranslation } from 'react-i18next'
import { 
  searchImg,
  searchOnImg,
  searchOnLightImg,
} from '@/asserts/imgs/common/index'
import { 
  editHoverImg,
  editOffImg,
  editOnLightImg
} from '@/asserts/imgs/quote/index'
import Tabs from '@/components/Tabs/index'
import AlertsCountButton from '@/components/Alerts/components/AlertsCountButton'
import APPstyles from '@/App.less'
import styles from './index.less'

class Header extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgSearchImg:searchImg,
      editImg:editOffImg
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {isLeftPageShow,ChgThemeData}=this.props
    if((isLeftPageShow!==newProps['isLeftPageShow']||ChgThemeData!==newProps['ChgThemeData'])){
      let imgurl_0=isLeftPageShow=='add'?ChgThemeData=='Light'?searchOnLightImg:searchOnImg:searchImg
      let imgurl_1=isLeftPageShow=='edit'?ChgThemeData=='Light'?editOnLightImg:editHoverImg:editOffImg
      this.setState({
        chgSearchImg:imgurl_0,
        editImg:imgurl_1
      })
    }
  }  
  render(){
    const {ChgThemeData,isPC,isTablet,isMobile,isShowHeaderPage,currentIndex,isLeftPageShow,tabsData} = this.props
    const {chgSearchImg,editImg}=this.state
    return (
      <div 
        className={[
          styles.header_con,
          APPstyles.flex_between,
          ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark,
          isShowHeaderPage?'':APPstyles.display_none
        ].join(' ')} 
        style={{
          minHeight:49
        }}
      >
        <div
          className={[
            APPstyles.flex_row,
            APPstyles.flex1_hidden,
            styles.header_left,
            isTablet?styles.quote_layoutMin:''
          ].join(' ')} 
          style={{
            marginLeft:isPC?72:0
          }}
        >
          <img 
            src={chgSearchImg} 
            alt='search' 
            className={APPstyles.img24} 
            style={{
              margin:'0 16px',
              display:isMobile?'none':''
            }}
            onClick={()=>{
              this.props.callbackLeftPageShow('add')
              window.sessionStorage.removeItem('ChgAddData')
              //ios终端
              if((currentIndex==2||currentIndex==3)&&isMobile){
                this.props.cbIsShowHeaderPage(false)
              }
            }} 
            onMouseOver={()=>{
              if(!isPC) return
              let imgurl=ChgThemeData=='Light'?searchOnLightImg:searchOnImg
              this.setState({
                chgSearchImg:imgurl
              })
            }} 
            onMouseOut={()=>{
              if(!isPC) return
              let imgurl=isLeftPageShow=='add'?ChgThemeData=='Light'?searchOnLightImg:searchOnImg:searchImg
              this.setState({
                chgSearchImg:imgurl
              })
            }}
          />
          <div 
            className={[APPstyles.flex_row,APPstyles.flex_1,styles.header_left_tab].join(' ')}
            style={{
              paddingLeft:isMobile?16:0,
              paddingRight:isMobile?16:0
            }}
          >
            <Tabs 
              {...this.props} 
              tag='quote'
              tabData={tabsData}
              callbackTabIndex={(index)=>{
                this.props.callbackTab(index)
                this.props.callbackLeftPageShow('list')
              }} 
              cbWatchlistId={(id)=>{
                this.props.cbWatchlistId(id)
              }} 
            />
            <img 
              src={editImg} 
              alt='edit' 
              className={APPstyles.img24} 
              style={{marginRight:isMobile?0:16}} 
              onClick={()=>{
                this.props.callbackLeftPageShow('edit')
                window.sessionStorage.removeItem('ChgAddData')
                //ios终端
                if((currentIndex==2||currentIndex==3)&&isMobile){
                  this.props.cbIsShowHeaderPage(false)
                }
              }}  
              onMouseOver={()=>{
                if(!isPC) return
                let imgurl=ChgThemeData=='Light'?editOnLightImg:editHoverImg
                this.setState({
                  editImg:imgurl
                })
              }} 
              onMouseOut={()=>{
                if(!isPC) return
                let imgurl=isLeftPageShow=='edit'?ChgThemeData=='Light'?editOnLightImg:editHoverImg:editOffImg
                this.setState({
                  editImg:imgurl
                })
              }}
            />
          </div>
          <div className={[APPstyles.img_shadow,ChgThemeData=='Light'?APPstyles.img_shadow_light:''].join(' ')} style={{display:isMobile?'':'none'}}>
            <img 
              src={isMobile&&ChgThemeData=='Dark'?searchOnImg:chgSearchImg} 
              alt='search' 
              className={APPstyles.img24} 
              style={{
                margin:'0 16px'
              }}
              onClick={()=>{
                this.props.callbackLeftPageShow('add')
                window.sessionStorage.removeItem('ChgAddData')
                //ios终端
                if((currentIndex==2||currentIndex==3)&&isMobile){
                  this.props.cbIsShowHeaderPage(false)
                }
              }}
            />
          </div>
        </div>
        {
          isMobile || isTablet?
            ''
          :
            <AlertsCountButton {...this.props} callbackAlertsShow={this.props.callbackAlertsShow}/>
        }
      </div>
    )}
}
export default withTranslation()(Header)