import React from 'react';
import { Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { closeImg, closeOnImg, closeOnLightImg } from '@/asserts/imgs/common/index';
import APPstyles from '@/App.less';
import styles from '../note/index.less';

class AgreeNoticeIndex extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      chgCloseImg:closeImg,
      careData:[
        {

          num:'一、',
          title:'除簽署上述風險預告書外，買賣下列外國有價證券需符合一定資格條件:',
          data:[
            {num:'1.',title:'首次買賣具槓桿或放空效果之ETF，且以正向不超過二倍或反向不超過一倍者，或'},
            {num:'2.',title:'欲交易香港創業版股票、封閉型基金(CEF)者'}
          ],
        },
        {
          num:'二、',
          title:'委託人均需符合下列資格條件之一，始可買賣:',
          data:[
            {num:'1.',title:'已開立國內信用交易帳戶。'},
            {num:'2.',title:'最近一年內委託買賣國內或外國認購（售）權證成交達十筆（含）以上。'},
            {num:'3.',title:'最近一年內委託買賣國內或外國期貨交易契約成交達十筆(含)以上。'},
            {num:'4.',title:'委託買賣國內或外國證券交易市場掛牌之槓桿或放空效果ETF之成交紀錄。'},
          ],
        }
      ],
      listData:[
        {name:'帳號',value:''},
        {name:'簽署日期',value:''},
        {name:'跳動單位',value:''},
        {name:'動作',value:''},
      ]
    }
  }
  onCloseClick(){
    this.props.cbBtnDataFun();
  };
  onMouseOver = () => {
    const {ChgThemeData} = this.props;
    let imgurl=ChgThemeData=='Light'?closeOnLightImg:closeOnImg;
    this.updateImage(imgurl);
  };
  onMouseOut = () => {
    this.updateImage(closeImg);
  };
  updateImage(imgurl) {
    const {isPC} = this.props;
    if(!isPC) return;
    this.setState({
      chgCloseImg: imgurl,
    });
  };
  componentDidMount() {
    const {accessData,riskNoticeData}=this.props;
    const {listData}=this.state;
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    let currentDate = `${year}/${month}/${day}`;

    listData[0]['value']=accessData['aid'];
    listData[1]['value']=currentDate;
    listData[2]['value']=riskNoticeData['code']==0?'已簽署':'未簽署';

    this.setState({
      listData,
    });
  };
  render(){
    const {t,isMobile,ChgThemeData,visible,accessData,user_email}=this.props;
    const {chgCloseImg,careData,listData}=this.state;
    return (
      <Modal 
        centered={true}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={visible}
        className={[styles.agreeCon,styles.agreeNoticeCon,APPstyles.flex_column,ChgThemeData=='Light'?styles.modalLight:'',isMobile&&styles.modalMobile].join(' ')}
      >
        <div className={[styles.noteTitle,APPstyles.flex_between,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,ChgThemeData==='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span>{t('複委託風險預告書')}</span>
          <img 
            src={chgCloseImg} 
            className={APPstyles.img24} 
            onClick={()=>this.onCloseClick()}
            onMouseOver={() => this.onMouseOver()}
            onMouseOut={() => this.onMouseOut()}
          />
        </div>
        <div className={[styles.messageCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={APPstyles.color_grey}>請選擇帳號:</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{accessData['aid']}</span>
        </div>
        <div className={[styles.messageCon,APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={APPstyles.color_grey}>電子信箱:</span>
          <div className={[APPstyles.flex_row,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{alignItems:'center'}}>
            <span>{user_email}</span>
          </div>
        </div>
        <div className={[styles.agreeContent,APPstyles.flex1_auto,ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark].join(' ')}>
          <span className={styles.lineCon}>&nbsp;</span>
          <div className={APPstyles.flex_column} style={{padding:16}}>
            <div className={APPstyles.flex_column} style={{color:'#F5475B'}}>
              <span className={styles.titleCon}>請注意：</span>
              {careData.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={[APPstyles.flex_row,styles.titleCon].join(' ')}>
                      <span>{item['num']}</span>
                      <span>{item['title']}</span>
                    </div>
                    {item['data'].map((item1, index1) => {
                      return (
                        <div key={index1} style={{padding:'0 16px'}}>
                          <div className={APPstyles.flex_row} style={{marginBottom:6}}>
                            <span>{item1['num']}&nbsp;</span>
                            <span>{item1['title']}</span>
                          </div>
                        </div>
                      )})
                    }
                  </div>
                )})
              }
            </div>
            <span style={{fontWeight:'700',margin:'4px 0 16px 0'}}>＊表單編號：複委託(客)050—111.11 經紀業務作服部版</span>
            <div className={[APPstyles.flex_row].join(' ')} style={{alignItems:'stretch'}}>
              {listData.map((item, index) => {
                  return (
                    <div 
                      key={index} 
                      className={[
                        APPstyles.flex_column,
                        APPstyles.flex_1,
                        styles.accountListCon,
                        ChgThemeData=='Light'&&APPstyles.accountListConLight,
                        index==listData.length-1?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                      ].join(' ')}
                    >
                      <span>{item['name']}</span>
                      <span>{item['value'] ? item['value'] : <label>&nbsp;</label>}</span>
                    </div>
                  )})
                }
            </div>
          </div>
        </div>
      </Modal>
  )}
}
export default withTranslation()(AgreeNoticeIndex);