import React from 'react'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import common from '@/asserts/js/common'
import { 
  tipImg,
  tipOnImg,
  tipOnLightImg
} from '@/asserts/imgs/common/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class SecurityAssets extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,hasData,isAssets,ChgThemeData,isPC,isTablet,isMobile,isSearchPageShow,tipsImg1,chgCur} = this.props
    return (
      <div
        className={[
          APPstyles.flex_column,
          isMobile?ChgThemeData=='Dark'?styles.msg_con_mobileD:styles.msg_con_mobileW:'', 
          isAssets&&(!isMobile&&isTablet&&isSearchPageShow)&&styles.min_height64,
          isAssets?'':(isPC&&isSearchPageShow)||(!isMobile&&isTablet&&!isSearchPageShow)?styles.width_per5:'',
          isAssets&&isMobile&&styles.min_height62,
          isAssets?'':(!isMobile&&isTablet&&isSearchPageShow)?'':styles.marginRight36
        ].join(' ')}
        style={{
          display:isAssets?
            (isPC&&!isSearchPageShow)||(!isMobile&&isTablet&&isSearchPageShow)||isMobile?'':'none'
          :
            (isPC&&!isSearchPageShow)||(!isMobile&&isTablet&&isSearchPageShow)||isMobile?'none':''
        }}
      >
        <div
          className={[
            APPstyles.flex_row,
            styles.tooltip_con,
            styles.min_height24
          ].join(' ')}
          style={{marginBottom:isMobile?2:0}}
        >
          <span className={styles.title} style={{marginBottom:0}}>{t('Total Security Assets')} <label style={{display:chgCur?'':'none'}}>({t(chgCur)})</label></span>
          <Tooltip 
            title={t("The Total Security Assets is for reference only. This calculation includes the buying commission fees, but does not include the selling commission fees, transaction tax, or other related expenses.")} 
            overlayClassName={ChgThemeData=='Light'?APPstyles.tooltip_con_custurm_light:APPstyles.tooltip_con_custurm}
          >
            <img 
              src={tipsImg1} 
              className={APPstyles.img16} 
              alt='tips'
              onMouseOver={()=>{
                let imgurl=ChgThemeData=='Light'?tipOnLightImg:tipOnImg
                this.props.cbImgurlFun(imgurl)
              }} 
              onMouseOut={()=>{
                this.props.cbImgurlFun(tipImg)
              }}
            />
          </Tooltip>
        </div>
        <span 
          className={[
            styles.num,
            ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white
          ].join(' ')}
          style={{
            fontSize:isMobile?isAssets?16:20:24,
            lineHeight:isMobile?isAssets?'18px':'24px':'28px'
          }}
        >
          {common.transThousands(common.keepNum(hasData&&hasData['total_security_assets'],2))}
        </span>
      </div>
  )}
}
export default withTranslation()(SecurityAssets)