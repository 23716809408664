import React from 'react'
import { Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import axios from 'axios'
import common from '@/asserts/js/common'
import Column from '@/asserts/js/charts/column'
import Tabs from '@/components/Tabs2/index'
import GaugeCon from './GaugeCon/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class Fundamentals extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            IncomeStatementData:[],
            DividendHistoryData:[],
            BalanceSheetData:[],
            CashFlowData:[],
            Ratios0Data:[],
            Ratios1Data:[],
            Ratios2Data:[],
            Ratios3Data:[],
            gaugeLoading:[false,false,false,false],
            columnLoading:[false,false,false,false],
            curData:['-','-','-','-'],
            isDividendHistoryShow:false,
            tabsData:[
                {
                    name:'Annual',
                    isShow:false,
                    key:'AnnualReport'
                },
                {
                    name:'Quarterly',
                    isShow:false,
                    key:'InterimReport'
                }
            ],
            tabIndex:0,
            xAxisData:[[],[],[],[]],
            colorsData:['#008FC7','#8D48A2','#009E9C','#AFADAD'],
            stockData:{PBR:0,PER:0,ROE:0,ROA:0},
            plotDHValue:'',
            plotISValue:'',
            AnnualReportData:[],
            InterimReportData:[],
            DHColorsData:[],
            DHLegendName:'',
            tooltipData:[],
            statementIndex:0,
            statementIData:[
                {
                    name:'Actual',
                    isShow:false,
                    key:'Actual'
                },
                {
                    name:'IBES Estimates',
                    isShow:false,
                    key:'IBES Estimates'
                }
            ],
            statementActualData:[],
            statementEstimatedData:[],
            isStatementTab:true
        }
    }
    //获取数据
    getData(ric){
        const {colorsData,tabIndex,statementIndex}=this.state
        let token=sessionStorage.getItem('token')
        axios
        .post(common.getHttp+'fundamentals', {
            token: token,
            ric: ric,
        })
        .then((res) => {
            //获取数据成功
            let data=res['data']['data']
            if(res['data']['status']==0&&data&&JSON.stringify(data) !== '{}'){
                // Financial Ratios
                this.FinancialRatiosFun(data['FinancialRatios'],colorsData)
                // Income Statement
                let getActualData=[],getEstimatedData=[]
                if(data['IncomeStatement']){
                    getActualData=this.reverseFun(data['IncomeStatement']['actual'])
                    getEstimatedData=this.reverseFun(data['IncomeStatement']['estimated'])
                }
                this.IncomeStatementFun(getActualData,getEstimatedData,colorsData,statementIndex)
                // Dividend History
                let getAnnualReportData=[],getInterimReportData=[]
                if(data['DividendHistory']){
                    getAnnualReportData=this.reverseFun(data['DividendHistory']['AnnualReport'])
                    getInterimReportData=this.reverseFun(data['DividendHistory']['InterimReport'])
                }
                this.dealDividendHistoryFun(getAnnualReportData,getInterimReportData,colorsData,tabIndex)
                // Balance Sheet
                this.BalanceSheetFun('BalanceSheet',data['BalanceSheet'].reverse(),2,'currency_converted_to','TotalAsset','TotalLiabilities',colorsData)
                // Cash Flow
                this.CashFlowFun(data['CashFlow'].reverse(),colorsData)
                
                this.setState({
                    columnLoading:[false,false,false,false],
                    AnnualReportData:getAnnualReportData,
                    InterimReportData:getInterimReportData,
                    statementActualData:getActualData,
                    statementEstimatedData:getEstimatedData
                })
                return
            }
            //获取失败
            this.onLoadDatas('err')
        }).catch(err => {
            //获取失败
            this.onLoadDatas('err')
        });
    }
    //处理图表数据--column
    dealColumnData(tag,datas,num,cur,key0,key1,key2,key3){
        const {curData,xAxisData} = this.state
        let data0=[],data1=[],data2=[],data3=[],getXAxisData=[],plotISValue=''
        if(datas&&datas.length>0){
            for(let i in datas){
                if(tag=='DividendHistory'){
                    let getValue=datas[i][key0]?datas[i][key0]:datas[i]['mean']
                    data0.push(Number(getValue))
                }else{
                    data0.push(Number(datas[i][key0]))
                    data1.push(Number(datas[i][key1]))
                }
                if(tag=='CashFlow'){
                    data2.push(Number(datas[i][key2]))
                    data3.push(Number(datas[i][key3]))
                }
                // Income Statement
                if(tag=='IncomeStatement'){    
                    data2.push(Number(datas[i][key2]))
                    getXAxisData.push(String(datas[i][key3==0?'fiscal_year':'end_year']))
                    if(key3==0){
                        if(plotISValue==''&&datas[i][key0]==null){
                            plotISValue=i==0?0:i-.5
                        }
                    }else{
                        if(plotISValue==''&&datas[i][key0]==null){
                            plotISValue=i==0?0:i-.5
                        }
                    }
                }
                if(tag=='BalanceSheet'||tag=='CashFlow'){
                    getXAxisData.push(datas[i]['fiscal_year'])
                }
            }
            let result={
                data0:data0,
                data1:data1,
                data2:data2,
                data3:data3
            }
            curData[num]=datas[0][cur]
            this.setState({
                curData:curData
            })
            if(tag!=='DividendHistory'){
                xAxisData[num]=getXAxisData
                this.setState({
                    xAxisData:xAxisData
                })
            }
            if(tag=='IncomeStatement'){
                this.setState({
                    plotISValue:plotISValue
                })
            }
            return result
        }
        let result={
            data0:data0,
            data1:data1,
            data2:data2,
            data3:data3
        }
        curData[num]='-'
        xAxisData[num]=[]
        this.setState({
            curData:curData,
            xAxisData:xAxisData,
            plotISValue:''
        })
        return result
    }
    //找出最大值对应的name
    findBigValueFun(data){
        let bigValue=data[0]['len'],bigName=data[0]['name']
        for(let i in data){
            if(data[i]['len']>bigValue){
                bigName=data[i]['name']
            }
        }
        return bigName
    }
    //反转数组
    reverseFun(arr){
        let newArr = []
        for(let i = arr.length - 1;i >= 0;i--){
            newArr[newArr.length] = arr[i]
        }
        return newArr
    }
    // 判断数组里面的值都为0
    isArrZeroFun(arr){
        let temp = [...new Set(arr)]
        return temp.length === 1 && temp[0] === 0
    }
    BalanceSheetFun(name,datas,num,cur,key0,key1,colorsData){
        const {columnLoading}=this.state
        let getBSData=this.dealColumnData(name,datas,num,cur,key0,key1)
        let data0=this.isArrZeroFun(getBSData['data0'])
        let data1=this.isArrZeroFun(getBSData['data1'])
        columnLoading[2]=false

        this.setState({      
            BalanceSheetData:data0&&data1?[]:[{
                name:'Total Asset',
                color:colorsData[3],
                data: getBSData&&getBSData['data0']
            }, {
                name: 'Total Liabilities',
                color:colorsData[0],
                data: getBSData&&getBSData['data1']
            }],
            columnLoading:columnLoading
        })
    }
    // Cash Flow
    CashFlowFun(resData,colorsData){
        const {columnLoading}=this.state 
        if(resData.length<=0){
            columnLoading[3]=false
            this.setState({ 
                CashFlowData:[],
                columnLoading:columnLoading
            })
            return
        }
        let getCFData=this.dealColumnData('CashFlow',resData,3,'currency_converted_to','SalesCF','InvestmentCF','FinancialCF','CashEquivalent')
        let data0=this.isArrZeroFun(getCFData['data0'])
        let data1=this.isArrZeroFun(getCFData['data1'])
        let data2=this.isArrZeroFun(getCFData['data2'])
        let data3=this.isArrZeroFun(getCFData['data3'])
        columnLoading[3]=false
        
        this.setState({ 
            CashFlowData:data0&&data1&&data2&&data3?[]:[{
                name: 'Sales CF',
                color:colorsData[3],
                data: getCFData['data0']
            }, {
                name: 'Investment CF',
                color:colorsData[0],
                data: getCFData['data1']
            }, {
                name: 'Financial CF',
                color:colorsData[2],
                data: getCFData['data2']
            }, {
                name: 'Cash Equivalent',
                color:colorsData[1],
                data: getCFData['data3']
            }],
            columnLoading:columnLoading
        })
    }
    // Income Statement
    IncomeStatementFun(data0,data1,colorsData,index){
        const {columnLoading}=this.state
        let actualData='',estimatedData=''
        if(index==0){
            actualData=this.dealColumnData('IncomeStatement',data0,0,'currency_converted_to','TotalRevenue','NetIncome','OperationIncome',0) 
        }else{
            estimatedData=this.dealColumnData('IncomeStatement',data1,0,'currency','TotalRevenue','NetIncome','OperationIncome',1)
        }
        columnLoading[0]=false
        let isShowChart=data0.length||data1.length?true:false
        this.setState({
            IncomeStatementData:isShowChart?[{
                name: 'Total Revenue',
                color:colorsData[3],
                data: index==0?actualData['data0']:estimatedData['data0']
            }, {
                name: 'Net Income',
                color:colorsData[0],
                data: index==0?actualData['data1']:estimatedData['data1']
            }, {
                name: 'Operating Income2',
                color:colorsData[2],
                data: index==0?actualData['data2']:estimatedData['data2']
            }]:[],
            columnLoading:columnLoading
        })
    }
    // Dividend History--deal
    dealDividendHistoryData(data,num,index){
        let getData=this.dealColumnData('DividendHistory',data,1,'a_currency','actvalue',num,index) 
        let newData=getData['data0']
        let isZero=newData.length?this.isArrZeroFun(newData):true
        let isShowChart=newData.length&&!isZero?true:false
        let obj={
            isShowChart:isShowChart,
            data:newData
        }
        return obj
    }
    // Dividend History
    dealDividendHistoryFun(data0,data1,colorsData,index){
        const {tabsData,DHColorsData,columnLoading,xAxisData}=this.state
        let isShowChart2=false,chartData=[],getXAxisData=[],plotDHValue=''
        let newData0=this.dealDividendHistoryData(data0,0,index)
        let newData1=this.dealDividendHistoryData(data1,1,index)
        if(data1&&index==1){
            tabsData[1]['name']=data1[0]['period_type']=='Q'?'Quarterly':'Semi-annual'
        }
        let getDatas=index==0?data0:data1
        if(getDatas.length){
            for(let i in getDatas){
                DHColorsData[i]=!getDatas[i]['actvalue']||getDatas[i]['actvalue']==null?colorsData[0]:colorsData[3]
                let str_m=getDatas[i]['end_month']
                let m=Number(str_m)>9?String(str_m):'0'+String(str_m)
                let y=String(getDatas[i]['end_year'])
                let newDate=index==0?y:m +'/'+ y
                getXAxisData.push(newDate)
                if(plotDHValue==''&&getDatas[i]['actvalue']==null){
                    plotDHValue=i==0?0:i-.5
                }
            }
        }
        xAxisData[1]=getDatas.length?getXAxisData:[]
        columnLoading[1]=false
        isShowChart2=(data0.length)||data1.length?true:false
        if(index==0){
            chartData=newData0['isShowChart']?[{
                name: 'Annual',
                data: newData0['data']
            }]:[]
        }else{
            chartData=newData1['isShowChart']?[{
                name: tabsData[1]['name'],
                data: newData1['data']
            }]:[]
        }
        this.setState({
            DividendHistoryData:chartData,
            tabsData:tabsData,
            isDividendHistoryShow:isShowChart2?!newData0['isShowChart']&&!newData1['isShowChart']?false:true:false,
            DHColorsData:DHColorsData,
            DHLegendName:index==0?'Annual':tabsData[1]['name'],
            columnLoading:columnLoading,
            xAxisData:xAxisData,
            plotDHValue:getDatas.length?plotDHValue:''
        })
    }
    //处理图表数据--Ratios
    dealFinancialRatiosData(item,key0,key1){
        let value=item[key0][key1]||item[key0][key1]!==null?item[key0][key1]:0
        return Number(value) 
    }
    //处理数据--让图表指针指到对应的位置
    getNewRatiosVaue(data){
        return Number(data)>=70?70:Number(data)<=-10?-10:Number(data)
    }
    // Financial Ratios
    FinancialRatiosFun(datas,colorsData){
        const {data}=this.props
        let symbol=data['symbol']
        // tooltip值
        let StockPBR=this.dealFinancialRatiosData(datas,'Stock','PBR')
        let SectorPBR=this.dealFinancialRatiosData(datas,'Sector','PBR') 
        let StockPER=this.dealFinancialRatiosData(datas,'Stock','PER')
        let SectorPER=this.dealFinancialRatiosData(datas,'Sector','PER') 
        let StockROE=this.dealFinancialRatiosData(datas,'Stock','ROE')
        let SectorROE=this.dealFinancialRatiosData(datas,'Sector','ROE') 
        let StockROA=this.dealFinancialRatiosData(datas,'Stock','ROA')
        let SectorROA=this.dealFinancialRatiosData(datas,'Sector','ROA')
        //data值
        let newStockPBR=this.getNewRatiosVaue(StockPBR)
        let newSectorPBR=this.getNewRatiosVaue(SectorPBR)
        let newStockPER=this.getNewRatiosVaue(StockPER)
        let newSectorPER=this.getNewRatiosVaue(SectorPER)
        let newStockROE=this.getNewRatiosVaue(StockROE)
        let newSectorROE=this.getNewRatiosVaue(SectorROE)
        let newStockROA=this.getNewRatiosVaue(StockROA)
        let newSectorROA=this.getNewRatiosVaue(SectorROA)
        
        this.setState({
            stockData:{PBR:StockPBR,PER:StockPER,ROE:StockROE,ROA:StockROA},
            tooltipData:[
                {stock:StockPBR,sector:SectorPBR,id:'Ratios0Id'},
                {stock:StockPER,sector:SectorPER,id:'Ratios1Id'},
                {stock:StockROE,sector:SectorROE,id:'Ratios2Id'},
                {stock:StockROA,sector:SectorROA,id:'Ratios3Id'}
            ],
            Ratios0Data:[{
                name: symbol?symbol:'Stock',
                data: [newStockPBR],
                color:colorsData[0],
                dial: {
                    backgroundColor:colorsData[0]
                }
              },{
                name: 'Sector',
                data: [newSectorPBR],
                color:colorsData[1]
            }],
            Ratios1Data:[{
                name: symbol?symbol:'Stock',
                data: [newStockPER],
                color:colorsData[0],
                dial: {
                    backgroundColor:colorsData[0]
                }
            },{
                name: 'Sector',
                data: [newSectorPER],
                color:colorsData[1]
            }],
            Ratios2Data:[{
                name: symbol?symbol:'Stock',
                data: [newStockROE],
                color:colorsData[0],
                dial: {
                    backgroundColor:colorsData[0]
                }
            },{
                name: 'Sector',
                data: [newSectorROE],
                color:colorsData[1]
            }],
            Ratios3Data:[{
                name: symbol?symbol:'Stock',
                data: [newStockROA],
                color:colorsData[0],
                dial: {
                    backgroundColor:colorsData[0]
                }
            },{
                name: 'Sector',
                data: [newSectorROA],
                color:colorsData[1]
            }],
            gaugeLoading:[false,false,false,false]
        })
    }
    //初始化数据
    onLoadDatas(tag){
        this.setState({
            Ratios0Data:[],
            Ratios1Data:[],
            Ratios2Data:[],
            Ratios3Data:[],
            DividendHistoryData:[],
            IncomeStatementData:[],
            BalanceSheetData:[],
            CashFlowData:[],
            columnLoading: tag=='ric'?[true,true,true,true]:[false,false,false,false],
            gaugeLoading: tag=='ric'?[true,true,true,true]:[false,false,false,false],
            curData:['-','-','-','-'],
            xAxisData:[[],[],[],[]],
            stockData:{PBR:0,PER:0,ROE:0,ROA:0},
            isDividendHistoryShow:false,
            QuarterlyData:[],
            AnnualReportData:[],
            InterimReportData:[],
            DHColorsData:[],
            DHLegendName:'',
            tooltipData:[]
        })
    }
    //更改数据
    chgData(){
        const {chgRic} = this.props
        
        if(chgRic&&typeof(chgRic)!=='undefined'&&chgRic!==''){
            this.setState({
                columnLoading: [true,true,true,true],
                gaugeLoading:[true,true,true,true],
                AnnualReportData:[],
                InterimReportData:[]
            })
            this.getData(chgRic)
        }
    }
    // isStatementTab--更改css
    chgStatementTabFun(){
        const {bodyW,ChgLangData,isPC,isMobile,currentIndex} = this.props
        let chgShow=true
        if(ChgLangData=='en-US'){
            if(isPC){
                chgShow=(bodyW>1540&&currentIndex!==3)||(currentIndex==3&&bodyW>1660)?true:false
            }else if(isMobile){
                chgShow=false
            }else{
                chgShow=(bodyW>870&&currentIndex!==3)||currentIndex==3?true:false
            }
        }else{
            if(isPC){
                chgShow=currentIndex==3&&bodyW>1250||currentIndex!==3?true:false
            }else if(isMobile){
                chgShow=false
            } 
        }
        this.setState({
            isStatementTab:chgShow
        })
    }
    // 监听组件传递的值
    componentDidUpdate(newProps){
        const {chgRic,bodyW,ChgLangData} = this.props
        if(chgRic&&chgRic!==newProps['chgRic']){
            this.chgData()
        }
        if(bodyW!==newProps['bodyW']||ChgLangData!==newProps['ChgLangData']){
            this.chgStatementTabFun()
        }
    }
    //初始化
    componentDidMount(){
        this.chgData()
        this.chgStatementTabFun()
    }
    render(){
        const {t,ChgThemeData,data,isRightPageShow,isPC,isTablet,isMobile,currentIndex,ChgLangData} = this.props
        const {IncomeStatementData,DividendHistoryData,BalanceSheetData,CashFlowData,Ratios0Data,Ratios1Data,Ratios2Data,Ratios3Data,gaugeLoading,isDividendHistoryShow,tabsData,columnLoading,curData,xAxisData,stockData,colorsData,plotDHValue,plotISValue,AnnualReportData,InterimReportData,DHColorsData,DHLegendName,tabIndex,tooltipData,statementIndex,statementIData,statementActualData,statementEstimatedData,isStatementTab}=this.state
        return (
            <div className={styles.body}>
                <div 
                    className={[
                        styles.chart,
                        isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.chart_min,
                        isPC&&isRightPageShow==''&&currentIndex!==3?APPstyles.flex_row:APPstyles.flex_column,
                        isPC&&isRightPageShow==''&&currentIndex!==3?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:'',
                        isPC&&!isDividendHistoryShow&&isRightPageShow!==''?styles.chart_dvnone:''
                    ].join(' ')}
                >
                    <div 
                        className={[
                            styles.chart_left,
                            isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.chart_left_min,
                            isPC&&isRightPageShow==''&&currentIndex!==3?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                            isPC&&isRightPageShow==''&&currentIndex!==3?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
                            isRightPageShow||currentIndex==3?APPstyles.flex_column:''
                        ].join(' ')}
                        style={{
                            paddingBottom:isMobile?8:0
                        }}
                    >
                        <span className={[styles.title,ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white].join(' ')}>{t('Financial Ratios')}</span>
                        <div 
                            className={[APPstyles.flex_row,styles.chart_lenged].join(' ')} 
                            style={{
                                display:Ratios0Data.length&&Ratios1Data.length&&Ratios2Data.length&&Ratios3Data.length?'':'none'
                            }}
                        >
                            <span className={styles.lenged_icon}>&nbsp;</span>
                            <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{data['symbol']?data['symbol']:'Stock'}</span>
                            <span className={styles.lenged_icon}>&nbsp;</span>
                            <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{t('Sector')}</span>
                        </div>
                        <div style={{
                                display:Ratios0Data.length&&Ratios1Data.length&&Ratios2Data.length&&Ratios3Data.length?'none':''
                            }}>&nbsp;</div>
                        {/* pc */}
                        <div 
                            className={[
                                APPstyles.flex_row,
                                styles.chart_gauge,
                                ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                            ].join(' ')} 
                            style={{
                                marginBottom:8
                            }} 
                        >
                            <GaugeCon
                                {...this.props}
                                gaugeId='Ratios0Id'
                                stockName='PBR'
                                subName='MRQ'
                                RatiosData={Ratios0Data}
                                gaugeLoading={gaugeLoading[0]}
                                tooltipData={tooltipData}
                                stockData={stockData['PBR']}
                            />
                            <GaugeCon
                                {...this.props}
                                gaugeId='Ratios1Id'
                                stockName='PER'
                                subName='TTM'
                                RatiosData={Ratios1Data}
                                gaugeLoading={gaugeLoading[1]}
                                tooltipData={tooltipData}
                                stockData={stockData['PER']}
                            />
                        </div>
                        <div 
                        className={[
                            APPstyles.flex_row,
                            styles.chart_gauge
                        ].join(' ')}
                        >
                            <GaugeCon
                                {...this.props}
                                gaugeId='Ratios2Id'
                                stockName='ROE2'
                                subName='TTM'
                                RatiosData={Ratios2Data}
                                gaugeLoading={gaugeLoading[2]}
                                tooltipData={tooltipData}
                                stockData={stockData['ROE']}
                            />
                            <GaugeCon
                                {...this.props}
                                gaugeId='Ratios3Id'
                                stockName='ROA'
                                subName='TTM'
                                RatiosData={Ratios3Data}
                                gaugeLoading={gaugeLoading[3]}
                                tooltipData={tooltipData}
                                stockData={stockData['ROA']}
                            />
                        </div>                      
                    </div>
                    <div 
                        className={[
                            styles.chart_right,
                            isPC&&isRightPageShow==''&&currentIndex!==3?styles.minH392:styles.minH250,
                            isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.chart_right_min,
                            isPC&&isRightPageShow==''&&currentIndex!==3?'':ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                        ].join(' ')}
                    >
                        <div className={[APPstyles.flex_between,styles.chart_title].join(' ')}>
                            <span className={[styles.title,ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white].join(' ')}>{t('Income Statement')}</span>
                            <span className={styles.sub_title}>{t('Unit')}: {curData[0]=='-'?'':t('Million')}{ChgLangData=='zh-CN'?'':' '}{t(curData[0])}</span>
                        </div>
                        <div 
                            className={[
                                APPstyles.flex_row,
                                APPstyles.flex_between,
                                isStatementTab?styles.dh_tabsData:''
                            ].join(' ')}
                            style={{
                                top:32,
                                left:isPC&&(isRightPageShow||currentIndex==3)||isTablet?0:16,
                                display:IncomeStatementData.length?'':'none',
                                width:'auto'
                            }}
                        >
                            <Tabs
                                {...this.props}
                                tag='incomeStatement'
                                tabData={statementIData}
                                index={statementIndex}
                                callbackTabIndex={(index)=>{
                                    if(statementIndex==index) return
                                    columnLoading[0]=true
                                    this.setState({
                                        statementIndex:index,
                                        columnLoading:columnLoading
                                    })
                                    this.IncomeStatementFun(statementActualData,statementEstimatedData,colorsData,index)
                                }}
                            />
                        </div>
                        {/* 加载中 */}
                        <div className={APPstyles.isLoading}><Spin spinning={columnLoading[0]} /></div>
                        {
                            IncomeStatementData.length?
                                <Column id='IncomeStatementId' {...this.props} seriesData={IncomeStatementData} xAxisData={xAxisData[0]} plotISValue={plotISValue} isStatementTab={isStatementTab} />
                            :

                                <div className={APPstyles.isLoading} style={{display:columnLoading[0]?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                        }
                    </div>
                </div>
                <div 
                    className={[
                        styles.chart,
                        styles.chart_bottom,
                        isPC&&isRightPageShow==''&&currentIndex!==3?APPstyles.flex_row:APPstyles.flex_column
                    ].join(' ')}
                >
                    {
                        isDividendHistoryShow?
                    
                            <div 
                                className={[
                                    styles.chart_left,
                                    isPC&&isRightPageShow==''&&currentIndex!==3?styles.minH392:styles.minH250,
                                    isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.chart_right_min,
                                    isPC&&isRightPageShow==''&&currentIndex!==3?ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark:'',
                                    (isPC&&isRightPageShow!=='')||isTablet||(isPC&&currentIndex==3)?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:''
                                ].join(' ')}
                                style={{
                                    height:282
                                }}
                            >
                                <div className={[APPstyles.flex_between,styles.chart_title].join(' ')}>
                                    <span className={[styles.title,ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white].join(' ')}>{t('Dividend History')}</span>
                                    <span className={styles.sub_title}>{t('Currency')}: {t(curData[1])}</span>
                                </div>
                                <div className={[APPstyles.flex_row,APPstyles.flex_between,styles.dh_tabsData].join(' ')}>
                                    <Tabs
                                        {...this.props}
                                        tag='dividendHistory'
                                        tabData={tabsData}
                                        index={tabIndex}
                                        callbackTabIndex={(index)=>{
                                            if(tabIndex==index) return
                                            columnLoading[1]=true
                                            this.setState({
                                                tabIndex:index,
                                                columnLoading:columnLoading
                                            })
                                            this.dealDividendHistoryFun(AnnualReportData,InterimReportData,colorsData,index)
                                        }}
                                    />
                                    <div 
                                        className={[
                                            APPstyles.flex_row,
                                            styles.chart_lenged,
                                            (isPC&&(isRightPageShow!==''||currentIndex==3))||isTablet?APPstyles.marginRight0:''
                                        ].join(' ')}
                                    >
                                        <span className={styles.lenged_icon}>&nbsp;</span>
                                        <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{t('Actual')}</span>
                                        {/* <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{t(DHLegendName)}</span> */}
                                        <span className={styles.lenged_icon}>&nbsp;</span>
                                        <span className={ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_grey1}>{t('Estimate')}</span>
                                    </div>
                                </div>
                                {/* 加载中 */}
                                <div className={APPstyles.isLoading}><Spin spinning={columnLoading[1]} /></div>
                                {
                                    DividendHistoryData.length?
                                        <Column id='DividendHistoryId' {...this.props} seriesData={DividendHistoryData} xAxisData={xAxisData[1]} plotDHValue={plotDHValue} DHColorsData={DHColorsData} />
                                    :

                                        <div className={APPstyles.isLoading} style={{display:columnLoading[1]?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                                }
                            </div>
                        :
                            ''
                    }
                    <div 
                        className={[
                            styles.chart_left,
                            styles.chart_Balance_Sheet,
                            isDividendHistoryShow?styles.chart_center:'',
                            isPC&&isRightPageShow==''&&currentIndex!==3?styles.minH392:styles.minH250,
                            isPC&&isRightPageShow==''&&currentIndex!==3?'':styles.chart_right_min,
                            isPC&&(isDividendHistoryShow||isRightPageShow!=='')||isTablet||currentIndex==3?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
                            isPC&&isDividendHistoryShow?'':styles.chart_Balance_Sheet_none,
                            (isPC&&!isDividendHistoryShow&&isRightPageShow!=='')||currentIndex==3?ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark:''
                        ].join(' ')}
                    >
                        <div className={[APPstyles.flex_between,styles.chart_title].join(' ')} style={{marginTop:(isPC&&(isRightPageShow||currentIndex==3))||isTablet?16:0}}>
                            <span className={[styles.title,ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white].join(' ')}>{t('Balance Sheet')}</span>
                            <span className={styles.sub_title}>{t('Unit')}: {curData[2]=='-'?'':t('Million')}{ChgLangData=='zh-CN'?'':' '}{t(curData[2])}</span>
                        </div>
                        {/* 加载中 */}
                        <div className={APPstyles.isLoading}><Spin spinning={columnLoading[2]} /></div>
                        {
                            BalanceSheetData.length?
                                <Column id='BalanceSheetId' {...this.props} seriesData={BalanceSheetData} xAxisData={xAxisData[2]} />
                            :

                                <div className={APPstyles.isLoading} style={{display:columnLoading[2]?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                        }
                    </div>
                    <div 
                        className={[
                            styles.chart_right,
                            (isPC&&isDividendHistoryShow)||currentIndex==3?APPstyles.wPer100:APPstyles.wPer50,
                            isPC&&isRightPageShow==''?styles.minH392:styles.minH250,
                            isPC&&isRightPageShow==''?'':styles.chart_right_min,
                            isPC&&isRightPageShow!==''?styles.chart_Cash_Flow_none:'',
                            (isPC&&isDividendHistoryShow)||isTablet?ChgThemeData=='Light'?APPstyles.borderTop_light:APPstyles.borderTop_dark:'',
                            isPC&&isDividendHistoryShow?'':styles.chart_right_dvnone
                        ].join(' ')}
                        style={{
                            paddingLeft:currentIndex==3||isDividendHistoryShow?0:16
                        }}
                    >
                        <div className={[APPstyles.flex_between,styles.chart_title].join(' ')} style={{marginTop:isPC&&isRightPageShow==''?currentIndex==3?16:0:16}}>
                            <span className={[styles.title,ChgThemeData=='Light'?APPstyles.color_black:APPstyles.color_white].join(' ')}>{t('Cash Flow')}</span>
                            <span className={styles.sub_title}>{t('Unit')}: {curData[3]=='-'?'':t('Million')}{ChgLangData=='zh-CN'?'':' '}{t(curData[3])}</span>
                        </div>
                        {/* 加载中 */}
                        <div className={APPstyles.isLoading}><Spin spinning={columnLoading[3]} /></div>
                        {
                            CashFlowData.length?
                                <Column id='CashFlowId' {...this.props} seriesData={CashFlowData} xAxisData={xAxisData[3]} />
                            :

                                <div className={APPstyles.isLoading} style={{display:columnLoading[3]?'none':'',zIndex:9}}>{t('Chart not available')}</div>  
                        }
                    </div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(Fundamentals))