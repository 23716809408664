import React, { Component } from "react";
import "./DatePicker.scss";

export default class DatePicker extends Component {
  today = new Date();
  year = new Date();
  ty = 0;

  constructor(props) {
    super(props);
    this.today.setHours(0, 0, 0, 0);
    this.year.setHours(0, 0, 0, 0);

    this.state = {
      selectedDate: new Date(),
      selectedYear: new Date().getFullYear(),
      selectMonth: new Date().getMonth() + 1,
      renderDate: new Date(),
      type: "m",
    };

    this.calYear(this.state.type);
  }

  calYear = (type) => {
    this.year = new Date();
    //for calculate the Year and Month control display
    if (type === "d") {
      this.year.setFullYear(this.year.getFullYear() - 2);
      this.ty = this.year.getFullYear();
    } else if (type === "w") {
      this.year.setFullYear(this.year.getFullYear() - 5);
      this.ty = this.year.getFullYear();
    } else {
      this.year.setFullYear(this.year.getFullYear() - 10);
      this.ty = this.year.getFullYear();
    }

    this.renderCalendar();
  };

  componentDidMount() {
    this.calYear(this.props.type);
    let dateStr = this.props.selectedDate.split("/");
    let monthStr =
      dateStr[1].length >= 2
        ? Number(dateStr[1]) - 1
        : Number(dateStr[1].substring(1, 2) - 1);
    var date = new Date(dateStr[0], monthStr, dateStr[2], 0, 0, 0, 0);
    this.setState({
      selectedDate: date,
      renderDate: date,
      selectedYear: dateStr[0],
      selectedMonth: monthStr + 1,
      type: this.props.type,
    });
  }

  componentWillReceiveProps(nextProps) {
    let dateStr = nextProps.selectedDate.split("/");
    let monthStr =
      dateStr[1].length >= 2
        ? Number(dateStr[1]) - 1
        : Number(dateStr[1].substring(1, 2) - 1);
    var date = new Date(dateStr[0], monthStr, dateStr[2], 0, 0, 0, 0);
    if (
      this.state.selectedDate.toDateString() === date.toDateString() &&
      this.state.type === nextProps.type
    )
      return;
    this.calYear(nextProps.type);
    if (date >= this.today) {
      date = this.today;
    }

    if (date <= this.year) {
      date = this.year;
    }

    this.setState({
      selectedDate: date,
      renderDate: date,
      selectedYear: dateStr[0],
      selectedMonth: monthStr + 1,
      type: nextProps.type,
    });
  }

  onCloseClick = () => {
    this.props.onCloseClick();
  };

  selectDate = (date) => {
    this.props.onSelectDateChange(date);
    this.props.onCloseClick();
  };

  renderCalendar = () => {
    let loop: Date = new Date(this.state.renderDate);
    loop.setMonth(loop.getMonth());

    let render_m = loop.getMonth();

    loop.setDate(1);
    while (loop.getDay() !== 0) {
      loop.setDate(loop.getDate() - 1);
    }

    let month = [];
    let today = new Date();
    let today_d = new Date().getDate();
    let today_m = new Date().getMonth() + 1;

    do {
      let week = [];
      for (let i = 0; i < 7; i++) {
        let day = "";
        let y = loop.getFullYear();
        let m = loop.getMonth();
        let d = loop.getDate();

        let c = "ui-datepicker-Day";
        if (render_m !== today_m) {
          if (loop.getMonth() === render_m) {
            day = loop.getDate().toString();
          }
        } else {
          if (loop.getMonth() === today_m) {
            if (loop.getDate() === today_d) {
              day = loop.getDate().toString();
              c += " Today";
            } else {
              day = loop.getDate().toString();
            }
          }
        }

        let dayRow = "";
        if (
          day !== "" &&
          loop.getFullYear() === this.state.selectedDate.getFullYear() &&
          loop.getMonth() === this.state.selectedDate.getMonth() &&
          loop.getDate() === this.state.selectedDate.getDate()
        ) {
          dayRow = (
            <div
              className={"ui-datepicker-Day-Text Select"}
              onClick={() => {
                this.selectDate(new Date(y, m, d));
              }}
            >
              {day}
            </div>
          );
        }
        // else if (day !== "" &&
        //     ((loop.getFullYear() === this.today.getFullYear() &&
        //         loop.getMonth() === this.today.getMonth() &&
        //         loop.getDate() === this.today.getDate())
        //     )) {
        //     dayRow = <div className={"ui-datepicker-Day-Text Today"}
        //         onClick={() => { this.selectDate(new Date(y, m, d)) }}
        //     >{day}</div>
        // }
        else if (
          day !== "" &&
          render_m === today.getMonth() &&
          loop.getFullYear() === today.getFullYear() &&
          loop.getMonth() === today.getMonth() &&
          loop.getDate() > today.getDate()
        ) {
          dayRow = (
            <div className={"ui-datepicker-Day-Text Disable"}>{day}</div>
          );
        } else if (day !== "" && loop < this.year) {
          dayRow = (
            <div className={"ui-datepicker-Day-Text Disable"}>{day}</div>
          );
        } else if (day === "") {
          dayRow = <div className={"ui-datepicker-Day-NoText"}>{day}</div>;
        } else {
          dayRow = (
            <div
              className={"ui-datepicker-Day-Text"}
              onClick={() => {
                this.selectDate(new Date(y, m, d));
              }}
            >
              {day}
            </div>
          );
        }

        if (
          day !== "" &&
          loop.getFullYear() === this.today.getFullYear() &&
          loop.getMonth() === this.today.getMonth() &&
          loop.getDate() === this.today.getDate()
        ) {
          dayRow = (
            <div
              className={"ui-datepicker-Day-Text Today"}
              onClick={() => {
                this.selectDate(new Date(y, m, d));
              }}
            >
              {day}
            </div>
          );
        }

        week.push(
          <div className={c} key={"CD" + i}>
            {dayRow}
          </div>
        );
        loop.setDate(loop.getDate() + 1);
      }

      month.push(
        <div key={"CW" + month.length} className="ui-datepicker-Week">
          {week}
        </div>
      );
    } while (loop.getMonth() === render_m);

    return month;
  };

  renderYearSelect = () => {
    let renderList = [];
    for (let i = 0; i <= this.today.getFullYear() - this.ty; i++) {
      renderList.push((this.today.getFullYear() - i).toString());
    }

    return (
      <select
        className="ui-datepicker-year"
        value={this.state.selectedYear}
        onChange={(e) => this.onselectYearChange(e)}
      >
        {renderList.map((i, idx) => (
          <option key={idx} value={i}>
            {i}
          </option>
        ))}
      </select>
    );
  };

  onselectYearChange = (e) => {
    if (this.today.getFullYear().toString() === e.target.value) {
      if (this.state.selectedMonth > this.today.getMonth()) {
        let date = new Date(
          e.target.value,
          this.today.getMonth(),
          this.today.getDate()
        );
        this.setState({
          renderDate: date,
          selectedYear: e.target.value,
          selectedMonth: this.today.getMonth() + 1,
        });
      } else {
        let date = new Date(
          e.target.value,
          this.state.selectedMonth - 1,
          this.today.getDate()
        );
        this.setState({ renderDate: date, selectedYear: e.target.value });
      }
    } else if (this.year.getFullYear().toString() === e.target.value) {
      if (this.state.selectedMonth < this.year.getMonth()) {
        let date = new Date(
          e.target.value,
          this.today.getMonth(),
          this.today.getDate()
        );
        this.setState({
          renderDate: date,
          selectedYear: e.target.value,
          selectedMonth: this.today.getMonth() + 1,
        });
      } else {
        let date = new Date(
          e.target.value,
          this.state.selectedMonth - 1,
          this.today.getDate()
        );
        this.setState({ renderDate: date, selectedYear: e.target.value });
      }
    } else {
      let date = new Date(
        e.target.value,
        this.state.selectedMonth - 1,
        this.today.getDate()
      );
      this.setState({ renderDate: date, selectedYear: e.target.value });
    }
  };

  renderMonthSelect = () => {
    let renderList = [];

    if (this.today.getFullYear() === this.state.renderDate.getFullYear()) {
      let m = this.today.getMonth() + 1;
      for (let i = 1; i <= m; i++) {
        renderList.push(i.toString());
      }
    } else if (
      this.year.getFullYear() === this.state.renderDate.getFullYear()
    ) {
      let m = this.today.getMonth() + 1;
      for (let i = m; i <= 12; i++) {
        renderList.push(i.toString());
      }
    } else {
      for (let i = 1; i <= 12; i++) {
        renderList.push(i.toString());
      }
    }

    return (
      <select
        className="ui-datepicker-month"
        value={this.state.selectedMonth}
        onChange={(e) => this.onselectMonthChange(e)}
      >
        {renderList.map((i, idx) => (
          <option key={idx} value={i}>
            {i + "月"}
          </option>
        ))}
      </select>
    );
  };

  onselectMonthChange = (e) => {
    let date = new Date(this.state.selectedYear, e.target.value - 1, 1);
    this.setState({ renderDate: date, selectedMonth: e.target.value }, () => {
      //console.log(this.state.renderDate)
    });
  };

  renderPrev = () => {
    let m: Date = new Date(
      this.state.selectedYear,
      this.state.selectedMonth,
      1
    );
    m.setMonth(m.getMonth() - 1);

    if (m > this.year) {
      return (
        <div className="ui-datepicker-prev ui-corner-all" onClick={this.onPrev}>
          <span className="ui-icon ui-icon-circle-triangle-w">&lt;前</span>
        </div>
      );
    } else {
      return (
        <div className="ui-datepicker-prev ui-corner-all">
          <span className="ui-icon ui-icon-circle-triangle-w-disable">
            &lt;前
          </span>
        </div>
      );
    }
  };

  onPrev = () => {
    let date = new Date(
      this.state.selectedYear,
      this.state.selectedMonth - 1,
      1
    );
    let newDate = new Date(date.setMonth(date.getMonth() - 1));
    this.setState({
      selectedYear: date.getFullYear(),
      selectedMonth: newDate.getMonth() + 1,
      renderDate: date,
    });
  };

  renderNext = () => {
    let date = new Date(this.state.selectedYear, this.state.selectedMonth, 1);
    if (this.today > date) {
      return (
        <div className="ui-datepicker-next ui-corner-all" onClick={this.onNext}>
          <span className="ui-icon ui-icon-circle-triangle-e">次&gt;</span>
        </div>
      );
    } else
      return (
        <div className="ui-datepicker-next ui-corner-all">
          <span className="ui-icon ui-icon-circle-triangle-e-disable">
            次&gt;
          </span>
        </div>
      );
  };

  onNext = () => {
    let date = new Date(this.state.selectedYear, this.state.selectedMonth, 1);
    this.setState({
      selectedYear: date.getFullYear(),
      selectedMonth: date.getMonth() + 1,
      renderDate: date,
    });
  };

  onTodayClick = () => {
    this.setState({
      selectedYear: this.today.getFullYear(),
      selectedMonth: this.today.getMonth() + 1,
      renderDate: this.today,
    });
  };

  render() {
    return (
      <div>
        <div
          className="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
          style={{
            position: "absolute",
            top: "235px",
            left: "450px",
            zIndex: 6,
            display: "block",
          }}
        >
          <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
            {this.renderPrev()}
            {this.renderNext()}
            <div className="ui-datepicker-title">
              {this.renderYearSelect()}年{this.renderMonthSelect()}
            </div>
          </div>
          <div className="ui-datepicker-Week-Header">
            <div className="ui-datepicker-Day-Header">{"日"}</div>
            <div className="ui-datepicker-Day-Header">{"月"}</div>
            <div className="ui-datepicker-Day-Header">{"火"}</div>
            <div className="ui-datepicker-Day-Header">{"水"}</div>
            <div className="ui-datepicker-Day-Header">{"木"}</div>
            <div className="ui-datepicker-Day-Header">{"金"}</div>
            <div className="ui-datepicker-Day-Header">{"土"}</div>
          </div>
          {this.renderCalendar()}

          <div className="ui-datepicker-buttonpane ui-widget-content">
            <button
              type="button"
              className="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all"
              onClick={this.onTodayClick}
            >
              今日
            </button>
            <button
              type="button"
              className="ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all"
              onClick={this.onCloseClick}
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }
}
