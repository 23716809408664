import React from 'react'
import { withTranslation } from 'react-i18next'
import APPstyles from '../../../../App.less'
import styles from '../../../../pages/Account/index.less'

class TipsCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const { isPC,ChgThemeData,ChgLangData } = this.props
    return (
      <div
        className={[
          styles.account_tips,
          // isPC?'':styles.account_tips_min,
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_content_dark
        ].join(' ')}
      >
        {
          ChgLangData == 'zh-CN'?
            <div>
              <span style={{marginBottom:4,display:'block'}}>1.因配合結帳及轉檔作業，資料於中午12:00後更新。</span>
              <span>2.當日更新前資料為試算值僅供參考，若有問題請洽營業員或客服中心: 0800-073-588</span>
            </div>
          :
            <div>
              Disclaimer
              <br />
              Historical commission returns and historical transaction returns.
          </div>
        }
      </div>
    )
  }
}
export default withTranslation()(TipsCon)
