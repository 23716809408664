import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import APPstyles from '@/App.less'
import styles from './index.less'

class DetailChild extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render(){
    const {t,ChgThemeData,ChgLangData,orderData,isMobile} = this.props
    return (
      <div
        className={[
          APPstyles.flex1_auto,
          styles.body,
          isMobile?styles.body_mobile:''
        ].join(' ')}
      >
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('direction')}</span>
          <span 
            className={[
              APPstyles.fw700,
              orderData['direction']=='B'?APPstyles.color_red:APPstyles.color_green
            ].join(' ')}
          >
            {t(orderData['direction']=='B'?'Buy':'Sell')}
          </span>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            styles.tradeflow_con_stauts,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span>
            <label className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Status')}</label> 
            <label 
              className={[ChgThemeData=='Light'?styles.label_light:'',styles.label_span].join(' ')}
              onClick={()=>{
                this.props.cbChgStatusShowFun()
              }}
            >
              {t('INFO')}
            </label>
          </span>
          <div className={[APPstyles.flex_column,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white].join(' ')} style={{textAlign:'right',width:'70%'}}>
            <span>{t(orderData['status_content'])}</span>
            <div style={{padding:'2px 0',width:'100%',whiteSpace:'break-spaces',display:orderData['error_code']?'':'none'}}>
              <span>{t(ChgLangData=='zh-CN'?'code':'Code')}</span>: {orderData['error_code']}
            </div>
            <div style={{width:'100%',whiteSpace:'break-spaces',display:orderData['error_msg']?'':'none'}}>
              <span>{t('Message')}</span>: {t(orderData['new_error_msg'])}
            </div> 
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.name,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t(ChgLangData=='zh-CN'?'name':'Name')}</span>
          <div>
            <span className={[APPstyles.fw700,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,APPstyles.marginBottom2].join(' ')}>
              {common.valToUpperCase(orderData['stock_code'])}
            </span>
            <span className={ChgThemeData=='Light'?APPstyles.color_009E9C:APPstyles.color_cur}>{common.valToUpperCase(orderData['stock_name'])}</span>
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
          style={{
            display:Number(orderData['order_type'])==0?'none':''
          }}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t(Number(orderData['order_type'])==50?orderData['direction']=='B'?'Limit Price':'Stop Price':'')}</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{orderData['stop_loss_price']==0?'-':common.transThousands(common.filterErro(orderData['stop_loss_price']))}</span>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Price1')}</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{common.transThousands(common.filterErro(orderData['order_price']))}</span>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.time,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Qty/Filled')}</span>
          <div>
            <span className={[APPstyles.marginBottom2,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,isMobile?APPstyles.fz14:''].join(' ')}>{common.transThousands(common.keepNum(orderData['qty'],0))}</span>
            <span className={[APPstyles.color_grey,isMobile?APPstyles.fz14:''].join(' ')}>{common.transThousands(common.keepNum(orderData['qty_filled'],0))}</span>
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Order Type')}</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>
            {t(
              orderData['order_type']==0&&(orderData['gtc_date']=='-'||orderData['gtc_date']==''||!orderData['gtc_date'])?
                'Limit Order'
              :orderData['order_type']==50&&(orderData['gtc_date']=='-'||orderData['gtc_date']==''||!orderData['gtc_date'])?
                'Stop Limit'
              :orderData['order_type']==0&&(orderData['gtc_date']!=='-'||orderData['gtc_date']!==''||orderData['gtc_date'])?
                'GTC Limit'
              :orderData['order_type']==50&&(orderData['gtc_date']!=='-'||orderData['gtc_date']!==''||orderData['gtc_date'])?
                'GTC Stop Limit'
              :
                ''
            )}
          </span>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.time,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Order Date/Time')}</span>
          <div>
            <span className={[APPstyles.marginBottom2,ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white,isMobile?APPstyles.fz14:''].join(' ')}>{common.changeOrderDate(orderData['time'])}</span>
            <span className={[APPstyles.color_grey,isMobile?APPstyles.fz14:''].join(' ')}>{common.changeOrderTime(orderData['time'])}</span>
          </div>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Good Until')}</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{t(orderData['gtc_date'])}</span>
        </div>
        <div 
          className={[
            APPstyles.flex_between,
            styles.tradeflow_con,
            ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
          ].join(' ')}
        >
          <span className={isMobile?ChgThemeData=='Dark'?styles.body_name_mobileD:styles.body_name_mobileW:''}>{t('Order ID')}</span>
          <span className={ChgThemeData=='Light'?APPstyles.color_theme_black:APPstyles.color_white}>{orderData['order_no']?orderData['order_no']:orderData['oid']}</span>
        </div>
      </div>
  )}
}
export default withTranslation()(DetailChild)