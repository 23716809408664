import React, { useState, useRef, useEffect } from "react";
import "./Menu.scss";
import { ReactComponent as IconActionOutlined } from "../../../../asserts/imgs/chart/fubon/Action_outlined.svg";
import { ReactComponent as IconAnalysisOutlined } from "../../../../asserts/imgs/chart/fubon/Analysis_outlined.svg";
import { ReactComponent as IconCandlestick } from "../../../../asserts/imgs/chart/fubon/candlestick.svg";
import { ReactComponent as IconPeriodOutlined } from "../../../../asserts/imgs/chart/fubon/Period_outlined.svg";
import { ReactComponent as IconFullScreen } from "../../../../asserts/imgs/chart/fubon/fullscreen_24px_rounded.svg";

import { ReactComponent as IconLineLight } from "../../../../asserts/imgs/chart/fubon/line-chart_light.svg";
import { ReactComponent as IconCandlestickLight } from "../../../../asserts/imgs/chart/fubon/candlestick_light.svg";
import { ReactComponent as IconCandlestickBlue } from "../../../../asserts/imgs/chart/fubon/candlestick_blue.svg";
import { ReactComponent as IconBarLight } from "../../../../asserts/imgs/chart/fubon/bar-chart_light.svg";
import { ReactComponent as IconHistogramLight } from "../../../../asserts/imgs/chart/fubon/histogram_light.svg";

import { ReactComponent as IconHandLight } from "../../../../asserts/imgs/chart/fubon/hand_light.svg";
import { ReactComponent as IconLine2Light } from "../../../../asserts/imgs/chart/fubon/line_light.svg";
import { ReactComponent as IconRetracementLight } from "../../../../asserts/imgs/chart/fubon/retracement_light.svg";
import { ReactComponent as IconFanLight } from "../../../../asserts/imgs/chart/fubon/fan_light.svg";
import { ReactComponent as IconArcLight } from "../../../../asserts/imgs/chart/fubon/arc_light.svg";
import { ReactComponent as IconAnnotationLight } from "../../../../asserts/imgs/chart/fubon/annotation_light.svg";

import { ReactComponent as IconCheckboxOff } from "../../../../asserts/imgs/chart/fubon/checkbox-off.svg";
import { ReactComponent as IconCheckboxOn } from "../../../../asserts/imgs/chart/fubon/checkbox-on.svg";

import { ReactComponent as IconSetting } from "../../../../asserts/imgs/chart/fubon/cog-outline.svg";

// import line from "../../../../asserts/imgs/chart/icon_charttype_line.png";
// import candlestick from "../../../../asserts/imgs/chart/icon_charttype_candlestick.png";
// import bar from "../../../../asserts/imgs/chart/icon_charttype_bar.png";
// import move from "../../../../asserts/imgs/chart/icon_drawtool_move.png";
// import drawline from "../../../../asserts/imgs/chart/icon_drawtool_line.png";
// import retracement from "../../../../asserts/imgs/chart/icon_drawtool_retracement.png";
// import fan from "../../../../asserts/imgs/chart/icon_drawtool_fan.png";
// import arcs from "../../../../asserts/imgs/chart/icon_drawtool_arcs.png";
// import viewchart from "../../../../asserts/imgs/chart/icon_viewmainchart.png";
// import viewta from "../../../../asserts/imgs/chart/icon_viewta.png";
// import viewtrendline from "../../../../asserts/imgs/chart/icon_viewtrendline.png";
// import viewpricetracker from "../../../../asserts/imgs/chart/icon_viewpricetracker.png";
// import viewscrollpane from "../../../../asserts/imgs/chart/icon_viewscrollpane.png";
// import viewcrosshair from "../../../../asserts/imgs/chart/icon_viewcrosshair.png";
// import viewchtip from "../../../../asserts/imgs/chart/icon_viewchtip.png";
// import clearall from "../../../../asserts/imgs/chart/icon_clearall.png";
// import reset from "../../../../asserts/imgs/chart/icon_reset.png";
// import print from "../../../../asserts/imgs/chart/icon_print.png";
// import help from "../../../../asserts/imgs/chart/icon_help.png";
// import stylesetting from "../../../../asserts/imgs/chart/icon_stylesetting.png";
import { MEMA } from "../../TA/MEMA";
import { TVMA } from "../../TA/TVMA";
import { ICH } from "../../TA/ICH";
import { MACD } from "../../TA/MACD";
import { LRT } from "../../TA/LRT";
import { SAR } from "../../TA/SAR";
import { ADXR } from "../../TA/ADXR";
import { DMI } from "../../TA/DMI";
import { Bias } from "../../TA/Bias";
import { ALF } from "../../TA/ALF";
import { BB } from "../../TA/BB";
import { VC } from "../../TA/VC";
import { ATR } from "../../TA/ATR";
import { SD } from "../../TA/SD";
import { VltyCC } from "../../TA/VltyCC";
import { PL } from "../../TA/PL";
import { CO } from "../../TA/CO";
import { RCI } from "../../TA/RCI";
import { CCI } from "../../TA/CCI";
import { MI } from "../../TA/MI";
import { WAD } from "../../TA/WAD";
import { KRI } from "../../TA/KRI";
import { FKD } from "../../TA/FKD";
import { SKD } from "../../TA/SKD";
import { UPDW } from "../../TA/UPDW";
import { UO } from "../../TA/UO";
import { Vector } from "../../TA/Vector";
import { DPO } from "../../TA/DPO";
import { Trix } from "../../TA/Trix";
import { VE } from "../../TA/VE";
import { VAP } from "../../TA/VAP";
import { Volume } from "../../TA/Volume";
import { VOSC } from "../../TA/VOSC";
import { ACD } from "../../TA/ACD";
import { VROC } from "../../TA/VROC";
import { OBV } from "../../TA/OBV";
import { FI } from "../../TA/FI";
import { MFI } from "../../TA/MFI";
import { NVI } from "../../TA/NVI";
import { EOM } from "../../TA/EOM";
import { VR } from "../../TA/VR";
import { ENV } from "../../TA/ENV";
import { MOM } from "../../TA/MOM";
import { RMI } from "../../TA/RMI";
import { WR } from "../../TA/WR";
import { RSI } from "../../TA/RSI";
import { POSC } from "../../TA/POSC";
import { PROC } from "../../TA/PROC";
import { PRR } from "../../TA/PRR";
import { VWAP } from "../../TA/VWAP";
import { WC } from "../../TA/WC";
import { PCV } from "../../TA/PCV";
import { Margin } from "../../TA/Margin";
import { TP } from "../../TA/TP";
import { RC } from "../../TA/RC";
import { Turnover } from "../../TA/Turnover";
import { CC } from "../../TA/CC";
import { UI } from "../../TA/UI";
import { KC } from "../../TA/KC";
import { WMA } from "../../TA/WMA";
import { SMA } from "../../TA/SMA";
import { EMA } from "../../TA/EMA";
import CompareList from "../../../Configs/compare.json";
// import { CompareDropdown } from "../../Compare/CompareDropdown";
// import { EQTY } from "../../Compare/EQTY";
import { CompareBox } from "../../Compare/CompareBox";
import TAHeader from "../../../Configs/taHeader.json";
// import { parse, isValid, format, parseISO } from "date-fns";
// import InputBoxRICSearch from "../../Inputs/InputBoxRICSearch";
// import Tooltip from "react-tooltip-lite";
import isEqual from "lodash/isEqual";
import { DEFAULT_SETTING, DEFAULT_SETTING_LIGHT } from "../TechnicalChart";
// import { cloneDeep } from "lodash";
// import config from "../../../../data/config";

export const Menu = (props) => {
  const today = new Date();
  const todayString =
    today.getFullYear() +
    "/" +
    (today.getMonth() + 1 < 10
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "/" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  const prev6Month = new Date(today.setMonth(today.getMonth() - 4));
  const prev6MonthString =
    prev6Month.getFullYear().toString() +
    "/" +
    (prev6Month.getMonth() - 1 < 10
      ? "0" + (prev6Month.getMonth() + 1)
      : prev6Month.getMonth() - 1) +
    "/" +
    (prev6Month.getDate() < 10
      ? "0" + prev6Month.getDate()
      : prev6Month.getDate());
  const prev1year = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const prev1yearString =
    prev1year.getFullYear().toString() +
    "/" +
    (prev1year.getMonth() + 1 < 10
      ? "0" + (prev1year.getMonth() + 1)
      : prev1year.getMonth() + 1) +
    "/" +
    (prev1year.getDate() < 10
      ? "0" + prev1year.getDate()
      : prev1year.getDate());
  const prev10year = new Date(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const prev10yearString =
    prev10year.getFullYear().toString() +
    "/" +
    (prev1year.getMonth() + 1 < 10
      ? "0" + (prev10year.getMonth() + 1)
      : prev10year.getMonth() + 1) +
    "/" +
    (prev10year.getDate() < 10
      ? "0" + prev10year.getDate()
      : prev10year.getDate());

  const {
    backgroundColor: BACKGROUND_COLOR,
    chartBackgroundColor: CHARTBACKGROUNDCOLOR,
    chartLineColor: CHARTLINECOLOR,
    toolTipsColor: TOOLTIPSCOLOR,
    upColor: UPCOLOR,
    upFillColor: UPFILLCOLOR,
    downColor: DOWNCOLOR,
    downFillColor: DOWNFILLCOLOR,
  } = props.ChgThemeData === "Light" ? DEFAULT_SETTING_LIGHT : DEFAULT_SETTING;

  // HTMLDivElement
  const periodRef = useRef(null);
  const chartTypeRef = useRef(null);
  const ctLineRef = useRef(null);
  const ctCandlestickRef = useRef(null);
  const ctBarRef = useRef(null);
  const ctHistogramRef = useRef(null);
  const drawtoolRef = useRef(null);
  const taRef = useRef(null);
  const zoomRef = useRef(null);
  const compareRef = useRef(null);
  const viewRef = useRef(null);
  const settingRef = useRef(null);

  const refIsSelectAnotherChartType = useRef(false); // check if user hasn't select charttype, only 1min show line chart and candlestick remaining
  const refIsSelectAnotherTA = useRef(false); // check if user hasn't select TA, only 1min not show SMA
  const refIsLineChartColorChange = useRef(false); // check if user hasn't chnage line color

  const [isOpen, setIsOpen] = useState(false);
  let [type, setType] = useState("menu");

  //Period
  const [period, setPeriod] = useState("1min");
  const [isDayOpen, setIsDayOpen] = useState(false);
  const [isWeekOpen, setIsWeekOpen] = useState(false);
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [day_month, setDay_month] = useState(false);
  const [day_1month, setDay_1month] = useState(false);
  const [day_2month, setDay_2month] = useState(false);
  const [day_3month, setDay_3month] = useState(false);
  const [day_6month, setDay_6month] = useState(true);
  const [day_year, setDay_year] = useState(false);
  const [day_1year, setDay_1year] = useState(false);
  const [day_2year, setDay_2year] = useState(false);
  const [day_fr_value, setDay_fr_value] = useState(prev6MonthString);
  const [day_to_value, setDay_to_value] = useState(todayString);
  const [day_free, setDay_free] = useState(false);

  const [week_1month, setWeek_1month] = useState(false);
  const [week_2month, setWeek_2month] = useState(false);
  const [week_3month, setWeek_3month] = useState(false);
  const [week_6month, setWeek_6month] = useState(false);
  const [week_year, setWeek_year] = useState(false);
  const [week_1year, setWeek_1year] = useState(true);
  const [week_2year, setWeek_2year] = useState(false);
  const [week_3year, setWeek_3year] = useState(false);
  const [week_4year, setWeek_4year] = useState(false);
  const [week_5year, setWeek_5year] = useState(false);
  const [week_fr_value, setWeek_fr_value] = useState(prev1yearString);
  const [week_to_value, setWeek_to_value] = useState(todayString);
  const [week_free, setWeek_free] = useState(false);

  const [month_6month, setMonth_6month] = useState(false);
  const [month_year, setMonth_year] = useState(false);
  const [month_1year, setMonth_1year] = useState(true);
  const [month_2year, setMonth_2year] = useState(false);
  const [month_3year, setMonth_3year] = useState(false);
  const [month_4year, setMonth_4year] = useState(false);
  const [month_5year, setMonth_5year] = useState(false);
  const [month_10year, setMonth_10year] = useState(false);
  const [month_fr_value, setMonth_fr_value] = useState(prev10yearString);
  const [month_to_value, setMonth_to_value] = useState(todayString);
  const [month_free, setMonth_free] = useState(false);

  const [periodOption, setPeriodOption] = useState({
    period: "1min",
    option: "",
  });

  //Draw Tool
  const [drawTool, setDrawTool] = useState("move");
  const [isDrawLineOpen, setIsDrawLineOpen] = useState(false);
  const [drawLineColor, setDrawLineColor] = useState("0080FF");
  const [drawLineThickness, setDrawLineThickness] = useState(2);
  const [drawLineStyle, setDrawLineStyle] = useState("s");

  const [isDrawRetracementOpen, setIsDrawRetracementOpen] = useState(false);
  const [drawRetracementColor, setDrawRetracementColor] = useState("0080FF");
  const [drawRetracementThickness, setDrawRetracementThickness] = useState(2);
  const [drawRetracementStyle, setDrawRetracementStyle] = useState("s");

  const [isDrawFanOpen, setIsDrawFanOpen] = useState(false);
  const [drawFanColor, setDrawFanColor] = useState("0080FF");
  const [drawFanThickness, setDrawFanThickness] = useState(2);
  const [drawFanStyle, setDrawFanStyle] = useState("s");

  const [isDrawArcsOpen, setIsDrawArcsOpen] = useState(false);
  const [drawArcsColor, setDrawArcsColor] = useState("0080FF");
  const [drawArcsThickness, setDrawArcsThickness] = useState(2);
  const [drawArcsStyle, setDrawArcsStyle] = useState("s");

  //Chart Type
  const [chartType, setChartType] = useState("line");
  const [showTracker, setShowTracker] = useState(false);
  const [lineGradientEnable, setLineGradientEnable] = useState(false);
  const [lineThickness, setLineThickness] = useState(2);
  const [lineStyle, setLineStyle] = useState("s");
  const [isLineOpen, setIsLineOpen] = useState(false);
  const [isCandlestickOpen, setIsCandlestickOpen] = useState(false);
  const [isBarOpen, setIsBarOpen] = useState(false);
  const [isHistogramOpen, setIsHistogramOpen] = useState(false);
  const [lineColor, setLineColor] = useState(
    props.ChgThemeData === "Light" ? "3366CC" : "F8F800"
  );
  const [lineFillColor, setLineFillColor] = useState("99CCFF");
  const [lineFillColor2, setLineFillColor2] = useState("FFFFFF");

  const [candlestickUpColor, setCandlestickUpColor] = useState(UPCOLOR);
  const [candlestickUpFillColor, setCandlestickUpFillColor] = useState(UPFILLCOLOR);
  const [candlestickDownColor, setCandlestickDownColor] = useState(DOWNCOLOR);
  const [candlestickDownFillColor, setCandlestickDownFillColor] = useState(DOWNFILLCOLOR);

  const [histogramUpColor, setHistogramUpColor] = useState(UPCOLOR);
  const [histogramUpFillColor, setHistogramUpFillColor] = useState(UPFILLCOLOR);
  const [histogramDownColor, setHistogramDownColor] = useState(DOWNCOLOR);
  const [histogramDownFillColor, setHistogramDownFillColor] = useState(DOWNFILLCOLOR);

  const [barColor, setBarColor] = useState("0033CC");

  //TA
  const [currentTA, setCurrentTA] = useState("");
  const [tapane, setTapane] = useState(new Array());
  const [overlay, setOverlay] = useState(new Array());
  const [taPowerSearchValue, setTAPowerSearchValue] = useState("");
  const [isSMA, setIsSMA] = useState(false);
  const [isWMA, setIsWMA] = useState(false);
  const [isEMA, setIsEMA] = useState(false);
  const [isMEMA, setIsMEMA] = useState(false);
  const [isTVMA, setIsTVMA] = useState(false);
  const [isICH, setIsICH] = useState(false);
  const [isMACD, setIsMACD] = useState(false);
  const [isLRT, setIsLRT] = useState(false);
  const [isSAR, setIsSAR] = useState(false);
  const [isADXR, setIsADXR] = useState(false);
  const [isDMI, setIsDMI] = useState(false);
  const [isBias, setIsBias] = useState(false);
  const [isALF, setIsALF] = useState(false);
  const [isBB, setIsBB] = useState(false);
  const [isVC, setIsVC] = useState(false);
  const [isATR, setIsATR] = useState(false);
  const [isSD, setIsSD] = useState(false);
  const [isVltyCC, setIsVltyCC] = useState(false);
  const [isKRI, setIsKRI] = useState(false);
  const [isPL, setIsPL] = useState(false);
  const [isCO, setIsCO] = useState(false);
  const [isRCI, setIsRCI] = useState(false);
  const [isFKD, setIsFKD] = useState(false);
  const [isSKD, setIsSKD] = useState(false);
  const [isCCI, setIsCCI] = useState(false);
  const [isMI, setIsMI] = useState(false);
  const [isUPDW, setIsUPDW] = useState(false);
  const [isUO, setIsUO] = useState(false);
  const [isVector, setIsVector] = useState(false);
  const [isDPO, setIsDPO] = useState(false);
  const [isWAD, setIsWAD] = useState(false);
  const [isTrix, setIsTrix] = useState(false);
  const [isVolume, setIsVolume] = useState(false);
  const [isVE, setIsVE] = useState(false);
  const [isVAP, setIsVAP] = useState(false);
  const [isVOSC, setIsVOSC] = useState(false);
  const [isVROC, setIsVROC] = useState(false);
  const [isACD, setIsACD] = useState(false);
  const [isOBV, setIsOBV] = useState(false);
  const [isEOM, setIsEOM] = useState(false);
  const [isFI, setIsFI] = useState(false);
  const [isMFI, setIsMFI] = useState(false);
  const [isNVI, setIsNVI] = useState(false);
  const [isVR, setIsVR] = useState(false);
  const [isENV, setIsENV] = useState(false);
  const [isMOM, setIsMOM] = useState(false);
  const [isRMI, setIsRMI] = useState(false);
  const [isRSI, setIsRSI] = useState(false);
  const [isWR, setIsWR] = useState(false);
  const [isPOSC, setIsPOSC] = useState(false);
  const [isPROC, setIsPROC] = useState(false);
  const [isPRR, setIsPRR] = useState(false);
  const [isVWAP, setIsVWAP] = useState(false);
  const [isWC, setIsWC] = useState(false);
  const [isPCV, setIsPCV] = useState(false);
  const [isMargin, setIsMargin] = useState(false);
  const [isTP, setIsTP] = useState(false);
  const [isRC, setIsRC] = useState(false);
  const [isTurnover, setIsTurnover] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [isKC, setIsKC] = useState(false);
  const [isUI, setIsUI] = useState(false);
  const [sortTAList, setSortTAList] = useState([]);

  const [initalSetting, setInitalSetting] = useState(false);

  useEffect(() => {
    const setting = props.localChartSetting;
    try {

      setLineColor(setting.lineOption.lineColor)
      setLineFillColor(setting.lineOption.lineFillColor)
      setLineFillColor2(setting.lineOption.lineFillColor2)
      setLineGradientEnable(setting.lineOption.lineGradientEnable)
      setLineStyle(setting.lineOption.lineStyle)
      setLineThickness(setting.lineOption.lineThickness)
      setShowTracker(setting.lineOption.showTracker)

      setCandlestickUpColor(setting.candlestickOption.upColor);
      setCandlestickUpFillColor(setting.candlestickOption.upFillColor);
      setCandlestickDownColor(setting.candlestickOption.downColor);
      setCandlestickDownFillColor(setting.candlestickOption.downFillColor);

      setHistogramUpColor(setting.histogramOption.upColor);
      setHistogramUpFillColor(setting.histogramOption.upFillColor);
      setHistogramDownColor(setting.histogramOption.downColor);
      setHistogramDownFillColor(setting.histogramOption.downFillColor);

      setBarColor(setting.barOption.barColor)

      setDrawLineColor(setting.drawToolOption.drawLineColor)
      setDrawLineThickness(setting.drawToolOption.drawLineThickness)
      setDrawLineStyle(setting.drawToolOption.drawLineStyle)

      setDrawRetracementColor(setting.drawToolOption.drawRetracementColor)
      setDrawRetracementThickness(setting.drawToolOption.drawRetracementThickness)
      setDrawRetracementStyle(setting.drawToolOption.drawRetracementStyle)

      setDrawFanColor(setting.drawToolOption.drawFanColor)
      setDrawFanThickness(setting.drawToolOption.drawFanThickness)
      setDrawFanStyle(setting.drawToolOption.drawFanStyle)

      setDrawArcsColor(setting.drawToolOption.drawArcsColor)
      setDrawArcsThickness(setting.drawToolOption.drawArcsThickness)
      setDrawArcsStyle(setting.drawToolOption.drawArcsStyle)

      periodOption.period === "1min" ? setChartType(setting["chartType-1min"]) : setChartType(setting.chartType);
      setInitalSetting(true)
    } catch { }
  }, [props.localChartSetting])

  useEffect(() => {
    if (initalSetting) {
      const load = async () => {
        props.setLoading(true);
        const setting = props.localChartSetting;

        const reset = async () => {
          setTapane(new Array())
          setOverlay(new Array())
        }
        await reset();
        await loadTAList(setting);
        props.setLoading(false);
        props.setIsReset(false)
      }
      load();
    }
    setInitalSetting(false)
  }, [initalSetting])

  // const loadTASetting = async () => {
  // const load = async () => {
  // props.setLoading(true);
  //     const setting = props.localChartSetting;
  //     await loadTAList(setting);
  //     props.setLoading(false);
  //   }
  //   await load();
  // }


  //Compare
  const [compareType, setCompareType] = useState("EQTY");
  const comparionDefaultColor = [
    "AA4433",
    "88AA44",
    "665588",
    "4499AA",
    "DD8833",
    "556655",
    "CC8888",
    "4488BB",
  ];
  const [compare, setCompare] = useState({
    name: "",
    code: "",
    value: "",
  });
  const [compareAddCount, setCompareAddCount] = useState(0);
  const [compares, setCompares] = useState(new Array());
  const [compareColor, setCompareColor] = useState("AA4433");
  const [compareThickness, setCompareThickness] = useState(2);
  const [compareStyle, setCompareStyle] = useState("s");
  const [isEQTY, setIsEQTY] = useState(true);
  const [isEQTYIDX, setIsEQTYIDX] = useState(false);
  const [isFUTOPT, setIsFUTOPT] = useState(false);
  const [isFX, setIsFX] = useState(false);
  const [isGBND, setIsGBND] = useState(false);
  const [isCOMM, setIsCOMM] = useState(false);
  const [isSECTOR, setIsSECTOR] = useState(false);
  const [isCompareEmpty, setIsCompareEmpty] = useState(true);

  //View
  const [showMainTracker, setShowMainTracker] = useState(true);
  const [showTa, setShowTa] = useState(true);
  const [showCompare, setShowCompare] = useState(false);
  const [showDrawTool, setShowDrawTool] = useState(true);
  const [showPriceTracker, setShowPriceTracker] = useState(true);
  const [showScrollView, setShowScrollView] = useState(false);
  const [showCrosshair, setShowCrosshair] = useState(true);
  const [showTip, setShowTip] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [isEventOpen, setIsEventOpen] = useState(false);
  const [isS, setIsS] = useState(true);
  const [isC, setIsC] = useState(true);
  const [isD, setIsD] = useState(true);

  //Setting
  const [isStyleSettingOpen, setIsStyleSettingOpen] = useState(false);
  const [fieldTrackColor, setFieldTrackColor] = useState("008000");
  const [fieldFontColor, setFieldFontColor] = useState("FFFFFF");
  const [fieldTrackThinkness, setFieldTrackThinkness] = useState(2);
  const [fieldTrackStyle, setFieldTrackStyle] = useState("d");
  const [yAsis, setYAsis] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(BACKGROUND_COLOR);
  const [chartBackgroundColor, setChartBackgroundColor] =
    useState(CHARTBACKGROUNDCOLOR);
  const [chartLineColor, setChartLineColor] = useState(CHARTLINECOLOR);
  const [chartLineStyle, setChartLineStyle] = useState("s");
  const [toolTipsColor, setToolTipsColor] = useState(TOOLTIPSCOLOR);
  const [crosshairLineColor, setCrosshairLineColor] = useState("444444");
  const [crosshairLineStyle, setCrosshairLineStyle] = useState("s");
  const [crosshairPointColor, setCrosshairPointColor] = useState("333333");
  const [crosshairPointSize, setCrosshairPointSize] = useState(3);
  const [crosshairFontColor, setCrosshairFontColor] = useState("FFFFFF");

  const [selectedColor, setSelectedColor] = useState("");
  const [colorSource, setColorSource] = useState("");
  const [dateSource, setDateSource] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [code_symbol, setCode_symbol] = useState(""); // code_symbol is symbol
  const { t } = props;

  useEffect(() => {
    document.addEventListener("mousedown", (e) => handleClickOutside(e));
  });

  useEffect(() => {
    if (props.period !== periodOption.period) {
      // if (props.periodOption === 'd') setPeriod('day');
      // else if (props.periodOption === 'w') setPeriod('week');
      // else if (props.periodOption === 'm') setPeriod('month');
      setPeriodOption({ period: props.period, option: props.periodOption })
    }
    resetTAUI();
  }, [props.period])

  useEffect(() => {
    // console.log("props.ChgThemeData");
    if (props.ChgThemeData === "Light") {
      setBackgroundColor("FAFAFA");
      setChartBackgroundColor("FAFAFA");
      setChartLineColor("EEEEEE");
      setToolTipsColor("333333");

      // candlestick
      setCandlestickUpColor("333333");
      setCandlestickUpFillColor("333333");
      setCandlestickDownColor("333333");
      setCandlestickDownFillColor("FFFFFF");
    } else {
      setBackgroundColor("000000");
      setChartBackgroundColor("000000");
      setChartLineColor("111111");
      setToolTipsColor("FFFFFF");

      // candlestick
      setCandlestickUpColor("FFFFFF");
      setCandlestickUpFillColor("FFFFFF");
      setCandlestickDownColor("F5475B");
      setCandlestickDownFillColor("F5475B");
    }
  }, [props.ChgThemeData]);

  useEffect(() => {
    onRemoveCompareClick({ value: props.code });
  }, [props.code]);

  // useEffect(() => {
  //     window.addEventListener("resize", updateDimensions);
  // });

  // const updateDimensions = () => {
  //     console.log(window.innerWidth)
  // };

  useEffect(() => {
    setShowMsg(props.showMsg);
  }, [props.showMsg]);

  const handleClickOutside = (event) => {
    if (
      !periodRef.current === undefined &&
      !periodRef.current === null &&
      !periodRef.current.contains(event.target) &&
      !periodRef.current.contains(event.target) === null &&
      !chartTypeRef.current === undefined &&
      !chartTypeRef.current === null &&
      !chartTypeRef.current.contains(event.target) &&
      !drawtoolRef.current === undefined &&
      !drawtoolRef.current === null &&
      !drawtoolRef.current.contains(event.target) &&
      !taRef.current === undefined &&
      !taRef.current === null &&
      !taRef.current.contains(event.target) &&
      !compareRef.current === undefined &&
      !compareRef.current === null &&
      !compareRef.current.contains(event.target) &&
      !viewRef.current === undefined &&
      !viewRef.current === null &&
      !viewRef.current.contains(event.target) &&
      !settingRef.current === undefined &&
      !settingRef.current === null &&
      !settingRef.current.contains(event.target) &&
      !props.colorRef.current === undefined &&
      !props.colorRef.current === null &&
      !props.colorRef.current.contains(event.target) &&
      event.target.className.replace(" focus-within", "") !== "area" &&
      event.target.className.replace(" focus-within", "") !== "colorpicker" &&
      event.target.className.replace(" focus-within", "") !== "dialogtitle" &&
      event.target.className.replace(" focus-within", "") !== "jscolor" &&
      event.target.className.replace(" focus-within", "") !== "helpmsg" &&
      event.target.className.replace(" focus-within", "") !==
      "ja hasdialogtitle" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-Day-Text" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-Day" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-Day-Header" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-Week" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-icon ui-icon-circle-triangle-w" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-icon ui-icon-circle-triangle-e" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-icon ui-icon-circle-triangle-w-disable" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-icon ui-icon-circle-triangle-e-disable" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-year" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-month" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" &&
      event.target.className.replace(" focus-within", "") !==
      "ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all" &&
      event.target.className.replace(" focus-within", "") !==
      "Polaris-Connected__Item Polaris-Connected__Item--connection" &&
      event.target.className.replace(" focus-within", "") !== "jscolor-input" &&
      event.target.className.replace(" focus-within", "") !== "event"
    ) {
      setType("");
      props.onColorPickerClose();
      props.onDatePickerClose();
    }
  };

  useEffect(() => {
    let option = {};
    if (chartType === "line")
      option = {
        type: "line",
        option: {
          lineColor: lineColor,
          lineFillColor: lineFillColor,
          lineFillColor2: lineFillColor2,
          lineGradientEnable: lineGradientEnable,
          lineStyle,
          lineThickness: lineThickness,
          showTracker: showTracker,
        },
      };
    else if (chartType === "candlestick")
      option = {
        type: "candlestick",
        option: {
          upColor: candlestickUpColor,
          upFillColor: candlestickUpFillColor,
          downColor: candlestickDownColor,
          downFillColor: candlestickDownFillColor,
        },
      };
    else if (chartType === "histogram")
      option = {
        type: "histogram",
        option: {
          upColor: histogramUpColor,
          upFillColor: histogramUpFillColor,
          downColor: histogramDownColor,
          downFillColor: histogramDownFillColor,
        },
      };
    else if (chartType === "bar")
      option = { type: "bar", option: { barColor: barColor } };
    props.onChartTypeChange(option);
  }, [
    chartType,
    lineGradientEnable,
    lineStyle,
    lineThickness,
    showTracker,
    lineColor,
    lineFillColor,
    lineFillColor2,
    candlestickUpColor,
    candlestickUpFillColor,
    candlestickDownColor,
    candlestickDownFillColor,
    histogramUpColor,
    histogramUpFillColor,
    histogramDownColor,
    histogramDownFillColor,
    barColor,
  ]);

  useEffect(() => {
    let view = {
      showMainTracker: showMainTracker,
      showTa: showTa,
      showCompare: showCompare,
      showDrawTool: showDrawTool,
      showTracker: showPriceTracker,
      showScrollView: showScrollView,
      showCrosshair: showCrosshair,
      showToolTips: showTip,
      showEvent: showEvent,
    };
    props.onViewChange(view);
  }, [
    showMainTracker,
    showTa,
    showCompare,
    showDrawTool,
    showPriceTracker,
    showScrollView,
    showCrosshair,
    showTip,
    showEvent,
  ]);

  useEffect(() => {
    if (!refIsLineChartColorChange.current) {
      if (periodOption.period === "1min") {
        setLineColor(props.ChgThemeData === "Light" ? "3366CC" : "F8F800");
      }
    }
  }, [props.ChgThemeData]);

  useEffect(() => {
    props.onPeriodChange(periodOption);

    // new reqirement, if no change charttype, 1min=line, other=candlestick
    if (!refIsSelectAnotherChartType.current) {
      if (periodOption.period === "1min") {
        setChartType("line");
        if (!refIsLineChartColorChange.current) {
          setLineColor(props.ChgThemeData === "Light" ? "3366CC" : lineColor);
        }
      } else {
        setChartType("candlestick");
      }
    }

    // new reqirement, if no change TA, 1min=no SMA, other=have SMA
    // if (!refIsSelectAnotherTA.current) {
    //   if (periodOption.period === "1min") {
    //     isSMA && removeOverLay("SMA");
    //   } else {
    //     setIsSMA(true);
    //   }
    // }
  }, [periodOption]);

  useEffect(() => {
    //console.log("Change");
    props.onDrawToolChange({
      type: drawTool,
      option: {
        drawLineColor: drawLineColor,
        drawLineThickness: drawLineThickness,
        drawLineStyle: drawLineStyle,
        drawRetracementColor: drawRetracementColor,
        drawRetracementThickness: drawRetracementThickness,
        drawRetracementStyle: drawRetracementStyle,
        drawFanColor: drawFanColor,
        drawFanThickness: drawFanThickness,
        drawFanStyle: drawFanStyle,
        drawArcsColor: drawArcsColor,
        drawArcsThickness: drawArcsThickness,
        drawArcsStyle: drawArcsStyle,
      },
    });
  }, [
    drawTool,
    drawLineColor,
    drawLineThickness,
    drawLineStyle,
    drawRetracementColor,
    drawRetracementThickness,
    drawRetracementStyle,
    drawFanColor,
    drawFanThickness,
    drawFanStyle,
    drawArcsColor,
    drawArcsThickness,
    drawArcsStyle,
  ]);

  useEffect(() => {
    setSelectedColor(props.selectedColor);
    if (props.selectedColor !== "") setColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setColorSource("");
  }, [props.menuColorPickerClose]);

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  useEffect(() => {
    let setting = {
      priceTrackColor: fieldTrackColor,
      priceTrackFontColor: fieldFontColor,
      priceTrackThinkness: fieldTrackThinkness,
      priceTrackStyle: fieldTrackStyle,
      yAsis: yAsis,
      backgroundColor: backgroundColor,
      chartBackgroundColor: chartBackgroundColor,
      chartLineColor: chartLineColor,
      chartLineStyle: chartLineStyle,
      toolTipsColor: toolTipsColor,
      crosshairLineColor: crosshairLineColor,
      crosshairLineStyle: crosshairLineStyle,
      crosshairPointColor: crosshairPointColor,
      crosshairPointSize: crosshairPointSize,
      crosshairFontColor: crosshairFontColor,
    };
    props.onSettingChange(setting);
  }, [
    fieldTrackColor,
    fieldFontColor,
    fieldTrackThinkness,
    fieldTrackStyle,
    yAsis,
    backgroundColor,
    chartBackgroundColor,
    chartLineColor,
    chartLineStyle,
    toolTipsColor,
    crosshairLineColor,
    crosshairLineStyle,
    crosshairPointColor,
    crosshairPointSize,
    crosshairFontColor,
  ]);

  useEffect(() => {
    if (props.drawTool === "move") setDrawTool("move");
  }, [props.drawTool]);

  useEffect(() => {
    let type = (isS ? "S" : "") + (isC ? "C" : "") + (isD ? "D" : "");
    //console.log(type);
    props.onEventChange(type);
  }, [showEvent, isS, isC, isD]);

  // color: string, type: string, title: any
  const onColorPickerOpen = (color, type, title) => {
    props.colorPickerOpen(color, type, title);
    setColorSource(type);
  };

  useEffect(() => {
    setColorSource(props.colorSource);
  }, [props.colorSource]);

  const openMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setType("");
      setIsOpen(true);
    }
  };

  const onMenuClick = (e, id) => {
    props.chartRef.current.onDoneClick();
    if (id === "period") {
      if (periodRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "period") setType("period");
        else setType("");
      }
    } else if (id === "chartType") {
      if (chartTypeRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "chartType") setType("chartType");
        else setType("");
        //setShowCompare(false)
      }
    } else if (id === "drawTool") {
      if (drawtoolRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "drawTool") setType("drawTool");
        else setType("");
      }
    } else if (id === "ta") {
      if (taRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "ta") setType("ta");
        else setType("");
        setShowCompare(false);
      }
    } else if (id === "compare") {
      if (compareRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "compare") setType("compare");
        else setType("");
      }
    } else if (id === "view") {
      if (viewRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "view") setType("view");
        else setType("");
      }
    } else if (id === "setting") {
      if (settingRef.current?.id === e.target.id) {
        props.onColorPickerClose();
        if (type !== "setting") setType("setting");
        else setType("");
      }
    }
  };

  //Period
  // e: any, id: string
  const onPeriodChange = (e, id) => {
    if (id === "1min") {
      setPeriod("1min");
      setPeriodOption({ period: "1min", option: "" });
    } else if (id === "5mins") {
      setPeriod("5mins");
      setPeriodOption({ period: "5mins", option: "" });
    } else if (id === "15mins") {
      setPeriod("15mins");
      setPeriodOption({ period: "15mins", option: "" });
    } else if (id === "1hour") {
      setPeriod("1hour");
      setPeriodOption({ period: "1hour", option: "" });
    } else if (id === "15mins5d") {
      setPeriod("15mins5d");
      setPeriodOption({ period: "15mins5d", option: "" });
    } else if (id === "day") {
      if (period !== "day") {
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setIsDayOpen(true);
        setIsWeekOpen(false);
        setIsMonthOpen(false);
        setPeriod("day");

        if (periodOption.option === "d") {
          switch (periodOption.period) {
            case "m": setDay_month(true); break;
            case "1m": setDay_1month(true); break;
            case "2m": setDay_2month(true); break;
            case "3m": setDay_3month(true); break;
            case "6m": setDay_6month(true); break;
            case "y": setDay_year(true); break;
            case "1y": setDay_1year(true); break;
            case "2y": setDay_2year(true); break;
            case "day_free": setDay_free(true); break;
            // default: setPeriodOption({ period: "6m", option: "d" }); setDay_6month(true); break;
          }
        }
      } else {
        if (
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow"
        ) {
          if (isDayOpen) {
            setIsDayOpen(false);
            setIsWeekOpen(false);
            setIsMonthOpen(false);
          } else {
            setIsDayOpen(true);
            setIsWeekOpen(false);
            setIsMonthOpen(false);
          }
        }
      }
    } else if (id === "week") {
      if (period !== "week") {
        setPeriod("week");
        // setPeriodOption({ period: "1y", option: "w" });
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setIsDayOpen(false);
        setIsWeekOpen(true);
        setIsMonthOpen(false);

        if (periodOption.option === "w") {
          switch (periodOption.period) {
            case "1m": setWeek_1month(true); break;
            case "2m": setWeek_2month(true); break;
            case "3m": setWeek_3month(true); break;
            case "6m": setWeek_6month(true); break;
            case "y": setWeek_year(true); break;
            case "1y": setWeek_1year(true); break;
            case "2y": setWeek_2year(true); break;
            case "3y": setWeek_3year(true); break;
            case "4y": setWeek_4year(true); break;
            case "5y": setWeek_5year(true); break;
            case "day_free": setWeek_free(true); break;
            // default: setPeriodOption({ period: "1y", option: "w" }); setWeek_1year(true); break;
          }
        }

      } else {
        if (
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (isWeekOpen) {
            setIsDayOpen(false);
            setIsWeekOpen(false);
            setIsMonthOpen(false);
          } else {
            setIsDayOpen(false);
            setIsWeekOpen(true);
            setIsMonthOpen(false);
          }
        }
      }
    } else if (id === "month") {
      if (period !== "month") {
        setPeriod("month");
        // setPeriodOption({ period: "5y", option: "m" });
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setIsDayOpen(false);
        setIsWeekOpen(false);
        setIsMonthOpen(true);

        if (periodOption.option === "m") {
          switch (periodOption.period) {
            case "6m": setMonth_6month(true); break;
            case "y": setMonth_year(true); break;
            case "1y": setMonth_1year(true); break;
            case "2y": setMonth_2year(true); break;
            case "3y": setMonth_3year(true); break;
            case "4y": setMonth_4year(true); break;
            case "5y": setMonth_5year(true); break;
            case "10y": setMonth_10year(true); break;
            case "month_free": setMonth_free(true); break;
            // default: setPeriodOption({ period: "5y", option: "m" }); setMonth_5year(true); break;
          }
        }

      } else {
        if (
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (isMonthOpen) {
            setIsDayOpen(false);
            setIsWeekOpen(false);
            setIsMonthOpen(false);
          } else {
            setIsDayOpen(false);
            setIsWeekOpen(false);
            setIsMonthOpen(true);
          }
        }
      }
    }
  };

  const onDayButtonClick = (id) => {
    switch (id) {
      case "m":
        setDay_month(true);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "m", option: "d" });
        break;
      case "1m":
        setDay_month(false);
        setDay_1month(true);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "1m", option: "d" });
        break;
      case "2m":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(true);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "2m", option: "d" });
        break;
      case "3m":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(true);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "3m", option: "d" });
        break;
      case "6m":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(true);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "6m", option: "d" });
        break;
      case "y":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(true);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "y", option: "d" });
        break;
      case "1y":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(true);
        setDay_2year(false);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "1y", option: "d" });
        break;
      case "2y":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(true);
        setDay_free(false);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({ period: "2y", option: "d" });
        break;
      case "day_free":
        setDay_month(false);
        setDay_1month(false);
        setDay_2month(false);
        setDay_3month(false);
        setDay_6month(false);
        setDay_year(false);
        setDay_1year(false);
        setDay_2year(false);
        setDay_free(true);
        setWeek_free(false);
        setMonth_free(false);
        setPeriodOption({
          period: "day_free",
          option: { option: "d", fr: day_fr_value, to: day_to_value },
        });
        break;
    }
  };

  const onWeekButtonClick = (id) => {
    switch (id) {
      case "1m":
        setWeek_1month(true);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "1m", option: "w" });
        break;
      case "2m":
        setWeek_1month(false);
        setWeek_2month(true);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "2m", option: "w" });
        break;
      case "3m":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(true);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "3m", option: "w" });
        break;

      case "6m":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(true);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "6m", option: "w" });
        break;
      case "y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(true);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "y", option: "w" });
        break;
      case "1y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(true);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "1y", option: "w" });
        break;

      case "2y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(true);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "2y", option: "w" });
        break;
      case "3y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(true);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "3y", option: "w" });
        break;
      case "4y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(true);
        setWeek_5year(false);
        setWeek_free(false);
        setPeriodOption({ period: "4y", option: "w" });
        break;
      case "5y":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(true);
        setWeek_free(false);
        setPeriodOption({ period: "5y", option: "w" });
        break;
      case "week_free":
        setWeek_1month(false);
        setWeek_2month(false);
        setWeek_3month(false);
        setWeek_6month(false);
        setWeek_year(false);
        setWeek_1year(false);
        setWeek_2year(false);
        setWeek_3year(false);
        setWeek_4year(false);
        setWeek_5year(false);
        setWeek_free(true);
        setPeriodOption({
          period: "week_free",
          option: { option: "w", fr: week_fr_value, to: week_to_value },
        });
        break;
    }
  };

  const onMonthButtonClick = (id) => {
    switch (id) {
      case "6m":
        setMonth_6month(true);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "6m", option: "m" });
        break;
      case "y":
        setMonth_6month(false);
        setMonth_year(true);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "y", option: "m" });
        break;
      case "1y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(true);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "1y", option: "m" });
        break;

      case "2y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(true);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "2y", option: "m" });
        break;
      case "3y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(true);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "3y", option: "m" });
        break;
      case "4y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(true);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "4y", option: "m" });
        break;
      case "5y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(true);
        setMonth_10year(false);
        setMonth_free(false);
        setPeriodOption({ period: "5y", option: "m" });
        break;
      case "10y":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(true);
        setMonth_free(false);
        setPeriodOption({ period: "10y", option: "m" });
        break;
      case "month_free":
        setMonth_6month(false);
        setMonth_year(false);
        setMonth_1year(false);
        setMonth_2year(false);
        setMonth_3year(false);
        setMonth_4year(false);
        setMonth_5year(false);
        setMonth_10year(false);
        setMonth_free(true);
        setPeriodOption({
          period: "month_free",
          option: { option: "m", fr: month_fr_value, to: month_to_value },
        });
        break;
    }
  };

  // value: any, type: string, source: string
  const onDateClick = (value, type, source) => {
    if (source === "day_free") {
      setDay_free(true);
      setWeek_free(false);
      setMonth_free(false);
    }
    if (source === "week_free") {
      setDay_free(false);
      setWeek_free(true);
      setMonth_free(false);
    }
    if (source === "month_free") {
      setDay_free(false);
      setWeek_free(false);
      setMonth_free(true);
    }

    if (dateSource !== source) {
      setDateSource(source);
    }

    props.onDatePickerClick(value, type);
  };

  // e: any, source: string
  const onDateChange = (e, source) => {
    //console.log(e.target.value)
    let type = "d";
    switch (source) {
      case "dayFr":
        setDay_fr_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "d";
        break;
      case "dayTo":
        setDay_to_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "d";
        break;
      case "weekFr":
        setWeek_fr_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "w";
        break;
      case "weekTo":
        setWeek_to_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "w";
        break;
      case "monthFr":
        setMonth_fr_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "m";
        break;
      case "monthTo":
        setMonth_to_value(e.target.value.replace(/[^0-9/]/gi, ""));
        type = "m";
        break;
    }

    let r = validatedate(e.target.value, "yyyy/mm/dd");
    if (r === false) {
      props.onDateChange(e.target.value);
    }
  };

  // inputText: any, DateFormat: string
  const validatedate = (inputText, DateFormat) => {
    // format dd/mm/yyyy or in any order of (dd or mm or yyyy) you can write dd or mm or yyyy in first or second or third position ... or can be slash"/" or dot"." or dash"-" in the dates formats
    var dt = "";
    var mn = "";
    var yr = "";
    var k;
    var delm = DateFormat.includes("/")
      ? "/"
      : DateFormat.includes("-")
        ? "-"
        : DateFormat.includes(".")
          ? "."
          : "";
    var f1 = inputText.split(delm);
    var f2 = DateFormat.split(delm);
    for (k = 0; k <= 2; k++) {
      dt = dt + (f2[parseInt(k)] === "dd" ? f1[parseInt(k)] : "");
      mn = mn + (f2[parseInt(k)] === "mm" ? f1[parseInt(k)] : "");
      yr = yr + (f2[parseInt(k)] === "yyyy" ? f1[parseInt(k)] : "");
    }
    var mn_days =
      "0-31-" + (yr % 4 === 0 ? 29 : 28) + "-31-30-31-30-31-31-30-31-30-31";
    var days = mn_days.split("-");
    if (
      f1.length !== 3 ||
      mn.length > 2 ||
      dt.length > 2 ||
      yr.length !== 4 ||
      !(parseInt(mn) >= 1 && parseInt(mn) <= 12) ||
      !(parseInt(yr) >= 1900 && parseInt(yr) <= 2100) ||
      !(parseInt(dt) >= 1 && parseInt(dt) <= parseInt(days[parseInt(mn)]))
    ) {
      return true;
    } else {
      return false;
    }
  };

  //Chart Type
  const onChartTypeChange = (e, id, isSVG) => {
    // return if step in svg click from parent tag
    if (!isSVG && typeof e.target.className !== "string") {
      return;
    }

    refIsSelectAnotherChartType.current = true;

    if (id === "line") {
      if (chartType !== "line") {
        setShowCompare(false);
        setIsLineOpen(true);
        setIsCandlestickOpen(false);
        setIsBarOpen(false);
        setIsHistogramOpen(false);
        setChartType("line");
      } else {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
            if (isLineOpen) {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            } else {
              setIsLineOpen(true);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            }
          }
        }
      }
    } else if (id === "candlestick") {
      if (chartType !== "candlestick") {
        setShowCompare(false);
        setIsLineOpen(false);
        setIsCandlestickOpen(true);
        setIsBarOpen(false);
        setIsHistogramOpen(false);
        setChartType("candlestick");
      } else {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
            if (isCandlestickOpen) {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            } else {
              setIsLineOpen(false);
              setIsCandlestickOpen(true);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            }
          }
        }
      }
    } else if (id === "histogram") {
      if (chartType !== "histogram") {
        setShowCompare(false);
        setIsLineOpen(false);
        setIsCandlestickOpen(false);
        setIsBarOpen(false);
        setIsHistogramOpen(true);
        setChartType("histogram");
      } else {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
            if (isHistogramOpen) {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            } else {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(true);
            }
          }
        }
      }
    } else if (id === "bar") {
      if (chartType !== "bar") {
        setShowCompare(false);
        setIsLineOpen(false);
        setIsCandlestickOpen(false);
        setIsBarOpen(true);
        setIsHistogramOpen(false);
        setChartType("bar");
      } else {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") ===
          "menusubitem msiaction radiogroup" ||
          e.target.className.replace(" focus-within", "") === "span" ||
          e.target.className.replace(" focus-within", "") === "optboxarrow" ||
          e.target.className.replace(" focus-within", "") === "msiicon" ||
          e.target.className.replace(" focus-within", "") === "radiobutton" ||
          e.target.className.replace(" focus-within", "") ===
          "radiobutton-selected"
        ) {
          if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
            if (isBarOpen) {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(false);
              setIsHistogramOpen(false);
            } else {
              setIsLineOpen(false);
              setIsCandlestickOpen(false);
              setIsBarOpen(true);
              setIsHistogramOpen(false);
            }
          }
        }
      }
    }
  };

  const onShowTracker = () => {
    if (showTracker) setShowTracker(false);
    else setShowTracker(true);
  };

  const onLineGradient = () => {
    if (lineGradientEnable) {
      setLineGradientEnable(false);
    } else {
      setLineGradientEnable(true);
    }
  };

  const onLineStyleChange = (e) => {
    setLineStyle(e.target.value);
  };

  const onLineThicknessChange = (e) => {
    setLineThickness(e.target.value);
  };

  //Draw Tool
  // e: any, id: string, isSVG: boolean
  const onDrawToolChange = (e, id, isSVG) => {
    // return if step in svg click from parent tag
    if (!isSVG && typeof e.target.className !== "string") {
      return;
    }

    if (id === "move") {
      setDrawTool("move");
      setIsDrawLineOpen(false);
      setIsDrawRetracementOpen(false);
      setIsDrawFanOpen(false);
      setIsDrawArcsOpen(false);
    } else if (id === "drawLine") {
      setDrawTool("drawLine");
      setShowDrawTool(true);
      if (
        isSVG ||
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction radiogroup" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon" ||
        e.target.className.replace(" focus-within", "") === "radiobutton" ||
        e.target.className.replace(" focus-within", "") ===
        "radiobutton-selected"
      ) {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") !== "b2dbtn"
        ) {
          if (isDrawLineOpen) {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          } else {
            setIsDrawLineOpen(true);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          }
        }
      }
    } else if (id === "drawRetracement") {
      setDrawTool("drawRetracement");
      setShowDrawTool(true);
      if (
        isSVG ||
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction radiogroup" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon" ||
        e.target.className.replace(" focus-within", "") === "radiobutton" ||
        e.target.className.replace(" focus-within", "") ===
        "radiobutton-selected"
      ) {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") !== "b2dbtn"
        ) {
          if (isDrawRetracementOpen) {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          } else {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(true);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          }
        }
      }
    } else if (id === "drawFan") {
      setDrawTool("drawFan");
      setShowDrawTool(true);
      if (
        isSVG ||
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction radiogroup" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon" ||
        e.target.className.replace(" focus-within", "") === "radiobutton" ||
        e.target.className.replace(" focus-within", "") ===
        "radiobutton-selected"
      ) {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") !== "b2dbtn"
        ) {
          if (isDrawFanOpen) {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          } else {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(true);
            setIsDrawArcsOpen(false);
          }
        }
      }
    } else if (id === "drawArcs") {
      setDrawTool("drawArcs");
      setShowDrawTool(true);
      if (
        isSVG ||
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction radiogroup" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon" ||
        e.target.className.replace(" focus-within", "") === "radiobutton" ||
        e.target.className.replace(" focus-within", "") ===
        "radiobutton-selected"
      ) {
        if (
          isSVG ||
          e.target.className.replace(" focus-within", "") !== "b2dbtn"
        ) {
          if (isDrawArcsOpen) {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(false);
          } else {
            setIsDrawLineOpen(false);
            setIsDrawRetracementOpen(false);
            setIsDrawFanOpen(false);
            setIsDrawArcsOpen(true);
          }
        }
      }
    }
  };

  // e: any, id: string
  const onDrawToolStyleChange = (e, id) => {
    if (id === "line") {
      setDrawLineStyle(e.target.value);
    } else if (id === "retracement") {
      setDrawRetracementStyle(e.target.value);
    } else if (id === "fan") {
      setDrawFanStyle(e.target.value);
    } else if (id === "arcs") {
      setDrawArcsStyle(e.target.value);
    }
  };

  // e: any, id: string
  const onDrawToolThicknessChange = (e, id) => {
    if (id === "line") {
      setDrawLineThickness(e.target.value);
    } else if (id === "retracement") {
      setDrawRetracementThickness(e.target.value);
    } else if (id === "fan") {
      setDrawFanThickness(e.target.value);
    } else if (id === "arcs") {
      setDrawArcsThickness(e.target.value);
    }
  };

  //View
  const onShowMainLineChange = () => {
    if (showMainTracker) setShowMainTracker(false);
    else setShowMainTracker(true);
  };

  const onShowTaChange = () => {
    if (showTa) setShowTa(false);
    else setShowTa(true);
  };

  const onShowCompareChange = () => {
    if (showCompare) setShowCompare(false);
    else setShowCompare(true);
  };

  const onShowDrawToolChange = () => {
    if (showDrawTool) setShowDrawTool(false);
    else setShowDrawTool(true);
  };

  const onShowValueChange = () => {
    if (showPriceTracker) setShowPriceTracker(false);
    else setShowPriceTracker(true);
  };

  const onShowScrollViewChange = () => {
    if (showScrollView) setShowScrollView(false);
    else setShowScrollView(true);
  };

  const onShowCrosshairChange = () => {
    if (showCrosshair) setShowCrosshair(false);
    else setShowCrosshair(true);
  };

  const onShowTipChange = () => {
    if (showTip) setShowTip(false);
    else setShowTip(true);
  };

  const onShowEventChange = (e) => {
    if (
      e.target.className.replace(" focus-within", "") ===
      "menusubitem msiaction radiogroup" ||
      e.target.className.replace(" focus-within", "") === "span" ||
      e.target.className.replace(" focus-within", "") === "optboxarrow" ||
      e.target.className.replace(" focus-within", "") === "msiicon" ||
      e.target.className.replace(" focus-within", "") === "event"
    ) {
    } else {
      if (showEvent) setShowEvent(false);
      else setShowEvent(true);
    }
  };

  const onEventSettingClick = (e) => {
    if (isEventOpen) setIsEventOpen(false);
    else setIsEventOpen(true);
  };

  const onEventClick = (type) => {
    if (type === "S") {
      if (isS) setIsS(false);
      else setIsS(true);
    } else if (type === "C") {
      if (isC) setIsC(false);
      else setIsC(true);
    } else {
      if (isD) setIsD(false);
      else setIsD(true);
    }
  };

  //Setting
  // confirm?: boolean
  const onRemoveTAAndComparion = (confirm) => {
    var answer = false;
    if (!confirm)
      answer = window.confirm("全てをクリアします。よろしいですか？");
    if (answer || confirm) {
      props.setIsReset(true)
      setTapane(new Array());
      setOverlay(new Array());
      //setCompares(new Array())
      setShowCompare(false);
      setCompares(new Array());
      resetTAUI();

      props.resetAnnotation();
    }
  };

  const resetTAUI = () => {
    setIsSMA(false);
    setIsWMA(false);
    setIsEMA(false);
    setIsMEMA(false);
    setIsTVMA(false);
    setIsICH(false);
    setIsMACD(false);
    setIsLRT(false);
    setIsSAR(false);
    setIsADXR(false);
    setIsDMI(false);
    setIsBias(false);
    setIsALF(false);
    setIsBB(false);
    setIsVC(false);
    setIsATR(false);
    setIsSD(false);
    setIsVltyCC(false);
    setIsKRI(false);
    setIsPL(false);
    setIsCO(false);
    setIsRCI(false);
    setIsFKD(false);
    setIsSKD(false);
    setIsCCI(false);
    setIsMI(false);
    setIsUPDW(false);
    setIsUO(false);
    setIsVector(false);
    setIsDPO(false);
    setIsWAD(false);
    setIsTrix(false);
    setIsVolume(false);
    setIsVE(false);
    setIsVAP(false);
    setIsVOSC(false);
    setIsVROC(false);
    setIsACD(false);
    setIsOBV(false);
    setIsEOM(false);
    setIsFI(false);
    setIsMFI(false);
    setIsNVI(false);
    setIsVR(false);
    setIsENV(false);
    setIsMOM(false);
    setIsRMI(false);
    setIsRSI(false);
    setIsWR(false);
    setIsPOSC(false);
    setIsPROC(false);
    setIsPRR(false);
    setIsVWAP(false);
    setIsWC(false);
    setIsPCV(false);
    setIsMargin(false);
    setIsTP(false);
    setIsRC(false);
    setIsTurnover(false);
    setIsCC(false);
    setIsKC(false);
    setIsUI(false);

    setCount(0);
  };

  const onHelpClick = () => {
    // window.open(`${config.SMBC_URL}${config.HELP_URL.CHART}`);
  };

  const onPrintClick = () => {
    props.onPrintChartClick();
  };

  const onStyleSettingClick = (e) => {
    if (
      e.target.className.replace(" focus-within", "") ===
      "menusubitem msiaction" ||
      e.target.className.replace(" focus-within", "") === "menusubitem" ||
      e.target.className.replace(" focus-within", "") === "span" ||
      e.target.className.replace(" focus-within", "") === "optboxarrow" ||
      e.target.className.replace(" focus-within", "") === "msiicon"
    ) {
      if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
        if (isStyleSettingOpen) setIsStyleSettingOpen(false);
        else setIsStyleSettingOpen(true);
      }
    }
  };

  const onYAsiaChange = () => {
    if (yAsis) setYAsis(false);
    else setYAsis(true);
  };

  const settingThicknessChange = (e, id) => {
    if (id === "pricetracker") {
      setFieldTrackThinkness(e.target.value);
    } else if (id === "crosshair") {
      setCrosshairPointSize(e.target.value);
    }
  };

  const settingStyleChange = (e, id) => {
    //console.log(id);
    if (id === "pricetracker") {
      setFieldTrackStyle(e.target.value);
    } else if (id === "gridline") {
      setChartLineStyle(e.target.value);
    } else if (id === "crosshair") {
      setCrosshairLineStyle(e.target.value);
    }
  };

  // confirm?: boolean
  const resetSettingStyle = (confirm) => {
    var answer = false;
    if (!confirm) answer = window.confirm(t("chart5.alertMsg"));
    if (answer || confirm) {
      setIsStyleSettingOpen(false);
      setFieldTrackColor("008000");
      setFieldFontColor("FFFFFF");
      setFieldTrackThinkness(2);
      setFieldTrackStyle("d");
      setYAsis(false);
      setBackgroundColor("FFFFFF");
      setChartBackgroundColor("FFFFFF");
      setChartLineColor("EEEEEE");
      setChartLineStyle("s");
      setToolTipsColor("333333");
      setCrosshairLineColor("444444");
      setCrosshairLineStyle("s");
      setCrosshairPointColor("333333");
      setCrosshairPointSize(3);
      setCrosshairFontColor("FFFFFF");
    }
  };

  //TA
  const onTAPowerSearchChange = (e) => {
    setTAPowerSearchValue(e.target.value);
  };

  let [count, setCount] = useState(0);
  // ta: string
  const onTAClick = (ta) => {
    refIsSelectAnotherTA.current = true;
    setCurrentTA(ta);
    // let c = count

    setShowTa(true);
    if (count < 5) {
      switch (ta) {
        case "SMA":
          if (isSMA) {
            setIsSMA(false);
          } else setIsSMA(true);
          break;
        case "WMA":
          if (isWMA) {
            setIsWMA(false);
          } else setIsWMA(true);
          break;
        case "EMA":
          if (isEMA) {
            setIsEMA(false);
          } else setIsEMA(true);
          break;
        case "MEMA":
          if (isMEMA) {
            setIsMEMA(false);
          } else setIsMEMA(true);
          break;
        case "TVMA":
          if (isTVMA) {
            setIsTVMA(false);
          } else setIsTVMA(true);
          break;
        case "ICH":
          if (isICH) {
            setIsICH(false);
          } else setIsICH(true);
          break;
        case "MACD":
          if (isMACD) {
            setIsMACD(false);
          } else setIsMACD(true);
          break;
        case "LRT":
          if (isLRT) {
            setIsLRT(false);
          } else setIsLRT(true);
          break;
        case "SAR":
          if (isSAR) {
            setIsSAR(false);
          } else setIsSAR(true);
          break;
        case "ADXR":
          if (isADXR) {
            setIsADXR(false);
          } else setIsADXR(true);
          break;
        case "DMI":
          if (isDMI) {
            setIsDMI(false);
          } else setIsDMI(true);
          break;
        case "Bias":
          if (isBias) {
            setIsBias(false);
          } else setIsBias(true);
          break;
        case "ALF":
          if (isALF) {
            setIsALF(false);
          } else setIsALF(true);
          break;
        case "BB":
          if (isBB) {
            setIsBB(false);
          } else setIsBB(true);
          break;
        case "VC":
          if (isVC) {
            setIsVC(false);
          } else setIsVC(true);
          break;
        case "ATR":
          if (isATR) {
            setIsATR(false);
          } else setIsATR(true);
          break;
        case "SD":
          if (isSD) {
            setIsSD(false);
          } else setIsSD(true);
          break;
        case "VltyCC":
          if (isVltyCC) {
            setIsVltyCC(false);
          } else setIsVltyCC(true);
          break;
        case "KRI":
          if (isKRI) {
            setIsKRI(false);
          } else setIsKRI(true);
          break;
        case "PL":
          if (isPL) {
            setIsPL(false);
          } else setIsPL(true);
          break;
        case "CO":
          if (isCO) {
            setIsCO(false);
          } else setIsCO(true);
          break;
        case "RCI":
          if (isRCI) {
            setIsRCI(false);
          } else setIsRCI(true);
          break;
        case "FKD":
          if (isFKD) {
            setIsFKD(false);
          } else setIsFKD(true);
          break;
        case "SKD":
          if (isSKD) {
            setIsSKD(false);
          } else setIsSKD(true);
          break;
        case "CCI":
          if (isCCI) {
            setIsCCI(false);
          } else setIsCCI(true);
          break;
        case "MI":
          if (isMI) {
            setIsMI(false);
          } else setIsMI(true);
          break;
        case "UPDW":
          if (isUPDW) {
            setIsUPDW(false);
          } else setIsUPDW(true);
          break;
        case "UO":
          if (isUO) {
            setIsUO(false);
          } else setIsUO(true);
          break;
        case "Vector":
          if (isVector) {
            setIsVector(false);
          } else setIsVector(true);
          break;
        case "DPO":
          if (isDPO) {
            setIsDPO(false);
          } else setIsDPO(true);
          break;
        case "WAD":
          if (isWAD) {
            setIsWAD(false);
          } else setIsWAD(true);
          break;
        case "Trix":
          if (isTrix) {
            setIsTrix(false);
          } else setIsTrix(true);
          break;
        case "Volume":
          if (isVolume) {
            setIsVolume(false);
          } else setIsVolume(true);
          break;
        case "VE":
          if (isVE) {
            setIsVE(false);
          } else setIsVE(true);
          break;
        case "VAP":
          if (isVAP) {
            setIsVAP(false);
          } else setIsVAP(true);
          break;
        case "VOSC":
          if (isVOSC) {
            setIsVOSC(false);
          } else setIsVOSC(true);
          break;
        case "VROC":
          if (isVROC) {
            setIsVROC(false);
          } else setIsVROC(true);
          break;
        case "ACD":
          if (isACD) {
            setIsACD(false);
          } else setIsACD(true);
          break;
        case "OBV":
          if (isOBV) {
            setIsOBV(false);
          } else setIsOBV(true);
          break;
        case "EOM":
          if (isEOM) {
            setIsEOM(false);
          } else setIsEOM(true);
          break;
        case "FI":
          if (isFI) {
            setIsFI(false);
          } else setIsFI(true);
          break;
        case "MFI":
          if (isMFI) {
            setIsMFI(false);
          } else setIsMFI(true);
          break;
        case "NVI":
          if (isNVI) {
            setIsNVI(false);
          } else setIsNVI(true);
          break;
        case "VR":
          if (isVR) {
            setIsVR(false);
          } else setIsVR(true);
          break;
        case "ENV":
          if (isENV) {
            setIsENV(false);
          } else setIsENV(true);
          break;
        case "MOM":
          if (isMOM) {
            setIsMOM(false);
          } else setIsMOM(true);
          break;
        case "RMI":
          if (isRMI) {
            setIsRMI(false);
          } else setIsRMI(true);
          break;
        case "RSI":
          if (isRSI) {
            setIsRSI(false);
          } else setIsRSI(true);
          break;
        case "WR":
          if (isWR) {
            setIsWR(false);
          } else setIsWR(true);
          break;
        case "POSC":
          if (isPOSC) {
            setIsPOSC(false);
          } else setIsPOSC(true);
          break;
        case "PROC":
          if (isPROC) {
            setIsPROC(false);
          } else setIsPROC(true);
          break;
        case "PRR":
          if (isPRR) {
            setIsPRR(false);
          } else setIsPRR(true);
          break;
        case "VWAP":
          if (isVWAP) {
            setIsVWAP(false);
          } else setIsVWAP(true);
          break;
        case "WC":
          if (isWC) {
            setIsWC(false);
          } else setIsWC(true);
          break;
        case "PCV":
          if (isPCV) {
            setIsPCV(false);
          } else setIsPCV(true);
          break;
        case "Margin":
          if (isMargin) {
            setIsMargin(false);
          } else setIsMargin(true);
          break;
        case "TP":
          if (isTP) {
            setIsTP(false);
          } else setIsTP(true);
          break;
        case "RC":
          if (isRC) {
            setIsRC(false);
          } else setIsRC(true);
          break;
        case "Turnover":
          if (isTurnover) {
            setIsTurnover(false);
          } else setIsTurnover(true);
          break;
        case "CC":
          if (isCC) {
            setIsCC(false);
          } else setIsCC(true);
          break;
        case "KC":
          if (isKC) {
            setIsKC(false);
          } else setIsKC(true);
          break;
        case "UI":
          if (isUI) {
            setIsUI(false);
          } else setIsUI(true);
          break;
      }
    } else {
      window.alert(t("chart5.maxTAMsg"));
    }
  };

  useEffect(() => {
    let TASortList = [];
    TAHeader.Trend.forEach((item) => TASortList.push(item.short));
    TAHeader.Volatility.forEach((item) => TASortList.push(item.short));
    TAHeader.Oscillator.forEach((item) => TASortList.push(item.short));
    TAHeader.Volume.forEach((item) => TASortList.push(item.short));
    TAHeader.Momentum.forEach((item) => TASortList.push(item.short));
    TAHeader.Price.forEach((item) => TASortList.push(item.short));
    TAHeader.Other.forEach((item) => TASortList.push(item.short));

    setSortTAList(TASortList);
  }, []);

  const renderTAHeader = () => {
    let trend = [];
    let trendList = [];
    let searchValue = taPowerSearchValue.toUpperCase();

    const renderCheckBox = (isOn) => {
      return isOn ? (
        <IconCheckboxOn className="checkbox" />
      ) : (
        <IconCheckboxOff className="checkbox" />
      );
    };

    TAHeader.Trend.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        trend.push(item);
      }
    });

    trend.forEach((item) => {
      if (item.short === "SMA") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isSMA ? removeOverLay("SMA") : onTAClick("SMA"))}
          >
            {renderCheckBox(isSMA)}
            <span>{t("chart5.TA.SMA")} (SMA)</span>
          </div>
        );
      } else if (item.short === "WMA") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isWMA ? removeOverLay("WMA") : onTAClick("WMA"))}
          >
            {renderCheckBox(isWMA)}
            <span>{t("chart5.TA.WMA")}&nbsp;(WMA)</span>
          </div>
        );
      } else if (item.short === "EMA") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isEMA ? removeOverLay("EMA") : onTAClick("EMA"))}
          >
            {renderCheckBox(isEMA)}
            <span>{t("chart5.TA.EMA")} (EMA)</span>
          </div>
        );
      } else if (item.short === "MEMA") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isMEMA ? removeOverLay("MEMA") : onTAClick("MEMA"))}
          >
            {renderCheckBox(isMEMA)}
            <span>{t("chart5.TA.MEMA")} (MEMA)</span>
          </div>
        );
      } else if (item.short === "TVMA") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isTVMA ? removeOverLay("TVMA") : onTAClick("TVMA"))}
          >
            {renderCheckBox(isTVMA)}
            <span>{t("chart5.TA.TVMA")} (TVMA)</span>
          </div>
        );
      } else if (item.short === "ICH") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isICH ? removeOverLay("ICH") : onTAClick("ICH"))}
          >
            {renderCheckBox(isICH)}
            <span>{t("chart5.TA.ICH")} (ICH)</span>
          </div>
        );
      } else if (item.short === "MACD") {
        trendList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isMACD ? removeTA("MACD") : onTAClick("MACD"))}
          >
            {renderCheckBox(isMACD)}
            <span>{t("chart5.TA.MACD")}&nbsp;(MACD)</span>
          </div>
        );
      } else if (item.short === "LRT") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isLRT ? removeOverLay("LRT") : onTAClick("LRT"))}
          >
            {renderCheckBox(isLRT)}
            <span>{t("chart5.TA.LRT")} (LRT)</span>
          </div>
        );
      } else if (item.short === "SAR") {
        trendList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isSAR ? removeOverLay("SAR") : onTAClick("SAR"))}
          >
            {renderCheckBox(isSAR)}
            <span>{t("chart5.TA.SAR")}&nbsp;(SAR)</span>
          </div>
        );
      } else if (item.short === "ADX-R") {
        trendList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isADXR ? removeTA("ADXR") : onTAClick("ADXR"))}
          >
            {renderCheckBox(isADXR)}
            <span>{t("chart5.TA.ADXR")} (ADX-R)</span>
          </div>
        );
      } else if (item.short === "DMI") {
        trendList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isDMI ? removeTA("DMI") : onTAClick("DMI"))}
          >
            {renderCheckBox(isDMI)}
            <span>{t("chart5.TA.DMI")} (DMI)</span>
          </div>
        );
      } else if (item.short === "Bias") {
        trendList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isBias ? removeTA("Bias") : onTAClick("Bias"))}
          >
            {renderCheckBox(isBias)}
            <span>{t("chart5.TA.Bias")} (Bias)</span>
          </div>
        );
      } else if (item.short === "ALF") {
        trendList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isALF ? removeTA("ALF") : onTAClick("ALF"))}
          >
            {renderCheckBox(isALF)}
            <span>{t("chart5.TA.ALF")} (ALF)</span>
          </div>
        );
      }
    });

    let volatility = [];
    let volatilityList = [];
    TAHeader.Volatility.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        volatility.push(item);
      }
    });

    volatility.forEach((item) => {
      if (item.short === "BB") {
        volatilityList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isBB ? removeOverLay("BB") : onTAClick("BB"))}
          >
            {renderCheckBox(isBB)}
            <span>{t("chart5.TA.BB")} (BB)</span>
          </div>
        );
      } else if (item.short === "VC") {
        volatilityList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVC ? removeTA("VC") : onTAClick("VC"))}
          >
            {renderCheckBox(isVC)}
            <span>{t("chart5.TA.VC")} (VC)</span>
          </div>
        );
      } else if (item.short === "ATR") {
        volatilityList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isATR ? removeTA("ATR") : onTAClick("ATR"))}
          >
            {renderCheckBox(isATR)}
            <span>{t("chart5.TA.ATR")}&nbsp;(ATR)</span>
          </div>
        );
      } else if (item.short === "SD") {
        volatilityList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isSD ? removeTA("SD") : onTAClick("SD"))}
          >
            {renderCheckBox(isSD)}
            <span>{t("chart5.TA.SD")} (SD)</span>
          </div>
        );
      } else if (item.short === "VltyCC") {
        volatilityList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() =>
              isVltyCC ? removeTA("VltyCC") : onTAClick("VltyCC")
            }
          >
            {renderCheckBox(isVltyCC)}
            <span>{t("chart5.TA.VltyCC")}&nbsp;(VltyCC)</span>
          </div>
        );
      }
    });

    let oscillator = [];
    let oscillatorList = [];
    TAHeader.Oscillator.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        oscillator.push(item);
      }
    });

    oscillator.forEach((item) => {
      if (item.short === "KRI") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isKRI ? removeTA("KRI") : onTAClick("KRI"))}
          >
            {renderCheckBox(isKRI)}
            <span>{t("chart5.TA.KRI")} (KRI)</span>
          </div>
        );
      } else if (item.short === "PL") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isPL ? removeTA("PL") : onTAClick("PL"))}
          >
            {renderCheckBox(isPL)}
            <span>{t("chart5.TA.PL")} (PL)</span>
          </div>
        );
      } else if (item.short === "CO") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isCO ? removeTA("CO") : onTAClick("CO"))}
          >
            {renderCheckBox(isCO)}
            <span>{t("chart5.TA.CO")} (CO)</span>
          </div>
        );
      } else if (item.short === "RCI") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isRCI ? removeTA("RCI") : onTAClick("RCI"))}
          >
            {renderCheckBox(isRCI)}
            <span>{t("chart5.TA.RCI")}&nbsp;(RCI)</span>
          </div>
        );
      } else if (item.short === "FKD") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isFKD ? removeTA("FKD") : onTAClick("FKD"))}
          >
            {renderCheckBox(isFKD)}
            <span>{t("chart5.TA.FKD")} (FKD)</span>
          </div>
        );
      } else if (item.short === "SKD") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isSKD ? removeTA("SKD") : onTAClick("SKD"))}
          >
            {renderCheckBox(isSKD)}
            <span>{t("chart5.TA.SKD")}&nbsp;(SKD)</span>
          </div>
        );
      } else if (item.short === "CCI") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isCCI ? removeTA("CCI") : onTAClick("CCI"))}
          >
            {renderCheckBox(isCCI)}
            <span>{t("chart5.TA.CCI")} (CCI)</span>
          </div>
        );
      } else if (item.short === "MI") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isMI ? removeTA("MI") : onTAClick("MI"))}
          >
            {renderCheckBox(isMI)}
            <span>{t("chart5.TA.MI")} (MI)</span>
          </div>
        );
      } else if (item.short === "UPDW") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isUPDW ? removeTA("UPDW") : onTAClick("UPDW"))}
          >
            {renderCheckBox(isUPDW)}
            <span>{t("chart5.TA.UPDW")} (UPDW)</span>
          </div>
        );
      } else if (item.short === "UO") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isUO ? removeTA("UO") : onTAClick("UO"))}
          >
            {renderCheckBox(isUO)}
            <span>{t("chart5.TA.UO")} (UO)</span>
          </div>
        );
      } else if (item.short === "Vector") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() =>
              isVector ? removeTA("Vector") : onTAClick("Vector")
            }
          >
            {renderCheckBox(isVector)}
            <span>{t("chart5.TA.Vector")}&nbsp;(Vector)</span>
          </div>
        );
      } else if (item.short === "DPO") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isDPO ? removeTA("DPO") : onTAClick("DPO"))}
          >
            {renderCheckBox(isDPO)}
            <span>{t("chart5.TA.DPO")}&nbsp;(DPO)</span>
          </div>
        );
      } else if (item.short === "WAD") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isWAD ? removeTA("WAD") : onTAClick("WAD"))}
          >
            {renderCheckBox(isWAD)}
            <span>{t("chart5.TA.WAD")} (WAD)</span>
          </div>
        );
      } else if (item.short === "Trix") {
        oscillatorList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isTrix ? removeTA("Trix") : onTAClick("Trix"))}
          >
            {renderCheckBox(isTrix)}
            <span>{t("chart5.TA.Trix")}&nbsp;(Trix)</span>
          </div>
        );
      }
    });

    let volume = [];
    let volumeList = [];
    TAHeader.Volume.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        volume.push(item);
      }
    });

    volume.forEach((item) => {
      if (item.short === "Volume") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() =>
              isVolume ? removeTA("Volume") : onTAClick("Volume")
            }
          >
            {renderCheckBox(isVolume)}
            <span>{t("chart5.TA.Volume")} (Volume)</span>
          </div>
        );
      } else if (item.short === "VE") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVE ? removeTA("VE") : onTAClick("VE"))}
          >
            {renderCheckBox(isVE)}
            <span>{t("chart5.TA.VE")}&nbsp;(VE)</span>
          </div>
        );
      } else if (item.short === "VAP") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVAP ? removeOverLay("VAP") : onTAClick("VAP"))}
          >
            {renderCheckBox(isVAP)}
            <span>{t("chart5.TA.VAP")} (VAP)</span>
          </div>
        );
      } else if (item.short === "VOSC") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVOSC ? removeTA("VOSC") : onTAClick("VOSC"))}
          >
            {renderCheckBox(isVOSC)}
            <span>{t("chart5.TA.VOSC")} (VOSC)</span>
          </div>
        );
      } else if (item.short === "VROC") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVROC ? removeTA("VROC") : onTAClick("VROC"))}
          >
            {renderCheckBox(isVROC)}
            <span>{t("chart5.TA.VROC")} (VROC)</span>
          </div>
        );
      } else if (item.short === "ACD") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isACD ? removeTA("ACD") : onTAClick("ACD"))}
          >
            {renderCheckBox(isACD)}
            <span>{t("chart5.TA.ACD")} (ACD)</span>
          </div>
        );
      } else if (item.short === "OBV") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isOBV ? removeTA("OBV") : onTAClick("OBV"))}
          >
            {renderCheckBox(isOBV)}
            <span>{t("chart5.TA.OBV")} (OBV)</span>
          </div>
        );
      } else if (item.short === "EOM") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isEOM ? removeTA("EOM") : onTAClick("EOM"))}
          >
            {renderCheckBox(isEOM)}
            <span>{t("chart5.TA.EOM")} (EOM)</span>
          </div>
        );
      } else if (item.short === "FI") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isFI ? removeTA("FI") : onTAClick("FI"))}
          >
            {renderCheckBox(isFI)}
            <span>{t("chart5.TA.FI")} (FI)</span>
          </div>
        );
      } else if (item.short === "MFI") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isMFI ? removeTA("MFI") : onTAClick("MFI"))}
          >
            {renderCheckBox(isMFI)}
            <span>{t("chart5.TA.MFI")} (MFI)</span>
          </div>
        );
      } else if (item.short === "NVI") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isNVI ? removeTA("NVI") : onTAClick("NVI"))}
          >
            {renderCheckBox(isNVI)}
            <span>{t("chart5.TA.NVI")} (NVI)</span>
          </div>
        );
      } else if (item.short === "VR") {
        volumeList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isVR ? removeTA("VR") : onTAClick("VR"))}
          >
            {renderCheckBox(isVR)}
            <span>{t("chart5.TA.VR")} (VR)</span>
          </div>
        );
      }
    });

    let momentum = [];
    let momentumList = [];
    TAHeader.Momentum.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        momentum.push(item);
      }
    });

    momentum.forEach((item) => {
      if (item.short === "ENV") {
        momentumList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isENV ? removeOverLay("ENV") : onTAClick("ENV"))}
          >
            {renderCheckBox(isENV)}
            <span>{t("chart5.TA.ENV")} (ENV)</span>
          </div>
        );
      } else if (item.short === "MOM") {
        momentumList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isMOM ? removeTA("MOM") : onTAClick("MOM"))}
          >
            {renderCheckBox(isMOM)}
            <span>{t("chart5.TA.MOM")} (MOM)</span>
          </div>
        );
      } else if (item.short === "RMI") {
        momentumList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isRMI ? removeTA("RMI") : onTAClick("RMI"))}
          >
            {renderCheckBox(isRMI)}
            <span>{t("chart5.TA.RMI")} (RMI)</span>
          </div>
        );
      } else if (item.short === "RSI") {
        momentumList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isRSI ? removeTA("RSI") : onTAClick("RSI"))}
          >
            {renderCheckBox(isRSI)}
            <span>{t("chart5.TA.RSI")} (RSI)</span>
          </div>
        );
      } else if (item.short === "WR") {
        momentumList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isWR ? removeTA("WR") : onTAClick("WR"))}
          >
            {renderCheckBox(isWR)}
            <span>{t("chart5.TA.WR")} (WR)</span>
          </div>
        );
      }
    });

    let price = [];
    let priceList = [];
    TAHeader.Price.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        price.push(item);
      }
    });

    price.forEach((item) => {
      if (item.short === "POSC") {
        priceList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isPOSC ? removeTA("POSC") : onTAClick("POSC"))}
          >
            {renderCheckBox(isPOSC)}
            <span>{t("chart5.TA.POSC")} (POSC)</span>
          </div>
        );
      } else if (item.short === "PROC") {
        priceList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isPROC ? removeTA("PROC") : onTAClick("PROC"))}
          >
            {renderCheckBox(isPROC)}
            <span>{t("chart5.TA.PROC")} (PROC)</span>
          </div>
        );
      } else if (item.short === "PRR") {
        priceList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isPRR ? removeOverLay("PRR") : onTAClick("PRR"))}
          >
            {renderCheckBox(isPRR)}
            <span>{t("chart5.TA.PRR")} (PRR)</span>
          </div>
        );
      } else if (item.short === "VWAP") {
        priceList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isVWAP ? removeOverLay("VWAP") : onTAClick("VWAP"))}
          >
            {renderCheckBox(isVWAP)}
            <span>{t("chart5.TA.VWAP")}&nbsp;(VWAP)</span>
          </div>
        );
      } else if (item.short === "WC") {
        priceList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isWC ? removeOverLay("WC") : onTAClick("WC"))}
          >
            {renderCheckBox(isWC)}
            <span>{t("chart5.TA.WC")} (WC)</span>
          </div>
        );
      } else if (item.short === "PCV") {
        priceList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isPCV ? removeTA("PCV") : onTAClick("PCV"))}
          >
            {renderCheckBox(isPCV)}
            <span>{t("chart5.TA.PCV")}&nbsp;(PCV)</span>
          </div>
        );
      }
    });

    let other = [];
    let otherList = [];
    TAHeader.Other.forEach((item) => {
      if (item.name.toUpperCase().includes(searchValue)) {
        other.push(item);
      }
    });

    other.forEach((item) => {
      if (item.short === "Margin") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() =>
              isMargin ? removeTA("Margin") : onTAClick("Margin")
            }
          >
            {renderCheckBox(isMargin)}
            <span>{t("chart5.TA.Margin")} (Margin)</span>
          </div>
        );
      } else if (item.short === "TP") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isTP ? removeOverLay("TP") : onTAClick("TP"))}
          >
            {renderCheckBox(isTP)}
            <span>{t("chart5.TA.TP")}&nbsp;(TP)</span>
          </div>
        );
      } else if (item.short === "RC") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isRC ? removeTA("RC") : onTAClick("RC"))}
          >
            {renderCheckBox(isRC)}
            <span>{t("chart5.TA.RC")} (RC)</span>
          </div>
        );
      } else if (item.short === "Turnover") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() =>
              isTurnover ? removeTA("Turnover") : onTAClick("Turnover")
            }
          >
            {renderCheckBox(isTurnover)}
            <span>{t("chart5.TA.Turnover")} (Turnover)</span>
          </div>
        );
      } else if (item.short === "CC") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isCC ? removeTA("CC") : onTAClick("CC"))}
          >
            {renderCheckBox(isCC)}
            <span>{t("chart5.TA.CC")}&nbsp;(CC)</span>
          </div>
        );
      } else if (item.short === "KC") {
        otherList.push(
          <div
            className="menusubitem msiaction techicaloverlay header"
            id="msi-ta"
            onClick={() => (isKC ? removeOverLay("KC") : onTAClick("KC"))}
          >
            {renderCheckBox(isKC)}
            <span>{t("chart5.TA.KC")}&nbsp;(KC)</span>
          </div>
        );
      } else if (item.short === "UI") {
        otherList.push(
          <div
            className="menusubitem msiaction techicalindicator header"
            id="msi-ta"
            onClick={() => (isUI ? removeTA("UI") : onTAClick("UI"))}
          >
            {renderCheckBox(isUI)}
            <span>{t("chart5.TA.UI")}&nbsp;(UI)</span>
          </div>
        );
      }
    });

    return (
      <div>
        {trendList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.trend")}</span>
            </div>
            <div className="flex">{trendList}</div>
          </div>
        )}
        {volatilityList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.volatility")}</span>
            </div>
            <div className="flex">{volatilityList}</div>
          </div>
        )}
        {oscillatorList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.oscillator")}</span>
            </div>
            <div className="flex">{oscillatorList}</div>
          </div>
        )}
        {volumeList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.volume")}</span>
            </div>
            <div className="flex">{volumeList}</div>
          </div>
        )}
        {momentumList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.momentum")}</span>
            </div>
            <div className="flex">{momentumList}</div>
          </div>
        )}
        {priceList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.price")}</span>
            </div>
            <div className="flex">{priceList}</div>
          </div>
        )}
        {otherList.length > 0 && (
          <div className="taselbox">
            <div className="msi-grplbl msi-optbox noclose">
              <span>{t("chart5.others")}</span>
            </div>
            <div className="flex">{otherList}</div>
          </div>
        )}
        {trendList.length === 0 &&
          volatilityList.length === 0 &&
          oscillatorList.length === 0 &&
          volumeList.length === 0 &&
          momentumList.length === 0 &&
          priceList.length === 0 &&
          otherList.length === 0 && (
            <div className="notasellbl">
              <span>{t("chart5.none")}</span>
            </div>
          )}
      </div>
    );
  };

  let ta = [];
  let taList = [];
  const rendarTA = () => {
    if (ta.length < 5) {
      if (isSMA) {
        taList.push("SMA");
        ta.push(
          <div key="SMA">
            <SMA
              isOpen={currentTA === "SMA" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isWMA) {
        taList.push("WMA");
        ta.push(
          <div key="WMA">
            <WMA
              isOpen={currentTA === "WMA" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isEMA) {
        taList.push("EMA");
        ta.push(
          <div key="EMA">
            <EMA
              isOpen={currentTA === "EMA" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMEMA) {
        taList.push("MEMA");
        ta.push(
          <div key="MEMA">
            <MEMA
              isOpen={currentTA === "MEMA" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isTVMA) {
        taList.push("TVMA");
        ta.push(
          <div key="TVMA">
            <TVMA
              isOpen={currentTA === "TVMA" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isICH) {
        taList.push("ICH");
        ta.push(
          <div key="ICH">
            <ICH
              isOpen={currentTA === "ICH" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMACD) {
        taList.push("MACD");
        ta.push(
          <div key="MACD">
            <MACD
              isOpen={currentTA === "MACD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isLRT) {
        taList.push("LRT");
        ta.push(
          <div key="LRT">
            <LRT
              isOpen={currentTA === "LRT" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isSAR) {
        taList.push("SAR");
        ta.push(
          <div key="SAR">
            <SAR
              isOpen={currentTA === "SAR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isADXR) {
        taList.push("ADXR");
        ta.push(
          <div key="ADXR">
            <ADXR
              isOpen={currentTA === "ADXR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isDMI) {
        taList.push("DMI");
        ta.push(
          <div key="DMI">
            <DMI
              isOpen={currentTA === "DMI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isBias) {
        taList.push("Bias");
        ta.push(
          <div key="Bias">
            <Bias
              isOpen={currentTA === "Bias" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isALF) {
        taList.push("ALF");
        ta.push(
          <div key="ALF">
            <ALF
              isOpen={currentTA === "ALF" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isBB) {
        taList.push("BB");
        ta.push(
          <div key="BB">
            <BB
              isOpen={currentTA === "BB" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVC) {
        taList.push("VC");
        ta.push(
          <div key="VC">
            <VC
              isOpen={currentTA === "VC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isATR) {
        taList.push("ATR");
        ta.push(
          <div key="ATR">
            <ATR
              isOpen={currentTA === "ATR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isSD) {
        taList.push("SD");
        ta.push(
          <div key="SD">
            <SD
              isOpen={currentTA === "SD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVltyCC) {
        taList.push("VltyCC");
        ta.push(
          <div key="VltyCC">
            <VltyCC
              isOpen={currentTA === "VltyCC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isKRI) {
        taList.push("KRI");
        ta.push(
          <div key="KRI">
            <KRI
              isOpen={currentTA === "KRI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isPL) {
        taList.push("PL");
        ta.push(
          <div key="PL">
            <PL
              isOpen={currentTA === "PL" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isCO) {
        taList.push("CO");
        ta.push(
          <div key="CO">
            <CO
              isOpen={currentTA === "CO" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isRCI) {
        taList.push("RCI");
        ta.push(
          <div key="RCI">
            <RCI
              isOpen={currentTA === "RCI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isFKD) {
        taList.push("FKD");
        ta.push(
          <div key="FKD">
            <FKD
              isOpen={currentTA === "FKD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isSKD) {
        taList.push("SKD");
        ta.push(
          <div key="SKD">
            <SKD
              isOpen={currentTA === "SKD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isCCI) {
        taList.push("CCI");
        ta.push(
          <div key="CCI">
            <CCI
              isOpen={currentTA === "CCI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMI) {
        taList.push("MI");
        ta.push(
          <div key="MI">
            <MI
              isOpen={currentTA === "MI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isUPDW) {
        taList.push("UPDW");
        ta.push(
          <div key="UPDW">
            <UPDW
              isOpen={currentTA === "UPDW" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              cc={props.cc}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isUO) {
        taList.push("UO");
        ta.push(
          <div key="UO">
            <UO
              isOpen={currentTA === "UO" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVector) {
        taList.push("Vector");
        ta.push(
          <div key="Vector">
            <Vector
              isOpen={currentTA === "Vector" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isDPO) {
        taList.push("DPO");
        ta.push(
          <div key="DPO">
            <DPO
              isOpen={currentTA === "DPO" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isWAD) {
        taList.push("WAD");
        ta.push(
          <div key="WAD">
            <WAD
              isOpen={currentTA === "WAD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isTrix) {
        taList.push("Trix");
        ta.push(
          <div key="Trix">
            <Trix
              isOpen={currentTA === "Trix" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVolume) {
        taList.push("Volume");
        ta.push(
          <div key="Volume">
            <Volume
              isOpen={currentTA === "Volume" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              ChgThemeData={props.ChgThemeData}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVE) {
        taList.push("VE");
        ta.push(
          <div key="VE">
            <VE
              isOpen={currentTA === "VE" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVAP) {
        taList.push("VAP");
        ta.push(
          <div key="VAP">
            <VAP
              isOpen={currentTA === "VAP" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVOSC) {
        taList.push("VOSC");
        ta.push(
          <div key="VOSC">
            <VOSC
              isOpen={currentTA === "VOSC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVROC) {
        taList.push("VROC");
        ta.push(
          <div key="VROC">
            <VROC
              isOpen={currentTA === "VROC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isACD) {
        taList.push("ACD");
        ta.push(
          <div key="ACD">
            <ACD
              isOpen={currentTA === "ACD" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isOBV) {
        taList.push("OBV");
        ta.push(
          <div key="OBV">
            <OBV
              isOpen={currentTA === "OBV" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isEOM) {
        taList.push("EOM");
        ta.push(
          <div key="EOM">
            <EOM
              isOpen={currentTA === "EOM" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isFI) {
        taList.push("FI");
        ta.push(
          <div key="FI">
            <FI
              isOpen={currentTA === "FI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMFI) {
        taList.push("MFI");
        ta.push(
          <div key="MFI">
            <MFI
              isOpen={currentTA === "MFI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isNVI) {
        taList.push("NVI");
        ta.push(
          <div key="NVI">
            <NVI
              isOpen={currentTA === "NVI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVR) {
        taList.push("VR");
        ta.push(
          <div key="VR">
            <VR
              isOpen={currentTA === "VR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isENV) {
        taList.push("ENV");
        ta.push(
          <div key="ENV">
            <ENV
              isOpen={currentTA === "ENV" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMOM) {
        taList.push("MOM");
        ta.push(
          <div key="MOM">
            <MOM
              isOpen={currentTA === "MOM" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isRMI) {
        taList.push("RMI");
        ta.push(
          <div key="RMI">
            <RMI
              isOpen={currentTA === "RMI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isRSI) {
        taList.push("RSI");
        ta.push(
          <div key="RSI">
            <RSI
              isOpen={currentTA === "RSI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isWR) {
        taList.push("WR");
        ta.push(
          <div key="WR">
            <WR
              isOpen={currentTA === "WR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isPOSC) {
        taList.push("POSC");
        ta.push(
          <div key="POSC">
            <POSC
              isOpen={currentTA === "POSC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isPROC) {
        taList.push("PROC");
        ta.push(
          <div key="PROC">
            <PROC
              isOpen={currentTA === "PROC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isPRR) {
        taList.push("PRR");
        ta.push(
          <div key="PRR">
            <PRR
              isOpen={currentTA === "PRR" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isVWAP) {
        taList.push("VWAP");
        ta.push(
          <div key="VWAP">
            <VWAP
              isOpen={currentTA === "VWAP" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isWC) {
        taList.push("WC");
        ta.push(
          <div key="WC">
            <WC
              isOpen={currentTA === "WC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isPCV) {
        taList.push("PCV");
        ta.push(
          <div key="PCV">
            <PCV
              isOpen={currentTA === "PCV" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isMargin) {
        taList.push("Margin");
        ta.push(
          <div key="Margin">
            <Margin
              isOpen={currentTA === "Margin" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isTP) {
        taList.push("TP");
        ta.push(
          <div key="TP">
            <TP
              ChgThemeData={props.ChgThemeData}
              isOpen={currentTA === "TP" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isRC) {
        taList.push("RC");
        ta.push(
          <div key="RC">
            <RC
              isOpen={currentTA === "RC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isTurnover) {
        taList.push("Turnover");
        ta.push(
          <div key="Turnover">
            <Turnover
              isOpen={currentTA === "Turnover" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isCC) {
        taList.push("CC");
        ta.push(
          <div key="CC">
            <CC
              isOpen={currentTA === "CC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isKC) {
        taList.push("KC");
        ta.push(
          <div key="KC">
            <KC
              isOpen={currentTA === "KC" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeOverLay={removeOverLay}
              onOverlayValueChange={onOverlayValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
      if (isUI) {
        taList.push("UI");
        ta.push(
          <div key="UI">
            <UI
              isOpen={currentTA === "UI" ? true : false}
              selectedColor={selectedColor}
              source={colorSource}
              removeTA={removeTA}
              onTAValueChange={onTAValueChange}
              onColorClick={onColorPickerOpen}
              isReset={isReset}
              localChartSetting={props.localChartSetting}
            />
          </div>
        );
      }
    } else {
      window.alert("テクニカル分析の最大数はすでに使用されています。");
    }
    return (
      <div>
        {ta.length === 0 && (
          <div className="notasellbl">
            <span>{t("chart5.none")}</span>
          </div>
        )}
        {ta}
      </div>
    );
  };

  useEffect(() => {
    props.onTApaneChange(tapane);
  }, [tapane]);

  const onTAValueChange = (value) => {
    let find = 0;
    let tempTA = [];
    if (tapane.length > 0) {
      tapane.forEach((item, idx) => {
        if (item.ta === value.ta) {
          item.option = value.option;
          item.value = value.value;
          setTapane([...tapane]);
          find = 1;
          return;
        }
      });

      if (find === 0) {
        let c = count;
        c = count + 1;
        setCount(c);
        tempTA = [...tapane, value].sort((a, b) => {
          return sortTAList.indexOf(a.ta) - sortTAList.indexOf(b.ta);
        });
        setTapane(tempTA);
      }
    } else {
      let c = count;
      c = count + 1;
      setCount(c);
      tempTA = [...tapane, value].sort((a, b) => {
        return sortTAList.indexOf(a.ta) - sortTAList.indexOf(b.ta);
      });
      setTapane(tempTA);
    }
  };

  useEffect(() => {
    props.onOverlayChange(overlay);
  }, [overlay]);

  const onOverlayValueChange = (value) => {
    let find = 0;
    let tempOverlay = [];
    if (overlay.length > 0) {
      overlay.forEach((item, idx) => {
        if (item.ta === value.ta) {
          item.option = value.option;
          item.value = value.value;
          setOverlay([...overlay]);
          find = 1;
          return;
        }
      });

      if (find === 0) {
        let c = count;
        c = count + 1;
        setCount(c);
        tempOverlay = [...overlay, value].sort((a, b) => {
          return sortTAList.indexOf(a.ta) - sortTAList.indexOf(b.ta);
        });
        setOverlay(tempOverlay);
      }
    } else {
      let c = count;
      c = count + 1;
      setCount(c);
      tempOverlay = [...overlay, value].sort((a, b) => {
        return sortTAList.indexOf(a.ta) - sortTAList.indexOf(b.ta);
      });
      setOverlay(tempOverlay);
    }
  };

  // ta: string
  const removeOverLay = (ta) => {
    props.onColorPickerClose();
    overlay.forEach((item, idx) => {
      if (item.ta === ta) {
        setOverlay(overlay.filter((item) => item.ta !== ta));
      }
    });
    taList.forEach((item, idx) => {
      if (item === ta) {
        ta.slice(idx, 1);
        taList.splice(idx, 1);
      }
    });

    let c = count;
    c = count - 1;
    setCount(c);

    switch (ta) {
      case "SMA":
        setIsSMA(false);
        break;
      case "WMA":
        setIsWMA(false);
        break;
      case "EMA":
        setIsEMA(false);
        break;
      case "MEMA":
        setIsMEMA(false);
        break;
      case "TVMA":
        setIsTVMA(false);
        break;
      case "ICH":
        setIsICH(false);
        break;
      case "MACD":
        setIsMACD(false);
        break;
      case "LRT":
        setIsLRT(false);
        break;
      case "SAR":
        setIsSAR(false);
        break;
      case "ADXR":
        setIsADXR(false);
        break;
      case "DMI":
        setIsDMI(false);
        break;
      case "Bias":
        setIsBias(false);
        break;
      case "ALF":
        setIsALF(false);
        break;
      case "BB":
        setIsBB(false);
        break;
      case "VC":
        setIsVC(false);
        break;
      case "ATR":
        setIsATR(false);
        break;
      case "SD":
        setIsSD(false);
        break;
      case "VltyCC":
        setIsVltyCC(false);
        break;
      case "KRI":
        setIsKRI(false);
        break;
      case "PL":
        setIsPL(false);
        break;
      case "CO":
        setIsCO(false);
        break;
      case "RCI":
        setIsRCI(false);
        break;
      case "FKD":
        setIsFKD(false);
        break;
      case "SKD":
        setIsSKD(false);
        break;
      case "CCI":
        setIsCCI(false);
        break;
      case "MI":
        setIsMI(false);
        break;
      case "UDPW":
        setIsUPDW(false);
        break;
      case "UO":
        setIsUO(false);
        break;
      case "Vector":
        setIsVector(false);
        break;
      case "DPO":
        setIsDPO(false);
        break;
      case "WAD":
        setIsWAD(false);
        break;
      case "Trix":
        setIsTrix(false);
        break;
      case "Volume":
        setIsVolume(false);
        break;
      case "VE":
        setIsVE(false);
        break;
      case "VAP":
        setIsVAP(false);
        break;
      case "VOSC":
        setIsVOSC(false);
        break;
      case "VROC":
        setIsVROC(false);
        break;
      case "ACD":
        setIsACD(false);
        break;
      case "OBV":
        setIsOBV(false);
        break;
      case "EOM":
        setIsEOM(false);
        break;
      case "FI":
        setIsFI(false);
        break;
      case "MFI":
        setIsMFI(false);
        break;
      case "NVI":
        setIsNVI(false);
        break;
      case "VR":
        setIsVR(false);
        break;
      case "ENV":
        setIsENV(false);
        break;
      case "MOM":
        setIsMOM(false);
        break;
      case "RMI":
        setIsRMI(false);
        break;
      case "RSI":
        setIsRSI(false);
        break;
      case "WR":
        setIsWR(false);
        break;
      case "POSC":
        setIsPOSC(false);
        break;
      case "PROC":
        setIsPROC(false);
        break;
      case "PRR":
        setIsPRR(false);
        break;
      case "VWAP":
        setIsVWAP(false);
        break;
      case "WC":
        setIsWC(false);
        break;
      case "PCV":
        setIsPCV(false);
        break;
      case "Margin":
        setIsMargin(false);
        break;
      case "TP":
        setIsTP(false);
        break;
      case "RC":
        setIsRC(false);
        break;
      case "Turnover":
        setIsTurnover(false);
        break;
      case "CC":
        setIsCC(false);
        break;
      case "KC":
        setIsKC(false);
        break;
      case "UI":
        setIsUI(false);
        break;
    }
  };

  // ta: string
  const removeTA = (ta) => {
    props.onColorPickerClose();
    tapane.forEach((item, idx) => {
      if (item.ta === ta) {
        setTapane(tapane.filter((item) => item.ta !== ta));
      }
    });
    taList.forEach((item, idx) => {
      if (item === ta) {
        ta.slice(idx, 1);
        taList.splice(idx, 1);
      }
    });

    let c = count;
    c = count - 1;
    setCount(c);

    switch (ta) {
      case "SMA":
        setIsSMA(false);
        break;
      case "WMA":
        setIsWMA(false);
        break;
      case "EMA":
        setIsEMA(false);
        break;
      case "MEMA":
        setIsMEMA(false);
        break;
      case "TVMA":
        setIsTVMA(false);
        break;
      case "ICH":
        setIsICH(false);
        break;
      case "MACD":
        setIsMACD(false);
        break;
      case "LRT":
        setIsLRT(false);
        break;
      case "SAR":
        setIsSAR(false);
        break;
      case "ADXR":
        setIsADXR(false);
        break;
      case "DMI":
        setIsDMI(false);
        break;
      case "Bias":
        setIsBias(false);
        break;
      case "ALF":
        setIsALF(false);
        break;
      case "BB":
        setIsBB(false);
        break;
      case "VC":
        setIsVC(false);
        break;
      case "ATR":
        setIsATR(false);
        break;
      case "SD":
        setIsSD(false);
        break;
      case "VltyCC":
        setIsVltyCC(false);
        break;
      case "KRI":
        setIsKRI(false);
        break;
      case "PL":
        setIsPL(false);
        break;
      case "CO":
        setIsCO(false);
        break;
      case "RCI":
        setIsRCI(false);
        break;
      case "FKD":
        setIsFKD(false);
        break;
      case "SKD":
        setIsSKD(false);
        break;
      case "CCI":
        setIsCCI(false);
        break;
      case "MI":
        setIsMI(false);
        break;
      case "UPDW":
        setIsUPDW(false);
        break;
      case "UO":
        setIsUO(false);
        break;
      case "Vector":
        setIsVector(false);
        break;
      case "DPO":
        setIsDPO(false);
        break;
      case "WAD":
        setIsWAD(false);
        break;
      case "Trix":
        setIsTrix(false);
        break;
      case "Volume":
        setIsVolume(false);
        break;
      case "VE":
        setIsVE(false);
        break;
      case "VAP":
        setIsVAP(false);
        break;
      case "VOSC":
        setIsVOSC(false);
        break;
      case "VROC":
        setIsVROC(false);
        break;
      case "ACD":
        setIsACD(false);
        break;
      case "OBV":
        setIsOBV(false);
        break;
      case "EOM":
        setIsEOM(false);
        break;
      case "FI":
        setIsFI(false);
        break;
      case "MFI":
        setIsMFI(false);
        break;
      case "NVI":
        setIsNVI(false);
        break;
      case "VR":
        setIsVR(false);
        break;
      case "ENV":
        setIsENV(false);
        break;
      case "MOM":
        setIsMOM(false);
        break;
      case "RMI":
        setIsRMI(false);
        break;
      case "RSI":
        setIsRSI(false);
        break;
      case "WR":
        setIsWR(false);
        break;
      case "POSC":
        setIsPOSC(false);
        break;
      case "PROC":
        setIsPROC(false);
        break;
      case "PRR":
        setIsPRR(false);
        break;
      case "VWAP":
        setIsVWAP(false);
        break;
      case "WC":
        setIsWC(false);
        break;
      case "PCV":
        setIsPCV(false);
        break;
      case "Margin":
        setIsMargin(false);
        break;
      case "TP":
        setIsTP(false);
        break;
      case "RC":
        setIsRC(false);
        break;
      case "Turnover":
        setIsTurnover(false);
        break;
      case "CC":
        setIsCC(false);
        break;
      case "KC":
        setIsKC(false);
        break;
      case "UI":
        setIsUI(false);
        break;
    }
  };

  //Compare
  const [result, setResult] = useState([]);
  const onCompareTypeChange = (e) => {
    setCompareType(e.target.value);
    setShowMsg(false);
    switch (e.target.value) {
      case "EQTY":
        setIsEQTY(true);
        setIsEQTYIDX(false);
        setIsFUTOPT(false);
        setIsFX(false);
        setIsGBND(false);
        setIsCOMM(false);
        setIsSECTOR(false);
        setCompare({ name: "", code: "", value: "" });
        break;
      case "EQTYIDX":
        setIsEQTY(false);
        setIsEQTYIDX(true);
        setIsFUTOPT(false);
        setIsFX(false);
        setIsGBND(false);
        setIsCOMM(false);
        setIsSECTOR(false);
        setCompare(CompareList.RIC[0]);
        break;
      case "FUTOPT":
        setIsEQTY(false);
        setIsEQTYIDX(false);
        setIsFUTOPT(true);
        setIsFX(false);
        setIsGBND(false);
        setIsCOMM(false);
        setIsSECTOR(false);
        setCompare(CompareList.FUTOPT[0]);
        break;
      case "FX":
        setIsFX(false);
        setIsEQTY(false);
        setIsEQTYIDX(false);
        setIsFUTOPT(false);
        setIsFX(true);
        setIsGBND(false);
        setIsCOMM(false);
        setIsSECTOR(false);
        setCompare(CompareList.FX[0]);
        break;
      case "GBND":
        setIsGBND(false);
        setIsEQTY(false);
        setIsEQTYIDX(false);
        setIsFUTOPT(false);
        setIsFX(false);
        setIsGBND(true);
        setIsCOMM(false);
        setIsSECTOR(false);
        setCompare(CompareList.GBND[0]);
        break;
      case "COMM":
        setIsCOMM(false);
        setIsEQTY(false);
        setIsEQTYIDX(false);
        setIsFUTOPT(false);
        setIsFX(false);
        setIsGBND(false);
        setIsCOMM(true);
        setIsSECTOR(false);
        setCompare(CompareList.COMM[0]);
        break;
      case "SECTOR":
        setIsEQTY(false);
        setIsEQTYIDX(false);
        setIsFUTOPT(false);
        setIsFX(false);
        setIsGBND(false);
        setIsCOMM(false);
        setIsSECTOR(true);
        setCompare(CompareList.SECTOR[0]);
        break;
    }
  };

  const onCompareThicknessChange = (e) => {
    setCompareThickness(e.target.value);
  };

  const onCompareStyleChange = (e) => {
    setCompareStyle(e.target.value);
  };

  // e: any, value: string
  const onComparePowerSearch = (e, value) => {
    props.onComparePowerSearch(e, value, "compare");
  };

  useEffect(() => {
    setResult(props.powerSearchResult);
  }, [props.powerSearchResult]);

  useEffect(() => {
    if (compares.length > 0) {
      setIsCompareEmpty(false);
    } else {
      setIsCompareEmpty(true);
    }
    props.onAddComparisonClick(compares);
  }, [compares]);

  const renderCompare = () => {
    let compareList = [];
    compares.forEach((item, idx) => {
      compareList.push(
        <div key={idx}>
          <CompareBox
            selectedColor={selectedColor}
            source={colorSource}
            showMsg={props.showMsg}
            cc={item.compare.flag}
            item={item.compare}
            color={item.option.color}
            thickness={item.option.thickness}
            style={item.option.style}
            onRemoveCompareClick={onRemoveCompareClick}
            onCompareBoxStyleChange={onCompareBoxStyleChange}
            onColorClick={onColorPickerOpen}
          />
        </div>
      );
    });

    return compareList;
  };

  const onCompareBoxStyleChange = (compare) => {
    if (compares.length > 0) {
      compares.forEach((item, idx) => {
        if (item.compare.value === compare.code) {
          item.option.color = compare.color;
          item.option.thickness = compare.thickness;
          item.option.style = compare.style;
          setCompares([...compares]);
          return;
        }
      });
    }
  };

  let [defaultColor, setDefaultColor] = useState([
    { color: "AA4433", isUse: true },
    { color: "88AA44", isUse: false },
    { color: "665588", isUse: false },
    { color: "4499AA", isUse: false },
    { color: "DD8833", isUse: false },
    { color: "556655", isUse: false },
    { color: "CC8888", isUse: false },
    { color: "4488BB", isUse: false },
  ]);

  const onRemoveCompareClick = (compare) => {
    //console.log(compare);
    compares.forEach((item, idx) => {
      if (item.compare.value === compare.value) {
        setCompares(
          compares.filter((item) => item.compare.value !== compare.value)
        );
        if (compares.length - 1 === 0) {
          setShowCompare(false);
        }

        for (let i = 0; i < defaultColor.length; i++) {
          if (defaultColor[i].color === item.option.color) {
            defaultColor[i].isUse = false;
            return;
          }
        }

        for (let i = 0; i < defaultColor.length; i++) {
          if (defaultColor[i].isUse === false) {
            defaultColor[i].isUse = true;
            setCompareColor(defaultColor[i].color);
            return;
          }
        }
      }
    });
  };

  const onAddComparisonClick = () => {
    let find = 0;
    compares.forEach((item) => {
      if (item.compare.value === compare.value) {
        window.alert("この銘柄は既に登録されています");
        find = 1;
      }
    });

    if (find === 0)
      if (compare !== undefined && compare.value !== "") {
        if (props.code !== compare.value) {
          setShowCompare(true);
          let value = {
            compare: compare,
            option: {
              color: compareColor,
              thickness: compareThickness,
              style: compareStyle,
            },
          };

          if (compares.length < 7) {
            setIsCompareEmpty(false);
            setCompares([...compares, value]);
            props.onAddComparisonClick(compares);
            setCode_symbol("");
          } else {
            window.alert("最大数の銘柄が既に追加されています。");
          }

          if (isEQTY) {
            setCompareAddCount(compareAddCount + 1);
            setCode_symbol("");
          }

          for (let i = 0; i < defaultColor.length; i++) {
            if (defaultColor[i].isUse === false) {
              setCompareColor(defaultColor[i].color);
              defaultColor[i].isUse = true;
              return true;
            }
          }
        } else {
          window.alert("これがメインチャート銘柄です。");
        }
      }
  };

  // const onResultClick = (item) => {
  //   setCompare({
  //     name: item.name,
  //     code: item.code,
  //     value: item.ric,
  //     flag: item.cc,
  //   });
  //   setResult([]);
  // };

  const onCompareSelectChange = (code) => {
    setCompare(code);
  };

  //Generate function
  const setColor = () => {
    switch (colorSource) {
      case "line":
        setLineColor(props.selectedColor);
        break;
      case "lineFill":
        setLineFillColor(props.selectedColor);
        break;
      case "lineFill1":
        setLineFillColor2(props.selectedColor);
        break;
      case "candlestickUpColor":
        setCandlestickUpColor(props.selectedColor);
        break;
      case "candlestickUpFillColor":
        setCandlestickUpFillColor(props.selectedColor);
        break;
      case "candlestickDownColor":
        setCandlestickDownColor(props.selectedColor);
        break;
      case "candlestickDownFillColor":
        setCandlestickDownFillColor(props.selectedColor);
        break;

      case "histogramUpColor":
        setHistogramUpColor(props.selectedColor);
        break;
      case "histogramUpFillColor":
        setHistogramUpFillColor(props.selectedColor);
        break;
      case "histogramDownColor":
        setHistogramDownColor(props.selectedColor);
        break;
      case "histogramDownFillColor":
        setHistogramDownFillColor(props.selectedColor);
        break;

      case "barColor":
        setBarColor(props.selectedColor);
        break;
      case "fieldTrackColor":
        setFieldTrackColor(props.selectedColor);
        break;
      case "fieldFontColor":
        setFieldFontColor(props.selectedColor);
        break;
      case "backgroundColor":
        setBackgroundColor(props.selectedColor);
        break;
      case "chartBackgroundColor":
        setChartBackgroundColor(props.selectedColor);
        break;
      case "chartLineColor":
        setChartLineColor(props.selectedColor);
        break;
      case "toolTipsColor":
        setToolTipsColor(props.selectedColor);
        break;
      case "crosshairLineColor":
        setCrosshairLineColor(props.selectedColor);
        break;
      case "crosshairPointColor":
        setCrosshairPointColor(props.selectedColor);
        break;
      case "crosshairFontColor":
        setCrosshairFontColor(props.selectedColor);
        break;
      case "compare":
        setCompareColor(props.selectedColor);
        break;
      case "drawLine":
        setDrawLineColor(props.selectedColor);
        break;
      case "drawRetracement":
        setDrawRetracementColor(props.selectedColor);
        break;
      case "drawFan":
        setDrawFanColor(props.selectedColor);
        break;
      case "drawArcs":
        setDrawArcsColor(props.selectedColor);
        break;
      default:
        break;
    }
  };

  // date: Date
  const setSelectedDate = (date) => {
    if (date !== undefined) {
      const dateString =
        date.getFullYear().toString() +
        "/" +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "/" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

      switch (dateSource) {
        case "dayFr":
          setDay_fr_value(dateString);
          setPeriodOption({
            period: "day_free",
            option: { option: "d", fr: dateString, to: day_to_value },
          });
          break;
        case "dayTo":
          setDay_to_value(dateString);
          setPeriodOption({
            period: "day_free",
            option: { option: "d", fr: day_fr_value, to: dateString },
          });
          break;
        case "weekFr":
          setWeek_fr_value(dateString);
          setPeriodOption({
            period: "week_free",
            option: { option: "w", fr: dateString, to: week_to_value },
          });
          break;
        case "weekTo":
          setWeek_to_value(dateString);
          setPeriodOption({
            period: "week_free",
            option: { option: "w", fr: week_fr_value, to: dateString },
          });
          break;
        case "monthFr":
          setMonth_fr_value(dateString);
          setPeriodOption({
            period: "month_free",
            option: { option: "m", fr: dateString, to: month_to_value },
          });
          break;
        case "monthTo":
          setMonth_to_value(dateString);
          setPeriodOption({
            period: "month_free",
            option: { option: "m", fr: month_fr_value, to: dateString },
          });
          break;
      }
    }
  };

  const [isReset, setIsReset] = useState(false);

  //reset
  // type: string
  const onResetClick = (type) => {
    const answer = window.confirm("全てを初期設定に戻します。よろしいですか？");
    if (answer) {
      if (type === "styleSetting" || "ALL") {
        resetSettingStyle(true);
      }
      if (type === "line" || "ALL") {
        resetChartTypeLine();
      }
      if (type === "candlestick" || "ALL") {
        resetChartTypeCandleStick();
      }
      if (type === "bar" || "ALL") {
        resetChartTypeBar();
      }
      if (type === "drawLine" || "ALL") {
        resetDrawLine();
      }
      if (type === "drawRetracement" || "ALL") {
        resetDrawRetracement();
      }
      if (type === "drawFan" || "ALL") {
        resetDrawFan();
      }
      if (type === "drawArcs" || "ALL") {
        resetDrawArcs();
      }
      if (type === "ALL") {
        setIsReset(true);
      }
      resetTAUI();
      restoreAllDefaultType();
      onRemoveTAAndComparion(true);
    }
  };

  const restoreAllDefaultType = () => {
    setChartType("candlestick");

    setDay_fr_value(prev6MonthString);
    setWeek_fr_value(prev1yearString);
    setMonth_fr_value(prev10yearString);
    setDay_to_value(todayString);
    setWeek_to_value(todayString);
    setMonth_to_value(todayString);
    onDayButtonClick("6m");
    setIsDayOpen(true);
    setIsWeekOpen(false);
    setIsMonthOpen(false);
    setPeriod("day");
    //setPeriodOption({ period: "6m", option: "d" });
    setShowMainTracker(true);
    setShowTa(true);
    setShowCompare(false);
    setShowDrawTool(true);
    setShowPriceTracker(true);
    setShowScrollView(true);
    setShowCrosshair(true);
    setShowTip(false);
    setDrawTool("move");
    setShowEvent(false);
    setIsS(true);
    setIsC(true);
    setIsD(true);

    props.resetAnnotation();
  };

  const resetChartTypeLine = () => {
    setShowTracker(false);
    setLineGradientEnable(false);
    setLineThickness(2);
    setLineStyle("s");
    setLineColor(props.ChgThemeData === "Light" ? "3366CC" : "F8F800");
    setLineFillColor("99CCFF");
    setLineFillColor2("FFFFFF");
  };

  const resetChartTypeCandleStick = () => {
    setCandlestickUpColor("39C46E");
    setCandlestickUpFillColor("39C46E");
    setCandlestickDownColor("F5475B");
    setCandlestickDownFillColor("F5475B");
  };

  const resetChartTypeHistogram = () => {
    setHistogramUpColor("39C46E");
    setHistogramUpFillColor("39C46E");
    setHistogramDownColor("F5475B");
    setHistogramDownFillColor("F5475B");
  };

  const resetChartTypeBar = () => {
    setBarColor("0033CC");
  };

  const resetDrawLine = () => {
    setDrawLineColor("0080FF");
    setDrawLineThickness(2);
    setDrawLineStyle("s");
    props.setSelectedColor("");
  };

  const resetDrawRetracement = () => {
    setDrawRetracementColor("0080FF");
    setDrawRetracementThickness(2);
    setDrawRetracementStyle("s");
    props.setSelectedColor("");
  };

  const resetDrawFan = () => {
    setDrawFanColor("0080FF");
    setDrawFanThickness(2);
    setDrawFanStyle("s");
    props.setSelectedColor("");
  };

  const resetDrawArcs = () => {
    setIsDrawArcsOpen(false);
    setDrawArcsColor("0080FF");
    setDrawArcsThickness(2);
    setDrawArcsStyle("s");
    props.setSelectedColor("");
  };

  const isPeriodOn = () => {
    return !(
      // isEqual(periodOption, { period: "1min", option: "" }) ||
      // isEqual(periodOption, { period: "15mins5d", option: "" }) ||
      // isEqual(periodOption, { period: "1m", option: "d" }) ||
      // isEqual(periodOption, { period: "3m", option: "d" }) ||
      // isEqual(periodOption, { period: "1y", option: "m" }) ||
      // isEqual(periodOption, { period: "5y", option: "m" })
      (
        isEqual(periodOption, { period: "1min", option: "" }) ||
        isEqual(periodOption, { period: "6m", option: "d" }) ||
        isEqual(periodOption, { period: "1y", option: "w" }) ||
        isEqual(periodOption, { period: "5y", option: "m" }) ||
        isEqual(periodOption, { period: "5mins", option: "" }) ||
        isEqual(periodOption, { period: "15mins", option: "" })
      )
    );
  };

  const isShowReset = () => {
    return tapane.length || overlay.length ? true : false;
  };

  const loadTAList = async (setting) => {
    const localTapane = periodOption.period === "1min" ? setting["tapane-1min"] : setting.tapane;
    const localOverlay = periodOption.period === "1min" ? setting["overlay-1min"] : setting.overlay;

    const loadMenu = async () => {
      localTapane.map((ta) => { enableTA(ta.ta) });
      localOverlay.map((ta) => { enableTA(ta.ta) });
    }

    await loadMenu();
    // props.onTApaneChange(localTapan);
    // props.onOverlayChange(localOverlay);
    setTapane(localTapane);
    setOverlay(localOverlay);
  }

  const enableTA = (ta) => {
    switch (ta) {
      case "SMA":
        setIsSMA(true);
        break;
      case "WMA":
        setIsWMA(true);
        break;
      case "EMA":
        setIsEMA(true);
        break;
      case "MEMA":
        setIsMEMA(true);
        break;
      case "TVMA":
        setIsTVMA(true);
        break;
      case "ICH":
        setIsICH(true);
        break;
      case "MACD":
        setIsMACD(true);
        break;
      case "LRT":
        setIsLRT(true);
        break;
      case "SAR":
        setIsSAR(true);
        break;
      case "ADXR":
        setIsADXR(true);
        break;
      case "DMI":
        setIsDMI(true);
        break;
      case "Bias":
        setIsBias(true);
        break;
      case "ALF":
        setIsALF(true);
        break;
      case "BB":
        setIsBB(true);
        break;
      case "VC":
        setIsVC(true);
        break;
      case "ATR":
        setIsATR(true);
        break;
      case "SD":
        setIsSD(true);
        break;
      case "VltyCC":
        setIsVltyCC(true);
        break;
      case "KRI":
        setIsKRI(true);
        break;
      case "PL":
        setIsPL(true);
        break;
      case "CO":
        setIsCO(true);
        break;
      case "RCI":
        setIsRCI(true);
        break;
      case "FKD":
        setIsFKD(true);
        break;
      case "SKD":
        setIsSKD(true);
        break;
      case "CCI":
        setIsCCI(true);
        break;
      case "MI":
        setIsMI(true);
        break;
      case "UDPW":
        setIsUPDW(true);
        break;
      case "UO":
        setIsUO(true);
        break;
      case "Vector":
        setIsVector(true);
        break;
      case "DPO":
        setIsDPO(true);
        break;
      case "WAD":
        setIsWAD(true);
        break;
      case "Trix":
        setIsTrix(true);
        break;
      case "Volume":
        setIsVolume(true);
        break;
      case "VE":
        setIsVE(true);
        break;
      case "VAP":
        setIsVAP(true);
        break;
      case "VOSC":
        setIsVOSC(true);
        break;
      case "VROC":
        setIsVROC(true);
        break;
      case "ACD":
        setIsACD(true);
        break;
      case "OBV":
        setIsOBV(true);
        break;
      case "EOM":
        setIsEOM(true);
        break;
      case "FI":
        setIsFI(true);
        break;
      case "MFI":
        setIsMFI(true);
        break;
      case "NVI":
        setIsNVI(true);
        break;
      case "VR":
        setIsVR(true);
        break;
      case "ENV":
        setIsENV(true);
        break;
      case "MOM":
        setIsMOM(true);
        break;
      case "RMI":
        setIsRMI(true);
        break;
      case "RSI":
        setIsRSI(true);
        break;
      case "WR":
        setIsWR(true);
        break;
      case "POSC":
        setIsPOSC(true);
        break;
      case "PROC":
        setIsPROC(true);
        break;
      case "PRR":
        setIsPRR(true);
        break;
      case "VWAP":
        setIsVWAP(true);
        break;
      case "WC":
        setIsWC(true);
        break;
      case "PCV":
        setIsPCV(true);
        break;
      case "Margin":
        setIsMargin(true);
        break;
      case "TP":
        setIsTP(true);
        break;
      case "RC":
        setIsRC(true);
        break;
      case "Turnover":
        setIsTurnover(true);
        break;
      case "CC":
        setIsCC(true);
        break;
      case "KC":
        setIsKC(true);
        break;
      case "UI":
        setIsUI(true);
        break;
    }
  }

  return (
    <>
      {/* bg */}
      <div
        className={"menu-bg-close"}
        style={type !== "menu" ? { display: "block" } : { display: "none" }}
        onClick={() => {
          setType("menu");
        }}
      ></div>
      <div
        className={[
          isOpen ? "menubox" : "menubox open",
          props.isTablet || props.isMobileViewInSector ? "tablet" : "",
          props.isTablet || props.isMobileViewInSector ? "mobile" : "", // add tablet become mobile and remain tablet class to safe the css change of tablet mode
          props.isMobile || props.isMobileViewInSector ? "mobile" : "",
          props.ChgThemeData === "Light" ? "light" : "",
        ].join(" ")}
        style={{
          display:
            // props.isMobile || props.isMobileViewInSector ? "block" : "flex",
            "flex",
        }}
      >
        {/* <div className={"openmenuhandle"} onClick={openMenu}>
          <div className="openmenuicon">
            <Tooltip
              styles={{
                whiteSpace: "nowrap",
                textAlgin: "center",
                display: "flex",
                justifyContent: "center",
              }}
              className="open-tooltip"
              tagName="span"
              content={isOpen ? "メニューを表示" : "メニューを非表示"}
              direction="down-start"
              arrow={false}
            >
              <div
                style={{
                  width: 48,
                  height: 48,
                  backgroundImage: "none",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              ></div>
            </Tooltip>
          </div>
        </div> */}

        {/* Period */}
        <div
          className={[
            "flex-icon-container",
            "left",
            props.isTablet || props.isMobileViewInSector ? "mobile" : "",
            props.isMobile || props.isMobileViewInSector ? "mobile" : "",
          ].join(" ")}
        >
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              isEqual(periodOption, { period: "1min", option: "" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              onPeriodChange(e, "1min");
              setType("menu");
            }}
          >
            {t("chart5.1D")}
          </div>
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              // isEqual(periodOption, { period: "15mins5d", option: "" })
              isEqual(periodOption, { period: "6m", option: "d" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              // onPeriodChange(e, "15mins5d");
              onDayButtonClick("6m");
              setType("menu");
            }}
          >
            {/* {t("chart5.5D")} */}
            {t("chart5.DK")}
          </div>
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              // isEqual(periodOption, { period: "1m", option: "d" })
              isEqual(periodOption, { period: "1y", option: "w" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              onWeekButtonClick("1y");
              setType("menu");
            }}
          >
            {/* {t("chart5.1M")} */}
            {t("chart5.WK")}
          </div>
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              // isEqual(periodOption, { period: "3m", option: "d" })
              isEqual(periodOption, { period: "5y", option: "m" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              onMonthButtonClick("5y");
              setType("menu");
            }}
          >
            {/* {t("chart5.3M")} */}
            {t("chart5.MK")}
          </div>
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              // isEqual(periodOption, { period: "1y", option: "m" })
              isEqual(periodOption, { period: "5mins", option: "" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              // onMonthButtonClick("1y");
              onPeriodChange(e, "5mins");
              setType("menu");
            }}
          >
            {/* {t("chart5.1Y")} */}
            {t("chart5.5mins")}
          </div>
          <div
            className={[
              "period-button",
              props.ChgLangData === "zh-CN" ? "chinese" : "",
              // isEqual(periodOption, { period: "5y", option: "m" })
              isEqual(periodOption, { period: "15mins", option: "" })
                ? "selected"
                : "",
            ].join(" ")}
            onClick={(e) => {
              // onMonthButtonClick("5y");
              onPeriodChange(e, "15mins");
              setType("menu");
            }}
          >
            {/* {t("chart5.5Y")} */}
            {t("chart5.15mins")}
          </div>
          <div
            className={[
              "menuitem",
              props.ChgThemeData === "Light" ? "light" : "",
            ].join(" ")}
            ref={periodRef}
            id={
              type === "period" || isPeriodOn() ? "mi-period-on" : "mi-period"
            }
            onClick={(e) => onMenuClick(e, "period")}
          >
            {/* <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"期間指定"}
              direction="down-start"
              arrow={false}
            > */}
            <div
              style={{
                display: isOpen ? "none" : "inline-block",
                width: 47,
                height: 48,
                backgroundImage: "none",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              ref={periodRef}
              id={
                type === "period" || isPeriodOn() ? "mi-period-on" : "mi-period"
              }
              onClick={(e) => onMenuClick(e, "period")}
            ></div>
            {/* </Tooltip> */}
            <div
              className="menudropdown"
              style={
                type === "period"
                  ? {
                    maxHeight: "300px",
                    width:
                      props.isTablet || props.isMobileViewInSector
                        ? "calc(100% + 32px)"
                        : "318px",
                    top:
                      props.isMobile || props.isMobileViewInSector
                        ? "40px"
                        : undefined,
                    left:
                      props.isTablet || props.isMobileViewInSector
                        ? "-16px"
                        : 0,
                  }
                  : { display: "none" }
              }
            >
              {!props.isTablet &&
                !(props.isMobile || props.isMobileViewInSector) && (
                  <>
                    <div
                      className={[
                        "menusubitem msiaction radiogroup",
                        isEqual(periodOption, { period: "1min", option: "" })
                          ? "selected"
                          : "",
                      ].join(" ")}
                      id="msi-int"
                      onClick={(e) => onPeriodChange(e, "1min")}
                    >
                      {/* <div
                  className={
                    period === "1min" ? "radiobutton-selected" : "radiobutton"
                  }
                  onClick={(e) => onPeriodChange(e, "1min")}
                ></div> */}
                      <span>{t("chart5.1min")}</span>
                    </div>
                    <div
                      className={[
                        "menusubitem msiaction radiogroup",
                        isEqual(periodOption, { period: "5mins", option: "" })
                          ? "selected"
                          : "",
                      ].join(" ")}
                      id="msi-int"
                      onClick={(e) => onPeriodChange(e, "5mins")}
                    >
                      {/* <div
                  className={
                    period === "5mins" ? "radiobutton-selected" : "radiobutton"
                  }
                  onClick={(e) => onPeriodChange(e, "1min")}
                ></div> */}
                      <span>{t("chart5.5min")}</span>
                    </div>
                    <div
                      className={[
                        "menusubitem msiaction radiogroup",
                        isEqual(periodOption, { period: "15mins", option: "" })
                          ? "selected"
                          : "",
                      ].join(" ")}
                      id="msi-int"
                      onClick={(e) => onPeriodChange(e, "15mins")}
                    >
                      {/* <div
                  className={
                    period === "15mins" ? "radiobutton-selected" : "radiobutton"
                  }
                  onClick={(e) => onPeriodChange(e, "1min")}
                ></div> */}
                      <span>{t("chart5.15min")}</span>
                    </div>
                    <div
                      className={[
                        "menusubitem msiaction radiogroup",
                        isEqual(periodOption, { period: "1hour", option: "" })
                          ? "selected"
                          : "",
                      ].join(" ")}
                      id="msi-int"
                      onClick={(e) => onPeriodChange(e, "1hour")}
                    >
                      {/* <div
                  className={
                    period === "1hour" ? "radiobutton-selected" : "radiobutton"
                  }
                  onClick={(e) => onPeriodChange(e, "1min")}
                ></div> */}
                      <span>{t("chart5.1h")}</span>
                    </div>
                  </>
                )}
              {(props.isTablet ||
                props.isMobile ||
                props.isMobileViewInSector) && (
                  <div
                    className="menusubitem msiaction msi-optbox special-period-box"
                    style={{ height: "72px" }}
                  >
                    <div
                      className="menusubitem msiaction msi-box"
                      style={
                        isEqual(periodOption, { period: "1min", option: "" })
                          ? { backgroundColor: "#008FC7", borderColor: "#008FC7" }
                          : {}
                      }
                      id="msi-period"
                      onClick={(e) => onPeriodChange(e, "1min")}
                    >
                      <span>{t("chart5.1min")}</span>
                    </div>
                    <div
                      className="menusubitem msiaction msi-box"
                      style={
                        isEqual(periodOption, { period: "5mins", option: "" })
                          ? { backgroundColor: "#008FC7", borderColor: "#008FC7" }
                          : {}
                      }
                      id="msi-period"
                      onClick={(e) => onPeriodChange(e, "5mins")}
                    >
                      <span>{t("chart5.5min")}</span>
                    </div>
                    <div
                      className="menusubitem msiaction msi-box"
                      style={
                        isEqual(periodOption, { period: "15mins", option: "" })
                          ? { backgroundColor: "#008FC7", borderColor: "#008FC7" }
                          : {}
                      }
                      id="msi-period"
                      onClick={(e) => onPeriodChange(e, "15mins")}
                    >
                      <span>{t("chart5.15min")}</span>
                    </div>
                    <div
                      className="menusubitem msiaction msi-box"
                      style={
                        isEqual(periodOption, { period: "1hour", option: "" })
                          ? { backgroundColor: "#008FC7", borderColor: "#008FC7" }
                          : {}
                      }
                      id="msi-period"
                      onClick={(e) => onPeriodChange(e, "1hour")}
                    >
                      <span>{t("chart5.1h")}</span>
                    </div>
                  </div>
                )}
              <div className="msi-sep"></div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-int"
                onClick={(e) => onPeriodChange(e, "day")}
              >
                {/* <div
                  className={
                    period === "day" ? "radiobutton-selected" : "radiobutton"
                  }
                ></div> */}
                <span
                  className={[
                    "span",
                    isEqual(periodOption.option, "d") || day_free
                      ? "selected"
                      : "",
                  ].join(" ")}
                >
                  {t("chart5.daily")}
                </span>
                <div
                  className="optboxarrow"
                  style={isDayOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="msi-optbox"
                  style={isDayOpen ? { height: "150px" } : { display: "none" }}
                >
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("m")}
                  >
                    <span>{t("chart5.mtd")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_1month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("1m")}
                  >
                    <span>{t("chart5.1m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_2month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("2m")}
                  >
                    <span>{t("chart5.2m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_3month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("3m")}
                  >
                    <span>{t("chart5.3m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_6month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("6m")}
                  >
                    <span>{t("chart5.6m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("y")}
                  >
                    <span>{t("chart5.ytd")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_1year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("1y")}
                  >
                    <span>{t("chart5.1y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      day_2year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onDayButtonClick("2y")}
                  >
                    <span>{t("chart5.2y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box date-input-container"
                    id="msi-period"
                    style={{
                      clear: "left",
                    }}
                    onClick={() => onDayButtonClick("day_free")}
                  >
                    <span
                      style={
                        day_free
                          ? {
                            backgroundColor: "#008FC7",
                            borderColor: "#008FC7",
                            color: "#FFFFFF",
                          }
                          : {}
                      }
                    >
                      {t("chart5.custom")}
                    </span>
                    {/* <input value="hi" /> */}
                    <div className="customdaterange">
                      <div className="inputbox dateinputbox fromdate">
                        <input
                          // id="msi-int-d-period-c-fromdate"
                          type="text"
                          // className="dateinput hasDatepicker"
                          value={day_fr_value}
                          onChange={(e) => onDateChange(e, "dayFr")}
                          onClick={() =>
                            onDateClick(day_fr_value, "d", "dayFr")
                          }
                          style={day_free ? { color: "#333333" } : {}}
                        />
                      </div>
                      <span>{t("chart5.to")}</span>
                      <div className="inputbox dateinputbox todate">
                        <input
                          id="msi-int-d-period-c-todate"
                          className="dateinput hasDatepicker"
                          value={day_to_value}
                          onChange={(e) => onDateChange(e, "dayTo")}
                          onClick={() =>
                            onDateClick(day_to_value, "d", "dayTo")
                          }
                          style={day_free ? { color: "#333333" } : {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-int"
                onClick={(e) => onPeriodChange(e, "week")}
              >
                {/* <div
                  className={
                    period === "week" ? "radiobutton-selected" : "radiobutton"
                  }
                ></div> */}
                <span
                  className={[
                    "span",
                    isEqual(periodOption.option, "w") || week_free
                      ? "selected"
                      : "",
                  ].join(" ")}
                >
                  {t("chart5.weekly")}
                </span>
                <div
                  className="optboxarrow"
                  style={isWeekOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="msi-optbox"
                  style={isWeekOpen ? { height: "200px" } : { display: "none" }}
                >
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_1month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("1m")}
                  >
                    <span>{t("chart5.1m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_2month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("2m")}
                  >
                    <span>{t("chart5.2m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_3month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("3m")}
                  >
                    <span>{t("chart5.3m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_6month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("6m")}
                  >
                    <span>{t("chart5.6m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("y")}
                  >
                    <span>{t("chart5.ytd")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_1year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("1y")}
                  >
                    <span>{t("chart5.1y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_2year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("2y")}
                  >
                    <span>{t("chart5.2y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_3year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("3y")}
                  >
                    <span>{t("chart5.3y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_4year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("4y")}
                  >
                    <span>{t("chart5.4y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      week_5year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onWeekButtonClick("5y")}
                  >
                    <span>{t("chart5.5y")}</span>
                  </div>
                  <div className="menusubitem" style={{ width: 55 }}></div>
                  <div className="menusubitem" style={{ width: 55 }}></div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box date-input-container"
                    id="msi-period"
                    style={{
                      clear: "left",
                    }}
                    onClick={() => onWeekButtonClick("week_free")}
                  >
                    <span
                      style={
                        week_free
                          ? {
                            backgroundColor: "#008FC7",
                            borderColor: "#008FC7",
                            color: "#FFFFFF",
                          }
                          : {}
                      }
                    >
                      {t("chart5.custom")}
                    </span>
                    <div className="customdaterange">
                      <div className="inputbox dateinputbox fromdate">
                        <input
                          id="msi-int-w-period-c-fromdate"
                          className="dateinput hasDatepicker"
                          value={week_fr_value}
                          onChange={(e) => onDateChange(e, "weekFr")}
                          onClick={() =>
                            onDateClick(week_fr_value, "w", "weekFr")
                          }
                          style={week_free ? { color: "#333333" } : {}}
                        />
                      </div>
                      <span>{t("chart5.to")}</span>
                      <div className="inputbox dateinputbox todate">
                        <input
                          id="msi-int-w-period-c-todate"
                          className="dateinput hasDatepicker"
                          value={week_to_value}
                          onChange={(e) => onDateChange(e, "weekTo")}
                          onClick={() =>
                            onDateClick(week_to_value, "w", "weekTo")
                          }
                          style={week_free ? { color: "#333333" } : {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-int"
                onClick={(e) => onPeriodChange(e, "month")}
              >
                {/* <div
                  className={
                    period === "month" ? "radiobutton-selected" : "radiobutton"
                  }
                ></div> */}
                <span
                  className={[
                    "span",
                    isEqual(periodOption.option, "m") || month_free
                      ? "selected"
                      : "",
                  ].join(" ")}
                >
                  {t("chart5.monthly")}
                </span>
                <div
                  className="optboxarrow"
                  style={isMonthOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="msi-optbox"
                  style={
                    isMonthOpen ? { height: "150px" } : { display: "none" }
                  }
                >
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_6month
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("6m")}
                  >
                    <span>{t("chart5.6m")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("y")}
                  >
                    <span>{t("chart5.ytd")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_1year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("1y")}
                  >
                    <span>{t("chart5.1y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_2year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("2y")}
                  >
                    <span>{t("chart5.2y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_3year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("3y")}
                  >
                    <span>{t("chart5.3y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_4year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("4y")}
                  >
                    <span>{t("chart5.4y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_5year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("5y")}
                  >
                    <span>{t("chart5.5y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box"
                    style={
                      month_10year
                        ? {
                          backgroundColor: "#008FC7",
                          borderColor: "#008FC7",
                          color: "#FFFFFF",
                        }
                        : {}
                    }
                    id="msi-period"
                    onClick={() => onMonthButtonClick("10y")}
                  >
                    <span>{t("chart5.10y")}</span>
                  </div>
                  <div
                    className="menusubitem msiaction radiogroup msi-box date-input-container"
                    id="msi-period"
                    style={{
                      clear: "left",
                    }}
                    onClick={() => onMonthButtonClick("month_free")}
                  >
                    <span
                      style={
                        month_free
                          ? {
                            backgroundColor: "#008FC7",
                            borderColor: "#008FC7",
                            color: "#FFFFFF",
                          }
                          : {}
                      }
                    >
                      {t("chart5.custom")}
                    </span>
                    <div className="customdaterange">
                      <div className="inputbox dateinputbox fromdate">
                        <input
                          id="msi-int-m-period-c-fromdate"
                          className="dateinput hasDatepicker"
                          value={month_fr_value}
                          onChange={(e) => onDateChange(e, "monthFr")}
                          onClick={() =>
                            onDateClick(month_fr_value, "m", "monthFr")
                          }
                          style={month_free ? { color: "#333333" } : {}}
                        />
                      </div>
                      <span>{t("chart5.to")}</span>
                      <div className="inputbox dateinputbox todate">
                        <input
                          id="msi-int-m-period-c-todate"
                          className="dateinput hasDatepicker"
                          value={month_to_value}
                          onChange={(e) => onDateChange(e, "monthTo")}
                          onClick={() =>
                            onDateClick(month_to_value, "m", "monthTo")
                          }
                          style={month_free ? { color: "#333333" } : {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* new mobile chart button section */}
          <IconSetting
            className={[
              "menuitem",
              "setting-icon",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? ""
                : "hide",
              type === "chartType" || type === "drawTool" || type === "ta"
                ? "selected"
                : "",
              type === "chartType" || type === "drawTool" || type === "ta"
                ? "shawdow"
                : "",
            ].join(" ")}
            // id={
            //   type === "chartType" || type === "drawTool" || type === "ta"
            //     ? "mi-charttype-on"
            //     : "mi-charttype"
            // }
            style={{ backgroundImage: "none", width: 20, height: 30 }}
            onClick={() => {
              onMenuClick(
                {
                  target: {
                    id:
                      type === "chartType" ? "mi-charttype-on" : "mi-charttype",
                  },
                },
                "chartType"
              );
            }}
          />
          <div
            className={[
              "menuitem menualignright open",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? ""
                : "hide",
            ].join(" ")}
            style={{ width: 20, height: 20 }}
            ref={zoomRef}
            id={props.isChartZoom ? "mi-zoom-on" : "mi-zoom"}
            onClick={() => {
              let elem = document.getElementById("back-mobile");
              if (props.isChartZoom) {
                elem.style.display = "flex";
              } else {
                elem.style.display = "none";
              }
              props.onClickZoom();
            }}
          ></div>
        </div>

        <div
          className={[
            "flex-icon-container",
            "right",
            props.isTablet || props.isMobileViewInSector ? "mobile" : "",
            props.isMobile || props.isMobileViewInSector ? "mobile" : "",
          ].join(" ")}
        >
          {!props.isTablet &&
            !props.isMobile &&
            !props.isMobileViewInSector && (
              <div
                className={[
                  "seperate-line",
                  props.ChgThemeData === "Light" ? "light" : "",
                ].join(" ")}
              ></div>
            )}

          {/* new mobile chart button section */}
          {(props.isTablet || props.isMobile || props.isMobileViewInSector) &&
            (type === "chartType" || type === "drawTool" || type === "ta") && (
              <>
                <div className={["setting-option"].join(" ")}>
                  <div
                    className={[
                      "setting-button",
                      type === "chartType" ? "selected" : "",
                    ].join(" ")}
                    onClick={() => {
                      onMenuClick(
                        {
                          target: {
                            id: "mi-charttype",
                          },
                        },
                        "chartType"
                      );
                    }}
                  >
                    <IconCandlestick />
                    {t("chart5.chart")}
                  </div>
                  <div
                    className={[
                      "setting-button",
                      type === "drawTool" ? "selected" : "",
                    ].join(" ")}
                    onClick={() => {
                      onMenuClick(
                        {
                          target: {
                            id: "mi-drawtool",
                          },
                        },
                        "drawTool"
                      );
                    }}
                  >
                    <IconActionOutlined />
                    {t("chart5.actions")}
                  </div>
                  <div
                    className={[
                      "setting-button",
                      type === "ta" ? "selected" : "",
                    ].join(" ")}
                    onClick={() => {
                      onMenuClick(
                        {
                          target: {
                            id: "mi-ta",
                          },
                        },
                        "ta"
                      );
                    }}
                  >
                    <IconAnalysisOutlined />
                    {t("chart5.technical")}
                  </div>
                </div>
                <div className="hidden-shadow"></div>
                <div className="hidden-icon-shadow"></div>
              </>
            )}
          {/* Chart Type */}
          <div
            className={[
              "menuitem",
              props.ChgThemeData === "Light" ? "light" : "",
              type === "chartType" ? "shawdow" : "",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? "hide"
                : "",
            ].join(" ")}
            ref={chartTypeRef}
            id={type === "chartType" ? "mi-charttype-on" : "mi-charttype"}
            onClick={(e) => onMenuClick(e, "chartType")}
          >
            {/* <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"チャートタイプ"}
              direction="down-start"
              arrow={false}
            > */}
            {/* {props.ChgThemeData === "Light" && type === "chartType" && (
              <div
                className={[
                  "connection-box",
                  props.isTablet || props.isMobileViewInSector ? "mobile" : "",
                ].join(" ")}
              ></div>
            )} */}
            <div
              style={{
                display: isOpen ? "none" : "inline-block",
                width: 47,
                height: 48,
                backgroundImage: "none",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              ref={chartTypeRef}
              id={type === "chartType" ? "mi-charttype-on" : "mi-charttype"}
              onClick={(e) => onMenuClick(e, "chartType")}
            ></div>
            {/* </Tooltip> */}
            <div
              className="menudropdown"
              style={
                type === "chartType"
                  ? {
                    width:
                      props.isTablet || props.isMobileViewInSector
                        ? "calc(100% + 32px)"
                        : "318px",
                    maxHeight: "300px",
                    top:
                      props.isTablet ||
                        props.isMobile ||
                        props.isMobileViewInSector
                        ? "120px"
                        : undefined,
                    left:
                      props.isTablet || props.isMobileViewInSector
                        ? "-16px"
                        : "auto",
                  }
                  : { display: "none" }
              }
            >
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-charttype"
                onClick={(e) => onChartTypeChange(e, "line")}
              >
                {/* <div
                  className={
                    chartType === "line"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon line"
                  // style={{ background: `url(${line}) center center no-repeat` }}
                ></div> */}
                <IconLineLight
                  className={[
                    "icon",
                    chartType === "line" ? "selected" : "",
                  ].join(" ")}
                />
                <span
                  className={[
                    "span",
                    chartType === "line" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => onChartTypeChange(e, "line", true)}
                >
                  {t("chart5.line")}
                </span>
                <div
                  className="optboxarrow"
                  style={isLineOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isLineOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetChartTypeLine}
                ></div>
                <div
                  className="msi-optbox"
                  ref={ctLineRef}
                  style={isLineOpen ? {} : { display: "none" }}
                >
                  <div className="menusubitem" id="msi-charttype-line">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        id="msi-charttype-line-color0"
                        className="colorpicker"
                        value={lineColor}
                        style={{ backgroundColor: "#" + lineColor }}
                        onClick={() => {
                          refIsLineChartColorChange.current = true;
                          onColorPickerOpen(
                            lineColor,
                            "line",
                            t("chart5.lineColor")
                          );
                        }}
                      ></button>
                      <div className="inputbox">
                        <input
                          id="msi-charttype-line-p-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={lineThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) => onLineThicknessChange(e)}
                        />
                      </div>
                      <div className="selectbox">
                        <select
                          id="msi-charttype-line-p-style"
                          onChange={(e) => onLineStyleChange(e)}
                          value={lineStyle}
                          style={{ width: 50 }}
                        >
                          <option value="s">{t("chart5.realLine")}</option>
                          <option value="d">{t("chart5.dotLine")}</option>
                        </select>
                      </div>
                      <div className="showtracker">
                        <div
                          id={"msi-charttype-line-p-showtracker"}
                          className={
                            showTracker
                              ? "checkboxbutton-selected"
                              : "checkboxbutton"
                          }
                          onClick={onShowTracker}
                        ></div>
                        <div className="styleboxlbl">
                          {t("chart5.showValue")}
                        </div>
                      </div>
                    </div>
                    <div className="menusubitem checkbox stylebox selected">
                      <div
                        id="msi-charttype-line-gradient-enabled"
                        className={
                          lineGradientEnable
                            ? "checkboxbutton-selected"
                            : "checkboxbutton"
                        }
                        onClick={onLineGradient}
                      ></div>
                      <div className="styleboxlbl">{t("chart5.gradation")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-line-fill0"
                        value={lineFillColor}
                        style={{ backgroundColor: "#" + lineFillColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            lineFillColor,
                            "lineFill",
                            t("chart5.gradation")
                          )
                        }
                      ></button>
                      <button
                        className="colorpicker"
                        id="msi-charttype-line-fill1"
                        value={lineFillColor2}
                        style={{ backgroundColor: "#" + lineFillColor2 }}
                        onClick={() =>
                          onColorPickerOpen(
                            lineFillColor2,
                            "lineFill1",
                            t("chart5.gradation")
                          )
                        }
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-charttype"
                onClick={(e) => onChartTypeChange(e, "candlestick")}
              >
                {/* <div
                  className={
                    chartType === "candlestick"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon candlestick"
                  // style={{
                  //   background: `url(${candlestick}) center center no-repeat`,
                  // }}
                ></div> */}
                {chartType !== "candlestick" && (
                  <IconCandlestickLight
                    className={[
                      "icon",
                      "candlestick",
                      chartType === "candlestick" ? "selected" : "",
                    ].join(" ")}
                  />
                )}
                {chartType === "candlestick" && (
                  <IconCandlestickBlue
                    className={[
                      "icon",
                      "candlestick",
                      chartType === "candlestick" ? "selected" : "",
                    ].join(" ")}
                    style={{ top: "4px" }}
                  />
                )}
                <span
                  className={[
                    "span",
                    chartType === "candlestick" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => onChartTypeChange(e, "candlestick", true)}
                >
                  {t("chart5.candlestick")}
                </span>
                <div
                  className="optboxarrow"
                  style={
                    isCandlestickOpen ? { transform: "rotate(180deg)" } : {}
                  }
                ></div>
                <div
                  className="b2dbtn"
                  style={isCandlestickOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetChartTypeCandleStick}
                ></div>
                <div
                  className="msi-optbox"
                  ref={ctCandlestickRef}
                  style={isCandlestickOpen ? {} : { display: "none" }}
                >
                  <div className="menusubitem" id="msi-optbox-candlestick">
                    <div
                      className="menusubitem stylebox floatmsibox"
                      id="msi-charttype-candlestick"
                    >
                      <div className="styleboxlbl">{t("chart5.positive")}:</div>
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-candlestick-p-color0"
                        value={candlestickDownColor}
                        style={{ backgroundColor: "#" + candlestickDownColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            candlestickDownColor,
                            "candlestickDownColor",
                            `${t("chart5.positive")}: ${t("chart5.lineColor")}`
                          )
                        }
                      ></button>
                      <div className="styleboxlbl">{t("chart5.fillColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-candlestick-p-fill"
                        value={candlestickDownFillColor}
                        style={{ backgroundColor: "#" + candlestickDownFillColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            candlestickDownFillColor,
                            "candlestickDownFillColor",
                            `${t("chart5.positive")}: ${t("chart5.fillColor")}`
                          )
                        }
                      ></button>
                    </div>
                    <div
                      className="menusubitem stylebox floatmsibox"
                      id="msi-charttype-candlestick"
                    >
                      <div className="styleboxlbl">{t("chart5.negative")}:</div>
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-candlestick-n-color0"
                        value={candlestickUpColor}
                        style={{ backgroundColor: "#" + candlestickUpColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            candlestickUpColor,
                            "candlestickUpColor",
                            `${t("chart5.negative")}: ${t("chart5.lineColor")}`
                          )
                        }
                      ></button>
                      <div className="styleboxlbl">{t("chart5.fillColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-candlestick-n-fill"
                        value={candlestickUpFillColor}
                        style={{ backgroundColor: "#" + candlestickUpFillColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            candlestickUpFillColor,
                            "candlestickUpFillColor",
                            `${t("chart5.negative")}: ${t("chart5.fillColor")}`
                          )
                        }
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-charttype"
                onClick={(e) => onChartTypeChange(e, "bar")}
              >
                {/* <div
                  className={
                    chartType === "bar" ? "radiobutton-selected" : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon bar"
                  // style={{ background: `url(${bar}) center center no-repeat` }}
                ></div> */}
                <IconBarLight
                  className={[
                    "icon",
                    chartType === "bar" ? "selected" : "",
                  ].join(" ")}
                />
                <span
                  className={[
                    "span",
                    chartType === "bar" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => onChartTypeChange(e, "bar", true)}
                >
                  {t("chart5.bar")}
                </span>
                <div
                  className="optboxarrow"
                  style={isBarOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isBarOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetChartTypeBar}
                ></div>
                <div
                  className="msi-optbox"
                  ref={ctBarRef}
                  style={isBarOpen ? {} : { display: "none" }}
                >
                  <div className="menusubitem" id="msi-charttype-bar">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-bar-p-color0"
                        value={barColor}
                        style={{ backgroundColor: "#" + barColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            barColor,
                            "barColor",
                            t("chart5.lineColor")
                          )
                        }
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-charttype"
                onClick={(e) => onChartTypeChange(e, "histogram")}
              >
                {/* <div
                  className={
                    chartType === "candlestick"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon candlestick"
                  // style={{
                  //   background: `url(${candlestick}) center center no-repeat`,
                  // }}
                ></div> */}
                <IconHistogramLight
                  className={[
                    "icon",
                    chartType === "histogram" ? "selected" : "",
                  ].join(" ")}
                />
                <span
                  className={[
                    "span",
                    chartType === "histogram" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => onChartTypeChange(e, "histogram", true)}
                >
                  {t("chart5.histogram")}
                </span>
                <div
                  className="optboxarrow"
                  style={isHistogramOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isHistogramOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetChartTypeHistogram}
                ></div>
                <div
                  className="msi-optbox"
                  ref={ctHistogramRef}
                  style={isHistogramOpen ? {} : { display: "none" }}
                >
                  <div className="menusubitem" id="msi-optbox-histogram">
                    <div
                      className="menusubitem stylebox floatmsibox"
                      id="msi-charttype-histogram"
                    >
                      <div className="styleboxlbl">{t("chart5.positive")}:</div>
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-histogram-p-color0"
                        value={histogramDownColor}
                        style={{ backgroundColor: "#" + histogramDownColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            histogramDownColor,
                            "histogramDownColor",
                            `${t("chart5.positive")}: ${t("chart5.lineColor")}`
                          )
                        }
                      ></button>
                      <div className="styleboxlbl">{t("chart5.fillColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-histogram-p-fill"
                        value={histogramDownFillColor}
                        style={{ backgroundColor: "#" + histogramDownFillColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            histogramDownFillColor,
                            "histogramDownFillColor",
                            `${t("chart5.positive")}: ${t("chart5.fillColor")}`
                          )
                        }
                      ></button>
                    </div>
                    <div
                      className="menusubitem stylebox floatmsibox"
                      id="msi-charttype-histogram"
                    >
                      <div className="styleboxlbl">{t("chart5.negative")}:</div>
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-histogram-n-color0"
                        value={histogramUpColor}
                        style={{ backgroundColor: "#" + histogramUpColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            histogramUpColor,
                            "histogramUpColor",
                            `${t("chart5.negative")}: ${t("chart5.lineColor")}`
                          )
                        }
                      ></button>
                      <div className="styleboxlbl">{t("chart5.fillColor")}</div>
                      <button
                        className="colorpicker"
                        id="msi-charttype-histogram-n-fill"
                        value={histogramUpFillColor}
                        style={{ backgroundColor: "#" + histogramUpFillColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            histogramUpFillColor,
                            "histogramUpFillColor",
                            `${t("chart5.negative")}: ${t("chart5.fillColor")}`
                          )
                        }
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Draw Tool */}
          <div
            className={[
              "menuitem",
              "menualignright",
              "open",
              props.ChgThemeData === "Light" ? "light" : "",
              type === "drawTool" ? "shawdow" : "",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? "hide"
                : "",
            ].join(" ")}
            // className="menuitem menualignright open"
            ref={drawtoolRef}
            id={type === "drawTool" ? "mi-drawtool-on" : "mi-drawtool"}
            onClick={(e) => onMenuClick(e, "drawTool")}
          >
            {/* <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"描画ツール"}
              direction="down-start"
              arrow={false}
            > */}
            {/* {props.ChgThemeData === "Light" && type === "drawTool" && (
              <div
                className={[
                  "connection-box",
                  props.isTablet || props.isMobileViewInSector ? "mobile" : "",
                ].join(" ")}
              ></div>
            )} */}
            <div
              style={{
                display: isOpen ? "none" : "inline-block",
                width: 47,
                height: 48,
                backgroundImage: "none",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              ref={drawtoolRef}
              id={type === "drawTool" ? "mi-drawtool-on" : "mi-drawtool"}
              onClick={(e) => onMenuClick(e, "drawTool")}
            ></div>
            {/* </Tooltip> */}
            <div
              className="menudropdown"
              style={
                type === "drawTool"
                  ? {
                    width:
                      props.isTablet || props.isMobileViewInSector
                        ? "calc(100% + 32px)"
                        : "318px",
                    maxHeight: "300px",
                    top:
                      props.isTablet ||
                        props.isMobile ||
                        props.isMobileViewInSector
                        ? "120px"
                        : undefined,
                    left:
                      props.isTablet || props.isMobileViewInSector
                        ? "-16px"
                        : "auto",
                  }
                  : { display: "none" }
              }
            >
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-drawtool"
                onClick={(e) => {
                  onDrawToolChange(e, "move");
                }}
              >
                {/* <div
                  className={
                    drawTool === "move" ? "radiobutton-selected" : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon"
                  style={{ background: `url(${move}) center center no-repeat` }}
                ></div> */}
                <IconHandLight
                  className={[
                    "icon",
                    drawTool === "move" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "move", true);
                  }}
                />
                <span
                  className={[
                    "span",
                    drawTool === "move" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "move", true);
                  }}
                >
                  {t("chart5.move")}
                </span>
                <div className="msi-optbox"></div>
              </div>
              <div className="msi-sep"></div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-drawtool"
                onClick={(e) => {
                  onDrawToolChange(e, "drawLine");
                }}
              >
                {/* <div
                  className={
                    drawTool === "drawLine"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon"
                  style={{
                    background: `url(${drawline}) center center no-repeat`,
                  }}
                ></div> */}
                <IconLine2Light
                  className={[
                    "icon",
                    drawTool === "drawLine" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawLine", true);
                  }}
                />
                <span
                  className={[
                    "span",
                    drawTool === "drawLine" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawLine", true);
                  }}
                >
                  {t("chart5.straightLine")}
                </span>
                <div
                  className="optboxarrow"
                  style={isDrawLineOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isDrawLineOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetDrawLine}
                ></div>
                <div
                  className="msi-optbox"
                  style={isDrawLineOpen ? {} : { display: "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="menusubitem" id="msi-drawtool-line">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        id="msi-drawtool-line-color0"
                        className="colorpicker"
                        value={drawLineColor}
                        style={{ backgroundColor: "#" + drawLineColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            drawLineColor,
                            "drawLine",
                            t("chart5.lineColor")
                          )
                        }
                      ></button>
                      <div className="inputbox">
                        <input
                          id="msi-drawtool-line-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={drawLineThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) => onDrawToolThicknessChange(e, "line")}
                        />
                      </div>
                      <div className="selectbox">
                        <select
                          id="msi-drawtool-line-style"
                          onChange={(e) => onDrawToolStyleChange(e, "line")}
                          value={drawLineStyle}
                        >
                          <option value="s">{t("chart5.realLine")}</option>
                          <option value="d">{t("chart5.dotLine")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-drawtool"
                onClick={(e) => {
                  onDrawToolChange(e, "drawRetracement");
                }}
              >
                {/* <div
                  className={
                    drawTool === "drawRetracement"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon"
                  style={{
                    background: `url(${retracement}) center center no-repeat`,
                  }}
                ></div> */}
                <IconRetracementLight
                  className={[
                    "icon",
                    drawTool === "drawRetracement" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawRetracement", true);
                  }}
                />
                <span
                  className={[
                    "span",
                    drawTool === "drawRetracement" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawRetracement", true);
                  }}
                >
                  {t("chart5.retracements")}
                </span>
                <div
                  className="optboxarrow"
                  style={
                    isDrawRetracementOpen ? { transform: "rotate(180deg)" } : {}
                  }
                ></div>
                <div
                  className="b2dbtn"
                  style={isDrawRetracementOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetDrawRetracement}
                ></div>
                <div
                  className="msi-optbox"
                  style={isDrawRetracementOpen ? {} : { display: "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="menusubitem" id="msi-drawtool-retracement">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        id="msi-drawtool-retracement-color0"
                        className="colorpicker"
                        value={drawRetracementColor}
                        style={{ backgroundColor: "#" + drawRetracementColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            drawRetracementColor,
                            "drawRetracement",
                            t("chart5.lineColor")
                          )
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-drawtool-retracement-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={drawRetracementThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) =>
                            onDrawToolThicknessChange(e, "retracement")
                          }
                        />
                      </div>

                      <div className="selectbox">
                        <select
                          id="msi-drawtool-retracement-style"
                          onChange={(e) =>
                            onDrawToolStyleChange(e, "retracement")
                          }
                          value={drawRetracementStyle}
                        >
                          <option value="s">{t("chart5.realLine")}</option>
                          <option value="d">{t("chart5.dotLine")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-drawtool"
                onClick={(e) => {
                  onDrawToolChange(e, "drawFan");
                }}
              >
                {/* <div
                  className={
                    drawTool === "drawFan"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon"
                  style={{ background: `url(${fan}) center center no-repeat` }}
                ></div> */}
                <IconFanLight
                  className={[
                    "icon",
                    drawTool === "drawFan" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawFan", true);
                  }}
                />
                <span
                  className={[
                    "span",
                    drawTool === "drawFan" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawFan", true);
                  }}
                >
                  {t("chart5.fans")}
                </span>
                <div
                  className="optboxarrow"
                  style={isDrawFanOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isDrawFanOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetDrawFan}
                ></div>
                <div
                  className="msi-optbox"
                  style={isDrawFanOpen ? {} : { display: "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="menusubitem" id="msi-drawtool-fan">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        id="msi-drawtool-fan-color0"
                        className="colorpicker"
                        value={drawFanColor}
                        style={{ backgroundColor: "#" + drawFanColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            drawFanColor,
                            "drawFan",
                            t("chart5.lineColor")
                          )
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-drawtool-fan-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={drawFanThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) => onDrawToolThicknessChange(e, "fan")}
                        />
                      </div>

                      <div className="selectbox">
                        <select
                          id="msi-drawtool-fan-style"
                          onChange={(e) => onDrawToolStyleChange(e, "fan")}
                          value={drawFanStyle}
                        >
                          <option value="s">{t("chart5.realLine")}</option>
                          <option value="d">{t("chart5.dotLine")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="menusubitem msiaction radiogroup"
                id="msi-drawtool"
                onClick={(e) => {
                  onDrawToolChange(e, "drawArcs");
                }}
              >
                {/* <div
                  className={
                    drawTool === "drawArcs"
                      ? "radiobutton-selected"
                      : "radiobutton"
                  }
                ></div> */}
                {/* <div
                  className="msiicon"
                  style={{ background: `url(${arcs}) center center no-repeat` }}
                ></div> */}
                <IconArcLight
                  className={[
                    "icon",
                    drawTool === "drawArcs" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawArcs", true);
                  }}
                />
                <span
                  className={[
                    "span",
                    drawTool === "drawArcs" ? "selected" : "",
                  ].join(" ")}
                  onClick={(e) => {
                    onDrawToolChange(e, "drawArcs", true);
                  }}
                >
                  {t("chart5.arcs")}
                </span>
                <div
                  className="optboxarrow"
                  style={isDrawArcsOpen ? { transform: "rotate(180deg)" } : {}}
                ></div>
                <div
                  className="b2dbtn"
                  style={isDrawArcsOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={resetDrawArcs}
                ></div>
                <div
                  className="msi-optbox"
                  style={isDrawArcsOpen ? {} : { display: "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="menusubitem" id="msi-drawtool-arcs">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">{t("chart5.lineColor")}</div>
                      <button
                        id="msi-drawtool-arcs-color0"
                        className="colorpicker"
                        value={drawArcsColor}
                        style={{ backgroundColor: "#" + drawArcsColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            drawArcsColor,
                            "drawArcs",
                            t("chart5.lineColor")
                          )
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-drawtool-arcs-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={drawArcsThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) => onDrawToolThicknessChange(e, "arcs")}
                        />
                      </div>
                      <div className="selectbox">
                        <select
                          id="msi-drawtool-arcs-style"
                          onChange={(e) => onDrawToolStyleChange(e, "arcs")}
                          value={drawArcsStyle}
                        >
                          <option value="s">{t("chart5.realLine")}</option>
                          <option value="d">{t("chart5.dotLine")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* TA */}
          <div
            className={[
              "menuitem",
              "menualignright",
              "open",
              props.ChgThemeData === "Light" ? "light" : "",
              type === "ta" ? "shawdow" : "",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? "hide"
                : "",
            ].join(" ")}
            ref={taRef}
            id={type === "ta" ? "mi-ta-on" : "mi-ta"}
            onClick={(e) => onMenuClick(e, "ta")}
          >
            {/* <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"テクニカル分析設定"}
              direction="down-start"
              arrow={false}
            > */}
            {/* {props.ChgThemeData === "Light" && type === "ta" && (
              <div
                className={[
                  "connection-box",
                  props.isTablet || props.isMobileViewInSector ? "mobile" : "",
                ].join(" ")}
              ></div>
            )} */}
            <div
              style={{
                display: isOpen ? "none" : "inline-block",
                width: 47,
                height: 48,
                backgroundImage: "none",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              ref={taRef}
              id={type === "ta" ? "mi-ta-on" : "mi-ta"}
              onClick={(e) => onMenuClick(e, "ta")}
            ></div>
            {/* </Tooltip> */}
            <div
              className="menudropdown"
              style={
                type === "ta"
                  ? {
                    width:
                      props.isTablet || props.isMobileViewInSector
                        ? "calc(100% + 32px)"
                        : "620px",
                    maxHeight: "300px",
                    top:
                      props.isTablet ||
                        props.isMobile ||
                        props.isMobileViewInSector
                        ? "120px"
                        : undefined,
                    left:
                      props.isTablet || props.isMobileViewInSector
                        ? "-16px"
                        : "auto",
                    paddingBottom: "30px",
                  }
                  : { display: "none" }
              }
            >
              {/* <div className="msi-grplbl">
                <span>可用なテクニカル分析</span>
                
              </div> */}
              <div className="background sticky">
                <div className="msi-grplbl top">
                  <span>{t("chart5.selectedTA")}</span>
                </div>
                <div className="inputbox taselboxfilter">
                  <input
                    type="text"
                    className="keyword placeholder"
                    placeholder={t("chart5.filterByName")}
                    value={taPowerSearchValue}
                    onChange={(e) => onTAPowerSearchChange(e)}
                  />
                </div>
              </div>

              <div className="taconfbox">
                {rendarTA()}
                {isShowReset() && (
                  <div
                    className="reset-button"
                    onClick={onRemoveTAAndComparion}
                  >
                    {t("Reset All")}
                  </div>
                )}
              </div>

              {renderTAHeader()}
            </div>
          </div>

          <div
            className={[
              "menuitem menualignright open",
              props.isTablet || props.isMobile || props.isMobileViewInSector
                ? "hide"
                : "",
            ].join(" ")}
            ref={zoomRef}
            id={props.isChartZoom ? "mi-zoom-on" : "mi-zoom"}
            onClick={props.onClickZoom}
          ></div>

          {/* <div className="empty"></div>
          <div className="empty"></div>
          <div className="empty"></div> */}

          {/* Compare */}
          {/* <div
            className="menuitem menualignright open"
            ref={compareRef}
            id={type === "compare" ? "mi-compare-on" : "mi-compare"}
            onClick={(e) => onMenuClick(e, "compare")}
          >
            <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"比較モード"}
              direction="down-start"
              arrow={false}
            >
              <div
                style={{
                  display: isOpen ? "none" : "inline-block",
                  width: 47,
                  height: 48,
                  backgroundImage: "none",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                ref={compareRef}
                id={type === "compare" ? "mi-compare-on" : "mi-compare"}
                onClick={(e) => onMenuClick(e, "compare")}
              ></div>
            </Tooltip>
            <div
              className="menudropdown"
              style={
                type === "compare"
                  ? {
                      width: "400px",
                      maxHeight: "450px",
                      left: "auto",
                      right: "-96px",
                    }
                  : { display: "none" }
              }
            >
              <div
                className="menusubitem msiaction checkbox"
                id="msi-comparemode"
              >
                <div
                  className={
                    showCompare ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                  onClick={onShowCompareChange}
                ></div>
                <span>比較モードを入力</span>
                <div id="compriclist">
                  <div className="msi-optbox noclose">
                    <div
                      className="nocomparelbl"
                      style={isCompareEmpty ? {} : { display: "none" }}
                    >
                      <span>なし</span>
                    </div>
                    {renderCompare()}
                  </div>
                </div>
              </div>

              <div className="msi-grplbl">
                <span>アイテムを追加</span>
              </div>
              <div id="addcompricinputbox">
                <div
                  className="powersearchbox editmode"
                  style={{ outline: "none" }}
                >
                  <div className="editbox modebox">
                    <div className="selectbox assettypeselectbox">
                      <select
                        style={{ width: "106px" }}
                        onChange={(e) => onCompareTypeChange(e)}
                      >
                        <option value="EQTY">株式</option>
                        <option value="EQTYIDX">指数</option>
                        <option value="SECTOR">業種別</option>
                      </select>
                    </div>
                    <div className="ricinputbox">
                      <div className="assettype_EQTYIDX ricinputform">
                        <div
                          style={
                            isEQTY
                              ? { position: "absolute", top: -1 }
                              : { display: "none" }
                          }
                        >
                          <InputBoxRICSearch
                            id="compare-ric-search"
                            style={{
                              border: "none",
                              borderRadius: 0,
                            }}
                            value={code_symbol}
                            onChange={(symbol) => {
                              setCode_symbol(symbol);
                            }}
                            // onKeyPressEnter={() => {
                            //   onClickSearch();
                            // }}
                            onSelected={(ric) => {}}
                            onSelectedWholeItem={(item) => {
                              // console.log("onSelectedWholeItem item");
                              // console.log(item);
                              setCompare({
                                name: item.name,
                                code: item.code,
                                value: item.ric,
                                flag: item.cc,
                              });
                            }}
                            placeholder="銘柄名・ティッカー"
                            isSearchSummary={false}
                            width={150}
                            height={26}
                          />
                        </div>
                        <div style={isEQTYIDX ? {} : { display: "none" }}>
                          <CompareDropdown
                            isHeader={false}
                            onSelectChange={onCompareSelectChange}
                            compareList={CompareList.RIC}
                          />
                        </div>
                        <div style={isSECTOR ? {} : { display: "none" }}>
                          <CompareDropdown
                            isHeader={false}
                            onSelectChange={onCompareSelectChange}
                            compareList={CompareList.SECTOR}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="closeeditbox"></div>
                  </div>
                </div>
                <button id="addcompricbutton" onClick={onAddComparisonClick}>
                  追加
                </button>
              </div>
              <div className="menusubitem" id="addcompricstyle">
                <div className="msi-optbox">
                  <div className="menusubitem">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl"></div>
                      <button
                        id="msi-compare-compric-color0"
                        className="colorpicker"
                        value={compareColor}
                        style={{ backgroundColor: "#" + compareColor }}
                        onClick={() =>
                          onColorPickerOpen(compareColor, "compare", "線色")
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-compare-compric-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={compareThickness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) => onCompareThicknessChange(e)}
                        />
                      </div>

                      <div className="selectbox">
                        <select onChange={(e) => onCompareStyleChange(e)}>
                          <option value="s">実線</option>
                          <option value="d">点線</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ display: "none" }}
                className="menusubitem msiaction"
                id="msi-comparetype"
              >
                <span>比較形式</span>
                <div className="msi-optbox noclose">
                  <div
                    className="menusubitem msiaction radiogroup selected"
                    id="msi-comparetype"
                  >
                    <div className="radiobutton"></div>
                    <span>相対比較</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* View */}
          {/* <div
            className="menuitem menualignright open"
            ref={viewRef}
            id={type === "view" ? "mi-viewswitch-on" : "mi-viewswitch"}
            onClick={(e) => onMenuClick(e, "view")}
          >
            <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"表示設定"}
              direction="down-start"
              arrow={false}
            >
              <div
                style={{
                  display: isOpen ? "none" : "inline-block",
                  width: 47,
                  height: 48,
                  backgroundImage: "none",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                ref={viewRef}
                id={type === "view" ? "mi-viewswitch-on" : "mi-viewswitch"}
                onClick={(e) => onMenuClick(e, "view")}
              ></div>
            </Tooltip>
            <div
              className="menudropdown"
              style={
                type === "view"
                  ? { maxHeight: "409px", right: "-48px", left: "auto" }
                  : { display: "none" }
              }
            >
              <div
                className="menusubitem msiaction checkbox selected"
                id="msi-viewmainchart"
                onClick={onShowMainLineChange}
              >
                <div
                  className={
                    showMainTracker
                      ? "checkboxbutton-selected"
                      : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewchart}) center center no-repeat`,
                  }}
                ></div>
                <span>主線を表示</span>
              </div>
              <div
                className="menusubitem msiaction checkbox selected"
                id="msi-viewta"
                onClick={onShowTaChange}
              >
                <div
                  className={
                    showTa ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewta}) center center no-repeat`,
                  }}
                ></div>
                <span>テクニカル分析を表示</span>
              </div>
              <div
                className="menusubitem msiaction checkbox"
                id="msi-viewtrendline"
                onClick={onShowDrawToolChange}
              >
                <div
                  className={
                    showDrawTool ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewtrendline}) center center no-repeat`,
                  }}
                ></div>
                <span>トレンドラインを表示</span>
              </div>

              <div
                className="menusubitem msiaction checkbox"
                id="msi-viewpricetracker"
                onClick={onShowValueChange}
              >
                <div
                  className={
                    showPriceTracker
                      ? "checkboxbutton-selected"
                      : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewpricetracker}) center center no-repeat`,
                  }}
                ></div>
                <span>現在値を表示</span>
              </div>

              <div className="msi-sep"></div>

              <div
                className="menusubitem msiaction checkbox selected"
                id="msi-viewscrollpane"
                onClick={onShowScrollViewChange}
              >
                <div
                  className={
                    showScrollView
                      ? "checkboxbutton-selected"
                      : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewscrollpane}) center center no-repeat`,
                  }}
                ></div>
                <span>タイムラインスクロールを表示</span>
              </div>
              <div className="msi-sep"></div>
              <div
                className="menusubitem msiaction checkbox selected"
                id="msi-viewcrosshair"
                onClick={onShowCrosshairChange}
              >
                <div
                  className={
                    showCrosshair ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewcrosshair}) center center no-repeat`,
                  }}
                ></div>
                <span>クロスを表示</span>
              </div>
              <div
                className="menusubitem msiaction checkbox selected"
                id="msi-viewchtip"
                onClick={onShowTipChange}
              >
                <div
                  className={
                    showTip ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewchtip}) center center no-repeat`,
                  }}
                ></div>
                <span>クロスツールチップを表示</span>
              </div>
              <div
                className="menusubitem msiaction checkbox selected open"
                id="msi-vieweventtip"
                onClick={(e) => onShowEventChange(e)}
              >
                <div
                  className={
                    showEvent ? "checkboxbutton-selected" : "checkboxbutton"
                  }
                ></div>
                <div
                  className="msiicon"
                  style={{
                    background: `url(${viewchtip}) center center no-repeat`,
                  }}
                ></div>
                <span>イベントを表示</span>
                <div
                  className="optboxarrow"
                  style={showEvent ? { transform: "rotate(180deg)" } : {}}
                  onClick={(e) => onEventSettingClick(e)}
                ></div>
                <div
                  className="msi-optbox"
                  style={showEvent ? { display: "block" } : { display: "none" }}
                >
                  <div
                    className={
                      "menusubitem msiaction checkbox msi-box" +
                      (isS ? " selected" : "")
                    }
                    id="msi-vieweventtype"
                    style={{
                      width: "86px",
                      float: "left",
                      margin: "4px 0 0 4px",
                    }}
                    onClick={(e) => onEventClick("S")}
                  >
                    <span className="event">重要行事</span>
                  </div>
                  <div
                    className={
                      "menusubitem msiaction checkbox msi-box" +
                      (isC ? " selected" : "")
                    }
                    id="msi-vieweventtype"
                    style={{
                      width: "132px",
                      float: "left",
                      margin: "4px 0 0 4px",
                    }}
                    onClick={(e) => onEventClick("C")}
                  >
                    <span className="event">株式分割・割当・併合</span>
                  </div>
                  <div
                    className={
                      "menusubitem msiaction checkbox msi-box" +
                      (isD ? " selected" : "")
                    }
                    id="msi-vieweventtype"
                    style={{ float: "left", margin: "4px 0 0 4px" }}
                    onClick={(e) => onEventClick("D")}
                  >
                    <span className="event">配当</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Setting */}
          {/* <div
            className="menuitem menualignright open"
            ref={settingRef}
            id={type === "setting" ? "mi-setting-on" : "mi-setting"}
            onClick={(e) => onMenuClick(e, "setting")}
          >
            <Tooltip
              styles={{ whiteSpace: "nowrap" }}
              tagName="span"
              content={"基本設定"}
              direction="down-start"
              arrow={false}
            >
              <div
                style={{
                  display: isOpen ? "none" : "inline-block",
                  width: 47,
                  height: 48,
                  backgroundImage: "none",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                ref={settingRef}
                id={type === "setting" ? "mi-setting-on" : "mi-setting"}
                onClick={(e) => onMenuClick(e, "setting")}
              ></div>
            </Tooltip>
            <div
              className="menudropdown"
              style={
                type === "setting"
                  ? {
                      maxHeight: "409px",
                      backgroundColor: "#333333",
                      left: "auto",
                      right: 0,
                    }
                  : { display: "none" }
              }
            >
              <div
                className="menusubitem msiaction"
                id="msi-clearall"
                onClick={(e) => onRemoveTAAndComparion()}
              >
                <div
                  className="msiicon"
                  style={{
                    background: `url(${clearall}) center center no-repeat`,
                  }}
                ></div>
                <span>全てをクリア</span>
              </div>
              <div
                className="menusubitem msiaction"
                id="msi-b2dall"
                onClick={(e) => onResetClick("ALL")}
              >
                <div
                  className="msiicon"
                  style={{
                    background: `url(${reset}) center center no-repeat`,
                  }}
                ></div>
                <span>全てを初期設定に戻す</span>
              </div>
              <div className="msi-sep"></div>
              <div
                className="menusubitem msiaction"
                id="msi-print"
                onClick={(e) => onPrintClick()}
              >
                <div
                  className="msiicon"
                  style={{
                    background: `url(${print}) center center no-repeat`,
                  }}
                ></div>
                <span>印刷</span>
              </div>
              <div
                className="menusubitem msiaction"
                id="msi-help"
                onClick={(e) => onHelpClick()}
              >
                <div
                  className="msiicon"
                  style={{ background: `url(${help}) center center no-repeat` }}
                ></div>
                <span>ヘルプ</span>
              </div>
              <div className="msi-sep"></div>
              <div
                className="menusubitem"
                id="msi-stylesetting"
                onClick={(e) => onStyleSettingClick(e)}
              >
                <div
                  className="msiicon"
                  style={{
                    background: `url(${stylesetting}) center center no-repeat`,
                  }}
                ></div>
                <span className="span">スタイル設定</span>
                <div
                  className="optboxarrow"
                  style={
                    isStyleSettingOpen ? { transform: "rotate(180deg)" } : {}
                  }
                  onClick={(e) => onStyleSettingClick(e)}
                ></div>
                <div
                  className="b2dbtn"
                  style={isStyleSettingOpen ? { display: "flex" } : {}}
                  title="スタイル設定を初期設定に戻す"
                  onClick={(e) => resetSettingStyle()}
                ></div>
                <div
                  className="msi-optbox"
                  style={
                    isStyleSettingOpen
                      ? { backgroundColor: "rgb(51, 51, 51)" }
                      : { display: "none" }
                  }
                >
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">現在値を表示</div>
                      <button
                        id="msi-stylesetting-pricetracker-color0"
                        className="colorpicker"
                        value={fieldTrackColor}
                        style={{ backgroundColor: "#" + fieldTrackColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            fieldTrackColor,
                            "fieldTrackColor",
                            "現在値を表示"
                          )
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-stylesetting-pricetracker-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={fieldTrackThinkness}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) =>
                            settingThicknessChange(e, "pricetracker")
                          }
                        />
                      </div>

                      <div className="selectbox">
                        <select
                          id="msi-stylesetting-pricetracker-style"
                          value={fieldTrackStyle}
                          onChange={(e) =>
                            settingStyleChange(e, "pricetracker")
                          }
                          style={{ width: 50 }}
                        >
                          <option value="s">実線</option>
                          <option value="d">点線</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">現在値の文字</div>
                      <button
                        className="colorpicker"
                        id="msi-stylesetting-pricetrackertext-color0"
                        value={fieldFontColor}
                        style={{ backgroundColor: "#" + fieldFontColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            fieldFontColor,
                            "fieldFontColor",
                            "現在値の文字"
                          )
                        }
                      ></button>
                    </div>
                  </div>
                  <div className="msi-sep"></div>

                  <div
                    className="menusubitem msiaction checkbox"
                    id="msi-stylesetting"
                  >
                    <div
                      id="msi-stylesetting-inverseyaxis-enabled"
                      className={
                        yAsis ? "checkboxbutton-selected" : "checkboxbutton"
                      }
                      onClick={(e) => onYAsiaChange()}
                    ></div>
                    <span>逆Y軸</span>
                  </div>
                  <div className="msi-sep"></div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">図表の背景色</div>
                      <button
                        className="colorpicker"
                        id="msi-stylesetting-chartbackground-color0"
                        value={backgroundColor}
                        style={{ backgroundColor: "#" + backgroundColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            backgroundColor,
                            "backgroundColor",
                            "図表の背景色"
                          )
                        }
                      ></button>
                    </div>
                  </div>
                  <div className="msi-sep"></div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">軸の背景色</div>
                      <button
                        className="colorpicker"
                        id="msi-stylesetting-axisbackground-color0"
                        value={chartBackgroundColor}
                        style={{ backgroundColor: "#" + chartBackgroundColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            chartBackgroundColor,
                            "chartBackgroundColor",
                            "軸の背景色"
                          )
                        }
                      ></button>
                    </div>
                  </div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">グリッド線</div>
                      <button
                        id="msi-stylesetting-gridline-color0"
                        className="colorpicker"
                        value={chartLineColor}
                        style={{ backgroundColor: "#" + chartLineColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            chartLineColor,
                            "chartLineColor",
                            "グリッド線"
                          )
                        }
                      ></button>

                      <div className="selectbox">
                        <select
                          id="msi-stylesetting-gridline-style"
                          value={chartLineStyle}
                          onChange={(e) => settingStyleChange(e, "gridline")}
                          style={{ width: 50 }}
                        >
                          <option value="s">実線</option>
                          <option value="d">点線</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">軸文字</div>
                      <button
                        className="colorpicker"
                        id="msi-stylesetting-gridtext-color0"
                        value={toolTipsColor}
                        style={{ backgroundColor: "#" + toolTipsColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            toolTipsColor,
                            "toolTipsColor",
                            "軸文字"
                          )
                        }
                      ></button>
                    </div>
                  </div>
                  <div className="msi-sep"></div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">クロスのライン</div>
                      <button
                        id="msi-stylesetting-chline-color0"
                        className="colorpicker"
                        value={crosshairLineColor}
                        style={{ backgroundColor: "#" + crosshairLineColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            crosshairLineColor,
                            "crosshairLineColor",
                            "クロスのライン"
                          )
                        }
                      ></button>

                      <div className="selectbox">
                        <select
                          id="msi-stylesetting-chline-style"
                          value={crosshairLineStyle}
                          onChange={(e) => settingStyleChange(e, "crosshair")}
                          style={{ width: 50 }}
                        >
                          <option value="s">実線</option>
                          <option value="d">点線</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox ">
                      <div className="styleboxlbl">クロスの点</div>
                      <button
                        id="msi-stylesetting-chdot-color0"
                        className="colorpicker"
                        value={crosshairPointColor}
                        style={{ backgroundColor: "#" + crosshairPointColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            crosshairPointColor,
                            "crosshairPointColor",
                            "クロスの点"
                          )
                        }
                      ></button>

                      <div className="inputbox">
                        <input
                          id="msi-stylesetting-chdot-thickness"
                          type="number"
                          step="1"
                          min="1"
                          max="9"
                          value={crosshairPointSize}
                          maxLength={1}
                          style={{ width: "30px" }}
                          onChange={(e) =>
                            settingThicknessChange(e, "crosshair")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="menusubitem msiaction" id="msi-stylesetting">
                    <div className="menusubitem stylebox">
                      <div className="styleboxlbl">クロス表示値の文字</div>
                      <button
                        className="colorpicker"
                        id="msi-stylesetting-chtracker-color0"
                        value={crosshairFontColor}
                        style={{ backgroundColor: "#" + crosshairFontColor }}
                        onClick={() =>
                          onColorPickerOpen(
                            crosshairFontColor,
                            "crosshairFontColor",
                            "クロス表示値の文字"
                          )
                        }
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
