import React, { useEffect, useState } from "react";
import "./ColorPicker.scss";
import colormap from "../../../../asserts/imgs/chart/colormap.gif";
import Config from "../../../Configs/colorpicker.json";
import JsColor from "./JsColor";
import { useTranslation } from "react-i18next";
import "./plugins/jscolor";

// declare global {
//   interface Window {
//     jscolor: any;
//     jsColorOnFineChange: any;
//   }
// }

export const ColorPicker = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("FFFFFF");
  const [top, setTop] = useState("47px");
  const [left, setLeft] = useState("62px");

  const colorInput = React.createRef();

  useEffect(() => {
    // Setup jscolor
    window.jscolor.installByClassName("jscolor-input");

    // Bind events
    colorInput.current.addEventListener("onFineChange", onFineChange);
    colorInput.current.addEventListener("change", onJsColorChange);
    colorInput.current.addEventListener("input", onJsColorChange);
  }, [props]);

  const onJsColorChange = () => {
    // if (colorInput.current){
    //     console.log(colorInput.current.jscolor.toHEXString())
    //     setValue(colorInput.current.jscolor.toHEXString())
    // }
  };

  const onFineChange = (e) => {
    let c = e.detail.jscolor.toHEXString();
    setValue(c);
    props.onColorChange(c);
  };

  const showColorPicker = () => {
    colorInput.current.jscolor.show();
  };

  window.jsColorOnFineChange = function (thisObj) {
    //console.log(thisObj);
    thisObj.valueElement.dispatchEvent(
      new CustomEvent("onFineChange", { detail: { jscolor: thisObj } })
    );
  };

  useEffect(() => {
    setValue(props.color);
    getPosition(props.color);
  }, [props.color]);

  // value: string
  function getPosition(value) {
    const colorArray = Config.color;
    colorArray.forEach((e) => {
      if (e.name === value) {
        setTop(e.position[0] + "px");
        setLeft(e.position[1] + "px");
      }
    });
  }

  function onCloseClick() {
    props.closeClick();
  }

  function onColorClick(v, position) {
    props.onColorChange(v);
    setValue(v);
    setTop(position[0] + "px");
    setLeft(position[1] + "px");
  }

  function onMouseOver(v) {
    setValue(v);
  }

  function onColorInputChange(e) {
    setValue(e.target.value);
  }

  const onInputClick = () => {
    //JsColor.showColorPicker()
    //setIsShow(true)
  };

  return (
    <>
      {/* bg */}
      <div id="colorpickerdialog-bg" onClick={onCloseClick}></div>
      <div
        id="colorpickerdialog"
        className="ja hasdialogtitle"
        style={{
          display: "block",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor:
            props.ChgThemeData === "Light" ? "#ffffff" : "#373737",
          borderColor: props.ChgThemeData === "Light" ? "#dddddd" : "#222429",
          color: props.ChgThemeData === "Light" ? "#828282" : "#ffffff",
          zIndex: 5,
        }}
      >
        <div className="dialogclosebutton" onClick={onCloseClick}></div>
        {props.title !== undefined && (
          <div
            className="dialogtitle"
            style={{
              display: "block",
              borderColor:
                props.ChgThemeData === "Light" ? "#dddddd" : "#505050",
            }}
          >
            {props.title}
          </div>
        )}
        <div style={{ position: "relative" }}>
          <img
            src={colormap}
            useMap="#colorpickerdialog_colormap"
            alt="colormap"
          />
          <map name="colorpickerdialog_colormap">
            <area
              className="area"
              shape="poly"
              coords="63,0,72,4,72,15,63,19,54,15,54,4"
              onClick={(e) => onColorClick("003366", [45, 62])}
              onMouseOver={(e) => onMouseOver("003366")}
              alt="003366"
            />
            <area
              className="area"
              shape="poly"
              coords="81,0,90,4,90,15,81,19,72,15,72,4"
              onClick={(e) => onColorClick("336699", [45, 80])}
              onMouseOver={(e) => onMouseOver("336699")}
              alt="336699"
            />
            <area
              className="area"
              shape="poly"
              coords="99,0,108,4,108,15,99,19,90,15,90,4"
              onClick={(e) => onColorClick("3366CC", [45, 98])}
              onMouseOver={(e) => onMouseOver("3366CC")}
              alt="3366CC"
            />
            <area
              className="area"
              shape="poly"
              coords="117,0,126,4,126,15,117,19,108,15,108,4"
              onClick={(e) => onColorClick("003399", [45, 116])}
              onMouseOver={(e) => onMouseOver("003399")}
              alt="003399"
            />
            <area
              className="area"
              shape="poly"
              coords="135,0,144,4,144,15,135,19,126,15,126,4"
              onClick={(e) => onColorClick("000099", [45, 134])}
              onMouseOver={(e) => onMouseOver("000099")}
              alt="000099"
            />
            <area
              className="area"
              shape="poly"
              coords="153,0,162,4,162,15,153,19,144,15,144,4"
              onClick={(e) => onColorClick("0000CC", [45, 152])}
              onMouseOver={(e) => onMouseOver("0000CC")}
              alt="0000CC"
            />
            <area
              className="area"
              shape="poly"
              coords="171,0,180,4,180,15,171,19,162,15,162,4"
              onClick={(e) => onColorClick("000000", [45, 170])}
              onMouseOver={(e) => onMouseOver("000000")}
              alt="000000"
            />
            <area
              className="area"
              shape="poly"
              coords="54,15,63,19,63,30,54,34,45,30,45,19"
              onClick={(e) => onColorClick("006666", [60, 53])}
              onMouseOver={(e) => onMouseOver("006666")}
              alt="006666"
            />
            <area
              className="area"
              shape="poly"
              coords="72,15,81,19,81,30,72,34,63,30,63,19"
              onClick={(e) => onColorClick("006699", [60, 71])}
              onMouseOver={(e) => onMouseOver("006699")}
              alt="006699"
            />
            <area
              className="area"
              shape="poly"
              coords="90,15,99,19,99,30,90,34,81,30,81,19"
              onClick={(e) => onColorClick("0099CC", [60, 89])}
              onMouseOver={(e) => onMouseOver("0099CC")}
              alt="0099CC"
            />
            <area
              className="area"
              shape="poly"
              coords="108,15,117,19,117,30,108,34,99,30,99,19"
              onClick={(e) => onColorClick("0066CC", [60, 107])}
              onMouseOver={(e) => onMouseOver("0066CC")}
              alt="0066CC"
            />
            <area
              className="area"
              shape="poly"
              coords="126,15,135,19,135,30,126,34,117,30,117,19"
              onClick={(e) => onColorClick("0033CC", [60, 125])}
              onMouseOver={(e) => onMouseOver("0033CC")}
              alt="0033CC"
            />
            <area
              className="area"
              shape="poly"
              coords="144,15,153,19,153,30,144,34,135,30,135,19"
              onClick={(e) => onColorClick("0000FF", [60, 143])}
              onMouseOver={(e) => onMouseOver("0000FF")}
              alt="0000FF"
            />
            <area
              className="area"
              shape="poly"
              coords="162,15,171,19,171,30,162,34,153,30,153,19"
              onClick={(e) => onColorClick("3333FF", [60, 161])}
              onMouseOver={(e) => onMouseOver("3333FF")}
              alt="3333FF"
            />
            <area
              className="area"
              shape="poly"
              coords="180,15,189,19,189,30,180,34,171,30,171,19"
              onClick={(e) => onColorClick("333399", [60, 178])}
              onMouseOver={(e) => onMouseOver("333399")}
              alt="333399"
            />
            <area
              className="area"
              shape="poly"
              coords="45,30,54,34,54,45,45,49,36,45,36,34"
              onClick={(e) => onColorClick("669999", [75, 42])}
              onMouseOver={(e) => onMouseOver("669999")}
              alt="669999"
            />
            <area
              className="area"
              shape="poly"
              coords="63,30,72,34,72,45,63,49,54,45,54,34"
              onClick={(e) => onColorClick("009999", [75, 62])}
              onMouseOver={(e) => onMouseOver("009999")}
              alt="009999"
            />
            <area
              className="area"
              shape="poly"
              coords="81,30,90,34,90,45,81,49,72,45,72,34"
              onClick={(e) => onColorClick("33CCCC", [75, 80])}
              onMouseOver={(e) => onMouseOver("33CCCC")}
              alt="33CCCC"
            />
            <area
              className="area"
              shape="poly"
              coords="99,30,108,34,108,45,99,49,90,45,90,34"
              onClick={(e) => onColorClick("00CCFF", [75, 98])}
              onMouseOver={(e) => onMouseOver("00CCFF")}
              alt="00CCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="117,30,126,34,126,45,117,49,108,45,108,34"
              onClick={(e) => onColorClick("0099FF", [75, 116])}
              onMouseOver={(e) => onMouseOver("0099FF")}
              alt="0099FF"
            />
            <area
              className="area"
              shape="poly"
              coords="135,30,144,34,144,45,135,49,126,45,126,34"
              onClick={(e) => onColorClick("0066FF", [75, 134])}
              onMouseOver={(e) => onMouseOver("0066FF")}
              alt="0066FF"
            />
            <area
              className="area"
              shape="poly"
              coords="153,30,162,34,162,45,153,49,144,45,144,34"
              onClick={(e) => onColorClick("3366FF", [75, 152])}
              onMouseOver={(e) => onMouseOver("3366FF")}
              alt="3366FF"
            />
            <area
              className="area"
              shape="poly"
              coords="171,30,180,34,180,45,171,49,162,45,162,34"
              onClick={(e) => onColorClick("3333CC", [75, 170])}
              onMouseOver={(e) => onMouseOver("3333CC")}
              alt="3333CC"
            />
            <area
              className="area"
              shape="poly"
              coords="189,30,198,34,198,45,189,49,180,45,180,34"
              onClick={(e) => onColorClick("666699", [75, 188])}
              onMouseOver={(e) => onMouseOver("666699")}
              alt="666699"
            />
            <area
              className="area"
              shape="poly"
              coords="36,45,45,49,45,60,36,64,27,60,27,49"
              onClick={(e) => onColorClick("339966", [90, 36])}
              onMouseOver={(e) => onMouseOver("339966")}
              alt="339966"
            />
            <area
              className="area"
              shape="poly"
              coords="54,45,63,49,63,60,54,64,45,60,45,49"
              onClick={(e) => onColorClick("00CC99", [90, 53])}
              onMouseOver={(e) => onMouseOver("00CC99")}
              alt="00CC99"
            />
            <area
              className="area"
              shape="poly"
              coords="72,45,81,49,81,60,72,64,63,60,63,49"
              onClick={(e) => onColorClick("00FFCC", [90, 71])}
              onMouseOver={(e) => onMouseOver("00FFCC")}
              alt="00FFCC"
            />
            <area
              className="area"
              shape="poly"
              coords="90,45,99,49,99,60,90,64,81,60,81,49"
              onClick={(e) => onColorClick("00FFFF", [90, 89])}
              onMouseOver={(e) => onMouseOver("00FFFF")}
              alt="00FFFF"
            />
            <area
              className="area"
              shape="poly"
              coords="108,45,117,49,117,60,108,64,99,60,99,49"
              onClick={(e) => onColorClick("33CCFF", [90, 107])}
              onMouseOver={(e) => onMouseOver("33CCFF")}
              alt="33CCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="126,45,135,49,135,60,126,64,117,60,117,49"
              onClick={(e) => onColorClick("3399FF", [90, 125])}
              onMouseOver={(e) => onMouseOver("3399FF")}
              alt="3399FF"
            />
            <area
              className="area"
              shape="poly"
              coords="144,45,153,49,153,60,144,64,135,60,135,49"
              onClick={(e) => onColorClick("6699FF", [90, 143])}
              onMouseOver={(e) => onMouseOver("6699FF")}
              alt="6699FF"
            />
            <area
              className="area"
              shape="poly"
              coords="162,45,171,49,171,60,162,64,153,60,153,49"
              onClick={(e) => onColorClick("6666FF", [90, 161])}
              onMouseOver={(e) => onMouseOver("6666FF")}
              alt="6666FF"
            />
            <area
              className="area"
              shape="poly"
              coords="180,45,189,49,189,60,180,64,171,60,171,49"
              onClick={(e) => onColorClick("6600FF", [90, 178])}
              onMouseOver={(e) => onMouseOver("6600FF")}
              alt="6600FF"
            />
            <area
              className="area"
              shape="poly"
              coords="198,45,207,49,207,60,198,64,189,60,189,49"
              onClick={(e) => onColorClick("6600CC", [90, 197])}
              onMouseOver={(e) => onMouseOver("6600CC")}
              alt="6600CC"
            />
            <area
              className="area"
              shape="poly"
              coords="27,60,36,64,36,75,27,79,18,75,18,64"
              onClick={(e) => onColorClick("339933", [105, 26])}
              onMouseOver={(e) => onMouseOver("339933")}
              alt="339933"
            />
            <area
              className="area"
              shape="poly"
              coords="45,60,54,64,54,75,45,79,36,75,36,64"
              onClick={(e) => onColorClick("00CC66", [105, 42])}
              onMouseOver={(e) => onMouseOver("00CC66")}
              alt="00CC66"
            />
            <area
              className="area"
              shape="poly"
              coords="63,60,72,64,72,75,63,79,54,75,54,64"
              onClick={(e) => onColorClick("00FF99", [105, 62])}
              onMouseOver={(e) => onMouseOver("00FF99")}
              alt="00FF99"
            />
            <area
              className="area"
              shape="poly"
              coords="81,60,90,64,90,75,81,79,72,75,72,64"
              onClick={(e) => onColorClick("66FFCC", [105, 80])}
              onMouseOver={(e) => onMouseOver("66FFCC")}
              alt="66FFCC"
            />
            <area
              className="area"
              shape="poly"
              coords="99,60,108,64,108,75,99,79,90,75,90,64"
              onClick={(e) => onColorClick("66FFFF", [105, 98])}
              onMouseOver={(e) => onMouseOver("66FFFF")}
              alt="66FFFF"
            />
            <area
              className="area"
              shape="poly"
              coords="117,60,126,64,126,75,117,79,108,75,108,64"
              onClick={(e) => onColorClick("66CCFF", [105, 116])}
              onMouseOver={(e) => onMouseOver("66CCFF")}
              alt="66CCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="135,60,144,64,144,75,135,79,126,75,126,64"
              onClick={(e) => onColorClick("99CCFF", [105, 134])}
              onMouseOver={(e) => onMouseOver("99CCFF")}
              alt="99CCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="153,60,162,64,162,75,153,79,144,75,144,64"
              onClick={(e) => onColorClick("9999FF", [105, 152])}
              onMouseOver={(e) => onMouseOver("9999FF")}
              alt="9999FF"
            />
            <area
              className="area"
              shape="poly"
              coords="171,60,180,64,180,75,171,79,162,75,162,64"
              onClick={(e) => onColorClick("9966FF", [105, 170])}
              onMouseOver={(e) => onMouseOver("9966FF")}
              alt="9966FF"
            />
            <area
              className="area"
              shape="poly"
              coords="189,60,198,64,198,75,189,79,180,75,180,64"
              onClick={(e) => onColorClick("9933FF", [105, 188])}
              onMouseOver={(e) => onMouseOver("9933FF")}
              alt="9933FF"
            />
            <area
              className="area"
              shape="poly"
              coords="207,60,216,64,216,75,207,79,198,75,198,64"
              onClick={(e) => onColorClick("9900FF", [105, 206])}
              onMouseOver={(e) => onMouseOver("9900FF")}
              alt="9900FF"
            />
            <area
              className="area"
              shape="poly"
              coords="18,75,27,79,27,90,18,94,9,90,9,79"
              onClick={(e) => onColorClick("006600", [120, 17])}
              onMouseOver={(e) => onMouseOver("006600")}
              alt="006600"
            />
            <area
              className="area"
              shape="poly"
              coords="36,75,45,79,45,90,36,94,27,90,27,79"
              onClick={(e) => onColorClick("00CC00", [120, 36])}
              onMouseOver={(e) => onMouseOver("00CC00")}
              alt="00CC00"
            />
            <area
              className="area"
              shape="poly"
              coords="54,75,63,79,63,90,54,94,45,90,45,79"
              onClick={(e) => onColorClick("00FF00", [120, 53])}
              onMouseOver={(e) => onMouseOver("00FF00")}
              alt="00FF00"
            />
            <area
              className="area"
              shape="poly"
              coords="72,75,81,79,81,90,72,94,63,90,63,79"
              onClick={(e) => onColorClick("66FF99", [120, 71])}
              onMouseOver={(e) => onMouseOver("66FF99")}
              alt="66FF99"
            />
            <area
              className="area"
              shape="poly"
              coords="90,75,99,79,99,90,90,94,81,90,81,79"
              onClick={(e) => onColorClick("99FFCC", [120, 89])}
              onMouseOver={(e) => onMouseOver("99FFCC")}
              alt="99FFCC"
            />
            <area
              className="area"
              shape="poly"
              coords="108,75,117,79,117,90,108,94,99,90,99,79"
              onClick={(e) => onColorClick("CCFFFF", [120, 107])}
              onMouseOver={(e) => onMouseOver("CCFFFF")}
              alt="CCFFFF"
            />
            <area
              className="area"
              shape="poly"
              coords="126,75,135,79,135,90,126,94,117,90,117,79"
              onClick={(e) => onColorClick("CCCCFF", [120, 125])}
              onMouseOver={(e) => onMouseOver("CCCCFF")}
              alt="CCCCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="144,75,153,79,153,90,144,94,135,90,135,79"
              onClick={(e) => onColorClick("CC99FF", [120, 143])}
              onMouseOver={(e) => onMouseOver("CC99FF")}
              alt="CC99FF"
            />
            <area
              className="area"
              shape="poly"
              coords="162,75,171,79,171,90,162,94,153,90,153,79"
              onClick={(e) => onColorClick("CC66FF", [120, 161])}
              onMouseOver={(e) => onMouseOver("CC66FF")}
              alt="CC66FF"
            />
            <area
              className="area"
              shape="poly"
              coords="180,75,189,79,189,90,180,94,171,90,171,79"
              onClick={(e) => onColorClick("CC33FF", [120, 178])}
              onMouseOver={(e) => onMouseOver("CC33FF")}
              alt="CC33FF"
            />
            <area
              className="area"
              shape="poly"
              coords="198,75,207,79,207,90,198,94,189,90,189,79"
              onClick={(e) => onColorClick("CC00FF", [120, 197])}
              onMouseOver={(e) => onMouseOver("CC00FF")}
              alt="CC00FF"
            />
            <area
              className="area"
              shape="poly"
              coords="216,75,225,79,225,90,216,94,207,90,207,79"
              onClick={(e) => onColorClick("9900CC", [120, 215])}
              onMouseOver={(e) => onMouseOver("9900CC")}
              alt="9900CC"
            />
            <area
              className="area"
              shape="poly"
              coords="9,90,18,94,18,105,9,109,0,105,0,94"
              onClick={(e) => onColorClick("003300", [135, 8])}
              onMouseOver={(e) => onMouseOver("003300")}
              alt="003300"
            />
            <area
              className="area"
              shape="poly"
              coords="27,90,36,94,36,105,27,109,18,105,18,94"
              onClick={(e) => onColorClick("009933", [135, 26])}
              onMouseOver={(e) => onMouseOver("009933")}
              alt="009933"
            />
            <area
              className="area"
              shape="poly"
              coords="45,90,54,94,54,105,45,109,36,105,36,94"
              onClick={(e) => onColorClick("33CC33", [135, 42])}
              onMouseOver={(e) => onMouseOver("33CC33")}
              alt="33CC33"
            />
            <area
              className="area"
              shape="poly"
              coords="63,90,72,94,72,105,63,109,54,105,54,94"
              onClick={(e) => onColorClick("66FF66", [135, 62])}
              onMouseOver={(e) => onMouseOver("66FF66")}
              alt="66FF66"
            />
            <area
              className="area"
              shape="poly"
              coords="81,90,90,94,90,105,81,109,72,105,72,94"
              onClick={(e) => onColorClick("99FF99", [135, 80])}
              onMouseOver={(e) => onMouseOver("99FF99")}
              alt="99FF99"
            />
            <area
              className="area"
              shape="poly"
              coords="99,90,108,94,108,105,99,109,90,105,90,94"
              onClick={(e) => onColorClick("CCFFCC", [135, 98])}
              onMouseOver={(e) => onMouseOver("CCFFCC")}
              alt="CCFFCC"
            />
            <area
              className="area"
              shape="poly"
              coords="117,90,126,94,126,105,117,109,108,105,108,94"
              onClick={(e) => onColorClick("FFFFFF", [135, 116])}
              onMouseOver={(e) => onMouseOver("FFFFFF")}
              alt="FFFFFF"
            />
            <area
              className="area"
              shape="poly"
              coords="135,90,144,94,144,105,135,109,126,105,126,94"
              onClick={(e) => onColorClick("FFCCFF", [135, 134])}
              onMouseOver={(e) => onMouseOver("FFCCFF")}
              alt="FFCCFF"
            />
            <area
              className="area"
              shape="poly"
              coords="153,90,162,94,162,105,153,109,144,105,144,94"
              onClick={(e) => onColorClick("FF99FF", [135, 152])}
              onMouseOver={(e) => onMouseOver("FF99FF")}
              alt="FF99FF"
            />
            <area
              className="area"
              shape="poly"
              coords="171,90,180,94,180,105,171,109,162,105,162,94"
              onClick={(e) => onColorClick("FF66FF", [135, 170])}
              onMouseOver={(e) => onMouseOver("FF66FF")}
              alt="FF66FF"
            />
            <area
              className="area"
              shape="poly"
              coords="189,90,198,94,198,105,189,109,180,105,180,94"
              onClick={(e) => onColorClick("FF00FF", [135, 188])}
              onMouseOver={(e) => onMouseOver("FF00FF")}
              alt="FF00FF"
            />
            <area
              className="area"
              shape="poly"
              coords="207,90,216,94,216,105,207,109,198,105,198,94"
              onClick={(e) => onColorClick("CC00CC", [135, 206])}
              onMouseOver={(e) => onMouseOver("CC00CC")}
              alt="CC00CC"
            />
            <area
              className="area"
              shape="poly"
              coords="225,90,234,94,234,105,225,109,216,105,216,94"
              onClick={(e) => onColorClick("660066", [135, 224])}
              onMouseOver={(e) => onMouseOver("660066")}
              alt="660066"
            />
            <area
              className="area"
              shape="poly"
              coords="18,105,27,109,27,120,18,124,9,120,9,109"
              onClick={(e) => onColorClick("336600", [150, 17])}
              onMouseOver={(e) => onMouseOver("336600")}
              alt="336600"
            />
            <area
              className="area"
              shape="poly"
              coords="36,105,45,109,45,120,36,124,27,120,27,109"
              onClick={(e) => onColorClick("009900", [150, 36])}
              onMouseOver={(e) => onMouseOver("009900")}
              alt="009900"
            />
            <area
              className="area"
              shape="poly"
              coords="54,105,63,109,63,120,54,124,45,120,45,109"
              onClick={(e) => onColorClick("66FF33", [150, 53])}
              onMouseOver={(e) => onMouseOver("66FF33")}
              alt="66FF33"
            />
            <area
              className="area"
              shape="poly"
              coords="72,105,81,109,81,120,72,124,63,120,63,109"
              onClick={(e) => onColorClick("99FF66", [150, 71])}
              onMouseOver={(e) => onMouseOver("99FF66")}
              alt="99FF66"
            />
            <area
              className="area"
              shape="poly"
              coords="90,105,99,109,99,120,90,124,81,120,81,109"
              onClick={(e) => onColorClick("CCFF99", [150, 89])}
              onMouseOver={(e) => onMouseOver("CCFF99")}
              alt="CCFF99"
            />
            <area
              className="area"
              shape="poly"
              coords="108,105,117,109,117,120,108,124,99,120,99,109"
              onClick={(e) => onColorClick("FFFFCC", [150, 107])}
              onMouseOver={(e) => onMouseOver("FFFFCC")}
              alt="FFFFCC"
            />
            <area
              className="area"
              shape="poly"
              coords="126,105,135,109,135,120,126,124,117,120,117,109"
              onClick={(e) => onColorClick("FFCCCC", [150, 125])}
              onMouseOver={(e) => onMouseOver("FFCCCC")}
              alt="FFCCCC"
            />
            <area
              className="area"
              shape="poly"
              coords="144,105,153,109,153,120,144,124,135,120,135,109"
              onClick={(e) => onColorClick("FF99CC", [150, 143])}
              onMouseOver={(e) => onMouseOver("FF99CC")}
              alt="FF99CC"
            />
            <area
              className="area"
              shape="poly"
              coords="162,105,171,109,171,120,162,124,153,120,153,109"
              onClick={(e) => onColorClick("FF66CC", [150, 161])}
              onMouseOver={(e) => onMouseOver("FF66CC")}
              alt="FF66CC"
            />
            <area
              className="area"
              shape="poly"
              coords="180,105,189,109,189,120,180,124,171,120,171,109"
              onClick={(e) => onColorClick("FF33CC", [150, 178])}
              onMouseOver={(e) => onMouseOver("FF33CC")}
              alt="FF33CC"
            />
            <area
              className="area"
              shape="poly"
              coords="198,105,207,109,207,120,198,124,189,120,189,109"
              onClick={(e) => onColorClick("CC0099", [150, 197])}
              onMouseOver={(e) => onMouseOver("CC0099")}
              alt="CC0099"
            />
            <area
              className="area"
              shape="poly"
              coords="216,105,225,109,225,120,216,124,207,120,207,109"
              onClick={(e) => onColorClick("993399", [150, 215])}
              onMouseOver={(e) => onMouseOver("993399")}
              alt="993399"
            />
            <area
              className="area"
              shape="poly"
              coords="27,120,36,124,36,135,27,139,18,135,18,124"
              onClick={(e) => onColorClick("333300", [165, 26])}
              onMouseOver={(e) => onMouseOver("333300")}
              alt="333300"
            />
            <area
              className="area"
              shape="poly"
              coords="45,120,54,124,54,135,45,139,36,135,36,124"
              onClick={(e) => onColorClick("669900", [165, 42])}
              onMouseOver={(e) => onMouseOver("669900")}
              alt="669900"
            />
            <area
              className="area"
              shape="poly"
              coords="63,120,72,124,72,135,63,139,54,135,54,124"
              onClick={(e) => onColorClick("99FF33", [165, 62])}
              onMouseOver={(e) => onMouseOver("99FF33")}
              alt="99FF33"
            />
            <area
              className="area"
              shape="poly"
              coords="81,120,90,124,90,135,81,139,72,135,72,124"
              onClick={(e) => onColorClick("CCFF66", [165, 80])}
              onMouseOver={(e) => onMouseOver("CCFF66")}
              alt="CCFF66"
            />
            <area
              className="area"
              shape="poly"
              coords="99,120,108,124,108,135,99,139,90,135,90,124"
              onClick={(e) => onColorClick("FFFF99", [165, 98])}
              onMouseOver={(e) => onMouseOver("FFFF99")}
              alt="FFFF99"
            />
            <area
              className="area"
              shape="poly"
              coords="117,120,126,124,126,135,117,139,108,135,108,124"
              onClick={(e) => onColorClick("FFCC99", [165, 116])}
              onMouseOver={(e) => onMouseOver("FFCC99")}
              alt="FFCC99"
            />
            <area
              className="area"
              shape="poly"
              coords="135,120,144,124,144,135,135,139,126,135,126,124"
              onClick={(e) => onColorClick("FF9999", [165, 134])}
              onMouseOver={(e) => onMouseOver("FF9999")}
              alt="FF9999"
            />
            <area
              className="area"
              shape="poly"
              coords="153,120,162,124,162,135,153,139,144,135,144,124"
              onClick={(e) => onColorClick("FF6699", [165, 152])}
              onMouseOver={(e) => onMouseOver("FF6699")}
              alt="FF6699"
            />
            <area
              className="area"
              shape="poly"
              coords="171,120,180,124,180,135,171,139,162,135,162,124"
              onClick={(e) => onColorClick("FF3399", [165, 170])}
              onMouseOver={(e) => onMouseOver("FF3399")}
              alt="FF3399"
            />
            <area
              className="area"
              shape="poly"
              coords="189,120,198,124,198,135,189,139,180,135,180,124"
              onClick={(e) => onColorClick("CC3399", [165, 188])}
              onMouseOver={(e) => onMouseOver("CC3399")}
              alt="CC3399"
            />
            <area
              className="area"
              shape="poly"
              coords="207,120,216,124,216,135,207,139,198,135,198,124"
              onClick={(e) => onColorClick("990099", [165, 206])}
              onMouseOver={(e) => onMouseOver("990099")}
              alt="990099"
            />
            <area
              className="area"
              shape="poly"
              coords="36,135,45,139,45,150,36,154,27,150,27,139"
              onClick={(e) => onColorClick("666633", [180, 36])}
              onMouseOver={(e) => onMouseOver("666633")}
              alt="666633"
            />
            <area
              className="area"
              shape="poly"
              coords="54,135,63,139,63,150,54,154,45,150,45,139"
              onClick={(e) => onColorClick("99CC00", [180, 53])}
              onMouseOver={(e) => onMouseOver("99CC00")}
              alt="99CC00"
            />
            <area
              className="area"
              shape="poly"
              coords="72,135,81,139,81,150,72,154,63,150,63,139"
              onClick={(e) => onColorClick("CCFF33", [180, 71])}
              onMouseOver={(e) => onMouseOver("CCFF33")}
              alt="CCFF33"
            />
            <area
              className="area"
              shape="poly"
              coords="90,135,99,139,99,150,90,154,81,150,81,139"
              onClick={(e) => onColorClick("FFFF66", [180, 89])}
              onMouseOver={(e) => onMouseOver("FFFF66")}
              alt="FFFF66"
            />
            <area
              className="area"
              shape="poly"
              coords="108,135,117,139,117,150,108,154,99,150,99,139"
              onClick={(e) => onColorClick("FFCC66", [180, 107])}
              onMouseOver={(e) => onMouseOver("FFCC66")}
              alt="FFCC66"
            />
            <area
              className="area"
              shape="poly"
              coords="126,135,135,139,135,150,126,154,117,150,117,139"
              onClick={(e) => onColorClick("FF9966", [180, 125])}
              onMouseOver={(e) => onMouseOver("FF9966")}
              alt="FF9966"
            />
            <area
              className="area"
              shape="poly"
              coords="144,135,153,139,153,150,144,154,135,150,135,139"
              onClick={(e) => onColorClick("FF6666", [180, 143])}
              onMouseOver={(e) => onMouseOver("FF6666")}
              alt="FF6666"
            />
            <area
              className="area"
              shape="poly"
              coords="162,135,171,139,171,150,162,154,153,150,153,139"
              onClick={(e) => onColorClick("FF0066", [180, 161])}
              onMouseOver={(e) => onMouseOver("FF0066")}
              alt="FF0066"
            />
            <area
              className="area"
              shape="poly"
              coords="180,135,189,139,189,150,180,154,171,150,171,139"
              onClick={(e) => onColorClick("CC6699", [180, 178])}
              onMouseOver={(e) => onMouseOver("CC6699")}
              alt="CC6699"
            />
            <area
              className="area"
              shape="poly"
              coords="198,135,207,139,207,150,198,154,189,150,189,139"
              onClick={(e) => onColorClick("993366", [180, 197])}
              onMouseOver={(e) => onMouseOver("993366")}
              alt="993366"
            />
            <area
              className="area"
              shape="poly"
              coords="45,150,54,154,54,165,45,169,36,165,36,154"
              onClick={(e) => onColorClick("999966", [195, 42])}
              onMouseOver={(e) => onMouseOver("999966")}
              alt="999966"
            />
            <area
              className="area"
              shape="poly"
              coords="63,150,72,154,72,165,63,169,54,165,54,154"
              onClick={(e) => onColorClick("CCCC00", [195, 62])}
              onMouseOver={(e) => onMouseOver("CCCC00")}
              alt="CCCC00"
            />
            <area
              className="area"
              shape="poly"
              coords="81,150,90,154,90,165,81,169,72,165,72,154"
              onClick={(e) => onColorClick("FFFF00", [195, 80])}
              onMouseOver={(e) => onMouseOver("FFFF00")}
              alt="FFFF00"
            />
            <area
              className="area"
              shape="poly"
              coords="99,150,108,154,108,165,99,169,90,165,90,154"
              onClick={(e) => onColorClick("FFCC00", [195, 98])}
              onMouseOver={(e) => onMouseOver("FFCC00")}
              alt="FFCC00"
            />
            <area
              className="area"
              shape="poly"
              coords="117,150,126,154,126,165,117,169,108,165,108,154"
              onClick={(e) => onColorClick("FF9933", [195, 116])}
              onMouseOver={(e) => onMouseOver("FF9933")}
              alt="FF9933"
            />
            <area
              className="area"
              shape="poly"
              coords="135,150,144,154,144,165,135,169,126,165,126,154"
              onClick={(e) => onColorClick("FF6600", [195, 134])}
              onMouseOver={(e) => onMouseOver("FF6600")}
              alt="FF6600"
            />
            <area
              className="area"
              shape="poly"
              coords="153,150,162,154,162,165,153,169,144,165,144,154"
              onClick={(e) => onColorClick("FF5050", [195, 152])}
              onMouseOver={(e) => onMouseOver("FF5050")}
              alt="FF5050"
            />
            <area
              className="area"
              shape="poly"
              coords="171,150,180,154,180,165,171,169,162,165,162,154"
              onClick={(e) => onColorClick("CC0066", [195, 170])}
              onMouseOver={(e) => onMouseOver("CC0066")}
              alt="CC0066"
            />
            <area
              className="area"
              shape="poly"
              coords="189,150,198,154,198,165,189,169,180,165,180,154"
              onClick={(e) => onColorClick("660033", [195, 188])}
              onMouseOver={(e) => onMouseOver("660033")}
              alt="660033"
            />
            <area
              className="area"
              shape="poly"
              coords="54,165,63,169,63,180,54,184,45,180,45,169"
              onClick={(e) => onColorClick("996633", [210, 53])}
              onMouseOver={(e) => onMouseOver("996633")}
              alt="996633"
            />
            <area
              className="area"
              shape="poly"
              coords="72,165,81,169,81,180,72,184,63,180,63,169"
              onClick={(e) => onColorClick("CC9900", [210, 71])}
              onMouseOver={(e) => onMouseOver("CC9900")}
              alt="CC9900"
            />
            <area
              className="area"
              shape="poly"
              coords="90,165,99,169,99,180,90,184,81,180,81,169"
              onClick={(e) => onColorClick("FF9900", [210, 89])}
              onMouseOver={(e) => onMouseOver("FF9900")}
              alt="FF9900"
            />
            <area
              className="area"
              shape="poly"
              coords="108,165,117,169,117,180,108,184,99,180,99,169"
              onClick={(e) => onColorClick("CC6600", [210, 107])}
              onMouseOver={(e) => onMouseOver("CC6600")}
              alt="CC6600"
            />
            <area
              className="area"
              shape="poly"
              coords="126,165,135,169,135,180,126,184,117,180,117,169"
              onClick={(e) => onColorClick("FF3300", [210, 125])}
              onMouseOver={(e) => onMouseOver("FF3300")}
              alt="FF3300"
            />
            <area
              className="area"
              shape="poly"
              coords="144,165,153,169,153,180,144,184,135,180,135,169"
              onClick={(e) => onColorClick("FF0000", [210, 143])}
              onMouseOver={(e) => onMouseOver("FF0000")}
              alt="FF0000"
            />
            <area
              className="area"
              shape="poly"
              coords="162,165,171,169,171,180,162,184,153,180,153,169"
              onClick={(e) => onColorClick("CC0000", [210, 161])}
              onMouseOver={(e) => onMouseOver("CC0000")}
              alt="CC0000"
            />
            <area
              className="area"
              shape="poly"
              coords="180,165,189,169,189,180,180,184,171,180,171,169"
              onClick={(e) => onColorClick("990033", [210, 178])}
              onMouseOver={(e) => onMouseOver("990033")}
              alt="990033"
            />
            <area
              className="area"
              shape="poly"
              coords="63,180,72,184,72,195,63,199,54,195,54,184"
              onClick={(e) => onColorClick("663300", [225, 62])}
              onMouseOver={(e) => onMouseOver("663300")}
              alt="663300"
            />
            <area
              className="area"
              shape="poly"
              coords="81,180,90,184,90,195,81,199,72,195,72,184"
              onClick={(e) => onColorClick("996600", [225, 80])}
              onMouseOver={(e) => onMouseOver("996600")}
              alt="996600"
            />
            <area
              className="area"
              shape="poly"
              coords="99,180,108,184,108,195,99,199,90,195,90,184"
              onClick={(e) => onColorClick("CC3300", [225, 98])}
              onMouseOver={(e) => onMouseOver("CC3300")}
              alt="CC3300"
            />
            <area
              className="area"
              shape="poly"
              coords="117,180,126,184,126,195,117,199,108,195,108,184"
              onClick={(e) => onColorClick("993300", [225, 116])}
              onMouseOver={(e) => onMouseOver("993300")}
              alt="993300"
            />
            <area
              className="area"
              shape="poly"
              coords="135,180,144,184,144,195,135,199,126,195,126,184"
              onClick={(e) => onColorClick("990000", [225, 134])}
              onMouseOver={(e) => onMouseOver("990000")}
              alt="990000"
            />
            <area
              className="area"
              shape="poly"
              coords="153,180,162,184,162,195,153,199,144,195,144,184"
              onClick={(e) => onColorClick("800000", [225, 152])}
              onMouseOver={(e) => onMouseOver("800000")}
              alt="800000"
            />
            <area
              className="area"
              shape="poly"
              coords="171,180,180,184,180,195,171,199,162,195,162,184"
              onClick={(e) => onColorClick("993333", [225, 170])}
              onMouseOver={(e) => onMouseOver("993333")}
              alt="993333"
            />
          </map>
        </div>
        <div
          className="colorselector"
          style={{ display: "block", top: top, left: left }}
        ></div>
        <div className="colorinputbox">
          <div className="helpmsg">
            {t("chart5.colorHelpMsg1")}
            <br />
            {t("chart5.colorHelpMsg2")}
          </div>
          <div className="jscolor-input">
            <input
              ref={colorInput}
              className="Polaris-TextField__Input jscolor-input {hash:true,styleElement:'',onFineChange:'jsColorOnFineChange(this)'}"
              value={value}
              readOnly
              style={{
                float: "none",
                backgroundImage: "none",
                backgroundColor: "#" + value,
                color: value === "FFFFFF" ? "#000000" : "#FFFFFF",
              }}
              onClick={onInputClick}
              onFocus={showColorPicker}
              data-jscolor={`{backgroundColor:'${
                props.ChgThemeData === "Light" ? "#ffffff" : "#373737"
              }', borderColor: '${
                props.ChgThemeData === "Light" ? "#dddddd" : "#222429"
              }'}`}
            />
          </div>
        </div>
      </div>
    </>
  );
});
