import React from 'react'
import { withTranslation } from 'react-i18next'
import common from '@/asserts/js/common'
import { 
  closeImg,
  searchImg
} from '@/asserts/imgs/common/index'
import DropDown from '../../DropDown/index'
import APPstyles from '@/App.less'
import styles from './index.less'

class InputCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      optionsNameData:[],
      isDownLoading:false,
      isDropdownShow:false,
      index3456:[3,4,5,6]
    }
    this.timer = null
  }
  //powersearch--获取数据
  getPowersearch(value){
    let token=window.sessionStorage.getItem('token')
    fetch(common.getHttp+'powersearch',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({token:token,k:value,limit:10,tradable:true})
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        // 处理数据--flag
        let resData=res['data']['datalist']
        if(resData.length){
          for(let i in resData){
            resData[i]['img']=common.imgShortNameFlag[resData[i]['exchange_country_code']]
          }
          resData=common.imgDataFlagFun(resData,'img')
        }
        this.setState({
          optionsNameData:resData,
          isDownLoading:false,
          isDropdownShow:true
        })
        return
      }
      //获取失败
      this.setState({
        isDownLoading: false
      })
    }).catch(err => {
      console.log(err)
    })
  }
  //input值的变化
  changeInputClick(e) {
    let value=e['target']['value']
    this.setState({
      inputValue: value,
      isDownLoading:value?true:false,
      optonsNameIndex:null,
      isDropdownShow:value?true:false,
      optionsNameData:[]
    })
    if(value=='') return
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      this.getPowersearch(value)
    }, 500)
  }
  //清空input的值
  clearInputClick(){
    this.setState({
      inputValue:'',
      optonsNameIndex:null,
      optionsNameData:[],
      isDownLoading:false,
      isDropdownShow:false
    })
    clearTimeout(this.timer)
  }
  //下拉框--选中某个--input
  dropDownInputClick(item){
    this.setState({
      inputValue: '',
      isDropdownShow:false
    })
    let data=[item['fbstockid'],item['exchange_country_code']]
    this.props.callbackInputData(data)
  }
  //点击外部关闭下拉框
  handleInputClick = e => {
    if(!this.inputComponent.contains(e.target)) {
      this.setState ({
        isDropdownShow:false,
        inputValue:''
      })
    }
  }
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {closeInput}=this.props
    //关闭下拉框
    if(closeInput!==newProps['closeInput']){
      this.setState ({
        isDropdownShow:false,
        inputValue:''
      })
    }
  }
  //初始化
  componentDidMount() {
    document.addEventListener('click', this.handleInputClick)
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleInputClick)
  }
  render() {
    const { t,ChgThemeData,dropDownL,dropDownR,dropDownT,dropDownW,accountIndex} = this.props
    const {
      inputValue,
      isDownLoading,
      optionsNameData,
      isDropdownShow,
      index3456
    } = this.state
    return (
      <div
        className={[
          APPstyles.flex_row,
          styles.inputs_con,
          ChgThemeData=='Light'?APPstyles.bg_chart_light:APPstyles.bg_black,
          ChgThemeData=='Light'?styles.inputs_con_light:''
        ].join(" ")}
        onClick={(e)=>{
          e.stopPropagation()
          if(index3456.includes(accountIndex)){
            this.props.cbCloseAll()
          }
        }}
      >
        <img
          src={searchImg}
          className={APPstyles.img16}
          style={{
            display:inputValue?'none':''
          }}
          alt="search"
        />
        <input
          autoComplete="off"
          placeholder={t("Name/Symbol")}
          value={inputValue}
          onChange={this.changeInputClick.bind(this)}
        />
        <img 
          src={closeImg} 
          className={APPstyles.img16} 
          style={{display:inputValue?'':'none'}}
          onClick={this.clearInputClick.bind(this)} 
          alt='clear' 
        />
        {/* 下拉框 */}
        <div ref = {testComponent => this.inputComponent = testComponent}>
        {
          isDropdownShow?
            <div
              className={styles.dropsDown_con}
              style={{
                width:dropDownW,
                top:dropDownT,
                left:dropDownL,
                right:dropDownR
              }}
            >
              <DropDown
                {...this.props}
                tag='inputcon'
                data={optionsNameData}
                isLoading={isDownLoading}
                cbDropDownData={(data)=>{
                  this.setState({
                    inputValue: '',
                    isDropdownShow:false
                  })
                  let result=[data[0]['fbstockid'],data[0]['exchange_country_code']]
                  this.props.callbackInputData(result)
                }}
              />
            </div>
          :
            ''
        }
        </div>   
      </div>
      )
  }
}
export default withTranslation()(InputCon)
