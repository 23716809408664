
import dropUpImg from '../imgs/common/DropUp.png'
import dropUpOnImg from '../imgs/common/DropUpOn.png'
import dropUpOnLightImg from '../imgs/common/DropUpOnLight.png'
import dropDownImg from '../imgs/common/dropDown.png'
import dropDownOnImg from '../imgs/common/dropDownOn.png'
import dropDownOnLightImg from '../imgs/common/dropDownOnLight.png'
//所有的--shing
const getHttp = process.env.REACT_APP_PATH_MARKET_API;
//所有的--zs
const getHttpZs = process.env.REACT_APP_PATH_TRADE_API;
//图表
const getHttpChart = process.env.REACT_APP_PATH_CHART_API;
const getHttpChartInfo = process.env.REACT_APP_PATH_CHART_INFO_API;
//免责声明
const getHttpAgree = process.env.REACT_APP_PATH_EAGREEMENT;
//WEBCA
const getHttpWebca = process.env.REACT_APP_PATH_WEBCA;
//ENABLE WEbCA?
const enableWebca = process.env.REACT_APP_ENABLE_WEBCA === 'true';
const isTestShow = process.env.REACT_APP_TEST_ENV !== 'false';
//月份
const monData = {'JAN': '01', 'FEB':'02', 'MAR':'03', 'APR':'04', 'MAY':'05', 'JUN':'06', 'JUL':'07', 'AUG':'08', 'SEP':'09', 'OCT':'10', 'NOV':'11', 'DEC':'12'}
//国家名称--flag
const imgCountryFullName = ['united states','united kingdom','singapore','japan','hong kong','china','germany','taiwan','euro']
const imgDataFlag = imgCountryFullName.map(item => require('../imgs/flag/' + item + '.png'))
//国家货币--flag
const imgArrFlag = {'USD': 'united states', 'GBP':'united kingdom', 'SGD':'singapore', 'JPY':'japan', 'HKD':'hong kong', 'CNY':'china', 'EUR':'euro', 'TWD':'taiwan'}
//国家缩写--flag
const imgCountryFlag = {'US': 'united states', 'UK':'united kingdom', 'SG':'singapore', 'JPN':'japan', 'HK':'hong kong', 'CHN':'china', 'GER':'germany', 'TW':'taiwan'}
//国家简称--flag
const imgShortNameFlag = {'US': 'united states', 'UK':'united kingdom', 'SG':'singapore', 'JP':'japan', 'HK':'hong kong', 'CN':'china', 'DE':'germany', 'TW':'taiwan', 'SH':'china', 'SZ':'china', 'GB':'united kingdom'}
//国家对应为货币
const imgChgCur = {'US': 'USD', 'UK':'GBP', 'SG':'SGD', 'JPN':'JPY', 'HK':'HKD', 'CHN':'CNY', 'GER':'EUR', 'TW':'TWD'}
const imgShortNameChgCur = {'US': 'USD', 'UK':'GBP', 'SG':'SGD', 'JP':'JPY', 'HK':'HKD', 'CN':'CNY', 'DE':'EUR', 'TW':'TWD', 'SH':'CNY', 'SZ':'CNY','GB':'GBP'}
const shortNameChgCode = {'USD': 'US', 'GBP':'UK', 'SGD':'SG', 'JPY':'JP', 'HKD':'HK', 'CNY':'CN', 'EUR':'DE', 'TWD':'TW', 'GBP':'GB'}
//ios终端
const u = navigator.userAgent
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) 
// 不同的值对应不同的背景色
const speedData=[
  {
      num:'-4%',
      bgColor:'#005521',
      key:-4
  },
  {
      num:'-3%',
      bgColor:'#16763B',
      key:-3
  },
  {
      num:'-2%',
      bgColor:'#38925A',
      key:-2
  },
  {
      num:'-1%',
      bgColor:'#39C46E',
      key:-1
  },
  {
      num:'0%',
      bgColor:'#AFADAD',
      key:0
  },
  {
      num:'1%',
      bgColor:'#F5475B',
      key:1
  },
  {
      num:'2%',
      bgColor:'#AB1829',
      key:2
  },
  {
      num:'3%',
      bgColor:'#830000',
      key:3
  },
  {
      num:'4%',
      bgColor:'#550000',
      key:4
  }
]
// 加‘+’号
const addplusNum = val => { 
  if (val && val !== '-' && val.toString().indexOf('-') === -1 && val !=0) {
    return '+'+val
  }
  return val
}
// 加 ‘%’号
const changeRate = rate => {
  if (rate && rate!=='-') {
    return rate+'%'
  }
  return '-'
}
// 去掉逗号
const delpointer = (sNum) => {
  return sNum.replace(/,/g,'')
}
// len: 留几位小数,四舍五入 
const keepNum = (num,n) => {
  n = Number(n)
  if (!n&&n!=0) {
    return num
  }
  num= String(num)
  let f = parseFloat(num)
  if(isNaN(f) || num === '-'){
    return '-'
  }
  if( num.indexOf(',') == -1){
    num = delpointer(num)
  }
  f = Math.round(num*Math.pow(10, n))/Math.pow(10, n)
  // n 幂
  let s = f.toString()
  let rs = s.indexOf('.')
  //判定如果是整数，增加小数点再补0
  if(rs < 0){
    rs = s.length
    s += '.'
  }
  while(s.length <= rs + n){
    s += '0'
  }
  if(n==0){
    s=s.substring(0,s.length-1)
  }
  return s
}
// 356785123l 转 356,785,123，逗号
const transThousands = nums => { 
  if( nums&&typeof(nums)!=='undefined' && nums!=='-'&&nums!==null){
    let numt,num,result = ''
    if (nums<0) {
      numt = nums.toString().split('-')
      num = (numt[1] || 0).toString().split('.')
    } else {
      num = (nums || 0).toString().split('.')
    }
    while (num[0].length > 3) {
      result = ',' + num[0].slice(-3) + result
      num[0] = num[0].slice(0, num[0].length - 3)
    }
    if (num[0]) {
      if (num[1]){
        return result = (nums<0?'-':'')+ num[0] + result + '.'+num[1]
      }
      return result = (nums<0?'-':'')+ num[0] + result
    }
    return
  }
  return '-'
}
// 拆分数据--1000000->1M
const splitNumM = val => {
  if (val&&String(val)!=='-') {
    if(Number(val)==0){
      return val
    }
    return Number(val)/1000000
  }
  return '-'
}
// 添加M
const addNumM = val => {
  if (val&&String(val)!=='-') {
    if(Number(val)==0){
      return val
    }
    return val+'M'
  }
  return '-'
}
// 拆分数据--1000000000->1B
const splitNumB = val => {
  if (val&&String(val)!=='-') {
    if(Number(val)==0){
      return val
    }
    return Number(val)/1000000000
  }
  return '-'
}
// 添加B
const addNumB = val => {
  if (val&&String(val)!=='-') {
    if(Number(val)==0){
      return val
    }
    return val+'B'
  }
  return '-'
}
// 2019-09-09 转换为 2019/09/09
const onlyFormatDate = (val) => {
  if(val && val !== '-' && typeof(val) !== 'undefined'&& val!=0){
    let arr = val.split('-')
    let result=arr.length==1?arr[0]:arr.length==2?arr[0]+'/'+arr[1]:arr[0]+'/'+arr[1]+'/'+arr[2]
    return result
  }
  return '-'
}
// 10 NOV 2021 转换为 2021/11/10
const changeFormatDate = (val) => {
  if(val && val !== '-' && typeof(val) !== 'undefined'&& val!=0){
    let arr = val.split(' ')
    return arr[2]+'/'+monData[arr[1]]+'/'+arr[0]
  }
  return '-'
}
// 20211117 转换为 2021/11/17
const onlyOrderDate = (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'&& data!=0){
    return data.slice(0,4)+'/'+data.slice(4,6)+'/'+data.slice(6)
  }
  return '-'
}
// 140313 转换为 14:03:13
const onlyOrderTime = (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'&& data!=0){
    return data.slice(0,2)+':'+data.slice(2,4)+':'+data.slice(4)
  }
  return '-'
}
// 20211117 140313 转换为 2021/11/17
const changeOrderDate = (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'&& data!=0){
    let time=data.split(' ')
    return time[0].slice(0,4)+'/'+time[0].slice(4,6)+'/'+time[0].slice(6)
  }
  return '-'
}
// 20211117 140313 转换为 14:03:13
const changeOrderTime = (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'&& data!=0){
    let time=data.split(' ')
    return time[1].slice(0,2)+':'+time[1].slice(2,4)+':'+time[1].slice(4)
  }
  return '-'
}
// USD 18,035.88 转换为 +USD18,035.88
const dealAddCurData = (cur,country,val) => { 
  if (val && val !== '-') {
    let chgCur=Number(cur)==0?imgShortNameChgCur[country]:cur
    if(Number(val)==0){
      return chgCur+val
    }
    if(Number(val)<0){
      return '-'+chgCur+val.slice(1)
    }
    return '+'+chgCur+val
  }
  return '-'
}
// 颜色转为rgba
const hexToRgba = (hex, opacity) => { 
  return hex && hex.replace(/\s+/g, '').length === 7 ? 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' +
    parseInt('0x' + hex.slice(3, 5)) + ',' +
    parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')' : ''
}
//主图----TA线====SMA
const calculateMA = (dayCount,closeValue) => {
  let len=closeValue.length
  let result = []
  for(let i = 0;i < len; i++){
    let t= 0
    if(i - dayCount+1 >= 0){
      for(let j = 0;j < dayCount;j++){
        let arg2=closeValue[i - j]
        t=add(t,arg2)//加法
      }
      let res=parseFloat(divs(t,dayCount).toString().substring(0,8))//除法
      result.push(res)
    }
  }
  return result
}
//解决js浮点数计算bug===加法
const add = (a, b)  => {
  let c, d, e
  try {
    c = a.toString().split('.')[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) {
    d = 0
  }
  return e = Math.pow(10, Math.max(c, d)), (mul(a, e) + mul(b, e)) / e
}
//解决js浮点数计算bug===除法
const divs = (a, b)  => {
  let c, d, e = 0,f = 0
  try {
    e = a.toString().split('.')[1].length
  } catch (g) {}
  try {
    f = b.toString().split('.')[1].length
  } catch (g) {}
  return c = Number(a.toString().replace('.', '')), d = Number(b.toString().replace('.', '')), mul(c / d, Math.pow(10, f - e))
}
//解决js浮点数计算bug===乘法
const mul = (a, b)  => {
  let c = 0,
  d = a.toString(),
  e = b.toString()
  try {
    c += d.split('.')[1].length
  } catch (f) {}
  try {
    c += e.split('.')[1].length
  } catch (f) {}
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}
//解决js浮点数计算bug===减法
const sub = (a, b) =>  {
  let c, d, e
  try {
    c = a.toString().split('.')[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split('.')[1].length
  } catch (f) {
    d = 0
  }
  return e = Math.pow(10, Math.max(c, d)), (mul(a, e) - mul(b, e)) / e
}
//处理国旗数据
const imgDataFlagFun = (resData,flag) =>  {
  for(let i in resData){
    if(typeof(resData[i][flag])!=='undefined'){
      for(let j in imgCountryFullName){
        if(imgCountryFullName[j]==(resData[i][flag]&&resData[i][flag].toLowerCase())){
          resData[i]['img']=imgDataFlag[j].default
        }
      }
    }
  }
  return resData
}
//去除值后面多余的0
const filterErro = (val) =>{
  val=String(val)
  if (val && val !== '-') {
    let regexp=/(?:\.0*|(\.\d+?)0+)$/
    val=val.replace(regexp,'$1')
    return val
  }
  return val
}
// url链接--获取参数
const getUrlSearch = (name) =>{
  // 未传参，返回空
  if (!name) return null
  // 查询参数：先通过search取值，如果取不到就通过hash来取
  let after = window.location.search
  after = after.substr(1) || window.location.hash.split('?')[1]
  // 地址栏URL没有查询参数，返回空
  if (!after) return null
  // 如果查询参数中没有"name"，返回空
  if (after.indexOf(name) === -1) return null
 
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  // 当地址栏参数存在中文时，需要解码，不然会乱码
  let r = decodeURI(after).match(reg)
  // 如果url中"name"没有值，返回空
  if (!r) return null
 
  return r[2]
}
// 获取上一个月时间,返回yyyyMMdd字符串
const getLastMonthTime = () =>{
  //  1    2    3    4    5    6    7    8    9   10    11   12月
  let daysInMonth = [0,31,28,31,30,31,30,31,31,30,31,30,31]
  let date=new Date()
  let strYear = date.getFullYear()
  let strDay = date.getDate();
  let strMonth = date.getMonth()+1
  //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
  if (((strYear % 4) === 0) && ((strYear % 100)!==0) || ((strYear % 400)===0)){
    daysInMonth[2] = 29
  }
  if(strMonth - 1 === 0) //二、解决跨年问题
  {
    strYear -= 1
    strMonth = 12
  }
  else
  {
    strMonth -= 1
  }
//  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
  strDay = Math.min(strDay,daysInMonth[strMonth])//三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

  if(strMonth<10)//给个位数的月、日补零
  {
    strMonth='0'+strMonth
  }
  if(strDay<10)
  {
    strDay='0'+strDay
  }
  let datastr = String(strYear)+String(strMonth)+String(strDay)
  return datastr
} 
// 字符串首字母大写
const charAtToUpperCase = (val) =>{
  if (val && val !== '-'&& val !=0) {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }
  return '-'
}
// 字符串小写转大写
const valToUpperCase = (val) =>{
  if (val && val !== '-' && val !=0 && val !==null && typeof(val) !=='undefined') {
    return val.toUpperCase()
  }
  return '-'
}
// 图片路径--flag
const imgFlagUrl = (num) =>{
  return imgDataFlag[num]['default']
}
// 转换日期格式2022-04-12T22:45:00.000Z->2022/04/12 22:45:00
const chgEventDate = (date,tag) =>{
  if(date && date !== '-' && typeof(date) !== 'undefined'&& date!==0 && date!==null){
    let date0 = new Date(date).toJSON()
    // 将输入的日期格式化成中国标准时间 “Mon Jul 19 2021 11:11:55 GMT+0800 (中国标准时间)”
    let dates = new Date(date0)
    let y = dates.getFullYear()
    let m = dates.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = dates.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = dates.getHours()
    h = h < 10 ? ('0' + h) : h
    let minute = dates.getMinutes()
    minute = minute < 10 ? ('0' + minute) : minute

    let Time = y + '/' + m + '/' + d+' '+h+':'+minute
    let Time1 = y + '/' + m + '/' + d

    return tag=='ymdhm' ?Time:tag=='y'?y:Time1
  }
  return '-'
}
// Apr. 21, 2022 / w/o Apr. 21, 2022 / Apr.2022 转换为 Apr 2022
const chgMonthYear= (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'&& data!=0){
    data=data.replace(/[w/o]/g,' ')
    let time='',result=''
    if(data.indexOf(',')==-1){
      time=data.split('.')
      result=time[0]+' '+time[1]
    }else{
      let tag=data.split('.')
      let m=tag[0]
      let y=tag[1].split(',')[1]
      result=m+' '+y
    }
    
    return result
  }
  return '-'
}
//根据值改变背景色
const chgBgColorData= (val) => {
  if(val&&typeof(val)!=='undefined'&&val!=='-'&&val!==null){
    let newnum='',num=String(val).split('.')[0]
    if(Number(num)==0){
      newnum=val>0?1:-1
    }else if(Number(num)<=-4){
      newnum=-4
    }else if(Number(num)>4){
      newnum=4
    }else{
      newnum=Number(num)
    }
    if(val==0){
      newnum=0
    }
    let keyData = speedData.find((x) => x['key'] == newnum)
    return keyData['bgColor']
  }
  return '#AFADAD'
}
// 判断值是否为null,是则显示 -
const isValShow = (val) => { 
  if (val && val !== '-' &&val !==null && typeof(val) !== 'undefined') {
    return val
  }
  return '-'
}
// 10 NOV 转换为 11/10
const chgEntozhData = (val) => {
  if(val && val !== '-' && typeof(val) !== 'undefined'&& val!=0 &&val !==null){
    let arr = val.split(' ')
    return monData[valToUpperCase(arr[1])]+'/'+arr[0]
  }
  return '-'
}
//显示为0
const showZero= (data) => {
  if(data && data !== '-' && typeof(data) !== 'undefined'){
    return data
  }
  return 0
}
//获取当前日期的 前n个月
const minDateFun= (num) => { 
  let date=new Date()
  date.setMonth(date.getMonth()-num)
  let year=date.getFullYear()
  let month=(date.getMonth()+1).toString().padStart(2,'0')
  let time=year+'-'+month
  let minDate=new Date(time).getTime()//获取时间戳
  return minDate
}
//去掉末尾多余的0
const handleCutZero=(num)=> {
  //拷贝一份 返回去掉零的新串
  let newstr = num
  //循环变量 小数部分长度
  let leng = num.length - num.indexOf('.') - 1
  //判断是否有效数
  if (num.indexOf('.') > -1) {
    //循环小数部分
    for (let i = leng; i > 0; i--) {
      //如果newstr末尾有0
      if (
        newstr.lastIndexOf('0') > -1 &&
        newstr.substr(newstr.length - 1, 1) == 0
      ) {
        let k = newstr.lastIndexOf('0')
        //如果小数点后只有一个0 去掉小数点
        if (newstr.charAt(k - 1) == '.') {
          return newstr.substring(0, k - 1)
        } else {
          //否则 去掉一个0
          newstr = newstr.substring(0, k)
        }
      } else {
        //如果末尾没有0
        return newstr
      }
    }
  }
  return num
}
//数字排序
const sortNumber = (tag, data, key) => {
  if (tag == 'asc') {
    //升序
    return data.sort(function (a, b) {
      let x = Number(a[key])
      let y = Number(b[key])
      return x < y ? -1 : x > y ? 1 : 0
    })
  }
  //降序
  return data.sort(function (a, b) {
    let x = Number(a[key])
    let y = Number(b[key])
    return x < y ? 1 : x > y ? -1 : 0
  })
}
//字母排序--降序
const sortDescLetter = (data) => {
  data.sort(function (a, b) {
    return (b + '').localeCompare(a + '')
  })
  return data
}
//字母排序
const sortLetter = (tag, data, key) => {
  if (tag == 'asc') {
    //升序
    data.sort(function (a, b) {
      return (a[key] + '').localeCompare(b[key] + '')
    })
    return data
  }
  //降序
  data.sort(function (a, b) {
    return (b[key] + '').localeCompare(a[key] + '')
  })
  return data
}
//处理数据--选中
const dealCheckedData = (data) => {
  let newData=[]
  for(let j in data){
    if(data[j]['checked']){
      newData.push(data[j])
    }
  } 
  return newData
}
//处理数据--选中key值
const dealCheckedKeyData = (data) => {
  let newData=[]
  for(let j in data){
    if(data[j]['checked']){
      newData.push(data[j]['key'])
    }
  } 
  return newData
}
// 滑块显示的值
const _truevalue2logvalue = (trueval) =>{
  let value=((trueval > 0) ? 1 : -1) * Math.log(Math.abs(trueval) + 1)
  value=value==0?0:keepNum(value,2)
  return Number(value)
}
// input显示的值
const _logvalue2truevalue = (logval) =>{
  let value=((logval > 0) ? 1 : -1) * (Math.exp(Math.abs(logval)) - 1)
  value=value==0?0:keepNum(value,2)
  return Number(value)
}
// 更改排序的数据
const getSortDataFun = (sortData,ChgThemeData,name,sortName) =>{
  for(let i in sortData){
    if(name==sortData[i]['name']){
      let getUpImg=sortName=='asc'?ChgThemeData=='Light'?dropUpOnLightImg:dropUpOnImg:dropUpImg
      let getDownImg=sortName=='desc'?ChgThemeData=='Light'?dropDownOnLightImg:dropDownOnImg:dropDownImg
      sortData[i]['upImg']=getUpImg
      sortData[i]['downImg']=getDownImg 
      sortData[i]['sortName']=name
      sortData[i]['sortKey']=sortName
    }else{
      sortData[i]['upImg']=dropUpImg 
      sortData[i]['downImg']=dropDownImg
      sortData[i]['sortName']=''
      sortData[i]['sortKey']=''
    }
  }
  return sortData
}
const getlocalChartStorage = () => {
  let localSetting = null;
  var stringJson = localStorage.getItem("fubon-chartSetting");
  if (stringJson !== null) {
    localSetting = JSON.parse(stringJson);
    if (localSetting === null) {
      localSetting = null;
    }
  } else {
    localSetting = null;
  }
  return localSetting
}
export default{
  getHttp:getHttp,
  getHttpZs:getHttpZs,
  getHttpChart:getHttpChart,
  getHttpChartInfo:getHttpChartInfo,
  getHttpAgree:getHttpAgree,
  getHttpWebca:getHttpWebca,
  enableWebca:enableWebca,
  imgDataFlag:imgDataFlag,
  imgArrFlag:imgArrFlag,
  imgCountryFlag:imgCountryFlag,
  imgCountryFullName:imgCountryFullName,
  imgShortNameFlag:imgShortNameFlag,
  imgChgCur:imgChgCur,
  isiOS:isiOS,
  isTestShow:isTestShow,
  imgShortNameChgCur:imgShortNameChgCur,
  speedData:speedData,
  addplusNum:addplusNum,
  changeRate:changeRate,
  keepNum:keepNum,
  transThousands:transThousands,
  splitNumM:splitNumM,
  addNumM:addNumM,
  splitNumB:splitNumB,
  addNumB:addNumB,
  onlyFormatDate:onlyFormatDate,
  changeFormatDate:changeFormatDate,
  hexToRgba:hexToRgba,
  calculateMA:calculateMA,
  imgDataFlagFun:imgDataFlagFun,
  onlyOrderDate:onlyOrderDate,
  onlyOrderTime:onlyOrderTime,
  changeOrderDate:changeOrderDate,
  changeOrderTime:changeOrderTime,
  getUrlSearch:getUrlSearch,
  dealAddCurData:dealAddCurData,
  getLastMonthTime:getLastMonthTime,
  charAtToUpperCase:charAtToUpperCase,
  valToUpperCase:valToUpperCase,
  imgFlagUrl:imgFlagUrl,
  chgEventDate:chgEventDate,
  chgMonthYear:chgMonthYear,
  chgBgColorData:chgBgColorData,
  isValShow:isValShow,
  chgEntozhData:chgEntozhData,
  showZero:showZero,
  minDateFun:minDateFun,
  handleCutZero:handleCutZero,
  sortNumber:sortNumber,
  sortDescLetter:sortDescLetter,
  sortLetter:sortLetter,
  dealCheckedData:dealCheckedData,
  dealCheckedKeyData:dealCheckedKeyData,
  _truevalue2logvalue:_truevalue2logvalue,
  _logvalue2truevalue:_logvalue2truevalue,
  getSortDataFun:getSortDataFun,
  filterErro:filterErro,
  shortNameChgCode,
  getlocalChartStorage:getlocalChartStorage
}