import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const NVI = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("AA2211");
  const [thickness, setThickness] = useState(2);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(true);

  const [enable2, setEnable2] = useState(true);
  const [period2, setPeriod2] = useState(72);
  const [color2, setColor2] = useState("0080FF");
  const [thickness2, setThickness2] = useState(2);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(true);

  const [enable3, setEnable3] = useState(false);
  const [period3, setPeriod3] = useState(255);
  const [color3, setColor3] = useState("9933FF");
  const [thickness3, setThickness3] = useState(2);
  const [style3, setStyle3] = useState("s");
  const [showTracker3, setShowTracker3] = useState(true);

  const [height, setHeight] = useState("1");
  const [value, setValue] = useState(period2 + "," + period3);

  // id: string
  const removeTA = (id) => {
    props.removeTA(id);
  };

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.NVI_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      try {
        const o = localChartSetting.option;
        const nvi = o.NVI;
        const atr1 = nvi[0]
        const atr2 = nvi[1]

        let NVI = "";
        let option1 = "";
        let option2 = "";
    
        NVI = {
          color: nvi.color,
          thickness: nvi.thickness,
          style: nvi.style,
          showTracker: nvi.showTracker,
        };
    
        option1 = {
          period: atr1.period,
          color: atr1.color,
          thickness: atr1.thickness,
          style: atr1.style,
          showTracker: atr1.showTracker,
          enabled: atr1.enabled,
        };
    
        option2 = {
          period: atr2.period,
          color: atr2.color,
          thickness: atr2.thickness,
          style: atr2.style,
          showTracker: atr2.showTracker,
          enabled: atr2.enabled,
        };
    
        let options = [option1, option2];
    
        let v = "";
        if (atr1.enabled) {
          v = v + atr1.period + ",";
        }
        if (atr2.enabled) {
          v = v + atr2.period;
        }
    
        setValue(v);
    
        props.onTAValueChange({
          ta: "NVI",
          name: `${t("chart5.TA.NVI")} (NVI)`,
          option: {
            NVI: NVI,
            atr: options,
            height: nvi.height,
          },
          value: localChartSetting.value,
        });
      } catch { }
    }
  }, [localChartSetting]);

  useEffect(() => {
    const NVI = props.localChartSetting.NVI_Option;

    const nvi = NVI.option.NVI;
    const atr1 = NVI.option.atr[0]
    const atr2 = NVI.option.atr[1]

    setColor(nvi.color);
    setThickness(parseInt(nvi.thickness) || thickness);
    setStyle(nvi.style);
    setShowTracker(nvi.showTracker);

    setEnable2(atr1.enabled);
    setPeriod2(parseInt(atr1.period) || period2);
    setColor2(atr1.color);
    setThickness2(parseInt(atr1.thickness) || thickness2);
    setStyle2(atr1.style);
    setShowTracker2(atr1.showTracker);

    setEnable3(atr2.enabled);
    setPeriod3(parseInt(atr2.period) || period3);
    setColor3(atr2.color);
    setThickness3(parseInt(atr2.thickness) || thickness3);
    setStyle3(atr2.style);
    setShowTracker3(atr2.showTracker);

    setHeight(NVI.option.height);

  }, [props.localChartSetting]);

  useEffect(() => {
    let NVI = "";
    let option1 = "";
    let option2 = "";

    NVI = {
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
    };

    option1 = {
      period: period2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
      enabled: enable2,
    };

    option2 = {
      period: period3,
      color: color3,
      thickness: thickness3,
      style: style3,
      showTracker: showTracker3,
      enabled: enable3,
    };

    let options = [option1, option2];

    let v = "";
    if (enable2) {
      v = v + period2 + ",";
    }
    if (enable3) {
      v = v + period3;
    }

    setValue(v);

    props.onTAValueChange({
      ta: "NVI",
      name: `${t("chart5.TA.NVI")} (NVI)`,
      option: {
        NVI: NVI,
        atr: options,
        height: height,
      },
      value: v,
    });
  }, [
    color,
    thickness,
    style,
    showTracker,
    period2,
    color2,
    thickness2,
    style2,
    showTracker2,
    enable2,
    period3,
    color3,
    thickness3,
    style3,
    showTracker3,
    enable3,
    height
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const NVI = props.localChartSetting.NVI_Option;

    const nvi = NVI.option.NVI;
    const atr1 = NVI.option.atr[0]
    const atr2 = NVI.option.atr[1]

    setColor(nvi.color);
    setThickness(parseInt(nvi.thickness) || thickness);
    setStyle(nvi.style);
    setShowTracker(nvi.showTracker);

    setEnable2(atr1.enabled);
    setPeriod2(parseInt(atr1.period) || period2);
    setColor2(atr1.color);
    setThickness2(parseInt(atr1.thickness) || thickness2);
    setStyle2(atr1.style);
    setShowTracker2(atr1.showTracker);

    setEnable3(atr2.enabled);
    setPeriod3(parseInt(atr2.period) || period3);
    setColor3(atr2.color);
    setThickness3(parseInt(atr2.thickness) || thickness3);
    setStyle3(atr2.style);
    setShowTracker3(atr2.showTracker);

    setHeight(NVI.option.height);
  }, [props.isReset]);

  const resetALL = () => {
    setColor("AA2211");
    setThickness(2);
    setStyle("s");
    setShowTracker(true);

    setEnable2(true);
    setPeriod2(72);
    setColor2("0080FF");
    setThickness2(2);
    setStyle2("s");
    setShowTracker2(true);

    setEnable3(false);
    setPeriod3(255);
    setColor3("9933FF");
    setThickness3(2);
    setStyle3("s");
    setShowTracker3(true);

    setHeight("1");
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicalindicator" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p2":
        if (enable2) setEnable2(false);
        else setEnable2(true);
        break;
      case "p3":
        if (enable3) setEnable3(false);
        else setEnable3(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "NVI":
        setStyle(e.target.value);
        break;
      case "p2":
        setStyle2(e.target.value);
        break;
      case "p3":
        setStyle3(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "p2":
        setPeriod2(e.target.value);
        break;
      case "p3":
        setPeriod3(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "NVI":
        setThickness(e.target.value);
        break;
      case "p2":
        setThickness2(e.target.value);
        break;
      case "p3":
        setThickness3(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "NVI":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "p2":
        if (showTracker2) setShowTracker2(false);
        else setShowTracker2(true);
        break;
      case "p3":
        if (showTracker3) setShowTracker3(false);
        else setShowTracker3(true);
        break;
    }
  };

  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const setTAColor = () => {
    switch (props.source) {
      case "NVI-C1":
        setColor(props.selectedColor);
        break;
      case "NVI-C2":
        setColor2(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicalindicator"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeTA("NVI")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.NVI")} (NVI) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeTA("NVI")}
        />
      </div>
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div className="menusubitem " id="msi-ta-NVI">
          <div className="taparamlbl">NVI</div>
          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-NVI-nvi-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "NVI-C1", t("chart5.setting"))}
            ></button>

            <div className="inputbox">
              <input
                id="msi-ta-NVI-nvi-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "NVI")}
              />
            </div>

            <div
              className="selectbox"
              onChange={(e) => onStyleChange(e, "NVI")}
            >
              <select id="msi-ta-NVI-nvi-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>

            <div className="showtracker">
              <div
                id="msi-ta-NVI-nvi-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("NVI")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable2 ? {} : { opacity: "0.5" }}
          id="msi-ta-NVI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "NVI-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-KC-mid-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox"
          style={enable3 ? {} : { opacity: "0.5" }}
          id="msi-ta-NVI"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable3 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p3")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 2</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color3}
              style={{ backgroundColor: "#" + color3 }}
              onClick={() =>
                onColorClick(color3, "NVI-C3", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness3}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p3")}>
              <select id="msi-ta-KC-mid-style" value={style3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div className="menusubitem" id="msi-ta-UI">
          <div className="flexbox">
            <div className="taparamlbl">{t("chart5.displayScreenSize")}</div>
            <div className="selectbox taparam">
              <select
                id="msi-ta-UI-ps-h"
                defaultValue={height}
                onChange={(e) => onHeightChange(e)}
                value={height}
              >
                <option value="2">{t("chart5.large")}</option>
                <option value="1">{t("chart5.normal")}</option>
                <option value="0">{t("chart5.small")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
