import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from "mobx-react"
import common from '../../../../asserts/js/common'
import { 
    upImg,
    downImg
} from '../../../../asserts/imgs/common/index'
import APPstyles from '../../../../App.less'
import styles from './index.less'

class MsgCon extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    render(){
        const {t,ChgLangData,ChgThemeData,msgData,isMobile} = this.props
        return (
            <div>
                <div 
                    className={[
                        styles.header,
                        ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark
                    ].join(' ')}
                    style={{
                        paddingTop:isMobile?0:16,
                        paddingBottom:isMobile?24:12
                    }}
                >
                    <span className={styles.title}>
                        {t('Sector')}: {msgData&&ChgLangData=='zh-CN'?msgData['name_cht']:msgData['name_en']}
                    </span>
                    <div className={[APPstyles.flex_row,styles.msg].join(' ')}>
                        <span 
                            className={[
                                styles.cur,
                                Number(msgData&&msgData['NETCHNG_1'])>0?APPstyles.color_red:Number(msgData&&msgData['NETCHNG_1'])<0?APPstyles.color_green:Number(msgData&&msgData['NETCHNG_1'])==0?APPstyles.color_grey:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                            ].join(' ')} 
                            style={{
                                marginRight:typeof(msgData&&msgData['NETCHNG_1'])=='undefined'||msgData&&msgData['NETCHNG_1']==0?8:0
                            }}
                        >
                            {common.transThousands(common.keepNum(msgData&&msgData['TRDPRC_1'],2))}
                        </span>
                        <img 
                            src={Number(msgData&&msgData['NETCHNG_1'])>0?upImg:downImg}
                            style={{
                                cursor:'default',
                                display:Number(msgData&&msgData['NETCHNG_1'])>0||String(msgData&&msgData['NETCHNG_1'])<0?'':'none'
                            }}
                        />
                        <span 
                            className={[
                                styles.num,
                                Number(msgData&&msgData['NETCHNG_1'])>0?APPstyles.color_red:Number(msgData&&msgData['NETCHNG_1'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                            ].join(' ')}
                        >
                            {common.addplusNum(common.transThousands(common.keepNum(msgData&&msgData['NETCHNG_1'],2)))}
                        </span>
                        <span 
                            className={[
                                styles.num,
                                Number(msgData&&msgData['PCTCHNG'])>0?APPstyles.color_red:Number(msgData&&msgData['PCTCHNG'])<0?APPstyles.color_green:ChgThemeData=='Light'?APPstyles.color_grey:APPstyles.color_white
                            ].join(' ')}
                            style={{margin:'0 12px'}}
                        >
                            {common.changeRate(common.addplusNum(common.transThousands(common.keepNum(msgData&&msgData['PCTCHNG'],2))))}
                        </span>
                        <span className={styles.date}>
                            {common.changeFormatDate(msgData&&msgData['TRADE_DATE'])}
                        </span>
                    </div>
                </div>
            </div>
        )}
}
export default withTranslation()(observer(MsgCon))