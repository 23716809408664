import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCloseLight } from "../../../asserts/imgs/chart/fubon/close_24px_rounded_light.svg";
import common from "../../../asserts/js/common";

export const SMA = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [period, setPeriod] = useState(5);
  const [color, setColor] = useState("0099FF");
  const [thickness, setThickness] = useState(1);
  const [style, setStyle] = useState("s");
  const [showTracker, setShowTracker] = useState(false);

  const [enable2, setEnable2] = useState(true);
  const [period2, setPeriod2] = useState(10);
  const [color2, setColor2] = useState("FF6600");
  const [thickness2, setThickness2] = useState(1);
  const [style2, setStyle2] = useState("s");
  const [showTracker2, setShowTracker2] = useState(false);

  const [enable3, setEnable3] = useState(true);
  const [period3, setPeriod3] = useState(20);
  const [color3, setColor3] = useState("00FF00");
  const [thickness3, setThickness3] = useState(1);
  const [style3, setStyle3] = useState("s");
  const [showTracker3, setShowTracker3] = useState(false);

  const [enable4, setEnable4] = useState(true);
  const [period4, setPeriod4] = useState(60);
  const [color4, setColor4] = useState("FFFF00");
  const [thickness4, setThickness4] = useState(1);
  const [style4, setStyle4] = useState("s");
  const [showTracker4, setShowTracker4] = useState(false);

  const [enable5, setEnable5] = useState(false);
  const [period5, setPeriod5] = useState(200);
  const [color5, setColor5] = useState("449999");
  const [thickness5, setThickness5] = useState(1);
  const [style5, setStyle5] = useState("s");
  const [showTracker5, setShowTracker5] = useState(false);
  const [value, setValue] = useState(
    period + "," + period2 + "," + period3 + "," + period4 + "," + period5
  );

  const [localChartSetting, setLocalChartSetting] = useState(null)

  useEffect(() => {
    let localSetting = common.getlocalChartStorage();

    if (localSetting)
      setLocalChartSetting(localSetting.SMA_Option)
  }, []);

  useEffect(() => {
    if (localChartSetting) {
      let option1 = "";
      let option2 = "";
      let option3 = "";
      let option4 = "";
      let option5 = "";

      option1 = {
        period: parseInt(localChartSetting.option[0].period) || period,
        color: localChartSetting.option[0].color,
        thickness: parseInt(localChartSetting.option[0].thickness) || thickness,
        style: localChartSetting.option[0].style,
        showTracker: localChartSetting.option[0].showTracker,
        enabled: localChartSetting.option[0].enabled,
      };

      option2 = {
        period: parseInt(localChartSetting.option[1].period) || period2,
        color: localChartSetting.option[1].color,
        thickness: parseInt(localChartSetting.option[1].thickness) || thickness2,
        style: localChartSetting.option[1].style,
        showTracker: localChartSetting.option[1].showTracker,
        enabled: localChartSetting.option[1].enabled,
      };

      option3 = {
        period: parseInt(localChartSetting.option[2].period) || period3,
        color: localChartSetting.option[2].color,
        thickness: parseInt(localChartSetting.option[2].thickness) || thickness3,
        style: localChartSetting.option[2].style,
        showTracker: localChartSetting.option[2].showTracker,
        enabled: localChartSetting.option[2].enabled,
      };

      option4 = {
        period: parseInt(localChartSetting.option[3].period) || period4,
        color: localChartSetting.option[3].color,
        thickness: parseInt(localChartSetting.option[3].thickness) || thickness4,
        style: localChartSetting.option[3].style,
        showTracker: localChartSetting.option[3].showTracker,
        enabled: localChartSetting.option[3].enabled,
      };

      option5 = {
        period: parseInt(localChartSetting.option[4].period) || period5,
        color: localChartSetting.option[4].color,
        thickness: parseInt(localChartSetting.option[4].thickness) || thickness5,
        style: localChartSetting.option[4].style,
        showTracker: localChartSetting.option[4].showTracker,
        enabled: localChartSetting.option[4].enabled,
      };

      let options = [option1, option2, option3, option4, option5];
      let v = "";
      if (localChartSetting.option[0].enabled) {
        v = v + parseInt(localChartSetting.option[0].period) + "," || period + ",";
      }
      if (localChartSetting.option[1].enabled) {
        v = v + parseInt(localChartSetting.option[1].period) + "," || period2 + ",";
      }
      if (localChartSetting.option[2].enabled) {
        v = v + parseInt(localChartSetting.option[2].period) + "," || period3 + ",";
      }
      if (localChartSetting.option[3].enabled) {
        v = v + parseInt(localChartSetting.option[3].period) + "," || period4 + ",";
      }
      if (localChartSetting.option[4].enabled) {
        v = v + parseInt(localChartSetting.option[4].period) || period5;
      }

      props.onOverlayValueChange({
        ta: "SMA",
        name: `${t("chart5.TA.SMA")} (SMA)`,
        option: options,
        value: v,
      });
    }
  }, [localChartSetting]);

  useEffect(() => {
    setEnable(props.localChartSetting.SMA_Option.option[0].enabled);
    setPeriod(parseInt(props.localChartSetting.SMA_Option.option[0].period) || period);
    setColor(props.localChartSetting.SMA_Option.option[0].color);
    setThickness(parseInt(props.localChartSetting.SMA_Option.option[0].thickness) || thickness);
    setStyle(props.localChartSetting.SMA_Option.option[0].style);
    setShowTracker(props.localChartSetting.SMA_Option.option[0].showTracker);

    setEnable2(props.localChartSetting.SMA_Option.option[1].enabled);
    setPeriod2(parseInt(props.localChartSetting.SMA_Option.option[1].period) || period2);
    setColor2(props.localChartSetting.SMA_Option.option[1].color);
    setThickness2(parseInt(props.localChartSetting.SMA_Option.option[1].thickness) || thickness2);
    setStyle2(props.localChartSetting.SMA_Option.option[1].style);
    setShowTracker2(props.localChartSetting.SMA_Option.option[1].showTracker);

    setEnable3(props.localChartSetting.SMA_Option.option[2].enabled);
    setPeriod3(parseInt(props.localChartSetting.SMA_Option.option[2].period) || period3);
    setColor3(props.localChartSetting.SMA_Option.option[2].color);
    setThickness3(parseInt(props.localChartSetting.SMA_Option.option[2].thickness) || thickness3);
    setStyle3(props.localChartSetting.SMA_Option.option[2].style);
    setShowTracker3(props.localChartSetting.SMA_Option.option[2].showTracker);

    setEnable4(props.localChartSetting.SMA_Option.option[3].enabled);
    setPeriod4(parseInt(props.localChartSetting.SMA_Option.option[3].period) || period4);
    setColor4(props.localChartSetting.SMA_Option.option[3].color);
    setThickness4(parseInt(props.localChartSetting.SMA_Option.option[3].thickness) || thickness4);
    setStyle4(props.localChartSetting.SMA_Option.option[3].style);
    setShowTracker4(props.localChartSetting.SMA_Option.option[3].showTracker);

    setEnable5(props.localChartSetting.SMA_Option.option[4].enabled);
    setPeriod5(parseInt(props.localChartSetting.SMA_Option.option[4].period) || period5);
    setColor5(props.localChartSetting.SMA_Option.option[4].color);
    setThickness5(parseInt(props.localChartSetting.SMA_Option.option[4].thickness) || thickness5);
    setStyle5(props.localChartSetting.SMA_Option.option[4].style);
    setShowTracker5(props.localChartSetting.SMA_Option.option[4].showTracker);

  }, [props.localChartSetting]);

  useEffect(() => {
    let option1 = "";
    let option2 = "";
    let option3 = "";
    let option4 = "";
    let option5 = "";

    option1 = {
      period: period,
      color: color,
      thickness: thickness,
      style: style,
      showTracker: showTracker,
      enabled: enable,
    };

    option2 = {
      period: period2,
      color: color2,
      thickness: thickness2,
      style: style2,
      showTracker: showTracker2,
      enabled: enable2,
    };

    option3 = {
      period: period3,
      color: color3,
      thickness: thickness3,
      style: style3,
      showTracker: showTracker3,
      enabled: enable3,
    };

    option4 = {
      period: period4,
      color: color4,
      thickness: thickness4,
      style: style4,
      showTracker: showTracker4,
      enabled: enable4,
    };

    option5 = {
      period: period5,
      color: color5,
      thickness: thickness5,
      style: style5,
      showTracker: showTracker5,
      enabled: enable5,
    };

    let options = [option1, option2, option3, option4, option5];
    let v = "";
    if (enable) {
      v = v + period + ",";
    }
    if (enable2) {
      v = v + period2 + ",";
    }
    if (enable3) {
      v = v + period3 + ",";
    }
    if (enable4) {
      v = v + period4 + ",";
    }
    if (enable5) {
      v = v + period5;
    }

    setValue(v);

    props.onOverlayValueChange({
      ta: "SMA",
      name: `${t("chart5.TA.SMA")} (SMA)`,
      option: options,
      value: value,
    });
  }, [
    period,
    color,
    thickness,
    style,
    showTracker,
    enable,
    period2,
    color2,
    thickness2,
    style2,
    showTracker2,
    enable2,
    period3,
    color3,
    thickness3,
    style3,
    showTracker3,
    enable3,
    period4,
    color4,
    thickness4,
    style4,
    showTracker4,
    enable4,
    period5,
    color5,
    thickness5,
    style5,
    showTracker5,
    enable5,
  ]);

  useEffect(() => {
    setTAColor();
  }, [props.selectedColor]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    setEnable(props.localChartSetting.SMA_Option.option[0].enabled);
    setPeriod(parseInt(props.localChartSetting.SMA_Option.option[0].period) || period);
    setColor(props.localChartSetting.SMA_Option.option[0].color);
    setThickness(parseInt(props.localChartSetting.SMA_Option.option[0].thickness) || thickness);
    setStyle(props.localChartSetting.SMA_Option.option[0].style);
    setShowTracker(props.localChartSetting.SMA_Option.option[0].showTracker);

    setEnable2(props.localChartSetting.SMA_Option.option[1].enabled);
    setPeriod2(parseInt(props.localChartSetting.SMA_Option.option[1].period) || period2);
    setColor2(props.localChartSetting.SMA_Option.option[1].color);
    setThickness2(parseInt(props.localChartSetting.SMA_Option.option[1].thickness) || thickness2);
    setStyle2(props.localChartSetting.SMA_Option.option[1].style);
    setShowTracker2(props.localChartSetting.SMA_Option.option[1].showTracker);

    setEnable3(props.localChartSetting.SMA_Option.option[2].enabled);
    setPeriod3(parseInt(props.localChartSetting.SMA_Option.option[2].period) || period3);
    setColor3(props.localChartSetting.SMA_Option.option[2].color);
    setThickness3(parseInt(props.localChartSetting.SMA_Option.option[2].thickness) || thickness3);
    setStyle3(props.localChartSetting.SMA_Option.option[2].style);
    setShowTracker3(props.localChartSetting.SMA_Option.option[2].showTracker);

    setEnable4(props.localChartSetting.SMA_Option.option[3].enabled);
    setPeriod4(parseInt(props.localChartSetting.SMA_Option.option[3].period) || period4);
    setColor4(props.localChartSetting.SMA_Option.option[3].color);
    setThickness4(parseInt(props.localChartSetting.SMA_Option.option[3].thickness) || thickness4);
    setStyle4(props.localChartSetting.SMA_Option.option[3].style);
    setShowTracker4(props.localChartSetting.SMA_Option.option[3].showTracker);

    setEnable5(props.localChartSetting.SMA_Option.option[4].enabled);
    setPeriod5(parseInt(props.localChartSetting.SMA_Option.option[4].period) || period5);
    setColor5(props.localChartSetting.SMA_Option.option[4].color);
    setThickness5(parseInt(props.localChartSetting.SMA_Option.option[4].thickness) || thickness5);
    setStyle5(props.localChartSetting.SMA_Option.option[4].style);
    setShowTracker5(props.localChartSetting.SMA_Option.option[4].showTracker);
  }, [props.isReset]);

  const resetALL = () => {
    setEnable(true);
    setPeriod(5);
    setColor("0099FF");
    setThickness(1);
    setStyle("s");
    setShowTracker(false);

    setEnable2(true);
    setPeriod2(10);
    setColor2("FF6600");
    setThickness2(1);
    setStyle2("s");
    setShowTracker2(false);

    setEnable3(true);
    setPeriod3(20);
    setColor3("00FF00");
    setThickness3(1);
    setStyle3("s");
    setShowTracker3(false);

    setEnable4(true);
    setPeriod4(60);
    setColor4("FFFF00");
    setThickness4(1);
    setStyle4("s");
    setShowTracker4(false);

    setEnable5(false);
    setPeriod5(200);
    setColor5("449999");
    setThickness5(1);
    setStyle5("s");
    setShowTracker5(false);
  };

  const onResetClick = () => {
    const answer = window.confirm(t("chart5.alertMsg"));
    if (answer) {
      setIsOpen(true);
      resetALL();
    } else {
      setIsOpen(true);
    }
  };

  const openOptionBox = (e) => {
    try {
      if (
        e.target.className.replace(" focus-within", "") ===
        "menusubitem msiaction techicaloverlay" ||
        e.target.className.replace(" focus-within", "") === "span" ||
        e.target.className.replace(" focus-within", "") === "optboxarrow" ||
        e.target.className.replace(" focus-within", "") === "msiicon"
      ) {
        if (e.target.className.replace(" focus-within", "") !== "b2dbtn") {
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }
    } catch (err) { }
  };

  // id: string
  const removeOverLay = (id) => {
    props.removeOverLay(id);
  };

  // type: string
  const onEnableChange = (type) => {
    switch (type) {
      case "p1":
        if (enable) setEnable(false);
        else setEnable(true);
        break;
      case "p2":
        if (enable2) setEnable2(false);
        else setEnable2(true);
        break;
      case "p3":
        if (enable3) setEnable3(false);
        else setEnable3(true);
        break;
      case "p4":
        if (enable4) setEnable4(false);
        else setEnable4(true);
        break;
      case "p5":
        if (enable5) setEnable5(false);
        else setEnable5(true);
        break;
    }
  };

  // e: any, type: string
  const onStyleChange = (e, type) => {
    switch (type) {
      case "p1":
        setStyle(e.target.value);
        break;
      case "p2":
        setStyle2(e.target.value);
        break;
      case "p3":
        setStyle3(e.target.value);
        break;
      case "p4":
        setStyle4(e.target.value);
        break;
      case "p5":
        setStyle5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onPeriodChange = (e, type) => {
    switch (type) {
      case "p1":
        setPeriod(e.target.value);
        break;
      case "p2":
        setPeriod2(e.target.value);
        break;
      case "p3":
        setPeriod3(e.target.value);
        break;
      case "p4":
        setPeriod4(e.target.value);
        break;
      case "p5":
        setPeriod5(e.target.value);
        break;
    }
  };

  // e: any, type: string
  const onThicknessChange = (e, type) => {
    switch (type) {
      case "p1":
        setThickness(e.target.value);
        break;
      case "p2":
        setThickness2(e.target.value);
        break;
      case "p3":
        setThickness3(e.target.value);
        break;
      case "p4":
        setThickness4(e.target.value);
        break;
      case "p5":
        setThickness5(e.target.value);
        break;
    }
  };

  // type: string
  const onShowTracker = (type) => {
    switch (type) {
      case "p1":
        if (showTracker) setShowTracker(false);
        else setShowTracker(true);
        break;
      case "p2":
        if (showTracker2) setShowTracker2(false);
        else setShowTracker2(true);
        break;
      case "p3":
        if (showTracker3) setShowTracker3(false);
        else setShowTracker3(true);
        break;
      case "p4":
        if (showTracker4) setShowTracker4(false);
        else setShowTracker4(true);
        break;
      case "p5":
        if (showTracker5) setShowTracker5(false);
        else setShowTracker5(true);
        break;
    }
  };

  const setTAColor = () => {
    switch (props.source) {
      case "SMA-C1":
        setColor(props.selectedColor);
        break;
      case "SMA-C2":
        setColor2(props.selectedColor);
        break;
      case "SMA-C3":
        setColor3(props.selectedColor);
        break;
      case "SMA-C4":
        setColor4(props.selectedColor);
        break;
      case "SMA-C5":
        setColor5(props.selectedColor);
        break;
    }
  };

  // color: string, type: any, title: any
  const onColorClick = (color, type, title) => {
    props.onColorClick(color, type, title);
  };

  const onKeyDown = (evt) => {
    if (evt.key === "-") evt.preventDefault();
  };

  return (
    <div
      className="menusubitem msiaction techicaloverlay"
      id="msi-ta"
      onClick={(e) => openOptionBox(e)}
    >
      {/* <div className="msiicon" onClick={() => removeOverLay("SMA")}></div> */}
      <div className="box-container">
        <span className="span">
          {t("chart5.TA.SMA")} (SMA) {value}
        </span>
        <IconCloseLight
          className="close-icon"
          onClick={() => removeOverLay("SMA")}
        />
      </div>
      {/* <span className="pval"></span> */}
      <div
        className="optboxarrow"
        style={isOpen ? { transform: "rotate(180deg)" } : {}}
        onClick={(e) => openOptionBox(e)}
      ></div>
      <div
        className="b2dbtn"
        style={isOpen ? { display: "flex" } : {}}
        title="スタイル設定を初期設定に戻す"
        onClick={onResetClick}
      ></div>
      <div className="msi-optbox" style={isOpen ? {} : { display: "none" }}>
        <div
          className="menusubitem checkbox selected"
          style={enable ? {} : { opacity: "0.5" }}
          id="msi-ta-SMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p1")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 1</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p1")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color}
              style={{ backgroundColor: "#" + color }}
              onClick={() => onColorClick(color, "SMA-C1", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p1")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p1")}>
              <select id="msi-ta-KC-mid-style" value={style}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p1")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable2 ? {} : { opacity: "0.5" }}
          id="msi-ta-SMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable2 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p2")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 2</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period2}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p2")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color2}
              style={{ backgroundColor: "#" + color2 }}
              onClick={() =>
                onColorClick(color2, "SMA-C2", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness2}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p2")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p2")}>
              <select id="msi-ta-KC-mid-style" value={style2}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker2 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p2")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable3 ? {} : { opacity: "0.5" }}
          id="msi-ta-SMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable3 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p3")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 3</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period3}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p3")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color3}
              style={{ backgroundColor: "#" + color3 }}
              onClick={() => onColorClick(color, "SMA-C3", t("chart5.setting"))}
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness3}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p3")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p3")}>
              <select id="msi-ta-KC-mid-style" value={style3}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker3 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p3")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable4 ? {} : { opacity: "0.5" }}
          id="msi-ta-SMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable4 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p4")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 4</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period4}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p4")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color4}
              style={{ backgroundColor: "#" + color4 }}
              onClick={() =>
                onColorClick(color4, "SMA-C4", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness4}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p4")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p4")}>
              <select id="msi-ta-KC-mid-style" value={style4}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker4 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p4")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>

        <div
          className="menusubitem checkbox selected"
          style={enable5 ? {} : { opacity: "0.5" }}
          id="msi-ta-SMA"
        >
          <div className="flexbox">
            <div
              id="msi-ta-KC-mid-enabled"
              className={enable5 ? "checkboxbutton-selected" : "checkboxbutton"}
              onClick={(e) => onEnableChange("p5")}
            ></div>
            <div className="taparamlbl">{t("chart5.period")} 5</div>
            <div className="inputbox taparam">
              <input
                id="msi-ta-KC-mid-period0"
                type="number"
                step="1"
                min="1"
                value={period5}
                maxLength={4}
                style={{ width: "40px" }}
                onChange={(e) => onPeriodChange(e, "p5")}
                onKeyDown={onKeyDown}
              />
            </div>
          </div>

          <div className="menusubitem stylebox ">
            <div className="styleboxlbl">{t("chart5.setting")}</div>
            <button
              id="msi-ta-KC-mid-color0"
              className="colorpicker"
              value={color5}
              style={{ backgroundColor: "#" + color5 }}
              onClick={() =>
                onColorClick(color5, "SMA-C5", t("chart5.setting"))
              }
            ></button>
            <div className="inputbox">
              <input
                id="msi-ta-KC-mid-thickness"
                type="number"
                step="1"
                min="1"
                max="9"
                value={thickness5}
                maxLength={1}
                style={{ width: "30px" }}
                onChange={(e) => onThicknessChange(e, "p5")}
              />
            </div>
            <div className="selectbox" onChange={(e) => onStyleChange(e, "p5")}>
              <select id="msi-ta-KC-mid-style" value={style5}>
                <option value="s">{t("chart5.realLine")}</option>
                <option value="d">{t("chart5.dotLine")}</option>
              </select>
            </div>
            <div className="showtracker">
              <div
                id="msi-ta-KC-mid-showtracker"
                className={
                  showTracker5 ? "checkboxbutton-selected" : "checkboxbutton"
                }
                onClick={(e) => onShowTracker("p5")}
              ></div>
              <div className="styleboxlbl">{t("chart5.showValue")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
